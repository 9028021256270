import { AnimatePresence, motion } from 'framer-motion'
import { FC, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { DropLocation, ProgramDraggable } from 'sierra-client/features/program/types'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import { useDelayedState } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/utils'
import { ProgramStep } from 'sierra-domain/api/manage'
import { isNotDefined } from 'sierra-domain/utils'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const DropIndicator = styled(motion.div).attrs({
  variants: {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: {
    duration: 0.2,
  },
})<{ $position: DropLocation }>`
  position: absolute;
  width: calc(100% - 16px);
  height: 2px;
  left: 8px;
  background: ${token('form/focus/border')};
  pointer-events: none;

  ${p =>
    p.$position === 'above'
      ? css`
          top: -8px;
        `
      : css`
          bottom: -8px;
        `}
`

const Hitbox = styled.div<{
  $position: DropLocation
  $active: boolean
  $debugging: boolean
  $isFirstStep: boolean
  $isLastStep: boolean
}>`
  position: absolute;
  width: 100%;
  opacity: 0.2;
  pointer-events: ${p => (p.$active ? 'all' : 'none')};

  ${p =>
    p.$position === 'above'
      ? css`
          top: ${p.$isFirstStep ? `-8px` : `calc(-50% - 14px)`};
          height: ${p.$isFirstStep ? `calc(50% + 8px)` : `calc(100% + 14px)`};
        `
      : css`
          bottom: ${p.$isLastStep ? `0px` : `calc(-50% - 6px)`};
          height: ${p.$isLastStep ? `calc(50% + 0px)` : `calc(100% + 6px)`};
        `}

  ${p =>
    p.$debugging &&
    css`
      background: orange;
    `}
`

type DropzoneProps = {
  position: DropLocation
  step: ProgramStep
  canDrop: (item: ProgramDraggable) => boolean
  isFirstStep: boolean
  isLastStep: boolean
}

export const Dropzone: FC<DropzoneProps> = ({ step, position, canDrop, isFirstStep, isLastStep }) => {
  const hitboxRef = useRef<HTMLDivElement>(null)
  const { moveStep, moveSection } = useOutlineEdit()
  const [hovering, setDelayedHovering, setImmediateHovering] = useDelayedState(false, 100)

  const debug = useIsDebugMode()

  const [collected, dropRef] = useDrop<
    ProgramDraggable,
    {
      index: number
    },
    { canDrop: boolean }
  >(() => {
    return {
      accept: ['program-step', 'program-section'],
      collect: monitor => {
        const item = monitor.getItem()

        if (isNotDefined(item)) {
          return {
            canDrop: false,
          }
        }

        return {
          canDrop: monitor.canDrop(),
        }
      },
      canDrop,
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return
        }

        if (item.type === 'program-section') {
          const sectionIndex = item.index
          const to = step.index
          moveSection(sectionIndex, to, position)
        } else {
          const from = item.index
          const to = step.index

          if (from !== to) {
            moveStep(from, to, position)
          }

          return { index: step.index }
        }
      },
    }
  }, [canDrop, moveSection, moveStep, position, step.index])
  dropRef(hitboxRef)

  const indicateDrop = collected.canDrop && hovering

  return (
    <>
      <AnimatePresence>
        {indicateDrop && (
          <DropIndicator key={`dropindicator-${position}-${step.index}`} $position={position} />
        )}
      </AnimatePresence>

      <Hitbox
        ref={hitboxRef}
        $position={position}
        $active={collected.canDrop}
        $debugging={debug && collected.canDrop}
        $isFirstStep={isFirstStep}
        $isLastStep={isLastStep}
        onPointerOver={() => setDelayedHovering(true)}
        onPointerLeave={() => setImmediateHovering(false)}
      />
    </>
  )
}
