import { EditorActionLog } from 'sierra-client/editor/action-log'
import * as Paragraph from 'sierra-client/editor/blocks/paragraph'
import { selectAll } from 'sierra-client/editor/utils/select-all'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import * as Code from 'sierra-client/views/v3-author/code/code-commands'
import * as KeyboardShortcuts from 'sierra-client/views/v3-author/keyboard-shortcuts'
import * as LinkCommands from 'sierra-client/views/v3-author/link/link-commands'
import * as ListCommands from 'sierra-client/views/v3-author/list/list-commands'
import { useSlashMenuOnKeyDown } from 'sierra-client/views/v3-author/slash-menu/slash-menu-on-key-down'
import { onKeyDown as onTableKeyDown } from 'sierra-client/views/v3-author/table'
import { useTagsMenuOnKeyDown } from 'sierra-client/views/v3-author/tags/tags-menu'
import * as TitleCard from 'sierra-client/views/v3-author/title-card/title-card-commands'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { useSlateStatic } from 'slate-react'

type KeyboardEventHandler = (event: EditorKeyboardEvent) => void

let handleAcceptCompletionKeyDownCallback: KeyboardEventHandler | undefined = undefined
export function setHandleAcceptCompletionKeyDownCallback(callback: KeyboardEventHandler | undefined): void {
  handleAcceptCompletionKeyDownCallback = callback
}

export const useOnKeyDown = (): ((event: EditorKeyboardEvent) => void) => {
  const editor = useSlateStatic()
  const SlashMenu = useSlashMenuOnKeyDown()
  const tagsMenu = useTagsMenuOnKeyDown()

  return useStableFunction((event: EditorKeyboardEvent) => {
    const eventHandlers: KeyboardEventHandler[] = [
      event => EditorActionLog.onKeyDown(editor, event),
      event => selectAll(event, editor),
      event => handleAcceptCompletionKeyDownCallback?.(event),
      event => tagsMenu(event),
      event => SlashMenu.onKeyDown(event),
      event => KeyboardShortcuts.runKeyboardShortcuts(event, editor),
      event => ListCommands.shortcuts(event, editor),
      event => LinkCommands.shortcuts(event, editor),
      event => TitleCard.shortcuts(event, editor),
      event => Code.onKeyDown(event, editor),
      event => onTableKeyDown(event, editor),
      event => Paragraph.onKeyDown(event, editor),
    ]

    for (const eventHandler of eventHandlers) {
      eventHandler(event)
      if (event.isPropagationStopped() === true) return
    }
  })
}
