import React, { useCallback, useRef, useState } from 'react'
import { playVideoAndIgnoreAbort } from 'sierra-client/utils/video.utils'
import { useEditorFocusContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { CreateCardConfig } from 'sierra-client/views/flexible-content/editor/content-sidebar/use-template-cards'
import { Text } from 'sierra-ui/primitives'
import { focusRing } from 'sierra-ui/utils'
import styled from 'styled-components'

const RootView = styled.button`
  all: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 16px;

  &:focus-visible {
    ${focusRing};
  }
`

const StyledVideo = styled.video`
  aspect-ratio: 16 / 11;
  max-width: 100%;
  border-radius: 16px;
`
const CoverImgWrapper = styled.div`
  overflow: hidden;
  aspect-ratio: 16 / 11;
  border-radius: 16px;
`
const CoverImg = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: cover;
  transition: transform 0.05s ease-in;
`

export type CreateCardConfigWithTranslations = CreateCardConfig & {
  title: string
  description: string
}

type CardMiniatureProps = {
  card: CreateCardConfigWithTranslations
  onClick?: () => void
}

// Safari flickers when poster prop is used.
const isNotSafari = (): boolean => !navigator.userAgent.match(/safari/i)

export const CardMiniatureV2: React.FC<CardMiniatureProps> = ({ card, onClick }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  const [loading, setLoading] = useState(false)

  const { setShouldAutoFocus } = useEditorFocusContext()

  const onCardClick = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      setShouldAutoFocus(true)
      await card.onClick()
      onClick?.()
      setLoading(false)
    }
  }, [loading, card, onClick, setShouldAutoFocus])

  const handleMouseEnter = (): void => {
    if (videoRef.current !== null && videoRef.current.paused) {
      void playVideoAndIgnoreAbort(videoRef.current)
      videoRef.current.currentTime = 0
    }
    if (imgRef.current !== null) {
      imgRef.current.style.transform = 'scale(1.1)'
    }
  }

  const handleMouseLeave = (): void => {
    if (videoRef.current !== null && !videoRef.current.paused) {
      videoRef.current.currentTime = 0
      videoRef.current.pause()
    }
    if (imgRef.current !== null) {
      imgRef.current.style.transform = 'scale(1)'
    }
  }

  return (
    <RootView onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onCardClick}>
      {card.video !== undefined ? (
        <StyledVideo
          ref={videoRef}
          preload='auto'
          muted
          playsInline
          loop
          poster={isNotSafari() ? card.cover.src : undefined}
          src={card.video.src}
        />
      ) : (
        <CoverImgWrapper>
          <CoverImg ref={imgRef} src={card.cover.src} />
        </CoverImgWrapper>
      )}
      <div>
        <Text bold>{card.title}</Text>
        <Text color='foreground/secondary'>{card.description}</Text>
      </div>
    </RootView>
  )
}
