import { useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CardMiniatureV2,
  CreateCardConfigWithTranslations,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/card-miniature-v2'
import {
  CreateCardCategory,
  CreateCardConfig,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/use-template-cards'
import {
  embedFileNamesAndDescriptions,
  fileNamesAndDescriptions,
} from 'sierra-client/views/flexible-content/file-names'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { Icon } from 'sierra-ui/components'
import { Button, IconButton, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CloseIcon = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })`
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(var(--radix-popper-available-height) - 200px);
  min-height: 760px;
  max-height: 1100px;
  width: 500px;
  padding-top: 32px;
  padding-left: 32px;
  overflow: hidden;
`

const SearchInput = styled.input`
  font-size: 14px;
  padding: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
`

const CardGrid = styled.div`
  padding-right: 32px;
  height: 100%;
  display: grid;
  column-gap: 20px;
  row-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding-bottom: 32px;
`

const addTranslationsToCard = (
  card: CreateCardConfig,
  t: TranslationLookup
): CreateCardConfigWithTranslations => {
  if (card.fileType === 'embed' && card.embedType !== undefined) {
    const { title, description } = embedFileNamesAndDescriptions[card.embedType]
    return { ...card, title, description: t(description) }
  }
  if (card.fileType === 'generate') {
    return {
      ...card,
      title: t('create.home.generate-from-doc'),
      description: t('author.generate.description'),
    }
  }

  const { title, description } = fileNamesAndDescriptions[card.fileType]

  return { ...card, title: t(title), description: t(description) }
}

type CreateCardPickerFilter = 'all' | CreateCardCategory

type CreateCardPickerProps = {
  cards: CreateCardConfig[]
  onClose: () => void
}

export const CreateCardPicker: React.FC<CreateCardPickerProps> = ({ cards, onClose }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [categoryFilter, setCategoryFilter] = useState<CreateCardPickerFilter>('all')

  const { scopedCreateContentId } = useCreatePageContext()
  const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)

  const getCardKey = (card: CreateCardConfig): string => {
    if (card.fileType !== 'embed' || card.embedType === undefined) return card.fileType
    else return card.embedType
  }

  const filteredCards = useMemo(
    () =>
      cards
        .map(card => addTranslationsToCard(card, t))
        .filter(card => {
          if (categoryFilter !== 'all' && !card.category.includes(categoryFilter)) return false
          if (search === '') return true

          const searchLower = search.toLowerCase()
          const titleLower = card.title.toLowerCase()
          const descriptionLower = card.description.toLowerCase()

          return titleLower.includes(searchLower) || descriptionLower.includes(searchLower)
        }),
    [cards, categoryFilter, search, t]
  )

  return (
    <Container>
      <CloseIcon onClick={onClose} />
      <View>
        <Icon iconId='search' />
        <SearchInput
          autoFocus
          placeholder='Search card...'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </View>

      <Spacer size='16' />

      <View>
        <Button
          onClick={() => setCategoryFilter('all')}
          variant={categoryFilter === 'all' ? 'ghost' : 'secondary'}
        >
          {t('dictionary.all')}
        </Button>
        <Button
          onClick={() => setCategoryFilter('text-and-media')}
          variant={categoryFilter === 'text-and-media' ? 'ghost' : 'secondary'}
        >
          {t('dictionary.text-and-media')}
        </Button>
        <Button
          onClick={() => setCategoryFilter('interactive')}
          variant={categoryFilter === 'interactive' ? 'ghost' : 'secondary'}
        >
          {t('dictionary.interactive')}
        </Button>
        {contentType === 'live' && (
          <Button
            onClick={() => setCategoryFilter('live')}
            variant={categoryFilter === 'live' ? 'ghost' : 'secondary'}
          >
            {t('dictionary.live')}
          </Button>
        )}
        <Button
          onClick={() => setCategoryFilter('embed')}
          variant={categoryFilter === 'embed' ? 'ghost' : 'secondary'}
        >
          {t('dictionary.embed')}
        </Button>
      </View>
      <Spacer size='24' />

      <CardGrid>
        {filteredCards.map(card => (
          <CardMiniatureV2 key={getCardKey(card)} card={card} onClick={onClose} />
        ))}
      </CardGrid>
    </Container>
  )
}
