import { forwardRef, ReactNode } from 'react'
import { LobbyContainer } from 'sierra-client/components/liveV2/cards/lobby'
import {
  cardBackgroundStyles,
  useSmartImageUnionLoader,
} from 'sierra-client/views/flexible-content/card-background'
import { BottomContentContainer } from 'sierra-client/views/self-paced/bottom-content'
import { HomeworkCardSettingsContainer } from 'sierra-client/views/v3-author/homework/homework-settings'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { File } from 'sierra-domain/flexible-content/types'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const LoadBackground = styled(View)<{
  className?: string
  $background: string | undefined
  $imageFit?: ImageFit
}>`
  border: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1); /* Animate background changes */

  background-color: ${p => p.theme.home.backgroundColor};
  color: ${p => p.theme.home.textColor};
  ${cardBackgroundStyles};

  ${HideScrollbarUnlessHovered}

  /* Every child should always take all of the available space */
  & > *:not(${BottomContentContainer}):not(${LobbyContainer}):not(${HomeworkCardSettingsContainer}) {
    width: 100%;
    height: auto;
    flex: 0 0 100%;
  }
`

/**
 * Direct parent to any card (create, live, self-paced, review)
 * [*] Loads background image and color
 * [*] Handles scrolling
 */
export const CardCanvas = forwardRef<
  HTMLDivElement,
  {
    className?: string
    card: Pick<File, 'backgroundImage' | 'data'>
    children?: ReactNode
    assetContext: AssetContext
  }
>(({ className, card, children, assetContext }, ref) => {
  const backgroundUrl = useSmartImageUnionLoader(assetContext, card.backgroundImage)
  const imageFit =
    card.data.type === 'image'
      ? card.data.imageFit
      : card.data.type === 'slate-card'
        ? card.data.imageFit
        : undefined

  return (
    <LoadBackground ref={ref} className={className} $background={backgroundUrl} $imageFit={imageFit}>
      {children}
    </LoadBackground>
  )
})
