import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { AssignmentWithDueDate, UserAssignmentWithDueDate } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'

export const parseModalToAssignment = (
  userIds: UserId[],
  selections: AssignModalSelection[]
): AssignmentWithDueDate[] =>
  selections.flatMap(d => {
    const contentType = d.type

    if (contentType === 'program' || contentType === 'user' || contentType === 'user-group') return []
    if (d.dueDate?.type === 'relative') return []

    const typeFixedDD = d.dueDate

    return userIds.map(id => ({
      content: {
        id: d.id,
        type: contentType,
      },
      assignee: {
        id,
        type: 'user',
        dueDate: typeFixedDD?.date,
        autoAssign: d.liveAssignmentType === 'auto-assign',
        selfEnroll: d.liveAssignmentType === 'self-enroll',
      },
    }))
  })

export const parseModalToUserAssignment = (selections: AssignModalSelection[]): UserAssignmentWithDueDate[] =>
  selections.flatMap(d => {
    const contentType = d.type

    if (contentType === 'program' || contentType === 'user' || contentType === 'user-group') return []
    if (d.dueDate?.type === 'relative') return []

    const typeFixedDD = d.dueDate

    return [
      {
        content: {
          id: d.id,
          type: contentType,
        },
        dueDate: typeFixedDD?.date,
        autoAssign: d.liveAssignmentType === 'auto-assign',
        selfEnroll: d.liveAssignmentType === 'self-enroll',
      },
    ]
  })

export const parseModalToContentAssignment = (
  contentIds: string[],
  type: 'course' | 'path' | 'live-session',
  selections: AssignModalSelection[]
): AssignmentWithDueDate[] =>
  selections.flatMap(d => {
    const assignee = ((): AssignmentWithDueDate['assignee'] | undefined => {
      if (
        d.type === 'course' ||
        d.type === 'path' ||
        d.type === 'live-session' ||
        d.type === 'program' ||
        d.type === 'calendar-event'
      )
        return undefined

      if (d.dueDate?.type === 'relative') return undefined
      return {
        ...(d.type === 'user' ? { type: 'user', id: d.id } : { type: d.type, id: d.id }),
        dueDate: d.dueDate?.date,
        autoAssign: d.liveAssignmentType === 'auto-assign',
        selfEnroll: d.liveAssignmentType === 'self-enroll',
      }
    })()

    return assignee === undefined
      ? []
      : contentIds.map(id => ({
          content: {
            id,
            type,
          },
          assignee,
        }))
  })

export const getGroupTypeFromSubjectType = (subjectType: 'user-group' | 'program'): 'program' | 'user' =>
  subjectType === 'user-group' ? 'user' : 'program'
