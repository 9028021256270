import { createSelector } from '@reduxjs/toolkit'
import { useParams } from '@tanstack/react-router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHideIntercom } from 'sierra-client/components/util/show-intercom-launcher'
import { Logging } from 'sierra-client/core/logging'
import { errorLogger } from 'sierra-client/error/error-logger'
import { selectCourseProgress } from 'sierra-client/state/card-progress/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { RootState } from 'sierra-client/state/types'
import { FCC } from 'sierra-client/types'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId, NanoId12, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { assertNever } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'
import { z } from 'zod'

type SetStatus = {
  name: 'cmi.set_status'
  value: 'completed' | 'incomplete'
}

type SetScore = {
  name: 'cmi.set_progress'
  value: number
}

// We still have old SCORM files in circulation and for now we need to pass messages in their format as well.
type SetStatusLegacy = {
  name: 'cmi.core.lesson_status'
  value: 'completed' | 'incomplete'
}

type SetScoreLegacy = {
  name: 'cmi.core.score.raw'
  value: number
}

type SendPing = {
  name: 'ping'
  value: number
}

type ScormOperation = SetStatus | SetScore | SetStatusLegacy | SetScoreLegacy | SendPing

// When using SCORM, our application is embedded within an iframe in a SCORM file.
// That SCORM file will send a message to our application, which we can reply to in order to
// report information back to the LMS.

const ScormPingResponse = z.object({
  name: z.literal('ping-response'),
  value: z.number(),
})
const ScormLogMessage = z.object({
  name: z.literal('log'),
  value: z.string(),
})

const ScormMessage = z.discriminatedUnion('name', [ScormPingResponse, ScormLogMessage])
const postScormMessage = (operation: ScormOperation): void => {
  window.parent.postMessage(operation, '*')
}

const postScormProgress = (newProgress: SetScore['value']): void => {
  postScormMessage({ name: 'cmi.set_progress', value: newProgress })
  postScormMessage({ name: 'cmi.core.score.raw', value: newProgress })
}

const postScormStatus = (newStatus: SetStatus['value']): void => {
  postScormMessage({ name: 'cmi.set_status', value: newStatus })
  postScormMessage({ name: 'cmi.core.lesson_status', value: newStatus })
}

// ---

type ScormContext = {
  setScormCourseGroupId: (groupId: NanoId12) => void
  scormCourseGroupId: NanoId12 | undefined
  setPing: (enabled: boolean) => void
  setCourseKind: (contentId: CourseKind) => void
  courseKind: CourseKind | undefined
}
export const ScormContext = React.createContext<ScormContext | undefined>(undefined)

const selectSelfPacedScormProgress = createSelector(
  (state: RootState, courseId: CourseId | undefined) => {
    if (courseId === undefined) return undefined

    const selfPacedIdResult = SelfPacedContentId.safeParse(courseId)

    return selfPacedIdResult.success ? selectCourseProgress(state, selfPacedIdResult.data) : undefined
  },
  progress => progress
)

type ScormProgress = {
  status: 'completed' | 'incomplete'
  score: number
}

const selectScormProgress = createSelector(
  (state: RootState, courseId: CourseId | undefined) => courseId,
  (state: RootState, courseId: CourseId | undefined) => selectSelfPacedScormProgress(state, courseId),
  (courseId, progress): ScormProgress | undefined => {
    if (courseId === undefined) return undefined
    if (progress === undefined) return undefined

    if (progress < 0 || progress > 1)
      throw new Error(`Invalid course progress: ${progress}. Course progress must be between 0 and 1.`)

    const scormScore = Math.floor(100 * progress)
    return {
      status: scormScore === 100 ? 'completed' : 'incomplete',
      score: scormScore,
    }
  }
)

export const DisabledScormProvider: FCC = ({ children }) => {
  const value: ScormContext = useMemo(
    () => ({
      setScormCourseGroupId: () => {},
      scormCourseGroupId: undefined,
      setPing: () => {},
      setCourseKind: () => {},
      courseKind: undefined,
    }),
    []
  )

  return <ScormContext.Provider value={value}>{children}</ScormContext.Provider>
}

export const EnabledScormProvider: FCC = ({ children }) => {
  const dispatch = useDispatch()
  useHideIntercom()

  const courseId = useParams({ strict: false, select: params => params.flexibleContentId })

  const [courseKind, setCourseKind] = useState<CourseKind | undefined>(undefined)
  const [scormCourseGroupId, setScormCourseGroupId] = useState<NanoId12 | undefined>(undefined)
  const [ping, setPing] = useState<boolean>(false)

  const scormProgress = useSelector(state => selectScormProgress(state, courseId))

  const timeoutRef = useRef<number | undefined>(undefined)

  useEffect(() => {
    if (!ping) return

    const handleMessage = (event: MessageEvent): void => {
      const either = ScormMessage.safeParse(event.data)

      if (!either.success) return

      switch (either.data.name) {
        case 'ping-response':
          // Got ping response. Log to Segment.
          void dispatch(Logging.scorm.scormConnectionStatus({ success: true }))
          window.clearTimeout(timeoutRef.current)
          break
        case 'log':
          // add debug log here if needed
          break
        default:
          assertNever(either.data)
      }
    }

    window.addEventListener('message', handleMessage, false)

    // Send ping message to parent context
    postScormMessage({ name: 'ping', value: 1 }) // We don't use the message ID right now, but we might in the future.

    timeoutRef.current = window.setTimeout(() => {
      // Ping request timed out. Log to Segment.
      void dispatch(Logging.scorm.scormConnectionStatus({ success: false }))
      // Also log to Sentry
      errorLogger.error('Could not establish SCORM connection.')
    }, 1000)

    return () => {
      window.removeEventListener('message', handleMessage)
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
  }, [ping, dispatch])

  useOnChanged((previous, current) => {
    if (current === undefined) return

    if (previous?.score !== current.score) {
      postScormProgress(current.score)
    }

    if (previous?.status !== current.status) {
      postScormStatus(current.status)
    }
  }, scormProgress)

  const value: ScormContext = useMemo(
    () => ({
      setCourseKind,
      setPing,
      setScormCourseGroupId,
      scormCourseGroupId,
      courseKind,
    }),
    [setCourseKind, setPing, setScormCourseGroupId, scormCourseGroupId, courseKind]
  )

  return <ScormContext.Provider value={value}>{children}</ScormContext.Provider>
}
export const useScormContext = (): ScormContext => {
  const context = useContext(ScormContext)
  if (context === undefined) {
    throw new Error('This component must be wrapped in a ScormContextProvider')
  }
  return context
}
