import ReactDOM from 'react-dom/client'
import { createLogging, UserSingleton } from 'sierra-client/core/user'
import 'sierra-client/i18n/i18n'
import { checkPolyfills } from 'sierra-client/polyfills/polyfills'
import { ReactRoot } from 'sierra-client/react-root'
import { cleanEntryUrl } from 'sierra-client/router'
import { store } from 'sierra-client/state/store'

checkPolyfills()
cleanEntryUrl()
UserSingleton.initialize(store, createLogging())

const rootElement = document.getElementById('app')!

ReactDOM.createRoot(rootElement).render(<ReactRoot />)
