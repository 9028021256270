import { BaseColumn } from 'sierra-client/lib/tabular/datatype/column'
import { ColumnToColumnData, ColumnType } from 'sierra-client/lib/tabular/datatype/data'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { ColumnRef } from 'sierra-client/lib/tabular/types'
import { ItemOf } from 'sierra-domain/utils'

type RowDefinition<D> = {
  getId: (_: D) => string
}

type ColumnDefinition<D, K extends keyof ColumnToColumnData> = BaseColumn & {
  getData: (_: D) => { type: K; data: ColumnToColumnData[K] }
}

export type AnyColumnDefinition<D> = ColumnDefinition<D, keyof ColumnToColumnData>

export type ColumnDefinitionOf<
  D,
  O extends { type: keyof ColumnToColumnData; ref: string },
> = ColumnDefinition<D, O['type']> & {
  getData: (_: D) => { data: ColumnToColumnData[O['type']]; type: O['type'] }
  type: O['type']
  ref: O['ref']
}

export type TableDefinition<D> = {
  nested: Record<PropertyKey, TableData>
  columns: ColumnDefinition<D, keyof ColumnToColumnData>[]
  rows: RowDefinition<D>
}

export type TableDefinitionOf<D, Cols extends { type: ColumnType; ref: ColumnRef }[]> = TableDefinition<D> & {
  columns: {
    [Index in keyof Cols]: Cols[Index] & ColumnDefinition<D, Cols[Index]['type']>
  } & { length: Cols['length'] }
}

// todo(workflows): Remove the need for this
type AnyData = { type: ColumnType; data: ColumnToColumnData[ColumnType] }

export const definition2Data = <
  D,
  TableDef extends TableDefinition<D>,
  TableData extends TableDataFromDefinition<D, TableDef> = TableDataFromDefinition<D, TableDef>,
>(
  definition: TableDef,
  data: D[]
): TableData => {
  const rows = data.map(d => {
    const id = definition.rows.getId(d)
    const data = definition.columns.reduce(
      (acc, c) => {
        acc[c.ref] = c.getData(d)
        return acc
      },
      {} as Record<PropertyKey, AnyData>
    )
    return { id, data }
  })

  return { nested: definition.nested, columns: definition.columns, rows } as TableData
}

type DataFromColumnDefinition<D, TableDef extends TableDefinition<D>> = {
  [Col in ItemOf<TableDef['columns']> as Col['ref']]: ReturnType<Col['getData']>
}

export type TableDataFromDefinition<D, TableDef extends TableDefinition<D>> = {
  nested: TableDef['nested']
  columns: TableDef['columns']
  rows: Array<{
    id: string
    data: DataFromColumnDefinition<D, TableDef>
  }>
}
