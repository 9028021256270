import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { useSetAtom } from 'jotai/index'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { sidebarHasNestedMenuOpenAtom } from 'sierra-client/features/collapsable-sidebar'
import { ContentPreview } from 'sierra-client/features/content-preview'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useLoadNamespacedCourseAssets } from 'sierra-client/hooks/use-resolve-asset'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { resolveThemeColor } from 'sierra-client/hooks/use-themes'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDuplicateFileCreatedByUser } from 'sierra-client/state/flexible-content/factory'
import { useFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import {
  selectClosestFileIdWhenDeleting,
  selectFlatSelectionInfoList,
  selectFlexibleContentFile,
  selectFlexibleContentNodes,
  selectNodeCollaborators,
  selectParentFolders,
} from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { resolveBackgroundImageUrl } from 'sierra-client/views/flexible-content/card-background'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useEditorFocusContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { CanvasMenuItem } from 'sierra-client/views/flexible-content/editor/content-sidebar/canvas-menu-item'
import { ContentPreviewEnabledAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-preview-atom'
import { sidebarContainerAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-sidebar-atoms'
import {
  isCopyPasteSupported,
  onPaste,
  useCopyCardToClipboard,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import {
  useEditorSidebarDrag,
  useEditorSidebarDrop,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/editor-dnd/single-item-dnd'
import {
  DistanceBetweenSidebarFiles,
  FileBackgroundCss,
  FileBaseProps,
  FileContainerBaseStylings,
  MultiSelectionFileCSS,
  MultiSelectionFileProps,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/file-base-stylings-css'
import { GenerateCardsShortcuts } from 'sierra-client/views/flexible-content/editor/content-sidebar/generate-cards-shortcut'
import { getFileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { isGeneratingAvatarsAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/is-generating-avatars-atom'
import { useMultiSelectionCopy } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/actions'
import { useMultiSelectionMenuItems } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/multi-selection-menu-items'
import { useAbortMultiSelectionClear } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-abort-multi-selection-clear'
import { useIsEnabledMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-is-enabled-multi-selection'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { isMetaKey } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/utils'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { CreateSpeakerNoteInput } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/ui'
import { UserIdAvatars } from 'sierra-client/views/flexible-content/editor/content-sidebar/user-id-avatars'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { useReversibleEditorAction } from 'sierra-client/views/flexible-content/undo-redo/use-reversible-editor-action'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { ContentPermission } from 'sierra-domain/api/common'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { apply } from 'sierra-domain/editor/operations'
import { isSlateFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocument, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { color } from 'sierra-ui/color'
import { EditableText, Icon, IconId, IconProps, MenuItem, Tooltip } from 'sierra-ui/components'
import { focusInput } from 'sierra-ui/components/editable-text/editable-text'
import { LoaderAnimation, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, token } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css, useTheme } from 'styled-components'
import * as Y from 'yjs'

export const FileContainer = styled(motion.div)<FileBaseProps & MultiSelectionFileProps>`
  position: relative;
  transition: border-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  display: flex;

  justify-content: space-between;
  text-align: left;
  gap: 10px;

  white-space: nowrap;
  text-overflow: ellipsis;

  margin: ${DistanceBetweenSidebarFiles} 0 ${DistanceBetweenSidebarFiles} 0;

  align-items: center;

  ${FileContainerBaseStylings}
  &::before {
    content: '';
    width: calc(100% - 10px);
    height: 2px;
    position: absolute;
    background: transparent;
    border-radius: 10px;
    bottom: 0px; /* Needs to have a default positioning as it will otherwise mess with the click listeners */
  }

  ${FileBackgroundCss};
  ${MultiSelectionFileCSS};
`

const DroppableNode = styled(FileContainer)<{
  isDragging: boolean
  draggingOver: boolean
  indicateBelow: boolean
  isRoot: boolean
}>`
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: ${p => (p.isDragging ? 'grabbing' : 'pointer')};
  gap: 4px;

  ${p =>
    p.draggingOver
      ? p.indicateBelow
        ? css`
            &::before {
              content: '';
              position: absolute;
              height: 2px;
              border-radius: 2.5px;
              width: 100%;
              background-color: ${p.theme.color.blueVivid};
              bottom: -5px;
              left: 0;
            }
          `
        : css`
            &::before {
              content: '';
              position: absolute;
              height: 2px;
              border-radius: 2.5px;
              width: 100%;
              background-color: ${p.theme.color.blueVivid};
              left: 0;
              top: -5px;
            }
          `
      : 'transparent'}
`

const FileMenu: React.FC<{
  parentFolderId: FolderId
  fileId: FileId
  startRename: () => void
  anchorElement: HTMLElement | null
  avatarStackShown: boolean
  isFileSelected: boolean
}> = ({ parentFolderId, fileId, startRename, anchorElement, avatarStackShown, isFileSelected }) => {
  const { isEnabled: pasteRecentlyClicked, setTrue: setPasteRecentlyClicked } = useResetBooleanAfterDelay()
  const { t } = useTranslation()
  const { createContentId, scopedCreateContentId, operationState } = useCreatePageContext()
  const dispatch = useDispatch()
  const currentFile = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))
  const closestFileWhenDeleting = useSelector(state =>
    selectClosestFileIdWhenDeleting(state, createContentId, parentFolderId, fileId)
  )
  const isBulkActionsEnabled = useIsEnabledMultiSelection()
  const userId = useSelector(state => selectUserId(state))
  const isSelfpaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const isCopyPasteEnabled = isCopyPasteSupported()
  const notif = useNotif()
  const nonExpiringNotif = useNonExpiringNotif()

  const { isEnabled: duplicateFileRecentlyFinished, setTrue: setDuplicateFileRecentlyFinished } =
    useResetBooleanAfterDelay()
  const duplicateFile = useDuplicateFileCreatedByUser({
    folderId: parentFolderId,
    nextTo: fileId,
    onSuccess: setDuplicateFileRecentlyFinished,
  })

  const applyReversibleAction = useReversibleEditorAction()

  const { isEnabled: copyCardRecentlyFinished, setTrue: setCopyCardRecentlyFinished } =
    useResetBooleanAfterDelay()
  const copyCardMutation = useCopyCardToClipboard({
    operationState,
    contentId: createContentId,
    onSuccess: setCopyCardRecentlyFinished,
  })

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [visible, { on, off }] = useToggle()

  useEffect(() => {
    if (anchorElement === null) return

    anchorElement.addEventListener('mouseenter', on)
    anchorElement.addEventListener('mouseleave', off)

    return () => {
      anchorElement.removeEventListener('mouseenter', on)
      anchorElement.removeEventListener('mouseleave', off)
    }
  }, [anchorElement, off, on])

  const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)
  const { setAbortingMultiSelectionClear } = useAbortMultiSelectionClear()

  const onOpenChange = useStableFunction((newIsOpen: boolean) => {
    setAbortingMultiSelectionClear(newIsOpen)
    setSidebarHasNestedMenuOpen(newIsOpen)
    setIsSettingsOpen(newIsOpen)
  })

  const multiSelectMenuItems = useMultiSelectionMenuItems({ closeMenu: () => onOpenChange(false) })

  const fileMenuItems = useMemo<MenuItem[]>(
    () =>
      multiSelectMenuItems !== undefined
        ? multiSelectMenuItems
        : [
            {
              id: 'rename',
              type: 'label',
              label: t('admin.rename'),
              icon: 'edit',
              onClick: startRename,
            },
            {
              id: 'duplicate',
              type: 'canvas',
              render({ onItemClick }) {
                return (
                  <CanvasMenuItem
                    onClick={e => {
                      onItemClick?.()
                      e.stopPropagation()
                      if (duplicateFile.isPending) {
                        return
                      }

                      assertIsNonNullable(currentFile)
                      duplicateFile.mutate({ originalFile: currentFile })
                    }}
                    loading={duplicateFile.isPending}
                    text={t('dictionary.duplicate')}
                    icon={duplicateFileRecentlyFinished ? 'checkmark' : 'duplicate'}
                    keyboardShortcut='D'
                  />
                )
              },
            },
            {
              id: 'copy',
              type: 'canvas',
              hidden: !isCopyPasteEnabled,
              render({ onItemClick }) {
                return (
                  <CanvasMenuItem
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()

                      if (copyCardMutation.isPending) {
                        return
                      }

                      onItemClick?.()
                      copyCardMutation.mutate(fileId)
                    }}
                    loading={copyCardMutation.isPending}
                    icon={copyCardRecentlyFinished ? 'checkmark' : 'copy--file'}
                    text={t('admin.copy')}
                    keyboardShortcut='C'
                  />
                )
              },
            },
            {
              id: 'paste',
              type: 'canvas',
              hidden: !isCopyPasteEnabled,

              render({ onItemClick }) {
                return (
                  <CanvasMenuItem
                    onClick={e => {
                      setPasteRecentlyClicked()
                      onItemClick?.()
                      e.stopPropagation()
                      e.preventDefault()
                      assertIsNonNullable(userId)
                      void onPaste({
                        operationState,
                        userId,
                        isSelfpacedContent: isSelfpaced,
                        notif,
                        nonExpiringNotif,
                        t,
                        scopedCreateContentId,
                        createContentId,
                        dispatch,
                        folderId: parentFolderId,
                        nextTo: fileId,
                        isBulkActionsEnabled,
                      })
                    }}
                    text={t('admin.paste')}
                    icon={pasteRecentlyClicked ? 'checkmark' : 'paste--file'}
                    keyboardShortcut='V'
                  />
                )
              },
            },
            {
              id: 'delete',
              type: 'label',
              label: t('admin.delete'),
              icon: 'trash-can',
              color: 'destructive/background',
              onClick: () => {
                onOpenChange(false)
                assertIsNonNullable(currentFile)

                const removeFile = (): void => {
                  applyReversibleAction({ type: 'remove-files', files: [currentFile] })
                }

                if (isFileSelected && closestFileWhenDeleting !== undefined) {
                  void navigateToCreateContentId({
                    scopedCreateContentId,
                    nodeId: closestFileWhenDeleting,
                  }).then(() => {
                    removeFile()
                  })
                } else {
                  removeFile()
                }
              },
            },
          ],
    [
      multiSelectMenuItems,
      t,
      startRename,
      isCopyPasteEnabled,
      duplicateFile,
      duplicateFileRecentlyFinished,
      currentFile,
      copyCardMutation,
      copyCardRecentlyFinished,
      fileId,
      pasteRecentlyClicked,
      setPasteRecentlyClicked,
      userId,
      operationState,
      isSelfpaced,
      notif,
      nonExpiringNotif,
      scopedCreateContentId,
      createContentId,
      dispatch,
      parentFolderId,
      isBulkActionsEnabled,
      onOpenChange,
      isFileSelected,
      closestFileWhenDeleting,
      applyReversibleAction,
    ]
  )

  return (
    <AnimatePresence>
      {(isSettingsOpen || visible) && (
        <motion.div
          initial={{ width: avatarStackShown ? '0px' : '24px', opacity: 0 }}
          animate={{ width: '24px', opacity: 1 }}
          exit={{ width: avatarStackShown ? '0px' : '24px', opacity: 0 }}
          transition={{ duration: 0 }}
        >
          <IconMenu
            size='small'
            variant='ghost'
            menuItems={fileMenuItems}
            isOpen={isSettingsOpen}
            onOpenChange={onOpenChange}
            iconId='overflow-menu--vertical'
            onSelect={() => {}}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const AvatarAndFileMenu: React.FC<{
  parentFolderId: FolderId
  fileId: FileId
  startRename: () => void
  nodeRef: React.RefObject<HTMLElement>
  animateAvatars: boolean
  permission: ContentPermission
  isFileSelected: boolean
}> = ({ parentFolderId, fileId, startRename, nodeRef, animateAvatars, permission, isFileSelected }) => {
  const userId = useSelector(selectUserId)

  const userIds = useSelector(
    state =>
      selectNodeCollaborators(state, fileId)
        .map(user => user.uuid)
        .filter(id => id !== userId),
    _.isEqual
  )
  return (
    <>
      <UserIdAvatars userIds={userIds} animateAvatars={animateAvatars} />
      {permission === 'edit' && (
        <FileMenu
          anchorElement={nodeRef.current}
          parentFolderId={parentFolderId}
          fileId={fileId}
          startRename={startRename}
          avatarStackShown={userIds.length > 0}
          isFileSelected={isFileSelected}
        />
      )}
    </>
  )
}

type DndContainerProps = {
  parentFolderId: FolderId
  file: File
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  isRenaming: boolean
  title: string
  iconId: IconId
  copyCardMutation: ReturnType<typeof useCopyCardToClipboard>
  duplicateCardMutation: ReturnType<typeof useDuplicateFileCreatedByUser>
  selectionCount: number
  multiSelectionContainsCurrentNode: boolean
}

const DndContainer = React.forwardRef<HTMLDivElement | null, DndContainerProps>(
  (
    {
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
      parentFolderId,
      file,
      isRenaming,
      iconId,
      title,
      copyCardMutation,
      duplicateCardMutation,
      selectionCount,
      multiSelectionContainsCurrentNode,
    },
    ref
  ) => {
    const fileId = file.id
    const { createContentId } = useCreatePageContext()
    const { nodeId: currentNodeId } = useCreatePageNodeIdContext()
    const { permission } = useCreatePageYDocContext()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [indicateBelow, setIndicateBelow] = useState(false)

    const flexibleContentNodes = useSelector(state => selectFlexibleContentNodes(state, createContentId))
    const parentFolders = useSelector(state => selectParentFolders(state, createContentId, fileId))
    const isBulkActionsEnabled = useIsEnabledMultiSelection()
    const { multiSelectionCopy, multiSelectionCopyLoading } = useMultiSelectionCopy()

    const isCurrentItem = fileId === currentNodeId
    const userId = useSelector(state => selectUserId(state))
    const { operationState, scopedCreateContentId } = useCreatePageContext()
    const currentFile = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))
    const isSelfpaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
    const nodeRef = useRef<HTMLDivElement | null>(null)
    const isCopyPasteEnabled = isCopyPasteSupported()
    const notif = useNotif()
    const nonExpiringNotif = useNonExpiringNotif()
    const { containsSelection } = useMultiSelection()

    const { focusedSpeakerNote, currentTab } = useSpeakerNoteContext()

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (isCurrentItem || (isBulkActionsEnabled && multiSelectionContainsCurrentNode)) {
        // Paste
        if (
          isMetaKey(event) &&
          event.key === 'v' &&
          !isRenaming &&
          isCopyPasteEnabled &&
          currentTab === 'Outline'
        ) {
          event.preventDefault()
          event.stopPropagation()
          if (userId !== undefined) {
            void onPaste({
              operationState,
              userId,
              isSelfpacedContent: isSelfpaced,
              notif,
              nonExpiringNotif,
              t,
              scopedCreateContentId,
              createContentId,
              dispatch,
              folderId: parentFolderId,
              nextTo: fileId,
              isBulkActionsEnabled,
            })
          }
        }
      }
      if (isCurrentItem) {
        // Duplicate
        if (isMetaKey(event) && event.key === 'd') {
          event.preventDefault() // Dont' jump
          assertIsNonNullable(currentFile)
          if (duplicateCardMutation.isPending) {
            return
          }
          duplicateCardMutation.mutate({ originalFile: currentFile })
        }
        // Single file Copy
        else if (
          isMetaKey(event) &&
          event.key === 'c' &&
          !isRenaming &&
          isCopyPasteEnabled &&
          currentTab === 'Outline'
        ) {
          event.preventDefault() // Dont' jump
          if (copyCardMutation.isPending) {
            return
          }

          copyCardMutation.mutate(fileId)
        }
      } else if (isBulkActionsEnabled && multiSelectionContainsCurrentNode) {
        // Copy
        if (
          isMetaKey(event) &&
          event.key === 'c' &&
          !isRenaming &&
          isCopyPasteEnabled &&
          currentTab === 'Outline'
        ) {
          event.preventDefault() // Dont' jump
          if (selectionCount > 1) {
            if (multiSelectionCopyLoading) {
              return
            }
            multiSelectionCopy()
          }
        }
      }
    }

    const [{ isDragging }, handleRef, dragRef] = useEditorSidebarDrag({
      canEdit: permission === 'edit',
      fileId,
      file,
      focusedSpeakerNote,
      parentFolderId,
      title,
      iconId,
      createContentId,
    })

    const targetBoundingBox = nodeRef.current?.getBoundingClientRect()
    const [{ isOver, canDrop }, dropRef] = useEditorSidebarDrop({
      dropOnNodeId: fileId,
      parentFolders,
      flexibleContentNodes,
      targetBoundingBox,
      indicateBelow,
      setIndicateBelow,
      createContentId,
      parentFolderId,
    })

    dragRef(dropRef(handleRef(nodeRef)))
    const isRoot = parentFolderId === 'folder:root'

    return (
      <DroppableNode
        id={fileId}
        onKeyDown={onKeyDown}
        tabIndex={0}
        draggingOver={isOver && canDrop}
        isDragging={isDragging}
        $isCurrentItem={isCurrentItem}
        $isSelected={containsSelection(fileId)}
        indicateBelow={indicateBelow}
        ref={current => {
          nodeRef.current = current
          if (ref === null) return
          else if (typeof ref === 'function') ref(current)
          else ref.current = current
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isRoot={isRoot}
      >
        {children}
      </DroppableNode>
    )
  }
)

const StyledEditableText = styled(EditableText)<{ $isCurrentItem: boolean; contentEditable: boolean }>`
  color: ${p => token(p.$isCurrentItem ? 'foreground/primary' : 'foreground/secondary')};

  ${p =>
    !p.contentEditable &&
    css`
      user-select: none; /* We don't want to highlight the text when we drag or select the item */
    `}
`

const FileTitle = React.forwardRef<
  HTMLParagraphElement,
  {
    isCurrentItem: boolean
    fileId: FileId
    title: string
    contentEditable: boolean
    setContentEditable: (_: boolean) => void
  }
>(({ isCurrentItem, fileId, title, contentEditable, setContentEditable }, ref) => {
  const { operationState } = useCreatePageContext()

  const dispatchRenameFile = useCallback(
    (updatedValue: string): void =>
      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          file.title = updatedValue
        },
      }),
    [fileId, operationState]
  )

  return (
    <StyledEditableText
      bold
      $isCurrentItem={isCurrentItem}
      ref={ref}
      contentEditable={contentEditable}
      setContentEditable={setContentEditable}
      value={title}
      onRename={dispatchRenameFile}
    />
  )
})

const NodeIconContainer = styled.div<{
  fileTheme?: Theme
  $background?: string | undefined
  color?: IconProps['color']
  $isSelected?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid ${color(palette.primitives.black).opacity(0.05).toString()};
  padding: 2px;
  margin-right: 6px;
  width: 16px;
  height: 16px;

  ${p => {
    const theme: Theme = p.fileTheme ?? p.theme
    return css`
      && {
        background-color: ${resolveThemeColor(theme.home.backgroundColor)};

        svg {
          color: ${resolveThemeColor(p.color ?? theme.home.textColor)};
        }
      }
    `
  }}

  ${p =>
    p.$background !== undefined &&
    css`
      background-image: url('${p.$background}');
      background-size: cover;
    `}

  ${p =>
    p.$isSelected === true &&
    css`
      && {
        background-color: ${token('button/background')};
        background-image: none;

        svg {
          color: ${token('button/foreground')};
        }
      }
    `}
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  &,
  * {
    height: inherit;
    width: inherit;
  }
`

const EditableContainer = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
`

const GeneratingAvatarSpinner = styled(LoaderAnimation).attrs({ size: 14 })`
  overflow: unset;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BrowseComponent: React.FC<{
  parentFolderId: FolderId
  fileId: FileId
  file: File
}> = ({ parentFolderId, fileId, file }) => {
  const { t } = useTranslation()
  const { scopedCreateContentId, createContentId, operationState } = useCreatePageContext()
  const { nodeId: currentFileId } = useCreatePageNodeIdContext()
  const { permission } = useCreatePageYDocContext()
  const nodeRef = useRef<HTMLDivElement>(null)
  const [animateAvatars, setAnimateAvatars] = useState(false)
  const { addSelection, removeSelection, containsSelection, selectionCount, selectedFileIds } =
    useMultiSelection()
  const flatSelectionInfoList = useSelector(state => selectFlatSelectionInfoList(state, createContentId))
  const isLive = ScopedCreateContentId.isLiveContentId(scopedCreateContentId)

  const { isEnabled: copyCardRecentlyFinished, setTrue: setCopyCardRecentlyFinished } =
    useResetBooleanAfterDelay()
  const copyCardMutation = useCopyCardToClipboard({
    operationState,
    contentId: createContentId,
    onSuccess: setCopyCardRecentlyFinished,
  })

  const { isEnabled: duplicateCardRecentlyFinished, setTrue: setDuplicateCardRecentlyFinished } =
    useResetBooleanAfterDelay()
  const duplicateCardMutation = useDuplicateFileCreatedByUser({
    folderId: parentFolderId,
    nextTo: fileId,
    onSuccess: setDuplicateCardRecentlyFinished,
  })

  const cardActionPending = copyCardMutation.isPending || duplicateCardMutation.isPending
  const cardActionRecentlyFinished = copyCardRecentlyFinished || duplicateCardRecentlyFinished

  const [contentEditable, setContentEditable] = useState(false)
  const inputRef = useRef<HTMLParagraphElement>(null)

  const { setShouldAutoFocus } = useEditorFocusContext()

  const startRename = useCallback((): void => {
    setContentEditable(true)
    focusInput(inputRef)
  }, [])

  const { currentTab } = useSpeakerNoteContext()

  const { yDoc } = useCreatePageYDocContext()
  const fileTitle = useFileTitle(yDoc, file)

  useScrollToView(nodeRef, { shouldScroll: fileId === currentFileId })
  const isCurrentItem = fileId === currentFileId

  const isEditingTitle = permission === 'edit' && contentEditable

  const loadNamespaced = useLoadNamespacedCourseAssets()
  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: createContentId }),
    [createContentId]
  )

  const baseTheme = useTheme()
  const fileTheme = useThemeForFile(file)
  const fileBackground = resolveBackgroundImageUrl(
    file.backgroundImage,
    { width: 100 },
    loadNamespaced,
    assetContext
  )
  const sidebarContainer = useAtomValue(sidebarContainerAtom)

  const isGeneratingAvatar = useAtomValue(isGeneratingAvatarsAtom)[fileId] ?? false

  const handleSelection = useCallback(
    (e: React.MouseEvent): void => {
      if (isMetaKey(e)) {
        // You can't remove the current file from the selection
        if (currentFileId === fileId) {
          return
        }
        if (containsSelection(fileId)) {
          return removeSelection(fileId)
        }

        return addSelection([{ fileId, info: { file, parentFolderId } }])
      } else if (e.shiftKey) {
        // For some reason, something is firing at the top level, resulting in it resetting the multi selection (some navigation running?)
        e.preventDefault()
        e.stopPropagation()

        // find all items since the last selection
        const lastSelectionId = selectedFileIds[selectionCount - 1]

        if (lastSelectionId === undefined) {
          return
        }

        const flatNodeIdList = flatSelectionInfoList.map(n => n.file.id)

        let startIndex = flatNodeIdList.indexOf(lastSelectionId) + 1
        let endIndex = flatNodeIdList.indexOf(fileId) + 1

        // Go backwards
        if (startIndex > endIndex) {
          const carryOver = startIndex
          startIndex = endIndex - 1 // we want to include the starting element this time
          endIndex = carryOver
        }

        const idsToAdd = flatNodeIdList.slice(startIndex, endIndex)

        const newItems = flatSelectionInfoList
          .filter(n => idsToAdd.includes(n.file.id))
          .map(info => ({
            fileId: info.file.id,
            info,
          }))

        return addSelection(newItems)
      }

      setShouldAutoFocus(false)
      void navigateToCreateContentId({ scopedCreateContentId, nodeId: fileId })
    },
    [
      addSelection,
      containsSelection,
      currentFileId,
      file,
      fileId,
      flatSelectionInfoList,
      parentFolderId,
      removeSelection,
      scopedCreateContentId,
      selectedFileIds,
      selectionCount,
      setShouldAutoFocus,
    ]
  )

  const isMultiSelected = containsSelection(fileId) && selectionCount > 1

  return (
    <>
      <DndContainer
        duplicateCardMutation={duplicateCardMutation}
        copyCardMutation={copyCardMutation}
        parentFolderId={parentFolderId}
        file={file}
        onClick={handleSelection}
        onMouseEnter={() => setAnimateAvatars(true)}
        onMouseLeave={() => setAnimateAvatars(false)}
        ref={nodeRef}
        isRenaming={contentEditable}
        title={fileTitle}
        iconId={getFileIcon(file.data, isLive ? 'live' : 'self-paced')}
        selectionCount={selectionCount}
        multiSelectionContainsCurrentNode={containsSelection(fileId)}
      >
        <Tooltip title={isGeneratingAvatar ? t('ai-narrations.generating-avatar') : undefined}>
          <NodeIconContainer
            fileTheme={isMultiSelected ? baseTheme : fileTheme}
            $isSelected={isMultiSelected}
            $background={fileBackground}
          >
            {isMultiSelected ? (
              <Icon size='size-16' iconId='checkbox--checkmark' color='white' />
            ) : cardActionPending ? (
              <StyledLoadingSpinner size='10' padding='none' />
            ) : cardActionRecentlyFinished ? (
              <Icon size='size-10' iconId='checkmark' color='greenBright' />
            ) : isGeneratingAvatar ? (
              <GeneratingAvatarSpinner />
            ) : (
              <Icon size='size-10' iconId={getFileIcon(file.data, isLive ? 'live' : 'self-paced')} />
            )}
          </NodeIconContainer>
        </Tooltip>

        <>
          <Tooltip title={isEditingTitle ? undefined : fileTitle} container={sidebarContainer ?? undefined}>
            <EditableContainer>
              <FileTitle
                isCurrentItem={isCurrentItem}
                fileId={fileId}
                title={fileTitle}
                ref={inputRef}
                contentEditable={isEditingTitle}
                setContentEditable={setContentEditable}
              />
            </EditableContainer>
          </Tooltip>

          <Debug>
            {file.data.type === 'external-notepad' ? (
              <Text size='technical' color='foreground/muted'>
                [external]
              </Text>
            ) : file.data.type === 'notepad' ? (
              <Text size='technical' color='foreground/muted'>
                [legacy]
              </Text>
            ) : null}
          </Debug>

          {!isEditingTitle && (
            <AvatarAndFileMenu
              nodeRef={nodeRef}
              parentFolderId={parentFolderId}
              fileId={fileId}
              startRename={startRename}
              animateAvatars={animateAvatars}
              permission={permission}
              isFileSelected={isCurrentItem}
            />
          )}

          {fileId === currentFileId && permission === 'edit' && (
            <>
              <ShortcutMenu.Action
                label={{ type: 'untranslated', value: 'Rename card' }}
                run={startRename}
                permission='ACCESS_EDITOR'
                iconId='edit'
                group='create'
              />
              <GenerateCardsShortcuts fileId={fileId} folderId={parentFolderId} />
            </>
          )}
        </>
      </DndContainer>
      {currentTab === 'Notes' && <CreateSpeakerNoteInput editable={permission === 'edit'} file={file} />}
    </>
  )
}

export const useFileSlateDoc = (yDoc: Y.Doc, file: File): SlateDocument | undefined => {
  const [slateDoc, setSlateDoc] = useDebouncedState<SlateDocument | undefined>(undefined, {
    wait: 1000,
    leading: true,
  })

  useEffect(() => {
    if (!isSlateFile(file)) return

    function onDocumentChanged(): void {
      const slateDoc = getSlateDocument(yDoc, file.id)
      setSlateDoc(slateDoc)
    }

    onDocumentChanged()

    const slateSharedType = getSlateDocumentSharedType(yDoc, file.id)
    slateSharedType.observeDeep(onDocumentChanged)
    return () => slateSharedType.unobserveDeep(onDocumentChanged)
  }, [file, setSlateDoc, yDoc])

  return slateDoc
}

const FilePreview: React.FC<{
  file: File
}> = ({ file }) => {
  const { yDoc } = useCreatePageYDocContext()
  const slateDoc = useFileSlateDoc(yDoc, file)
  const { scopedCreateContentId } = useCreatePageContext()

  if (!slateDoc) return null

  return (
    <View
      padding='xxsmall'
      onClick={() => {
        void navigateToCreateContentId({ scopedCreateContentId, nodeId: file.id })
      }}
    >
      <ContentPreview
        width={200}
        slateDocument={slateDoc}
        file={file}
        assetContext={{ type: 'course', courseId: ScopedCreateContentId.extractId(scopedCreateContentId) }}
      />
    </View>
  )
}

export const BrowseFile: React.FC<{
  parentFolderId: FolderId
  fileId: FileId
}> = props => {
  const { createContentId } = useCreatePageContext()
  const file = useSelector(state => selectFlexibleContentFile(state, createContentId, props.fileId))
  const contentPreviewToggled = useAtomValue(ContentPreviewEnabledAtom)
  const debugMode = useIsDebugMode()
  const contentPreviewEnabled = contentPreviewToggled && debugMode

  if (!file) return <div>No such file {props.fileId}</div>

  if (contentPreviewEnabled) return <FilePreview file={file}></FilePreview>

  return <BrowseComponent {...props} file={file}></BrowseComponent>
}
