import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/components/util/show-intercom-launcher'
import { AddTeamspacePanel } from 'sierra-client/features/teamspace/components/modals/panels/add-panel'
import { BrowseTeamspacesPanel } from 'sierra-client/features/teamspace/components/modals/panels/browse-panel'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ClosePanelButton, Panel } from 'sierra-ui/components'

const noop = (): void => {}

export const TeamspaceModal: React.FC<{
  trigger: ReactNode
  open: boolean
  onClose: () => void
  defaultPane: 'browse' | 'add'
}> = ({ open, onClose, defaultPane, trigger }) => {
  const { t } = useTranslation()
  useOverrideIntercomLauncherVisibility(open ? 'hidden' : 'default')

  const [currentPane, setCurrentPane] = useState<'browse' | 'add'>(defaultPane)

  useEffect(() => {
    setCurrentPane(defaultPane)
  }, [defaultPane])

  const handleClose = useCallback((): void => {
    setCurrentPane(defaultPane)
    onClose()
  }, [defaultPane, onClose])

  const panelComponent = useMemo(() => {
    switch (currentPane) {
      case 'browse':
        return <BrowseTeamspacesPanel onClickNew={() => setCurrentPane('add')} onClose={onClose} />

      case 'add':
        return (
          <AddTeamspacePanel
            onClose={handleClose}
            onCancel={defaultPane === 'add' ? onClose : () => setCurrentPane(defaultPane)}
          />
        )
    }
    currentPane satisfies never
  }, [currentPane, defaultPane, onClose, handleClose])

  return (
    <Panel
      trigger={trigger}
      onOpenChange={noop}
      size={{ width: 656 }}
      animation={'slide-right'}
      open={open}
      onClose={handleClose}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={handleClose} ariaLabel={t('dictionary.close')} />
      {open && panelComponent}
    </Panel>
  )
}
