import React, { useMemo, useState } from 'react'
import { Column, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { ContentRow } from 'sierra-client/components/common/content-elements'
import { RouterLink } from 'sierra-client/components/common/link'
import { Menu } from 'sierra-client/components/common/menu'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import {
  TableMediumSearchHeader,
  TableMediumSearchTrigger,
} from 'sierra-client/components/table/table-medium'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import * as format from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { BetweenContainer, InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { mapCourseToCsv, useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { CourseSummary } from 'sierra-domain/api/admin'
import { CourseId } from 'sierra-domain/api/nano-id'
import { MUIBox, MUIMenuItem } from 'sierra-ui/mui'
import { Spacer, Text } from 'sierra-ui/primitives'

type CoursesTableProps = {
  courseIds: CourseId[]
}

export const CoursesTable: React.FC<CoursesTableProps> = ({ courseIds }) => {
  const { t } = useTranslation()

  const { courses } = useManageCourses(courseIds)
  const { timeFormatter } = format.useTimeFormatter()
  const [focusedCourseId, setFocusedCourseId] = useState<string | undefined>(undefined)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const coursesTableColumns: Array<Column<CourseSummary>> = useMemo(
    () => [
      {
        Header: p => <SortableHeader label={t('table.name')} {...p} />,
        accessor: 'title',
        Cell: p => {
          const { title, image, kind, isCourseEdition, courseId } = p.row.original
          return (
            <ContentRow
              image={image}
              title={title}
              contentType='course'
              courseKind={kind}
              isCourseEdition={isCourseEdition}
              assetContext={{ type: 'course', courseId: CourseId.parse(courseId) }}
            />
          )
        },
        width: '50%',
      },
      {
        Header: p => <SortableHeader label={t('table.duration')} {...p} />,
        accessor: 'timeTotal',
        Cell: ({ row }) => <>{timeFormatter(row.original.timeTotal) ?? '-'}</>,
        width: '20%',
      },
      {
        Header: p => <SortableHeader label={t('table.rating')} {...p} />,
        accessor: 'rating',
        Cell: ({ row }) => (
          <>{row.original.rating === undefined ? null : `${row.original.rating.toFixed(1)} / 5`}</>
        ),
        width: '20%',
      },
      {
        accessor: 'courseId',
        width: '10%',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: ({ row }) => (
          <MUIBox display='flex' justifyContent='flex-end' onClick={e => e.stopPropagation()}>
            <Menu
              onTriggerClick={() => setFocusedCourseId(row.original.courseId)}
              onClose={() => setFocusedCourseId(undefined)}
            >
              <RouterLink href={`/manage/courses/${row.original.courseId}`}>
                <MUIMenuItem>
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                    {t('manage.view-details')}
                  </Text>
                </MUIMenuItem>
              </RouterLink>
            </Menu>
          </MUIBox>
        ),
      },
    ],
    [t, timeFormatter]
  )

  const tableInstance = useTable<CourseSummary>(
    {
      data: courses,
      columns: coursesTableColumns,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy
  )

  return (
    <>
      <SmallTableScrollContainer scrollToTopDeps={[tableInstance.state.globalFilter]}>
        <Table
          tableInstance={tableInstance}
          getRowProps={row => ({
            onClick: () => {
              void getGlobalRouter().navigate({ to: `/manage/courses/${row.original.courseId}` })
            },
            isFocused: row.original.courseId === focusedCourseId,
          })}
          headerOverride={
            isSearchOpen ? (
              <TableMediumSearchHeader
                tableInstance={tableInstance}
                searchPlaceholder={t('search.courses')}
                onClose={() => {
                  setIsSearchOpen(false)
                  tableInstance.setGlobalFilter('')
                }}
              />
            ) : undefined
          }
          small
        />
        {tableInstance.rows.length === 0 && (
          <InfiniteScrollMessage padding='medium' text={t('manage.courses.no-results')} />
        )}
      </SmallTableScrollContainer>
      <Spacer size='small' />
      <BetweenContainer>
        <div></div>
        <ExportCSVIconButton
          fetchCsvData={() => courses.map(mapCourseToCsv)}
          filename={t('admin.author.courses')}
        />
      </BetweenContainer>
    </>
  )
}
