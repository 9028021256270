import { useAtom, useAtomValue } from 'jotai'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  CenterHeaderContent,
  HeaderViewContainer,
  LeftHeaderContent,
  RightHeaderContent,
  SimpleTextTitle,
} from 'sierra-client/components/common/header-view'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { getFlag } from 'sierra-client/config/global-config'
import { useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar'
import { sanaNowSidebarAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { GlobalSidebarIdAtom, GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar/atoms'
import { QRCodeButton, QuickJoinQRModal } from 'sierra-client/features/sana-now/header/QR-code-modal'
import {
  NowButtonCSS,
  NowButtonCSSProps,
  TransparentNowIconButton,
} from 'sierra-client/features/sana-now/header/buttons'
import { ChatButton } from 'sierra-client/features/sana-now/header/chat-button'
import { JoinVideoCallButton } from 'sierra-client/features/sana-now/header/join-video-call-button'
import { ParticipantsButton } from 'sierra-client/features/sana-now/header/participants-button'
import { PictureInPictureToggle } from 'sierra-client/features/sana-now/header/picture-in-picture-toggle'
import { useNowSessionContext } from 'sierra-client/features/sana-now/hooks/use-now-session-context'
import { DebugPresencesButton } from 'sierra-client/features/sana-now/presence/debug-presence-button'
import { TimerCountdown } from 'sierra-client/features/sana-now/timer'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import {
  ButtonStyles,
  ButtonStylesProps,
  usePrimitiveButtonPseudoStyles,
} from 'sierra-ui/primitives/button/button'
import styled from 'styled-components'

const EnablePointerEvents = styled.div`
  pointer-events: all;
`
const MenuButton = styled.div<NowButtonCSSProps & ButtonStylesProps>`
  gap: 0.5rem;
  ${ButtonStyles}
  ${NowButtonCSS};
`

const HeaderLeftContent: FC = (): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const [openSidebar] = useAtom(GlobalSidebarOpenAtom)
  const [sanaNowOpenSidebar, setOpenSanaNowSidebar] = useAtom(sanaNowSidebarAtom)
  const { toggle: toggleMobileSidebar } = useToggleMobileGlobalSidebar()
  const isMobile = useIsMobile()
  const globalSidebarId = useAtomValue(GlobalSidebarIdAtom)
  const { t } = useTranslation()

  const pseudoStyles = usePrimitiveButtonPseudoStyles('secondary')
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  useEffect(() => {
    setOpenSanaNowSidebar('collapsed')
    return () => {
      setOpenSanaNowSidebar(undefined)
    }
  }, [setOpenSanaNowSidebar])

  const onClick = useCallback(() => {
    if (isMobile) {
      toggleMobileSidebar()
    } else {
      setOpenSanaNowSidebar(sanaNowOpenSidebar === 'collapsed' ? 'sticky' : 'collapsed')
    }
  }, [isMobile, setOpenSanaNowSidebar, sanaNowOpenSidebar, toggleMobileSidebar])

  return (
    <EnablePointerEvents>
      {isMobile ? (
        <TransparentNowIconButton
          aria-label={t('sidebar.toggle-sidebar-label')}
          aria-expanded={openSidebar}
          aria-controls={globalSidebarId}
          $backgroundColor={backgroundColor}
          iconId={'menu'}
          onClick={onClick}
        />
      ) : (
        <MenuButton
          aria-label={t('sidebar.toggle-sidebar-label')}
          aria-expanded={openSidebar}
          $backgroundColor={backgroundColor}
          role='button'
          $grow={false}
          aria-controls={globalSidebarId}
          $pseudoStyles={pseudoStyles}
          onClick={onClick}
        >
          <Icon color={'currentColor'} iconId='menu' size='size-14' />

          <SimpleTextTitle lineLength={15} bold color={'currentColor'}>
            {liveSession.data.title}
          </SimpleTextTitle>
        </MenuButton>
      )}
    </EnablePointerEvents>
  )
}

const HeaderRightContent: FC = (): JSX.Element => {
  const liveSession = useNowSessionContext()
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()
  const [openJoinModal, setOpenJoinModal] = useState(false)

  const joinModalShownOnceForFacilitator = useRef(false)
  useEffect(() => {
    if (
      isFacilitator &&
      liveSession.data.type === 'not-scheduled' &&
      !joinModalShownOnceForFacilitator.current
    ) {
      setOpenJoinModal(true)
      joinModalShownOnceForFacilitator.current = true
    }
  }, [isFacilitator, liveSession.data.type])

  const pictureInPictureEnabled = getFlag('sana-now-picture-in-picture')

  const isMobile = useIsMobile()
  const debugEnabled = useIsDebugMode()

  return (
    <EnablePointerEvents>
      <View gap={isMobile ? '8' : '24'}>
        <View gap='8'>
          {/* Temporary fix for hiding buttons in mobile header */}
          {!isMobile && <QRCodeButton onClick={() => setOpenJoinModal(prev => !prev)} />}
          {liveSession.data.videoCallSetting.type !== 'none' && <JoinVideoCallButton />}
        </View>
        <View>
          <ParticipantsButton />
          <ChatButton />
          {pictureInPictureEnabled && <PictureInPictureToggle />}
        </View>
        {debugEnabled && <DebugPresencesButton />}
      </View>
      <QuickJoinQRModal open={!isMobile && openJoinModal} onClose={() => setOpenJoinModal(false)} />
    </EnablePointerEvents>
  )
}

const HeaderCenterContent: FC = (): JSX.Element => {
  return (
    <EnablePointerEvents>
      <TimerCountdown />
    </EnablePointerEvents>
  )
}

const TransparentHeaderViewContainer = styled(HeaderViewContainer)`
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  pointer-events: none;
  flex-direction: row;
  overflow-x: auto;
`

type HeaderViewProps = {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  centerContent?: React.ReactNode
  border?: boolean
  className?: string
  mobileOffset?: string
  tabletOffset?: string
}

const HeaderView: React.FC<HeaderViewProps> = ({ leftContent, rightContent, centerContent, className }) => {
  return (
    <TransparentHeaderViewContainer className={className}>
      <LeftHeaderContent $show={true}>{leftContent}</LeftHeaderContent>
      <CenterHeaderContent $show={true}>{centerContent}</CenterHeaderContent>
      <RightHeaderContent $show={true}>{rightContent}</RightHeaderContent>
    </TransparentHeaderViewContainer>
  )
}

export const Header: React.FC = () => {
  return (
    <HeaderView
      rightContent={<HeaderRightContent />}
      leftContent={<HeaderLeftContent />}
      centerContent={<HeaderCenterContent />}
    />
  )
}
