import * as React from 'react'
import * as U from 'sierra-client/lib/tabular/components/util'
import { TableBody, TableHeader } from 'sierra-client/lib/tabular/provider/components/basic/components'
import { InViewportLoadMore } from 'sierra-client/lib/tabular/provider/components/common'
import { scrollViewStyles } from 'sierra-ui/primitives'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
`

// If height is not fixed, allow parent to scroll
export const TableFlowContainer = styled.div<{ $width: number; $height?: number }>`
  ${props => (isDefined(props.$height) ? scrollViewStyles : ``)}
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
`

type BasicTabularProps = {
  fixedHeight?: boolean
  disableLoadMore?: boolean
  footer?: React.ReactNode
}

export const BasicTabular: React.FC<BasicTabularProps> = ({
  fixedHeight = true,
  disableLoadMore = false,
  footer = null,
}) => {
  return React.useMemo(
    () => (
      <U.WithParentSize>
        {({ width, height, setScrollRef, scrollRef }) => (
          <TableFlowContainer $width={width} $height={fixedHeight ? height : undefined} ref={setScrollRef}>
            <Table>
              <TableHeader />
              <TableBody containerRef={scrollRef} />
              {footer}
            </Table>
            {!disableLoadMore && <InViewportLoadMore />}
          </TableFlowContainer>
        )}
      </U.WithParentSize>
    ),
    [fixedHeight, footer, disableLoadMore]
  )
}
