import { APP_LANGUAGE_CODES } from 'sierra-client/i18n/app-languages.generated'

/**
 * List of extra languages that can be used to translate courses.
 * Not to be confused with app languages!
 */
const extraTranslationLanguages = [
  'fa', // Farsi
] as const satisfies string[]

/**
 * List of languages that can be used to translate courses.
 * Not to be confused with app languages!
 */
export const COURSE_TRANSLATION_LANGUAGES = [
  ...APP_LANGUAGE_CODES,
  ...extraTranslationLanguages,
] as const satisfies string[]

export type CourseTranslationLanguageCode = (typeof COURSE_TRANSLATION_LANGUAGES)[number]
