import { createFileRoute } from '@tanstack/react-router'

import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, PlainSanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { Button, Heading, Spacer, View } from 'sierra-ui/primitives'
import { min_height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'

const ErrorPageWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 30.625rem;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  ${min_height_100dvh}
`

const RouteComponent = function Custom404(): JSX.Element {
  const { t } = useTranslation()

  return (
    <PlainSanaPage mode='light' headerType='none' page={PageIdentifier.Error()}>
      <ErrorPageWrap>
        <Heading size='h3' bold avoidHanging align='center'>
          {t('content-not-found.heading')}
        </Heading>
        <Spacer size='4' />
        <Heading color={'foreground/secondary'} size='h5' avoidHanging align='center'>
          {t('content-not-found.subheading')}
        </Heading>
        <Spacer />

        <View direction='column'>
          <Button
            type='submit'
            variant='secondary'
            id={'home-button'}
            onClick={() => {
              void getGlobalRouter().navigate({ to: '/' })
            }}
          >
            {t('content-not-found.back-home')}
          </Button>
        </View>
      </ErrorPageWrap>
    </PlainSanaPage>
  )
}

export const Route = createFileRoute('/content-not-found')({
  component: RouteComponent as React.FC,
})
