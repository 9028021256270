import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useMemo, useState } from 'react'
import {
  isContentInAnotherTeamspace,
  isMyContent,
  listTeamspacesQuery,
  useMoveCoursesMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { HeaderButtonColorCSS, HeaderGroupButtonCSS } from 'sierra-client/components/liveV2/header-buttons'
import { teamspaceContentMovedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FolderRow, TeamspaceDataWithEffectiveRole } from 'sierra-domain/api/teamspace'
import { MenuItem } from 'sierra-ui/components'
import styled from 'styled-components'

const StyledIconMenu = styled(IconMenu)`
  cursor: pointer;
  width: 2.25rem;

  ${HeaderButtonColorCSS}
  ${HeaderGroupButtonCSS}
`

export const useMoveTeamspaceMenuItem = (
  courseId: NanoId12,
  includeMyContent: boolean,
  content?: EditableContent | NonEditorTeamspaceCourse,
  onMoveSuccess?: () => void
): {
  moveToTeamspaceMenuItem: MenuItem
  confirmModal: { isOpen: boolean; primaryAction: () => void; close: () => void; title: string; body: string }
} => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notification = useNotif()
  const teamspacesQuery = useQuery(listTeamspacesQuery)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [targetTeamspaceId, setTargetTeamspaceId] = useState<NanoId12 | undefined>(undefined)
  const [targetFolderId, setTargetFolderId] = useState<NanoId12 | undefined>(undefined)
  const [notifBody, setNotifBody] = useState<string | undefined>(undefined)

  const isContentInMyContent = content !== undefined && content.type !== 'path' && isMyContent(content)

  const { mutate } = useMoveCoursesMutation()

  const moveContent = useCallback(
    (
      targetTeamspaceId: NanoId12 | undefined,
      notificationBody: string | undefined,
      targetFolderId: NanoId12 | undefined
    ): void => {
      mutate(
        { courseIds: [courseId], targetTeamspaceId, targetFolderId },
        {
          onSuccess: () => {
            if (notificationBody !== undefined) {
              notification.push({
                type: 'custom',
                level: 'info',
                body: notificationBody,
              })
            }
            void dispatch(
              teamspaceContentMovedLogger({
                teamspaceId: targetTeamspaceId,
                contentId: courseId,
                movedBy: 'itemMenu',
                contentType: 'editable-content',
                parentFolderId: undefined,
              })
            )
            setConfirmModalOpen(false)
            onMoveSuccess?.()
          },
        }
      )
    },
    [courseId, dispatch, mutate, notification, onMoveSuccess]
  )

  const onMove = useCallback(
    (
      targetTeamspaceId: NanoId12 | undefined,
      notificationBody: string | undefined,
      targetFolderId: NanoId12 | undefined
    ): void => {
      if (content && isContentInAnotherTeamspace(content, targetTeamspaceId)) {
        setTargetTeamspaceId(targetTeamspaceId)
        setTargetFolderId(targetFolderId)
        setNotifBody(notificationBody)
        setConfirmModalOpen(true)
        return
      }

      moveContent(targetTeamspaceId, notificationBody, targetFolderId)
    },
    [content, moveContent]
  )

  const getFirstLevelFoldersOfTeamspace = useCallback(
    (teamspace: TeamspaceDataWithEffectiveRole): FolderRow[] => {
      const rootFolder = teamspace.folderRows.find(it => it.parentFolderId === undefined)
      if (rootFolder === undefined) return []

      const firstLevelFoldersOfTeamspace = teamspace.folderRows
        .filter(it => it.parentFolderId === rootFolder.id)
        .map(
          it =>
            ({
              type: 'folder',
              id: it.id,
              displayName: it.displayName,
              createdAt: it.createdAt,
              updatedAt: it.updatedAt,
              parentFolderId: it.parentFolderId,
            }) as FolderRow
        )

      return firstLevelFoldersOfTeamspace
    },
    []
  )

  const getFolderMenuItems = useCallback(
    (folders: FolderRow[], teamspace: TeamspaceDataWithEffectiveRole): MenuItem[] => {
      const rootFolder = teamspace.folderRows.find(it => it.parentFolderId === undefined)

      const isContentInRootFolder =
        teamspace.courseRows.find(it => it.courseId === courseId && it.folderId === rootFolder?.id) !==
        undefined

      const rootFolderMenuItem: MenuItem = {
        id: `${teamspace.teamspaceRow.id}-rootfolder`,
        type: 'label',
        label: `${t('teamspace.move.move-to')} "${teamspace.teamspaceRow.displayName}"`,
        icon: 'folder--shared',
        selected: isContentInRootFolder,
        onClick: () => {
          if (isContentInRootFolder) {
            notification.push({
              type: 'custom',
              level: 'info',
              body: t('teamspace.move.already-in-teamspace') + ' ' + teamspace.teamspaceRow.displayName,
            })
            return
          }
          onMove(
            teamspace.teamspaceRow.id,
            t('teamspace.move.successful') + ' ' + teamspace.teamspaceRow.displayName,
            undefined
          )
        },
      }

      const folderItems: MenuItem[] = folders.map(folder => {
        const isContentAlreadyInFolder =
          teamspace.courseRows.find(it => it.courseId === courseId && it.folderId === folder.id) !== undefined

        return {
          id: folder.id,
          type: 'label',
          label: folder.displayName,
          icon: 'folder',
          selected: isContentAlreadyInFolder,
          onClick: () => {
            if (isContentAlreadyInFolder) {
              notification.push({
                type: 'custom',
                level: 'info',
                body: t('teamspace.move.already-in-teamspace') + ' ' + folder.displayName,
              })
              return
            }

            onMove(
              teamspace.teamspaceRow.id,
              t('teamspace.move.successful') +
                ' ' +
                folder.displayName +
                ' ' +
                t('dictionary.in') +
                ' ' +
                teamspace.teamspaceRow.displayName,
              folder.id
            )
          },
        }
      })

      return [rootFolderMenuItem, ...folderItems]
    },
    [courseId, notification, onMove, t]
  )

  const getTeamspaceMenuItem = useCallback(
    (teamspace: TeamspaceDataWithEffectiveRole): MenuItem => {
      const folders = getFirstLevelFoldersOfTeamspace(teamspace)

      if (folders.length > 0) {
        return {
          type: 'nested',
          label: teamspace.teamspaceRow.displayName,
          id: teamspace.teamspaceRow.id,
          menuItems: getFolderMenuItems(folders, teamspace),
        }
      }

      return {
        id: teamspace.teamspaceRow.id,
        type: 'label',
        label: teamspace.teamspaceRow.displayName,
        selected: teamspace.courseRows.some(it => it.courseId === courseId),
        onClick: () => {
          if (teamspace.courseRows.some(it => it.courseId === courseId)) {
            notification.push({
              type: 'custom',
              level: 'info',
              body: t('teamspace.move.already-in-teamspace') + ' ' + teamspace.teamspaceRow.displayName,
            })
            return
          }
          onMove(
            teamspace.teamspaceRow.id,
            t('teamspace.move.successful') + ' ' + teamspace.teamspaceRow.displayName,
            undefined
          )
        },
      }
    },
    [courseId, getFirstLevelFoldersOfTeamspace, getFolderMenuItems, notification, onMove, t]
  )

  const privateMenuItems = useMemo<MenuItem[]>(
    () => [
      {
        id: 'private',
        type: 'label',
        label: t('author.content.my-content'),
        icon: 'locked',
        selected: isContentInMyContent,
        hidden: content?.highestCollaboratorRole !== 'owner',
        onClick: () => {
          if (isContentInMyContent) {
            notification.push({
              type: 'custom',
              level: 'info',
              body: t('teamspace.move.already-in-teamspace') + ' ' + t('author.content.my-content'),
            })
            return
          }

          onMove(undefined, t('teamspace.move.successful') + ' ' + t('author.content.my-content'), undefined)
        },
      },
    ],
    [isContentInMyContent, notification, onMove, t, content]
  )

  const teamspacesMenuItems = useMemo<MenuItem[]>(
    () =>
      teamspacesQuery.data !== undefined
        ? teamspacesQuery.data
            .filter(it => {
              const userRole = it.teamspaceRow.effectiveRole
              if (userRole === undefined) return false
              return isCollaboratorRoleAboveOrEqual(userRole, 'editor')
            })
            .map(teamspace => {
              return getTeamspaceMenuItem(teamspace)
            })
        : [],
    [getTeamspaceMenuItem, teamspacesQuery.data]
  )

  const teamspacesSubMenuItems: MenuItem[] = includeMyContent
    ? privateMenuItems.concat(teamspacesMenuItems)
    : teamspacesMenuItems

  const moveItem: MenuItem = {
    type: 'nested',
    label: t('author.content.move'),
    id: 'teamspace',
    icon: 'folder--move-to',
    menuItems: teamspacesSubMenuItems,
    withSearch: true,
  }

  const close = (): void => {
    setConfirmModalOpen(false)
  }

  const primaryAction = (): void => {
    moveContent(targetTeamspaceId, notifBody, targetFolderId)
  }

  return {
    moveToTeamspaceMenuItem: moveItem,
    confirmModal: {
      isOpen: confirmModalOpen,
      close,
      primaryAction,
      title: t('teamspace.move-content.title'),
      body: t('teamspace.move-content.body'),
    },
  }
}

export const MenuItemWithMoveToTeamspaces: React.FC<{
  defaultItems: MenuItem[]
  content: EditableContent | NonEditorTeamspaceCourse
  inTopBar?: boolean
  onMoveSuccess?: () => void
}> = ({ defaultItems, content, inTopBar = false, onMoveSuccess }) => {
  const { t } = useTranslation()
  const { moveToTeamspaceMenuItem, confirmModal } = useMoveTeamspaceMenuItem(
    NanoId12.parse(content.id),
    true,
    content,
    onMoveSuccess
  )
  const menuItems = [...defaultItems.slice(0, 3), moveToTeamspaceMenuItem, ...defaultItems.slice(3)]

  return (
    <>
      {inTopBar ? (
        <StyledIconMenu
          items={menuItems}
          color='currentColor'
          closeOnPick
          aria-label={t('admin.author.more-content-actions')}
        />
      ) : (
        <IconMenu
          items={menuItems}
          color='foreground/muted'
          closeOnPick
          aria-label={t('admin.author.more-content-actions')}
        />
      )}
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </>
  )
}
