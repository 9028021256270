import { Draggable, DraggableProps, DraggableProvided } from '@hello-pangea/dnd'
import _ from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { assertBlockDefintionExists, blockDefinitions } from 'sierra-client/editor/blocks'
import { useGetPathForId } from 'sierra-client/editor/editor-jotai-context'
import { supportsTextToSpeech } from 'sierra-client/editor/text-to-speech/supports-text-to-speech'
import { FCC } from 'sierra-client/types'
import { BlockDefinition } from 'sierra-client/views/block-types'
import { BlockCommentButton } from 'sierra-client/views/v3-author/block-actions/block-commenting-button'
import {
  AddBlockIcon,
  BlockDragIcon,
  BlockMenu,
} from 'sierra-client/views/v3-author/block-actions/block-menu'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { RulesOfBlocks } from 'sierra-client/views/v3-author/common/rules-of-blocks'
import * as Components from 'sierra-client/views/v3-author/components'
import {
  useEditorChatId,
  useEditorMode,
  useEditorReadOnly,
  useRulesOfBlockViolationSeveriy,
} from 'sierra-client/views/v3-author/context'
import { ElementContextProvider } from 'sierra-client/views/v3-author/element-context'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BaseInlineWrapper, BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { iife } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { Editor, Element, Path } from 'slate'
import { RenderElementProps, useSlateSelector, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const InlineChromiumBugFixSpan = styled.span`
  content: ${String.fromCodePoint(160) /* Non-breaking space */};

  &:selected {
    display: none;
  }
`

// Fix for inline components to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugFix: FCC<{ isInline: boolean }> = ({ isInline, children }) => {
  if (!isInline) return <>{children}</>
  else
    return (
      <>
        <InlineChromiumBugFixSpan contentEditable={false} />
        {children}
        <InlineChromiumBugFixSpan contentEditable={false} />
      </>
    )
}

/**
 * Renders the contents of a Slate element.
 */
const RenderInner: SlateFC = ({ children, ...props }) => {
  const { type } = props.element
  const Component = blockDefinitions[type].Component ?? Components.EmptyComponent
  const inline = props._attributes['data-slate-inline'] === true

  return (
    <Component {...props} data-block-inner={props.element.id}>
      <InlineChromiumBugFix isInline={inline}>{children}</InlineChromiumBugFix>
    </Component>
  )
}

/**
 * Wraps all Slate elements at the outermost layer.
 */
const RenderOuter: FCC<SlateWrapperProps & { provided?: DraggableProvided }> = ({
  children,
  attributes,
  provided,
  ...props
}) => {
  const { type } = props.element
  const editor = useSlateStatic()
  const inline = attributes['data-slate-inline'] === true

  const Wrapper = iife(() => {
    const BlockDefinitionWrapper = blockDefinitions[type].Wrapper
    if (BlockDefinitionWrapper !== undefined) return BlockDefinitionWrapper
    if (inline) return BaseInlineWrapper
    return BlockWrapper
  })

  const wrapperRef = useRef<HTMLElement | null>(null)
  const previousWrapperRef = useRef<HTMLElement | null>(null)

  const severity = useRulesOfBlockViolationSeveriy()
  const readOnly = useEditorReadOnly()

  const notif = useNonExpiringNotif()
  useEffect(() => {
    if (!readOnly) {
      RulesOfBlocks.verify(editor, type, wrapperRef.current, attributes, severity, notif)
    }
  }, [type, attributes, severity, editor, readOnly, notif])

  const attributesRef = attributes.ref
  const draggableInnerRef = provided?.innerRef
  const wrapperRefCallback = useCallback(
    (current: HTMLElement | null) => {
      if (wrapperRef.current !== null) {
        previousWrapperRef.current = wrapperRef.current
      }
      wrapperRef.current = current

      if (typeof attributesRef === 'function') attributesRef(current)
      else attributesRef.current = current

      draggableInnerRef?.(current)

      RulesOfBlocks.verifyStableElementWrapper({
        prevWrapper: previousWrapperRef.current,
        currWrapper: current,
        type,
        level: severity,
        editor,
        notif,
      })
    },
    [attributesRef, draggableInnerRef, editor, severity, type, notif]
  )

  const textToSpeechAttributes = supportsTextToSpeech(props.element) ? { 'data-text-to-speech': true } : {}

  return (
    <Wrapper
      attributes={{ ...provided?.draggableProps, ...attributes }}
      {...attributes}
      {...textToSpeechAttributes}
      {...provided?.draggableProps}
      {...props}
      ref={wrapperRefCallback}
    >
      {children}
    </Wrapper>
  )
}

function isElementDraggable({ preventDrag }: BlockDefinition, editor: SanaEditor, path: Path): boolean {
  if (typeof preventDrag !== 'function') return preventDrag !== true

  // It's possible that the path has not yet been resolved at this point for the element.
  // In these cases we will make sure the element is not draggable until the path is available.
  if (path.length === 0) return false
  return !preventDrag({ editor, path })
}

type DraggableChildren = (provided?: DraggableProvided) => JSX.Element

type SlateDraggableProps = Omit<DraggableProps, 'index'> & {
  element: Element
}

function SlateNotDraggable({
  children,
}: Partial<SlateDraggableProps> & { children: DraggableChildren }): JSX.Element {
  return children(undefined)
}

/**
 * This is a separate function because we want to limit re-renders
 * when root nodes change order. We currently have performance issues
 * in large documents when inserting or removing root nodes at the start
 * of the document. This seems to mainly be because the dnd library does a lot of
 * work when `index` changes, but this isolates the issue a bit.
 */
function SlateDraggable({
  element,
  children,
  ...rest
}: SlateDraggableProps & { children: DraggableChildren }): JSX.Element {
  const getPathById = useGetPathForId()

  const selectDragIndex = useCallback((): number => {
    const path = getPathById(element.id)

    return path?.[0] ?? -1
  }, [element.id, getPathById])

  const index = useSlateSelector(selectDragIndex)

  return (
    <Draggable index={index} {...rest}>
      {children}
    </Draggable>
  )
}

const RenderElement = ({ children, attributes, element }: RenderElementProps): JSX.Element => {
  const { type } = element
  assertBlockDefintionExists(type)
  const definition = blockDefinitions[type]
  const chatId = useEditorChatId()
  const getPathById = useGetPathForId()

  const renderingContext = useRenderingContext()
  const allowBlockComments =
    definition.enableBlockComments === true &&
    renderingContext.allowBlockComments === true &&
    chatId !== undefined

  const mode = useEditorMode()
  const readOnly = useEditorReadOnly()
  const inline = attributes['data-slate-inline'] === true

  const selectSlateProps = useCallback(
    (editor: Editor): { isDraggable: boolean } => {
      const path = getPathById(element.id)
      if (path === undefined) {
        return {
          isDraggable: false,
        }
      }

      const isDraggablePosition = path.length === 1 // This should filter out children elements, such as QuestionHeader etc.
      const isDraggableElementType = isElementDraggable(definition, editor, path)

      const isDraggable =
        !readOnly &&
        mode === 'create' &&
        isDraggablePosition &&
        isDraggableElementType &&
        renderingContext.preventDrag !== true &&
        !inline

      return {
        isDraggable,
      }
    },
    [definition, element.id, getPathById, inline, mode, readOnly, renderingContext.preventDrag]
  )

  const { isDraggable } = useSlateSelector(selectSlateProps, _.isEqual)

  const DraggableWrapper = isDraggable ? SlateDraggable : SlateNotDraggable

  return (
    <ElementContextProvider value={element}>
      <DraggableWrapper element={element} draggableId={element.id}>
        {provided => (
          <RenderOuter
            readOnly={readOnly}
            mode={mode}
            element={element}
            attributes={attributes}
            provided={provided}
          >
            <RenderInner mode={mode} readOnly={readOnly} _attributes={attributes} element={element}>
              {children}

              {definition.hasGenericCommentIndicator === true && <BlockCommentIndicator element={element} />}
            </RenderInner>

            <BlockMenu
              inline={inline}
              disabled={readOnly}
              draggable={isDraggable}
              element={element}
              alignment={definition.actionsVerticalAlignment}
            >
              {allowBlockComments && <BlockCommentButton chatId={chatId} blockId={element.id} />}
              {isDraggable && <AddBlockIcon element={element} />}
              {isDraggable && <BlockDragIcon element={element} dragHandleProps={provided?.dragHandleProps} />}
            </BlockMenu>
          </RenderOuter>
        )}
      </DraggableWrapper>
    </ElementContextProvider>
  )
}

export const renderElement = (props: RenderElementProps): JSX.Element => <RenderElement {...props} />
