import { Duration } from 'luxon'
import React, { ReactNode, useMemo } from 'react'
import { SkillId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import type { SkillContentAttribute, SkillContentTableQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLBasicUserToUserShape } from 'sierra-client/api/graphql/util/convert-gql-basic-user'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useSetOpenAssignContentPanel } from 'sierra-client/features/skills/components/assign-content/assign-content'
import {
  getSkillContentTableQueryKey,
  skillContentTableQuery,
} from 'sierra-client/features/skills/components/tabular/skill-content-table-query'
import { EmptyTableWrapper, TableHeight } from 'sierra-client/features/skills/components/tabular/styles'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  singleSelectColumn,
  skillContentsColumn,
  userStackColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { createDataLoader, type DataLoaderStateMachine } from 'sierra-client/lib/tabular/control/dataloader'
import {
  convertGQLContentEntityType,
  convertGQLContentKind,
} from 'sierra-client/lib/tabular/dataloader/content'
import type { UserShape } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { capitalizedLabel, translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { PaginatedTabular } from 'sierra-client/lib/tabular/provider/components/paginated'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, getRowData } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import { isDefined, isNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { z } from 'zod'
import { isContentNotVisible } from '../assign-content/select-content-form'

const SkillContentDataLevelSettings = z.object({
  id: SkillLevelSettingId,
  index: z.number(),
  name: z.string().min(1),
  badgeTheme: z.string().min(1),
  fastTrackContentId: z.string().optional(),
})
type SkillContentDataLevelSettings = z.infer<typeof SkillContentDataLevelSettings>

type SkillContentData = {
  content: SkillContentTableQuery['skillAssignedContent']['data'][number]['content']
  level: SkillContentDataLevelSettings
  levels: Array<{ id: SkillLevelSettingId; name: string }>
  usersMissingAccess: UserShape[]
  isNotPublicVisible: boolean
}

type SkillContentTableDefinition = TableDefinitionOf<
  SkillContentData,
  [
    { type: 'skillContent'; ref: 'NAME' },
    { type: 'singleSelect'; ref: 'level' },
    { type: 'userStacks'; ref: 'usersMissingAccess' },
  ]
>

export type SkillContentTableData = TableDataFromDefinition<SkillContentData, SkillContentTableDefinition>

const skillContentTableDefinition = (
  skillId: string,
  tableActions: SkillContentTabularActions
): SkillContentTableDefinition => ({
  nested: {},
  rows: { getId: r => r.content.contentId },
  columns: [
    skillContentsColumn({
      ref: 'NAME',
      header: translatedLabel('dictionary.content-plural'),
      sortable: true,
      getData: ({ content, level }) => ({
        id: content.contentId,
        image: convertGQLImage(content.image),
        contentType: convertGQLContentEntityType(content.contentType),
        courseKind: 'courseKind' in content ? convertGQLContentKind(content.courseKind) : undefined,
        title: content.title,
        learnersCount: content.assignmentCount,
        createdAt: 'publishedAt' in content ? content.publishedAt ?? content.createdAt : content.createdAt,
        duration: Duration.fromISO(content.duration).as('seconds'),
        tags: [],
        isLevelFastTrack: level.fastTrackContentId === content.contentId,
        level,
        isFeatured: content.featured,
        isCourseEdition: false,
        isPublicVisibility:
          content.__typename === 'Program'
            ? false
            : content.__typename === 'Path'
              ? content.isPathVisible
              : content.courseVisibility === 'VISIBLE',
        courseGroupId: undefined,
      }),
      hints: ['content-with-duration'],
    }),
    singleSelectColumn({
      ref: 'level',
      header: capitalizedLabel(translatedLabel('dictionary.level')),
      sortable: false,
      getData: data => ({
        selected: data.level.index === 0 ? '' : data.level.name,
        label: { type: 'translated', key: 'skills.table.content.level.text' },
        items: data.levels.map(level => ({
          id: level.id,
          label: level.name,
        })),
        onSelect: (item, ref) => {
          tableActions.onChangeLevel(ref, SkillId.parse(skillId), SkillLevelSettingId.parse(item.id))
        },
      }),
    }),
    userStackColumn({
      ref: 'usersMissingAccess',
      header: capitalizedLabel(translatedLabel('admin.settings.visibility')),
      getData: data => {
        const contentType = convertGQLContentEntityType(data.content.contentType)
        const id = data.content.contentId

        let contentSettingsHref = getHrefForContentDetails({ id, contentType })

        if (contentType === 'program') {
          contentSettingsHref = contentSettingsHref + '?panel=edit'
        }

        return data.usersMissingAccess.length === 0
          ? {
              users: [],
              label: data.isNotPublicVisible
                ? {
                    text: translatedLabel('skills.table.limited-access.label'),
                    icon: 'warning--filled',
                    tooltip: {
                      label: translatedLabel('skills.table.limited-access.tooltip'),
                      contentLink: contentSettingsHref,
                    },
                  }
                : { text: translatedLabel('skills.table.public-access.label'), hints: ['muted'] },
            }
          : {
              users: data.usersMissingAccess,
              label: {
                text: translatedLabel('skills.table.limited-access.label'),
                icon: 'warning--filled',
                tooltip: {
                  label: translatedLabel('skills.table.limited-access.tooltip'),
                  contentLink: contentSettingsHref,
                },
              },
            }
      },
    }),
  ],
})

const queryToTableData = (queryData: SkillContentTableQuery): SkillContentData[] => {
  const data = queryData.skillAssignedContent.data

  return data.map((assignedContent): SkillContentData => {
    const badgeIssuedVia = assignedContent.skillLevel.badgeIssuedVia
    return {
      content: assignedContent.content,
      level: {
        id: assignedContent.skillLevel.levelSetting.id,
        index: assignedContent.skillLevel.levelSetting.index,
        name: assignedContent.skillLevel.levelSetting.name,
        badgeTheme: 'default',
        fastTrackContentId:
          badgeIssuedVia.__typename === 'OnSpecificContentCompletion' ? badgeIssuedVia.id : undefined,
      },
      levels: queryData.currentSkill?.skillLevels.map(level => level.levelSetting) ?? [],
      usersMissingAccess: assignedContent.usersMissingAccess.map(convertGQLBasicUserToUserShape),
      isNotPublicVisible: isContentNotVisible(assignedContent.content),
    }
  })
}

export type SkillContentTabularActions = {
  onRemoveContent: (ref: RowRef, api: TableAPI<SkillContentTableData>) => void
  onAddContent: () => void
  onMakeFastTrack: (ref: RowRef, api: TableAPI<SkillContentTableData>) => void
  onChangeLevel: (ref: RowRef, skillId: SkillId, skillLevelSettingId: SkillLevelSettingId) => void
}

const virtualColumns = (
  actions: SkillContentTabularActions,
  t: TranslationLookup
): VirtualColumns<SkillContentTableData> => ({
  left: [],
  right: [
    defaultMenuActionVirtualColumn({
      options: { columnToggle: false },
      getProps: (api, pos, td) => {
        const row = getRowData(td, pos.row)

        const isFastTrackDisabled =
          row?.NAME.data.isLevelFastTrack === true
            ? false
            : isDefined(row?.NAME.data.level.fastTrackContentId)

        const selectedFastTrackContentForRowLevel = td.rows.find(
          it => it.data.NAME.data.id === row?.NAME.data.level.fastTrackContentId
        )
        return {
          menuItems: [
            {
              id: pos.row + '-switch',
              type: 'switch',
              onToggleChange: () => actions.onMakeFastTrack(pos.row, api),
              icon: 'racing--flag',
              label: t('admin.skills.level-fast-tack.label'),
              description:
                isDefined(selectedFastTrackContentForRowLevel) && isFastTrackDisabled
                  ? t('skills.level-fast-tack.disabled.description', {
                      contentTitle: selectedFastTrackContentForRowLevel.data.NAME.data.title,
                      levelTitle: selectedFastTrackContentForRowLevel.data.NAME.data.level.name,
                    })
                  : t('admin.skills.level-fast-tack.description'),
              checked: row?.NAME.data.isLevelFastTrack ?? false,
              disabled: isFastTrackDisabled,
            },
            {
              id: pos.row + '-separator',
              type: 'separator',
            },
            {
              id: pos.row + '-label',
              type: 'label',
              onClick: () => actions.onRemoveContent(pos.row, api),
              color: 'destructive/background',
              label: t('dictionary.remove'),
              icon: 'trash-can',
            },
          ],
        }
      },
    }),
  ],
})

const skillContentDataLoader = (
  id: SkillId,
  actions: SkillContentTabularActions
): DataLoaderStateMachine<SkillContentTableData, { cursor: string | undefined | null }> => {
  return createDataLoader({
    async fetchInit({ control, modifier }) {
      const sortBy = modifier.sorting?.map(sort => ({
        // todo: fix cast
        key: sort.column as SkillContentAttribute,
        order: toGQLSortOrder(sort.direction),
      }))
      const res = await graphQuery(skillContentTableQuery, { id: id, limit: control.limit, sortBy })

      const data = queryToTableData(res)

      return {
        meta: { cursor: res.skillAssignedContent.cursor },
        data,
        totalCount: res.skillAssignedContent.totalCount,
        done: isNullable(res.skillAssignedContent.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const res = await graphQuery(skillContentTableQuery, {
        id: id,
        limit: control.limit,
        cursor: meta.cursor,
      })

      const data = queryToTableData(res)

      return {
        meta: { cursor: res.skillAssignedContent.cursor },
        data,
        totalCount: res.skillAssignedContent.totalCount,
        done: isNullable(res.skillAssignedContent.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(skillContentTableDefinition(id, actions), data)]
    },
  })
}

export const useSkillContentTableAPI = (
  skillId: SkillId,
  actions: SkillContentTabularActions
): UseTableAPI<SkillContentTableData, any> => {
  const { t } = useTranslation()
  return useTableAPI({
    dataLoader: useMemo(
      () => ({
        loader: skillContentDataLoader(skillId, actions),
        options: { queryKey: getSkillContentTableQueryKey(skillId) },
      }),
      [actions, skillId]
    ),
    virtualColumns: useMemo(() => virtualColumns(actions, t), [actions, t]),
    options: {
      limit: 7,
    },
  })
}

const EmptyTable: React.FC = () => {
  const { t } = useTranslation()
  const setOpenAssignContentPanel = useSetOpenAssignContentPanel()

  return (
    <EmptyTableWrapper>
      <Icon iconId='course' color='foreground/primary' />
      <Text size='small' bold>
        {t('manage.programs.add-content')}
      </Text>
      <Text align='center' color='foreground/muted'>
        {t('manage.skills.add-content.description')}
      </Text>
      <Spacer size='2' />
      <Button
        variant='secondary'
        onClick={() => {
          setOpenAssignContentPanel(true)
        }}
      >
        {t('manage.programs.add-content')}
      </Button>
    </EmptyTableWrapper>
  )
}

export const SkillContentTabular: React.FC<{
  actions: SkillContentTabularActions
}> = ({ actions }) => {
  const { t } = useTranslation()
  const { api, pages, dataloaderState } = useTabularContext()

  const nRows = pages.flat().length

  const AddContentToolbarButton: ReactNode = (
    <Button variant='transparent' onClick={actions.onAddContent}>
      {t('manage.heatmap.add-content')}
    </Button>
  )

  return (
    <TableHeight>
      <TabularToolbar
        countsTranslationKeys={{
          totalKey: 'manage.content.n-contents',
          filterKey: 'manage.program-members.n-filtered',
          selectedKey: 'manage.tables.n-selected',
        }}
        api={api}
        clearFilters={false}
        actions={AddContentToolbarButton}
      />
      {dataloaderState === 'init' ? (
        <View grow justifyContent='center' alignItems='center'>
          <LoadingSpinner />
        </View>
      ) : nRows === 0 ? (
        <EmptyTable />
      ) : (
        <PaginatedTabular />
      )}
    </TableHeight>
  )
}
