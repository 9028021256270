import { createFileRoute } from '@tanstack/react-router'
import { getFlag } from 'sierra-client/config/global-config'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageUsers } from 'sierra-client/views/manage/users/manage-users'
import { prefetchFilterUsersDomainReps } from 'sierra-client/views/manage/users/use-filter-users'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageUsersPage(): JSX.Element | null {
  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageUsers()}>
      <ManageUsers />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageUsersPage

export const Route = createFileRoute('/manage/users/')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (context.isLoggedIn && getFlag('preloading')) {
      void prefetchFilterUsersDomainReps()
    }
  },
})
