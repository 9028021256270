import { DateTime } from 'luxon'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { date } from 'sierra-client/core/format'
import { EnrollmentStep } from 'sierra-client/features/program/admin/user-timeline/types'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, isDefined } from 'sierra-domain/utils'

export function getNullableDate(raw: string | null | undefined): string | null {
  return isDefined(raw) ? date(raw) : null
}

export const getStepId = (step: EnrollmentStep): string => {
  switch (step.__typename) {
    case 'UserProgramCourseStep':
    case 'UserProgramPathStep':
      return step.contentId
    case 'UserProgramEmailStep':
      return step.resourceId
    default:
      assertNever(step)
  }
}

export function getResourceUrl(contentId: string): string {
  const [scope, id] = contentId.split(':')

  if (scope === 'path') {
    return `/manage/paths/${id}`
  } else if (scope === 'course') {
    return `/manage/courses/${id}`
  }

  throw Error('Failed to resolve url from contentId ' + contentId)
}

export function getStepImage(enrollmentStep: EnrollmentStep): ImageUnion | undefined {
  return enrollmentStep.__typename === 'UserProgramEmailStep'
    ? convertGQLImage(enrollmentStep.image)
    : isDefined(enrollmentStep.content)
      ? convertGQLImage(enrollmentStep.content.image)
      : undefined
}

export function getStepProgress(enrollmentStep: EnrollmentStep): number {
  switch (enrollmentStep.__typename) {
    case 'UserProgramEmailStep': {
      return isDefined(enrollmentStep.availableAt) &&
        DateTime.fromISO(enrollmentStep.availableAt) < DateTime.now()
        ? 1
        : 0
    }
    case 'UserProgramCourseStep':
    case 'UserProgramPathStep': {
      return isDefined(enrollmentStep.progress) ? enrollmentStep.progress.progress : 0
    }
    default: {
      return 0
    }
  }
}
