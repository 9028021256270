import { useEffect } from 'react'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { preloadFileImage } from 'sierra-client/views/flexible-content/card-background/preload-file-image'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const NextCardImagePreload = ({
  nextCardId,
  flexibleContentId,
}: {
  nextCardId: FileId
  flexibleContentId: LiveContentId
}): null => {
  const nextFile = useSelector(state => selectFlexibleContentFile(state, flexibleContentId, nextCardId))

  useEffect(() => {
    if (nextFile) preloadFileImage(nextFile)
  }, [nextFile])

  return null
}
