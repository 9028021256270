import React, { useEffect, useMemo } from 'react'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { HighlightChanges } from 'sierra-client/editor/version-history//utils/highlight-changes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { BreakoutRoomCard } from 'sierra-client/views/flexible-content/breakout-room-card'
import { CreateCardCanvas } from 'sierra-client/views/flexible-content/create-page'
import { FullHeightWithPadding } from 'sierra-client/views/flexible-content/editor/content-sidebar/full-height-with-padding'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { CreateImageCard } from 'sierra-client/views/flexible-content/image-card'
import { LobbyCard } from 'sierra-client/views/flexible-content/lobby-card'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { VideoCard } from 'sierra-client/views/flexible-content/video-card'
import { StaticEditor, useStaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { useSafeFlexibleContentId } from 'sierra-client/views/v3-author/hooks'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-card'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { assertNever, assertWith } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocumentSafe } from 'sierra-domain/v3-author/slate-yjs-extension'
import { ReplayUpdatesContext } from 'sierra-domain/version-history/replay-updates'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const CenteredContainer = styled(View)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;

  & > div {
    border-radius: 6px;
    border: 1px solid ${token('border/default')};
    padding: 8px;
  }
`

const NoFile: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CenteredContainer>
      <View direction='column'>
        <Text bold>{t('version-history.selected-card-not-found-title')}</Text>
        <Text color='foreground/secondary'>{t('version-history.selected-card-not-found-description')}</Text>
      </View>
    </CenteredContainer>
  )
}

const NotSupported: FCC = ({ children }) => {
  return (
    <CenteredContainer>
      <View direction='column'>
        <Text bold>{children}</Text>
      </View>
    </CenteredContainer>
  )
}

const NoPointerEvents = styled.div`
  pointer-events: none;
`

const Switch: React.FC<{
  file: File
  authorIds: UserId[] | undefined
  currentDocument: SlateDocument | undefined
  previousDocument: SlateDocument | undefined
}> = ({ file, authorIds, currentDocument, previousDocument }) => {
  const { t } = useTranslation()
  const editor = useStaticEditor(currentDocument ?? [])

  const flexibleContentId = useSafeFlexibleContentId()
  const assetContext = useMemo(
    () =>
      flexibleContentId !== undefined
        ? { type: 'course' as const, courseId: flexibleContentId }
        : { type: 'unknown' as const },
    [flexibleContentId]
  )

  switch (file.data.type) {
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'question-card':
    case 'assessment-card':
    case 'project-card':
    case 'sticky-notes':
    case 'reflections':
    case 'homework':
      return (
        <HighlightChanges
          authorId={authorIds?.length === 1 ? authorIds[0] : undefined}
          editor={editor}
          previousDocument={previousDocument ?? []}
          currentDocument={currentDocument ?? []}
        >
          <NoPointerEvents>
            <StaticEditor
              editor={editor}
              key={file.id}
              document={currentDocument ?? []}
              mode='version-history'
              layout={'page-card'}
              assetContext={assetContext}
            />
          </NoPointerEvents>
        </HighlightChanges>
      )
    case 'live-lobby':
      return <LobbyCard />
    case 'breakout-room':
      return <BreakoutRoomCard data={file.data} />
    case 'video':
      return (
        <VideoCard
          readOnly
          video={file.data.video}
          setVideoDuration={() => {}}
          onUploadDone={() => {}}
          assetContext={assetContext}
          file={file}
        />
      )
    case 'image':
      return (
        <CreateImageCard
          data={file.data}
          onUploadDone={() => {}}
          readOnly
          mode='create'
          assetContext={assetContext}
        />
      )
    case 'embed':
      return <EmbedCard data={file.data} onUploadDone={() => {}} readOnly />
    case 'stupid-questions':
      return <QACard fileId={file.id} data={file.data} readOnly />

    case 'notepad':
    case 'external-notepad':
    case 'scenario':
    case 'drop-a-word':
      return (
        <NotSupported>
          {file.data.type === 'notepad' || file.data.type === 'external-notepad'
            ? t('version-history.notepads-not-supported')
            : t('dictionary.empty')}
        </NotSupported>
      )
    // @deprecated
    case 'roleplay':
      return null

    default:
      assertNever(file.data)
  }
}

const Content: React.FC<{
  authorIds?: UserId[]
  context: ReplayUpdatesContext
  scopedCreateContentId: ScopedCreateContentId
  file: File
}> = ({ authorIds, file, context: { previousYDoc, currentYDoc }, scopedCreateContentId }) => {
  const fileId = file.id
  const previousDocument = useMemo(() => getSlateDocumentSafe(previousYDoc, fileId), [fileId, previousYDoc])
  const currentDocument = useMemo(() => getSlateDocumentSafe(currentYDoc, fileId), [fileId, currentYDoc])

  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: ScopedCreateContentId.extractId(scopedCreateContentId),
        fileId: fileId,
        cardType: file.data.type,
        mode: 'version-history',
      })
    )
  }, [dispatch, file.data.type, file.id, fileId, scopedCreateContentId])

  return (
    <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
      <Switch
        file={file}
        authorIds={authorIds}
        currentDocument={currentDocument}
        previousDocument={previousDocument}
      ></Switch>
    </FileContext>
  )
}

const CardTheme: FCC<{ file: File; assetContext: AssetContext }> = ({ file, assetContext, children }) => (
  <PolarisCardTheme {...file}>
    <CreateCardCanvas card={file} assetContext={assetContext}>
      {children}
    </CreateCardCanvas>
  </PolarisCardTheme>
)

export const VersionHistoryCenterContent = ({
  authorIds,
  scopedCreateContentId,
  nodeId,
  context,
  assetContext,
}: {
  scopedCreateContentId: ScopedCreateContentId
  authorIds: UserId[] | undefined
  nodeId: FileId | undefined
  context: ReplayUpdatesContext
  assetContext: AssetContext
}): JSX.Element | null => {
  const file = useMemo(() => {
    if (nodeId === undefined) return undefined

    const file = safeGetFile(context.currentYDoc, nodeId)
    assertWith(File.optional(), file)
    return file
  }, [nodeId, context])

  return (
    <FullHeightWithPadding>
      {file === undefined ? (
        <NoFile />
      ) : (
        <CardTheme file={file} assetContext={assetContext}>
          <Content
            authorIds={authorIds}
            file={file}
            scopedCreateContentId={scopedCreateContentId}
            context={context}
          />
        </CardTheme>
      )}
    </FullHeightWithPadding>
  )
}
