import { concat } from 'lodash'
import 'regenerator-runtime/runtime' // note: must be loaded before exceljs
import { downloadBlob } from 'sierra-client/api'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { BaseColumn } from 'sierra-client/lib/tabular/datatype/column'
import { Data } from 'sierra-client/lib/tabular/datatype/data'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { assertNever, getUserName, iife } from 'sierra-domain/utils'

export type FileType = 'csv' | 'xlsx'

export type TableFile = {
  headers: string[]
  rows: (string | number | boolean | Date | string[] | undefined)[][]
  fileType: FileType
}

export const createTableFileBlob = async ({ headers, rows, fileType }: TableFile): Promise<Blob> => {
  const ExcelJS = await import('exceljs')
  const workbook = new ExcelJS.Workbook()

  const worksheet = workbook.addWorksheet('Sheet1')

  worksheet.addRow(headers)
  worksheet.addRows(rows)

  const buffer = await (() => {
    switch (fileType) {
      case 'csv':
        return workbook.csv.writeBuffer()
      case 'xlsx':
        return workbook.xlsx.writeBuffer()
      default:
        assertNever(fileType)
    }
  })()

  const blob = new Blob([buffer])
  return blob
}

type ExtractData<T extends Data['type']> = Extract<Data, { type: T }>['data']

const getColumnHeaderString = (column: BaseColumn, t: TranslationLookup): string => {
  if (typeof column.header === 'string' || column.header.type !== 'cell') {
    return labelToString(column.header, t)
  }

  return labelToString(column.header.label, t)
}

export const toHeaderData = (tableData: TableData, t: TranslationLookup): string[] => {
  const headerData = tableData.columns
    .map(column =>
      column.type === 'users' ? [getColumnHeaderString(column, t), 'Email'] : getColumnHeaderString(column, t)
    )
    .flat()
  return headerData
}

export const toRowData = (
  tableData: TableData
): Array<Array<number | string | boolean | Date | undefined | string[]>> =>
  tableData.rows.map(row =>
    tableData.columns
      .map(({ ref, type }) => {
        const value = row.data[ref]?.data
        switch (type) {
          case 'booleans':
          case 'numbers':
          case 'strings':
          case 'expiryTimes':
          case 'dateTimes':
            return value as ExtractData<typeof type>
          case 'duration': {
            const duration = value as ExtractData<typeof type>
            return duration !== undefined ? duration.as('minutes') : ''
          }
          case 'users': {
            const user = value as ExtractData<typeof type>
            if (user === undefined) {
              return undefined
            }

            return [`${user.firstName ?? ''} ${user.lastName ?? ''}`, user.email]
          }
          case 'enrolledBys': {
            const enrolledBy = value as ExtractData<typeof type>
            switch (enrolledBy.type) {
              case 'admin':
                return `${enrolledBy.firstName} ${enrolledBy.lastName}`
              case 'self':
                return 'self enrolment'
              case 'enrollment-rule':
                return enrolledBy.name
              default:
                return 'unknown'
            }
          }
          case 'homeworks': {
            const homeworks = value as ExtractData<typeof type>
            return homeworks.title ?? ''
          }
          case 'userStacks': {
            const data = value as ExtractData<typeof type>
            const joinedUsers = data.users.map(getUserName).join(', ')
            return joinedUsers
          }
          case 'content': {
            const content = value as ExtractData<typeof type>
            return content?.title
          }
          case 'skillContent': {
            const content = value as ExtractData<typeof type>
            return content.title
          }
          case 'links': {
            const links = value as ExtractData<typeof type>
            return links.label
          }
          case 'certificates': {
            const certificates = value as ExtractData<typeof type>
            return certificates?.title
          }
          case 'certificateIssuedBy': {
            const issuedBy = value as ExtractData<typeof type>
            switch (issuedBy.type) {
              case 'content':
              case 'program':
                return issuedBy.title
              case 'user':
                return issuedBy.displayName
              default:
                return undefined
            }
          }
          case 'issuedCertificates': {
            const issuedCertificates = value as ExtractData<typeof type>
            return issuedCertificates.issuedToUserDisplayName
          }
          case 'addStepContent': {
            const addStepContent = value as ExtractData<typeof type>
            return addStepContent.title
          }
          case 'issuesRevoked': {
            const issuesRevoked = value as ExtractData<typeof type>
            return issuesRevoked.issued
          }
          case 'programVersionNumbers': {
            const programVersionNumbers = value as ExtractData<typeof type>
            return programVersionNumbers.updatedAt
          }
          case 'titlesAndSubtitles': {
            const data = value as ExtractData<typeof type>

            return (data?.title ?? '') + (data?.subtitle ?? '')
          }
          case 'groups': {
            const data = value as ExtractData<typeof type>
            return data?.name
          }
          case 'eventGroups': {
            const data = value as ExtractData<typeof type>
            return data.title
          }
          case 'singleSelect': {
            const data = value as ExtractData<typeof type>
            return data.selected
          }
          case 'card': {
            const data = value as ExtractData<typeof type>
            return data?.title
          }
          case 'canvas': {
            const data = value as ExtractData<typeof type>
            return data.meta.exports()
          }

          case 'pills': {
            const data = value as ExtractData<typeof type>
            return data.type + data.text + data.subtext
          }
          default: {
            assertNever(type)
          }
        }
      })
      .flat()
  )

export const exportTableData = async <Data extends TableData, Meta>({
  api,
  dataLoader,
  t,
  fileType = 'csv',
  includeNested = false,
  withName = undefined,
}: {
  api: TableAPI
  dataLoader: DL.DataLoaderStateMachine<Data, Meta>
  t: TranslationLookup
  fileType: FileType
  includeNested?: boolean
  withName?: string
}): Promise<void> => {
  const filter = api.query.filter().filter
  const query = api.query.query().query

  let state = await dataLoader.onInit({
    predicate: { filter, query },
    modifier: {},
    // It appears that we currently have a limit max of 1 < limit < 1000
    control: { limit: 999 },
  })

  while (state.type !== 'done') {
    state = await dataLoader.onMore(state, { control: state.control })
  }

  // this only flattens one level
  const tableData = includeNested
    ? state.data.map(d => ({ ...d, rows: d.rows.flatMap(row => concat(row, d.nested[row.id]?.rows ?? [])) }))
    : state.data

  const selection = api.query.selected().selection

  // Filter out the ones we have selected, excluded etc based on selection
  const filtered = tableData.map(td => {
    const matched = iife(() => {
      switch (selection.type) {
        case 'all':
          return td.rows.filter(({ id }) => !selection.excluded.has(id))
        case 'none':
          return td.rows
        case 'manual':
          return td.rows.filter(({ id }) => selection.rows.has(id))
        default:
          assertNever(selection)
      }
    })

    return { ...td, rows: matched }
  })

  const headers = tableData.slice(0, 1).flatMap(td => toHeaderData(td, t))
  const rows = filtered.flatMap(toRowData)

  const blob = await createTableFileBlob({ headers, rows, fileType })
  downloadBlob(blob, `${withName ?? 'export'}.${fileType}`)
}
