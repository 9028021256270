import { config } from 'sierra-client/config/global-config'
import { errorLogger } from 'sierra-client/error/error-logger'
import { MediaLibraryImage } from 'sierra-domain/content/v2/content'
import { AvatarUrl } from 'sierra-domain/user/avatar-url'

export function getAvatarImage(userId: string | undefined, name: string | undefined): AvatarUrl | undefined {
  if (userId === undefined) return undefined
  if (name === undefined) return undefined

  if (userId.length !== 36) {
    // TODO(uk) Remove this check, it was just to verify that all cases were correct.
    errorLogger.captureError(new Error(`userId is not valid: ${userId}`))
    return undefined
  }

  if (AvatarUrl.safeParse(name).success) {
    // TODO(uk) Remove this case, it should not happen.
    errorLogger.captureError(new Error(`name is an URL already: ${userId}`))
    return name as AvatarUrl
  }

  const tenantId = config.organization.tenantId
  const bucket = `${config.organization.bucketPrefix}-assets`

  const parameters = ['q_auto:best', 'z_0.8', 'g_face', 'c_thumb', 'h_200', 'w_200']

  // TODO(uk) Remove the jpeg thing in Kotlin instead

  const url = `https://res.cloudinary.com/sana-ai/image/upload/${parameters.join()}/${bucket}/${tenantId}/users/${userId}/${name.replace(
    '.jpeg',
    ''
  )}`

  return url as AvatarUrl
}

export function getAvatarMediaLibraryImage(
  image: MediaLibraryImage,
  mediaLibraryBucket: string
): AvatarUrl | undefined {
  const name = image.file
  if (AvatarUrl.safeParse(name).success) return name as AvatarUrl
  const parameters = ['q_auto:best', 'z_0.8', 'g_face', 'c_thumb', 'h_200', 'w_200']
  const url = `https://res.cloudinary.com/sana-ai/image/upload/${parameters.join()}/${mediaLibraryBucket}/${
    image.file
  }`

  return url as AvatarUrl
}
