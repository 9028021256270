import React, { useMemo, useState } from 'react'
import {
  DownloadTableDataButton,
  TableToolbar,
} from 'sierra-client/features/insights/display-widgets/table/table-toolbar'
import { resultDataLoader } from 'sierra-client/features/insights/display-widgets/table/table-utils'
import { PivotTabular } from 'sierra-client/features/insights/tabular/pivot'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DataLoaderStateMachine } from 'sierra-client/lib/tabular/control/dataloader'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { PivotTableWidget, TableResult } from 'sierra-domain/api/insights'
import { InternalTruncatedText, Text, View } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const PIVOT_TABLE_LIMIT = 20

const TableWrapper = styled(View).attrs({ grow: true, alignItems: 'stretch' })`
  overflow: hidden;
  margin: 0;
  width: 100%;

  * > ${InternalTruncatedText} {
    font-weight: ${fonts.weight.regular};
  }
  * > a:hover {
    ${InternalTruncatedText}:first-child {
      text-decoration: underline;
    }
  }
`

type PivotTableProps = {
  tableResult: TableResult
  showToolbar?: boolean
  widget: PivotTableWidget
}

export const PivotTable: React.FC<PivotTableProps> = ({ tableResult, showToolbar = true, widget }) => {
  const { t } = useTranslation()

  const [pivotPaginationIndex, setPivotPaginationIndex] = useState(1)

  const tableResultWithMax20Columns: TableResult = useMemo(() => {
    return {
      ...tableResult,
      schema: {
        ...tableResult.schema,
        columns: [
          tableResult.schema.columns[0]!,
          ...tableResult.schema.columns.slice(pivotPaginationIndex, pivotPaginationIndex + PIVOT_TABLE_LIMIT),
        ],
      },
    }
  }, [pivotPaginationIndex, tableResult])

  const resultsLoader: DataLoaderStateMachine<TableData, Record<string, never>> = useMemo(() => {
    const loader = resultDataLoader(tableResultWithMax20Columns, t)
    return loader
  }, [t, tableResultWithMax20Columns])

  const tableAPI = useTableAPI({
    dataLoader: { loader: resultsLoader },
    virtualColumns: useMemo(() => ({ left: [], right: [] }), []),
  })

  const { api } = tableAPI

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <View grow direction='column'>
        {showToolbar && (
          <TableToolbar api={api} widget={widget} dbSofLimitReached={tableResult.dbSoftLimitReached} />
        )}
        <TableWrapper>
          <PivotTabular
            currentIndex={pivotPaginationIndex}
            totalSize={tableResult.schema.columns.length}
            onChange={setPivotPaginationIndex}
            pivotTableLimit={PIVOT_TABLE_LIMIT}
            footer={
              tableResult.dbSoftLimitReached ? (
                <tfoot>
                  <View>
                    <Text color='foreground/muted'>
                      {t('manage.insights.widgetbuilder.db-soft-limit-use-download', {
                        count: tableResult.rows.length,
                      })}
                    </Text>
                    <DownloadTableDataButton widget={widget} />
                  </View>
                </tfoot>
              ) : null
            }
          />
        </TableWrapper>
      </View>
    </TabularProviderFromTableAPI>
  )
}
