import { produce } from 'immer'
import _ from 'lodash'
import { getStepId } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline, ProgramStep } from 'sierra-domain/api/manage'

/**
 * Updates the indices of each step to their array counterparts.
 */
const updateIndices = (outline: ProgramOutline): ProgramOutline =>
  produce(outline, draft => {
    for (let i = 0; i < draft.steps.length; i++) {
      const el = draft.steps[i]

      if (el === undefined) {
        return
      }

      el.index = i
    }

    return draft
  })

/**
 * Remove duplicates
 */
const removeDuplicates = (outline: ProgramOutline): ProgramOutline => {
  const uniqueIds: Set<string> = new Set()
  const result: ProgramStep[] = []

  for (const step of outline.steps) {
    const currentId = getStepId(step)
    if (!uniqueIds.has(currentId)) {
      uniqueIds.add(currentId)
      result.push(step)
    }
  }

  return { ...outline, steps: result }
}

/**
 * Apply constraints
 */
const applyConstraints = (steps: ProgramOutline): ProgramOutline => {
  /* TODO(seb): Any constraints? */
  return steps
}

export const normalizeOutline: (steps: ProgramOutline) => ProgramOutline = _.flow([
  removeDuplicates,
  applyConstraints,
  updateIndices,
])
