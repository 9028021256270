import _ from 'lodash'
import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { FeaturedContentDialog } from 'sierra-client/views/manage/content/components/featured-content-dialog'
import { ContentItem, ContentType } from 'sierra-client/views/manage/content/utils/content-utils'
import {
  CreateCourseGroupModal,
  CreateEditionPrefillData,
} from 'sierra-client/views/manage/courses/course-groups/modals/create-course-group-modal'
import { useContentGroupAssignmentPanes } from 'sierra-client/views/manage/courses/utils/use-content-group-assignment-panes'
import { DirectLinkImportDialog } from 'sierra-client/views/manage/direct-link/direct-link-import-dialog'
import { LinkedInImportDialog } from 'sierra-client/views/manage/linked-in/linked-in-import-dialog'
import { ScormImportDialog } from 'sierra-client/views/manage/scorm/scorm-import-dialog'
import { CourseKind } from 'sierra-domain/api/common'

export type ContentActionsProps = {
  action:
    | { modal: undefined }
    | {
        modal: 'enroll'
        targets: { id: string; contentType: ContentType; courseKind: CourseKind | undefined }[]
      }
    | {
        modal: 'create-course-group'
        prefillEditions: [CreateEditionPrefillData, ...CreateEditionPrefillData[]]
      }
    | {
        modal: 'organization-add-featured-content'
        contentItem: ContentItem
      }
    | { modal: 'linked-in' | 'scorm' | 'direct-link' }
    | { modal: 'detach-edition'; onConfirm: () => Promise<void> }
    | { modal: 'detach-all-editions'; onConfirm: () => Promise<void> }
  onClose: () => void
  onDone: () => void
}

export const ContentActions: React.FC<ContentActionsProps> = ({ action, onClose, onDone }) => {
  const { t } = useTranslation()
  const { assignWithDueDate } = useDueDate()
  const notifications = useNotif()
  const panes = useContentGroupAssignmentPanes()

  if (action.modal === 'enroll') {
    const subjects = action.targets.map(c => c.id)
    // Workaround because assignment modal does not support multiple targets.
    // @TODO change this with when we build the new assignment modal
    const groupedIds = _.groupBy(action.targets, c => c.contentType)
    const targetPathIds = (groupedIds.path ?? []).map(c => c.id)
    const targetCourseIds = (groupedIds.course ?? []).map(c => c.id)
    const subjectType: ContentType = targetCourseIds.length > 0 ? 'course' : 'path'

    const subjectsSupportAssignmentSettings =
      subjectType === 'course'
        ? (groupedIds.course ?? []).some(
            x => x.courseKind === 'native:live' || x.courseKind === 'native:event-group'
          )
        : true // we don't have access to path contents here.

    const autoAssignmentAvailable =
      subjectType === 'course' ? (groupedIds.course ?? []).some(x => x.courseKind === 'native:live') : true // we don't have access to path contents here.

    return (
      <AssignModal
        isOpen
        subjectType={subjectType}
        subjects={subjects}
        subjectsSupportAssignmentSettings={subjectsSupportAssignmentSettings}
        autoAssignmentAvailable={autoAssignmentAvailable}
        title={t('admin.organization.paths.enroll')}
        panes={panes}
        showDueDates={panes}
        activePane='user'
        onClose={onClose}
        onSave={async selections => {
          const parsedCourseAssignments = parseModalToContentAssignment(targetCourseIds, 'course', selections)
          const parsedPathAssignments = parseModalToContentAssignment(targetPathIds, 'path', selections)

          const result = await assignWithDueDate([...parsedCourseAssignments, ...parsedPathAssignments])

          if (result?.error !== undefined) {
            notifications.push({
              type: 'custom',
              level: 'error',
              body: t(getUserErrorTranslationKey(result.error)),
            })
            return
          }

          notifications.push({ type: 'assigned' })
          onDone()
          onClose()
        }}
      />
    )
  }
  if (action.modal === 'linked-in') {
    return (
      <LinkedInImportDialog
        isOpen
        onClose={onClose}
        onAfterImport={() => {
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('manage.linked-in.import-message-title'),
          })
          onDone()
        }}
      />
    )
  }

  if (action.modal === 'create-course-group') {
    return <CreateCourseGroupModal prefillData={action.prefillEditions} onClose={onClose} onSave={onDone} />
  }

  if (action.modal === 'scorm') {
    return <ScormImportDialog isOpen onClose={onClose} onAfterImport={onDone} />
  }
  if (action.modal === 'direct-link') {
    return <DirectLinkImportDialog isOpen onClose={onClose} onAfterImport={onDone} />
  }
  if (action.modal === 'organization-add-featured-content') {
    return <FeaturedContentDialog isOpen onClose={onClose} content={action.contentItem} />
  }
  if (action.modal === 'detach-edition') {
    return (
      <ActionModal
        title={t('manage.course-groups.detach-edition.title')}
        open={true}
        onClose={onClose}
        primaryActionLabel={t('manage.course-groups.detach-edition.confirm-action')}
        primaryAction={async () => {
          await action.onConfirm()
          onDone()
        }}
      >
        {t('manage.course-groups.detach-edition.confirm-body')}
      </ActionModal>
    )
  }

  if (action.modal === 'detach-all-editions') {
    return (
      <ActionModal
        title={t('course-editions.create.delete.title')}
        open={true}
        onClose={onClose}
        deleteAction
        primaryActionLabel={t('course-editions.create.delete.title')}
        primaryAction={async () => {
          await action.onConfirm()
          onDone()
        }}
      >
        {t('manage.course-groups.delete-group.confirm-body')}
      </ActionModal>
    )
  }
  return null
}
