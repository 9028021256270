import { DateTime } from 'luxon'
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { ContentRow } from 'sierra-client/components/common/content-elements'
import { GlobalFilter } from 'sierra-client/components/table/global-filter'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableProps } from 'sierra-client/components/table/table'
import { TrimFutureDateAsNow, useLocalizedFormatters } from 'sierra-client/core/format'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { calculateUserDueDateMetadata } from 'sierra-client/views/manage/components/due-date'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { ContentTypeFilter } from 'sierra-client/views/manage/content/components/content-filter'
import {
  getAssetContextForContentDetails,
  getHrefForContentDetails,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { LiveSessionMenuItem } from 'sierra-client/views/manage/courses/components/live-session-components'
import { useManageCourseLiveSessions } from 'sierra-client/views/manage/courses/use-manage-course-live-sessions'
import { AssignedViaPill } from 'sierra-client/views/manage/users/components/content-assignment-components'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import { UserContentProgressRow } from 'sierra-client/views/manage/users/components/user-content-progress-row'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import {
  UserContentRow,
  useUserAssignedContent,
  userContentRowToCsv,
} from 'sierra-client/views/manage/users/utils/use-user-assigned-content'
import { LiveSessionRow, UserDetailResponse } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { Icon, Label, MenuItem, Tooltip } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, spacing } from 'sierra-ui/theming'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

export type UserContentTableProps = {
  courses: UserDetailResponse['courses']
  paths: UserDetailResponse['paths']
  liveSessions: UserDetailResponse['liveSessions']
  userId: UserId
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  userType: UserDetailResponse['type']
}

export type UserSelfStartTableProps = {
  coursesSelfStart: UserDetailResponse['coursesSelfStart']
}

const UserContentSubRowContext = React.createContext<
  Pick<UserContentTableProps, 'setUserAction' | 'userType'> & {
    userId: UserId | undefined
  }
>({
  userId: undefined,
  userType: 'standard',
  setUserAction: () => {},
})

const MinWidth = styled.div`
  min-width: 350px;
`

const VerticalTd = styled.td`
  vertical-align: middle;
`

const ContentSubRowProgressBorder = styled.div<{ $isLast: boolean }>`
  margin-left: 4rem;
  padding: 0.5rem 2rem;

  border-left: 4px solid ${palette.grey[5]};
  ${p => p.$isLast === true && `margin-bottom: ${spacing.xsmall};`}
`

interface SubmenuProps {
  content: UserContentRow
  userId: UserId
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  userType: 'standard' | 'scorm' | 'virtual' | 'guest' | undefined
  canResetProgress: boolean
  canSetContentCompletion: boolean
}

const Submenu: React.FC<SubmenuProps> = ({
  content,
  userId,
  setUserAction,
  userType,
  canResetProgress,
  canSetContentCompletion,
}) => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        type: 'label',
        id: 'view-details',
        label: t('manage.view-course-details'),
        onClick: () => getGlobalRouter().navigate({ to: getHrefForContentDetails(content) }),
        icon: 'course',
      },
      {
        type: 'label',
        id: 'complete-content',
        hidden: userType === 'scorm' || !canSetContentCompletion,
        label: t('manage.complete-progress'),
        onClick: () => {
          setUserAction({
            modal: 'complete-content',
            userId: userId,
            contentType: content.contentType,
            contentId: content.id,
          })
          setOpen(false)
        },
        disabled: content.progress === 1,
        icon: 'checkmark--outline',
      },
      {
        type: 'label',
        id: 'reset-course',
        hidden:
          userType === 'scorm' ||
          content.courseKind === 'scorm' ||
          content.courseKind === 'scorm:course-group' ||
          !canResetProgress,
        label: t('manage.reset-progress'),
        onClick: () => {
          setUserAction({
            modal: 'reset-course',
            contentType:
              content.courseKind === 'native:course-group' || content.courseKind === 'scorm:course-group'
                ? 'course-group'
                : content.contentType,
            contentId: content.id,
          })
          setOpen(false)
        },
        disabled: content.progress === 0,
        color: 'destructive/background',
        icon: 'reset',
      },
    ],
    [canResetProgress, canSetContentCompletion, content, setUserAction, t, userId, userType]
  )

  return (
    <View justifyContent='flex-end' grow>
      <IconMenu
        iconId='overflow-menu--horizontal'
        variant='transparent'
        onSelect={item => {
          if ('onClick' in item) {
            item.onClick?.()
          }
        }}
        menuItems={menuItems}
        isOpen={open}
        onOpenChange={setOpen}
      />
    </View>
  )
}

const CoursesInPathsSubRow: TableProps<UserContentRow>['renderSubComponent'] = ({ row }) => {
  const { userId, setUserAction, userType } = useContext(UserContentSubRowContext)
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')

  const {
    original: { subComponentData },
    isExpanded,
  } = row

  if (userId === undefined || !isExpanded || subComponentData === undefined) {
    return null
  }

  return (
    <>
      {subComponentData.map((content, index) => (
        <tr key={content.id}>
          <VerticalTd>
            <ContentSubRowProgressBorder $isLast={index === subComponentData.length - 1}>
              <ContentRow
                {...content}
                href={getHrefForContentDetails(content)}
                assetContext={getAssetContextForContentDetails(content)}
              />
            </ContentSubRowProgressBorder>
          </VerticalTd>
          <VerticalTd>
            <UserContentProgressRow {...content} />
          </VerticalTd>
          <VerticalTd />
          <VerticalTd />
          <VerticalTd>
            <Submenu
              content={content}
              userId={userId}
              setUserAction={setUserAction}
              userType={userType}
              canResetProgress={canResetProgress}
              canSetContentCompletion={canSetContentCompletion}
            />
          </VerticalTd>
        </tr>
      ))}
    </>
  )
}

export const UserSelfStartTable: React.FC<UserSelfStartTableProps> = ({ coursesSelfStart }) => {
  const { t } = useTranslation()
  const { filteredItems, isLoading, filter, setFilter } = useUserAssignedContent({
    coursesSelfStart,
  })

  const [focusedContentId, setFocusedContentId] = useState<string | undefined>()

  const courseColumns: Array<Column<UserContentRow>> = React.useMemo(
    () => [
      {
        id: 'title',
        accessor: 'title',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('table.name')} smallLabel {...p} />
          </>
        ),
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content } = p.row
          return (
            <>
              <SelectableRow {...p} />
              <ContentRow
                {...content}
                href={getHrefForContentDetails(content)}
                assetContext={getAssetContextForContentDetails(content)}
              />
            </>
          )
        },
        width: '50%',
      },
      {
        accessor: 'progress',
        Header: p => <SortableHeader label={t('admin.analytics.table.progress')} smallLabel {...p} />,
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content } = p.row
          return <UserContentProgressRow {...content} isSelfStartSection />
        },
        width: '150px',
      },
      {
        id: 'actions',
        Header: '',
        width: '100px',
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content } = p.row
          const [open, setOpen] = useState(false)

          useEffect(() => {
            if (open) {
              setFocusedContentId(content.id)
            } else {
              setFocusedContentId(undefined)
            }
          }, [open, content.id])

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                id: 'view-details',
                hidden: false,
                label: t('manage.view-course-details'),
                onClick: () => getGlobalRouter().navigate({ to: getHrefForContentDetails(content) }),
                icon: 'course',
              },
            ]
          }, [content])

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                variant='transparent'
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={menuItems}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: filteredItems, columns: courseColumns, autoResetExpanded: false },
    getEntityId: s => s.id,
  })

  return (
    <View direction='column' gap='xsmall' marginTop='xsmall'>
      <View justifyContent='space-between'>
        <View>
          <GlobalFilter
            tableInstance={tableInstance}
            render={props => (
              <MinWidth>
                <RoundedSearchBar {...props} placeholder={t('dictionary.search')} />
              </MinWidth>
            )}
            debounceMs={100}
          />
          <UserContentProgressFilter
            value={filter.progress}
            onChange={value => setFilter(f => ({ ...f, progress: value }))}
          />
          <ContentTypeFilter
            value={filter.contentClassification}
            onChange={value => setFilter(f => ({ ...f, contentClassification: value }))}
          />
        </View>
      </View>
      <ManageTableSmall
        tableInstance={tableInstance}
        isLoading={isLoading}
        focusedId={focusedContentId}
        getEntityId={c => c.id}
        mapEntityToCsv={userContentRowToCsv}
        renderSubComponent={CoursesInPathsSubRow}
        $maxHeight='60vh'
        translations={{
          searchPlaceholder: t('manage.search.courses'),
          tableLoading: t('manage.courses.table-loading'),
          tableNoResults: t('manage.courses.no-results'),
          csvPrefix: t('admin.analytics.courses'),
        }}
      />
    </View>
  )
}

export const UserContentTable: React.FC<UserContentTableProps> = ({
  courses,
  paths,
  liveSessions,
  userId,
  setUserAction,
  userType,
}) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const { filteredItems, isLoading, filter, setFilter } = useUserAssignedContent({
    courses,
    paths,
    liveSessions,
  })

  const [focusedContentId, setFocusedContentId] = useState<string | undefined>(undefined)
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')

  const courseColumns: Array<Column<UserContentRow>> = React.useMemo(
    () => [
      {
        id: 'title',
        accessor: 'title',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('table.name')} smallLabel {...p} />
          </>
        ),
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content, isExpanded, getToggleRowExpandedProps } = p.row
          const { dueDate } = calculateUserDueDateMetadata(content.dueDates)

          return (
            <>
              <SelectableRow {...p} />
              <ContentRow
                {...content}
                href={getHrefForContentDetails(content)}
                assetContext={getAssetContextForContentDetails(content)}
                titleAddon={
                  dueDate === undefined ? undefined : (
                    <Label $size='small' $bgColor='grey30' $color='white'>
                      <b>
                        {t('manage.user.due-on-date-label', {
                          date: DateTime.fromISO(dueDate.date).toFormat('MMM dd yyyy'),
                        })}
                      </b>
                    </Label>
                  )
                }
                pillAddon={
                  <>
                    {content.subComponentData === undefined ? undefined : (
                      <>
                        <Spacer size='4' />
                        <Text size='small' color='foreground/muted'>
                          {narrowDotSeparator}
                        </Text>
                        <Label
                          {...getToggleRowExpandedProps()}
                          iconId={isExpanded ? 'chevron--up--small' : 'chevron--down--small'}
                          $size='large'
                          $bgColor='transparent'
                          $color='grey40'
                          iconPos='right'
                        >
                          {t('dictionary.expand')}
                        </Label>
                      </>
                    )}
                    {/* {content.liveSessions !== undefined && (
                      <Label
                        $size='small'
                        $bgColor='white'
                        $color={content.liveSessions.length === 0 ? 'redBright' : 'grey40'}
                      >
                        {content.liveSessions.length === 0 ? 'Not scheuled' : 'scheduled'}
                      </Label>
                    )} */}
                  </>
                }
              />
            </>
          )
        },
        width: '45%',
      },
      {
        accessor: 'progress',
        Header: p => <SortableHeader label={t('admin.analytics.table.progress')} smallLabel {...p} />,
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content } = p.row
          return <UserContentProgressRow {...content} />
        },
        width: '15%',
      },
      {
        accessor: 'assignmentData',
        Header: t('table.assigned-via'),
        Cell: (p: CellProps<UserContentRow>) => (
          <AssignedViaPill assignmentData={p.row.original.assignmentData} />
        ),
        width: '15%',
      },
      {
        Header: t('table.available-from'),
        Cell: ({ row }: CellProps<UserContentRow>) => {
          if (isDefined(row.original.assignmentData!.availableAt)) {
            return (
              <Text color='foreground/secondary'>
                {formatTimestamp(
                  row.original.assignmentData!.availableAt,
                  undefined,
                  undefined,
                  TrimFutureDateAsNow.Disabled
                )}
              </Text>
            )
          } else {
            return (
              <Tooltip title={t('table.user-content.tooltip.pending.text')} delayDuration={400}>
                <View gap='4' cursor='default'>
                  <Text color='foreground/secondary'>{t('table.available-from-pending')}</Text>
                  <Icon iconId='information' color='foreground/secondary' />
                </View>
              </Tooltip>
            )
          }
        },
        width: '15%',
      },
      {
        id: 'actions',
        Header: '',
        width: '10%',
        Cell: (p: CellProps<UserContentRow>) => {
          const { original: content, toggleRowExpanded } = p.row
          const { dueDate, origin } = calculateUserDueDateMetadata(content.dueDates)
          const [open, setOpen] = useState(false)

          const { fetchLiveSessions } = useManageCourseLiveSessions()
          const [courseLiveSessions, setCourseLiveSessions] = useState<LiveSessionRow[]>([])

          useEffect(() => {
            void (async () => {
              if (content.courseKind !== 'native:live') return
              if (!open) return
              // Doesn't re-fetch
              if (courseLiveSessions.length !== 0) return

              const response = await fetchLiveSessions(CourseId.parse(content.id), { skipPastSessions: true })
              setCourseLiveSessions(response)
            })()
          }, [content.courseKind, content.id, courseLiveSessions.length, open, fetchLiveSessions])

          useEffect(() => {
            if (open) {
              setFocusedContentId(content.id)
            } else {
              setFocusedContentId(undefined)
            }
          }, [open, content.id])

          const currentLiveSessionIds = content.liveSessions?.map(ls => ls.liveSessionId) ?? []

          const filteredLiveSessionsForEnrollment = courseLiveSessions.filter(ls => {
            const containsLs = currentLiveSessionIds.includes(ls.liveSessionId)
            if (containsLs) return false
            return true
          })

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                id: 'view-details',
                hidden: false,
                label: t('manage.view-course-details'),
                onClick: () => getGlobalRouter().navigate({ to: getHrefForContentDetails(content) }),
                icon: 'course',
              },
              {
                type: 'label',
                id: 'due-date',
                hidden: !canSetContentDueDates,
                label: t('due-date.set-due-date'),
                onClick: () => {
                  setUserAction({
                    modal: 'due-date',
                    contentId: content.id,
                    contentType: content.contentType,
                    currentDueDate: dueDate,
                    currentDueDateOrigin: origin,
                  })
                  setOpen(false)
                },
                icon: 'time',
              },
              {
                type: 'label',
                id: 'complete-content',
                hidden: userType === 'scorm' || !canSetContentCompletion,
                label: t('manage.complete-progress'),
                onClick: () => {
                  setUserAction({
                    modal: 'complete-content',
                    userId: userId,
                    contentType: content.contentType,
                    contentId: content.id,
                  })
                  setOpen(false)
                },
                disabled: content.progress === 1,
                icon: 'checkmark--outline',
              },
              {
                type: 'nested',
                id: 'assign-live-sessions',
                hidden: content.courseKind !== 'native:live' || !canEditAssignments,
                disabled: filteredLiveSessionsForEnrollment.length === 0,
                label: t('manage.live-session.assign-session'),
                icon: 'event--schedule',
                menuItems: filteredLiveSessionsForEnrollment.map((ls, i) => ({
                  type: 'canvas',
                  id: `live-session${i}`,
                  render() {
                    return (
                      <LiveSessionMenuItem
                        liveSession={ls}
                        targetUser={userId}
                        onDone={() => setUserAction({ modal: 'reload' })}
                        action='assign'
                      />
                    )
                  },
                })),
              },
              {
                type: 'label',
                id: 'reset-course',
                hidden:
                  userType === 'scorm' ||
                  content.courseKind === 'scorm' ||
                  content.courseKind === 'scorm:course-group' ||
                  !canResetProgress,
                label: t('manage.reset-progress'),
                onClick: () => {
                  setUserAction({
                    modal: 'reset-course',
                    contentType:
                      content.courseKind === 'native:course-group' ||
                      content.courseKind === 'scorm:course-group'
                        ? 'course-group'
                        : content.contentType,
                    contentId: content.id,
                  })
                  setOpen(false)
                },
                disabled: content.progress === 0,
                color: 'destructive/background',
                icon: 'reset',
              },
              {
                type: 'label',
                id: 'unassign',
                hidden: content.assignmentData?.type !== 'individual' || !canEditAssignments,
                label: t('dictionary.unassign'),
                onClick: () => {
                  toggleRowExpanded(false)
                  setUserAction({
                    modal: 'unassign',
                    messageType: 'content',
                    targets: [{ id: content.id, type: content.contentType }],
                  })
                  setOpen(false)
                },
                color: 'destructive/background',
                icon: 'user--remove',
              },
            ]
          }, [content, dueDate, origin, toggleRowExpanded, filteredLiveSessionsForEnrollment])

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                variant='transparent'
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={menuItems}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
      },
    ],
    [
      t,
      formatTimestamp,
      userType,
      canResetProgress,
      canSetContentCompletion,
      canSetContentDueDates,
      canEditAssignments,
      setUserAction,
      userId,
    ]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: filteredItems, columns: courseColumns, autoResetExpanded: false },
    getEntityId: s => s.id,
  })
  const selectedContent = tableInstance.selectedFlatRows.map(r => r.original)
  const hasSelectedOnlyIndividualAssigned = selectedContent.every(
    c => c.assignmentData?.type === 'individual'
  )

  const userContentSubRowContextValue = useMemo(
    () => ({ userId, setUserAction, userType }),
    [setUserAction, userId, userType]
  )

  return (
    <View direction='column' gap='xsmall' marginTop='xsmall'>
      <View justifyContent='space-between'>
        <View>
          <GlobalFilter
            tableInstance={tableInstance}
            render={props => (
              <MinWidth>
                <RoundedSearchBar {...props} placeholder={t('dictionary.search')} />
              </MinWidth>
            )}
            debounceMs={100}
          />
          <UserContentProgressFilter
            value={filter.progress}
            onChange={value => setFilter(f => ({ ...f, progress: value }))}
          />
          <ContentTypeFilter
            value={filter.contentClassification}
            onChange={value => setFilter(f => ({ ...f, contentClassification: value }))}
          />
        </View>

        {canEditAssignments && (
          <Button onClick={() => setUserAction({ modal: 'assign-content' })}>
            {t('admin.organization.paths.enroll')}
          </Button>
        )}
      </View>
      {/* @TODO move bulk actions away from the table header */}
      {/* <View>
        <TableFooterBulkActionsRow
          tableInstance={tableInstance}
          csv={{ mapEntityToCsv: userContentRowToCsv, filePrefix: t('dictionary.content-singular') }}
        >
          <TableFooterBulkActionPill
            disabled={!hasSelectedOnlyIndividualAssigned}
            onClick={() =>
              setUserAction({
                modal: 'unassign',
                messageType: 'content',
                targets: selectedContent.map(c => ({ id: c.id, type: c.contentType })),
              })
            }
          >
            {t('dictionary.unassign')}
          </TableFooterBulkActionPill>
        </TableFooterBulkActionsRow>
      </View> */}
      <UserContentSubRowContext.Provider value={userContentSubRowContextValue}>
        <ManageTableSmall
          tableInstance={tableInstance}
          isLoading={isLoading}
          focusedId={focusedContentId}
          getEntityId={c => c.id}
          mapEntityToCsv={userContentRowToCsv}
          renderSubComponent={CoursesInPathsSubRow}
          $maxHeight='60vh'
          bulkActions={
            <>
              {hasSelectedOnlyIndividualAssigned && canEditAssignments && (
                <ActionButton
                  color='redBright'
                  // Action button does not support disabled!!!
                  disabled={!hasSelectedOnlyIndividualAssigned}
                  onClick={() =>
                    setUserAction({
                      modal: 'unassign',
                      messageType: 'content',
                      targets: selectedContent.map(c => ({ id: c.id, type: c.contentType })),
                    })
                  }
                >
                  {t('dictionary.unassign')}
                </ActionButton>
              )}
            </>
          }
          translations={{
            searchPlaceholder: t('manage.search.courses'),
            tableLoading: t('manage.courses.table-loading'),
            tableNoResults: t('manage.courses.no-results'),
            csvPrefix: t('admin.analytics.courses'),
          }}
        />
      </UserContentSubRowContext.Provider>
    </View>
  )
}
