import { motion } from 'framer-motion'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { pictureInPictureAtom } from 'sierra-client/features/sana-now/picture-in-picture-atom'
import { Icon } from 'sierra-ui/components'
import { IconButton, Spacer, Text } from 'sierra-ui/primitives'
import { LightTokenProvider } from 'sierra-ui/theming'
import styled from 'styled-components'

const CloseButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'close' })`
  position: absolute;
  top: 16px;
  right: 16px;
`

const PictureInPictureContainer = styled(motion.div)<{ $open: boolean }>`
  position: relative;
  height: 100%;
  transition: width 0.3s;
  width: ${p => (p.$open ? '300px' : '0')};
  background: rgba(242, 242, 242);
  display: grid;
  place-items: center;

  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.04),
    inset 30px 0 20px -15px rgba(0, 0, 0, 0.04);
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`

const TextContainer = styled.div<{ $open: boolean }>`
  opacity: ${p => (p.$open ? 1 : 0)};
  transition: opacity 0.3s;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
`

export const PictureInPictureArea: React.FC = () => {
  const [pictureInPictureState, setPictureInPictureState] = useAtom(pictureInPictureAtom)
  const [textVisible, setTextVisible] = useState(false)

  const pictureInPictureEnabled = getFlag('sana-now-picture-in-picture')

  useEffect(() => {
    if (pictureInPictureState.open) {
      setTimeout(() => {
        setTextVisible(true)
      }, 300)
    } else {
      setTextVisible(false)
    }
  }, [pictureInPictureState.open])

  if (!pictureInPictureEnabled) {
    return null
  }

  return (
    <LightTokenProvider>
      <PictureInPictureContainer $open={pictureInPictureState.open}>
        {pictureInPictureState.open && (
          <ContentContainer>
            <CloseButton onClick={() => setPictureInPictureState({ open: false })} />
            <Icon iconId='breakout-room' size='size-48' color='foreground/muted' />

            <Spacer size='16' />

            <TextContainer $open={textVisible}>
              <Text align='center' color='foreground/muted'>
                Meeting squares align
              </Text>

              <Text align='center' color='foreground/muted'>
                Friendly faces peer through screens
              </Text>

              <Text align='center' color='foreground/muted'>
                Mosaic of smiles
              </Text>
            </TextContainer>
          </ContentContainer>
        )}
      </PictureInPictureContainer>
    </LightTokenProvider>
  )
}
