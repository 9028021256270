import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsers } from 'sierra-client/state/users/hooks'
import { useCreateDateTime, useDateAndTimeAgo } from 'sierra-client/utils/date-utils'
import { UserIdAvatars } from 'sierra-client/views/flexible-content/editor/content-sidebar/user-id-avatars'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { isDefined } from 'sierra-domain/utils'
import { History } from 'sierra-domain/version-history/get-updates-meta'
import { GroupedUpdate } from 'sierra-domain/version-history/group-updates'
import { FoldingColumn } from 'sierra-ui/components'
import { ScrollView, Skeleton, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const DuplicateContainer = styled.div`
  margin-left: auto;
`

const UpdateLi = styled.li<{ $selected: boolean }>`
  background-color: ${p => (p.$selected ? token('foreground/primary').opacity(0.05) : 'transparent')};
  transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: 10px;

  &:hover {
    background-color: ${p =>
      p.$selected ? token('foreground/primary').opacity(0.05) : token('foreground/primary').opacity(0.025)};
  }

  ${DuplicateContainer} {
    opacity: 0;
  }

  &:hover ${DuplicateContainer} {
    opacity: 1;
  }
`

const UpdateButton = styled.button<{ $showBorder: boolean }>`
  width: 100%;
  height: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${token('border/default')};
    &:hover {
      border-color: transparent;
    }

    ${p =>
      p.$showBorder === false &&
      css`
        border-color: transparent;
      `}

    padding-block: 12px;
  }

  background: transparent;

  border-radius: 8px;
  padding-block: 0px;
  padding-inline: 16px;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const UserDataSkeleton: React.FC = () => (
  <View paddingLeft='2' paddingRight='4'>
    <Skeleton $radius='100%' $height={20} $width={20} />
  </View>
)

const UserData: React.FC<{ update: GroupedUpdate }> = ({ update }) => {
  const users = useUsers(update.userIds)
  const hasLoadingUsers = users.some(user => user.status === 'loading')

  if (hasLoadingUsers) return <UserDataSkeleton />
  else return <UserIdAvatars animateAvatars={false} userIds={update.userIds} />
}

export const VersionHistoryRightFoldingColumn = styled(FoldingColumn)`
  background-color: ${token('surface/default')};
  color: ${token('foreground/primary')};
  border: 1px solid ${token('border/default')};
  border-radius: 0.5rem;
  overflow: hidden;
`

const Scrollable = styled(ScrollView).attrs({ as: 'ul' })`
  height: 100%;
  overflow: auto;
  padding: 0 8px;
`

function useWaitForElement(ref: React.RefObject<HTMLDivElement | null>): HTMLDivElement | null {
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let isCancelled = false

    function loop(): void {
      if (isCancelled) return
      else if (ref.current === null) requestAnimationFrame(loop)
      else setElement(ref.current)
    }

    loop()

    return () => {
      isCancelled = true
    }
  }, [ref])

  return element
}

const RightSidebarContainer = styled.div`
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`
const CapitalizedText = styled(Text)`
  display: flex;
  flex-direction: row;
  gap: 4px;

  &::first-letter {
    text-transform: uppercase;
  }
`

const Month = styled(CapitalizedText)`
  color: ${token('foreground/muted')};
`

const SidebarMonth: React.FC<{ dateTime: DateTime }> = ({ dateTime }) => {
  const text = useMemo(() => dateTime.toLocaleString({ month: 'long', year: 'numeric' }), [dateTime])

  return (
    <View padding='8 16'>
      <Month color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
        {text}
      </Month>
    </View>
  )
}

const SidebarDateSkeleton: React.FC = () => (
  <Text>
    <Skeleton $width={100} $height={16} $radius={4} />
  </Text>
)

const SidebarDate: React.FC<{ dateTime: DateTime; selected: boolean; isCurrentVersion: boolean }> = ({
  dateTime,
  selected,
  isCurrentVersion,
}) => {
  const { t } = useTranslation()
  const { formattedDate } = useDateAndTimeAgo(dateTime)

  return (
    <CapitalizedText>
      <Text bold={selected}>
        {isCurrentVersion ? t('version-history.right-sidebar-current-version') : formattedDate}
      </Text>
    </CapitalizedText>
  )
}

type SidebarUpdate = History['updates'][number] & {
  dateTime: DateTime
  isCurrentVersion: boolean
  targetIndex: number
  type: 'update'
}
type SidebarDay = { dateTime: DateTime; type: 'month' }
type SidebarItem = SidebarUpdate | SidebarDay

const SidebarUpdateSkeleton: React.FC<{ showBorder: boolean }> = ({ showBorder }) => (
  <UpdateLi $selected={false}>
    <UpdateButton $showBorder={showBorder}>
      <SidebarDateSkeleton />

      <UserDataSkeleton />
    </UpdateButton>
  </UpdateLi>
)

const SidebarSkeleton: React.FC = () => (
  <Scrollable>
    <View padding='8 16'>
      <Skeleton $radius={4} $height={16} $width={80} />
    </View>

    <SidebarUpdateSkeleton showBorder={true} />
    <SidebarUpdateSkeleton showBorder={true} />
    <SidebarUpdateSkeleton showBorder={false} />
  </Scrollable>
)

const SidebarUpdate = (props: {
  setTargetIndex: React.Dispatch<React.SetStateAction<number>>
  update: SidebarUpdate
  selected: boolean
  showBorder: boolean
  isCurrentVersion: boolean
}): JSX.Element | null => {
  const { setTargetIndex, update, selected, showBorder, isCurrentVersion } = props

  const ref = useRef<HTMLLIElement | null>(null)
  useScrollToView(ref, { shouldScroll: selected })
  return (
    <UpdateLi ref={ref} $selected={selected} onClick={() => setTargetIndex(update.targetIndex)}>
      <UpdateButton $showBorder={showBorder}>
        <div>
          <SidebarDate dateTime={update.dateTime} selected={selected} isCurrentVersion={isCurrentVersion} />
          <UserData update={update} />
        </div>
      </UpdateButton>
    </UpdateLi>
  )
}

function groupSidebarUpdatesByMonth(updates: SidebarUpdate[]): SidebarItem[] {
  const groupedUpdates: SidebarItem[] = []

  for (const update of updates) {
    const lastItem = groupedUpdates[groupedUpdates.length - 1]

    if (lastItem === undefined || !lastItem.dateTime.hasSame(update.dateTime, 'month')) {
      groupedUpdates.push({ dateTime: update.dateTime, type: 'month' }, update)
    } else if (update.userIds.length !== 0) {
      // if there are no user ids it's an update from the backend, we don't want to show that to the users
      groupedUpdates.push(update)
    }
  }

  return groupedUpdates
}

const ScrollContainer = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`

const SidebarContent = ({
  history,
  nodeId,
  targetIndex,
  setTargetIndex,
  showFullHistory,
}: {
  history: History
  nodeId: FileId | undefined
  targetIndex: number
  setTargetIndex: React.Dispatch<React.SetStateAction<number>>
  showFullHistory: boolean
}): JSX.Element | null => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const scrollElement = useWaitForElement(scrollRef)

  const createDateTime = useCreateDateTime()

  const relevantUpdates = useMemo((): SidebarUpdate[] => {
    const updates: SidebarUpdate[] = history.updates
      .map(
        (update, index, updates): SidebarUpdate => ({
          ...update,
          type: 'update',
          targetIndex: index,
          isCurrentVersion: index === updates.length - 1,
          dateTime: createDateTime(update.createdAt),
        })
      )
      // We never care about the first update since it is generated by our backend
      .filter(it => it.targetIndex > 0)
      .reverse()

    if (showFullHistory) return updates
    else
      return updates.filter(update => {
        if (nodeId === undefined) return true
        else return history.updatesMeta[nodeId]?.some(it => it.targetIndex === update.targetIndex)
      })
  }, [history, nodeId, showFullHistory, createDateTime])

  const sidebarItems = useMemo(() => groupSidebarUpdatesByMonth(relevantUpdates), [relevantUpdates])

  const isSelected = useCallback(
    (index: number) => {
      const update = sidebarItems[index]
      if (update === undefined) return false
      if (update.type === 'month') return false
      return targetIndex === update.targetIndex
    },
    [sidebarItems, targetIndex]
  )

  return (
    <ScrollContainer
      onKeyDown={e => {
        const currentRelevantUpdateIndex = relevantUpdates.findIndex(
          update => update.targetIndex === targetIndex
        )
        if (currentRelevantUpdateIndex === -1) return

        const nextRelevantTargetIndex = relevantUpdates[currentRelevantUpdateIndex + 1]?.targetIndex
        const previousRelevantTargetIndex = relevantUpdates[currentRelevantUpdateIndex - 1]?.targetIndex
        if (e.key === 'ArrowDown') {
          if (isDefined(nextRelevantTargetIndex)) setTargetIndex(nextRelevantTargetIndex)
          e.preventDefault()
        }
        if (e.key === 'ArrowUp') {
          if (isDefined(previousRelevantTargetIndex)) setTargetIndex(previousRelevantTargetIndex)
          e.preventDefault()
        }
      }}
    >
      <Scrollable ref={scrollRef} grow gap='none'>
        <ListVirtualizer<SidebarItem>
          scrollElement={scrollElement}
          items={sidebarItems}
          estimateSize={64} // Estimated size of UpdateContainer element in the DOM.
          renderItem={(item, index) =>
            item.type === 'update' ? (
              <SidebarUpdate
                key={item.id}
                update={item}
                setTargetIndex={setTargetIndex}
                selected={isSelected(index)}
                isCurrentVersion={item.isCurrentVersion}
                showBorder={
                  // Do not show a border on the last element
                  index !== sidebarItems.length - 1 &&
                  // Do not show a border if the next item is selected
                  !isSelected(index + 1)
                }
              />
            ) : (
              <SidebarMonth key={item.dateTime.toMillis()} dateTime={item.dateTime} />
            )
          }
        />
      </Scrollable>
    </ScrollContainer>
  )
}

const HorizontalLine = styled.div`
  margin: 0 36px 0 24px;
  border-top: 1px solid ${token('border/default')};
`

const SwitchContainer = styled(View)`
  padding: 24px 16px 24px 20px;
  * {
    color: ${token('foreground/muted')};
    gap: 8px;
  }
`

export const VersionHistoryRightSidebar = ({
  history,
  ...props
}: {
  history: History | undefined
  nodeId: FileId | undefined
  targetIndex: number
  setTargetIndex: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element | null => {
  const { t } = useTranslation()
  const [showFullHistory, { toggle }] = useToggle(true)

  return (
    <RightSidebarContainer>
      <View padding='16 24' direction='column'>
        <Text bold>{t('version-history.right-sidebar-title')}</Text>
        <Text color='foreground/muted'>{t('version-history.right-sidebar-description')}</Text>
      </View>
      <HorizontalLine />
      {isDefined(history) ? (
        <SidebarContent {...props} history={history} showFullHistory={showFullHistory} />
      ) : (
        <SidebarSkeleton />
      )}
      <HorizontalLine />
      <SwitchContainer>
        <Switch
          size='small'
          checked={!showFullHistory}
          onChange={toggle}
          text={t('version-history.right-sidebar-full-history-toggle')}
        />
      </SwitchContainer>
    </RightSidebarContainer>
  )
}
