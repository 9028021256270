import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { color } from 'sierra-ui/color'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Placeholder = styled.span`
  user-select: none;
  pointer-events: none;
  cursor: text;
  margin-right: 1.5rem;
  color: ${p => color(p.theme.home.textColor).opacity(0.5).toString()};
`
const Container = styled.div`
  background-color: ${p =>
    color(p.theme.home.backgroundColor).under(color(p.theme.home.textColor).opacity(0.03))};
`
export const MatrixOption: SlateFC = ({ element, children }) => {
  assertElementType('matrix-option', element)
  const isEmpty = hasOnlyEmptyTextInNodes([element])
  const { t } = useTranslation()

  return (
    <Container>
      <Text size='large' bold color='foreground/secondary'>
        {isEmpty && (
          <Placeholder contentEditable={false}>{t('author.sliding-scale.add-statement')}</Placeholder>
        )}
        {children}
      </Text>
    </Container>
  )
}
