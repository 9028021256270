import {
  DefaultError,
  FetchQueryOptions,
  MutationOptions,
  QueryClient,
  QueryKey,
  queryOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import FormData from 'form-data'
import { useCallback } from 'react'
import { FetchParams, postZod } from 'sierra-client/api'
import { queryClient } from 'sierra-client/api/query-client'
import { postSseZod } from 'sierra-client/api/sse'
import { Auth } from 'sierra-client/core/auth'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import {
  addNotification,
  reportUserError,
  toNotificationState,
} from 'sierra-client/state/notifications/actions'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import { AnyZodRoute, AnyZodSseRoute, ZodRouteInput, ZodRouteOutput } from 'sierra-domain/api/types'
import { Either, isLeft, left, right } from 'sierra-domain/either'
import { parseUserErrorCode, RequestError, UserError, UserErrorCode } from 'sierra-domain/error'
import { SseEvent } from 'sierra-domain/routes-sse'
import { retry } from 'ts-retry-promise'

type PostOptions = {
  notifications?: boolean
}

export const postWithUserErrorException = async <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  dispatch: RootStateThunkDispatch,
  postOptions: PostOptions = {}
): Promise<ZodRouteOutput<Route>> => {
  let result
  try {
    result = await postZod(route, input)
  } catch (e) {
    if (postOptions.notifications ?? false)
      void dispatch(addNotification(toNotificationState({ type: 'error' })))
    if (
      e instanceof RequestError &&
      (e.status === 401 || e.status === 403) &&
      route.path !== '/x-realtime/user/me' &&
      route.path !== '/x-realtime/auth/check-session'
    ) {
      // Call `synchronize` to check the session and maybe redirect to the login view.
      // NOTE: `synchronize` calls `postWithUserErrorException`, so it's important that we
      // exclude the endpoints it calls in the condition here to avoid an infinite loop.
      await Auth.getInstance().synchronize()
    }

    throw e
  }

  if (isLeft(result)) {
    if (postOptions.notifications ?? false)
      void dispatch(addNotification(toNotificationState({ type: 'error' })))
    void dispatch(reportUserError({ code: parseUserErrorCode(result.left.code) }))
    throw new UserError(result.left.code, result.left.message)
  }

  return Promise.resolve(result.right)
}

export const postWithUserErrorCode = async <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  dispatch: RootStateThunkDispatch,
  options: PostOptions = {}
): Promise<Either<UserErrorCode, ZodRouteOutput<Route>>> => {
  let result
  try {
    result = await postZod(route, input)
  } catch (e) {
    if (options.notifications ?? false) void dispatch(addNotification(toNotificationState({ type: 'error' })))
    throw e
  }

  if (isLeft(result)) return left(parseUserErrorCode(result.left.code))

  return right(result.right)
}

export const subscribeSse = async <Route extends AnyZodSseRoute>({
  route,
  input,
  onMessage,
  onClose,
  signal,
  onError,
}: {
  route: Route
  input: ZodRouteInput<Route>
  onMessage?: (event: SseEvent<ZodRouteOutput<Route>>) => void
  onError?: (error: unknown) => void
  onClose?: () => void
  signal?: AbortSignal
}): Promise<SseEvent<ZodRouteOutput<Route>>[]> => {
  const eventList: SseEvent<ZodRouteOutput<Route>>[] = []
  await postSseZod(
    route,
    input,
    message => {
      eventList.push(message)
      onMessage?.(message)
    },
    res => {
      if (!res.ok) {
        onError?.(new Error('Failed to establish SSE stream ' + JSON.stringify(res)))
      }
    },
    err => {
      onError?.(err)
    },
    onClose,
    signal
  )
  return eventList
}

export const typedPost = async <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  fetchParams: FetchParams = {}
): Promise<ZodRouteOutput<Route>> => {
  try {
    const result = await postZod(route, input, fetchParams)
    if (isLeft(result)) {
      throw new UserError(result.left.code, result.left.message)
    }

    return result.right
  } catch (e) {
    if (
      e instanceof RequestError &&
      (e.status === 401 || e.status === 403) &&
      route.path !== '/x-realtime/user/me' &&
      route.path !== '/x-realtime/auth/check-session'
    ) {
      // Call `synchronize` to check the session and maybe redirect to the login view.
      // NOTE: `synchronize` calls `postWithUserErrorException`, so it's important that we
      // exclude the endpoints it calls in the condition here to avoid an infinite loop.
      await Auth.getInstance().synchronize()
    }

    throw e
  }
}

/**
 * Same as `typedPost`, but retries the request up to 3 times if it fails with a non-access error.
 * IMPORTANT: Not all requests are reliably retryable, so use this with caution.
 */
export const typedPostWithRetry = async <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData
): Promise<ZodRouteOutput<Route>> =>
  retry(() => typedPost(route, input), {
    retries: 3,
    timeout: 'INFINITELY',
    backoff: 'LINEAR',
    retryIf: error => !RequestError.isAccessError(error) && !RequestError.isNotFound(error),
  })

/**
 * Get the react-query query key for a given route.
 * Useful if you need to interact with the query cache directly.
 */
export const getCachedQueryKey = <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData
): QueryKey => {
  return [route.path, input]
}

export type CachedQueryOptions<TQueryFnData = unknown, TData = TQueryFnData, TError = DefaultError> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData>,
  'queryKey'
>

export const useInvalidateCachedQuery = <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route>
): (() => Promise<void>) => {
  const queryClient = useQueryClient()
  return useStableFunction(() => queryClient.invalidateQueries({ queryKey: getCachedQueryKey(route, input) }))
}

function getQueryFn<Route extends AnyZodRoute>(route: Route, input: ZodRouteInput<Route> | FormData) {
  return () => typedPost(route, input)
}

export const useCachedQuery = <
  Route extends AnyZodRoute,
  TData = ZodRouteOutput<Route>,
  TSelectData = TData,
  TError = DefaultError,
>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  options?: Omit<UseQueryOptions<TData, TError, TSelectData>, 'queryKey'>
): UseQueryResult<TSelectData, TError> => {
  const queryKey = getCachedQueryKey(route, input)
  return useQuery<TData, TError, TSelectData>({
    queryKey,
    queryFn: getQueryFn(route, input),
    ...options,
  })
}

export function prefetchCachedQuery<
  Route extends AnyZodRoute,
  TData = ZodRouteOutput<Route>,
  TSelectData = TData,
  TError = DefaultError,
>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  options?: Omit<FetchQueryOptions<TData, TError, TSelectData>, 'queryKey'>
): Promise<void> {
  const queryKey = getCachedQueryKey(route, input)

  return queryClient.prefetchQuery<TData, TError, TSelectData>({
    queryKey,
    queryFn: getQueryFn(route, input),
    ...options,
  })
}

/**
 * Type helper to define the query options for a given route. Use like so:
 *
 * ```ts
 * export const myQuery = {
 *   ...apiQueryOptions(MyXRealtimeRoute, {}),
 *   staleTime: 10_000,
 * } as const satisfies RouteQueryOptions<typeof MyXRealtimeRoute>
 * ```
 */
export type RouteQueryOptions<Route extends AnyZodRoute> = UseQueryOptions<ZodRouteOutput<Route>>

/**
 * Similar to react-query's {@link queryOptions}, but with a standardized query key and query function.
 * @returns query options with a set key and function
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function apiQueryOptions<TRoute extends AnyZodRoute, TError = RequestError>(
  route: TRoute,
  input: ZodRouteInput<TRoute> | FormData,
  options?: Omit<UseQueryOptions<ZodRouteOutput<TRoute>, TError>, 'queryKey' | 'initialData'> & {
    queryKey?: QueryKey
  }
) {
  return queryOptions({
    queryKey: getCachedQueryKey(route, input),
    queryFn: () => typedPost(route, input),
    ...options,
  })
}

export type TypedMutation<Route extends AnyZodRoute, TContext = unknown> = UseMutationResult<
  ZodRouteOutput<Route>,
  RequestError,
  ZodRouteInput<Route>,
  TContext
>

export const useTypedMutation = <Route extends AnyZodRoute, TContext = unknown>(
  route: Route,
  options: MutationOptions<ZodRouteOutput<Route>, RequestError, ZodRouteInput<Route>, TContext> = {}
): TypedMutation<Route, TContext> => {
  return useMutation({
    mutationFn: (params: ZodRouteInput<Route>) => typedPost(route, params),
    ...options,
  })
}

export type InvalidateQuery = () => Promise<void>
export type InvalidateQueries = () => Promise<void[]>

/**
 * @deprecated Since it is easy to accidentally invalidate all caches by a route.
 * Use one of the following instead:
 * - `typedInvalidateQuery`
 * - `typedInvalidateAllQueries`
 */
export const useTypedInvalidateQueries = (queryKeys: AnyZodRoute[]): InvalidateQueries => {
  const queryClient = useQueryClient()

  const invalidate = useCallback<InvalidateQueries>(
    () =>
      Promise.all(
        queryKeys.map(queryKey =>
          queryClient.invalidateQueries({
            queryKey: [queryKey.path],
          })
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient]
  )

  return invalidate
}

export type InvalidateReturnType = ReturnType<QueryClient['invalidateQueries']>

/**
 * Invalidate a single query in the cache.
 */
export const typedInvalidateQuery = <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route>
): InvalidateReturnType => {
  const queryKey = getCachedQueryKey(route, input)
  return queryClient.invalidateQueries({ queryKey })
}

/**
 * Invalidate all queries in the cache for a given route.
 */
export const typedInvalidateAllQueries = <Route extends AnyZodRoute>(route: Route): InvalidateReturnType => {
  const queryKey = [route.path]
  return queryClient.invalidateQueries({ queryKey })
}
