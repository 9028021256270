import { Auth } from 'sierra-client/core/auth/index'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import { statusChanged } from 'sierra-client/state/user/actions'
import { UserAuthStatus } from 'sierra-client/state/user/user-types'
import { XRealtimeUserMe } from 'sierra-domain/routes'

export class TokenAuth extends Auth {
  constructor(
    public dispatch: RootStateThunkDispatch,
    private token: string
  ) {
    super()
  }

  override initialize(): void {
    void (async () => {
      try {
        const user = await postWithUserErrorException(XRealtimeUserMe, {}, this.dispatch)
        const status: UserAuthStatus = { type: 'user', user: user }
        this.dispatch(statusChanged(status))
      } catch {
        const status: UserAuthStatus = { type: 'loading', loaded: true }
        this.dispatch(statusChanged(status))
      }
    })()
  }

  // NOTE: `postWithUserErrorException` can call `synchronize`. Make sure to exclude any endpoints called
  // here from the condition in that function to avoid infinite recursion.
  override async synchronize(): Promise<void> {
    try {
      const user = await postWithUserErrorException(XRealtimeUserMe, {}, this.dispatch)
      const status: UserAuthStatus = { type: 'user', user: user }
      this.dispatch(statusChanged(status))
    } catch {
      const status: UserAuthStatus = { type: 'loading', loaded: true }
      this.dispatch(statusChanged(status))
    }
  }

  override getToken(): string {
    return this.token
  }
}
