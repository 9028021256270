import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { VersionHistoryPage } from 'sierra-client/editor/version-history/page'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const SplatParamSchema = z.tuple([SelfPacedContentId]).or(z.tuple([SelfPacedContentId, FileId]))

function Page(): JSX.Element | null {
  const [contentId, nodeId] = Route.useParams({
    select: params => SplatParamSchema.parse(params._splat?.split('/')),
  })

  const scopedContentId = ScopedSelfPacedContentId.fromId(contentId)

  const assetContext = { type: 'course' as const, courseId: contentId }

  return <VersionHistoryPage scopedContentId={scopedContentId} nodeId={nodeId} assetContext={assetContext} />
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/history/s/$')({
  component: RouteComponent as React.FC,
})
