import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { NativeCreateAccountFromInviteCode } from 'sierra-client/views/authentication/native/create-account-from-invite-code'
import { z } from 'zod'

const AcceptInvitePage = (): JSX.Element => {
  const { inviteCode } = Route.useSearch()

  return (
    <SanaPage mode='dark' headerType='auth' page={PageIdentifier.AuthenticationLogin()}>
      {inviteCode === undefined ? null : <NativeCreateAccountFromInviteCode code={inviteCode} />}
    </SanaPage>
  )
}

export const Route = createFileRoute('/accept-invite')({
  component: AcceptInvitePage,
  validateSearch: z.object({
    inviteCode: z.string().optional(),
  }),
})
