import { useCallback, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { config } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Brand, CustomTheme, CustomThemes, Fonts, OrganizationSettings } from 'sierra-domain/api/private'
import { XRealtimeAdminSettingsSetOrganizationSettings } from 'sierra-domain/routes'

export const DEFAULT_FONTS: Fonts = {
  regular: '',
  regularOriginalFileName: null,
  bold: '',
  boldOriginalFileName: null,
  italic: '',
  italicOriginalFileName: null,
  boldItalic: '',
  boldItalicOriginalFileName: null,
  headline: '',
  headlineOriginalFileName: null,
  headlineItalic: '',
  headlineItalicOriginalFileName: null,
  headlineBold: '',
  headlineBoldOriginalFileName: null,
  headlineBoldItalic: '',
  headlineBoldItalicOriginalFileName: null,
}

export const DEFAULT_CUSTOM_THEME: CustomTheme = {
  backgroundColor: '',
  foregroundColor: '',
  customThemeName: '',
}

export const DEFAULT_CUSTOM_THEMES: CustomThemes = {
  customTheme1: null,
  customTheme2: null,
  customTheme3: null,
  customTheme4: null,
  customTheme5: null,
  customTheme6: null,
  customTheme7: null,
  customTheme8: null,
  customTheme9: null,
  customTheme10: null,
  customTheme11: null,
  customTheme12: null,
  customTheme13: null,
  customTheme14: null,
  customTheme15: null,
  customTheme16: null,
  customTheme17: null,
  customTheme18: null,
  customTheme19: null,
  customTheme20: null,
  customTheme21: null,
  customTheme22: null,
  customTheme23: null,
  customTheme24: null,
  customTheme25: null,
  customTheme26: null,
  customTheme27: null,
  customTheme28: null,
}

const DEFAULT_BRAND: Brand = {
  interfaceTheme: null,
  defaultTheme: null,
  customThemes: {
    customTheme1: null,
    customTheme2: null,
    customTheme3: null,
    customTheme4: null,
    customTheme5: null,
    customTheme6: null,
    customTheme7: null,
    customTheme8: null,
    customTheme9: null,
    customTheme10: null,
    customTheme11: null,
    customTheme12: null,
    customTheme13: null,
    customTheme14: null,
    customTheme15: null,
    customTheme16: null,
    customTheme17: null,
    customTheme18: null,
    customTheme19: null,
    customTheme20: null,
    customTheme21: null,
    customTheme22: null,
    customTheme23: null,
    customTheme24: null,
    customTheme25: null,
    customTheme26: null,
    customTheme27: null,
    customTheme28: null,
  },
  logo: null,
  squareLogo: null,
  pageTitle: null,
  welcomeMessage: null,
  platformName: null,
  platformDescription: null,
  favicon: null,
  fonts: DEFAULT_FONTS,
}

export const DEFAULT_ORGANIZATION_SETTINGS: OrganizationSettings = {
  brand: DEFAULT_BRAND,
  preferredTimezoneId: null,
  meetingToolsSettings: {
    meetSettings: {
      enabled: false,
    },
    teamsSettings: {
      enabled: false,
    },
    zoomSettings: {
      enabled: false,
    },
    sanaSettings: {
      enabled: true,
    },
  },
}

type UseOrganizationSettings = {
  settings: OrganizationSettings
  updateSettings: (settings: OrganizationSettings) => Promise<void>
}

export const useOrganizationSettings = (): UseOrganizationSettings => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const orgConfig = config.organization
  const notifications = useNotif()
  const [settings, setSettings] = useState<OrganizationSettings>(orgConfig.settings)

  const updateSettings = useCallback(
    async (settings: OrganizationSettings) => {
      await postWithUserErrorException(XRealtimeAdminSettingsSetOrganizationSettings, settings)
      setSettings(settings)

      notifications.push({
        type: 'custom',
        level: 'success',
        body: t('notifications.done'),
      })

      window.location.reload()
    },
    [postWithUserErrorException, t, notifications]
  )

  return {
    settings,
    updateSettings,
  }
}
