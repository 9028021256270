import _ from 'lodash'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { IconId, iconIdList } from 'sierra-ui/components'

export type Fiber = {
  type: unknown

  // Time it took this node to render
  actualDuration?: number
  // Time that it took the subtree to render
  treeBaseDuration?: number

  child?: Fiber | null
  sibling?: Fiber | null
  return: Fiber | null
  memoizedProps?: {
    icon?: string
    iconId?: string
  }
  pendingProps?: {
    icon?: string
    iconId?: string
  }
  elementType?: {
    name?: string
    styledComponentId?: string
    render?: {
      displayName?: string
    }
    // MUI components set this
    options?: { name?: string }
  }
  stateNode?: HTMLElement
  _debugOwner?: {
    _debugOwner?: Fiber['_debugOwner']
    _debugSource?: Fiber['_debugSource']
  }
  _debugSource?: {
    fileName?: string
    lineNumber?: number
    columnNumber?: number
  }
}

export type DebugState = {
  domNode: HTMLElement
  pathToParent: [Fiber, ...Fiber[]]
  metadata: { iconIds?: [IconId, ...IconId[]] }
}

type DOMNode = HTMLElement & Record<string, Fiber>

function clientFileName(fileName: string): string {
  const fileNameParts = fileName.split('yarn-project-root/client/')
  return fileNameParts[1] ?? fileName
}

function pathToRoot(fiber: Fiber): Fiber[] {
  const path: Fiber[] = []
  let current: Fiber | null = fiber
  while (current !== null) {
    path.push(current)
    current = current.return
  }
  return path
}

export function findDomNode(fiber: Fiber): HTMLElement | undefined {
  function find(node: Fiber, depth = 0): HTMLElement | undefined {
    if (node.stateNode) return node.stateNode
    if (node.child) return find(node.child, depth + 1)
    return undefined
  }

  return find(fiber)
}

/**
 * Traverse up the react tree until a fiber is found which satisfies the `where` predicate.
 */
function findFiber(
  fiber: Fiber,
  { where, maxDepth = 100 }: { where: (_: Fiber) => boolean; maxDepth?: number }
): { match: Fiber; path: [Fiber, ...Fiber[]] } | undefined {
  function findFiberInner(fiber: Fiber, path: [Fiber, ...Fiber[]]): ReturnType<typeof findFiber> {
    if (path.length > maxDepth) return undefined
    const result = where(fiber)
    if (result) return { match: fiber, path }
    const parent = fiber.return
    if (!parent) return undefined

    return findFiberInner(parent, [...path, parent])
  }

  return findFiberInner(fiber, [fiber])
}

function debugField(fiber: Fiber): Fiber['_debugSource'] | undefined {
  function findRecursively(node: Fiber | Fiber['_debugOwner'], depth = 0): Fiber['_debugSource'] | undefined {
    if (depth > 10) return undefined

    if (!node) return undefined

    if (node._debugSource) return node._debugSource

    const owner = node._debugOwner
    if (!owner) return undefined
    else return findRecursively(owner, depth + 1)
  }

  return findRecursively(fiber)
}

/**
 * We typically want to ignore the components in client/ui and client/components/common, as well as material ui components
 */
function isInIgnoredDirectory(fiber: Fiber): boolean {
  const isMaterialUI = fiber.elementType?.options?.name?.toLowerCase().includes('mui') ?? false
  const fileName = debugField(fiber)?.fileName
  const isInClientUi = fileName?.includes('root/client/ui') ?? false
  const isInViewsWorkSpace = fileName?.includes('root/client/src/views/workspace/components') ?? false
  const isInManageUtils = fileName?.includes('root/client/src/views/manage/components') ?? false

  const isInRootUI = fileName?.includes('root/ui/') === true && !fileName.includes('root/ui/missions')
  const isInClientComponentsCommon = fileName?.includes('client/components/common') ?? false

  return (
    isMaterialUI ||
    isInClientUi ||
    isInRootUI ||
    isInClientComponentsCommon ||
    isInViewsWorkSpace ||
    isInManageUtils
  )
}

export function fiberDebugData(
  fiber: Fiber
): { fileName: string; lineNumber?: number; columnNumber?: number } | undefined {
  const debugNode = findFiber(fiber, {
    where: it => {
      const fileName = debugField(it)?.fileName
      if (fileName === undefined) return false
      return !isInIgnoredDirectory(it)
    },
  })?.match
  const debugData = debugNode ? debugField(debugNode) : undefined
  if (!debugData) return undefined
  const { fileName, lineNumber, columnNumber } = debugData
  if (fileName === undefined) return undefined
  return { fileName, lineNumber, columnNumber }
}

function reactFiberFromDomNode(node: DOMNode): Fiber | undefined {
  const fiberKey = Object.keys(node).find(it => it.startsWith('__reactFiber$'))
  if (fiberKey === undefined) return undefined
  return node[fiberKey] as Fiber
}

function findPathToParent(fiber: Fiber): [Fiber, ...Fiber[]] {
  const path = pathToRoot(fiber).filter(it => !isInIgnoredDirectory(it))
  if (isNonEmptyArray(path)) return path
  else return [fiber]
}

function findDebugMetadata(_fiber: Fiber): DebugState['metadata'] {
  try {
    const iconIds: IconId[] = []
    for (const fiber of pathToRoot(_fiber)) {
      const iconId =
        fiber.memoizedProps?.iconId ??
        fiber.memoizedProps?.icon ??
        fiber.pendingProps?.iconId ??
        fiber.pendingProps?.icon
      if (iconIdList.includes(iconId as IconId)) iconIds.push(iconId as IconId)
    }
    return { iconIds: iconIds.length > 0 ? (_.uniq(iconIds) as [IconId, ...IconId[]]) : undefined }
  } catch (e) {
    return {}
  }
}

export function computeDebugState(node: DOMNode | EventTarget | undefined): DebugState | undefined {
  const domNode = node as DOMNode | undefined
  if (domNode === undefined) return undefined

  const fiber = reactFiberFromDomNode(domNode)
  if (fiber === undefined) {
    return undefined
  }

  const metadata = findDebugMetadata(fiber)

  const pathToParent = findPathToParent(fiber)
  return { domNode, pathToParent, metadata }
}

export async function openFileInEditor(
  fileName: string,
  lineNumber: number,
  columnNumber: number
): Promise<void> {
  // Attempt to open the file using the client-server's /__launch-editor endpoint
  try {
    await fetch(`/__launch-editor?file=${fileName}&lineNumber=${lineNumber}&column=${columnNumber}`)
  } catch (e) {
    console.error('There was an issue opening this code in your editor.')
  }
}

function debugDataFromNode(
  node: HTMLElement
): { fileName: string; lineNumber: number; columnNumber: number } | undefined {
  const fiber = reactFiberFromDomNode(node as DOMNode)
  if (fiber === undefined) return undefined
  const debugData = fiberDebugData(fiber)
  if (debugData === undefined) return undefined
  const { fileName, lineNumber = 0, columnNumber = 0 } = debugData
  return { fileName, lineNumber, columnNumber }
}

export function nodeSourceCodeLocation(node: HTMLElement): string | undefined {
  const data = debugDataFromNode(node)
  if (data === undefined) return undefined
  const { fileName, lineNumber, columnNumber } = data
  return `${clientFileName(fileName)}&lineNumber=${lineNumber}&column=${columnNumber}`
}
