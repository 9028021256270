import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { keyBy, maxBy } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLiveSessionIdContextIfAvailable } from 'sierra-client/components/liveV2/live-session-id-provider'
import { liveSessionDataChannel, MatrixChangedData } from 'sierra-client/realtime-data/channels'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { selectCardIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  currentMatrixDataAtom,
  MatrixData,
  MatrixResponseData,
} from 'sierra-client/state/interactive-card-data/matrix-card'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { useRecapContext } from 'sierra-client/views/recap/recap-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { LiveSessionId, MatrixId } from 'sierra-domain/api/nano-id'
import { MatrixPosition, MatrixUpsertPositionRequest } from 'sierra-domain/api/strategy-v2'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import {
  XRealtimeStrategyContentDataMatrixGetResults,
  XRealtimeStrategyContentDataMatrixUpsertPosition,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Matrix } from 'sierra-domain/v3-author'

type MatrixDataLayer = {
  matrixData?: MatrixData
  userPosition?: MatrixPosition
  setUserPosition?: (position: MatrixPosition) => void
}

const LiveContext = React.createContext<MatrixDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<MatrixDataLayer | undefined>(undefined)
const CreateContext = React.createContext<MatrixDataLayer | undefined>(undefined)
const RecapContext = React.createContext<MatrixDataLayer | undefined>(undefined)

const FallbackDataProvider: FCC<{ element: Entity<Matrix> }> = ({ children }) => {
  return <CreateContext.Provider value={undefined}>{children}</CreateContext.Provider>
}

const LiveRealTimeDataHandler = ({
  liveSessionId,
  matrixId,
  onData,
  onReadyToReceiveData,
}: {
  liveSessionId: LiveSessionId
  matrixId: string
  onData: (newData: MatrixChangedData) => void
  onReadyToReceiveData: (receiving: boolean) => void
}): null => {
  const { isReceivingData } = liveSessionDataChannel.useChannelEvent({
    channelId: liveSessionId,
    event: 'matrix-changed',
    eventId: matrixId,
    callback: onData,
  })

  useEffect(() => {
    onReadyToReceiveData(isReceivingData)
  }, [onReadyToReceiveData, isReceivingData])

  return null
}

const BackendDataProvider: FCC<{ element: Entity<Matrix> }> = ({ element, children }) => {
  const userId = useSelector(selectUserId)
  const { file, flexibleContentId } = useFileContext()
  const isCardCompleted = useSelector(state => selectCardIsCompleted(state, flexibleContentId, file.id))
  const { setCardCompleted } = useSetCardProgress()
  const setMatrixData = useSetAtom(currentMatrixDataAtom)
  const { liveSessionId: scopedLiveSessionId } = useLiveSessionIdContextIfAvailable() ?? {}
  const liveSessionId =
    scopedLiveSessionId !== undefined ? ScopedLiveSessionId.extractId(scopedLiveSessionId) : undefined

  const matrixId = MatrixId.parse(element.id)
  const [userPosition, setUserPositionState] = useState<MatrixPosition | undefined>(undefined)
  const [realTimeReady, setRealTimeReady] = useState(false)
  const [realTimeDataMap, setRealTimeDataMap] = useState<{
    [id: string]: MatrixChangedData
  }>({})

  const matrixResponses = useCachedQuery(
    XRealtimeStrategyContentDataMatrixGetResults,
    {
      contentId: flexibleContentId,
      matrixId: matrixId,
      fileId: file.id,
      liveSessionId,
    },
    { enabled: realTimeReady || liveSessionId === undefined }
  )

  const onNewRealTimeData = useCallback((newData: MatrixChangedData) => {
    setRealTimeDataMap(data => ({ ...data, [newData.matrixResponseId]: newData }))
  }, [])

  const upsertUserPositionMutation = useMutation({
    mutationFn: (data: MatrixUpsertPositionRequest) =>
      typedPost(XRealtimeStrategyContentDataMatrixUpsertPosition, data),
    onSuccess: () => matrixResponses.refetch(),
  })

  useEffect(() => {
    if (matrixResponses.data === undefined) return

    const remoteUserPosition = matrixResponses.data.responses.find(response => response.userId === userId)
      ?.position

    if (remoteUserPosition !== undefined) {
      setUserPositionState(remoteUserPosition)
    }
  }, [setCardCompleted, matrixResponses.data, userId])

  useEffect(() => {
    if (userPosition !== undefined && !isCardCompleted) {
      setCardCompleted()
    }
  }, [userPosition, setCardCompleted, isCardCompleted])

  const setUserPosition = useCallback(
    (position: MatrixPosition) => {
      if (userId === undefined) throw new Error('no userId')

      setUserPositionState(position)
      upsertUserPositionMutation.mutate({
        contentId: flexibleContentId,
        position,
        anonymous: false,
        matrixId: matrixId,
        fileId: file.id,
        liveSessionId,
      })
    },
    [file.id, flexibleContentId, liveSessionId, matrixId, upsertUserPositionMutation, userId]
  )

  const matrixData = useMemo(() => {
    if (matrixResponses.data === undefined) return

    const apiResponseMap = keyBy(matrixResponses.data.responses, 'matrixResponseId')
    const ids = new Set([...Object.keys(apiResponseMap), ...Object.keys(realTimeDataMap)])

    const responses: MatrixResponseData[] = Array.from(ids)
      .map(id => {
        const realTimeData = realTimeDataMap[id]
        const apiData = apiResponseMap[id]

        const latest = maxBy([realTimeData, apiData], data =>
          data !== undefined ? new Date(data.updatedAt).valueOf() : 0
        )

        if (latest === undefined) return
        if (latest.userId === userId) return

        return {
          matrixResponseId: latest.matrixResponseId,
          userId: latest.userId,
          position: latest.position,
        }
      })
      .filter(isDefined)

    return {
      responses,
    }
  }, [matrixResponses.data, realTimeDataMap, userId])

  setMatrixData(matrixData)

  const value = useMemo(
    () => ({ matrixData, userPosition, setUserPosition }),
    [matrixData, userPosition, setUserPosition]
  )

  return (
    <SelfPacedContext.Provider value={value}>
      <>
        {children}
        {liveSessionId !== undefined && (
          <LiveRealTimeDataHandler
            liveSessionId={liveSessionId}
            matrixId={matrixId}
            onData={onNewRealTimeData}
            onReadyToReceiveData={setRealTimeReady}
          />
        )}
      </>
    </SelfPacedContext.Provider>
  )
}

const BackendRecapProvider: FCC<{ element: Entity<Matrix> }> = ({ element, children }) => {
  const userId = useSelector(selectUserId)
  const recapContext = useRecapContext()
  if (recapContext === undefined) throw new Error('no recap context')

  const matrixId = MatrixId.parse(element.id)
  const scopedLiveSessionId = recapContext.liveSessionId
  const liveSessionId = ScopedLiveSessionId.extractId(scopedLiveSessionId)
  const contentId = recapContext.flexibleContentId
  const { file } = useFileContext()

  const matrixResponses = useCachedQuery(XRealtimeStrategyContentDataMatrixGetResults, {
    contentId,
    matrixId: matrixId,
    fileId: file.id,
    liveSessionId,
  })

  const matrixData = useMemo(
    () =>
      matrixResponses.data !== undefined
        ? {
            responses: matrixResponses.data.responses.map(response => ({
              matrixResponseId: response.matrixResponseId,
              userId: response.userId,
              position: response.position,
            })),
          }
        : undefined,
    [matrixResponses.data]
  )

  const userPosition = useMemo(
    () => matrixResponses.data?.responses.find(response => response.userId === userId)?.position,
    [matrixResponses.data, userId]
  )

  const contextValue = useMemo(() => ({ matrixData, userPosition }), [matrixData, userPosition])

  return <RecapContext.Provider value={contextValue}>{children}</RecapContext.Provider>
}

const modeToProvider: Record<EditorMode, FCC<{ element: Entity<Matrix> }> | undefined> = {
  'recap': BackendRecapProvider,
  'live': BackendDataProvider,
  'self-paced': BackendDataProvider,
  'placement-test': undefined,
  'version-history': undefined,
  'create': undefined,
  'review': undefined,
  'template': undefined,
}

export const MatrixDataProvider: FCC<{ element: Entity<Matrix>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = modeToProvider[mode] ?? FallbackDataProvider

  return <Provider element={element}>{children}</Provider>
}

export const useMatrixData = (): MatrixDataLayer => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const recapData = useContext(RecapContext)

  const data = liveData ?? selfPacedData ?? createData ?? recapData

  if (!data) {
    throw new Error('Matrix data not provided')
  }

  return data
}
