import { createAction, createSlice } from '@reduxjs/toolkit'

export type LiveSessionFacilitatorsState = {
  facilitators: string[]
}
export const setFacilitators = createAction<string[]>('live-session-facilitators/set-facilitators')

const initialState: LiveSessionFacilitatorsState = { facilitators: [] }

export const liveSessionFacilitatorsSlice = createSlice({
  name: 'live-session-facilitators',
  reducers: {},
  initialState: initialState,
  extraReducers: builder => {
    builder.addCase(setFacilitators, (state, action) => {
      state.facilitators = action.payload
    })
  },
})
