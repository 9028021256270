import { useAtomValue } from 'jotai'
import * as _ from 'lodash/fp'
import { DateTime, DurationLikeObject } from 'luxon'
import React, { useEffect, useReducer, useState } from 'react'
import { TimeUnit, UpsertCertificateRequestInput } from 'sierra-client/api/graphql/gql/graphql'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useHideIntercom } from 'sierra-client/components/util/show-intercom-launcher'
import { getFlag } from 'sierra-client/config/global-config'
import { areArraysEqualShallow, useMemoByCompare } from 'sierra-client/hooks/use-memo-by-compare'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { typedPost } from 'sierra-client/state/api'
import { selectedCertificateAtom } from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import { ResolvingGroupMembersSkeleton } from 'sierra-client/views/manage/certificates/issue-certificate-panel/atoms'
import { BulkEditButtonWithPanel } from 'sierra-client/views/manage/certificates/issue-certificate-panel/bulk-edit'
import { FormData as EditData } from 'sierra-client/views/manage/certificates/issue-certificate-panel/bulk-edit/form'
import {
  EMPTY_ISSUED_CERTIFICATES_STATE,
  issuedCertificatesReducer,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/reducer'
import { FileInputWithUpload } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared'
import {
  IssueByProxySelector,
  useMeAsIdentity,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared/by-proxy-selector'
import {
  DraftIssued,
  DraftIssuedWithError,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/types'
import { useIssueCertificate } from 'sierra-client/views/manage/certificates/use-issue-certificate'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminListGroupIdentities } from 'sierra-domain/routes'
import { assert, isNonNullable, isNullable } from 'sierra-domain/utils'
import { FormElement, InputDatePicker, UserDisplay } from 'sierra-ui/components'
import { Button, IconButton, InputPrimitive, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
const HalfWidthContainer = styled.div`
  width: 50%;
`

const AVATAR_WIDTH = 32
const LEFT_LINE_WIDTH = 4

const FormContainer = styled(View).attrs({
  grow: true,
  paddingLeft: 'xlarge',
  direction: 'column',
})`
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 4px;
    left: ${(AVATAR_WIDTH - LEFT_LINE_WIDTH) / 2}px;
    width: ${LEFT_LINE_WIDTH}px;
    height: 100%;
    border-radius: 4px;
    background-color: ${token('border/default')};
  }
`

const FormRowContainer = styled(View).attrs({
  grow: true,
})``

const ErrorMessage = styled(Text).attrs({ size: 'micro', color: 'redBright' })`
  position: absolute;
  top: 4px;
`

const RelativeView = styled(View).attrs({ paddingTop: '4' })`
  position: relative;
`

type IssueToUserProps = {
  userId: UserId
  draft: DraftIssuedWithError
  updateDraft: (draft: DraftIssued) => void
  onClickRemove: (identity: Identity) => void
}

const IssueToUser = React.memo<IssueToUserProps>(({ userId, onClickRemove, draft, updateDraft }) => {
  const { t } = useTranslation()
  const issueByProxyEnabled: boolean = getFlag('certificates/issue-by-proxy')

  function handleExpirationDateChange(newExpiresAt: DateTime | undefined): void {
    updateDraft({ ...draft, expiresAt: newExpiresAt ?? undefined })
  }

  function handleIssueDateChange(newIssuedAt: DateTime | undefined): void {
    if (newIssuedAt === undefined) return

    updateDraft({ ...draft, issuedAt: newIssuedAt })
  }

  function handleSupportingNoteChange(newSupportingNote: string): void {
    updateDraft({ ...draft, supportingNote: newSupportingNote })
  }

  return (
    <View direction='column'>
      <View grow justifyContent='space-between' alignItems='flex-start'>
        <UserDisplay
          primaryText={draft.identity.name}
          avatar={{
            ...draft.identity.avatar,
            firstName: draft.identity.name,
            size: 'medium',
          }}
        />
        <View>
          <IconButton variant='transparent' iconId='close' onClick={() => onClickRemove(draft.identity)} />
        </View>
      </View>
      <FormContainer gap='xsmall'>
        <FormRowContainer alignItems='flex-start'>
          <HalfWidthContainer>
            <FormElement label={t('input.issue-date')}>
              <InputDatePicker
                placeholder={t('input.issue-date-placeholder')}
                value={draft.issuedAt}
                onChange={handleIssueDateChange}
                onBlur={handleIssueDateChange}
                zone={'utc'}
              />
            </FormElement>
          </HalfWidthContainer>
          <HalfWidthContainer>
            <FormElement
              label={
                <View>
                  <Text bold>{t('input.expiry-date')}</Text>
                  <Text bold color='foreground/muted'>
                    {t('input.optional')}
                  </Text>
                </View>
              }
            >
              <InputDatePicker
                placeholder={t('input.expiry-date-placeholder')}
                value={draft.expiresAt}
                onChange={handleExpirationDateChange}
                onBlur={handleExpirationDateChange}
                zone={'utc'}
              />
            </FormElement>
            <RelativeView>
              {draft.hasError && (
                <ErrorMessage>{t('manage.certificates.expiry-date-error-message')}</ErrorMessage>
              )}
            </RelativeView>
          </HalfWidthContainer>
        </FormRowContainer>
        <FormRowContainer>
          <View direction='column' grow>
            <FormElement label={t('manage.certificates.add-note-label')}>
              <InputPrimitive
                placeholder={t('manage.certificates.add-note-placeholder')}
                value={draft.supportingNote ?? ''}
                onChange={e => handleSupportingNoteChange(e.target.value)}
              />
            </FormElement>
            <FileInputWithUpload
              value={draft.supportingFile}
              onChange={file =>
                updateDraft({
                  ...draft,
                  supportingFile: file ? { id: file.id, file: file.file } : undefined,
                })
              }
            />
          </View>
        </FormRowContainer>
        {issueByProxyEnabled && (
          <FormRowContainer>
            <FormElement
              label={t('manage.certificates.issue.issuer')}
              helper={t('manage.certificates.issue.issuer-select-description')}
            >
              <IssueByProxySelector
                userIds={[userId]}
                onSelect={pi => updateDraft({ ...draft, issuedByProxy: pi })}
                onUnselect={() => updateDraft({ ...draft, issuedByProxy: undefined })}
                value={draft.issuedByProxy}
              />
            </FormElement>
          </FormRowContainer>
        )}
      </FormContainer>
    </View>
  )
})

const timeUnitToValidDurationKey: Record<TimeUnit, keyof DurationLikeObject> = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
}

const validityPeriodToDurationLike = (
  validityPeriod: UpsertCertificateRequestInput['validityPeriod'] | undefined
): DurationLikeObject | undefined => {
  const { timeUnit, duration } = validityPeriod ?? {}

  if (isNullable(timeUnit) || isNullable(duration)) return undefined

  return {
    [timeUnitToValidDurationKey[timeUnit]]: duration,
  }
}

type IssueCertificatePanelProps = {
  certificateId: string
  afterSubmit?: () => void
}

type DraftUpdater = (_: EditData) => (_: DraftIssued) => DraftIssued
const fileUpdate: DraftUpdater = data => draft => {
  // File is set to null if form deletes it
  if (data.file === null) {
    return { ...draft, supportingFile: undefined }
  } else if (data.file) {
    return { ...draft, supportingFile: { id: data.file.id, file: data.file.file } }
  }
  return draft
}

const noteUpdate: DraftUpdater = data => draft => {
  if (isNonNullable(data.note)) {
    return { ...draft, supportingNote: data.note }
  }
  return draft
}

const expiryAtUpdate: DraftUpdater = data => draft => {
  if (isNonNullable(data.date.expiry)) {
    return { ...draft, expiresAt: data.date.expiry }
  }
  return draft
}

const issuedAtUpdate: DraftUpdater = data => draft => {
  if (isNonNullable(data.date.issue)) {
    return { ...draft, issuedAt: data.date.issue }
  }
  return draft
}

const proxyIssueUpdate: DraftUpdater = data => draft => {
  if (data.issuer === null) {
    return { ...draft, issuedByProxy: undefined }
  } else if (data.issuer && data.issuer.identity.type === 'user') {
    return { ...draft, issuedByProxy: { ...data.issuer, id: data.issuer.identity.id } }
  }
  return draft
}

const CloseButton = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const IssueCertificatePanel = withPanel<IssueCertificatePanelProps>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
  },
  ({ onClose, certificateId, afterSubmit }) => {
    useHideIntercom()
    const certificate = useAtomValue(selectedCertificateAtom)
    const [issuedDraftState, issuedDraftStateDispatch] = useReducer(
      issuedCertificatesReducer,
      EMPTY_ISSUED_CERTIFICATES_STATE
    )
    const [isIssuing, setIsIssuing] = useState<boolean>(false)
    const { t } = useTranslation()
    const track = useTracking()
    const meIdentity = useMeAsIdentity()
    const notifications = useNotif()

    const fetchIdentities = useAdminIdentitiesFetcher({ withTypes: ['user', 'user-group'], limit: 30 })

    const onSelectIdentity = React.useCallback(
      (identity: Identity): void => {
        if (identity.identity.type === 'userGroup') {
          issuedDraftStateDispatch({
            type: 'add-group',
            identityRef: identity.identity,
          })
        } else {
          issuedDraftStateDispatch({
            type: 'add',
            identity,
            validityPeriod: validityPeriodToDurationLike(certificate.validityPeriod),
            issuedByProxy: meIdentity,
          })
        }
      },
      [certificate.validityPeriod, meIdentity]
    )

    const onUnselectIdentity = React.useCallback((identity: Identity): void => {
      issuedDraftStateDispatch({
        type: 'remove',
        payload: identity.identity,
      })
    }, [])

    const issueCertificate = useIssueCertificate()

    const handleIssue = async (): Promise<void> => {
      // Should be unreachable
      if (issuedDraftState.hasError) return

      // TODO: Handle batch issuing in backend
      const graphqlRequests = issuedDraftState.issues.map(draft => {
        assert(draft.identity.identity.type === 'user')

        assert(draft.issuedByProxy?.identity.type !== 'userGroup')

        return issueCertificate({
          certificateId,
          userId: draft.identity.identity.id,
          issuedAt: draft.issuedAt.toISO(),
          expiresAt: draft.expiresAt?.toISO(),
          supportingFileId: draft.supportingFile?.id,
          supportingNote: draft.supportingNote,
          issuedByProxy: draft.issuedByProxy?.identity.id,
        })
      })

      try {
        setIsIssuing(true)
        track.issueTo.click(certificateId, graphqlRequests.length)
        await Promise.all(graphqlRequests)
      } catch (e) {
        logger.captureError(e)
      } finally {
        setIsIssuing(false)
      }

      afterSubmit?.()
      onClose()
    }

    const updateDraft = React.useCallback((payload: DraftIssued): void => {
      issuedDraftStateDispatch({
        type: 'upsert',
        payload,
      })
    }, [])

    useEffect(() => {
      let cancel = false

      async function work(): Promise<void> {
        if (issuedDraftState.resolvingGroup === null) return

        const groupId = issuedDraftState.resolvingGroup.id

        try {
          const { members } = await typedPost(XRealtimeAdminListGroupIdentities, {
            groupId,
          })

          if (cancel) {
            return
          }

          for (const identity of members) {
            onSelectIdentity({
              ...identity,
              id: identity.identity.id,
            })
          }

          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('manage.certificates.added-users-to-issuing', { count: members.length }),
          })

          track.issueTo.addGroup(certificateId, groupId, members.length)

          issuedDraftStateDispatch({
            type: 'resolved-group',
          })
        } catch (error) {
          notifications.push({
            type: 'custom',
            level: 'error',
            body: t('manage.certificates.added-users-to-issuing-error'),
          })
        }
      }

      void work()

      return () => {
        cancel = true
      }
    }, [issuedDraftState.resolvingGroup, onSelectIdentity, notifications, certificateId, t, track])

    const isIssueDisabled = isIssuing || issuedDraftState.hasError

    const selectedIdentities = useMemoByCompare(
      issuedDraftState.issues.map(issue => issue.identity),
      areArraysEqualShallow
    )

    return (
      <>
        <View direction='column' padding='32' paddingBottom='16'>
          <View justifyContent='space-between'>
            <Text size='large' bold>
              {t('manage.certificates.issue-certificate', { title: certificate.title })}
            </Text>
            <CloseButton onClick={onClose} />
          </View>
          <Spacer />

          <FormElement label={t('certificates.manual-issuing.identities-selector.label')}>
            <IdentitiesSelector
              selectorId='certificate-issue-panel'
              fetchIdentities={fetchIdentities}
              selectedIdentities={selectedIdentities}
              onUnselect={onUnselectIdentity}
              onSelect={onSelectIdentity}
              placeholder='certificates.manual-issuing.identities-selector.placeholder'
            />
          </FormElement>
        </View>
        <ScrollView direction='column' grow gap='32' padding='32' paddingTop='24' paddingBottom='24'>
          {issuedDraftState.resolvingGroup !== null && <ResolvingGroupMembersSkeleton />}

          {issuedDraftState.issues.map(draft => {
            assert(draft.identity.identity.type === 'user')

            return (
              <IssueToUser
                key={draft.identity.identity.id}
                userId={draft.identity.identity.id}
                updateDraft={updateDraft}
                draft={draft}
                onClickRemove={onUnselectIdentity}
              />
            )
          })}
        </ScrollView>
        <View padding='medium' paddingTop='xsmall' justifyContent='space-between'>
          {issuedDraftState.issues.length >= 2 ? (
            <BulkEditButtonWithPanel
              issued={issuedDraftState.issues}
              onSubmit={data => {
                track.bulkEdit.edit({
                  certificateId,
                  file: isNonNullable(data.file),
                  note: isNonNullable(data.note),
                  issuedDate: isNonNullable(data.date.issue),
                  expiryDate: isNonNullable(data.date.expiry),
                  issuer: isNonNullable(data.issuer),
                })
                issuedDraftState.issues.forEach(draft => {
                  const changeDraft = _.pipe(
                    // Updating the draft here
                    fileUpdate(data),
                    noteUpdate(data),
                    expiryAtUpdate(data),
                    issuedAtUpdate(data),
                    proxyIssueUpdate(data)
                  )
                  updateDraft(changeDraft(draft))
                })
              }}
            />
          ) : (
            <span />
          )}

          <View>
            <Button variant='secondary' onClick={onClose}>
              {t('dictionary.cancel')}
            </Button>
            <Button onClick={handleIssue} variant='primary' disabled={isIssueDisabled} loading={isIssuing}>
              {t('manage.certificates.issued-action')}
            </Button>
          </View>
        </View>
      </>
    )
  }
)
