import { createSlice } from '@reduxjs/toolkit'
import {
  closePlacementTest,
  fetchCourseProgress,
  setCardProgressCompleted,
  setCardProgressStarted,
} from 'sierra-client/state/card-progress/actions'
import { CardProgressState } from 'sierra-client/state/card-progress/types'

const initialState: CardProgressState = {
  progress: {},
  nextUps: {},
}

export const cardProgressSlice = createSlice({
  name: 'cardProgress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setCardProgressStarted.fulfilled, (state, { payload }) => {
      state.progress[payload.courseId] = payload.status
      state.nextUps[payload.courseId] = payload.nextUp
    })

    builder.addCase(setCardProgressCompleted.fulfilled, (state, { payload }) => {
      state.progress[payload.courseId] = payload.status
      state.nextUps[payload.courseId] = payload.nextUp
    })

    builder.addCase(fetchCourseProgress.fulfilled, (state, { payload }) => {
      state.progress[payload.courseId] = payload.status
      state.nextUps[payload.courseId] = payload.nextUp
    })

    builder.addCase(closePlacementTest.fulfilled, (state, { payload }) => {
      state.progress[payload.courseId] = payload.status
      state.nextUps[payload.courseId] = payload.nextUp
    })
  },
})
