// --- Data Loading

import { Filter } from 'sierra-client/lib/filter'
import { Pagination } from 'sierra-client/lib/tabular/datatype/pagination'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'

// A DataLoader can be initialised with a set of parameters, and these parameters are now categorised as
// Control, Predicate, and Modifier parameters.
//
// Control params only impact the manner in which data loading progresses, the only example of this at the
// time is limit - that is, how many results we load at a time.
//
// Predicate params have an impact on which set of results will be returned by executing the data loading.
// Examples being query and filter.
//
// Modifier params don't change the set of returned results, but can impact the order in which they are
// loaded. Only example at this time is sorting.
//
// ┌───────┐┌─────────┐┌────────┐
// │Control││Predicate││Modifier│
// └┬─┬────┘└┬────────┘└┬──┬────┘
//  │┌▽──────▽──────────▽─┐│
//  ││Init                ││
//  │└┬──┬────────────────┘│
// ┌▽─▽─┐│                 │
// │More││                 │
// └┬───┘│                 │
// ┌▽────▽─────────────────▽┐
// │Done                    │
// └────────────────────────┘
//
// We want to support doing local/client-side processing in certain situations. For example if we use an API
// that doesn't support sorting we could support client-side sorting of the data if and only if we have loaded
// all data.
//
// Because we have made these distinctions above we can phrase this as: If we have loaded all data we can
// allow the client to change modifier params and those changes can be reflected locally without
// inconsistency. Trivially we also know that changes to control params don't matter if we're in a done state.

type PredicateArguments = {
  query?: string
  filter?: Filter
}

type ModifierArguments = {
  sorting?: Array<Sorting>
}

type ControlArguments = {
  limit?: number
}

export type AllArguments = {
  predicate: PredicateArguments
  modifier: ModifierArguments
  control: ControlArguments
}

export type PaginationInitArguments = AllArguments

export type PaginationMoreArguments = {
  control: ControlArguments
}

////////////////////////////////////////////////////////////////////

type InitState = {
  type: 'init'
}

// This is a separate type to allow us to ignore the generic data type in the fetchMore function. We need
// to do that temporarily during the migration.
type InitializedStateNoData<Meta> = {
  predicate: PredicateArguments
  modifier: ModifierArguments
  control: ControlArguments
  // todo: force pagination.done to be tied to More and Done states
  pagination: Pagination
  meta: Meta
}

type InitializedState<Data, Meta> = InitializedStateNoData<Meta> & {
  data: Data[]
}

type MoreState<Data, Meta> = InitializedState<Data, Meta> & {
  type: 'more'
}

type DoneState<Data, Meta> = InitializedState<Data, Meta> & {
  type: 'done'
}

export type State<Data, Meta> = InitState | MoreState<Data, Meta> | DoneState<Data, Meta>

export type DataLoaderStateMachine<Data, Meta> = {
  onInit: (init: PaginationInitArguments) => Promise<MoreState<Data, Meta> | DoneState<Data, Meta>>

  onMore: (
    state: MoreState<Data, Meta>,
    more: PaginationMoreArguments
  ) => Promise<MoreState<Data, Meta> | DoneState<Data, Meta>>
}

export const MoreState = <Data, Meta>(payload: InitializedState<Data, Meta>): MoreState<Data, Meta> => ({
  ...payload,
  type: 'more',
})

export const DoneState = <Data, Meta>(payload: InitializedState<Data, Meta>): DoneState<Data, Meta> => ({
  ...payload,
  type: 'done',
})

const resolveState = <Data, Meta>(
  newState: InitializedState<Data, Meta>
): MoreState<Data, Meta> | DoneState<Data, Meta> =>
  newState.pagination.done ? DoneState(newState) : MoreState(newState)

type PartialResult<D, Meta> = {
  data: D[]
  meta: Meta
  totalCount: number
  done: boolean
}

// NOTE: The D2 parameter is TEMPORARY until we've migrated all the table code to store raw data.
export const createDataLoader = <D, Meta, D2 = D>({
  fetchInit,
  fetchMore,
  // NOTE: Temporary -- see comment above function definition.
  transformResults,
}: {
  fetchInit: (init: PaginationInitArguments) => Promise<PartialResult<D, Meta>>
  fetchMore: (state: InitializedStateNoData<Meta>) => Promise<PartialResult<D, Meta>>
  // NOTE: Temporary -- see comment above function definition.
  transformResults: (data: D[], meta: Meta) => D2[]
}): DataLoaderStateMachine<D2, Meta> => ({
  async onInit(init) {
    const partial = await fetchInit(init)

    return resolveState({
      ...init,
      data: transformResults(partial.data, partial.meta),
      meta: partial.meta,
      pagination: {
        total: partial.totalCount,
        loaded: partial.data.length,
        done: partial.done,
      },
    })
  },

  async onMore(state, more) {
    const mergedState = {
      ...state,
      ...more,
      // this isn't technically required right now since `control` only contains `limit`, but we'll do it properly.
      control: {
        ...state.control,
        ...more.control,
      },
    }

    const partial = await fetchMore(mergedState)

    return resolveState({
      ...mergedState,
      data: [...state.data, ...transformResults(partial.data, partial.meta)],
      meta: partial.meta,
      pagination: {
        total: partial.totalCount,
        loaded: state.pagination.loaded + partial.data.length,
        done: partial.done,
      },
    })
  },
})
