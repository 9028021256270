import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai'
import { FC, useEffect } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { HeaderButtonColorCSS, HeaderItemCSS } from 'sierra-client/components/liveV2/header-buttons'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { aiNarrationPanelClosed, aiNarrationPanelOpened } from 'sierra-client/core/logging/authoring/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  AINarrationControlsState,
  AINarrationNudgeState,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const NarrationButton = styled.button`
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
    cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
`
export const NarrationControls: FC<{
  fileId: FileId
  createContentId: CreateContentId
  narrationStateAtom: PrimitiveAtom<boolean>
}> = ({ fileId, createContentId, narrationStateAtom }) => {
  const { operationState } = useCreatePageContext()
  const { t } = useTranslation()

  const card = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))

  const [narrationIsOpen, setNarrationIsOpen] = useAtom(narrationStateAtom)
  const narrationExists = card?.narration !== undefined ? true : false

  const confirmationModal = useConfirmationModalContext()

  const deleteNarration = (): void => {
    confirmationModal.show({
      title: t('alert.are-you-sure'),
      bodyText: '',
      confirmLabel: t('dictionary.remove'),
      onConfirm: () => {
        apply(operationState, {
          type: 'update-files',
          fileIds: [fileId],
          update: file => {
            file.narration = undefined
          },
        })
      },
    })
  }

  const toggleNarrationModal = (): void => {
    setNarrationIsOpen(previous => !previous)
  }

  const iconAdd = 'record' as const
  const iconOff = 'stop--recording' as const
  const label = 'dictionary.narrate' as const

  // Turn off the narration when the card changes
  useEffect(() => {
    setNarrationIsOpen(false)
  }, [card, setNarrationIsOpen])

  return (
    <NarrationButton
      onClick={narrationExists ? deleteNarration : toggleNarrationModal}
      id='tour-create-narration'
    >
      <View>
        <Icon iconId={narrationIsOpen || narrationExists ? iconOff : iconAdd} color='currentColor' />

        <Text color='currentColor' bold>
          {narrationIsOpen || narrationExists ? t('create.narration.remove') : t('create.narration.add')}
        </Text>
      </View>
      <ShortcutMenu.Toggle
        label={label}
        iconId={iconAdd}
        isOn={() => narrationIsOpen}
        toggle={toggleNarrationModal}
        permission='ACCESS_EDITOR'
        group='create'
      />
    </NarrationButton>
  )
}

const AiNarrationButton = styled(Button)`
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`
export const AINarrationsControls: FC<{
  fileId: FileId
  contentType: ContentType
  contentId: CreateContentId
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
}> = ({
  fileId,
  aiNarrationControlsStateAtom,
  legacyNarrationStateAtom,
  contentType,
  contentId,
  aiNarrationNudgeStateAtom,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useAtom(aiNarrationControlsStateAtom)
  const setNudgeState = useSetAtom(aiNarrationNudgeStateAtom)
  const dispatch = useDispatch()

  const isOpen = state.type === 'open'
  useOnChanged((wasOpenBefore, isOpenNow) => {
    if (wasOpenBefore === undefined) {
      return
    }

    if (!wasOpenBefore && isOpenNow) {
      void dispatch(
        aiNarrationPanelOpened({
          contentType,
          contentId,
          fileId: fileId,
        })
      )
    }

    if (!isOpenNow && wasOpenBefore) {
      void dispatch(
        aiNarrationPanelClosed({
          contentType,
          contentId,
          fileId: fileId,
        })
      )
    }
  }, isOpen)

  // Close narrations when the card changes
  useOnChanged(() => {
    setState({ type: 'closed' })
  }, fileId)

  const setLegacyNarrationState = useSetAtom(legacyNarrationStateAtom)

  return (
    <AiNarrationButton
      icon='user'
      onClick={() => {
        setNudgeState(prev => ({ ...prev, [contentId]: { shown: true } }))

        setState(previous => (previous.type === 'closed' ? { type: 'open' } : { type: 'closed' }))
        // Close the old menu in case it was open
        setLegacyNarrationState(false)
      }}
    >
      {t('dictionary.narration')}
    </AiNarrationButton>
  )
}
