import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { EventGroupTableQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { eventGroupsColumn, numbersColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { StaticLoaderSearchKeyBy, staticDataLoader } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { getPendingApprovalRequests } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/utils'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import {
  formatGqlEventSchedule,
  getGqlEventScheduleEnd,
  gqlEventLocationString,
  gqlEventLocationToEventLocation,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { isDefined } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

export type EventGroupsData = EventGroupTableQuery['content']['data'][number] & {
  __typename: 'NativeEventGroup'
}

// -- columns --

type EventGroupsTableDefinition = TableDefinitionOf<
  EventGroupsData,
  [
    { type: 'eventGroups'; ref: 'eventGroup' },
    { type: 'strings'; ref: 'type' },
    { type: 'strings'; ref: 'date' },
    { type: 'strings'; ref: 'location' },
    { type: 'strings'; ref: 'facilitators' },
    { type: 'numbers'; ref: 'learners' },
  ]
>

type EventGroupsTableData = TableDataFromDefinition<EventGroupsData, EventGroupsTableDefinition>

export const eventGroupsToTableDefinition = (t: TranslationLookup): EventGroupsTableDefinition => ({
  nested: {},
  rows: {
    getId: eg => eg.courseId,
  },
  columns: [
    eventGroupsColumn({
      ref: 'eventGroup',
      header: translatedLabel('dictionary.title'),
      sortable: true,
      hints: ['with-nested-row-toggle'],
      getData: eg => ({
        id: eg.courseId,
        title: eg.title,
        requestCount: getPendingApprovalRequests(eg.calendarEventsWithApprovalRequests).length,
        description: eg.description ?? '',
        image: convertGQLImage(eg.image),
        contentType: 'course',
        courseKind: 'native:event-group',
        isFeatured: eg.featured,
        isPublicVisibility: eg.visibility === 'VISIBLE',
        duration: 3600, // used to show example time estimate in content feature modal
      }),
    }),
    stringsColumn({
      ref: 'type',
      header: translatedLabel('table.type'),
      sortable: true,
      getData: () => t('dictionary.in-person'),
    }),
    stringsColumn({
      ref: 'date',
      header: translatedLabel('dictionary.date'),
      sortable: true,
      getData: eg => {
        const futureCalendarEvents = eg.calendarEvents.filter(
          ce => getGqlEventScheduleEnd(ce.schedule) > DateTime.now()
        )
        if (futureCalendarEvents.length > 1) {
          return t('events-groups.multiple-sessions')
        } else if (futureCalendarEvents.length === 1 && isDefined(futureCalendarEvents[0])) {
          return formatGqlEventSchedule(futureCalendarEvents[0].schedule)
        } else {
          return t('manage.events.no-scheduled-sessions')
        }
      },
    }),
    stringsColumn({
      ref: 'location',
      header: translatedLabel('dictionary.location'),
      sortable: true,
      getData: eg => {
        const futureCalendarEvents = eg.calendarEvents.filter(
          ce => getGqlEventScheduleEnd(ce.schedule) > DateTime.now()
        )

        const firstEvent = futureCalendarEvents[0]

        if (futureCalendarEvents.length > 1 && isDefined(firstEvent)) {
          const firstEventLocation = gqlEventLocationToEventLocation(firstEvent.location)
          const allFutureEventsHaveSameLocation = futureCalendarEvents.every(event =>
            _.isEqual(firstEventLocation, gqlEventLocationToEventLocation(event.location))
          )

          if (allFutureEventsHaveSameLocation) {
            return gqlEventLocationString(firstEvent.location)
          } else {
            return t('dictionary.multiple-locations')
          }
        } else if (futureCalendarEvents.length === 1 && isDefined(firstEvent)) {
          return gqlEventLocationString(firstEvent.location)
        } else {
          return ''
        }
      },
    }),
    stringsColumn({
      ref: 'facilitators',
      header: translatedLabel('dictionary.facilitators'),
      sortable: true,
      getData: eg => {
        const futureCalendarEvents = eg.calendarEvents.filter(
          ce => getGqlEventScheduleEnd(ce.schedule) > DateTime.now()
        )

        if (futureCalendarEvents.length > 1 && isDefined(futureCalendarEvents[0])) {
          const firstEvent = futureCalendarEvents[0]
          const allFutureEventsHaveSameFacilitators = futureCalendarEvents.every(event =>
            _.isEqual(firstEvent.location, event.location)
          )
          if (allFutureEventsHaveSameFacilitators && isDefined(futureCalendarEvents[0])) {
            return firstEvent.facilitators
              .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
              .join(', ')
          } else {
            return t('dictionary.different-facilitators')
          }
        } else if (futureCalendarEvents.length === 1 && isDefined(futureCalendarEvents[0])) {
          return futureCalendarEvents[0].facilitators
            .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
            .join(', ')
        } else {
          return undefined
        }
      },
    }),
    numbersColumn({
      ref: 'learners',
      header: translatedLabel('table.learners'),
      sortable: true,
      getData: eg => {
        return eg.assignmentCount
      },
    }),
  ],
})

// -- actions --

export type ManageEventGroupsTabularActions = {
  onViewEventGroupDetails: (id: RowRef, api: TableAPI<EventGroupsTableData>) => void
  onAssignEventGroup: (id: RowRef, api: TableAPI<EventGroupsTableData>) => void
  onEditEventGroupFeatureOnHome: (id: RowRef, api: TableAPI<EventGroupsTableData>) => void
  onAddEventGroupFeatureOnHome: (id: RowRef, api: TableAPI<EventGroupsTableData>) => void
}

const virtualColumns = (
  actions: ManageEventGroupsTabularActions,
  t: TranslationLookup,
  canEditAssignments: boolean
): VirtualColumns<EventGroupsTableData> => ({
  left: [],
  right: [
    defaultMenuActionVirtualColumn<EventGroupsTableData>({
      getProps: (api, pos) => {
        const data = getRowDataFromTableAPI(api, pos.row)?.eventGroup.data
        const featureAction: LabelMenuItem =
          data?.isFeatured === true
            ? {
                id: pos.row + '-edit-presentation',
                type: 'label',
                label: t('manage.content.actions.edit-presentation'),
                onClick: () => actions.onEditEventGroupFeatureOnHome(pos.row, api),
              }
            : {
                id: pos.row + '-feature',
                type: 'label',
                label: t('manage.content.actions.feature'),
                onClick: () => actions.onAddEventGroupFeatureOnHome(pos.row, api),
              }

        const menuItems: Array<MenuItem> = [
          {
            id: pos.row + '-view-details',
            type: 'label',
            label: t('manage.view-details'),
            onClick: () => actions.onViewEventGroupDetails(pos.row, api),
          },
          {
            id: pos.row + '-assign',
            type: 'label',
            label: t('dictionary.assign'),
            hidden: !canEditAssignments,
            onClick: () => actions.onAssignEventGroup(pos.row, api),
          },
          featureAction,
        ]
        return {
          menuItems,
        }
      },
    }),
  ],
})

const searchKey: StaticLoaderSearchKeyBy<EventGroupsTableData> = (tableData, row) =>
  tableData.rows[row]?.data.eventGroup?.data?.title ?? ''

export const useManageEventGroupsTableAPI = (
  actions: ManageEventGroupsTabularActions,
  eventGroups: EventGroupsData[] | undefined
): UseTableAPI<EventGroupsTableData, any> => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({
        loader: staticDataLoader<EventGroupsTableData>(
          definition2Data(eventGroupsToTableDefinition(t), eventGroups ?? []),
          searchKey
        ),
        options: { queryKey: ['manage-event-groups-tabular'] },
      }),
      [eventGroups, t]
    ),
    virtualColumns: React.useMemo(
      () => virtualColumns(actions, t, canEditAssignments),
      [actions, t, canEditAssignments]
    ),
  })
}

const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.events.search-events' }

export const ManageEventGroupsTabular: React.FC<{
  tableAPI: UseTableAPI<EventGroupsTableData, any>
  onCreateEventGroup: () => void
  hideCounts: boolean
}> = React.memo(({ onCreateEventGroup, tableAPI, hideCounts }) => {
  const { t } = useTranslation()
  const canCreateEventGroups = useHasOrganizationPermission('CREATE_EVENT_GROUP')

  const memoizedCallbacks = React.useMemo<TableCallbacks<EventGroupsTableData>>(
    () => ({ onRow: row => getGlobalRouter().navigate({ to: `/manage/in-person-events/${row.ref}` }) }),
    []
  )

  return (
    <ColumnLayout>
      <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
        <PageHeader
          title='dictionary.in-person-events'
          withoutMargin
          helpLink='https://help.sana.ai/en/articles/114853-in-person-events'
        />

        <ManageHeader
          api={tableAPI.api}
          search={searchBar}
          actionElement={
            canCreateEventGroups ? (
              <Button variant='secondary' onClick={onCreateEventGroup}>
                {t('manage.events.new-event')}
              </Button>
            ) : null
          }
        />
        <TabularToolbar
          countsTranslationKeys={
            hideCounts
              ? undefined
              : {
                  totalKey: 'manage.events.n-events',
                  filterKey: 'manage.events.n-filtered',
                  selectedKey: 'manage.events.n-selected',
                }
          }
          api={tableAPI.api}
          clearFilters={false}
        />
        <BasicTabular />
      </TabularProviderFromTableAPI>
    </ColumnLayout>
  )
})
