import React from 'react'
import { CellPill } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { isDefined } from 'sierra-domain/utils'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { Pill, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PrimaryLabel = styled(Text).attrs({ size: 'micro', bold: true })`
  white-space: nowrap;
`

const SecondaryLabel = styled(TruncatedText).attrs({ lines: 1, size: 'micro', color: 'foreground/muted' })``

export const PillCell: React.FC<{ cell: CellPill }> = ({ cell }) => {
  const { type, text, subtext } = cell.data

  switch (type) {
    case 'success':
      return (
        <Pill variant='success'>
          <Icon size='size-12' iconId='checkmark--filled' color='success/foreground' />
          <PrimaryLabel color='success/foreground'>{text}</PrimaryLabel>
          {isDefined(subtext) && <SecondaryLabel>{subtext}</SecondaryLabel>}
        </Pill>
      )
    case 'error':
      return (
        <Pill variant='error'>
          <Icon size='size-12' iconId='close--circle--filled' color='destructive/background' />
          <PrimaryLabel color='destructive/background'>{text}</PrimaryLabel>
          {isDefined(subtext) && <SecondaryLabel>{subtext}</SecondaryLabel>}
        </Pill>
      )
    case 'ghost':
      return (
        <Pill variant='ghost'>
          <PrimaryLabel>{text}</PrimaryLabel>
          {isDefined(subtext) && <SecondaryLabel>{subtext}</SecondaryLabel>}
        </Pill>
      )
  }
}
