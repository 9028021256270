import React from 'react'
import { DynamicT, TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { DomainRep, Filter, Label, Operator, Predicate, Value } from 'sierra-client/lib/filter'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'
import { assertNever } from 'sierra-domain/utils'

export type Context = {
  domainReps: DomainRep[]
  menuItems?: MenuItemWithDomainRep[] // Very optional - Only used when you have a pre-defined menu item structure that you want to use (for this PR we use the pre-defined dimensions list)
  update: (modify: (filter: Filter) => Filter) => void
  remove: () => void
}

export const Intersperse: React.FC<{
  separator: JSX.Element
  children: React.ReactNode[]
}> = React.memo(({ separator, children }) => (
  <>
    {children.flatMap<React.ReactNode[]>((child, index) =>
      index === 0 ? [child] : [<React.Fragment key={`sep-${index}`}>{separator}</React.Fragment>, child]
    )}
  </>
))

export const labelToString = (label: Label, dynamicT: DynamicT): string => {
  switch (label.type) {
    case 'label.default':
      return label.label
    case 'label.translation':
      return dynamicT(label.translationKey, undefined, label.defaultLabel)
    case 'label.concatenation':
      return [labelToString(label.first, dynamicT), labelToString(label.second, dynamicT)].join(
        label.separator
      )
    default:
      return assertNever(label)
  }
}

const labelFor = (domainRep: DomainRep, value: Value, dynamicT: DynamicT): string => {
  if (value.type === 'value.none') return ''
  switch (domainRep.domain.type) {
    case 'domain.with-parent-choices':
    case 'domain.choices': {
      if (value.type === 'value.exercise-id' || value.type === 'value.file-id') {
        throw new Error('Unsupported value type')
      }

      const valueRep = domainRep.domain.choices.find(valueRep => valueRep.value.value === value.value)
      if (valueRep !== undefined) {
        return labelToString(valueRep.label, dynamicT)
      } else {
        return value.value.toString()
      }
    }
    case 'domain.type':
      if (value.type === 'value.exercise-id' || value.type === 'value.file-id') {
        throw new Error('Unsupported value type')
      }
      return value.value.toString()
    case 'domain.auto-complete':
      if (value.type === 'value.exercise-id') {
        return `course:${value.courseId}:file:${value.fileId}:exercise:${value.exerciseId}`
      }
      if (value.type === 'value.file-id') {
        return `course:${value.courseId}:file:${value.fileId}`
      }
      return value.value.toString()
    default:
      assertNever(domainRep.domain)
  }
}

export const ellipse = (maxLength: number, str: string): string =>
  str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str

export const labelsForPredicate = (
  domainRep: DomainRep,
  predicate: Predicate,
  dynamicT: DynamicT
): string[] => {
  switch (predicate.type) {
    case 'predicate.single':
      return [labelFor(domainRep, predicate.value, dynamicT)]
    case 'predicate.and':
    case 'predicate.or':
      return predicate.values.map(value => labelFor(domainRep, value, dynamicT))
    case 'predicate.none':
      return []
    default:
      return assertNever(predicate)
  }
}

export const predicateLabelsToLabel = (labels: string[]): string => ellipse(24, labels.join(', '))

const operatorToTranslationKey: Record<Operator['type'], TranslationKey> = {
  'operator.eq': 'filter.operator.eq',
  'operator.neq': 'filter.operator.neq',
  'operator.gt': 'filter.operator.gt',
  'operator.gte': 'filter.operator.gte',
  'operator.lt': 'filter.operator.lt',
  'operator.lte': 'filter.operator.lte',
  'operator.contains': 'filter.operator.contains',
  'operator.not-contains': 'filter.operator.not-contains',
  'operator.is-null': 'filter.operator.is-null',
  'operator.not-null': 'filter.operator.not-null',
}

export const humanShowOp = (op: Operator, t: TranslationLookup): string =>
  t(operatorToTranslationKey[op.type])

export const groupBy = <T, K>(items: T[], key: (item: T) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>()
  items.forEach(item => {
    const k = key(item)
    const group = map.get(k) || []
    group.push(item)
    map.set(k, group)
  })
  return map
}
