import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import { Duration } from 'luxon'
import { useCallback, useMemo } from 'react'
import { postZod } from 'sierra-client/api'
import {
  isReadonlyAttributesAtom,
  isReadonlyUserMetadataAtom,
} from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/atoms'
import {
  generalSettingsDraftAtom,
  generalSettingsErrorsAtom,
  initialGeneralSettingsAtom,
} from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/atoms'
import {
  EmailErrorType,
  GeneralSettingError,
  GeneralSettings,
} from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/types'
import { UserId } from 'sierra-domain/api/uuid'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAdminUpdateGeneralUserSettings } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { z } from 'zod'

export type UseGeneralSettingsDraft = {
  readOnlyAttributes: boolean
  readOnlyMetadata: boolean
  hasChanged: boolean
  getErrors: (type: EmailErrorType) => GeneralSettingError[]
  hasErrors: boolean
  updateErrors: (type: EmailErrorType, newErrors?: GeneralSettingError[]) => void
  initialGeneralSettings: GeneralSettings
  resetGeneralSettingsDraft: () => void
  generalSettingsDraft: GeneralSettings
  setGeneralSettingsDraft: (settings: GeneralSettings) => void
  saveSettings: (userId: UserId) => Promise<'OK' | 'ERROR'>
}

const RateLimit = z
  .string()
  .transform(value => {
    const match = value.match(/(PT\d+M)/)
    const isoDuration = match?.[0]
    return isoDuration
  })
  .refine(isDefined)
  .transform(isoDuration => Duration.fromISO(isoDuration).toHuman())

export const useGeneralSettingsDraft = (): UseGeneralSettingsDraft => {
  const initialGeneralSettings = useAtomValue(initialGeneralSettingsAtom)
  const readOnlyAttributes = useAtomValue(isReadonlyAttributesAtom)
  const readOnlyMetadata = useAtomValue(isReadonlyUserMetadataAtom)
  const [generalSettingsDraft, setGeneralSettingsDraft] = useAtom(generalSettingsDraftAtom)
  const [errors, setErrors] = useAtom(generalSettingsErrorsAtom)
  const hasErrors = errors.length > 0

  if (generalSettingsDraft === undefined) {
    throw new Error('General settings draft has not been initialized.')
  }

  if (initialGeneralSettings === undefined) {
    throw new Error('Initial general settings draft has not been initialized.')
  }

  const hasChanged = useMemo(
    () => !_.isEqual(initialGeneralSettings, generalSettingsDraft),
    [generalSettingsDraft, initialGeneralSettings]
  )

  const resetGeneralSettingsDraft = useCallback(() => {
    setGeneralSettingsDraft(initialGeneralSettings)
    setErrors([])
  }, [initialGeneralSettings, setGeneralSettingsDraft, setErrors])

  const getErrors: UseGeneralSettingsDraft['getErrors'] = useCallback(
    type => errors.filter(e => e.type === type),
    [errors]
  )

  const updateErrors: UseGeneralSettingsDraft['updateErrors'] = useCallback(
    (type, newErrors = []) => setErrors(errors => errors.filter(e => e.type !== type).concat(newErrors)),
    [setErrors]
  )

  const saveSettings: UseGeneralSettingsDraft['saveSettings'] = useCallback(
    async userId => {
      // Only pick the properties from the generalSettingsDraft which have different values from the properties in the initialSettings
      const updates = _.pickBy(
        generalSettingsDraft,
        (value, key) => value !== (initialGeneralSettings[key as keyof typeof initialGeneralSettings] ?? '')
      )

      const res = await postZod(XRealtimeAdminUpdateGeneralUserSettings, {
        userId,
        updates,
      })

      if (isLeft(res) && res.left.code === 'user/email-rate-limited') {
        const rateLimit = RateLimit.safeParse(res.left.message)
        if (rateLimit.success) {
          updateErrors('email-rate-limit', [{ type: 'email-rate-limit', rateLimit: rateLimit.data }])
        }

        return 'ERROR'
      }
      return 'OK'
    },
    [generalSettingsDraft, initialGeneralSettings, updateErrors]
  )

  return {
    readOnlyAttributes,
    readOnlyMetadata,
    hasChanged,
    getErrors,
    hasErrors,
    updateErrors,
    initialGeneralSettings,
    generalSettingsDraft,
    setGeneralSettingsDraft,
    resetGeneralSettingsDraft,
    saveSettings,
  }
}
