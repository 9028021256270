import { Dimensions } from 'sierra-domain/api/insights'
import { IconId } from 'sierra-ui/components'

export const dimensionIconMap: Record<Dimensions['id'], IconId> = {
  'time': 'timer',
  'user.entity': 'user',
  'user.attributes': 'user--attributes',
  'group.entity': 'user--group',
  'content': 'bookmark--outline',
  'interactive-card-dimensions': 'touch',
  'questions': 'help',
  'question.entity': 'help',
  'user-question.answer': 'chat-bubble',
  'file.entity': 'card',
  'file.type': 'diagram',
  'polls': 'poll',
  'poll.entity': 'poll',
  'poll.option': 'chart--funnel',
  'content.entity': 'bookmark--filled',
  'course.entity': 'course',
  'path.entity': 'path',
  'program.entity': 'portfolio',
  'content.attributes': 'content--attributes',
  'assessments': 'assessment',
  'assessment.entity': 'assessment',
  'skills': 'skill',
  'skill.entity': 'skill',
  'skill-level.entity': 'badge--icon',
  'course.feedback.comment': 'closed-caption',
  'course.feedback.rating': 'rating--5',
  'reflections.responses.entity': 'closed-caption',
  'homework': 'task',
  'homework.entity': 'task',
  'homework.attempt-number': 'hashtag',
  'homework.grade': 'checkbox--checked',
  'certificates': 'medal',
  'certificate.entity': 'medal',
  'certificate.status': 'roadmap',
  'certificate.expiry-date': 'calendar',
  'certificate.due-date': 'calendar',
  'certificate.revoked-at': 'calendar',
  'certificate.issued-at': 'calendar',
}
