import { useMemo } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useCachedQuery } from 'sierra-client/state/api'
import { Flags } from 'sierra-domain/api/private'
import { XRealtimeUserGetFlags } from 'sierra-domain/routes'

type LiveFlagData<FlagName extends keyof Flags> = {
  refresh: () => Promise<Flags[FlagName]>
  flag: Flags[FlagName]
}

/**
 * Fetches a flag directly from the backend and keeps it up to date.
 * This is useful when you need to know the flag value in real time, without waiting for the user to reload the page.
 *
 * For example if you need all users to switch at the same time.
 *
 * ALWAYS use `refetch` to make sure you use the most recent value, the "flag" property is only the last known value.
 *
 * example:
 *
 * ```
 *  const { flag } = useFlagWithRefresh('myFlag')
 *
 *  if (flag) {
 *   return <NewFlaggedComponent />
 *  }
 *
 * ```
 */
export const useFlagWithRefresh = <FlagName extends keyof Flags>(
  flagName: FlagName
): LiveFlagData<FlagName> => {
  const initialFlagValue = getFlag(flagName)

  const { data: backendFlag, refetch } = useCachedQuery(
    XRealtimeUserGetFlags,
    {},
    {
      staleTime: 30_000,
      refetchInterval: 60_000,
      select: data => {
        return data.flags[flagName]
      },
    }
  )

  return useMemo(
    () => ({
      refresh: async () => {
        // the request can fail so we fall back to the initial flag
        return (await refetch()).data ?? initialFlagValue
      },
      flag: backendFlag ?? initialFlagValue,
    }),
    [initialFlagValue, backendFlag, refetch]
  )
}
