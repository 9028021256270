import { useEffect } from 'react'
import { ConnectDragPreview, ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd'
import { DragItemTypes, EditorSidebarDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useMultiSelectMoveFiles } from 'sierra-client/state/flexible-content/factory'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { isFile, isFileId, isFolderId } from 'sierra-client/state/flexible-content/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { editorNodeMovedLogger } from 'sierra-client/views/flexible-content/editor/content-sidebar/logger'
import { useIsEnabledMultiDnd } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-is-enabled-multi-dnd'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, NodeMap } from 'sierra-domain/flexible-content/types'
import { IconId } from 'sierra-ui/components'

export const useEditorSidebarDrop = ({
  dropOnNodeId,
  parentFolders,
  flexibleContentNodes,
  targetBoundingBox,
  indicateBelow,
  setIndicateBelow,
  createContentId,
  parentFolderId,
}: {
  dropOnNodeId: FileId // node we are trying to drop on (not the node we are dragging)
  parentFolders: FolderId[]
  flexibleContentNodes: NodeMap | undefined
  targetBoundingBox: DOMRect | undefined
  indicateBelow: boolean
  setIndicateBelow: (indicateBelow: boolean) => void
  createContentId: CreateContentId
  parentFolderId: FolderId
}): [{ isOver: boolean; canDrop: boolean }, ConnectDropTarget] => {
  const dispatch = useDispatch()
  const { selection, containsSelection, areMultipleSelected, clearSelection } = useMultiSelection()
  const multiSelectMoveFiles = useMultiSelectMoveFiles({ onSuccess: clearSelection })
  const isMultiDndEnabled = useIsEnabledMultiDnd()

  const [{ isOver, canDrop }, dropRef] = useDrop<
    EditorSidebarDragItem,
    { nodeId: NodeId },
    {
      isOver: boolean
      canDrop: boolean
    }
  >(() => {
    return {
      accept: [DragItemTypes.EditorSidebar],
      collect: monitor => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        }
      },
      canDrop: item => {
        const nodeId = item.id
        // Don't allow self-drops
        if (nodeId === dropOnNodeId) return false

        // Don't allow drops on cards which are in the current selection
        if (containsSelection(dropOnNodeId)) return false

        if (isFolderId(item.id) && parentFolders.length > 1) {
          // You can't drop folders on files if they are not only in the root folder
          return false
        }

        // Cannot drop assessment cards on files if they are in another folder
        if (isFileId(item.id)) {
          const file = flexibleContentNodes?.[item.id]

          if (isFile(file) && file.data.type === 'assessment-card' && parentFolders.length > 1) {
            return false
          }
        }

        // Don't allow parent folders to be dropped here
        if (isFolderId(item.id) && parentFolders.includes(item.id)) return false

        return true
      },
      hover: (item, monitor) => {
        if (!monitor.isOver({ shallow: true }) || !monitor.canDrop()) return

        const hoveringPosition = monitor.getClientOffset()

        if (hoveringPosition !== null && targetBoundingBox !== undefined) {
          setIndicateBelow(hoveringPosition.y > targetBoundingBox.y + targetBoundingBox.height / 2)
        }
      },
      drop: (item, monitor) => {
        // This tells us if the drop has been handled by another drop target already.
        if (monitor.didDrop()) return

        const nodeId = item.id

        if (isMultiDndEnabled && areMultipleSelected && !isFolderId(nodeId) && containsSelection(nodeId)) {
          // move all nodes
          multiSelectMoveFiles.mutate({
            fileIds: Object.values(selection)
              .filter(s =>
                s.file.data.type === 'assessment-card' ? s.parentFolderId === parentFolderId : true
              )
              .map(s => s.file.id), // filter out assessment cards which are not from the root folder
            folderId: parentFolderId,
            destination: {
              type: 'next-to',
              nodeId: dropOnNodeId,
              position: indicateBelow ? 'below' : 'above',
            },
          })
        } else {
          // mode only one
          void dispatch(
            flexibleContentSlice.actions.moveNode({
              createContentId,
              nodeId,
              parentFolderId: item.parentFolderId,
              destinationId: parentFolderId,
              nextTo: {
                nodeId: dropOnNodeId,
                after: indicateBelow,
              },
            })
          )

          void dispatch(
            editorNodeMovedLogger({
              nodeType: item.itemType,
              nodeId: item.id,
            })
          )
          // Returning an object marks the drop as handled
        }

        return { nodeId }
      },
    }
  }, [
    dropOnNodeId,
    containsSelection,
    parentFolders,
    flexibleContentNodes,
    targetBoundingBox,
    setIndicateBelow,
    areMultipleSelected,
    isMultiDndEnabled,
    multiSelectMoveFiles,
    selection,
    parentFolderId,
    indicateBelow,
    dispatch,
    createContentId,
  ])

  return [{ isOver, canDrop }, dropRef]
}

export const useEditorSidebarDrag = ({
  canEdit,
  fileId,
  file,
  focusedSpeakerNote,
  parentFolderId,
  title,
  iconId,
  createContentId,
}: {
  canEdit: boolean
  fileId: FileId
  file: File
  focusedSpeakerNote: FileId | undefined
  parentFolderId: FolderId
  title: string
  iconId: IconId
  createContentId: CreateContentId
}): [{ isDragging: boolean }, ConnectDragSource, ConnectDragPreview] => {
  const isMobile = useIsMobile()
  const { clearSelection } = useMultiSelection()
  const isMultiDndEnabled = useIsEnabledMultiDnd()

  const [{ isDragging }, handleRef, dragRef] = useDrag<
    EditorSidebarDragItem,
    void,
    { isDragging: boolean }
  >(() => {
    return {
      canDrag: canEdit && focusedSpeakerNote !== fileId && !isMobile,
      type: DragItemTypes.EditorSidebar,
      item: {
        type: DragItemTypes.EditorSidebar,
        id: fileId,
        parentFolderId,
        file,
        title,
        iconId,
        itemType: 'file',
        assetContext: { type: 'course', courseId: createContentId },
      },
      collect: monitor => ({ isDragging: monitor.isDragging() }),
    }
  }, [canEdit, focusedSpeakerNote, fileId, isMobile, parentFolderId, file, title, iconId, createContentId])

  // We should not be able to multi drag if it's not enabled
  useEffect(() => {
    if (isDragging && !isMultiDndEnabled) {
      clearSelection()
    }
  }, [clearSelection, isDragging, isMultiDndEnabled])

  return [{ isDragging }, handleRef, dragRef]
}
