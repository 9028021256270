import { Editor as CoreEditor } from '@tiptap/core'
import Link from '@tiptap/extension-link'
import { Mention } from '@tiptap/extension-mention'
import { EditorContent, Extensions, generateText, JSONContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { cloneDeep, findLast } from 'lodash'
import { useEffect, useMemo } from 'react'
import {
  createEditedMessageMarkJsonContent,
  EditedMessageMark,
} from 'sierra-client/components/chat/tiptap-edited-mark'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

/* Styles for the contents inside the editor */
export const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }

    p {
      font-size: ${fonts.body.small};
      line-height: 140%;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      font-family:
        ui-monospace,
        SFMono-Regular,
        SF Mono,
        Menlo,
        Consolas,
        Liberation Mono,
        monospace;
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      background-color: rgb(175 184 193 / 20%);
      border-radius: 6px;
    }

    strong {
      font-weight: 500;
    }

    pre {
      background: ${token('surface/soft')};
      color: ${token('foreground/primary')};
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid ${token('border/strong')};
    }

    hr {
      border: none;
      border-top: 2px solid ${token('border/default')};
      margin: 2rem 0;
    }

    .link {
      color: ${token('foreground/link')};

      &:hover {
        text-decoration: underline;
      }
    }

    .edited-text {
      color: ${token('foreground/muted')};
      padding-left: 4px;
    }

    .mention {
      font-size: ${fonts.body.small};
      font-weight: ${fonts.weight.bold};
      color: ${token('foreground/link')};
    }
  }

  /* This is styling for the placeholder  */

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #a5a5a5;
    pointer-events: none;
    height: 0;
  }
`

export const getTiptapBaseExtensions = (): Extensions => [
  StarterKit.configure({
    blockquote: false,
    bulletList: false,
    codeBlock: false,
    heading: false,
    horizontalRule: false,
    listItem: false,
    orderedList: false,
  }),
  Link.configure({
    HTMLAttributes: {
      class: 'link',
    },
    autolink: false, // setting this to true causes links to open twice in chats everywhere
  }),
  EditedMessageMark.configure({
    HTMLAttributes: {
      class: 'edited-text',
    },
  }),
]

export const ReadOnlyTiptapContent = ({
  content,
  edited = false,
}: {
  content: unknown
  edited: boolean
}): JSX.Element => {
  const contentToRender = useMemo<JSONContent>(() => {
    if (!edited) return content as JSONContent
    // If the message is edited, we extend the last paragraph by adding the EditedMessageMark
    // immer automatically makes the data frozen, so we need to make a copy in order to modify it
    const contentClone = cloneDeep(content) as JSONContent
    const lastParagraph = findLast(contentClone.content, node => node.type === 'paragraph')

    if (lastParagraph?.content) {
      lastParagraph.content = [...lastParagraph.content, createEditedMessageMarkJsonContent('(edited)')]
    }
    return contentClone
  }, [content, edited])

  const editor = useEditor(
    {
      content: contentToRender,
      extensions: [
        ...getTiptapBaseExtensions(),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        }),
      ],
      editable: false,
    },
    []
  )

  useEffect(() => {
    editor?.commands.setContent(contentToRender)
  }, [contentToRender, editor?.commands])

  return <StyledEditorContent editor={editor} />
}

export const tiptapContentToString = (content: JSONContent, additonalExtensions: Extensions = []): string => {
  return generateText(content, [...getTiptapBaseExtensions(), ...additonalExtensions]).trim()
}

export const getTiptapContents = (editor: CoreEditor): JSONContent => {
  const data = JSON.parse(JSON.stringify(editor.getJSON())) as JSONContent
  data.content = data.content?.filter(
    content => !(content.type === 'paragraph' && content.content === undefined)
  )
  return data
}
