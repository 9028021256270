import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Emoji, useEmojiPicker } from 'sierra-client/components/common/emoji'
import { getFlag } from 'sierra-client/config/global-config'
import { atomWithStorage } from 'sierra-client/state/storage'
import { Popover } from 'sierra-ui/components'
import { Measurable } from 'sierra-ui/components/popover/popover'
import { IconButton, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const EmojiInputButtonContainer = styled.div`
  position: relative;
`

const EmojiInputInnerContainer = styled(View)`
  background-color: white;
  padding: ${spacing['4']};
  gap: 2px;
`

const EmojiButton = styled.button<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  background-color: ${p => (p.$active ? palette.grey[5] : 'white')};
  border-radius: 6px;

  &:hover {
    background-color: ${palette.grey[5]};
  }
`

type ReactionInputProps = {
  onReactionClicked: (reaction: string) => void
  currentSelectedReactions: string[]
}

const SAVED_REACTIONS_COUNT = 2
const DEFAULT_REACTIONS = [':eyes:', ':+1:', ':100:', ':heart:', ':cry:', ':white_check_mark:']
const recentReactionsAtom = atomWithStorage('recent-reflection-reactions', [], z.string().array(), {
  getOnInit: true,
})

export const ReactionInput: React.FC<ReactionInputProps> = ({
  onReactionClicked,
  currentSelectedReactions,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const emojiPickerAnchor = useRef<HTMLDivElement | null>(null)
  const [recentReactions, setRecentReactions] = useAtom(recentReactionsAtom)

  // We use a virtual anchor because we want the popover to be stable, and not move as reactions are added
  const popoverAnchorRef = useRef<HTMLDivElement | null>(null)
  const popoverVirtualAnchor = useRef<Measurable | null>(null)
  useEffect((): void => {
    if (popoverAnchorRef.current) {
      const { current } = popoverAnchorRef
      popoverVirtualAnchor.current = {
        getBoundingClientRect: () => current.getBoundingClientRect(),
        clientWidth: current.clientWidth,
        clientHeight: current.clientHeight,
      }
    }
  }, [])

  const { open: openEmojiPicker, isOpen: isEmojiPickerOpen, close: closeEmojiPicker } = useEmojiPicker()

  const expandedReactionsEnabled = getFlag('expanded-reflection-reactions')

  const react = useCallback(
    (reaction: string) => {
      onReactionClicked(reaction)

      if (!DEFAULT_REACTIONS.includes(reaction) && !recentReactions.includes(reaction)) {
        setRecentReactions([reaction, ...recentReactions.slice(0, SAVED_REACTIONS_COUNT - 1)])
      }
      setPopoverOpen(false)
    },
    [onReactionClicked, recentReactions, setRecentReactions]
  )
  const toggleEmojiPicker = (): void => {
    if (isEmojiPickerOpen) {
      closeEmojiPicker()
    } else if (emojiPickerAnchor.current !== null) {
      openEmojiPicker(
        emojiPickerAnchor.current,
        ({ emojiCode }) => {
          react(emojiCode)
          closeEmojiPicker()
        },
        () => setPopoverOpen(false)
      )
    }
  }

  return (
    <EmojiInputButtonContainer>
      <div ref={popoverAnchorRef} />
      <Popover
        isOpen={popoverOpen}
        onOpenChange={setPopoverOpen}
        customAnchor={popoverVirtualAnchor.current ?? undefined}
        sideOffset={4}
        side='top'
        onInteractOutside={e => {
          if (isEmojiPickerOpen) {
            e.preventDefault()
          }
        }}
        renderTrigger={() => (
          <IconButton
            variant='transparent'
            iconId='face--add'
            color='grey25'
            onClick={() => setPopoverOpen(!popoverOpen)}
          />
        )}
      >
        <EmojiInputInnerContainer direction='row' ref={emojiPickerAnchor}>
          {DEFAULT_REACTIONS.map(reaction => (
            <EmojiButton
              key={reaction}
              $active={currentSelectedReactions.includes(reaction)}
              onClick={() => {
                react(reaction)
                closeEmojiPicker()
              }}
            >
              <Emoji emojiCode={reaction} size={18} />
            </EmojiButton>
          ))}

          {expandedReactionsEnabled && (
            <>
              {recentReactions.map(reaction => (
                <EmojiButton
                  key={reaction}
                  $active={currentSelectedReactions.includes(reaction)}
                  onClick={() => react(reaction)}
                >
                  <Emoji emojiCode={reaction} size={18} />
                </EmojiButton>
              ))}
              <IconButton onClick={toggleEmojiPicker} iconId='face--add' variant='transparent' />
            </>
          )}
        </EmojiInputInnerContainer>
      </Popover>
    </EmojiInputButtonContainer>
  )
}
