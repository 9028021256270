import { FC } from 'react'
import { useInvalidateUserCache } from 'sierra-client/api/hooks/use-user'
import { Auth } from 'sierra-client/core/auth'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { XRealtimeAuthDeleteImpersonationSession } from 'sierra-domain/routes'
import { getUserName } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const BannerTextWrapper = styled.div`
  background-color: ${token('warning/background')};
  text-align: center;
  width: 100%;
  padding: 16px 30px 16px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StopImpersonatingButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  opacity: 85%;
`

export const ImpersonationBanner: FC = () => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const invalidate = useInvalidateUserCache()

  const user = useSelector(selectUser)

  if (user === undefined) return null
  if (user.impersonatorId === undefined) return null

  const stopImpersonation = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeAuthDeleteImpersonationSession, {})
    await Auth.getInstance().synchronize()

    await invalidate()
  }

  return (
    <DarkTokenProvider>
      <BannerTextWrapper>
        <View>
          <Text bold>{t('dictionary.impersonating-banner-impersonating')}</Text>
          <RoundAvatar
            size='tiny'
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
          />
          <Text size='small' bold>
            {getUserName(user)}
          </Text>
        </View>
        <View gap='16'>
          <Text>{t('dictionary.impersonating-banner-actions-recorded')}</Text>

          <StopImpersonatingButton variant='primary' icon='stop--recording' onClick={stopImpersonation}>
            {t('dictionary.stop')}
          </StopImpersonatingButton>
        </View>
      </BannerTextWrapper>
    </DarkTokenProvider>
  )
}
