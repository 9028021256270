import { createSlice } from '@reduxjs/toolkit'
import {
  fetchUsers,
  pendUserIds,
  queueUserIds,
  removePendingUserIds,
} from 'sierra-client/state/users/actions'
import { UserResult, UsersState, userAdapter } from 'sierra-client/state/users/types'

const initialState: UsersState = {
  queuedUserIds: [],
  adapter: userAdapter.getInitialState(),
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(queueUserIds.pending, (state, { meta }) => {
      for (const userId of meta.arg.userIds) {
        if (!state.queuedUserIds.includes(userId)) {
          state.queuedUserIds.push(userId)
        }
      }
    })

    builder.addCase(pendUserIds, (state, { payload }) => {
      userAdapter.upsertMany(
        state.adapter,
        payload.userIds.map(uuid => ({ uuid, status: 'loading' }))
      )
    })

    builder.addCase(removePendingUserIds, (state, { payload }) => {
      userAdapter.removeMany(state.adapter, payload.userIds)
    })

    builder.addCase(fetchUsers.fulfilled, (state, { payload: { users, requestedUserIds } }) => {
      const foundIds = new Set(users.map(it => it.uuid))
      const notFoundIds = requestedUserIds.filter(it => !foundIds.has(it))

      userAdapter.upsertMany(state.adapter, [
        ...users.map((user): Extract<UserResult, { status: 'loaded' }> => ({ ...user, status: 'loaded' })),
        ...notFoundIds.map(uuid => ({ uuid, status: 'not-found' as const })),
      ])
    })
  },
})
