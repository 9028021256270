import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SortOrder } from 'sierra-client/views/v3-author/reflection-card/reflection-card-contexts'
import { useReflectionsData } from 'sierra-client/views/v3-author/reflection-card/reflection-card-data-layer'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const SORT_LABEL = {
  'created-time-desc': 'sort.most-recent',
  'popular': 'sort.popular',
  'created-time-asc': 'sort.oldest',
} as const

export const SortButton = (): JSX.Element | null => {
  const { sortOrder, setSortOrder, availableSortOptions } = useReflectionsData()
  const { t } = useTranslation()

  const menuItems = useMemo<MenuItem<SortOrder>[]>(() => {
    return availableSortOptions.map(option => ({
      type: 'label',
      id: option,
      label: t(SORT_LABEL[option]),
      selected: option === sortOrder,
    }))
  }, [availableSortOptions, t, sortOrder])

  if (setSortOrder === undefined) return null

  const selectedItem = menuItems.find(item => item.id === sortOrder)

  return (
    <SingleSelectDropdown
      grow={false}
      variant='ghost'
      selectedItem={selectedItem}
      menuItems={menuItems}
      onSelect={item => setSortOrder(item.id)}
    />
  )
}
