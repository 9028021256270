import styled from 'styled-components'

const StyledThumbnail = styled.div<{ $height?: number; $width?: number; $radius?: number }>`
  display: flex;
  position: relative;
  width: ${p => (p.$width !== undefined ? p.$width : 4)}rem;
  min-width: ${p => (p.$width !== undefined ? p.$width : 4)}rem;
  height: ${p => (p.$height !== undefined ? p.$height : 4)}rem;
  min-height: ${p => (p.$height !== undefined ? p.$height : 4)}rem;
  border-radius: ${p => (p.$radius !== undefined ? p.$radius : 1)}rem;
  background-color: ${p => p.theme.color.grey10};
  overflow: hidden;
`

const ThumbnailImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Thumbnail: React.FC<{
  image?: string
  className?: string
  style?: React.CSSProperties
  width?: number
  height?: number
  radius?: number
  lazy?: boolean
}> = ({ image, width, height, radius, lazy, ...props }) => (
  <StyledThumbnail $width={width} $height={height} $radius={radius} {...props}>
    {image !== undefined ? <ThumbnailImg loading={lazy !== false ? 'lazy' : undefined} src={image} /> : null}
  </StyledThumbnail>
)
