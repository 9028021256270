import { useEffect, useState } from 'react'
import { getFileContent } from 'sierra-client/api/content'
import FileIcon from 'sierra-client/assets/images/editor/file-attachment.svg'
import { getFlag } from 'sierra-client/config/global-config'
import { useLoadNamespacedCourseAssets, useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import {
  Toolbar,
  ToolbarIcon,
  ToolbarItem,
  ToolbarSeparator,
  ToolbarV2,
} from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { useEditorAssetContext } from 'sierra-client/views/v3-author/context'
import {
  useEditorFileUploadStatus,
  useEditorUploadFile,
} from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import {
  Color,
  downloadFileAttachment,
  formatBytes,
  getColor,
} from 'sierra-client/views/v3-author/file-attachment/utils'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { AssetContext } from 'sierra-domain/asset-context'
import { XRealtimeImportAssetsFromZip } from 'sierra-domain/routes'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { BlockToolbarButton } from 'sierra-ui/components/block-toolbar/toolbar-components/block-toolbar-button'
import { BlockToolbarIconButton } from 'sierra-ui/components/block-toolbar/toolbar-components/block-toolbar-icon-button'
import { BlockToolbarSeparator } from 'sierra-ui/components/block-toolbar/toolbar-components/block-toolbar-separator'
import { IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { useFocused, useSelected } from 'slate-react'

import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const ToolbarColor = styled.button<{ color: string; selected: boolean }>`
  background-color: ${p => p.color};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: 1px solid ${p => (p.selected ? palette.primitives.white : p.theme.color.grey35)};
  cursor: pointer;

  & + & {
    margin-left: 1rem;
  }
`
const brightnessThreshold = 0.5

const NewFileIconWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  width: 72px;
  position: relative;
  aspect-ratio: 1/1;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.1)};
  border-radius: 16px;
  background-color: white;
  transform: rotate(-2deg);
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.08);
`

export const RightAlignedIcon = styled(Icon)`
  margin-left: auto;
  opacity: 0.3;
`

const FileAttachmentContainer = styled.div<{ $color: Color; $selected: boolean; $newStyle: boolean }>`
  padding: ${spacing['32']};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: visible;
  ${({ $newStyle, $color, theme }) =>
    $newStyle
      ? css`
          background-color: ${color(theme.home.backgroundColor).under(
            color(theme.home.textColor).opacity(0.1)
          )};
          color: ${color(theme.home.textColor)};
          border-radius: ${p => p.theme.borderRadius['size-16']};
          &::before {
            content: '';
            position: absolute;
            inset: -1px;
            z-index: -1;
            border-radius: 17px;
            --border-bottom-color: ${() => {
              if (color(theme.home.textColor).brightness() < brightnessThreshold)
                return color(theme.home.textColor).opacity(0.15)
              else {
                return color(theme.home.textColor).opacity(0)
              }
            }};
            --border-top-color: ${() => {
              if (color(theme.home.textColor).brightness() < brightnessThreshold)
                return color(theme.home.textColor).opacity(0)
              else return color(theme.home.textColor).opacity(0.15)
            }};
            background: linear-gradient(0deg, var(--border-bottom-color) 0%, var(--border-top-color) 100%);
          }
          &:hover {
            ${NewFileIconWrapper} {
              box-shadow: none;
              transform: rotate(0deg) scale(0.95);
            }
            ${RightAlignedIcon} {
              opacity: 0.6;
            }
            background-color: ${color(theme.home.backgroundColor).under(
              color(theme.home.textColor).opacity(0.14)
            )};

            --border-bottom-color: ${p => {
              if (color(p.theme.home.textColor).brightness() < brightnessThreshold)
                return color(p.theme.home.textColor).opacity(0.19)
              else {
                return color(p.theme.home.textColor).opacity(0)
              }
            }};
            --border-top-color: ${p => {
              if (color(p.theme.home.textColor).brightness() < brightnessThreshold)
                return color(p.theme.home.textColor).opacity(0)
              else return color(p.theme.home.textColor).opacity(0.19)
            }};
          }
        `
      : css`
          background-color: ${getColor($color)};
          color: ${palette.primitives.white};
          border-radius: ${p => p.theme.borderRadius['size-10']};
        `}
  height: 7rem;

  ${p =>
    p.$selected &&
    (p.$newStyle
      ? css`
          box-shadow: 0 0 0 3px ${color(p.theme.home.textColor).opacity(0.15)};
          &::before {
            opacity: 0;
          }
        `
      : css`
          box-shadow: 0 0 0 3px #b4d5ff;
        `)}
`

const Audio = styled.audio<{ $selected: boolean }>`
  width: 100%;
  ${p =>
    p.$selected &&
    css`
      box-shadow: 0 0 0 3px #b4d5ff;
    `}
  border-radius: 10px;
`

const FileIconWrapper = styled.div`
  svg {
    display: block;
  }

  margin-right: 1.5rem;
  width: 2rem;
  height: auto;
  position: relative;
`

export const RightAlignedIconButton = styled(IconButton)`
  margin-left: auto;
`

const NowrapText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const FullSizedView = styled(View)`
  width: 100%;
`

const BytesText = styled(Text)`
  user-select: none;
`

const PaddedToolbar = styled(Toolbar)`
  padding: 4px;
`

const FileAttachmentToolbar: React.FC<{
  element: SlateRootElement & { type: 'file-attachment' }
  hideColors?: boolean
  assetContext: AssetContext
}> = ({ element, hideColors: _hideColors = false, assetContext }) => {
  const nodeId = element.id
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const showUploadFileModal = useEditorUploadFile().showUploadModal
  const status = useEditorFileUploadStatus({ nodeId })
  const isUploading = status === 'uploading'

  const useNamespaced = useLoadNamespacedCourseAssets()
  const namespacedDownloadUrl = useResolveAsset({
    assetContext,
    image: { type: 'file', file: element.urlId },
    assetType: 'raw',
    size: 'default',
  })
  const downloadUrl = useNamespaced ? namespacedDownloadUrl : getFileContent(element.urlId)
  const isNewStyle = getFlag('remove-file-block-color')

  const hideColors = isNewStyle || _hideColors
  if (isNewStyle) {
    return (
      <ToolbarV2 elementId={nodeId} ignoreEditorFocus>
        <BlockToolbarButton
          iconId='download'
          label={t('author.block-editor.fileblock-toolbar.download')}
          onClick={() => downloadFileAttachment(downloadUrl, element.name)}
        />
        <BlockToolbarSeparator />
        <BlockToolbarButton
          iconId={isUploading ? 'loading' : 'folder'}
          label={t('author.block-editor.fileblock-toolbar.replace')}
          onClick={() => showUploadFileModal({ nodeId, assetContext })}
        />
        <BlockToolbarIconButton
          tooltip={t('author.block-editor.remove')}
          iconId='trash-can'
          onClick={() => removeNodeWithId(editor, nodeId)}
        />
      </ToolbarV2>
    )
  }

  return (
    <PaddedToolbar elementId={nodeId} ignoreEditorFocus>
      {!hideColors && (
        <>
          <ToolbarItem onClick={() => updateNodeWithId(editor, nodeId, { color: 'blue' })}>
            <ToolbarColor color={getColor('blue')} selected={element.color === 'blue'} />
          </ToolbarItem>

          <ToolbarItem onClick={() => updateNodeWithId(editor, nodeId, { color: 'black' })}>
            <ToolbarColor color={getColor('black')} selected={element.color === 'black'} />
          </ToolbarItem>

          <ToolbarItem onClick={() => updateNodeWithId(editor, nodeId, { color: 'red' })}>
            <ToolbarColor color={getColor('red')} selected={element.color === 'red'} />
          </ToolbarItem>

          <ToolbarSeparator />
        </>
      )}

      {isUploading ? (
        <LoadingSpinner size='24' color='white' />
      ) : (
        <ToolbarIcon
          iconId='arrow--up'
          tooltip={t('author.block-editor.file-update')}
          onClick={() => showUploadFileModal({ nodeId, assetContext })}
        />
      )}

      {element.urlId !== '' && (
        <>
          <ToolbarSeparator />
          <ToolbarIcon iconId='download' onClick={() => downloadFileAttachment(downloadUrl, element.name)} />
        </>
      )}

      <ToolbarSeparator />

      <ToolbarIcon
        tooltip={t('author.block-editor.remove')}
        iconId='trash-can'
        onClick={() => removeNodeWithId(editor, nodeId)}
      />
    </PaddedToolbar>
  )
}

export const FileAttachment: SlateFC = ({ children, element, readOnly }) => {
  assertElementType('file-attachment', element)

  const nodeId = element.id
  const editor = useSlateStatic()
  const { showUploadModal, uploadFile } = useEditorUploadFile()
  const fileUploadStatus = useEditorFileUploadStatus({ nodeId })

  const [initialFile] = useState(() => {
    const file = editor.initialFileUploads[nodeId]
    if (file) delete editor.initialFileUploads[nodeId]
    return file
  })

  const assetContext = useEditorAssetContext()

  useEffect(() => {
    if (initialFile) {
      void uploadFile({ nodeId, file: initialFile, assetContext })
    }
  }, [initialFile, nodeId, uploadFile, assetContext])

  // Handle copy-pasting of file attachments between courses/orgs
  const [fileIsImporting, setFileIsImporting] = useState(
    editor.importingAssetsFileUrls[element.urlId] !== undefined
  )
  useEffect(() => {
    if (!fileIsImporting) return
    const importAsset = async (): Promise<void> => {
      if (assetContext.type !== 'course') return
      const signedUrl = editor.importingAssetsFileUrls[element.urlId]
      if (signedUrl === undefined) return
      await typedPost(XRealtimeImportAssetsFromZip, {
        courseId: assetContext.courseId,
        signedUrl,
        filterImageId: [element.urlId],
      })
      setFileIsImporting(false)
    }
    void importAsset()
  }, [assetContext, element.urlId, editor, fileIsImporting, setFileIsImporting])

  const status = fileIsImporting ? 'uploading' : fileUploadStatus

  useEffect(() => {
    if (status === 'idle') return
    if (status === 'uploading') return
    updateNodeWithId(editor, nodeId, status)
  }, [editor, nodeId, status])

  const isMp3 = element.name.endsWith('.mp3')

  const hasFileAttachment = element.urlId !== ''

  const focused = useFocused()
  const selected = useSelected()

  const useNamespaced = useLoadNamespacedCourseAssets()
  const namespacedDownloadUrl = useResolveAsset({
    assetContext,
    image: { type: 'file', file: element.urlId },
    assetType: 'raw',
    size: 'default',
  })
  const downloadUrl = useNamespaced ? namespacedDownloadUrl : getFileContent(element.urlId)
  const isNewStyle = getFlag('remove-file-block-color')

  const textColor = isNewStyle ? ('currentColor' as const) : ('white' as const)

  if (isMp3) {
    return (
      <>
        {children}
        <Audio contentEditable={false} $selected={focused && selected} controls data-block-inner={nodeId}>
          <source src={downloadUrl} type='audio/mpeg' />
        </Audio>
        <FileAttachmentToolbar element={element} hideColors assetContext={assetContext} />
      </>
    )
  }

  return (
    <>
      <FileAttachmentContainer
        $color={element.color}
        $newStyle={isNewStyle}
        $selected={focused && selected}
        data-block-inner={nodeId}
        onClick={
          readOnly && hasFileAttachment ? () => downloadFileAttachment(downloadUrl, element.name) : undefined
        }
      >
        {children}
        <FullSizedView contentEditable={false}>
          {status === 'uploading' ? (
            <LoadingSpinner size='40' padding='none' color={textColor} />
          ) : isNewStyle ? (
            <NewFileIconWrapper>
              <Icon color='grey60' iconId='folder' size='size-24' />
            </NewFileIconWrapper>
          ) : (
            <FileIconWrapper>
              <FileIcon />
            </FileIconWrapper>
          )}
          <FullSizedView direction='column' gap='none'>
            <NowrapText size='large' bold color={textColor}>
              {element.name}
            </NowrapText>
            <BytesText size='micro' bold color={textColor}>
              {formatBytes(element.size)}
            </BytesText>
          </FullSizedView>
        </FullSizedView>
        {!(readOnly && !hasFileAttachment) &&
          (isNewStyle ? (
            <RightAlignedIcon
              contentEditable={false}
              onClick={e => {
                if (hasFileAttachment) {
                  void downloadFileAttachment(downloadUrl, element.name)
                  e.stopPropagation()
                } else if (!readOnly) {
                  showUploadModal({ nodeId, assetContext })
                }
              }}
              iconId={hasFileAttachment ? 'download' : 'arrow--up'}
              size='size-24'
            />
          ) : (
            <RightAlignedIconButton
              contentEditable={false}
              onClick={e => {
                if (hasFileAttachment) {
                  void downloadFileAttachment(downloadUrl, element.name)
                  e.stopPropagation()
                } else if (!readOnly) {
                  showUploadModal({ nodeId, assetContext })
                }
              }}
              variant='transparent'
              iconId={hasFileAttachment ? 'download' : 'arrow--up'}
            />
          ))}

        <FileAttachmentToolbar element={element} assetContext={assetContext} />
      </FileAttachmentContainer>
    </>
  )
}
