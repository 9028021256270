import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import { DynamicT } from 'sierra-client/hooks/use-translation/types'
import { NotificationPushType } from 'sierra-client/state/notifications/types'
import { AppDispatch } from 'sierra-client/state/store'
import { isInElement } from 'sierra-client/views/v3-author/command'
import { ShowUploadFileModal } from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import { getCurrentlySelectedLeaf, getCurrentlySelectedNode } from 'sierra-client/views/v3-author/queries'
import {
  isSlashMenuEntryEnabled,
  slashMenuEntries,
} from 'sierra-client/views/v3-author/slash-menu/slash-menu-entries'
import { CreatePageSlashMenuContext, resolveLabel } from 'sierra-client/views/v3-author/slash-menu/types'
import {
  clearSearchText,
  currentSearchText,
  startState,
} from 'sierra-client/views/v3-author/slash-menu/utils'
import { Flags } from 'sierra-domain/api/private'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { SLASH_MENU_COLUMN_SIZE, SlashMenuState } from 'sierra-domain/v3-author/slash-menu'
import { BaseRange, Editor, Element } from 'slate'

/*
  Return true when the shortcut menu should be disabled
 */
const isDisabled = (editor: Editor): boolean => {
  return isInElement(editor, 'list-item')
}

export const onHover = (state: SlashMenuState, index: number): SlashMenuState => {
  if (state.type === 'idle') {
    throw Error('Shortcut menu: Should not be in idle state when hovering items.')
  }

  return {
    ...state,
    selectedIndex: index,
  }
}

export const onClick = ({
  idsSupportedByEditor,
}: Pick<SlashMenuState, 'idsSupportedByEditor'>): SlashMenuState => ({
  type: 'idle',
  idsSupportedByEditor,
})

const loop = (index: number, increase: number, length: number): number => {
  const result = index + increase
  return result < 0 || result >= length ? index : result
}

export const onKeyDown = ({
  createPageContext,
  event,
  editor,
  selection,
  dispatch,
  state,
  notification,
  dynamicT,
  showUploadFileModal,
  flags,
}: {
  createPageContext: CreatePageSlashMenuContext | undefined
  event: EditorKeyboardEvent
  editor: Editor
  selection: BaseRange | null
  dispatch: AppDispatch
  state: Readonly<SlashMenuState>
  notification?: {
    push: (v: NotificationPushType) => void
  }
  showUploadFileModal: ShowUploadFileModal
  dynamicT: DynamicT
  flags: Flags
}): SlashMenuState => {
  const { idsSupportedByEditor } = state
  const slashMenuEntryExists = idsSupportedByEditor.some(id =>
    isSlashMenuEntryEnabled(editor, flags, slashMenuEntries[id])
  )
  // If there are no possible slash menu entries, don't show the menu
  if (!slashMenuEntryExists) return { type: 'idle', idsSupportedByEditor }

  if (state.type !== 'active') {
    if (event.key === '/') {
      return startState(editor, { idsSupportedByEditor })
    }
    return state
  } else {
    const { idsMatchingSearchText, selectedIndex } = state

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault()
        return { ...state, selectedIndex: state.selectedIndex + 1 }
      }
      case 'ArrowUp': {
        event.preventDefault()
        return { ...state, selectedIndex: state.selectedIndex - 1 }
      }
      case 'ArrowLeft':
        event.preventDefault()
        return {
          ...state,
          selectedIndex: loop(selectedIndex, -1 * SLASH_MENU_COLUMN_SIZE, idsMatchingSearchText.length),
        }
      case 'ArrowRight':
        event.preventDefault()
        return {
          ...state,
          selectedIndex: loop(selectedIndex, SLASH_MENU_COLUMN_SIZE, idsMatchingSearchText.length),
        }
      case 'Tab':
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()

        try {
          const { startPoint } = state
          const matchingSlashMenuEntries = idsMatchingSearchText.map(id => ({ id, ...slashMenuEntries[id] }))
          const result = matchingSlashMenuEntries[selectedIndex % matchingSlashMenuEntries.length]
          const currentElement = getCurrentlySelectedNode(editor)
          const currentId = Element.isElement(currentElement?.[0]) ? currentElement[0].id : undefined

          if (result !== undefined) {
            clearSearchText(editor, selection, startPoint)
            editor.pushActionsLogEntry({ type: 'select-slash-menu-entry', id: result.id, withEnter: true })
            result.edit({
              createPageContext,
              editor,
              dispatch,
              lastSelection: selection,
              currentId,
              notification,
              showUploadFileModal,
              dynamicT,
            })
          }
        } catch (e) {
          console.debug('[slash-menu] Error selecting slash menu entry:', e)
        }

        return { type: 'idle', idsSupportedByEditor }
      }
      case '/':
      case 'Escape': {
        event.preventDefault()
        return { type: 'idle', idsSupportedByEditor }
      }
      default:
        return state
    }
  }
}

export const onUpdate = (
  editor: Editor,
  state: SlashMenuState,
  t: DynamicT,
  flags: Flags
): SlashMenuState => {
  if (state.type !== 'active') return state

  const { idsSupportedByEditor } = state
  if (isDisabled(editor)) return { type: 'idle', idsSupportedByEditor }

  const searchText = currentSearchText(editor)

  if (searchText !== undefined) {
    const possibleEntries = idsSupportedByEditor.flatMap(id => {
      const entry = slashMenuEntries[id]
      if (isSlashMenuEntryEnabled(editor, flags, entry)) return [{ label: resolveLabel(entry.label, t), id }]
      else return []
    })

    const idsMatchingSearchText =
      searchText.length === 0
        ? possibleEntries.map(it => it.id)
        : _.chain(fuzzysort.go(searchText, possibleEntries, { key: 'label' }))
            .map(it => ({ ...it, score: it.score * -1 })) //sortBy only sorts in ascending order but we want score to be sorted in descending order
            .sortBy(['score', 'target'])
            .map(({ obj }) => obj.id)
            .value()

    return { ...state, searchText, idsMatchingSearchText }
  } else {
    // Stop searching if no search text is available. e.g., the user deleted the '/' character.
    const currentLeaf = getCurrentlySelectedLeaf(editor)

    if (currentLeaf !== undefined) {
      const { text } = currentLeaf[0]
      const lastCharIsSlash = _.last(text) !== '/'

      if (lastCharIsSlash) {
        return { type: 'idle', idsSupportedByEditor }
      }
    }

    // There is no slash, keep the current one
    return state
  }
}
