import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { SimpleBadge, simpleBadgeThemeMap } from 'sierra-client/features/skills'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCurrentProgram } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { EndOfCourseRecommendations } from 'sierra-client/views/course-helper/content/course-helper-recommendations/end-of-course-recommendations'
import { HelperContent } from 'sierra-client/views/course-helper/shared/shared'
import { Achievements } from 'sierra-client/views/self-paced/achievements'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { useMyAchievedBadges } from 'sierra-client/views/self-paced/use-my-achieved-badges'
import { useMyIssuedCertificates } from 'sierra-client/views/self-paced/use-my-issued-certificates'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const Background = styled(motion.div)<{ backgroundSrc: string }>`
  position: absolute;
  inset: -10px;
  background-image: url(${p => p.backgroundSrc});
  filter: blur(5px);
  background-position: center;
  background-size: cover;
`

const BackgroundColor = styled(motion.div)<{ backgroundColor: string }>`
  position: absolute;
  inset: 0;
  background-image: ${p => p.backgroundColor};
`

const CertificateContainer = styled(View)<{ withGradient: boolean }>`
  position: relative;
  ${p =>
    p.withGradient &&
    css`
      background-image: linear-gradient(60.97deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    `}

  padding: 18px 24px;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
`

const Img = styled.img`
  position: absolute;
  width: auto;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  aspect-ratio: 16/12;
  right: 50px;
  bottom: -10px;
  transform: rotateZ(-3deg);
  box-shadow:
    0px 17.77px 26.65px 0px #00000014,
    0px 0px 0px 1.11px #0000000a;
`

const BadgeContainer = styled.div`
  position: absolute;
  height: 100px;
  width: 100px;
  object-fit: cover;
  right: 50px;
  transform: rotateZ(4deg);
  bottom: -30px;
`

const RelativeView = styled(View)``

export const CertificatesAndBadges: React.FC = () => {
  const { t } = useTranslation()
  const [showAchievements, setShowAchievements] = useState(false)
  const program = useSelector(state => selectCurrentProgram(state))
  const flexibleContentId = useSelfPacedFiles().flexibleContentId

  const { certificates } = useMyIssuedCertificates({
    courseId: flexibleContentId,
    programId: program?.id,
    enabled: true,
  })

  const { badges } = useMyAchievedBadges({
    courseId: flexibleContentId,
    enabled: true,
  })

  if (certificates.length === 0) {
    return <></>
  }

  return (
    <RelativeView direction='column' gap='8' paddingBottom='24'>
      {certificates.map((certificate, index) => (
        <div style={{ position: 'relative', overflow: 'hidden', borderRadius: '24px' }} key={index}>
          <Background
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: (certificates.length - index) * 0.4 }}
            backgroundSrc={certificate.url ?? ''}
          />
          <CertificateContainer
            withGradient
            onClick={() => setShowAchievements(true)}
            animated
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: (certificates.length - index) * 0.4 }}
            direction='row'
            padding='16 24'
            justifyContent='space-between'
            grow
          >
            <View gap='4' direction='column'>
              <View direction='row'>
                <Icon color={'white'} iconId='skill--rosette' />
                <Text color={'white'} bold>
                  {t('helper.certificates.new-certificate')}
                </Text>
              </View>
              <Text bold style={{ cursor: 'pointer', opacity: 0.5 }} color={'white'}>
                {t('helper.certificates.click-to-view')}
              </Text>
            </View>
            <Img src={certificate.url} />
          </CertificateContainer>
        </div>
      ))}
      {badges.map((badge, index) => (
        <div style={{ position: 'relative', overflow: 'hidden', borderRadius: '24px' }} key={index}>
          <BackgroundColor backgroundColor={simpleBadgeThemeMap[badge.badgeTheme].backgroundColor} />
          <CertificateContainer
            withGradient={false}
            onClick={() => setShowAchievements(true)}
            animated
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: (certificates.length - index - 1) * 0.2 }}
            direction='row'
            padding='16 24'
            justifyContent='space-between'
            grow
          >
            <View gap='4' direction='column'>
              <View direction='row'>
                <Icon color={'white'} iconId='skill--rosette' />
                <Text color={'white'} bold>
                  {t('helper.badges.new-badge')}
                </Text>
              </View>
              <Text bold style={{ cursor: 'pointer', opacity: 0.5 }} color={'white'}>
                {t('helper.certificates.click-to-view')}
              </Text>
            </View>
            <BadgeContainer>
              <SimpleBadge iconId={badge.badgeIcon} theme={badge.badgeTheme} />
            </BadgeContainer>
          </CertificateContainer>
        </div>
      ))}
      {showAchievements && (
        <Achievements
          open={showAchievements}
          onClose={() => setShowAchievements(false)}
          achievements={[...certificates, ...badges]}
        />
      )}
    </RelativeView>
  )
}

export const ProgramCompleted: React.FC = () => {
  const { t } = useTranslation()

  return (
    <HelperContent title={t('helper.program-completed')} icon='racing--flag'>
      <CertificatesAndBadges />
      <EndOfCourseRecommendations />
    </HelperContent>
  )
}
