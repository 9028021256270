import {
  XRealtimeAdminCoursesListEnrolledUsers,
  XRealtimeAdminCoursesListLiveSessions,
} from 'sierra-domain/routes'

import { useSearch } from '@tanstack/react-router'
import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useThrottledAndLiveState } from 'sierra-client/hooks/use-throttled-state'
import { removeSearchParams } from 'sierra-client/router/search-params'
import {
  BaseListUsersRequest,
  CourseStatus,
  ListContentEnrolledUsersResponse,
  ListCourseEnrolledUsersRequest,
  LiveSessionRow,
} from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { assertNever } from 'sierra-domain/utils'

export type LSFilter = {
  groups: string[]
  programs: string[]
  assignedToLiveSession?: 'assigned' | 'not-assigned'
  status?: CourseStatus
  query: string
}

const parseAssignmentFilter = (value: LSFilter['assignedToLiveSession']): boolean | undefined => {
  if (value === 'assigned') return true
  if (value === 'not-assigned') return false
  return undefined
}

type UseManageCourseLiveSessionsSettings = {
  courseId?: CourseId
  initialUsersToLoad?: number
}
export type UseManageCourseLiveSessionsOutput = {
  allAssignedUsers: ListContentEnrolledUsersResponse & { isLoading: boolean }
  isLoading: boolean
  liveSessions: LiveSessionRow[]
  filter: LSFilter
  setFilter: Dispatch<SetStateAction<LSFilter>>
  fetchUserAssignments: (
    contentId: string,
    filters?: Partial<BaseListUsersRequest>,
    options?: {
      reset?: boolean
      // forCsv?: boolean
      assignedToLiveSession?: LSFilter['assignedToLiveSession']
      status?: LSFilter['status']
    }
  ) => Promise<ListContentEnrolledUsersResponse>
  updateUsersRows: (courseId: CourseId, userIds: UserId[]) => Promise<void>
  fetchLiveSessions: (
    courseId: CourseId,
    options?: { skipPastSessions?: boolean }
  ) => Promise<LiveSessionRow[]>
}

const status2ProgressType = (status?: LSFilter['status']): ListCourseEnrolledUsersRequest['progressType'] => {
  switch (status) {
    case 'passed':
      return 'completed'
    case 'started':
      return 'started'
    case 'not-started':
      return 'not-started'
    case undefined:
      return undefined
    default:
      assertNever(status)
  }
}

export const useManageCourseLiveSessions = ({
  courseId,
  initialUsersToLoad = 20,
}: UseManageCourseLiveSessionsSettings = {}): UseManageCourseLiveSessionsOutput => {
  const { postWithUserErrorException } = usePost()
  const initialGroupIdFilter = useSearch({ strict: false, select: s => s.groupId })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [liveSessions, setLiveSessions] = useState<LiveSessionRow[]>([])
  const [allAssignedUsers, setAllAssignedUsers] = useState<
    UseManageCourseLiveSessionsOutput['allAssignedUsers']
  >({
    hasMore: false,
    data: [],
    isLoading: false,
  })
  const [throttledFilter, filter, setFilter] = useThrottledAndLiveState<
    UseManageCourseLiveSessionsOutput['filter']
  >({
    groups: initialGroupIdFilter !== undefined ? [initialGroupIdFilter] : [],
    programs: initialGroupIdFilter !== undefined ? [initialGroupIdFilter] : [],
    assignedToLiveSession: undefined,
    status: undefined,
    query: '',
  })

  useEffect(() => {
    if (initialGroupIdFilter !== undefined) {
      removeSearchParams(['groupId'])
    }
  }, [initialGroupIdFilter])

  const fetchUserAssignments = useCallback<UseManageCourseLiveSessionsOutput['fetchUserAssignments']>(
    async (courseId, commonFilters, options = {}) => {
      setAllAssignedUsers(current => ({ ...current, isLoading: true }))
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledUsers, {
        courseId,
        assignedToLiveSession: parseAssignmentFilter(options.assignedToLiveSession),
        progressType: status2ProgressType(options.status),
        commonFilters: {
          lastUserId: undefined,
          query: undefined,
          requestedUserIds: undefined,
          groupIds: undefined,
          maxResults: 20,
          sortBy: { direction: 'asc', type: 'name' },
          ...commonFilters,
        },
      })

      setAllAssignedUsers(current =>
        options.reset === true
          ? { ...response, isLoading: false }
          : {
              hasMore: response.hasMore,
              data: current.data.concat(response.data),
              isLoading: false,
            }
      )
      return response
    },
    [postWithUserErrorException]
  )
  const updateUsersRows = useCallback<UseManageCourseLiveSessionsOutput['updateUsersRows']>(
    async (courseId, requestUserIds) => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledUsers, {
        courseId,
        assignedToLiveSession: undefined,
        commonFilters: {
          requestedUserIds: requestUserIds,
          lastUserId: undefined,
          query: undefined,
          groupIds: undefined,
          maxResults: undefined,
          sortBy: { direction: 'asc', type: 'name' },
        },
      })

      const userById = _.keyBy(response.data, row => row.userInfo.baseUserInfo.userId)
      setAllAssignedUsers(current => ({
        ...current,
        data: current.data.map(row => userById[row.userInfo.baseUserInfo.userId] ?? row),
      }))
    },
    [postWithUserErrorException]
  )

  const fetchLiveSessions = useCallback<UseManageCourseLiveSessionsOutput['fetchLiveSessions']>(
    async (courseId, options = {}) => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListLiveSessions, {
        flexibleContentId: courseId,
        skipPastSessions: options.skipPastSessions ?? false,
      })
      setLiveSessions(response)
      return response
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    if (!courseId) return

    setIsLoading(true)
    void (async () => {
      await fetchLiveSessions(courseId)
    })()
    setIsLoading(false)
  }, [courseId, fetchUserAssignments, fetchLiveSessions])

  useEffect(() => {
    if (!courseId) return

    void fetchUserAssignments(
      courseId,
      {
        groupIds: filter.groups.concat(filter.programs),
        query: throttledFilter.query,
        maxResults: initialUsersToLoad,
      },
      { assignedToLiveSession: filter.assignedToLiveSession, reset: true, status: filter.status }
    )
  }, [
    courseId,
    fetchUserAssignments,
    filter.assignedToLiveSession,
    filter.groups,
    filter.programs,
    filter.status,
    throttledFilter.query,
    initialUsersToLoad,
  ])

  return {
    allAssignedUsers,
    liveSessions,
    isLoading,
    filter,
    updateUsersRows,
    setFilter,
    fetchUserAssignments,
    fetchLiveSessions,
  }
}
