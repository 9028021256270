import { useMemo } from 'react'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { EditorInner, LayoutType } from 'sierra-client/views/flexible-content/editor-inner'
import { WaitForEditor } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-polaris-editor'
import { EditorContext } from 'sierra-client/views/v3-author/context'
import { renderLeaf } from 'sierra-client/views/v3-author/render-leaf'
import * as Renderer from 'sierra-client/views/v3-author/renderer'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { File, SlateFileType } from 'sierra-domain/flexible-content/types'
import { assertNever, assertWith } from 'sierra-domain/utils'
import { SlashMenuEntryId, allSlashMenuEntryIds } from 'sierra-domain/v3-author/slash-menu'
import { Editor } from 'slate'

const getSupportedSlashMenuEntryIds = (fileType: SlateFileType): readonly SlashMenuEntryId[] => {
  switch (fileType) {
    case 'general':
      return allSlashMenuEntryIds.filter(
        entryId =>
          ![
            'generate-text-50',
            'generate-text-150',
            'poll',
            'group-question-choose-the-best-answer',
            'group-question-select-all-that-apply',
            'group-question-match-pairs',
            'free-text',
            'choose-the-best-answer',
            'select-all-that-apply',
            'match-pairs',
            'generate-question',
          ].includes(entryId)
      )

    case 'notepad':
    case 'external-notepad':
      return [
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'turn-into-paragraph',
        'paragraph',
        'link',
        'bullet-list',
        'numbered-list',
        'check-list',
        'preamble',
        'image',
        'table',
      ] as const

    case 'question-card':
    case 'reflections':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'assessment-card':
    case 'homework':
    case 'scenario':
      return [
        'paragraph',
        'turn-into-paragraph',
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'numbered-list',
        'bullet-list',
        'check-list',
        'image',
        'embed',
        'preamble',
        'separator',
        'code',
        'file-attachment',
        'markdown',
        'link',
      ] as const

    case 'slate-card':
    case 'bullet':
      return [
        'paragraph',
        'turn-into-paragraph',
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'numbered-list',
        'bullet-list',
        'check-list',
        'link',
        'generate-text-100',
      ] as const

    case 'roleplay':
    case 'sticky-notes':
    case 'project-card':
      return []
    default:
      assertNever(fileType)
  }
}

export type SlateCardProps = {
  courseId: CreateContentId
  file: File
  readOnly: boolean
  mode: EditorMode
  enableCommenting?: boolean
}

export const SlateCardWithExplicitEditor: React.FC<
  SlateCardProps & { editor: Editor; assetContext: AssetContext }
> = ({ file, readOnly, mode, enableCommenting, editor, assetContext }) => {
  assertWith(SlateFileType, file.data.type)
  const fileId = file.id
  const fileType = file.data.type
  const supportedSlashMenuEntryIds = useMemo(() => getSupportedSlashMenuEntryIds(fileType), [fileType])
  const document = editor.children
  const chatId = useCreatePageContextSafe()?.chatId
  const chatIdentifier = useCreatePageContextSafe()?.chatIdentifier

  const cursorsEnabled = fileType === 'notepad' || fileType === 'external-notepad' || mode === 'create'

  const layout: LayoutType | undefined = ['general', 'notepad', 'external-notepad'].includes(fileType)
    ? 'page-card'
    : ['sliding-scale'].includes(fileType)
      ? 'interactive-card'
      : undefined

  return (
    <EditorContext
      initialValue={document}
      editor={editor}
      editorId={fileId}
      mode={mode}
      readOnly={readOnly}
      supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
      chatId={chatId}
      chatIdentifier={chatIdentifier}
      enableCommenting={enableCommenting ?? false}
      assetContext={assetContext}
    >
      <EditorInner
        layout={layout}
        renderElement={Renderer.renderElement}
        renderLeaf={renderLeaf}
        cursorsEnabled={cursorsEnabled}
      />
    </EditorContext>
  )
}

export const SlateCard: React.FC<SlateCardProps> = props => {
  const assetContext: AssetContext = { type: 'course', courseId: props.courseId }
  return (
    <WaitForEditor>
      {editor => (
        <SlateCardWithExplicitEditor
          editor={editor}
          // Must use key to remount component when the editor ref changes because SlateContext requires an unchanging editor prop.
          key={editor.key}
          assetContext={assetContext}
          {...props}
        />
      )}
    </WaitForEditor>
  )
}
