import { createContext, default as React, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import {
  findPathToNodeWithId,
  insertNodeAfterNodeWithId,
  removeNodeWithId,
} from 'sierra-client/views/v3-author/command'
import { useEditorMode } from 'sierra-client/views/v3-author/context'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { useAncestorEntryWithType } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { VariationsTopbar } from 'sierra-client/views/v3-author/question-card/question-grid'
import { SettingButtons } from 'sierra-client/views/v3-author/question-card/settings'
import { pickQuestionCardFromVariation } from 'sierra-client/views/v3-author/question-card/utils'
import { VariationsButton } from 'sierra-client/views/v3-author/question-card/variations-button'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { Entity } from 'sierra-domain/entity'
import { QuestionVariations } from 'sierra-domain/v3-author'
import { createQuestionCard } from 'sierra-domain/v3-author/create-blocks'
import { color } from 'sierra-ui/color'
import { IconButton, View } from 'sierra-ui/primitives'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

type QuestionVariationsContext = {
  variationIndex: (questionId: string) => number
  currentQuestionId: string | undefined
  addVariation: () => void
}

export const ReactQuestionVariationsContext = createContext<QuestionVariationsContext | undefined>(undefined)

const QuestionVariationsWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const VariationItems = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0.5rem;
  gap: 0.5rem;
  color: ${p => p.theme.home.textColor};
  margin-right: auto;
`

const Topbar = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  border-bottom: 1px solid ${p => color(p.theme.home.textColor).opacity(0.1).toString()};
`

const AddVariationIconButton = styled(IconButton).attrs({
  iconId: 'add--alt',
  variant: 'transparent',
})``

const AddVariationButton: React.FC<{ nodeId: string }> = ({ nodeId }) => {
  const context = useContext(ReactQuestionVariationsContext)
  const { t } = useTranslation()

  const questionCardPath = useAncestorEntryWithType({ nodeId, type: 'question-card' })?.[1]
  const addVariation = (): void => {
    if (context !== undefined) return context.addVariation()
    if (questionCardPath === undefined) return
  }

  return <AddVariationIconButton tooltip={t('author.slate.question-variation.add')} onClick={addVariation} />
}

const QuestionVariationsCreateWrapper: FCC<{
  element: Entity<QuestionVariations>
}> = ({ element, children }) => {
  const { t } = useTranslation()
  const mode = useEditorMode()
  if (mode !== 'create')
    throw new Error('QuestionVariationsCreateWrapper cannot be rendered outside of create')

  const editor = useSlateStatic()
  const questionsUnstable = element.children.filter(isElementType('question-card'))

  const questions = useDeepEqualityMemo(questionsUnstable)
  const questionIds = useDeepEqualityMemo(questions.map(card => card.id))
  const [index, setIndex] = useState(0)
  const showAllVariations = useIsInPdfExport()

  // Make sure the index is not greater than the number of question ids.
  // This can happen when a question is deleted
  useEffect(() => {
    if (index > questionIds.length - 1) setIndex(questionIds.length - 1)
  }, [index, questionIds])

  const nodeId = element.id
  const value: QuestionVariationsContext = useMemo(
    () => ({
      variationIndex: id => questionIds.indexOf(id),
      currentQuestionId: questionIds[index],
      addVariation: () => {
        const path = findPathToNodeWithId(editor, nodeId)
        if (path === undefined) throw new Error(`Could not find path to node ${nodeId}`)
        const at = path.concat(questionIds.length)
        Transforms.insertNodes(editor, createQuestionCard(), { at })
      },
    }),
    [questionIds, index, editor, nodeId]
  )

  const duplicateQuestion = useCallback(
    (questionId: string) => {
      const question = questions.find(it => it.id === questionId)
      if (question === undefined) return
      const [duplicate] = replaceIdsInNodes([question])
      if (!isElementType('question-card', duplicate)) return

      insertNodeAfterNodeWithId(editor, question.id, duplicate)
    },
    [editor, questions]
  )

  const deleteQuestion = useCallback(
    (questionId: string) => {
      removeNodeWithId(editor, questionId)
    },
    [editor]
  )

  return (
    <ReactQuestionVariationsContext.Provider value={value}>
      {questionIds.length > 1 && (
        <VariationsTopbar contentEditable={false}>
          {showAllVariations === false && (
            <Topbar>
              <VariationItems>
                <View wrap='wrap'>
                  {questionIds.map((id, questionIndex) => (
                    <VariationsButton
                      key={id}
                      onPrimaryClick={() => setIndex(questionIndex)}
                      onDuplicate={() => duplicateQuestion(id)}
                      onDelete={() => deleteQuestion(id)}
                      selected={index === questionIndex}
                    >
                      {`${t('author.slate.question-variation.variation')} ${questionIndex + 1}`}
                    </VariationsButton>
                  ))}
                  <AddVariationButton nodeId={element.id} />
                </View>
              </VariationItems>
              <SettingButtons element={element} />
            </Topbar>
          )}
        </VariationsTopbar>
      )}
      {children}
    </ReactQuestionVariationsContext.Provider>
  )
}

const QuestionVariationsLearnWrapper: FCC<{
  element: Entity<QuestionVariations>
}> = ({ element, children }) => {
  const currentQuestionId = pickQuestionCardFromVariation(element).id

  const value = useMemo(
    (): QuestionVariationsContext => ({ addVariation: () => {}, currentQuestionId, variationIndex: () => 0 }),
    [currentQuestionId]
  )

  return (
    <ReactQuestionVariationsContext.Provider value={value}>
      {children}
    </ReactQuestionVariationsContext.Provider>
  )
}

export const QuestionVariationsWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ element, children, attributes, readOnly, mode, ...props }, ref) => {
    assertElementType('question-variations', element)

    return (
      <QuestionVariationsWrapperInner {...attributes} {...props} ref={ref}>
        <RenderingContext preventDrag={true}>
          {mode === 'create' ? (
            <QuestionVariationsCreateWrapper element={element}>{children}</QuestionVariationsCreateWrapper>
          ) : (
            <QuestionVariationsLearnWrapper element={element}>{children}</QuestionVariationsLearnWrapper>
          )}
        </RenderingContext>
      </QuestionVariationsWrapperInner>
    )
  }
)
