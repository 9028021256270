import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column, HeaderProps } from 'react-table'
import { ContentLabels, ContentRow } from 'sierra-client/components/common/content-elements'
import { IconMenu as DeprecatedIconMenu } from 'sierra-client/components/common/icon-menu'
import { SanaImage } from 'sierra-client/components/common/image'
import { Message } from 'sierra-client/components/common/message'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { getFlag } from 'sierra-client/config/global-config'
import { useLocalizedFormatters, useTimeFormatter } from 'sierra-client/core/format'
import { useContentKindPermissions, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { CourseSettingsModal } from 'sierra-client/views/course-settings/modal'
import {
  ActionButton,
  InfoIcon,
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { ContentGroupsTable } from 'sierra-client/views/manage/components/content-tables'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import {
  DueDateDialog,
  calculateUserDueDateMetadata,
  getDueDateStringForUser,
  useDueDate,
} from 'sierra-client/views/manage/components/due-date'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { ProgressPill } from 'sierra-client/views/manage/components/progress-pill'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import {
  ActionsSection,
  DurationSection,
  LastEditedSection,
  OtherCourseDetailsSection,
  OverviewSection,
  QuickSettingsSection,
} from 'sierra-client/views/manage/courses/components/sections'
import { TeamspaceDropdown } from 'sierra-client/views/manage/courses/components/teamspace-dropdown'
import { UpdateCourseGroupModal } from 'sierra-client/views/manage/courses/course-groups/modals/update-course-group-modal'
import { useManageCourseGroupDetails } from 'sierra-client/views/manage/courses/use-manage-course-group-details'
import { isSelfStarted } from 'sierra-client/views/manage/courses/utils/course-group-utils'
import { useContentGroupAssignmentPanes } from 'sierra-client/views/manage/courses/utils/use-content-group-assignment-panes'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { formatPercentage } from 'sierra-client/views/measure/analytics-v2/util'
import { Separator } from 'sierra-client/views/showcase/common'
import { CourseGroupDetailRow, CourseGroupUserProgressRow, DueDateAbsolute } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { Icon, MenuItem, TruncatedText } from 'sierra-ui/components'
import { RoundAvatar, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components/avatars/round-avatar'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

interface CourseGroupDetailsProps {
  courseGroupId: CourseId
}

type ContentUserCsv = {
  userId: string
  email: string
  firstName: string
  lastName: string
  completion: string
  assignedAt: string
  startedAt: string
  assignedVia: string
  activeEdition: string
}

const parseEnrolledUserToCsv = (user: CourseGroupUserProgressRow): ContentUserCsv => ({
  userId: user.userId,
  email: user.email ?? '',
  firstName: user.firstName ?? '',
  lastName: user.lastName ?? '',
  completion: formatPercentage(user.completionRate),
  assignedAt: user.createdAt?.toISOString() ?? '',
  startedAt: user.startedAt?.toISOString() ?? '',
  assignedVia:
    user.programs.length === 0 ? 'Individual' : _.compact(user.programs.map(g => g.name)).join(', '),
  activeEdition:
    ((user.completionRate ?? 0) !== 0 || !user.assignedViaCourseGroup
      ? user.editionTitleUsedForProgress
      : '') ?? '',
})

type CourseGroupCsv = {
  name: string
  added: string
  learners: number
  progress: string
  duration: string
}

function mapCourseGroupToCsv(
  timeFormatter: (timeInSeconds: number | undefined, remaining?: boolean) => string | undefined
): (cg: CourseGroupDetailRow) => CourseGroupCsv {
  return (cg: CourseGroupDetailRow) => ({
    name: cg.title,
    added: cg.publishedAt?.toISOString() ?? '',
    learners: cg.enrollmentCount,
    progress: formatPercentage(cg.completionRate),
    duration: cg.timeTotal === 0 ? '-' : timeFormatter(cg.timeTotal) ?? '-',
  })
}

type OpenSetDueDates = (userId: UserId, currentDueDate?: DueDateAbsolute, origin?: 'group' | 'user') => void
type OpenUnassignUsers = (userIds: UserId[]) => void

const UserProgressTable: React.FC<{
  usersData: CourseGroupUserProgressRow[]
  isUsersDataLoading: boolean
  openUnassignUsers: OpenUnassignUsers
  openEnrollUsers: () => void
  openSetDueDates: OpenSetDueDates
  fetchCourseGroup: () => Promise<void>
  fetchUsersData: () => Promise<void>
  canEditAssignments: boolean
  courseGroupId: CourseId
}> = ({
  usersData,
  isUsersDataLoading,
  openSetDueDates,
  openEnrollUsers,
  openUnassignUsers,
  fetchCourseGroup,
  fetchUsersData,
  canEditAssignments,
  courseGroupId,
}) => {
  const { t } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()

  const [focusedUserId, setFocusedUserId] = useState<UserId | undefined>(undefined)
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')

  const goToUserDetails = useCallback(async (userId: string) => {
    await getGlobalRouter().navigate({ to: `/manage/users/${userId}` })
  }, [])

  const [userAction, setUserAction] = useState<UserModalActionsProps['action']>({
    modal: undefined,
  })

  // Table
  const usersTableColumns = useMemo<Column<CourseGroupUserProgressRow>[]>(
    () => [
      {
        id: 'select',
        accessor: 'userId',
        Header: p => <SelectableHeader {...p} />,
        Cell: p => <SelectableRow {...p} />,
        width: '5%',
      },
      {
        id: 'firstName',
        Header: p => {
          return (
            <>
              <SortableHeader label={t('table.name')} smallLabel {...p} />{' '}
            </>
          )
        },
        Cell: (p: CellProps<CourseGroupUserProgressRow>) => (
          <View alignItems='center'>
            <RoundAvatar
              {...getAvatarPropsFromBaseUserInfo({
                firstName: p.row.original.firstName,
                lastName: p.row.original.lastName,
                avatar: p.row.original.avatar,
                avatarColor: p.row.original.avatarColor,
              })}
              src={getAvatarImage(p.row.original.userId, p.row.original.avatar)}
              size='small'
            />
            <Spacer />
            <Text size='small' bold>
              {[p.row.original.firstName, p.row.original.lastName].join(' ')}
            </Text>
          </View>
        ),
        width: '30%',
      },
      {
        id: 'progress',
        Header: (p: HeaderProps<CourseGroupUserProgressRow>) => (
          <>
            <SortableHeader label={t('admin.analytics.table.progress')} smallLabel {...p} />
            <InfoIcon title={t('manage.groups.tooltip.group-completion')} />
          </>
        ),
        Cell: ({ row }: CellProps<CourseGroupUserProgressRow>) => (
          <ProgressPill progress={row.original.completionRate} homeworks={row.original.homeworks} />
        ),
        width: '15%',
      },
      {
        id: 'edition',
        Header: (p: HeaderProps<CourseGroupUserProgressRow>) => (
          <>
            <SortableHeader label={t('manage.course.groups.active.edition')} smallLabel {...p} />
          </>
        ),
        Cell: ({ row }: CellProps<CourseGroupUserProgressRow>) => (
          <>
            {(row.original.completionRate ?? 0) !== 0 || !(row.original.assignedViaCourseGroup === true)
              ? row.original.editionTitleUsedForProgress
              : ''}
          </>
        ),
        width: '15%',
      },
      {
        Header: t('table.last-activity'),
        accessor: original => (original.lastActivity ? formatTimeAgo(original.lastActivity) : ''),
        width: '15%',
      },
      {
        Header: t('table.assigned-via'),
        Cell: ({ row }: CellProps<CourseGroupUserProgressRow>) => {
          return (
            <TruncatedText lines={1} size='small' color='grey50'>
              {row.original.programs.length === 0
                ? isSelfStarted(row.original)
                  ? t('manage.users.self-started')
                  : t('manage.users.assignment-type.individual')
                : _.compact(row.original.programs.map(g => g.name)).join(', ')}
            </TruncatedText>
          )
        },
        width: '15%',
      },
      {
        id: 'dueDate',
        Header: t('due-date.due-date'),
        Cell: ({ row }: CellProps<CourseGroupUserProgressRow>) => (
          <>
            {getDueDateStringForUser({
              t,
              dueDateDirect: row.original.dueDateUser,
              dueDateGroup: row.original.dueDateGroup,
            }) ?? null}
          </>
        ),
        width: '15%',
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({ row }: CellProps<CourseGroupUserProgressRow>) => {
          const shouldShowDetails =
            (row.original.completionRate ?? 0) !== 0 || !(row.original.assignedViaCourseGroup === true)

          const [open, setOpen] = useState(false)

          useEffect(() => {
            if (open) {
              setFocusedUserId(row.original.userId)
            } else {
              setFocusedUserId(undefined)
            }
          }, [open, row.original.userId])

          const items: MenuItem[] = [
            {
              id: 'view-user',
              type: 'label',
              label: t('manage.view-details'),
              onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${row.original.userId}` }),
              icon: 'user',
            },
            {
              id: 'view-active-edition',
              type: 'label',
              label: t('manage.course.groups.view.active.edition'),
              hidden: !shouldShowDetails,
              onClick: () => {
                void getGlobalRouter().navigate({
                  to: `/manage/courses/${row.original.editionIdUsedForProgress}`,
                })
              },
              icon: 'information',
            },
            {
              id: 'course-group-due-date',
              type: 'label',
              label: t('due-date.set-due-date'),
              hidden: !canSetContentDueDates,
              onClick: () => {
                const { dueDate, origin } = calculateUserDueDateMetadata({
                  dueDateDirect: row.original.dueDateUser,
                  dueDateGroup: row.original.dueDateGroup,
                })

                openSetDueDates(row.original.userId, dueDate, origin)
              },
              icon: 'time',
            },
            {
              type: 'label',
              label: t('manage.complete-progress'),
              id: 'complete-content',
              onClick: () =>
                setUserAction({
                  modal: 'complete-content',
                  userId: row.original.userId,
                  contentType: 'course',
                  contentId: courseGroupId,
                }),
              icon: 'checkmark--outline',
              disabled: row.original.completionRate === 1,
              hidden: !canSetContentCompletion,
            },
            {
              id: 'reset-content',
              type: 'label',
              label: t('manage.reset-progress'),
              onClick: () =>
                setUserAction({
                  modal: 'reset-course',
                  contentType: 'course-group',
                  contentId: courseGroupId,
                }),
              icon: 'reset',
              disabled: row.original.completionRate === 0 || row.original.completionRate === undefined,
              hidden: !canResetProgress,
              color: 'destructive/background',
            },
            {
              id: 'unassign',
              type: 'label',
              color: 'destructive/background',
              label: t('dictionary.unassign'),
              hidden: !canEditAssignments,
              disabled: row.original.unassignable,
              onClick: () => openUnassignUsers([row.original.userId]),
              icon: 'user--remove',
            },
          ]

          if (items.every(item => item.hidden === true)) {
            return null
          }

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                aria-label='Details'
                variant='transparent'
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={items}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
        width: '10%',
      },
    ],
    [
      t,
      formatTimeAgo,
      openSetDueDates,
      openUnassignUsers,
      canEditAssignments,
      canResetProgress,
      canSetContentCompletion,
      canSetContentDueDates,
      courseGroupId,
    ]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: usersData, columns: usersTableColumns },
    getEntityId: (c: CourseGroupUserProgressRow) => c.userId,
  })

  const selectedIds = useMemo(
    () => tableInstance.selectedFlatRows.map(row => row.original.userId),
    [tableInstance.selectedFlatRows]
  )

  return (
    <>
      <SmallTableWrapper title={t('admin.organization.tab.users')}>
        <ManageTableSmall
          tableInstance={tableInstance}
          isLoading={isUsersDataLoading}
          onViewDetails={goToUserDetails}
          focusedId={focusedUserId}
          getEntityId={c => c.userId}
          mapEntityToCsv={parseEnrolledUserToCsv}
          footerButton={
            canEditAssignments ? (
              <Button onClick={openEnrollUsers}>{t('manage.manage-learners')}</Button>
            ) : undefined
          }
          translations={{
            searchPlaceholder: t('manage.search.users'),
            tableLoading: t('manage.users.table-loading'),
            tableNoResults: t('manage.users.no-results'),
            csvPrefix: t('admin.analytics.users'),
          }}
          bulkActions={
            canEditAssignments ? (
              <>
                <ActionButton color='redBright' onClick={() => openUnassignUsers(selectedIds)}>
                  {t('dictionary.unassign')}
                </ActionButton>
              </>
            ) : undefined
          }
        />
      </SmallTableWrapper>
      {focusedUserId !== undefined && (
        <UserModalActions
          targetUserId={focusedUserId}
          action={userAction}
          onClose={() => setUserAction({ modal: undefined })}
          onDone={() => {
            setUserAction({ modal: undefined })
            void [fetchCourseGroup(), fetchUsersData()]
          }}
        />
      )}
    </>
  )
}

type Action =
  | {
      modal: 'delete'
    }
  | {
      modal: 'unassign-users'
      userIds: UserId[]
    }
  | {
      modal: 'enroll-to-group' | 'enroll-to-edition'
      courseId: string
    }
  | {
      modal: 'detach-edition'
      courseEditionId: string
    }
  | {
      modal: 'update-course-group'
    }
  | {
      modal: 'set-due-dates'
      currentDueDate?: DueDateAbsolute
      origin?: 'user' | 'group'
      userId: UserId
    }

export const ManageCourseGroupDetails: React.FC<CourseGroupDetailsProps> = ({ courseGroupId }) => {
  const { t, dynamicT } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()
  const dispatch = useDispatch()
  const notifications = useNotif()
  const { timeFormatter } = useTimeFormatter()
  const assignmentPanes = useContentGroupAssignmentPanes()
  const { setUsersDueDate, assignWithDueDate } = useDueDate()
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)
  const allowCourseGroupInTeamspace = getFlag('teamspaces/course-groups')
  const newCourseSettingsModalEnabled = getFlag('new-course-settings-modal')

  const {
    fetchCourseGroup,
    fetchUsersData,
    courseEditionsData,
    courseGroupData,
    courseGroupProgramAssignments,
    isEditionsLoading,
    usersData,
    isUsersDataLoading,
    deleteCourseGroup,
    saveVisibility,
    detachEdition,
    unassignUsers,
  } = useManageCourseGroupDetails(courseGroupId)

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  const goToCourseDetails = useCallback(async (courseId: string) => {
    await getGlobalRouter().navigate({ to: `/manage/courses/${courseId}` })
  }, [])

  const [action, setAction] = useState<Action | undefined>()

  const detachCourseEdition = useCallback(
    async (editionId: string) => {
      if (courseEditionsData.length <= 2) {
        await deleteCourseGroup() // Remove all editions and delete group
        void getGlobalRouter().navigate({ to: '/manage/content' }) // Go back to content
      } else {
        await detachEdition(editionId)
        await fetchCourseGroup() // refresh
      }
    },
    [courseEditionsData, detachEdition, deleteCourseGroup, fetchCourseGroup]
  )

  // Table
  const courseEditionsColumns = useMemo<Column<CourseGroupDetailRow>[]>(
    () => [
      {
        Header: p => (
          <>
            <SortableHeader label={t('table.name')} {...p} />
          </>
        ),
        accessor: 'title',
        Cell: (p: CellProps<CourseGroupDetailRow>) => (
          <View direction='column'>
            <ContentRow
              {...p.row.original}
              assetContext={{ type: 'course', courseId: CourseId.parse(p.row.original.courseId) }}
              contentType='course'
              isCourseEdition={true}
              href={getHrefForContentDetails({
                contentType: 'course',
                courseKind: p.row.original.kind,
                id: p.row.original.courseId,
              })}
              options={{ hidePill: true }}
              language={
                p.row.original.language !== undefined
                  ? dynamicT(`language.${p.row.original.language}`)
                  : undefined
              }
            />
          </View>
        ),
        width: '30%',
      },
      {
        id: 'createdAt',
        Header: p => <SortableHeader label={t('table.added')} {...p} />,
        sortType: 'alphanumeric',
        Cell: (p: CellProps<CourseGroupDetailRow>) => (
          <>{p.row.original.publishedAt !== undefined ? formatTimeAgo(p.row.original.publishedAt) : null}</>
        ),
        width: '15%',
      },
      {
        Header: p => <SortableHeader label={t('table.learners')} {...p} />,
        accessor: 'assignedOrStartedUsersCount',
        width: '15%',
      },
      {
        id: 'progress',
        Header: (p: HeaderProps<CourseGroupDetailRow>) => (
          <>
            <SortableHeader label={t('admin.analytics.table.progress')} smallLabel {...p} />
            <InfoIcon title={t('manage.groups.tooltip.group-completion')} />
          </>
        ),
        Cell: ({ row }: CellProps<CourseGroupDetailRow>) => (
          <>{formatPercentage(row.original.completionRate)}</>
        ),
        width: '15%',
      },
      {
        Header: p => <SortableHeader label={t('table.duration')} {...p} />,
        accessor: 'timeTotal',
        Cell: ({ row }) => {
          const { timeTotal } = row.original
          return <>{timeTotal === 0 ? '-' : timeFormatter(timeTotal)}</>
        },
        width: '15%',
      },
      {
        id: 'actions',
        width: '10%',
        Header: '',
        Cell: (p: CellProps<CourseGroupDetailRow>) => {
          const hidden = p.row.original.isDefault && courseEditionsData.length >= 3

          const items: MenuItem[] = [
            {
              type: 'label',
              id: 'view-details',
              label: t('manage.view-details'),
              onClick: () => {
                void getGlobalRouter().navigate({
                  to: getHrefForContentDetails({
                    contentType: 'course',
                    courseKind: p.row.original.kind,
                    id: p.row.original.courseId,
                  }),
                })
              },
            },
            {
              type: 'label',
              id: 'assign-learner',
              label: t('manage.course-groups.assignments.assign-learners'),
              hidden: !courseGroupPermissions.has('EDIT_ASSIGNMENTS'),
              onClick: () => setAction({ modal: 'enroll-to-edition', courseId: p.row.original.courseId }),
            },
          ]

          if (courseGroupData?.canEdit === true) {
            items.push({
              type: 'canvas',
              id: 'detach-edition',
              render: ({ onItemClick }) => {
                return (
                  <View
                    grow
                    justifyContent='space-between'
                    cursor={!hidden ? 'pointer' : 'default'}
                    onClick={e => {
                      e.stopPropagation()
                      onItemClick?.()

                      if (hidden) {
                        return
                      }

                      setAction({ modal: 'detach-edition', courseEditionId: p.row.original.courseId })
                    }}
                  >
                    <Text color={!hidden ? 'black' : 'grey25'} size='small'>
                      {t('manage.course-groups.detach-edition.title')}
                    </Text>
                    {hidden && <InfoIcon title={t('manage.course-groups.detach-edition.info')} />}
                  </View>
                )
              },
            })
          }

          return (
            <View justifyContent='flex-end' paddingRight='small' onClick={e => e.stopPropagation()}>
              <DeprecatedIconMenu
                iconId='overflow-menu--horizontal'
                items={items}
                tooltip={t('dictionary.details')}
              />
            </View>
          )
        },
      },
    ],
    [
      t,
      dynamicT,
      timeFormatter,
      formatTimeAgo,
      courseEditionsData.length,
      courseGroupData?.canEdit,
      courseGroupPermissions,
    ]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: courseEditionsData, columns: courseEditionsColumns },
    getEntityId: c => c.courseId,
  })

  const openSetDueDates = useCallback<OpenSetDueDates>(
    (userId, currentDueDate, origin) =>
      setAction({
        modal: 'set-due-dates',
        userId,
        currentDueDate,
        origin,
      }),
    []
  )

  const openUnassignUsers = useCallback<OpenUnassignUsers>(
    userIds =>
      setAction({
        modal: 'unassign-users',
        userIds,
      }),
    []
  )

  const imageSrc = useResolveAsset({
    image: courseGroupData?.image,
    assetContext: { type: 'course', courseId: CourseId.parse(courseGroupId) },
    size: 'default',
  })

  if (courseGroupData === undefined || isEditionsLoading || isUsersDataLoading) return null

  return (
    <>
      <PageTitle title={courseGroupData.title} />

      <ColumnLayout>
        <DetailsHeader
          titleAddon={
            <ContentLabels contentType='course' courseKind={courseGroupData.kind} isCourseEdition={false} />
          }
          backlink={{
            href: '/manage/content',
            label: 'manage.backlinks--content',
          }}
        />

        <ManageDetailContainer>
          <ManageDetailGrid>
            <ManageDetailSidebar>
              <SanaImage src={imageSrc} ratio='16:9' rounded />
              <Spacer size='small' />
              <Heading size='h4' bold>
                {courseGroupData.title}
              </Heading>
              <Spacer size='xxsmall' />
              <Spacer size='xxsmall' />
              <DurationSection timeTotal={courseGroupData.timeTotal} />
              <Spacer size='xxsmall' />
              <LastEditedSection
                publishedAt={courseGroupData.publishedAt}
                lastEditedAt={courseGroupData.lastEditedAt}
              />
              <Spacer size='xsmall' />
              <ActionsSection
                courseId={courseGroupId}
                courseKind={courseGroupData.kind}
                canEdit={courseGroupData.canEdit}
                canEditMetadata={courseGroupPermissions.has('EDIT_METADATA')}
                canDelete={courseGroupPermissions.has('DELETE')}
                onClickDelete={() => setAction({ modal: 'delete' })}
              />

              <QuickSettingsSection
                courseId={courseGroupId}
                courseKind={courseGroupData.kind}
                isVisible={courseGroupData.isVisible}
                saveVisibility={saveVisibility}
              />

              <OtherCourseDetailsSection
                description={courseGroupData.description}
                tags={courseGroupData.tags}
                includedInPaths={courseGroupData.includedInPaths}
              />
              <Separator />
              {allowCourseGroupInTeamspace && (
                <TeamspaceDropdown courseId={courseGroupData.courseId} courseType={courseGroupData.kind} />
              )}
            </ManageDetailSidebar>
            <ManageDetailContent>
              <View direction='column' gap='none'>
                <OverviewSection
                  courseKind={courseGroupData.kind}
                  completionRate={courseGroupData.completionRate}
                  rating={courseGroupData.rating}
                  ratingCount={courseGroupData.ratingCount}
                  directAssignmentsCount={courseGroupData.enrollmentCount}
                  selfStartedUsersCount={
                    courseGroupData.assignedOrStartedUsersCount - courseGroupData.enrollmentCount
                  }
                />
                <Spacer size='xxlarge' />
                <UserProgressTable
                  usersData={usersData}
                  isUsersDataLoading={isUsersDataLoading}
                  openSetDueDates={openSetDueDates}
                  openEnrollUsers={() => setAction({ modal: 'enroll-to-group', courseId: courseGroupId })}
                  openUnassignUsers={openUnassignUsers}
                  fetchCourseGroup={fetchCourseGroup}
                  fetchUsersData={fetchUsersData}
                  canEditAssignments={courseGroupPermissions.has('EDIT_ASSIGNMENTS')}
                  courseGroupId={courseGroupId}
                />
                <Spacer size='xxlarge' />
                <SmallTableWrapper title={t('dictionary.edition-plural')}>
                  <ManageTableSmall
                    tableInstance={tableInstance}
                    isLoading={isEditionsLoading}
                    onViewDetails={goToCourseDetails}
                    getEntityId={c => c.courseId}
                    mapEntityToCsv={mapCourseGroupToCsv(timeFormatter)}
                    translations={{
                      searchPlaceholder: t('manage.search.groups'),
                      tableLoading: t('manage.groups.table-loading'),
                      tableNoResults: t('manage.groups.no-results'),
                      csvPrefix: t('admin.analytics.groups'),
                    }}
                    footerButton={
                      courseGroupData.canEdit === true ? (
                        <View marginBottom='32'>
                          <Button
                            variant='secondary'
                            onClick={() => setAction({ modal: 'update-course-group' })}
                          >
                            {t('manage.course-groups.update-course-group.manage-editions')}
                          </Button>
                        </View>
                      ) : undefined
                    }
                    warningMessage={
                      courseGroupData.privateEditionsCount > 0 ? (
                        <Message padding='small'>
                          <View justifyContent='flex-start' gap='xxsmall'>
                            <Icon iconId='information' color='foreground/primary' />
                            <Text size='small' bold color='foreground/secondary'>
                              {t('manage.course-groups.update-course-group.private-editions-heading')}
                            </Text>
                          </View>
                          <Spacer size='xxsmall' />
                          <Text size='small' color='foreground/muted'>
                            {t('manage.course-groups.update-course-group.private-editions-information.text', {
                              count: courseGroupData.privateEditionsCount,
                            })}
                          </Text>
                        </Message>
                      ) : undefined
                    }
                  />
                </SmallTableWrapper>
                <Spacer size='small' />
                {courseGroupProgramAssignments.length > 0 && (
                  <>
                    <ContentGroupsTable assignedPrograms={courseGroupProgramAssignments} />
                    <Spacer size='xxlarge' />
                  </>
                )}
              </View>
            </ManageDetailContent>
          </ManageDetailGrid>
          {(action?.modal === 'enroll-to-group' || action?.modal === 'enroll-to-edition') && (
            <AssignModal
              isOpen
              subjectType='course'
              subjects={action.courseId}
              subjectsSupportAssignmentSettings={false}
              autoAssignmentAvailable={false}
              panes={assignmentPanes}
              showDueDates={assignmentPanes}
              title={
                action.modal === 'enroll-to-edition'
                  ? t('manage.course-groups.assignments.assign-to-edition')
                  : t('manage.course-groups.assignments.assign-to-group')
              }
              activePane={'user'}
              onClose={() => {
                setAction(undefined)
              }}
              onSave={async selections => {
                const result = await assignWithDueDate(
                  parseModalToContentAssignment([action.courseId], 'course', selections)
                )
                if (result?.error !== undefined) return

                notifications.push({ type: 'assigned' })
                setAction(undefined)
                void Promise.all([fetchCourseGroup(), fetchUsersData()])
              }}
            />
          )}
          {action?.modal === 'unassign-users' && (
            <ActionModal
              open
              onClose={() => setAction(undefined)}
              primaryAction={async (): Promise<void> => {
                await unassignUsers(action.userIds)
                setAction(undefined)
                notifications.push({
                  type: 'custom',
                  level: 'success',
                  body: t('notifications.done'),
                })
              }}
              primaryActionLabel={t('dictionary.unassign')}
              title={t('manage.unassign-users.title', { count: action.userIds.length })}
              deleteAction
            />
          )}
          <ActionModal
            title={t('course-editions.create.delete.title')}
            open={action?.modal === 'delete'}
            onClose={() => setAction(undefined)}
            deleteAction
            primaryActionLabel={t('course-editions.create.delete.title')}
            primaryAction={async () => {
              await deleteCourseGroup()
              setAction(undefined)
            }}
          >
            {t('manage.course-groups.delete-group.confirm-body')}
          </ActionModal>
          <ActionModal
            title={t('manage.course-groups.detach-edition.title')}
            open={action?.modal === 'detach-edition'}
            onClose={() => setAction(undefined)}
            primaryActionLabel={t('manage.course-groups.detach-edition.confirm-action')}
            primaryAction={async () => {
              const editionId = action?.modal === 'detach-edition' ? action.courseEditionId : undefined

              if (editionId === undefined) return

              await detachCourseEdition(editionId)
            }}
          >
            {t('manage.course-groups.detach-edition.confirm-body')}
          </ActionModal>
          {newCourseSettingsModalEnabled ? (
            <NewCourseSettingsModal onSave={fetchCourseGroup} />
          ) : (
            <CourseSettingsModal onSave={fetchCourseGroup} />
          )}
        </ManageDetailContainer>
        <DueDateDialog
          open={action?.modal === 'set-due-dates'}
          onClose={() => setAction(undefined)}
          value={action?.modal === 'set-due-dates' ? action.currentDueDate : undefined}
          type='user'
          forceDisableRemove={action?.modal === 'set-due-dates' ? action.origin !== 'user' : undefined}
          onChange={async value => {
            if (action?.modal !== 'set-due-dates' || value?.type === 'relative') return

            // Set due date on both the course group and all editions
            const courseGroupAssignment = {
              dueDate: value?.date,
              content: { type: 'course' as const, id: courseGroupId },
            }

            const assignments = courseEditionsData.map(edition => ({
              content: { id: edition.courseId, type: 'course' as const },
              dueDate: value?.date,
            }))

            await setUsersDueDate([action.userId], [...assignments, courseGroupAssignment])

            await Promise.all([fetchCourseGroup(), fetchUsersData()])

            setAction(undefined)
          }}
        />
        <UpdateCourseGroupModal
          courseGroupId={courseGroupId}
          open={action?.modal === 'update-course-group'}
          onClose={() => setAction(undefined)}
          onSave={fetchCourseGroup}
        />
      </ColumnLayout>
    </>
  )
}
