import React, {
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { resolveUrlImage, simplifyUrlImage } from 'sierra-client/api/content'
import { createDataURlFromThumbhashBase64, thumbhashAverageColor } from 'sierra-client/utils/image-thumbhash'
import { UnsplashAttribution } from 'sierra-client/views/author/components/block-editor/unsplash-attribution'
import { useSmartImageUnionLoader } from 'sierra-client/views/flexible-content/card-background'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { VerticalWrapper } from 'sierra-client/views/v3-author/sections/stacks'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, iife } from 'sierra-domain/utils'
import { Image as ImageBlock } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StableAspectRatioContainer = styled.div<{
  $showBackground: boolean
}>`
  position: relative;
  overflow: hidden;
  background-color: ${p => (p.$showBackground ? token('surface/soft') : undefined)};
`

const ActualImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

const AttributionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  opacity: 0;
  transition: 0.1s opacity ease-out;
`

const ImageWrapperContainer = styled.div.attrs({ contentEditable: false })`
  text-align: center;
  position: relative;

  &:hover {
    ${AttributionWrapper} {
      opacity: 1;
    }
  }

  ${StableAspectRatioContainer} {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
  }
`

const NoGridImgContainer = styled.div.attrs({ contentEditable: false })`
  position: relative;

  &:hover {
    ${AttributionWrapper} {
      opacity: 1;
    }
  }

  ${StableAspectRatioContainer} {
    max-width: 100%;
    display: block;
    border-radius: 8px;

    ${VerticalWrapper} & {
      max-height: 100%;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const ResizeHandle = styled.div`
  cursor: ew-resize;
  position: absolute;
  width: 32px;
  height: 100%;
  top: 0;
  right: 0;

  &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 80px;
    max-height: 75%;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto 0;
    border-radius: 3px;
    outline: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    opacity: 0;
    transition: 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:hover::before {
    opacity: 1;
  }
`

type ImageWrapperProps = {
  courseId: string
  readOnly: boolean
  block: ImageBlock
  creditInput: ReactNode
  altText: string | undefined
  altTextInput: ReactNode
  hotspotLayer: ReactNode
  onResizeStart: (event: React.MouseEvent<unknown, MouseEvent>) => void
  customSize: number | undefined
  assetContext: AssetContext

  //
  isEditingStyle: boolean
  onImageStyleEditStart: () => void
  onImageStyleDragStart: (event: React.MouseEvent<unknown, MouseEvent>) => void
  customStyle: ImageBlock['customStyle']
}

const getImageDimentions = async (url: string): Promise<{ width: number; height: number }> => {
  const image = new Image()
  image.src = url
  await image.decode()
  return { width: image.width, height: image.height }
}

const StableAspectRatioImg = forwardRef<
  HTMLImageElement,
  ImageWrapperProps & { src: string | undefined; aspectRatio: string; customStyle: ImageBlock['customStyle'] }
>(
  (
    { customSize, block, src, altText, aspectRatio, customStyle, isEditingStyle, onImageStyleDragStart },
    imageRef
  ) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    const imageThumbhash = iife(() => {
      switch (block.image?.type) {
        case 'file':
        case 'media-library-image':
        case 'unsplash':
          return block.image.thumbHashBase64
        case 'url':
          return undefined
      }
    })

    const containerStyle = useMemo(() => {
      const style: React.CSSProperties =
        customSize !== undefined
          ? { width: customSize }
          : { width: block.variant === 'full-width' ? '100%' : undefined }

      style.aspectRatio = aspectRatio

      style.backgroundColor = iife(() => {
        switch (block.background) {
          case 'dominant-color':
            return imageThumbhash !== undefined ? color(thumbhashAverageColor(imageThumbhash)) : undefined
          case 'semi-transparent':
            return imageThumbhash !== undefined
              ? color(thumbhashAverageColor(imageThumbhash)).opacity(0.3)
              : undefined
          case 'transparent':
          case undefined:
            return 'transparent'
          default:
            assertNever(block.background)
        }
      })

      return style
    }, [aspectRatio, block.background, block.variant, customSize, imageThumbhash])

    const imageStyle = useMemo(() => {
      const style: React.CSSProperties =
        block.image?.type === 'unsplash'
          ? {
              objectFit: 'cover',
              objectPosition: 'center',
            }
          : {}

      if (!imageLoaded && imageThumbhash !== undefined) {
        style.background = `center / cover url(${createDataURlFromThumbhashBase64(imageThumbhash)})`
      }

      if (customStyle !== undefined) {
        style.transform = `translate(${customStyle.translateX}%, ${customStyle.translateY}%) scale(${customStyle.scale})`
      }

      if (isEditingStyle) {
        style.cursor = 'grab'
      }

      if (isDragging) {
        style.cursor = 'grabbing'
      }

      return style
    }, [block.image?.type, customStyle, imageLoaded, imageThumbhash, isDragging, isEditingStyle])

    const onImageLoaded = useCallback(() => setImageLoaded(true), [setImageLoaded])

    const onImagePostionChange: MouseEventHandler<HTMLImageElement> | undefined = isEditingStyle
      ? evt => {
          evt.preventDefault()
          onImageStyleDragStart(evt)
          setIsDragging(true)

          const onDragEnd = (): void => {
            setIsDragging(false)
            window.removeEventListener('mouseup', onDragEnd)
          }

          window.addEventListener('mouseup', onDragEnd)
        }
      : undefined

    return (
      <StableAspectRatioContainer $showBackground={!imageLoaded} style={containerStyle} ref={imageRef}>
        <ActualImage
          onMouseDown={onImagePostionChange}
          onLoad={onImageLoaded}
          style={imageStyle}
          src={src}
          alt={altText}
          loading='lazy'
        />
      </StableAspectRatioContainer>
    )
  }
)

export const ImageWrapper = React.forwardRef<HTMLImageElement, ImageWrapperProps>((props, imageRef) => {
  const {
    readOnly,
    block,
    creditInput,
    hotspotLayer,
    altTextInput,
    onResizeStart,
    assetContext,
    onImageStyleEditStart,
    isEditingStyle,
  } = props
  const { image: unresolvedImage, variant } = block
  const [aspectRatio, setAspectRatio] = useState<string | undefined>(() => {
    if (block.image?.type === 'unsplash') return '1 / 1'
    if (block.image?.type === 'file' || block.image?.type === 'media-library-image') {
      const width = block.image.width
      const height = block.image.height
      if (width !== undefined && height !== undefined) return `${width} / ${height}`
    }
    return undefined
  })

  const image = resolveUrlImage(simplifyUrlImage(unresolvedImage))

  const options: { width?: number } = useMemo(() => {
    switch (variant) {
      case 'wide':
        return { width: 1600 }
      case 'narrow':
        return { width: 1600 }
      case 'center':
        return { width: 1024 }
      default:
        return {}
    }
  }, [variant])

  const src = useSmartImageUnionLoader(assetContext, image, options)

  useEffect(() => {
    if (aspectRatio === undefined && src !== undefined) {
      void getImageDimentions(src).then(({ width, height }) => {
        setAspectRatio(current => current ?? `${width} / ${height}`)
      })
    }
  }, [aspectRatio, src])

  const sourceAuthorName = image?.type === 'unsplash' ? image.user.name : undefined
  const sourceAuthorUsername = image?.type === 'unsplash' ? image.user.username : undefined

  const attributionElement =
    sourceAuthorName !== undefined && sourceAuthorUsername !== undefined ? (
      <AttributionWrapper>
        <UnsplashAttribution name={sourceAuthorName} userName={sourceAuthorUsername} />
      </AttributionWrapper>
    ) : null

  const resizeElement = readOnly ? undefined : (
    <ResizeHandle
      contentEditable={false}
      onMouseDown={event => {
        onResizeStart(event)
      }}
    />
  )

  const notGrid = !useRenderingContext().withGrid

  const onDoubleClick = !isEditingStyle && !readOnly ? onImageStyleEditStart : undefined

  if (notGrid)
    return (
      <>
        <NoGridImgContainer onDoubleClick={onDoubleClick}>
          <StableAspectRatioImg {...props} ref={imageRef} src={src} aspectRatio={aspectRatio ?? '1/1'} />
          {hotspotLayer}
          {attributionElement}
          {resizeElement}
        </NoGridImgContainer>
        <div contentEditable={false}>
          {/* Empty span to satisfy slate in case credit and alt text are not set */}
          <span />
          {creditInput}
          {altTextInput}
        </div>
      </>
    )

  return (
    <ImageWrapperContainer>
      <ImageContainer onDoubleClick={onDoubleClick}>
        <StableAspectRatioImg {...props} ref={imageRef} src={src} aspectRatio={aspectRatio ?? '1/1'} />
        {hotspotLayer}
        {attributionElement}
        {resizeElement}
      </ImageContainer>

      {creditInput}
      {altTextInput}
    </ImageWrapperContainer>
  )
})
