import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useIsFurtherAuthenticationRequired } from 'sierra-client/config/use-is-further-authentication-required'
import { usePost } from 'sierra-client/hooks/use-post'
import { useRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { XRealtimeContentQueryCourse } from 'sierra-domain/routes'

import { FCC } from 'sierra-client/types'
import { AssessmentsManager } from 'sierra-client/views/flexible-content/assessments-manager'
import { SelfPacedFilesProvider } from 'sierra-client/views/self-paced/files-provider'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentRecord } from 'sierra-domain/collaboration/serialization'
import { isDefined } from 'sierra-domain/utils'

export type SelfPacedContentRecord = FlexibleContentRecord

const ReactSelfPacedBigContext = React.createContext<SelfPacedContentRecord | undefined>(undefined)

export const useSelfPacedBigContext = (): SelfPacedContentRecord => {
  const context = useContext(ReactSelfPacedBigContext)
  if (context === undefined)
    throw new Error('SelfPacedContext was undefined. This hook can only be used on the self-paced page')

  return context
}

export const useSelfPacedBigContextSafe = (): SelfPacedContentRecord | undefined => {
  return useContext(ReactSelfPacedBigContext)
}

function useSelfPacedContentRecord(
  courseId: SelfPacedContentId | undefined
): SelfPacedContentRecord | undefined {
  const [contextMap, setContextMap] = useState<Record<SelfPacedContentId, FlexibleContentRecord>>({})
  const { postWithUserErrorException } = usePost()

  useEffect(() => {
    if (!isDefined(courseId)) return

    let isCancelled = false
    void postWithUserErrorException(XRealtimeContentQueryCourse, { courseId }).then(result => {
      if (result.type === 'native:self-paced' && !isCancelled) {
        setContextMap({ [courseId]: result.record })
      }
    })

    return () => {
      isCancelled = true
      setContextMap(prev => _.omit(prev, courseId))
    }
  }, [courseId, postWithUserErrorException])

  return isDefined(courseId) ? contextMap[courseId] : undefined
}

export const SelfPacedUniversalContext: FCC = ({ children }) => {
  const ids = useRouterSelfPacedIds()
  const isAuthenticated = !useIsFurtherAuthenticationRequired()

  const flexibleContentId = isAuthenticated ? ids?.flexibleContentId : undefined
  const context = useSelfPacedContentRecord(flexibleContentId)

  return (
    <ReactSelfPacedBigContext.Provider value={context}>
      <SelfPacedFilesProvider>
        <AssessmentsManager>{children}</AssessmentsManager>
      </SelfPacedFilesProvider>
    </ReactSelfPacedBigContext.Provider>
  )
}
