import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deepPatchJson } from '@sanalabs/json'
import {
  collaboratorsUpdated,
  fetchLiveSessionsForFlexibleContent,
} from 'sierra-client/state/flexible-content/actions'
import { getFolder } from 'sierra-client/state/flexible-content/helpers'
import { FlexibleContentState, MoveNodeActionPayload } from 'sierra-client/state/flexible-content/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentJsonData } from 'sierra-domain/flexible-content/types'

const initialState: FlexibleContentState = {
  flexibleContent: {},
  folderLiveSessions: {},
  collaborators: {},
}

const getCreateContent = (
  state: FlexibleContentState,
  createContentId: CreateContentId
): FlexibleContentJsonData => {
  const flexibleContent = state.flexibleContent[createContentId]
  if (flexibleContent !== undefined) return flexibleContent

  const newFlexibleContent: FlexibleContentJsonData = {
    nodeMap: {},
  }
  state.flexibleContent[createContentId] = newFlexibleContent
  return newFlexibleContent
}

export const flexibleContentSlice = createSlice({
  name: 'flexibleContent',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchLiveSessionsForFlexibleContent.fulfilled, (state, action) => {
      const {
        payload: { liveContentId, liveSessions },
      } = action

      state.folderLiveSessions[liveContentId] = liveSessions
    })
    builder.addCase(collaboratorsUpdated, (state, { payload: { collaborators, createContentId } }) => {
      state.collaborators[createContentId] = collaborators
    })
  },
  reducers: {
    setData(
      state,
      {
        payload: { createContentId, data },
      }: PayloadAction<{ createContentId: CreateContentId; data: FlexibleContentJsonData }>
    ) {
      deepPatchJson(getCreateContent(state, createContentId), data)
    },

    moveNode(state, { payload }: PayloadAction<MoveNodeActionPayload>) {
      const { createContentId, parentFolderId, destinationId, nodeId, nextTo } = payload
      const sourceFolder = getFolder({ state, createContentId, folderId: parentFolderId })
      const destinationFolder = getFolder({ state, createContentId, folderId: destinationId })

      // Remove it from the source folder
      sourceFolder.nodeIds = sourceFolder.nodeIds.filter(n => n !== nodeId)

      // Add it to the destination folder
      const insertAtIndex =
        nextTo === undefined ? 0 : destinationFolder.nodeIds.indexOf(nextTo.nodeId) + (nextTo.after ? 1 : 0)
      destinationFolder.nodeIds.splice(insertAtIndex, 0, nodeId)
    },
  },
})
