import { createFileRoute } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { invalidateGlobalSidebarTeamspaceCache } from 'sierra-client/api/hooks/queries-invalidation'
import {
  getRootFolderOfTeamspace,
  prefetchTeamspaceById,
  useJoinTeamspace,
  useLeaveTeamspace,
  useTeamspaceById,
} from 'sierra-client/api/hooks/use-teamspace'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { TemplatePickerProvider } from 'sierra-client/components/templates'
import { getFlag } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import {
  CreateNewButtonWithOptions,
  EditTeamSpaceModal,
  GridContainer,
  NoContentContainer,
  PageHeader,
  PlainCreateNewButton,
  TeamspaceAccess,
  TeamspaceIcon,
  isTeamspaceRoleAbove,
  useCheckTeamspaceAccess,
  useTeamspaceAccessRedirect,
  useTeamspaceContentDrop,
} from 'sierra-client/features/teamspace'
import { teamspaceViewedLogger } from 'sierra-client/features/teamspace/logger'
import {
  prefetchTeamspacePermissions,
  useHasTeamspacePermission,
  useTeamspacePermissions,
} from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useDispatch } from 'sierra-client/state/hooks'
import { useUserLegacy, useUsersLegacy } from 'sierra-client/state/users/hooks'
import { PageContainer } from 'sierra-client/views/workspace/components'
import { TeamspaceContentTable } from 'sierra-client/views/workspace/teamspace/teamspace-content-table'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FolderRow } from 'sierra-domain/api/teamspace'
import { UserId } from 'sierra-domain/api/uuid'
import { assert, assertNever, isDefined } from 'sierra-domain/utils'
import { AvatarStack, Icon, MenuItem } from 'sierra-ui/components'
import { AvatarStackGroupShape, AvatarStackItemShape } from 'sierra-ui/components/avatars/avatar-stack'
import { Button, LoadingSpinner, ScrollView, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { height_100dvh, narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'
import { z } from 'zod'

const DescriptionView = styled(View)`
  max-width: 75ch;
`

const DetailsWrapper = styled(View)`
  flex-direction: column;
  align-items: start;
  gap: 24px;

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const DetailsMetadataWrapper = styled(View)`
  width: fit-content;

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    margin-left: auto;
  }
`

const Page: FC<{ teamspaceId: NanoId12 }> = ({ teamspaceId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const hasListMembersPermission = useHasTeamspacePermission(teamspaceId, 'LIST_MEMBERS')

  const teamspaceQuery = useTeamspaceById(teamspaceId)

  const allowEdit = useMemo(
    () =>
      teamspaceQuery.data?.effectiveRole !== undefined &&
      isTeamspaceRoleAbove(teamspaceQuery.data.effectiveRole, 'commenter'),
    [teamspaceQuery.data]
  )

  const [defaultTabValue, setDefaultTabValue] = useState(() =>
    allowEdit && hasListMembersPermission ? 'general' : 'members'
  )
  const [editTeamspaceModalOpen, setEditTeamspaceModalOpen] = useState(false)
  const [leaveTeamspaceModalOpen, setLeaveTeamspaceModalOpen] = useState(false)

  const teamspaceRequestAccessUserId = Route.useSearch({
    select: search => search['teamspace-request-access'],
  })
  const requestAccessUser = useUserLegacy(allowEdit ? teamspaceRequestAccessUserId : undefined)

  useEffect(() => {
    if (allowEdit && teamspaceRequestAccessUserId && requestAccessUser) {
      setDefaultTabValue('members')
      setEditTeamspaceModalOpen(true)
    }
  }, [allowEdit, teamspaceRequestAccessUserId, requestAccessUser])

  // Segment logging for viewed teamspace
  useEffect(() => {
    void dispatch(
      teamspaceViewedLogger({
        teamspaceId,
      })
    )
  }, [teamspaceId, dispatch])

  const rootFolder: FolderRow | undefined = useMemo(() => {
    if (teamspaceQuery.data !== undefined) {
      const rootFolder = getRootFolderOfTeamspace(teamspaceQuery.data)
      return rootFolder
    }
  }, [teamspaceQuery.data])

  const memberIds = useMemo(
    () => teamspaceQuery.data?.userMembers.map(it => it.userId) ?? [],
    [teamspaceQuery.data]
  )
  const groupMembers = useMemo(() => teamspaceQuery.data?.groupMembers ?? [], [teamspaceQuery.data])

  const users = useUsersLegacy(memberIds).filter(isDefined)

  const descriptionParagraphs = useMemo(() => {
    if (teamspaceQuery.data?.description !== undefined) {
      return teamspaceQuery.data.description.split('\n')
    }
    return []
  }, [teamspaceQuery.data])

  const avatars: AvatarStackItemShape[] = useMemo(() => {
    const groups = groupMembers.map((it): AvatarStackGroupShape => {
      assert(it.identity.type === 'userGroup')

      return {
        type: 'group',
        uuid: it.identity.id,
        name: it.name,
        avatarColor: it.avatar.type === 'color' ? it.avatar.color : 'pinkBright',
      }
    })

    return [...users, ...groups].map((item: AvatarStackItemShape) => {
      switch (item.type) {
        case undefined:
          return resolveUserAvatar(item)
        case 'group':
          return item
        default:
          assertNever(item)
      }
    })
  }, [groupMembers, users])

  const { leave } = useLeaveTeamspace(teamspaceId)
  const { join } = useJoinTeamspace(teamspaceId)

  const isPublicTeamspace = teamspaceQuery.data?.visibility === 'public'
  const teamspacePermissions = useTeamspacePermissions(teamspaceId)

  const refetchPermissions = async (): Promise<void> => {
    await teamspacePermissions.refetch()
  }

  const menuItems: MenuItem[] = [
    {
      hidden: !allowEdit,
      type: 'label',
      id: 'settings',
      label: t('dictionary.settings'),
      onClick: () => {
        setDefaultTabValue('general')
        setEditTeamspaceModalOpen(true)
      },
    },
    {
      type: 'label',
      id: 'view-members',
      hidden: !hasListMembersPermission,
      label: t('teamspace.view-members'),
      onClick: () => {
        setDefaultTabValue('members')
        setEditTeamspaceModalOpen(true)
      },
    },
    {
      hidden: !teamspacePermissions.has('LEAVE'),
      type: 'label',
      id: 'leave',
      label: t('teamspaces.leave-teamspace'),
      preventClose: false,
      onClick: async () => {
        if (isPublicTeamspace) {
          leave()
          await refetchPermissions()
          await teamspaceQuery.refetch()
        } else {
          setLeaveTeamspaceModalOpen(true)
        }
      },
    },
  ]

  const allMenuItemsAreHidden = _.every(menuItems, it => it.hidden === true)

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspaceQuery.data, undefined)
  drop(scrollRef)
  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  return (
    <SanaPage mode='light' headerType='none' page={PageIdentifier.NewCreatePage()}>
      {teamspaceQuery.isPending ? (
        <LoadingSpinner />
      ) : teamspaceQuery.data === undefined ? null : (
        <>
          <PageTitle title={teamspaceQuery.data.displayName} />
          <ScrollView ref={scrollRef} grow gap='none'>
            <PageContainer $sidebarOpen={sidebarOpen}>
              <PageHeader>
                <View justifyContent='space-between' marginBottom='8'>
                  <TeamspaceIcon
                    displayName={teamspaceQuery.data.displayName}
                    themeName={teamspaceQuery.data.iconTheme}
                  />

                  <View>
                    <DetailsMetadataWrapper gap='16'>
                      {hasListMembersPermission && (
                        <AvatarStack
                          size='tiny'
                          users={avatars}
                          max={5}
                          withRemainerIndication
                          withTooltips={true}
                        />
                      )}
                      <View>
                        {teamspacePermissions.has('JOIN') && (
                          <Button
                            variant='primary'
                            onClick={async () => {
                              join()
                              await refetchPermissions()
                              await teamspaceQuery.refetch()
                            }}
                          >
                            {t('dictionary.join')}
                          </Button>
                        )}

                        {allowEdit && !teamspacePermissions.has('JOIN') && (
                          <TemplatePickerProvider teamspaceId={teamspaceId}>
                            {rootFolder !== undefined ? (
                              <CreateNewButtonWithOptions
                                parentFolder={{ folderId: rootFolder.id, displayName: undefined }}
                                teamspaceId={teamspaceId}
                                teamspaceName={teamspaceQuery.data.displayName}
                              />
                            ) : (
                              <PlainCreateNewButton />
                            )}
                          </TemplatePickerProvider>
                        )}
                        {!teamspacePermissions.has('JOIN') && !allMenuItemsAreHidden && (
                          <IconMenu
                            aria-label={t('teamspace.more-settings-actions')}
                            items={menuItems}
                            closeOnPick={true}
                          />
                        )}
                      </View>
                    </DetailsMetadataWrapper>
                  </View>
                </View>

                <Text size='large' bold>
                  {teamspaceQuery.data.displayName}
                </Text>

                <Text bold size='small' color='grey25'>
                  {t(isPublicTeamspace ? 'teamspace.visibility.public' : 'share.label.invite-only')}{' '}
                  {narrowDotSeparator} {t('teamspace.members', { count: teamspaceQuery.data.membersCount })}
                </Text>

                {descriptionParagraphs.length > 0 && (
                  <DetailsWrapper paddingTop='16' justifyContent='space-between' grow>
                    <DescriptionView direction='column' grow>
                      {descriptionParagraphs.map((value, index) => (
                        <Text key={index} size='small' color='foreground/secondary'>
                          {value}
                        </Text>
                      ))}
                    </DescriptionView>
                  </DetailsWrapper>
                )}
              </PageHeader>
              <GridContainer>
                <TeamspaceContentTable
                  currentFolderId={undefined}
                  teamspace={teamspaceQuery.data}
                  scrollElement={scrollRef.current}
                  isOver={isOver && canDrop}
                  content={teamspaceQuery.data.content.filter(it => it.parentFolderId === rootFolder?.id)}
                  loadContent={() => {
                    void teamspaceQuery.refetch()
                    void invalidateGlobalSidebarTeamspaceCache()
                  }}
                  noContentComponent={
                    <NoContentContainer>
                      <Icon size='size-24' color='foreground/muted' iconId='folder--shared' />
                      <View
                        direction='column'
                        gap='4'
                        justifyContent='center'
                        alignItems='center'
                        marginBottom='8'
                      >
                        <Text color='foreground/muted' bold>
                          {teamspaceQuery.data.displayName} {t('teamspace.is-empty')}
                        </Text>
                        <Text color='foreground/muted'>
                          {allowEdit ? t('teamspace.no-content.edit') : t('teamspace.no-content.no-edit')}
                        </Text>
                      </View>
                      {allowEdit && (
                        <TemplatePickerProvider teamspaceId={teamspaceId}>
                          <PlainCreateNewButton variant='secondary' />
                        </TemplatePickerProvider>
                      )}
                    </NoContentContainer>
                  }
                />
              </GridContainer>
              <EditTeamSpaceModal
                mode={allowEdit ? 'edit' : 'read-only'}
                requestAccessUser={requestAccessUser}
                tab={defaultTabValue}
                setTab={setDefaultTabValue}
                open={editTeamspaceModalOpen}
                onClose={() => {
                  setEditTeamspaceModalOpen(false)
                }}
                teamspace={teamspaceQuery.data}
              />
              <ActionModal
                open={leaveTeamspaceModalOpen}
                deleteAction
                title={t('teamspace.edit-modal.remove-user-modal.title')}
                onClose={() => setLeaveTeamspaceModalOpen(false)}
                primaryAction={async () => {
                  leave()
                  await teamspaceQuery.refetch()
                }}
                primaryActionLabel={t('teamspace.edit-modal.remove-user-modal.action')}
              >
                {t('teamspace.edit-modal.remove-user-modal.body')}
              </ActionModal>
            </PageContainer>
          </ScrollView>
        </>
      )}
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </SanaPage>
  )
}

const LoadingContainer = styled(View)`
  position: absolute;
  inset: 0;
  width: 100vw;

  ${height_100dvh}
  & > * {
    margin: auto;
  }
`

const requireTeamspacePermission =
  <T extends Record<string, unknown>>(Component: typeof Page): React.ComponentType<T> =>
  () => {
    const { teamspaceId } = Route.useParams()

    const access = useCheckTeamspaceAccess(teamspaceId)

    useTeamspaceAccessRedirect(access)

    switch (access) {
      case TeamspaceAccess.ALLOWED:
        return <Component teamspaceId={teamspaceId} />

      case TeamspaceAccess.DENIED:
        return null

      case TeamspaceAccess.ERROR:
        return null

      case TeamspaceAccess.LOADING:
        return (
          <LoadingContainer>
            <View direction='column'>
              <LoadingSpinner />
            </View>
          </LoadingContainer>
        )
    }
  }

export const Route = createFileRoute('/t/$teamspaceId/')({
  loader: ({ context, params }) => {
    if (getFlag('preloading') && context.isLoggedIn) {
      // TODO: Validate that the teamspace permissions are correct before trying to prefetch the teamspace.
      // Since user can only see teamspaces they have access to and the backend validates the request this isn't dangerous, but is
      // potentially an unnecessary request to the backend.
      void prefetchTeamspacePermissions(params.teamspaceId)
      void prefetchTeamspaceById(params.teamspaceId)
    }
  },
  component: requireLoggedIn(requireTeamspacePermission(Page)) as React.FC,
  validateSearch: z.object({
    'teamspace-request-access': UserId.optional().catch(undefined),
  }),
  params: {
    parse: z.object({ teamspaceId: NanoId12 }).parse,
    stringify: params => params,
  },
})
