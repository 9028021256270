import { motion } from 'framer-motion'
import { useAtom } from 'jotai'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { MAX_IMAGE_SIZE_MB, useUploadImage } from 'sierra-client/hooks/use-image-upload'
import { useFormattedList } from 'sierra-client/hooks/use-list-formatter'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { atomWithStorage } from 'sierra-client/state/storage'
import { Upload } from 'sierra-client/views/v3-author/common/media-uploader/shared'
import { GeneratedBrowser } from 'sierra-client/views/v3-author/images/generated-browser'
import { MediaLibraryBrowser } from 'sierra-client/views/v3-author/images/media-library-browser'
import { UnsplashBrowser } from 'sierra-client/views/v3-author/images/unsplash-browser'
import { acceptedImageTypes } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { XRealtimeAuthorMediaLibraryGetAllAvailableTags } from 'sierra-domain/routes'
import { Icon, Modal, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, Heading, IconButton, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { height_100dvh } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 180px;
  gap: 4px;
  margin-bottom: 24px;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
  }
  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    margin-top: 8px;
  }
`

const Tab = styled(View)<{ selected: boolean }>`
  cursor: pointer;
  background: transparent;
  padding: 8px 1rem;
  margin: 0rem 2px;
  border-radius: 6px;
  width: 100%;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    ${p =>
      p.selected === false &&
      css`
        background-color: ${token('surface/soft')};
        transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
      `}
  }

  ${p =>
    p.selected === true &&
    css`
      background-color: ${token('surface/soft')};
    `}
`

const MediaLibraryTag = styled(View)<{ selected: boolean }>`
  cursor: pointer;
  background: transparent;
  padding: 8px 1rem;
  margin: 0rem 2px;
  border-radius: 6px;
  width: 100%;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;

  &:hover {
    ${p =>
      p.selected === false &&
      css`
        background-color: ${token('surface/soft')};
        transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
      `}
  }

  ${p =>
    p.selected === true &&
    css`
      background-color: ${token('surface/soft')};
    `}
`

const UploadButtonContainer = styled.div`
  white-space: nowrap;
`

const BrowserContainer = styled(View)`
  height: calc(80vh - 200px);
  margin-top: 24px;

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    ${height_100dvh}
  }

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    margin-top: 0px;
  }
`

const GenerateImageContainer = styled(motion.div)`
  height: calc(80vh - 124px);
  width: 100%;

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    ${height_100dvh}
  }
`

const ImagePickerWrapper = styled(View)`
  width: 80vw;
  height: 80vh;
  padding: 24px;
  max-width: 1200px;

  flex-direction: row;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    flex-direction: column;
  }
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: 100vw;
    height: 100vh;
    padding: 24px;
  }
`

const ImageSourcesWrapper = styled(View)`
  width: 100%;
  padding-top: 60px;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    padding-top: 12px;
  }
`

const MediaLibrarySourcesWrapper = styled(ImageSourcesWrapper)`
  padding-top: 0;
`

const SideBarWrapper = styled(View)`
  padding-right: 24px;
  margin-right: 20px;
  height: 100%;
  border-right: 1px solid ${token('border/default')};

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    padding-right: 12px;
  }

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    border-right: none;
  }
`

type UploadModalProps = {
  onUploadDone: (payload: ImageUnion) => void
  onClose: () => void
  open: boolean
  assetContext: AssetContext
}

const ModalTitle = styled(Heading)`
  white-space: nowrap;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
`

const HiddenInput = styled.input`
  display: none;
`

const acceptedMimeTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp']

const DropZone = styled.div``

type Tag = {
  id: string
  name: string
}

type AvailableTag = {
  id: string
  name: string
  numberOfMedia: number
}

const useGetTags = (): { tags: AvailableTag[] } => {
  const tagsQuery = useCachedQuery(XRealtimeAuthorMediaLibraryGetAllAvailableTags, {})
  const tags: AvailableTag[] = tagsQuery.data?.tags ?? []
  return { tags }
}

const MediaLibrarySidebarTabsWrapper = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid ${token('border/default')};
`

function useMediaLibraryTagsExpanded(
  options: {
    initial?: boolean
  } = {}
): [expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>] {
  const { initial = true } = options
  const expandedAtom = useMemo(() => atomWithStorage('mediaLibraryExpanded/editor', initial), [initial])
  return useAtom(expandedAtom)
}

const MediaLibrarySidebarTabs: FC<{
  currentTab: Tag
  onTabChange: (tab: Tag) => void
}> = ({ currentTab, onTabChange }) => {
  const { t } = useTranslation()
  const { tags } = useGetTags()
  const [isAllTagsExpanded, setIsAllTagsExpanded] = useMediaLibraryTagsExpanded({ initial: true })

  const nonEmptyTags = tags.filter(tag => tag.numberOfMedia > 0)

  return (
    <MediaLibrarySidebarTabsWrapper>
      <TabContainer>
        <Tab
          selected={currentTab.id === 'all-media'}
          onClick={() => onTabChange({ name: t('dictionary.all-media'), id: 'all-media' })}
        >
          <View grow justifyContent='space-between'>
            <View>
              <Icon iconId='media--library' color='foreground/primary' />
              <Text bold size='small'>
                {t('dictionary.all-media')}
              </Text>
            </View>
            {nonEmptyTags.length > 0 && (
              <IconButton
                size='small'
                variant='transparent'
                onClick={() => setIsAllTagsExpanded(!isAllTagsExpanded)}
                iconId={isAllTagsExpanded ? 'chevron--up--small' : 'chevron--down--small'}
              />
            )}
          </View>
        </Tab>
        {isAllTagsExpanded &&
          nonEmptyTags.map(tag => (
            <MediaLibraryTag
              key={tag.id}
              selected={currentTab.id === tag.id}
              onClick={() => onTabChange(tag)}
            >
              <Icon color='foreground/muted' iconId={currentTab.id === tag.id ? 'tag--filled' : 'tag'} />
              <TruncatedTextWithTooltip bold size='small'>
                {tag.name}
              </TruncatedTextWithTooltip>
            </MediaLibraryTag>
          ))}
      </TabContainer>
    </MediaLibrarySidebarTabsWrapper>
  )
}

export const UploadImageModal: FC<UploadModalProps> = ({ onUploadDone, open, onClose, assetContext }) => {
  const [query, setQuery] = useState<string>()
  const { uploadImage } = useUploadImage()
  const { t } = useTranslation()
  const fileTypes = useFormattedList({
    list: acceptedMimeTypes.map(t => t.replace(/^image\//, '')).map(t => t.toUpperCase()),
    type: 'conjunction',
  })

  const [images, setImages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [tab, onTabChange] = useState<Tag>({ id: 'all-media', name: t('dictionary.all-media') })

  const isTablet = useIsTablet()
  const nonMediaLibraryTabs = ['upload', 'stock', 'generate', 'media-library']

  const handleUploadDone = useCallback(
    (payload: ImageUnion): void => {
      onUploadDone(payload)
      onClose()
    },
    [onClose, onUploadDone]
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value)
  }

  const fileInput = useRef<HTMLInputElement>(null)

  const handleClick = (): void => {
    if (fileInput.current === null) return
    fileInput.current.click()
  }

  const [isUploading, setIsUploading] = useState(false)
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files === null) return
    const file = e.target.files[0]
    if (file && acceptedMimeTypes.includes(file.type)) {
      try {
        setIsUploading(true)
        const data = await uploadImage(file, assetContext)
        handleUploadDone(data)
      } finally {
        setIsUploading(false)
      }
    }
  }

  return (
    <AppThemeTokenProvider>
      <Modal size='fit-content' open={open} onClose={onClose}>
        <DropZone
          onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            const file = e.dataTransfer.files[0]
            if (file !== undefined && acceptedMimeTypes.includes(file.type)) {
              const image = await uploadImage(file, assetContext)
              onUploadDone(image)
            }
          }}
        >
          <ImagePickerWrapper alignItems='flex-start'>
            <CloseButtonWrapper>
              <IconButton iconId='close' onClick={onClose} variant='transparent' />
            </CloseButtonWrapper>
            <SideBarWrapper direction='column' alignItems='flex-start' gap={isTablet ? undefined : '32 none'}>
              <ModalTitle bold size={'h5'}>
                {t('author.upload-image-popup.select')}
              </ModalTitle>
              <TabContainer>
                <MediaLibrarySidebarTabs currentTab={tab} onTabChange={(tag: Tag) => onTabChange(tag)} />

                <Tab
                  onClick={() => onTabChange({ id: 'generate', name: t('author.slate.generate') })}
                  selected={tab.id === 'generate'}
                >
                  <Icon iconId='glitter' color='foreground/primary' />
                  <Text bold size='small'>
                    {t('author.slate.generate')}
                  </Text>
                </Tab>
                <Tab
                  onClick={() => onTabChange({ id: 'stock', name: t('author.slate.browse-image-tab') })}
                  selected={tab.id === 'stock'}
                >
                  <Icon iconId='unsplash' color='foreground/primary' />
                  <Text bold size='small'>
                    {t('author.slate.browse-image-tab')}
                  </Text>
                </Tab>
                {isTablet && (
                  <UploadButtonContainer>
                    <HiddenInput
                      type='file'
                      accept='.jpg,.jpeg'
                      ref={fileInput}
                      onChange={handleFileUpload}
                    />
                    <Button loading={isUploading} variant='secondary' onClick={handleClick}>
                      {t('author.slate.upload-image-tab')}
                    </Button>
                  </UploadButtonContainer>
                )}
              </TabContainer>

              {!isTablet && (
                <View grow justifyContent='flex-end' alignItems='flex-end'>
                  <HiddenInput
                    type='file'
                    accept={acceptedMimeTypes.toString()}
                    ref={fileInput}
                    onChange={handleFileUpload}
                  />
                  <View direction='column' gap='4'>
                    <Button loading={isUploading} variant='secondary' onClick={handleClick}>
                      {t('author.slate.upload-image-tab')}
                    </Button>
                    <Text size='micro' color='foreground/muted'>
                      {t('manage.media-library.file-size-indicator', {
                        fileTypes,
                        size: MAX_IMAGE_SIZE_MB,
                      })}
                    </Text>
                  </View>
                </View>
              )}
            </SideBarWrapper>
            {!nonMediaLibraryTabs.includes(tab.id) ? (
              <MediaLibrarySourcesWrapper>
                <MediaLibraryBrowser onImageSelect={handleUploadDone} currentTab={tab} />
              </MediaLibrarySourcesWrapper>
            ) : (
              <ImageSourcesWrapper>
                {tab.id === 'upload' && (
                  <Upload
                    iconId={'upload'}
                    accept={acceptedImageTypes}
                    uploadMedia={uploadImage}
                    onUploaded={handleUploadDone}
                    assetContext={assetContext}
                  />
                )}
                {tab.id === 'stock' && (
                  <View direction='column' grow>
                    <InputPrimitive
                      id='search'
                      type='text'
                      placeholder={t('dictionary.search')}
                      value={query ?? ''}
                      onChange={handleSearchChange}
                      autoFocus
                    />
                    <BrowserContainer>
                      <UnsplashBrowser
                        query={Boolean(query?.length) ? query : undefined}
                        onImageUploaded={handleUploadDone}
                      />
                    </BrowserContainer>
                  </View>
                )}
                {tab.id === 'generate' && (
                  <GenerateImageContainer>
                    <GeneratedBrowser
                      onImageUploaded={handleUploadDone}
                      setImages={setImages}
                      isLoading={isLoading}
                      images={images}
                      setIsLoading={setIsLoading}
                      textValue={textValue}
                      setTextValue={setTextValue}
                      assetContext={assetContext}
                    />
                  </GenerateImageContainer>
                )}
              </ImageSourcesWrapper>
            )}
          </ImagePickerWrapper>
        </DropZone>
      </Modal>
    </AppThemeTokenProvider>
  )
}
