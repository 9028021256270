import { createSlice } from '@reduxjs/toolkit'
import {
  clockSynced,
  collabProviderStatusChanged,
  removeCollabProvider,
  setInvalidCollaborationVersion,
} from 'sierra-client/state/collaboration/actions'
import { CollaborationState } from 'sierra-client/state/collaboration/types'

const initialState: CollaborationState = {
  collabProviderStatuses: {},
  invalidVersion: false,
}

export const collaborationSlice = createSlice({
  name: 'collaboration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(collabProviderStatusChanged, (state, { payload: { channels, clientId, status } }) => {
      state.collabProviderStatuses[clientId] = { channels, clientId, status }
    })

    builder.addCase(removeCollabProvider, (state, { payload: { clientId } }) => {
      delete state.collabProviderStatuses[clientId]
    })

    builder.addCase(clockSynced, (state, { payload }) => {
      state.clock = payload
    })

    builder.addCase(setInvalidCollaborationVersion, (state, { payload: value }) => {
      state.invalidVersion = value
    })
  },
})
