import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectCurrentCourseId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { cohesionTodoT } from 'sierra-client/views/v3-author/question-card/question-responses'
import { LearnerReflections } from 'sierra-client/views/v3-author/reflection-card/learner'
import { ReflectionCardPreview } from 'sierra-client/views/v3-author/reflection-card/preview'
import {
  ReflectionsDataProvider,
  useReflectionsData,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-data-layer'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { EditorMode, SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { InteractiveCardWrapper } from 'sierra-client/views/v3-author/wrapper'
import { iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export { withReflectionCard } from 'sierra-client/views/v3-author/reflection-card/with-reflection-card'

const ReflectionsBlockContainer = styled.div`
  margin-top: 1rem;
`

const EmptyResponsesContainer = styled.div`
  position: absolute;
  height: fit-content;
  width: max-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const FadeInDiv = styled(motion.div).attrs({
  transition: { duration: 0.2 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})``

const StyledInteractiveCardWrapper = styled(InteractiveCardWrapper)`
  position: relative;
  padding: 4rem 0.5rem;

  flex: 1;
  grid-template-rows: auto 1fr;

  & {
    margin-top: 4rem;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: ${p => p.theme.borderRadius['size-6']};
    transition: background-color 0.1s;
  }
`

const ReflectionCardContent: React.FC<{ mode: EditorMode }> = ({ mode }) => {
  const isCreate = mode === 'create' || mode === 'template'
  const editOrResponsesState = useEditOrResponsesStateSafe()
  const data = useReflectionsData()

  return (
    <AnimatePresence mode='wait'>
      {iife(() => {
        if (isCreate && editOrResponsesState?.type !== 'responses')
          return (
            <FadeInDiv key='preview'>
              <ReflectionCardPreview />
            </FadeInDiv>
          )
        else if (data.responses === undefined) {
          return (
            <FadeInDiv key='loading'>
              <EmptyResponsesContainer>
                <LoadingSpinner />
              </EmptyResponsesContainer>
            </FadeInDiv>
          )
        } else if (data.responses.length === 0 && editOrResponsesState?.type === 'responses') {
          return (
            <FadeInDiv key='empty'>
              <EmptyResponsesContainer>
                <View direction='column' gap='24'>
                  <Icon iconId='edit' size='size-20' color='foreground/secondary' />
                  <View direction='column' gap='4'>
                    <Text color='foreground/secondary' size='regular' bold align='center'>
                      {cohesionTodoT('No reflections added yet')}
                    </Text>
                    <Text color='foreground/muted' size='small' align='center'>
                      {cohesionTodoT('Submitted reflections will appear here')}
                    </Text>
                  </View>
                </View>
              </EmptyResponsesContainer>
            </FadeInDiv>
          )
        } else {
          return (
            <FadeInDiv key='learner'>
              <LearnerReflections />
            </FadeInDiv>
          )
        }
      })}
    </AnimatePresence>
  )
}

export const ReflectionCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element } = props
  assertElementType('reflection-card', element)
  const userId = useSelector(selectUserId)

  const dispatch = useDispatch()
  const isCreate = mode === 'create' || mode === 'template'
  const courseId = useSelector(selectCurrentCourseId)
  const isCreateAccessible = useIsCreateAccessible()

  // We need to fetch the latest state of collaborators in order to know if a user can remove reflections
  useEffect(() => {
    if (courseId !== undefined && !isCreate && isCreateAccessible)
      void dispatch(settingsActions.fetch({ courseId }))
  }, [dispatch, courseId, isCreate, isCreateAccessible])

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>

  return (
    <RenderingContext preventDrag={true} disableMenu={false}>
      <StyledInteractiveCardWrapper {...props} {...attributes} ref={ref}>
        <RenderingContext withGrid={false}>
          {children}
          <ReflectionsBlockContainer contentEditable={false}>
            <ReflectionsDataProvider element={element} mode={mode}>
              <ReflectionCardContent mode={mode} />
            </ReflectionsDataProvider>
          </ReflectionsBlockContainer>
        </RenderingContext>
      </StyledInteractiveCardWrapper>
    </RenderingContext>
  )
})

export const ReflectionCard: SlateFC = ({ element, children }) => {
  assertElementType('reflection-card', element)

  return <>{children}</>
}
