import { Duration } from 'luxon'
import { Data } from 'sierra-client/lib/tabular/datatype/data'
import { EnrollmentRep } from 'sierra-client/lib/tabular/datatype/internal/reps/enrollment-rep'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'

export const mergeTableData = <TD extends TableData>(tableData: TD[]): TD =>
  tableData.reduce((td, ctd) => ({
    ...td,
    nested: { ...td.nested, ...ctd.nested },
    rows: [...td.rows, ...ctd.rows],
  }))

type Ord = -1 | 0 | 1

const compareBool = (a: boolean, b: boolean): Ord => (a === b ? 0 : a ? 1 : -1)

const compareNumber = (a: number, b: number): Ord => (a === b ? 0 : a > b ? 1 : -1)

const compareString = (a: string, b: string): Ord => <Ord>a.localeCompare(b)

const compareDateTime = (a: Date, b: Date): Ord =>
  a.getTime() === b.getTime() ? 0 : a.getTime() > b.getTime() ? 1 : -1

const compareDuration = (a?: Duration, b?: Duration): Ord => {
  const aMillis = a?.as('milliseconds') ?? 0
  const bMillis = b?.as('milliseconds') ?? 0
  return aMillis === bMillis ? 0 : aMillis > bMillis ? 1 : -1
}

const compareUser = (a?: UserRep, b?: UserRep): Ord =>
  compareString(
    [a?.firstName, a?.lastName, a?.email].filter(isDefined).join(''),
    [b?.firstName, b?.lastName, b?.email].filter(isDefined).join('')
  )

function compareKeyEnrollment(e: EnrollmentRep): string {
  switch (e.type) {
    case 'admin':
      return [e.firstName, e.lastName].join('')
    case 'self':
      return '#self'
    case 'enrollment-rule':
      return e.name
    case 'unknown':
      return '#unknown'
  }
}

const compareEnrollment = (a: EnrollmentRep, b: EnrollmentRep): Ord =>
  compareString(compareKeyEnrollment(a), compareKeyEnrollment(b))

const sortSign = (sorting: Sorting): 1 | -1 => (sorting.direction === 'ascending' ? 1 : -1)

const compareTitleAndSubtitle = (
  a?: { title: string; subtitle: string },
  b?: { title: string; subtitle: string }
): Ord =>
  compareString(
    [a?.title, a?.subtitle].filter(isDefined).join(''),
    [b?.title, b?.subtitle].filter(isDefined).join('')
  )

type TypedData<A extends Data = Data> = A extends Data
  ? { a: A['data']; b: A['data']; type: A['type'] }
  : never

function sortData({ a, b, type }: TypedData): Ord {
  switch (type) {
    case 'booleans':
      return compareBool(a ?? false, b ?? false)
    case 'numbers':
      return compareNumber(a ?? 0, b ?? 0)
    case 'strings':
      return compareString(a ?? '', b ?? '')
    case 'titlesAndSubtitles':
      return compareTitleAndSubtitle(a, b)
    case 'dateTimes':
      return compareDateTime(a!, b!)
    case 'duration':
      return compareDuration(a, b)
    case 'users':
      return compareUser(a, b)
    // TODO: do we need homeworks and reviewers below if we do BE sorting?
    // Answer: The thinking was that Tabular should sort locally when DataLoaderStateMachine is in Done state.
    // Don't think this is the case yet.
    case 'homeworks':
      return 0
    case 'userStacks':
      return 0
    //
    case 'enrolledBys':
      return compareEnrollment(a, b)
    case 'content':
      return compareString(a?.title ?? '', b?.title ?? '')
    case 'skillContent':
      return compareString(a.title, b.title)
    case 'links':
      return compareString(a.label, b.label)
    case 'certificates':
      return compareString(a?.title ?? '', b?.title ?? '')
    case 'certificateIssuedBy': {
      const stringA = a.type === 'user' ? a.displayName : a.title
      const stringB = b.type === 'user' ? b.displayName : b.title
      return compareString(stringA, stringB)
    }
    case 'issuedCertificates':
      return compareString(a.issuedToUserDisplayName, b.issuedToUserDisplayName)
    case 'addStepContent':
      return compareString(a.title, b.title)
    case 'expiryTimes':
      return compareDateTime(a!, b!)
    case 'issuesRevoked':
      return compareDateTime(a.issued, b.issued)
    case 'programVersionNumbers':
      return compareNumber(a.version, b.version)
    case 'groups':
      return compareString(a?.name ?? '', b?.name ?? '')
    case 'eventGroups':
      return compareString(a.title, b.title)
    case 'singleSelect':
      return compareString(a.selected, b.selected)
    case 'card':
      return compareString(a?.title ?? '', b?.title ?? '')
    case 'canvas':
      return compareString(a.meta.sorts(), b.meta.sorts())
    case 'pills':
      return compareString(a.type, b.type)
    default:
      assertNever(a)
  }
}

export const sortTableData = <Data extends TableData>(tableData: Data, sorting: Sorting[]): Data => {
  const tableLength = tableData.rows.length
  const colMap = new Map(tableData.columns.map(c => [c.ref, c]))
  const sortedIdx = Array.from({ length: tableLength }, (_, i) => i).sort((a, b) =>
    sorting.reduce((acc, s) => {
      if (acc !== 0) return acc
      const col = colMap.get(s.column)
      if (!col) return 0

      const maybeA = tableData.rows[a]?.data[col.ref]?.data
      const maybeB = tableData.rows[b]?.data[col.ref]?.data
      if (isNotDefined(maybeA) || isNotDefined(maybeB)) return 0

      // Temporary object to be able to get type safety out of the data from the column type.
      const cmp = {
        a: maybeA,
        b: maybeB,
        type: col.type,
      } as TypedData

      return sortData(cmp) * sortSign(s)
    }, 0)
  )

  return {
    ...tableData,
    rows: sortedIdx.map(i => tableData.rows[i]),
  }
}
