import { PrimitiveAtom } from 'jotai'
import React from 'react'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { useCachedQuery } from 'sierra-client/state/api'
import {
  AINarrationControlsState,
  AiNarrationsDebugControls,
  calculateQuotaExceeded,
  NarrationTab,
  ScriptState,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { useAiNarrationDraftState } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-draft-state'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations'
import { File, GeneratedNarrationDraft } from 'sierra-domain/flexible-content/types'
import { XRealtimeAuthorGetNarrationDefaultDraftState } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'

const _AINarrationsNewInfra: React.FC<{
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
  debugControls: AiNarrationsDebugControls
  errorGeneratingNarration: boolean
  generatedNarrationDraft: GeneratedNarrationDraft
  file: File
}> = ({
  scopedCreateContentId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
  debugControls,
  errorGeneratingNarration,
  generatedNarrationDraft,
  file,
}) => {
  const { showDebugData, synthesiaUnavailableOverride } = debugControls

  const isDebugMode = useIsDebugMode()
  const { minutesUsed, quotaExceeded } = calculateQuotaExceeded(debugControls, isDebugMode, narrationSettings)

  const contentId = ScopedSelfPacedContentId.extractId(scopedCreateContentId)

  const { characterLimit, quota, avatars } = narrationSettings

  const { script, setScript, avatarId, setAvatarId } = useAiNarrationDraftState({
    file,
    operationState,
    defaultAvatarId: generatedNarrationDraft.avatarId,
    defaultScript: generatedNarrationDraft.script,
  })

  const scriptState: ScriptState = {
    type: 'plain',
    setScript: setScript,
    script: script,
  }

  return (
    <ConfirmationModalProvider>
      <NarrationTab
        contentId={contentId}
        fileId={file.id}
        operationState={operationState}
        aiNarrationControlStateAtom={aiNarrationControlStateAtom}
        legacyNarrationStateAtom={legacyNarrationStateAtom}
        narrationMetadata={narrationMetadata}
        quotaExceeded={quotaExceeded}
        characterLimit={characterLimit}
        minutesUsed={minutesUsed}
        quota={quota}
        avatars={avatars}
        showDebugData={showDebugData}
        errorGeneratingNarration={errorGeneratingNarration}
        forceShowSynthesiaUnavailable={synthesiaUnavailableOverride === 'unavailable'}
        assetContext={assetContext}
        avatarId={avatarId}
        setAvatarId={setAvatarId}
        scriptState={scriptState}
      />
    </ConfirmationModalProvider>
  )
}

export const AINarrationsNewInfra: React.FC<{
  file: File
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
}> = ({
  file,
  scopedCreateContentId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
}) => {
  const debugControls = useDebug('ai-narrations', {
    quotaExceededOverride: ['no-override', 'exceeded'] as const,
    errorGeneratingNarrationOverride: ['no-override', 'error'] as const,
    synthesiaUnavailableOverride: ['no-override', 'unavailable'] as const,
    showDebugData: false,
    minutesUsedOverride: ['no-override', '5%', '50%', '95%', '100%', '150%'] as const,
  })
  const isDebugMode = useIsDebugMode()

  const errorGeneratingNarration = iife(() => {
    const actualValue = narrationMetadata?.type === 'error'
    if (isDebugMode && debugControls.errorGeneratingNarrationOverride === 'error') {
      return true
    } else {
      return actualValue
    }
  })

  const draftQuery = useCachedQuery(XRealtimeAuthorGetNarrationDefaultDraftState, {
    fileId: file.id,
    contentId: ScopedSelfPacedContentId.extractId(scopedCreateContentId),
  })

  const generatedNarrationDraft = draftQuery.data

  if (generatedNarrationDraft === undefined) {
    return <Debug>Narration draft was undefined</Debug>
  }

  return (
    <_AINarrationsNewInfra
      generatedNarrationDraft={generatedNarrationDraft}
      debugControls={debugControls}
      file={file}
      scopedCreateContentId={scopedCreateContentId}
      operationState={operationState}
      aiNarrationControlStateAtom={aiNarrationControlStateAtom}
      legacyNarrationStateAtom={legacyNarrationStateAtom}
      narrationSettings={narrationSettings}
      narrationMetadata={narrationMetadata}
      assetContext={assetContext}
      errorGeneratingNarration={errorGeneratingNarration}
    />
  )
}
