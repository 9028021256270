import { createFileRoute } from '@tanstack/react-router'

import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { SelfReportAttendancePage } from 'sierra-client/features/event-group'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { CalendarEventId, EventGroupId } from 'sierra-domain/api/nano-id'
import { ThemeProvider } from 'styled-components'
import { z } from 'zod'

const Page: React.FC = () => {
  const { eventGroupId, eventId } = Route.useParams()
  const learnerTheme = useLearnerTheme()

  return (
    <SanaPage
      mode='light'
      headerType='none'
      page={PageIdentifier.LearnerEventSelfCheckInPage({ eventGroupId, eventId })}
    >
      <ThemeProvider theme={learnerTheme}>
        <SelfReportAttendancePage eventGroupId={eventGroupId} calendarEventId={eventId} />
      </ThemeProvider>
    </SanaPage>
  )
}

export const Route = createFileRoute('/check-in/$eventGroupId/$eventId/')({
  component: requireLoggedIn(Page) as React.FC,
  params: {
    parse: z.object({ eventGroupId: EventGroupId, eventId: CalendarEventId }).parse,
    stringify: p => p,
  },
})
