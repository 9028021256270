import { useOverrideIntercomLauncherVisibility } from 'sierra-client/components/util/show-intercom-launcher'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { PanelHeadline } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { ClosePanelButton, Panel } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PanelContent = styled(View).attrs({
  direction: 'column',
  paddingTop: '32',
  paddingBottom: '24',
  grow: true,
  gap: 'small',
})`
  height: 100%;
  overflow: hidden;
`
const ContentWrapper = styled(View).attrs({
  grow: true,
})`
  overflow: hidden;
`

export const SkillPanel: FCC<{
  onClose: () => void
  isOpen: boolean
  id?: string
  title: string
}> = ({ onClose, isOpen, id, children, title }) => {
  const { t } = useTranslation()

  useOverrideIntercomLauncherVisibility(isOpen ? 'hidden' : 'default')

  return (
    <Panel
      size={{ width: 650 }}
      animation='slide-right'
      padding='none'
      onClose={onClose}
      open={isOpen}
      id={id}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={onClose} ariaLabel={t('dictionary.close')} />

      <PanelContent>
        <View paddingLeft='40' paddingRight='40'>
          <PanelHeadline>{title}</PanelHeadline>
        </View>
        <ContentWrapper>{children}</ContentWrapper>
      </PanelContent>
    </Panel>
  )
}
