import { BroadcastChannel } from 'broadcast-channel'
import { Auth } from 'sierra-client/core/auth'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import { statusChanged } from 'sierra-client/state/user/actions'
import { UserAuthStatus } from 'sierra-client/state/user/user-types'
import { XRealtimeAuthCheckSession, XRealtimeAuthExpireSession, XRealtimeUserMe } from 'sierra-domain/routes'

export class CookieAuth extends Auth {
  protected channel: BroadcastChannel<UserAuthStatus>
  private timeout: number | null

  constructor(
    public dispatch: RootStateThunkDispatch,
    timeout: number | null
  ) {
    super()
    this.channel = new BroadcastChannel('sierra-user')
    this.timeout = timeout
  }

  override initialize(): void {
    this.channel.onmessage = (status: UserAuthStatus) => {
      this.dispatch(statusChanged(status))
    }
  }

  // NOTE: `postWithUserErrorException` can call `synchronize`. Make sure to exclude any endpoints called
  // here from the condition in that function to avoid infinite recursion.
  override async synchronize(): Promise<void> {
    const result = await postWithUserErrorException(XRealtimeAuthCheckSession, {}, this.dispatch)

    if (result.status === 'ok') {
      const user = await postWithUserErrorException(XRealtimeUserMe, {}, this.dispatch)
      const status: UserAuthStatus = { type: 'user', user: user }
      this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    } else if (result.status === 'verify-code') {
      const status: UserAuthStatus = { type: 'code-verification' }
      this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    } else {
      const status: UserAuthStatus = { type: 'loading', loaded: true }
      this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    }
  }

  override getCookie(): string | undefined {
    return 'x-session'
  }
}

export class NativeAuth extends CookieAuth {
  constructor(dispatch: RootStateThunkDispatch, timeout: number | null) {
    super(dispatch, timeout)
  }

  override async signOut(): Promise<void> {
    await postWithUserErrorException(XRealtimeAuthExpireSession, {}, this.dispatch)
    await this.synchronize()
  }
}
