import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { UserErrorCode } from 'sierra-domain/error'

const userErrorTranslationKeyRecord: Record<UserErrorCode, TranslationKey | undefined> = {
  'admin/live-session-max-users': 'user-error.admin.live-session-max-users',
  'auth/email-already-exists': 'user-error.auth.email-already-exists',
  'auth/expired-verification-code': 'user-error.auth.expired-verification-code',
  'auth/invalid-verification-code': 'user-error.auth.invalid-verification-code',
  'auth/invalid-action-code': 'user-error.auth.invalid-action-code',
  'auth/invalid-email': 'user-error.auth.invalid-email',
  'auth/invalid-invite-code': 'user-error.auth.invalid-invite-code',
  'auth/invalid-live-session': 'user-error.auth.invalid-live-session',
  // bearer:disable javascript_lang_hardcoded_secret
  'auth/invalid-password': 'user-error.auth.invalid-password',
  'auth/invalid-recaptcha': 'user-error.auth.invalid-recaptcha',
  'auth/invalid-signup-domain': 'user-error.auth.invalid-signup-domain',
  'auth/invalid-signup-token': 'user-error.auth.invalid-signup-token',
  'auth/invalid-user': 'user-error.auth.invalid-user',
  'auth/invite-already-accepted': 'user-error.auth.invite-already-accepted',
  'auth/invite-not-accepted': 'user-error.auth.invite-not-accepted',
  // bearer:disable javascript_lang_hardcoded_secret
  'auth/password-not-set': 'user-error.auth.password-not-set',
  'auth/provider-not-authoritative': 'user-error.auth.provider-not-authoritative',
  'report/empty-result': 'user-error.report.empty-result',
  'scorm-import/manifest-not-found': 'scorm.import.user-error.manifest-not-found',
  'scorm-import/manifest-parsing-error': 'scorm.import.user-error.manifest-parsing-error',
  'scorm-import/manifest-structure-error': 'scorm.import.user-error.manifest-structure-error',
  'scorm-import/missing-resource': 'scorm.import.user-error.missing-resource',
  'user/invalid-token-organization': 'user-error.user.invalid-token-organization',
  'user/email-rate-limited': 'user-error.user.email-rate-limited',
  'other/unknown': 'user-error.other.unknown',
  'enrollment-rule/duplicate-name': 'manage.programs.enrollment-rules.notifications.duplicate-name',
  'skill/duplicate-name': undefined,
  'xapi/duplicate-name': undefined,
  'freetext/too-long': undefined,
  'generate-question/short-context': undefined,
  'generate-question/sensitive-context': undefined,
  'generate-question/no-question': undefined,
  'generate-takeaways/no-takeaway': undefined,
}

/**
 * Gets the translation key for a UserError.
 */
export const getUserErrorTranslationKey = (
  error: UserErrorCode,
  defaultTranslationKey: TranslationKey = 'user-error.other.unknown'
): TranslationKey => userErrorTranslationKeyRecord[error] ?? defaultTranslationKey
