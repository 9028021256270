import { motion } from 'framer-motion'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { createImageThumbhashBase64 } from 'sierra-client/utils/image-thumbhash'
import { ImageStylesPopup, styles } from 'sierra-client/views/v3-author/images/image-styles-popup'
import { AuthorResolveImageUrlRequest } from 'sierra-domain/api/author-v2'
import type { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import {
  XRealtimeAuthorGenerateImages,
  XRealtimeAuthorGetGeneratedImages,
  XRealtimeAuthorResolveImageUrl,
} from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { Popover } from 'sierra-ui/components'
import { Button, InputPrimitive, Skeleton, Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
`

const ImageWrapper = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  grid-gap: 0.5rem;
  overflow-y: auto;
  padding: 4px;
  margin-top: 24px;
  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
`

const ImageContainer = styled(View)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transition: 100ms ease-in;
  outline: 0px solid ${p => p.theme.color.blueLight};
  border-radius: 6px;

  &:hover {
    border-radius: 6px;
    outline: 4px solid ${p => p.theme.color.blueLight};
  }
`

const MetaData = styled(Text)`
  max-width: 320px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.75rem;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`

const NoImagesPlaceholder = styled(View)`
  height: 100%;
`

const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
`

const getImageMetadata = async (
  url: string
): Promise<{ width: number; height: number; thumbhash: string | undefined }> => {
  const image = new Image()
  image.crossOrigin = 'anonymous'
  image.src = url
  await image.decode()
  const hash = createImageThumbhashBase64(image)

  return {
    width: image.width,
    height: image.height,
    thumbhash: hash,
  }
}

export const GeneratedBrowser: React.FC<{
  onImageUploaded: (_: ImageUnion) => void
  images: string[]
  isLoading: boolean
  setImages: Dispatch<SetStateAction<string[]>>
  setIsLoading: (_: boolean) => void
  textValue: string
  setTextValue: (_: string) => void
  assetContext: AssetContext
}> = ({
  onImageUploaded,
  isLoading,
  images,
  setImages,
  setIsLoading,
  textValue,
  setTextValue,
  assetContext,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const storedStyle = localStorage.getItem('generationStyle')
  const [showStyles, setShowstyles] = useState(false)
  const [selectedStyle, setSelectedStyle] = useState(storedStyle !== null ? parseInt(storedStyle) : 0)

  const handleTextChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setTextValue(e.target.value)
  }

  const uploadUrlToStorage = useCallback(
    async (url: string, assetContext: AssetContext): Promise<ImageUnion> => {
      const data: AuthorResolveImageUrlRequest | undefined = iife(() => {
        switch (assetContext.type) {
          case 'course':
            return { url, type: 'course', courseId: assetContext.courseId }
          case 'program':
            return { url, type: 'program', programId: assetContext.programId }
          case 'path':
            return { url, type: 'path', pathId: assetContext.pathId }
          case 'certificate':
            return { url, type: 'certificate' }
          case 'tag':
            return { url, type: 'tag' }
          case 'courseTemplate':
          case 'organization':
          case 'organization-settings':
          case 'homework':
          case 'unknown':
          case 'pdf-image':
            return undefined // This should never happen
          default:
            assertNever(assetContext)
        }
      })

      if (data === undefined) {
        throw new Error('Unsupported asset context for uploadUrlToStorage')
      }

      const [{ fileId }, { width, height, thumbhash }] = await Promise.all([
        postWithUserErrorException(XRealtimeAuthorResolveImageUrl, data),
        getImageMetadata(url),
      ])

      return {
        type: 'file',
        file: fileId,
        width,
        height,
        thumbHashBase64: thumbhash,
      }
    },
    [postWithUserErrorException]
  )

  const generateImagesFromQuery = useCallback(async (): Promise<void> => {
    if (textValue.length === 0) return

    setIsLoading(true)

    const promptStyle = Object.keys(styles)[selectedStyle]

    const { urls } = await postWithUserErrorException(XRealtimeAuthorGenerateImages, {
      prompt: textValue,
      nImages: 3,
      style: promptStyle === undefined ? 'sanaDefault' : promptStyle,
    })
    if (urls.length === 0) {
      notifications.push({
        type: 'custom',
        level: 'error',
        body: 'Could not generate images, try again with another description...',
      })
    }

    setImages(prev => [...urls, ...prev])

    setIsLoading(false)
  }, [textValue, setIsLoading, selectedStyle, postWithUserErrorException, setImages, notifications])

  const handleGenerateImages = useCallback(async () => {
    void generateImagesFromQuery()
  }, [generateImagesFromQuery])

  const getGeneratedImages = useCallback(async (): Promise<void> => {
    const { result } = await postWithUserErrorException(XRealtimeAuthorGetGeneratedImages, {})

    const urls = result.map(item => {
      return item.url
    })
    setImages(urls)
  }, [postWithUserErrorException, setImages])

  useEffect(() => {
    void getGeneratedImages()
  }, [getGeneratedImages])

  useEffect(() => {
    localStorage.setItem('generationStyle', selectedStyle.toString())
  }, [selectedStyle])

  const onClickImage = useCallback(
    async (url: string): Promise<void> => {
      const image = await uploadUrlToStorage(url, assetContext)
      onImageUploaded(image)
    },
    [uploadUrlToStorage, assetContext, onImageUploaded]
  )

  return (
    <Container>
      <InputContainer>
        <View direction='column' grow>
          <InputPrimitive
            id='generate-image'
            autoFocus
            placeholder={t('author.slate.describe-image')}
            autoComplete='off'
            type='text'
            value={textValue}
            onChange={handleTextChange}
            onKeyDown={async e => {
              if (e.key === 'Enter') {
                void handleGenerateImages()
              }
            }}
          />
        </View>

        <Popover
          isOpen={showStyles}
          onOpenChange={setShowstyles}
          renderTrigger={() => (
            <DefaultDropdownTrigger open={showStyles} grow={false}>
              {Object.values(styles)[selectedStyle]}
            </DefaultDropdownTrigger>
          )}
        >
          <ImageStylesPopup
            selectedStyle={selectedStyle}
            setSelectedStyle={style => {
              setSelectedStyle(style)
              setShowstyles(false)
            }}
          />
        </Popover>

        <View>
          <Button
            variant='primary'
            icon='glitter'
            disabled={isLoading === true}
            onClick={() => {
              if (textValue.length !== 0) {
                setShowstyles(false)
                void handleGenerateImages()
              }
            }}
          >
            {t('author.slate.generate')}
          </Button>
        </View>
      </InputContainer>
      {images.length === 0 && isLoading === false && (
        <NoImagesPlaceholder direction='column' alignItems='center' justifyContent='center'>
          <Text color='foreground/muted' size='large' bold>
            {t('author.slate.generate-image')}
          </Text>

          <View marginBottom='xsmall'>
            <MetaData color='foreground/muted' size='small' align='center'></MetaData>
          </View>
        </NoImagesPlaceholder>
      )}
      <ImageWrapper>
        {isLoading === true && (
          <>
            <ImageContainer>
              <SkeletonWrapper>
                <Skeleton $width={'100%'} $height={'100%'} />
              </SkeletonWrapper>
            </ImageContainer>
            <ImageContainer>
              <SkeletonWrapper>
                <Skeleton $width={'100%'} $height={'100%'} />
              </SkeletonWrapper>
            </ImageContainer>
            <ImageContainer>
              <SkeletonWrapper>
                <Skeleton $width={'100%'} $height={'100%'} />
              </SkeletonWrapper>
            </ImageContainer>
          </>
        )}

        {images.length > 0 && (
          <>
            {images.map(url => (
              <ImageContainer key={url + 'image-container'}>
                <StyledImage loading='lazy' key={url} src={url} onClick={() => onClickImage(url)} />
              </ImageContainer>
            ))}
          </>
        )}
      </ImageWrapper>
    </Container>
  )
}
