import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ApplyProgramChangesToAllExistingLearnersMutation,
  UpdateProgramStepsMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { GridArea, ProgramAdminView } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PROGRAM_QUERY_KEY_PREFIX } from 'sierra-client/views/manage/programs/hooks/use-program-details'
import { EmptyOutline } from 'sierra-client/views/manage/programs/staggered-assignments/empty-outline'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import { EditOutlinePanel } from 'sierra-client/views/manage/programs/staggered-assignments/panels/edit-outline-panel'
import {
  fetchProgramOutline,
  useInvalidateFetchProgramOutline,
} from 'sierra-client/views/manage/programs/staggered-assignments/queries/fetch-program-outline'
import { StaggeredAssignmentsMode } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import {
  SerializedOutline,
  deserializeOutline,
  getOnlyInProgressUsers,
  serializeOutline,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const updateProgramOutline = async (
  programId: string,
  programOutline: SerializedOutline
): Promise<UpdateProgramStepsMutation> => {
  return graphQuery(
    graphql(`
      mutation updateProgramSteps(
        $programId: ProgramId!
        $programSteps: [InputProgramStepInput!]!
        $programSections: [InputProgramSectionInput!]!
      ) {
        updateProgramSteps(
          programId: $programId
          programSteps: $programSteps
          programSections: $programSections
        ) {
          sections {
            id
            title
            description
          }
          steps {
            schedule {
              __typename
              ... on AbsoluteDate {
                date
              }
              ... on RelativeDate {
                offset {
                  value
                  ... on Days {
                    __typename
                  }
                }
              }
              ... on OnCompletionOfPrevious {
                offset {
                  value
                  ... on Days {
                    __typename
                  }
                }
              }
              ... on Directly {
                isDirectly
              }
            }
            ... on EmailProgramStep {
              __typename
              title
              type
              resourceId
              emailTemplateBindingsId
              sectionId
              sectionIndex
              image {
                ...ImageFragment
              }
            }
            ... on CourseProgramStep {
              __typename
              type
              sectionId
              sectionIndex
              dueDate {
                ... on Absolute {
                  __typename
                  date
                }
                ... on Relative {
                  __typename
                  days
                }
              }
              autoAssignLiveSession
              courseId
              course {
                courseKind
                image {
                  ...ImageFragment
                }
                title
              }
              enableSelfEnrollment
            }
            ... on PathProgramStep {
              __typename
              type
              sectionId
              sectionIndex
              dueDate {
                ... on Absolute {
                  __typename
                  date
                }
                ... on Relative {
                  __typename
                  days
                }
              }
              autoAssignLiveSession
              enableSelfEnrollment
              pathId
              path {
                title
                image {
                  ...ImageFragment
                }
              }
            }
          }
          id
          name
          version
          description
          stepsCount
        }
      }
    `),
    {
      programId,
      programSteps: programOutline.steps,
      programSections: programOutline.sections,
    }
  )
}

const updateProgramOutlineToAllLearners = async (
  programId: string,
  onlyInProgressUsers: boolean,
  programOutline: SerializedOutline
): Promise<ApplyProgramChangesToAllExistingLearnersMutation> => {
  return graphQuery(
    graphql(`
      mutation applyProgramChangesToAllExistingLearners(
        $programId: ProgramId!
        $onlyInProgressUsers: Boolean!
        $programSteps: [InputProgramStepInput!]!
        $programSections: [InputProgramSectionInput!]!
      ) {
        applyProgramChangesToAllExistingLearners(
          programId: $programId
          onlyInProgressUsers: $onlyInProgressUsers
          programSteps: $programSteps
          programSections: $programSections
        ) {
          sections {
            id
            title
            description
          }
          steps {
            schedule {
              __typename
              ... on AbsoluteDate {
                date
              }
              ... on RelativeDate {
                offset {
                  value
                  ... on Days {
                    __typename
                  }
                }
              }
              ... on OnCompletionOfPrevious {
                offset {
                  value
                  ... on Days {
                    __typename
                  }
                }
              }
              ... on Directly {
                isDirectly
              }
            }
            ... on EmailProgramStep {
              __typename
              title
              type
              sectionId
              sectionIndex
              image {
                ...ImageFragment
              }
              resourceId
              emailTemplateBindingsId
            }
            ... on CourseProgramStep {
              __typename
              type
              sectionId
              sectionIndex
              dueDate {
                ... on Absolute {
                  __typename
                  date
                }
                ... on Relative {
                  __typename
                  days
                }
              }
              autoAssignLiveSession
              courseId
              course {
                courseKind
                image {
                  ...ImageFragment
                }
                title
              }
              enableSelfEnrollment
            }
            ... on PathProgramStep {
              __typename
              type
              sectionId
              sectionIndex
              dueDate {
                ... on Absolute {
                  __typename
                  date
                }
                ... on Relative {
                  __typename
                  days
                }
              }
              autoAssignLiveSession
              enableSelfEnrollment
              pathId
              path {
                title
                image {
                  ...ImageFragment
                }
              }
            }
          }
          id
          name
          version
          description
          stepsCount
        }
      }
    `),
    {
      programId,
      onlyInProgressUsers,
      programSteps: programOutline.steps,
      programSections: programOutline.sections,
    }
  )
}

const ReserveSpace = styled(View)`
  width: 100%;
  height: 500px;
`

/**
 * @deprecated Use the hook defined in the program feature instead
 */
const useProgramOutline = ({
  programId,
}: {
  programId: string
}): {
  programOutline: ProgramOutline
  setProgramOutline: (outline: ProgramOutline) => void
  enrollmentCount: number
  initialOutlineLoading: boolean
} => {
  const [programOutline, setProgramOutline] = useState<ProgramOutline>({
    steps: [],
    sections: [],
  })
  const [enrollmentCount, setEnrollmentCount] = useState(0)
  const [initialOutlineLoading, setInitialOutlineLoading] = useState(false)

  useEffect(() => {
    void (async () => {
      setInitialOutlineLoading(true)
      const graphQLOutline = await fetchProgramOutline({ programId })
      setInitialOutlineLoading(false)
      setEnrollmentCount(graphQLOutline.program?.enrollmentCount ?? 0)
      setProgramOutline(deserializeOutline(graphQLOutline.program))
    })()
  }, [programId])

  return {
    programOutline,
    setProgramOutline,
    enrollmentCount,
    initialOutlineLoading,
  }
}

const Headers = styled.div`
  display: grid;
  grid-template-columns: 32px 2fr 1fr 1fr;
  grid-template-areas: 'step step available due';
`

export const ProgramContentSection: React.FC<{
  programId: ProgramId
  canEdit: boolean
  canChangeUserVersion: boolean
}> = ({ programId, canEdit, canChangeUserVersion }) => {
  const { t } = useTranslation()
  const { programOutline, setProgramOutline, enrollmentCount, initialOutlineLoading } = useProgramOutline({
    programId,
  })

  const queryClient = useQueryClient()
  const invalidateProgramOutline = useInvalidateFetchProgramOutline({ programId })

  const {
    setPanels: {
      editOutline: { on: editOutlineOn, off: editOutlineOff },
      addContent: { off: addStepOff },
    },
    setIsSavingOutline,
  } = useOutlineEdit()

  const onSaveOutline = useCallback(
    (outline: ProgramOutline, mode: StaggeredAssignmentsMode) => {
      const serializedOutline = serializeOutline(outline)

      void (async () => {
        let newOutline: UpdateProgramStepsMutation['updateProgramSteps'] | undefined

        setIsSavingOutline(true)

        if (mode === 'new') {
          newOutline = (await updateProgramOutline(programId, serializedOutline)).updateProgramSteps
        } else {
          newOutline = (
            await updateProgramOutlineToAllLearners(
              programId,
              getOnlyInProgressUsers(mode),
              serializedOutline
            )
          ).applyProgramChangesToAllExistingLearners
        }

        void queryClient.invalidateQueries({ queryKey: PROGRAM_QUERY_KEY_PREFIX })
        void invalidateProgramOutline()

        setIsSavingOutline(false)

        setProgramOutline(deserializeOutline(newOutline))
        editOutlineOff()
        addStepOff()
      })()
    },
    [
      setIsSavingOutline,
      queryClient,
      setProgramOutline,
      editOutlineOff,
      addStepOff,
      programId,
      invalidateProgramOutline,
    ]
  )

  return (
    <>
      <View marginTop='32' marginBottom='32' direction='column'>
        <View marginBottom='none' direction='row' justifyContent='space-between' alignItems='flex-start'>
          <Text size='large' bold>
            {t('manage.program.program-details.outline.program-outline-heading')}
          </Text>

          {canEdit && programOutline.steps.length > 0 && (
            <Button
              variant='primary'
              onClick={() => {
                editOutlineOn()
                addStepOff()
              }}
            >
              {t('manage.program.program-details.outline.edit-outline-button')}
            </Button>
          )}
        </View>

        <Headers>
          <GridArea area='step'>
            <Text bold>Step</Text>
          </GridArea>
          <GridArea area='available'>
            <Text bold>Made available</Text>
          </GridArea>

          <GridArea area='due'>
            <Text bold>Due</Text>
          </GridArea>
        </Headers>

        {initialOutlineLoading ? (
          <ReserveSpace />
        ) : programOutline.steps.length === 0 ? (
          <EmptyOutline canEdit={canEdit} />
        ) : (
          <ProgramAdminView programId={programId} />
        )}
      </View>
      {canEdit && (
        <EditOutlinePanel
          initialOutline={programOutline}
          canChangeUserVersion={canChangeUserVersion}
          onSave={onSaveOutline}
          enrollmentCount={enrollmentCount}
          programId={programId}
        />
      )}
    </>
  )
}
