import {
  prefetchImageUrl,
  resolvePlaceholderBackgroundImageUrl,
} from 'sierra-client/views/flexible-content/card-background'
import { File } from 'sierra-domain/flexible-content/types'

// A helper that will fetch a files background and, if it's an image card it will fetch the image too
// There are no garanties that the image will be cached or for how long, that is up to the browser
// but it works most of the time for most people
export const preloadFileImage = (file: File): void => {
  const background = file.backgroundImage
  const imageData = file.data.type === 'image' ? file.data.image : undefined

  if (background) {
    const imageUrl = resolvePlaceholderBackgroundImageUrl(background)
    if (imageUrl !== undefined) void prefetchImageUrl(imageUrl)
  }

  const imageUrl = resolvePlaceholderBackgroundImageUrl(imageData)
  if (imageUrl !== undefined) void prefetchImageUrl(imageUrl)
}
