import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { useSetLiveSessionStartedMutation } from 'sierra-client/api/hooks/use-live-session'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { PreLobbyRightContent } from 'sierra-client/components/liveV2/pre-lobby-right-content'
import { AGORA_REGIONS } from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'
import { SimulateHardwareErrorDebug } from 'sierra-client/components/liveV2/simulate-hardware-error'
import { config } from 'sierra-client/config/global-config'
import { toLocalDayFormat, toLocalTimeFormat } from 'sierra-client/core/format'
import { Logging } from 'sierra-client/core/logging'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useFormattedTimeToSession } from 'sierra-client/hooks/use-formatted-time-to-session'
import { useMaxParticipantsInLive } from 'sierra-client/hooks/use-max-participants-in-live'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectAllSessionParticipants,
  selectDataHasLoaded,
  selectIsFacilitator,
} from 'sierra-client/state/live-session/selectors'
import { joinCall, muteAudio } from 'sierra-client/state/live/actions'
import { selectAudioState } from 'sierra-client/state/live/selectors'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { LiveSessionBase } from 'sierra-domain/content/session'
import { LightUser } from 'sierra-domain/user'
import { iife } from 'sierra-domain/utils'
import { Label } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const FIFTEEN_MINUTES = 15 * 60 * 1000

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 90%;
  max-width: 1400px;
  margin-inline: auto;
  margin-block: 18px;
`

const SessionInfo = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  gap: 'none',
  justifyContent: 'center',
})``

const VideoWrapper = styled(View).attrs({ direction: 'column' })``

const ContentGrid = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  gap: 40px;
  width: 100%;
  grid-template-rows: auto;

  > ${SessionInfo} {
    grid-row: 2;
  }

  > ${VideoWrapper} {
    grid-row: 1;
  }

  ${minWidth.phone} {
    gap: 60px;
  }

  ${minWidth.tablet} {
    grid-template-columns: 1fr 1fr;

    > ${SessionInfo} {
      grid-row: 1;
      grid-column: 1;
    }

    > ${VideoWrapper} {
      grid-row: 1;
      grid-column: 2;
    }
  }
`

const Title = styled(Heading)`
  max-width: 530px;
  line-height: 1;
`

const TranscriptionInfo = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24 24',
  radius: 'size-16',
})`
  width: 100%;
  max-width: 70ch;
  margin: auto;
  background-color: ${token('surface/soft')};
`

const TranscriptionInfoMobile = styled(Text)`
  max-width: 70ch;
`

const SecondaryText = styled(Text).attrs({ color: 'foreground/secondary' })``

const TranscriptionText = styled(Text).attrs({
  color: 'foreground/muted',
})`
  ${minWidth.tablet} {
    text-align: center;
  }
`

const SessionMetaData = styled.div`
  max-width: 70ch;
  border-top: 1px solid ${p => p.theme.color.grey10};
  padding-top: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
`

const CenteredText = styled(SecondaryText)`
  text-align: center;
`

function getFirstName(user: LightUser): string {
  return user.firstName
}

const UserNames: React.FC<{ uuids: UserId[] }> = ({ uuids }) => {
  const { t } = useTranslation()
  const users = useUsersLegacy(uuids)

  if (users.length === 0) {
    return (
      <Text size='small' color='foreground/muted'>
        {t('live.no-one-else-is-here')}
      </Text>
    )
  } else if (users.length === 1 && users[0] !== undefined) {
    const name = getFirstName(users[0])
    return <SecondaryText size='small'>{t('live.one-person-is-here', { person: name })}</SecondaryText>
  } else if (users.length === 2 && users[0] !== undefined && users[1] !== undefined) {
    const firstPerson = getFirstName(users[0])
    const secondPerson = getFirstName(users[1])
    return (
      <SecondaryText size='small'>
        {t('live.two-people-are-here', { person1: firstPerson, person2: secondPerson })}
      </SecondaryText>
    )
  } else if (users.length >= 3 && users[0] !== undefined && users[1] !== undefined) {
    const firstPerson = getFirstName(users[0])
    const secondPerson = getFirstName(users[1])
    return (
      <SecondaryText size='small'>
        {t('live.multiple-people-are-here', {
          person1: firstPerson,
          person2: secondPerson,
          count: users.length - 2,
        })}
      </SecondaryText>
    )
  } else {
    return null
  }
}

const FormatSessionTimestamp = ({ session }: { session: LiveSessionBase }): JSX.Element | null => {
  const { t } = useTranslation()

  if (session.type === 'not-scheduled') {
    return (
      <Heading bold size='h5' color='grey40'>
        {t('dictionary.live-session.not-scheduled')}
      </Heading>
    )
  }
  const start = DateTime.fromISO(session.startTime)
  const end = DateTime.fromISO(session.endTime)

  if (session.allDay) {
    if (start.toJSDate().getDate() === end.toJSDate().getDate()) {
      return (
        <Heading bold size='h5' color='grey40'>
          {toLocalDayFormat(start)}
        </Heading>
      )
    } else
      return (
        <Heading bold size='h5' color='grey40'>
          {toLocalDayFormat(start)} – {toLocalDayFormat(end)}
        </Heading>
      )
  }
  return (
    <Heading bold size='h5' color='grey40'>
      {start.toFormat('MMM d') + ', ' + toLocalTimeFormat(start)} – {toLocalTimeFormat(end)}
    </Heading>
  )
}

const languageJoin = (list: Array<string>): string => {
  if (list.length === 1) {
    return list[0] as string
  } else if (list.length === 2) {
    return `${list[0]} or ${list[1]}`
  } else {
    return `${list.slice(0, -1).join(', ')}, or ${list[list.length - 1]}`
  }
}

const ExternalLink = styled.a`
  text-decoration: underline;
`

const FIVE_SECONDS = 5 * 1000

export const PreLobby: React.FC<{ liveSessionId: ScopedLiveSessionId }> = props => {
  const orgConfig = config.organization
  const { isReady: ready } = useLiveContext()
  const liveSessionDataReady = useSelector(selectDataHasLoaded)
  const [showAutoMutedMessage, setShowAutoMutedMessage] = useState<boolean>(false)
  const liveSessionParticipants = useSelector(selectAllSessionParticipants)
  const audioState = useSelector(selectAudioState)
  const liveSession = useLiveSessionContext()
  const isFacilitator = useSelector(selectIsFacilitator)
  const { formattedTime, msRemaining } = useFormattedTimeToSession() ?? {}
  const startSessionMutation = useSetLiveSessionStartedMutation()
  const timeoutAfterSessionStart = useResetBooleanAfterDelay({ delay: FIVE_SECONDS })
  const [joinState, setJoinState] = useState<'Join' | 'Joining'>('Join')
  const [secondsSinceJoin, setSecondsSinceJoin] = useState<number>(0)
  const { t } = useTranslation()
  const maxParticipants = useMaxParticipantsInLive()

  const participantUuids = useMemo(
    () => liveSessionParticipants.map(participant => participant.userId),
    [liveSessionParticipants]
  )

  const { push } = useNotif()
  const dispatch = useDispatch()
  const moreThanTwoParticipants = liveSessionParticipants.length > 2

  // If the user has manually changed their audio state
  // or if they've alreayd been auto muted once
  // we don't want to auto mute them
  const disableAutoMute = useRef<boolean>(false)

  useEffect(() => {
    if (disableAutoMute.current) return

    if (ready && moreThanTwoParticipants && audioState === 'on') {
      disableAutoMute.current = true
      setShowAutoMutedMessage(true)
      void (async () => {
        await dispatch(muteAudio())
      })()
    }
  }, [ready, moreThanTwoParticipants, audioState, dispatch])

  const startJoin = async (): Promise<void> => {
    setJoinState('Joining')
    const interval = setInterval(() => {
      setSecondsSinceJoin(seconds => seconds + 1)
    }, 1000)
    const channelId = ScopedLiveSessionId.extractId(props.liveSessionId)

    try {
      await dispatch(joinCall({ channelId })).unwrap()
      clearInterval(interval)
      setSecondsSinceJoin(0)
      await dispatch(
        Logging.liveSession.liveSessionJoined({
          liveSessionId: liveSession.liveSessionId,
          sessionTitle: liveSession.data.title,
        })
      )
    } catch (error) {
      clearInterval(interval)
      setSecondsSinceJoin(0)
      push({ type: 'error', body: 'Could not join video call' })
      errorLogger.captureError(error)
      setJoinState('Join')
    }
  }

  const processingCountries = orgConfig.live?.geofenceAreas
    ? languageJoin(orgConfig.live.geofenceAreas.map(area => AGORA_REGIONS[area]))
    : undefined

  const isSessionFull = useMemo(
    () => liveSessionParticipants.length >= maxParticipants,
    [liveSessionParticipants.length, maxParticipants]
  )

  const sessionHasStarted = liveSession.data.startedAt !== undefined
  const sessionHasEnded = liveSession.data.endedAt !== undefined

  const sessionIsOngoing = sessionHasStarted && !sessionHasEnded

  const sessionShouldHaveStarted = msRemaining !== undefined && msRemaining <= 0

  const sessionWillStartSoon = msRemaining === undefined || msRemaining < FIFTEEN_MINUTES

  const shouldShowStartButton =
    !sessionHasStarted && (isFacilitator || (liveSession.data.learnerLedSession && sessionWillStartSoon))

  const disableJoin = !liveSessionDataReady || !ready || joinState === 'Joining' || isSessionFull

  return (
    <Wrapper>
      <SimulateHardwareErrorDebug />

      <ContentGrid>
        <SessionInfo>
          <FormatSessionTimestamp session={liveSession.data} />
          <Spacer size='32' />
          <Title size='h3' bold avoidHanging={false} align='center'>
            {liveSession.data.title}
          </Title>
          <Spacer size='16' />
          <View direction='column' alignItems='center' gap='none'>
            {iife(() => {
              if (!sessionHasStarted || timeoutAfterSessionStart.isEnabled) {
                if (sessionShouldHaveStarted) {
                  return (
                    <SecondaryText size='regular'>
                      {t('sana-now.pre-lobby.meeting-should-have-started')}
                    </SecondaryText>
                  )
                }

                return (
                  <>
                    <SecondaryText size='regular'>
                      <Trans
                        i18nKey='sana-now.pre-lobby.meeting-starts-in-time'
                        values={{ title: liveSession.data.title, time: formattedTime }}
                        components={{
                          bold: <strong />,
                        }}
                      />
                    </SecondaryText>
                    <SecondaryText align='center'>
                      {iife(() => {
                        if (liveSession.data.learnerLedSession === true && !sessionWillStartSoon) {
                          return t('sana-now.pre-lobby.learner-led-start-explanation')
                        } else t('sana-now.pre-lobby.meeting-starts-in-time--subtitle')
                      })}
                    </SecondaryText>
                  </>
                )
              }
            })}

            {sessionIsOngoing && !timeoutAfterSessionStart.isEnabled && (
              <UserNames uuids={participantUuids} />
            )}

            {iife(() => {
              if (shouldShowStartButton || timeoutAfterSessionStart.isEnabled) {
                return (
                  <>
                    <Spacer size='16' />
                    <Button
                      variant={'success'}
                      onClick={async () => {
                        timeoutAfterSessionStart.setTrue()
                        startSessionMutation.mutate(
                          { liveSessionId: liveSession.liveSessionId },
                          { onSuccess: startJoin }
                        )
                      }}
                      loading={startSessionMutation.isPending || joinState === 'Joining'}
                      disabled={!liveSessionDataReady || !ready}
                    >
                      {t('sana-now.pre-lobby.start-and-join')}
                    </Button>
                  </>
                )
              }

              if (sessionIsOngoing) {
                return (
                  <>
                    <Spacer size='16' />
                    <Button
                      data-pfcid={!disableJoin ? 'join-button' : null}
                      variant='primary'
                      onClick={startJoin}
                      disabled={disableJoin}
                      loading={joinState === 'Joining'}
                    >
                      {isSessionFull
                        ? t('live.session-full')
                        : joinState === 'Joining'
                          ? t('live.joining')
                          : t('live.join')}
                    </Button>
                  </>
                )
              }
            })}
          </View>

          {secondsSinceJoin >= 10 && <CenteredText size='small'>{t('live.joining-takes-long')}</CenteredText>}
          {liveSession.data.learnerLedSession === true && (
            <SessionMetaData>
              <Text size='micro' color={'grey65'}>
                {t('live.learner-led-session-info')}
              </Text>
            </SessionMetaData>
          )}
          {!isDesktop && liveSession.data.transcribeSession === true && (
            <TranscriptionInfoMobile size='micro' color='grey65' align='center'>
              <Trans
                i18nKey='live.session-transcribed-info'
                components={{
                  link1: (
                    <ExternalLink
                      href='https://www.sanalabs.com/download/legal/Privacy-Notice.pdf'
                      target='_blank'
                    />
                  ),
                }}
              />
            </TranscriptionInfoMobile>
          )}

          {!isDesktop && (
            <View direction='column' margin='small none' gap='2' animated layout>
              <CenteredText size='regular' bold>
                {t('live.rejoin-from-desktop')}
              </CenteredText>
              {!isMobile && (
                <CenteredText size='small'>{t('live.mobile-experience-coming-soon')}</CenteredText>
              )}
            </View>
          )}
          <View direction='column' gap='medium' animated layout marginTop='24'>
            {isSessionFull && <CenteredText size='micro'>{t('live.session-all-spots-taken')}</CenteredText>}
            {showAutoMutedMessage && <CenteredText size='micro'>{t('live.auto-muted')}</CenteredText>}
            {processingCountries !== undefined && (
              <CenteredText size='micro'>
                Your streaming data will be encrypted and transmitted in {processingCountries}
              </CenteredText>
            )}
          </View>
        </SessionInfo>

        <VideoWrapper>
          <PreLobbyRightContent
            setShowAutoMutedMessage={setShowAutoMutedMessage}
            disableAutoMute={disableAutoMute}
          />
        </VideoWrapper>
      </ContentGrid>

      {isDesktop && liveSession.data.transcribeSession === true && (
        <TranscriptionInfo>
          <View>
            <SecondaryText size='small' bold>
              {t('live.transcribe-session-active')}
            </SecondaryText>
            <Label $bgColor='grey10' $color='grey90' $size='small' bold>
              Beta
            </Label>
          </View>

          <TranscriptionText size='small'>
            <Trans
              i18nKey='live.session-transcribed-info'
              components={{
                link1: (
                  <ExternalLink
                    href='https://www.sanalabs.com/download/legal/Privacy-Notice.pdf'
                    target='_blank'
                  />
                ),
              }}
            />
          </TranscriptionText>
        </TranscriptionInfo>
      )}
    </Wrapper>
  )
}
