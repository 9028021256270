import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  addPdfAnnotation,
  deletePdfAnnotation,
  magicWand,
  reorderAnnotation,
  setActiveAnnotationId,
  setActiveAnnotationType,
  setFilename,
  setPdfId,
  setPrecomputedPdfAnnotations,
  undoMagicWand,
  updatePdfAnnotation,
} from 'sierra-client/state/pdf-annotation/actions'
import { userAnnotationAdapter } from 'sierra-client/state/pdf-annotation/adapters'
import {
  annotationEntitiesSelectors,
  precomputedImageAnnotationSelectors,
  precomputedTokenAnnotationSelectors,
} from 'sierra-client/state/pdf-annotation/selectors'
import { ParagraphAndImageAnnotation, PdfAnnotationState } from 'sierra-client/state/pdf-annotation/types'
import { PdfAnnotationToken, userAnnotationsFromServer } from 'sierra-domain/api/author-v2'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { isDefined } from 'sierra-domain/utils'

const textTypes = ['title', 'h1', 'h2', 'h3', 'paragraph'] as const
type TextTypes = (typeof textTypes)[number]

export const pdfAnnotationSlice = createSlice({
  name: 'pdf-annotation',
  initialState: {
    data: { type: 'loading' },
    ui: { showChangeType: false, activeAnnotationType: 'title' },
    settings: { clip: true },
  } as PdfAnnotationState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setPdfId, (state, { payload: { pdfId } }) => {
      state.documentId = pdfId
      state.data = { type: 'loading' }
    })

    builder.addCase(setFilename, (state, { payload: { filename } }) => {
      state.filename = filename
    })

    builder.addCase(setPrecomputedPdfAnnotations, (state, { payload }) => {
      state.data = {
        type: 'loaded',
        precomputedAnnotationsByPage: _.zip(payload.pdfAnnotationToken, payload.pdfAnnotationImage).map(
          ([pageTokens, pageImages]) => [...pageTokens!.map(it => it.id), ...pageImages!.map(it => it.id)]
        ),
        precomputedTokenAnnotationMap: {
          ids: payload.pdfAnnotationToken.flatMap(it => it).map(it => it.id),
          entities: _.keyBy(
            payload.pdfAnnotationToken.flatMap(it => it),
            it => it.id
          ),
        },
        precomputedImageAnnotationMap: {
          ids: payload.pdfAnnotationImage.flatMap(it => it).map(it => it.id),
          entities: _.keyBy(
            payload.pdfAnnotationImage.flatMap(it => it),
            it => it.id
          ),
        },
        fonts: payload.fonts,
        annotationMap: userAnnotationAdapter.addMany(
          userAnnotationAdapter.getInitialState(),
          payload.userAnnotations.flat().map((annotation: userAnnotationsFromServer) => {
            return annotation as ParagraphAndImageAnnotation
          })
        ),
        annotationsByPage:
          payload.userAnnotations.length > 0
            ? payload.userAnnotations.map((annotation: userAnnotationsFromServer[]) => {
                return annotation.map((item: userAnnotationsFromServer) => {
                  return item.id
                })
              })
            : payload.pdfAnnotationToken.map(() => []),
      }
      /*   annotationAdapter.upsertMany(
        state.data.annotationMap,
        payload.userAnnotations.flat().map((annotation: userAnnotationsFromServer) => {
          return annotation as ParagraphAndImageAnnotation
        })
      )*/
    })

    builder.addCase(addPdfAnnotation, (state, { payload }) => {
      if (state.data.type !== 'loaded') return
      userAnnotationAdapter.upsertOne(state.data.annotationMap, payload.annotation)
      state.data.annotationsByPage[payload.pageIndex]?.push(payload.annotation.id)
    })

    builder.addCase(updatePdfAnnotation, (state, { payload }) => {
      if (state.data.type !== 'loaded') return

      userAnnotationAdapter.upsertOne(state.data.annotationMap, payload)
    })

    builder.addCase(deletePdfAnnotation, (state, { payload }) => {
      if (state.data.type !== 'loaded') return

      userAnnotationAdapter.removeOne(state.data.annotationMap, payload.id)

      const index = state.data.annotationsByPage[payload.pageIndex]?.indexOf(payload.id)
      if (index !== undefined && index > -1) {
        state.data.annotationsByPage[payload.pageIndex]?.splice(index, 1)
      }
    })

    builder.addCase(setActiveAnnotationId, (state, { payload }) => {
      state.ui.activeAnnotation = payload.id
    })

    builder.addCase(setActiveAnnotationType, (state, { payload }) => {
      state.ui.activeAnnotationType = payload.type
    })

    builder.addCase(reorderAnnotation, (state, { payload }) => {
      if (state.data.type !== 'loaded') return
      let itemPageIndex = 0
      let itemIndex = 0
      const activeId = state.ui.activeAnnotation as string

      state.data.annotationsByPage.forEach((page, pageI) => {
        page.forEach((id, ind) => {
          if (id === activeId) {
            itemPageIndex = pageI
            itemIndex = ind
          }
        })
      })

      if (payload.key === 'w') {
        if (itemIndex > 0) {
          state.data.annotationsByPage[itemPageIndex]?.splice(itemIndex, 1)
          state.data.annotationsByPage[itemPageIndex]?.splice(itemIndex - 1, 0, activeId)
        }
      } else if (payload.key === 's') {
        if (itemIndex + 1 < state.data.annotationsByPage[itemPageIndex]!.length) {
          state.data.annotationsByPage[itemPageIndex]?.splice(itemIndex, 1)
          state.data.annotationsByPage[itemPageIndex]?.splice(itemIndex + 1, 0, activeId)
        }
      }
    })
    builder.addCase(magicWand, (state, { payload: { includeImages } }) => {
      if (state.data.type !== 'loaded') return
      const data = state.data
      const firstPageAnnotatedByUserIndex = data.annotationsByPage.findIndex(
        pageAnnotations => pageAnnotations.length > 0
      )
      if (firstPageAnnotatedByUserIndex === -1) return
      const annotationDataByPage = _.chain(data.annotationsByPage)
        .filter(it => it.length > 0)
        .map(pageAnnotations =>
          pageAnnotations.map(id => annotationEntitiesSelectors.selectById(data.annotationMap, id)!)
        )
        .value()

      const annotationDataByType = _.chain(annotationDataByPage)
        .flatten()
        .filter(isDefined)
        .groupBy(annotation => annotation.type)
        .value()

      if (annotationDataByType['title'] === undefined) return
      if (
        annotationDataByType['h1'] === undefined &&
        annotationDataByType['h2'] === undefined &&
        annotationDataByType['h3'] === undefined
      )
        return
      if (annotationDataByType['paragraph'] === undefined) return

      const annotationTokensByType = _.mapValues(annotationDataByType, userAnnotations =>
        userAnnotations.map(userAnnotation =>
          userAnnotation.tokenRefs
            .map(tokenId =>
              precomputedTokenAnnotationSelectors.selectById(data.precomputedTokenAnnotationMap, tokenId)
            )
            .filter(isDefined)
        )
      )

      const textTypeToMaxLineHeight = _.chain(annotationTokensByType)
        .mapValues(tokenLists => {
          const distinctOrderedLineYPositions = tokenLists.map(tokens =>
            _.uniq(tokens.map(token => token.yEnd).sort())
          )

          const distinctOrderedLineHeights = distinctOrderedLineYPositions.flatMap(
            distinctOrderedLineYPositionsForBlock =>
              _.zip(
                _.dropRight(distinctOrderedLineYPositionsForBlock, 1),
                _.drop(distinctOrderedLineYPositionsForBlock, 1)
              ).flatMap(([currentLineHeight, nextLineHeight]) =>
                nextLineHeight === undefined || currentLineHeight === undefined
                  ? []
                  : [nextLineHeight - currentLineHeight]
              )
          )

          return _.max(distinctOrderedLineHeights) ?? 0
        })
        .value()

      const textTypeToFonts = _.chain(annotationTokensByType)
        .mapValues(tokenLists => tokenLists.flatMap(tokens => _.uniq(tokens.flatMap(token => token.fontId))))
        .value()

      // start from first annotated page
      _.range(firstPageAnnotatedByUserIndex, data.annotationsByPage.length).map(pageIndex => {
        const pagePrecomputedAnnotationIds = data.precomputedAnnotationsByPage[pageIndex]!
        const pageUserAnnotations = data.annotationsByPage[pageIndex]!.map(tokenId =>
          annotationEntitiesSelectors.selectById(data.annotationMap, tokenId)
        ).filter(isDefined)

        const usedTokens = new Set(pageUserAnnotations.flatMap(it => it.tokenRefs))
        const unusedTokens = pagePrecomputedAnnotationIds.filter(it => !usedTokens.has(it))

        const newAnnotationsForPage = _.chain(unusedTokens)
          .map(tokenId =>
            precomputedTokenAnnotationSelectors.selectById(data.precomputedTokenAnnotationMap, tokenId)
          )
          .filter(isDefined)
          // This is a hack to sort by yEnd then xStart. It uses the fact that sorting is stable.
          .sortBy(it => it.xStart)
          .sortBy(it => it.yEnd)
          .reduce(
            (
              acc: [TextTypes[], PdfAnnotationToken[]][],
              newToken: PdfAnnotationToken
            ): [TextTypes[], PdfAnnotationToken[]][] => {
              const prev = _.dropRight(acc, 1),
                last = _.last(acc)

              const lastHeight =
                last === undefined
                  ? undefined
                  : _.chain(last[1])
                      .map(it => it.yEnd)
                      .max()
                      .value()

              const possibleTypesForCurrentBlock = textTypes.filter(type => {
                const maxLineHeight = textTypeToMaxLineHeight[type]
                const possibleFonts = textTypeToFonts[type]

                const sameOrNewLine =
                  lastHeight === undefined || newToken.yEnd - lastHeight <= (maxLineHeight ?? 0)
                const areFontsCompatible = [...(last?.[1].map(it => it.fontId) ?? []), newToken.fontId].every(
                  fontId => possibleFonts?.includes(fontId)
                )

                return sameOrNewLine && areFontsCompatible
              })

              if (possibleTypesForCurrentBlock.length === 0) {
                const possibleTypesForNewBlock = textTypes.filter(type => {
                  const possibleFonts = textTypeToFonts[type]
                  return possibleFonts?.includes(newToken.fontId)
                })
                if (possibleTypesForNewBlock.length === 0) {
                  return acc
                }
                return [...acc, [possibleTypesForNewBlock, [newToken]]]
              }

              return [...prev, [possibleTypesForCurrentBlock, [...(last?.[1] ?? []), newToken]]]
            },
            []
          )
          .map(([possibleClasses, tokens]) => {
            // We take the "biggest" class.
            const type = _.first(possibleClasses)!

            const coordinates = {
              xStart: _.chain(tokens)
                .map(it => it.xStart)
                .min()
                .value(),
              yStart: _.chain(tokens)
                .map(it => it.yStart)
                .min()
                .value(),
              xEnd: _.chain(tokens)
                .map(it => it.xEnd)
                .max()
                .value(),
              yEnd: _.chain(tokens)
                .map(it => it.yEnd)
                .max()
                .value(),
            }
            const id = nanoid12()
            if (state.data.type !== 'loaded') return
            return {
              id,
              type,
              tokenRefs: tokens.map(it => it.id),
              automated: true,
              ...coordinates,
            }
          })
          .filter(isDefined)
          .value()

        if (state.data.type !== 'loaded') return
        userAnnotationAdapter.upsertMany(state.data.annotationMap, newAnnotationsForPage)

        if (includeImages === true) {
          const pageImages = pagePrecomputedAnnotationIds
            .map(annotationId =>
              precomputedImageAnnotationSelectors.selectById(data.precomputedImageAnnotationMap, annotationId)
            )
            .filter(isDefined)
            .map((it): ParagraphAndImageAnnotation => ({ ...it, automated: true, tokenRefs: [] }))

          userAnnotationAdapter.upsertMany(state.data.annotationMap, pageImages)
          const orderedAnnotationsForPage = _.chain([
            ...pageUserAnnotations,
            ...newAnnotationsForPage,
            ...pageImages,
          ])
            .sortBy(it => it.xStart)
            .sortBy(it => it.yEnd)
            .value()

          state.data.annotationsByPage[pageIndex] = orderedAnnotationsForPage.map(it => it.id)
        } else {
          const orderedAnnotationsForPage = _.chain([...pageUserAnnotations, ...newAnnotationsForPage])
            .sortBy(it => it.xStart)
            .sortBy(it => it.yEnd)
            .value()

          state.data.annotationsByPage[pageIndex] = orderedAnnotationsForPage.map(it => it.id)
        }
      })
    })

    builder.addCase(undoMagicWand, state => {
      if (state.data.type !== 'loaded') return
      const annotationMap = state.data.annotationMap

      state.data.annotationsByPage = state.data.annotationsByPage.map(pageAnnotationRefs => {
        return pageAnnotationRefs.filter(id => {
          const annotation = annotationEntitiesSelectors.selectById(annotationMap, id)
          return annotation?.automated !== true
        })
      })

      userAnnotationAdapter.removeMany(
        state.data.annotationMap,
        Object.entries(annotationMap.entities).flatMap(([id, annotation]) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          annotation?.automated !== true ? [] : [id]
        )
      )
    })
  },
})
