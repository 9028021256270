import { produce } from 'immer'
import { GetUserAttributesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { getValueRepFromDomainChoices } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { CustomAttributesGraphQl } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/types'
import {
  createInvitationAttribute,
  findAttribute,
  findAttributeIndex,
} from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserDetailResponse } from 'sierra-domain/api/manage'
import { ValueRep } from 'sierra-domain/filter/datatype/domain'
import { ValueWithoutExerciseAndFile } from 'sierra-domain/filter/datatype/value'
import { UserInvitationAttributeData } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { UserInvitationDomainRef } from 'sierra-domain/user-attributes/user-invitation-domain-ref'
import {
  UserCustomAttributeDomainRepChoices,
  UserCustomAttributeDomainRepWithParentChoices,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { EmailUserValidateInvitationError } from 'sierra-domain/user-attributes/user-invitation-validation-error'
import { assertNever } from 'sierra-domain/utils'

const mapTypeToValueType = (
  type: CustomAttributesGraphQl[number]['type'],
  value: CustomAttributesGraphQl[number]['parsedValue'],
  rawValue: CustomAttributesGraphQl[number]['rawValue']
): ValueWithoutExerciseAndFile => {
  switch (type) {
    case 'ENUM':
      return {
        type: 'value.string',
        value: value?.__typename !== 'EnumValue' ? rawValue : value.enumValue,
      }
    case 'DATE':
      return { type: 'value.date', value: value?.__typename !== 'DateValue' ? rawValue : value.date }
    case 'INT':
      return {
        type: 'value.integer',
        value: value?.__typename !== 'IntValue' ? parseInt(rawValue) : value.int,
      }
    case 'STRING':
      return {
        type: 'value.string',
        value: value?.__typename !== 'StringValue' ? rawValue : value.string,
      }
    default:
      assertNever(type)
  }
}

const extractCustomAttributes = (customAttributes: CustomAttributesGraphQl): UserInvitationAttribute[] => {
  return customAttributes.map(attribute => {
    const ref = { type: 'user.customAttribute', key: attribute.key }
    const value = mapTypeToValueType(attribute.type, attribute.parsedValue, attribute.rawValue)

    return createInvitationAttribute(ref, [value])
  })
}

const extractRole = (userData: UserDetailResponse): UserInvitationAttribute =>
  createInvitationAttribute({ type: 'user.accessLevel' }, [
    { type: 'value.string', value: userData.accessLevel },
  ])

const extractManager = (userData: UserDetailResponse): UserInvitationAttribute[] => {
  if (userData.manager === undefined) {
    return []
  }

  return [
    createInvitationAttribute({ type: 'user.manager' }, [{ type: 'value.uuid', value: userData.manager.id }]),
  ]
}

export const userDataToInvitationAttributes = (
  userData: UserDetailResponse,
  customAttributesGraphQl: GetUserAttributesQuery
): UserInvitationAttributeData => {
  const customAttributesData = customAttributesGraphQl.user?.customAttributes ?? []
  const customAttributes = extractCustomAttributes(customAttributesData)
  const roleAttribute = extractRole(userData)
  const managerAttribute = extractManager(userData)

  const data: UserInvitationAttribute[] = [...customAttributes, ...managerAttribute, roleAttribute]

  return {
    data,
  }
}

const getValuesFromDraft = (
  userInvitationDomainRef: UserInvitationDomainRef,
  userAttributeData: UserInvitationAttributeData
): ValueWithoutExerciseAndFile[] | undefined => {
  const attribute = findAttribute(userAttributeData.data, userInvitationDomainRef)

  if (attribute !== undefined) {
    return attribute.values
  }

  return undefined
}

export const getValueFromAttributeDataDraft = (
  userInvitationDomainRef: UserInvitationDomainRef,
  userAttributeData: UserInvitationAttributeData
): ValueWithoutExerciseAndFile | undefined => {
  const values = getValuesFromDraft(userInvitationDomainRef, userAttributeData)

  if (values !== undefined) {
    const currentValue = values[0]

    if (currentValue === undefined || currentValue.type === 'value.none') {
      throw new Error('Attribute set but has no value.')
    }

    return currentValue
  }

  return undefined
}

export const getCurrentValueRepFromDomainChoices = (
  domainRep: UserCustomAttributeDomainRepChoices | UserCustomAttributeDomainRepWithParentChoices,
  userAttributeData: UserInvitationAttributeData
): ValueRep | undefined => {
  const currentValueForFirst = getValueFromAttributeDataDraft(domainRep.ref, userAttributeData)

  if (currentValueForFirst === undefined) {
    return undefined
  }

  const valueRep = getValueRepFromDomainChoices(domainRep.domain, valueId(currentValueForFirst), false)
  return valueRep
}

export const addOrReplaceAttributes = (
  userAttributeData: UserInvitationAttributeData,
  newAttributes: UserInvitationAttribute[]
): UserInvitationAttributeData => {
  return produce(userAttributeData, draft => {
    newAttributes.forEach(newAttribute => {
      const oldAttributeIndex = findAttributeIndex(draft.data, newAttribute.ref)

      // Attribute couldn't be found, add
      if (oldAttributeIndex === -1) {
        draft.data.push(newAttribute)
      } else {
        // Attribute found, replace
        draft.data.splice(oldAttributeIndex, 1, newAttribute)
      }
    })
  })
}

export const isEmptyValidationErrors = (errors: EmailUserValidateInvitationError[]): boolean =>
  !(errors.length > 0)
