import { Provider } from 'jotai'
import { useAtomValue } from 'jotai/index'
import { Context, createContext, useContext, useMemo } from 'react'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { TableRowData } from 'sierra-client/lib/tabular/to-table-row-data'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { isDefined } from 'sierra-domain/utils'

export type TableCallbacks<TD extends TableData = TableData> = {
  onRow?: (row: Row.Row<TD>) => void
  onScroll?: (ev: { scrollHeight: number; scrollTop: number; clientHeight: number }) => void
}
export type TabularContextType<TD extends TableData = TableData> = {
  api: TableAPI<TD>
  headers: Array<Header>
  pages: Array<TableRowData<TD>>
  selection: Selection
  sorting: Array<Sorting>
  dataloaderState: 'init' | 'more' | 'done'

  callbacks?: TableCallbacks<TD>
}
export const TabularContext = createContext<TabularContextType | null>(null)

type TabularProviderProps<Data extends TableData, Meta> = {
  tableAPI: UseTableAPI<Data, Meta>
  callbacks?: TableCallbacks<Data>
  children: React.ReactNode
}
export const _TabularProviderFromTableAPI = <Data extends TableData, Meta>({
  tableAPI,
  children,
  callbacks,
}: TabularProviderProps<Data, Meta>): JSX.Element => {
  const rows = useAtomValue(tableAPI.rowsAtom)
  const headers = useAtomValue(tableAPI.headersAtom)
  const selection = useAtomValue(tableAPI.api.atoms.selection)
  const sorting = useAtomValue(tableAPI.api.atoms.sorting)
  const dataloaderState = useAtomValue(tableAPI.api.atoms.loaderState)
  const context = useMemo<TabularContextType<Data>>(
    () => ({
      api: tableAPI.api,
      headers,
      pages: rows,
      selection,
      sorting,
      dataloaderState,
      callbacks,
    }),
    [tableAPI.api, headers, rows, selection, sorting, dataloaderState, callbacks]
  )
  const Context = TabularContext as Context<TabularContextType<Data> | null>
  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const TabularProviderFromTableAPI = <Data extends TableData, Meta>({
  tableAPI,
  children,
  callbacks,
}: TabularProviderProps<Data, Meta>): JSX.Element => (
  <Provider store={tableAPI.store}>
    <_TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={callbacks}>
      {children}
    </_TabularProviderFromTableAPI>
  </Provider>
)

export const useTabularContext = (): TabularContextType => {
  const c = useContext<TabularContextType | null>(TabularContext)
  if (!isDefined(c)) {
    throw new Error(
      'useTabularContext must be used within a TabularContext.Provider. Make sure to set value in provider'
    )
  }
  return c
}
