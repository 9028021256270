import { useRouter } from '@tanstack/react-router'
import React from 'react'
import { getFallbackFileId } from 'sierra-client/views/flexible-content/navigate-to-default-file'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { isDefined } from 'sierra-domain/utils'
import { ReplayUpdatesContext } from 'sierra-domain/version-history/replay-updates'

export const NavigateToDefaultFile: React.FC<{
  contentId: CreateContentId
  mode: ContentType
  currentYDoc: ReplayUpdatesContext['currentYDoc']
}> = ({ mode, contentId, currentYDoc }) => {
  const router = useRouter()
  const fallbackFileId = getFallbackFileId(currentYDoc)

  if (isDefined(fallbackFileId)) {
    const scope = ScopedCreateContentId.urlSubPath(mode)
    void router.navigate({
      to: `/history/${scope}/$`,
      params: { _splat: `${contentId}/${fallbackFileId}` },
      replace: true,
    })
  }

  return null
}
