import { AnimatePresence, motion } from 'framer-motion'
import { PrimitiveAtom, useSetAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import {
  aiNarrationNudgeAccepted,
  aiNarrationNudgeDismissed,
  aiNarrationNudgeShown,
} from 'sierra-client/core/logging/authoring/logger'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  AINarrationControlsState,
  AINarrationNudgeState,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { Layers } from 'sierra-client/views/flexible-content/card-narration/layers'
import { Avatar } from 'sierra-domain/api/author-v2'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations'
import { isSlateFile, safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { textInNodes } from 'sierra-domain/slate-util'
import { asNonEmptyArray, sample } from 'sierra-domain/utils'
import { getSlateDocument, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { useMediaQuery } from 'sierra-ui/utils'
import { useDeepEqualityMemo } from 'sierra-ui/utils/use-deep-equality-memo'
import styled from 'styled-components'
import * as Y from 'yjs'

const SlideInAndFadeOut = styled(motion.div).attrs({
  initial: 'start',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    start: {
      opacity: 1,
      right: '-200px',
      rotate: -30,
    },
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      right: '24px',
      rotate: 4,
      transition: {
        opacity: { duration: 0.5 },
        type: 'spring',
        stiffness: 256,
        damping: 20,
        mass: 1,
        delay: 0.5,
        duration: 0.5,
      },
    },
  },
})`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  transition: rotate 0.1s ease-in-out;

  &:hover {
    rotate: -5deg;
  }
`

const StyledLayersContainer = styled(Layers.Container)`
  position: relative;
  top: 0;
  right: 0;
`

const AbortButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

type NarrationNudgeProps = {
  aiNarrationNudgeStateAtom: PrimitiveAtom<AINarrationNudgeState>
  aiNarrationControlsStateAtom: PrimitiveAtom<AINarrationControlsState>
  contentType: ContentType
  contentId: CreateContentId
  fileId: FileId
  avatars: Avatar[]
}

const CircularIconButton = styled(IconButton)`
  border-radius: 100%;
`

const NarrationNudgeComponent: React.FC<NarrationNudgeProps> = ({
  avatars,
  aiNarrationControlsStateAtom,
  aiNarrationNudgeStateAtom,
  contentType,
  contentId,
  fileId,
}) => {
  const { t } = useTranslation()
  const supportsHover = useMediaQuery('(hover: hover)')
  const dispatch = useDispatch()

  const setAiNarrationControlsState = useSetAtom(aiNarrationControlsStateAtom)
  const setAiNarrationNudgeState = useSetAtom(aiNarrationNudgeStateAtom)

  const setShown = (): void => {
    setAiNarrationNudgeState(prev => ({ ...prev, [contentId]: { shown: true } }))
  }

  const stableAvatars = useDeepEqualityMemo(avatars)
  const randomAvatar: Avatar = useMemo(() => {
    return sample(asNonEmptyArray(stableAvatars))
  }, [stableAvatars])

  useOnMount(() => {
    void dispatch(
      aiNarrationNudgeShown({
        contentId,
        contentType,
        fileId,
        avatarId: randomAvatar.id,
      })
    )
  })

  return (
    <SlideInAndFadeOut>
      <StyledLayersContainer shadow larger={true}>
        <Layers.Layer
          background='white'
          $backgroundImageUrl={getFileContentImage(randomAvatar.thumbnailUrl, {
            bucket: 'sierra-static',
          })}
        />
        <Layers.Layer withFade padding='xxsmall' alignItems='flex-end' justifyContent='center'>
          <View paddingLeft='xxsmall' paddingRight='xxsmall' paddingBottom='8' alignItems='center'>
            <Icon color='white' iconId='user' />
            <TruncatedTextWithTooltip color='white' size='small' bold>
              {t('create.narration.add')}
            </TruncatedTextWithTooltip>
          </View>
        </Layers.Layer>
        <Layers.Layer
          data-hover={supportsHover}
          padding='xxsmall'
          alignItems='flex-start'
          justifyContent='flex-end'
          onClick={() => {
            setAiNarrationControlsState({ type: 'open', preSelectAvatar: randomAvatar.id })
            setShown()

            void dispatch(
              aiNarrationNudgeAccepted({
                contentId,
                contentType,
                fileId,
                avatarId: randomAvatar.id,
              })
            )
          }}
        >
          <AbortButtonContainer>
            <CircularIconButton
              color='white'
              variant='ghost'
              iconId='checkbox--cross'
              onClick={e => {
                e.stopPropagation()
                setShown()

                void dispatch(
                  aiNarrationNudgeDismissed({
                    contentId,
                    contentType,
                    fileId,
                    avatarId: randomAvatar.id,
                  })
                )
              }}
            />
          </AbortButtonContainer>
        </Layers.Layer>
      </StyledLayersContainer>
    </SlideInAndFadeOut>
  )
}

function useFileText(yDoc: Y.Doc, fileId: FileId): string {
  const [text, setText] = useState<string>('')

  const file = useMemo(() => safeGetFile(yDoc, fileId), [fileId, yDoc])

  useEffect(() => {
    if (file === undefined || !isSlateFile(file)) {
      setText('')
      return
    }

    function onDocumentChanged(): void {
      const slateDoc = getSlateDocument(yDoc, fileId)
      setText(textInNodes(slateDoc).join('\n'))
    }

    onDocumentChanged()

    const slateSharedType = getSlateDocumentSharedType(yDoc, file.id)
    slateSharedType.observeDeep(onDocumentChanged)
    return () => slateSharedType.unobserveDeep(onDocumentChanged)
  }, [file, fileId, yDoc])

  return text
}

export const NarrationNudge: React.FC<
  NarrationNudgeProps & { show: boolean; operationState: CreateOperationState }
> = ({ show, operationState, ...props }) => {
  // This is inside of this component to prevent excessive re-renders
  const fileText = useFileText(operationState.yDoc, props.fileId)

  return (
    <AnimatePresence>
      {show && fileText.length > 200 && props.avatars.length > 0 && <NarrationNudgeComponent {...props} />}
    </AnimatePresence>
  )
}
