import React, { useMemo, useRef, useState } from 'react'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { DataViewer } from 'sierra-client/features/insights/display-widgets/data-viewers/data-viewer'
import {
  getMeasuresFromWidget,
  getViewFromWidget,
  getWidgetIcon,
} from 'sierra-client/features/insights/display-widgets/utils'
import { WidgetSubtitle } from 'sierra-client/features/insights/display-widgets/widget-card/widget-subtitle'
import {
  useAllFilterDomainReps,
  useInsightsViewsFromMeasures,
} from 'sierra-client/features/insights/hooks/use-insights-views'
import {
  addDashboardFilterToWidget,
  insightsErrorBoundaryBeforeSend,
} from 'sierra-client/features/insights/utils'
import { useIsInViewport } from 'sierra-client/hooks/use-is-in-viewport'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DashboardWidget, DimensionRef, Widget, areDimensionRefsEqual } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { Padding } from 'sierra-ui/types'
import styled from 'styled-components'
import { EditableHeader } from './editable-header'
import { widgetErrorBoundaryComponent } from './widget-error-component'

const WidgetCardContainer = styled(View)<{ editable: boolean }>`
  border: 1px solid ${token('border/default')};
  border-radius: 16px;
  background-color: ${token('surface/default')};
  position: relative;
  overflow: hidden;
  height: 100%;
  gap: 16px;
  padding-bottom: 20px;
`

const WidgetCardHeaderContainer = styled(View)`
  display: flex;
  justify-content: space-between;
`

interface WidgetCardProps {
  dashboardWidget: DashboardWidget
  onSelect?: (dashboardWidget: DashboardWidget) => void
  onRemove?: (dashboardWidget: DashboardWidget) => void
  onDuplicate?: (dashboardWidget: DashboardWidget) => void
  onTitleChange?: (widgetId: string, newTitle: string, automatic: boolean) => void
  dashboardFilter?: Filter
}

const widgetTypePaddingMap: Record<Widget['type'], Padding> = {
  'widget.aggregation-table': 'small',
  'widget.bar-chart': 'none',
  'widget.line-chart': 'small',
  'widget.metric': 'small',
  'widget.progress-bar': 'small',
  'widget.pivot-table': 'small',
  'widget.list-table': 'small',
}

const WidgetHeadlineContainer = styled(View)`
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  cursor: pointer;

  transition: background-color 0.1s ease-in-out;

  &:hover {
    background: ${token('surface/soft')};
  }
`

const getDimensionsFromFilter = (filter?: Filter): DimensionRef[] => {
  if (filter === undefined) {
    return []
  }
  if (filter.type === 'filter.and' || filter.type === 'filter.or') {
    return filter.filters.flatMap(getDimensionsFromFilter)
  }
  return [DimensionRef.parse(filter.domain)]
}

export const WidgetCard: React.FC<WidgetCardProps> = React.memo(
  ({ dashboardWidget, onSelect, onRemove, onDuplicate, onTitleChange, dashboardFilter }) => {
    const { t } = useTranslation()
    const allFilterDomainReps = useAllFilterDomainReps()
    const isEditable = [onRemove, onDuplicate, onTitleChange].every(it => it !== undefined)

    const ref = useRef<HTMLDivElement>(null)
    const isInViewport = useIsInViewport(ref)

    const { data: viewData, isLoading } = useInsightsViewsFromMeasures(
      getMeasuresFromWidget(dashboardWidget.widget),
      getViewFromWidget(dashboardWidget.widget)
    )

    const [reloadKey, setReloadKey] = useState(0)

    const filterDomains = viewData?.availableFilters

    const dashboardFilterDimensions = getDimensionsFromFilter(dashboardFilter)

    const dashboardFiltersApplicableToWidget = dashboardFilterDimensions.every(
      dimension => filterDomains?.some(domain => areDimensionRefsEqual(dimension, domain.ref))
    )

    // Always return true until we have fetched the view
    const dashboardFilterApplicableOrStillFetching = dashboardFiltersApplicableToWidget || isLoading

    const widgetWithdashboardFilter = useMemo(
      () => addDashboardFilterToWidget(dashboardWidget.widget, allFilterDomainReps, dashboardFilter),
      [allFilterDomainReps, dashboardFilter, dashboardWidget.widget]
    )

    return (
      <WidgetCardContainer direction='column' editable={true} ref={ref}>
        <WidgetCardHeaderContainer>
          {isEditable ? (
            <EditableHeader
              dashboardWidget={dashboardWidget}
              onSelect={onSelect}
              onRemove={onRemove}
              onDuplicate={onDuplicate}
              onTitleChange={onTitleChange}
              dashboardFiltersApplicableToWidget={dashboardFilterApplicableOrStillFetching}
            />
          ) : (
            <WidgetHeadlineContainer direction='column' onClick={() => onSelect?.(dashboardWidget)} grow>
              <View>
                <Icon
                  iconId={
                    dashboardFilterApplicableOrStillFetching
                      ? getWidgetIcon(dashboardWidget.widget.type)
                      : 'warning--filled'
                  }
                  color={
                    dashboardFilterApplicableOrStillFetching ? 'foreground/primary' : 'warning/background'
                  }
                />
                <Text
                  size='regular'
                  bold
                  color={
                    dashboardFilterApplicableOrStillFetching ? 'foreground/primary' : 'warning/background'
                  }
                >
                  {dashboardWidget.title}
                </Text>
              </View>
              <WidgetSubtitle widget={dashboardWidget.widget} />
            </WidgetHeadlineContainer>
          )}
        </WidgetCardHeaderContainer>
        <View
          grow
          direction='column'
          alignItems='stretch'
          padding={`none ${widgetTypePaddingMap[dashboardWidget.widget.type]}`}
        >
          {dashboardFiltersApplicableToWidget ? (
            <StrategicErrorBoundary
              id='insights-widget-card'
              Fallback={widgetErrorBoundaryComponent(() => setReloadKey(key => key + 1))}
              strategies={[]}
              resetKeys={[dashboardWidget, dashboardFilter, reloadKey]}
              beforeSend={error => insightsErrorBoundaryBeforeSend(error, dashboardWidget.widget)}
            >
              {isInViewport && <DataViewer widget={widgetWithdashboardFilter} showToolbar={false} />}
            </StrategicErrorBoundary>
          ) : (
            <View justifyContent='center' direction='column' grow alignItems='center'>
              <Text color='foreground/muted'>
                {t('manage.insights.widget-card.global-filter-not-compatible')}
              </Text>
            </View>
          )}
        </View>
      </WidgetCardContainer>
    )
  }
)
