import { useQuery } from '@tanstack/react-query'
import { FC, useEffect, useMemo } from 'react'
import { useDebouncedAction } from 'sierra-client/hooks/use-debounced-action'
import { Filter } from 'sierra-client/lib/filter'
import { filterQueryStateSerializer } from 'sierra-client/lib/filter/use-filter'
import { fileSupportsShowingResponses } from 'sierra-client/views/flexible-content/data-history-switch/data-history-switch'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useQuestionCardData } from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId, ScopedFileId } from 'sierra-domain/collaboration/types'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import styled from 'styled-components'

export function cohesionTodoT(key: string): string {
  return key
}

const FadeInView = styled(View)`
  animation: ${fadeIn} 0.2s ease-in forwards;
  width: 100%;
`

const filters = (courseId: CourseId, questionId: string, fileId: NanoId12): Filter => ({
  type: 'filter.and',
  filters: [
    {
      type: 'filter.filter',
      domain: {
        type: 'dimension.native.question.question',
      },
      operator: {
        type: 'operator.eq',
      },
      predicate: {
        type: 'predicate.or',
        values: [
          {
            type: 'value.exercise-id',
            courseId: courseId,
            fileId: fileId,
            exerciseId: questionId,
          },
        ],
      },
    },
  ],
})

const getHrefWithParams = (pathname: string, params: URLSearchParams): string => {
  const paramsString = params.toString()

  if (paramsString === '') return pathname
  return `${pathname}?${paramsString}`
}
const _QuestionResponses: FC<{
  questionId: string
}> = ({ questionId }) => {
  const { questionResponsesDataLoader } = useFileContext()
  const { liveDataEventEmitter } = useQuestionCardData()
  const { file, flexibleContentId: courseId } = useFileContext()

  const queryRes = useQuery({
    queryKey: questionResponsesDataLoader.getQueryKey(questionId),
    queryFn: () => questionResponsesDataLoader.load(questionId),
    refetchOnWindowFocus: true,
  })

  const debouncedRefetch = useDebouncedAction<void>(() => queryRes.refetch(), { wait: 1000 })

  useEffect(() => {
    const onData = (): void => {
      void debouncedRefetch()
    }
    liveDataEventEmitter?.on('data', onData)

    return () => {
      liveDataEventEmitter?.off('data', onData)
    }
  }, [debouncedRefetch, liveDataEventEmitter])

  const urlParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    params.set(
      'filter',
      filterQueryStateSerializer.encode(filters(courseId, questionId, ScopedFileId.extractId(file.id)))
    )
    return params
  }, [courseId, file.id, questionId])

  if (queryRes.data === undefined) {
    return null
  }

  return (
    <FadeInView justifyContent='space-between'>
      <View gap='64'>
        <View direction='column' gap='4'>
          <Text size='small' bold color='foreground/muted'>
            {cohesionTodoT('Learners')}
          </Text>
          <View gap='6'>
            <Icon iconId='user' size='size-16' color='foreground/secondary' />
            <Text bold size='regular' color='foreground/secondary'>
              {queryRes.data.totalUsers}
            </Text>
          </View>
        </View>
        <View direction='column' gap='4'>
          <Text size='small' bold color='foreground/muted'>
            {cohesionTodoT('Correct on first try')}
          </Text>
          <View gap='6'>
            <Icon iconId='user' size='size-16' />
            <Text bold size='regular' color='foreground/secondary'>
              {(queryRes.data.correctOnFirstTryRate * 100).toFixed(0)}%
            </Text>
          </View>
        </View>
      </View>
      <View>
        <Button
          icon='launch'
          decoratorPosition='right'
          variant='secondary'
          target='_blank'
          href={getHrefWithParams('/manage/insights/dashboards/templates/question-card', urlParams)}
        >
          View in insights
        </Button>
      </View>
    </FadeInView>
  )
}

export const QuestionResponses: typeof _QuestionResponses = props => {
  const { file, scopedCreateContentId } = useFileContext()
  const isSupportedInCurrentFile = fileSupportsShowingResponses(
    file,
    ScopedCreateContentId.contentType(scopedCreateContentId)
  )

  if (!isSupportedInCurrentFile) {
    return null
  }

  return <_QuestionResponses {...props} />
}
