import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useInView } from 'react-intersection-observer'
import { CellProps, Column, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table'
import { SelectableHeader, SelectableRow, TableBulkActions } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import {
  TableMediumSearchHeaderManual,
  TableMediumSearchTrigger,
} from 'sierra-client/components/table/table-medium'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import * as format from 'sierra-client/core/format'
import { percentage, useLocalizedFormatters } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  ActionButton,
  BetweenContainer,
  InfiniteScrollMessage,
} from 'sierra-client/views/manage/components/common'
import {
  calculateUserDueDateMetadata,
  getDueDateStringForGroup,
  getDueDateStringForUser,
  getSortGroupDueDate,
} from 'sierra-client/views/manage/components/due-date'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { ProgressPill } from 'sierra-client/views/manage/components/progress-pill'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import {
  parseEnrolledGroupToCsv,
  parseEnrolledUserToCsv,
} from 'sierra-client/views/manage/utils/content-enrollment-to-csv'
import { useGetManageProgramURL } from 'sierra-client/views/manage/utils/use-manage-program-url'
import { Separator } from 'sierra-client/views/showcase/common'
import {
  BaseListUsersRequest,
  ContentProgramEnrollment,
  DueDateAbsolute,
  EnrolledUser,
  ListContentEnrolledUsersResponse,
} from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import {
  Icon,
  MenuItem,
  Tooltip,
  TruncatedText,
  UserDisplay,
  getAvatarPropsFromBaseUserInfo,
} from 'sierra-ui/components'
import { MUIBox } from 'sierra-ui/mui'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

export type ContentUsersTableProps<T = ListContentEnrolledUsersResponse> = {
  assignedUsers: ListContentEnrolledUsersResponse & {
    isLoading: boolean
    filtered: boolean
  }
  contentId: string
  fetchUserAssignments: (
    contentId: string,
    filters?: Partial<BaseListUsersRequest>,
    options?: {
      reset?: boolean
      forCsv?: boolean
    }
  ) => Promise<T>
  openEnrollUsers: () => void
  onRemoveUser: (userIds: UserId[]) => void
  hideAnalytics?: boolean // @TODO Elyes Remove when have path analytics
  onSetUserDueDate?: (userId: UserId, currentDueDate?: DueDateAbsolute, origin?: 'group' | 'user') => void
  contentType: 'course' | 'path'
  canEditAssignments: boolean
}

export const ContentUsersTable: React.FC<ContentUsersTableProps> = ({
  assignedUsers,
  contentId,
  fetchUserAssignments,
  openEnrollUsers,
  onRemoveUser,
  onSetUserDueDate,
  contentType,
  canEditAssignments,
}) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const [debouncedFilter, liveFilter, setFilter] = useDebouncedAndLiveState('')
  const [isUserSearchOpen, setIsUserSearchOpen] = useState<boolean>(false)
  const [focusedUserId, setFocusedUserId] = useState<UserId | undefined>(undefined)

  const [userAction, setUserAction] = useState<UserModalActionsProps['action']>({
    modal: undefined,
  })

  const selfStarted = (row: EnrolledUser): boolean => {
    return row.assignedAt === undefined && row.paths.length === 0 && row.programs.length === 0
  }

  const assignedViaText = useCallback(
    (row: EnrolledUser): string => {
      if (row.programs.length === 0 && row.paths.length === 0) {
        return row.assignedAt !== undefined
          ? t('manage.users.assignment-type.individual')
          : t('manage.users.self-started')
      } else if (row.paths.length === 0) {
        return _.compact(row.programs.map(g => g.name)).join(', ')
      } else if (row.programs.length === 0) {
        return _.compact(row.paths.map(g => g.title)).join(', ')
      }
      return (
        _.compact(row.programs.map(g => g.name)).join(', ') +
        ', ' +
        _.compact(row.paths.map(g => g.title)).join(', ')
      )
    },
    [t]
  )

  const columns: Column<EnrolledUser>[] = useMemo(
    () =>
      _.compact([
        {
          id: 'firstName',
          Header: p => {
            return (
              <>
                <SelectableHeader {...p} />
                <SortableHeader label={t('table.name')} smallLabel {...p} />{' '}
              </>
            )
          },
          Cell: (p: CellProps<EnrolledUser>) => (
            <View grow direction='row' justifyContent='flex-start' alignItems='center'>
              <SelectableRow {...p} />
              <UserDisplay
                primaryText={[
                  p.row.original.userInfo.baseUserInfo.firstName,
                  p.row.original.userInfo.baseUserInfo.lastName,
                ].join(' ')}
                avatar={{
                  ...getAvatarPropsFromBaseUserInfo(p.row.original.userInfo.baseUserInfo),
                  size: 'small',
                  src: getAvatarImage(
                    p.row.original.userInfo.baseUserInfo.userId,
                    p.row.original.userInfo.baseUserInfo.avatar
                  ),
                }}
              />
              <Spacer />
            </View>
          ),
          width: '30%',
        },
        {
          Header: t('admin.analytics.table.progress'),
          accessor: original => {
            return t('manage.users.status.n-completed', { n: format.percentage(original.progress) })
          },
          Cell: ({ row }: CellProps<EnrolledUser>) => {
            return <ProgressPill progress={row.original.progress} homeworks={row.original.homeworks} />
          },
          width: '15%',
        },
        {
          Header: t('table.last-activity'),
          accessor: original => (original.lastActivity ? formatTimestamp(original.lastActivity) : ''),
          width: '12%',
        },

        {
          Header: t('table.assigned-via'),
          Cell: ({ row }: CellProps<EnrolledUser>) => {
            return (
              <TruncatedText lines={1} size='small' color='grey50'>
                {assignedViaText(row.original)}
              </TruncatedText>
            )
          },
          width: '15%',
        },
        {
          id: 'dueDate',
          Header: t('due-date.due-date'),
          accessor: item => item.dueDateGroup ?? item.dueDateDirect,
          Cell: ({ row }: CellProps<EnrolledUser>) => (
            <>
              {getDueDateStringForUser({
                t,
                dueDateDirect: row.original.dueDateDirect,
                dueDateGroup: row.original.dueDateGroup,
              }) ?? null}
            </>
          ),
          width: '13%',
        },
        {
          Header: t('table.available-from'),
          Cell: ({ row }: CellProps<EnrolledUser>) => {
            if (isDefined(row.original.assignedAt)) {
              return (
                <TruncatedText lines={1} size='small' color='grey50'>
                  {formatTimestamp(row.original.assignedAt)}
                </TruncatedText>
              )
            } else if (selfStarted(row.original)) {
              return ''
            } else {
              return (
                <Tooltip title={t('table.user-content.tooltip.pending.text')} delayDuration={400}>
                  <View gap='4' cursor='default'>
                    <Text color='foreground/secondary'>{t('table.available-from-pending')}</Text>
                    <Icon iconId='information' color='foreground/secondary' />
                  </View>
                </Tooltip>
              )
            }
          },
          width: '13%',
        },
        {
          id: 'actions',
          Header: <TableMediumSearchTrigger onClick={() => setIsUserSearchOpen(true)} />,
          Cell: ({ row }: CellProps<EnrolledUser>) => {
            const userId = row.original.userInfo.baseUserInfo.userId

            const { dueDateDirect, dueDateGroup } = row.original
            const { dueDate, origin } = calculateUserDueDateMetadata({ dueDateDirect, dueDateGroup })

            const [open, setOpen] = useState(false)

            useEffect(() => {
              if (open) {
                setFocusedUserId(userId)
              } else {
                setFocusedUserId(undefined)
              }
            }, [open, userId])

            const menuItems: MenuItem[] = useMemo(() => {
              return [
                {
                  type: 'label',
                  label: t('manage.view-details'),
                  id: 'view-details',
                  onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${userId}` }),
                  icon: 'user',
                },
                {
                  type: 'label',
                  label: t('due-date.set-due-date'),
                  id: 'set-due-date',
                  onClick: () => {
                    onSetUserDueDate?.(userId, dueDate, origin)
                    setOpen(false)
                  },
                  hidden: onSetUserDueDate === undefined || !canSetContentDueDates,
                  icon: 'time',
                  disabled: row.original.assignedAt === undefined,
                },
                {
                  type: 'label',
                  label: t('manage.complete-progress'),
                  id: 'complete-content',
                  onClick: () => {
                    setUserAction({
                      modal: 'complete-content',
                      userId: userId,
                      contentType: contentType,
                      contentId: contentId,
                    })
                  },
                  icon: 'checkmark--outline',
                  disabled: row.original.progress === 1,
                  hidden: !canSetContentCompletion,
                },
                {
                  type: 'label',
                  label: t('manage.reset-progress'),
                  id: 'reset-content',
                  onClick: () => {
                    setUserAction({
                      modal: 'reset-course',
                      contentType: contentType,
                      contentId: contentId,
                    })
                  },
                  icon: 'reset',
                  disabled: row.original.progress === 0 || row.original.progress === undefined,
                  hidden: !canResetProgress,
                  color: 'destructive/background',
                },
                {
                  type: 'label',
                  label: t('dictionary.unassign'),
                  id: 'unassign',
                  onClick: () => {
                    onRemoveUser([userId])
                    setOpen(false)
                  },
                  icon: 'time',
                  color: 'destructive/background',
                  hidden: !canEditAssignments,
                  disabled:
                    row.original.programs.length !== 0 ||
                    row.original.paths.length !== 0 ||
                    row.original.assignedAt === undefined,
                },
              ]
            }, [
              row.original.progress,
              row.original.programs.length,
              row.original.paths.length,
              row.original.assignedAt,
              userId,
              dueDate,
              origin,
            ])

            return (
              <View justifyContent='flex-end' grow>
                <IconMenu
                  iconId='overflow-menu--horizontal'
                  aria-label='Details'
                  variant='transparent'
                  onSelect={item => {
                    if ('onClick' in item) {
                      item.onClick?.()
                    }
                  }}
                  menuItems={menuItems}
                  isOpen={open}
                  onOpenChange={setOpen}
                />
              </View>
            )
          },
          width: '10%',
        },
      ]),
    [
      t,
      onSetUserDueDate,
      contentType,
      contentId,
      onRemoveUser,
      formatTimestamp,
      assignedViaText,
      canEditAssignments,
      canResetProgress,
      canSetContentCompletion,
      canSetContentDueDates,
    ]
  )

  const userTableInstance = useTable(
    { data: assignedUsers.data, columns, autoResetGlobalFilter: false, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  )

  const selectedUserIds = userTableInstance.selectedFlatRows.map(r => r.original.userInfo.baseUserInfo.userId)
  const isAnyAssignedByProgram = userTableInstance.selectedFlatRows.some(r => r.original.programs.length > 0)
  const isAnyAssignedByPath = userTableInstance.selectedFlatRows.some(r => r.original.paths.length > 0)
  const isAnySelfStarted = userTableInstance.selectedFlatRows.some(r => r.original.assignedAt === undefined)

  useEffect(() => {
    if (!assignedUsers.hasMore || assignedUsers.isLoading || !shouldLoadMore) return
    void fetchUserAssignments(contentId, {
      lastUserId: assignedUsers.data[assignedUsers.data.length - 1]?.userInfo.baseUserInfo.userId,
      query: debouncedFilter,
    })
  }, [
    assignedUsers.data,
    assignedUsers.hasMore,
    assignedUsers.isLoading,
    contentId,
    debouncedFilter,
    fetchUserAssignments,
    shouldLoadMore,
  ])

  useEffect(() => {
    void fetchUserAssignments(contentId, { query: debouncedFilter }, { reset: true })
  }, [contentId, fetchUserAssignments, debouncedFilter])

  return (
    <View direction='column' gap='xsmall'>
      <SmallTableWrapper title={t('admin.analytics.learners')}>
        <SmallTableScrollContainer scrollToTopDeps={[liveFilter]}>
          <Table
            tableInstance={userTableInstance}
            getRowProps={row => ({
              onClick: () => {
                void getGlobalRouter().navigate({
                  to: `/manage/users/${row.original.userInfo.baseUserInfo.userId}`,
                })
              },
              isFocused: row.original.userInfo.baseUserInfo.userId === focusedUserId,
            })}
            headerOverride={
              isUserSearchOpen || userTableInstance.selectedFlatRows.length > 0 ? (
                <TableBulkActions
                  tableInstance={userTableInstance}
                  search={
                    isUserSearchOpen ? (
                      <TableMediumSearchHeaderManual
                        value={liveFilter}
                        onChange={setFilter}
                        searchPlaceholder={t('manage.search.users')}
                        onClose={() => {
                          setIsUserSearchOpen(false)
                          setFilter('')
                        }}
                      />
                    ) : (
                      <TableMediumSearchTrigger onClick={() => setIsUserSearchOpen(true)} />
                    )
                  }
                >
                  <CSVLink
                    data={userTableInstance.selectedFlatRows.map(r => parseEnrolledUserToCsv(r.original))}
                    filename={getCsvFileName(t('admin.organization.users.users'))}
                  >
                    <ActionButton color='blueBright'>
                      {t('manage.export')} {'.csv'}
                    </ActionButton>
                  </CSVLink>
                  {canEditAssignments &&
                    !isAnyAssignedByProgram &&
                    !isAnyAssignedByPath &&
                    !isAnySelfStarted && (
                      <ActionButton color='redBright' onClick={() => onRemoveUser(selectedUserIds)}>
                        {t('dictionary.unassign')}
                      </ActionButton>
                    )}
                </TableBulkActions>
              ) : undefined
            }
            small
          />
          {assignedUsers.hasMore && (
            <InfiniteScrollMessage
              padding='medium'
              ref={inViewRef}
              text={t('manage.users.table-loading')}
              showSanaLogo
            />
          )}
          {userTableInstance.rows.length === 0 && (
            <InfiniteScrollMessage padding='medium' text={t('manage.users.no-results')} />
          )}
        </SmallTableScrollContainer>
        <Separator top='none' bottom='none' />
        <Spacer size='small' />
        <BetweenContainer>
          {canEditAssignments && <Button onClick={openEnrollUsers}>{t('manage.manage-learners')}</Button>}
          <ExportCSVIconButton
            fetchCsvData={async () => {
              const response = await fetchUserAssignments(contentId, { query: liveFilter }, { forCsv: true })
              return response.data.map(parseEnrolledUserToCsv)
            }}
            filename={t('admin.organization.users.users')}
          />
        </BetweenContainer>
      </SmallTableWrapper>
      {focusedUserId !== undefined && (
        <UserModalActions
          targetUserId={focusedUserId}
          action={userAction}
          onClose={() => setUserAction({ modal: undefined })}
          onDone={() => {
            setUserAction({ modal: undefined })
            void fetchUserAssignments(contentId, { query: debouncedFilter }, { reset: true })
          }}
        />
      )}
    </View>
  )
}

export type ContentGroupsTableProps = {
  assignedPrograms: ContentProgramEnrollment[]
  //openEnrollPrograms: () => void
  //onRemoveGroup: (groupIds: string[]) => void //we don't allow removal of programs from content pages atm
}

export const ContentGroupsTable: React.FC<ContentGroupsTableProps> = ({ assignedPrograms }) => {
  const { t } = useTranslation()
  const [focusedGroupId, setFocusedGroupId] = useState<string | undefined>()
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const getManageProgramURL = useGetManageProgramURL()

  const goToGroupDetails = useCallback(
    (groupId: string) => getGlobalRouter().navigate({ to: getManageProgramURL(groupId) }),
    [getManageProgramURL]
  )

  const columns: Column<ContentProgramEnrollment>[] = useMemo(
    () =>
      _.compact([
        {
          id: 'groupName',
          Header: p => {
            return (
              <>
                <SelectableHeader {...p} />
                <SortableHeader label={t('table.name')} smallLabel {...p} />
              </>
            )
          },
          accessor: original => original.groupInfo.groupName,
          Cell: (p: CellProps<ContentProgramEnrollment>) => {
            return (
              <MUIBox display='flex' alignItems='center'>
                <SelectableRow {...p} />
                <Text size='small' bold>
                  {p.row.original.groupInfo.groupName ?? 'N/A'}
                </Text>
              </MUIBox>
            )
          },
          width: '45%',
        },
        {
          id: 'progress',
          Header: p => <SortableHeader label={t('admin.analytics.table.progress')} smallLabel {...p} />,
          accessor: 'progress',
          Cell: ({ row }: CellProps<ContentProgramEnrollment>) => <>{percentage(row.original.progress)}</>,
          width: '15%',
        },
        {
          id: 'adminName',
          Header: p => <SortableHeader label={t('table.admin')} smallLabel {...p} />,
          accessor: item => item.groupInfo.adminName ?? '',
          width: '15%',
        },
        {
          id: 'dueDate',
          Header: p => <SortableHeader label={t('due-date.due-date')} smallLabel {...p} />,
          accessor: item => getSortGroupDueDate(item.dueDate),
          Cell: ({ row }: CellProps<ContentProgramEnrollment>) => (
            <>
              {row.original.dueDate !== undefined
                ? getDueDateStringForGroup({ t, dueDate: row.original.dueDate })
                : null}
            </>
          ),
          width: '15%',
        },
        {
          id: 'actions',
          Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
          Cell: ({ row }: CellProps<ContentProgramEnrollment>) => {
            const [open, setOpen] = useState(false)

            useEffect(() => {
              if (open) {
                setFocusedGroupId(row.original.groupInfo.groupId)
              } else {
                setFocusedGroupId(undefined)
              }
            }, [open, row.original.groupInfo.groupId])

            const menuItems: MenuItem[] = useMemo(() => {
              return [
                {
                  type: 'label',
                  label: t('manage.view-details'),
                  id: 'view-details',
                  onClick: () =>
                    getGlobalRouter().navigate({ to: getManageProgramURL(row.original.groupInfo.groupId) }),
                  icon: 'information',
                },
              ]
            }, [row.original.groupInfo.groupId])

            return (
              <View justifyContent='flex-end' grow>
                <IconMenu
                  iconId='overflow-menu--horizontal'
                  aria-label='Details'
                  variant='transparent'
                  onSelect={item => {
                    if ('onClick' in item) {
                      item.onClick?.()
                    }
                  }}
                  menuItems={menuItems}
                  isOpen={open}
                  onOpenChange={setOpen}
                />
              </View>
            )
          },
          width: '10%',
        },
      ]),
    [t, getManageProgramURL]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: assignedPrograms, columns },
    getEntityId: s => s.groupInfo.groupId,
  })

  return (
    <SmallTableWrapper title={t('admin.organization.tab.programs')}>
      <ManageTableSmall
        tableInstance={tableInstance}
        isLoading={false}
        focusedId={focusedGroupId}
        onViewDetails={goToGroupDetails}
        getEntityId={p => p.groupInfo.groupId}
        mapEntityToCsv={parseEnrolledGroupToCsv}
        isSearchOpen={isSearchOpen}
        searchTrigger={setIsSearchOpen}
        translations={{
          searchPlaceholder: t('manage.search.groups'),
          tableLoading: t('manage.groups.table-loading'),
          tableNoResults: t('manage.groups.no-results'),
          csvPrefix: t('admin.analytics.groups'),
        }}
      />
    </SmallTableWrapper>
  )
}
