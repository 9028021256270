import _, { orderBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import * as format from 'sierra-client/core/format'
import { useHasContentKindPermission, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  LiveSessionMenuItem,
  SelectLiveSession,
} from 'sierra-client/views/manage/courses/components/live-session-components'
import {
  ClearAllFilters,
  LiveSessionAssignedFilter,
  LiveSessionUserGroupFilter,
} from 'sierra-client/views/manage/courses/components/live-session-filters'
import { useManageCourseLiveSessions } from 'sierra-client/views/manage/courses/use-manage-course-live-sessions'
import { useContentGroupAssignmentPanes } from 'sierra-client/views/manage/courses/utils/use-content-group-assignment-panes'
import { formatLiveSessionTimeAsSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { useGroups } from 'sierra-client/views/manage/groups/use-groups'
import { AssignedViaPill } from 'sierra-client/views/manage/users/components/content-assignment-components'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { BaseProgramInfoUser, EnrolledUser, LiveSessionRow } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { BaseUserRowWithType } from 'sierra-domain/user/base-user-row'
import { MenuItem, RoundAvatar, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

type UserTableRow = {
  id: UserId
  userInfo: BaseUserRowWithType
  progress?: number
  programs: BaseProgramInfoUser[]
  assignedAt?: string
  assignedSessions: EnrolledUser['liveSessions']
}

const userTableRowToCsv = (row: UserTableRow): Record<string, string> => ({
  userId: row.id,
  email: row.userInfo.baseUserInfo.email,
  firstName: row.userInfo.baseUserInfo.firstName ?? '',
  lastName: row.userInfo.baseUserInfo.lastName ?? '',
  progress: row.progress?.toFixed(3) ?? '0',
  assignedAt: row.assignedAt !== undefined ? new Date(row.assignedAt).toISOString() : '',
  assignedSessions: row.assignedSessions.map(session => session.startTime).join(', '),
})

type CourseLiveSessionAssignmentTableProps = {
  courseId: CourseId
  onDone?: () => void
}

export const CourseLiveSessionAssignmentTable: React.FC<CourseLiveSessionAssignmentTableProps> = ({
  courseId,
  onDone,
}) => {
  const { t } = useTranslation()
  const { assignWithDueDate } = useDueDate()
  const notifications = useNotif()
  const { groupsData: programsData } = useGroups('program')
  const { groupsData } = useGroups('user')
  const assignmentPanes = useContentGroupAssignmentPanes()

  const {
    allAssignedUsers,
    liveSessions,
    filter,
    setFilter,
    updateUsersRows,
    // fetchUserAssignments,
    fetchLiveSessions,
  } = useManageCourseLiveSessions({ courseId, initialUsersToLoad: 750 }) //
  // TODO(damjan) fix this table so that it is backend paginated instead.

  const [userAction, setUserAction] = useState<UserModalActionsProps['action']>({ modal: undefined })
  const [action, setAction] = useState<{ modal: 'assign' } | undefined>(undefined)
  const [focusedUserId, setFocusedUserId] = useState<UserId | undefined>(undefined)
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canEditAssignments = useHasContentKindPermission(courseId, 'EDIT_ASSIGNMENTS')

  const handleAfterAssignReload = useCallback<(userIds?: UserId[]) => void>(
    userIds => {
      if (userIds) {
        void updateUsersRows(courseId, userIds)
      } else {
        setFilter({
          groups: [],
          programs: [],
          query: '',
          assignedToLiveSession: undefined,
        })
      }
      void fetchLiveSessions(courseId)
      if (onDone) onDone()
    },
    [courseId, fetchLiveSessions, onDone, setFilter, updateUsersRows]
  )

  const handleUpdateUserRow = useCallback<(userId: UserId | undefined) => void>(
    userId => {
      if (userId !== undefined) void updateUsersRows(courseId, [userId])
      if (onDone) onDone()
    },
    [updateUsersRows, courseId, onDone]
  )

  const data = useMemo<UserTableRow[]>(() => {
    return allAssignedUsers.data.map(u => ({
      id: u.userInfo.baseUserInfo.userId,
      userInfo: u.userInfo,
      progress: u.progress,
      programs: u.programs,
      assignedAt: u.assignedAt?.toISOString(),
      assignedSessions: orderBy(u.liveSessions, 'startTime', 'desc'),
    }))
  }, [allAssignedUsers.data])

  const columns = useMemo<Column<UserTableRow>[]>(
    () => [
      {
        id: 'select',
        accessor: 'id',
        Header: p => <SelectableHeader {...p} />,
        Cell: p => <SelectableRow {...p} />,
        width: '5%',
      },
      {
        id: 'name',
        Header: t('table.name'),
        width: '45%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { userInfo } = p.row.original
          return (
            <View gap='small'>
              <RoundAvatar
                {...getAvatarPropsFromBaseUserInfo(userInfo.baseUserInfo)}
                src={getAvatarImage(userInfo.baseUserInfo.userId, userInfo.baseUserInfo.avatar)}
                size='small'
              />
              <View direction='column' gap='none'>
                <Text color='foreground/primary' size='small' bold>
                  {[userInfo.baseUserInfo.firstName, userInfo.baseUserInfo.lastName].join(' ')}
                </Text>
                <Text color='foreground/muted' size='small'>
                  {userInfo.baseUserInfo.email}
                </Text>
              </View>
            </View>
          )
        },
      },
      {
        id: 'liveSessions',
        accessor: 'assignedSessions',
        Header: t('learner-home.live-session'),
        width: '20%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { assignedSessions } = p.row.original
          if (assignedSessions.length === 0) {
            return null
          }

          const assignedIds = assignedSessions.map(ls => ls.liveSessionId)
          const sessionsData = liveSessions.filter(session => assignedIds.includes(session.liveSessionId))

          return (
            <View direction='column'>
              {sessionsData.map(ls => {
                const formatted = formatLiveSessionTimeAsSchedule(scheduledOrNull(ls.data))
                if (formatted === undefined) return null
                return (
                  <Text color='foreground/primary' key={ls.liveSessionId} size='small'>
                    {formatted}
                  </Text>
                )
              })}
            </View>
          )
        },
      },
      {
        id: 'progress',
        accessor: 'progress',
        Header: t('admin.analytics.table.progress'),
        width: '15%',
        Cell: p => {
          const { progress } = p.row.original
          return (
            <>
              {progress === undefined || progress === 0
                ? t('manage.users.status.not-started')
                : t('manage.users.status.n-completed', { n: format.percentage(progress) })}
            </>
          )
        },
      },
      {
        id: 'programs',
        accessor: 'programs',
        Header: t('table.assigned-via'),
        Cell: p => {
          const { assignedAt, programs } = p.row.original

          if (assignedAt === undefined) return null

          return (
            <AssignedViaPill
              assignmentData={{
                assignedAt: assignedAt,
                availableAt: assignedAt,
                type: programs.length === 0 ? 'individual' : 'program',
                programs: programs,
              }}
            />
          )
        },
        width: '15%',
      },
      {
        id: 'actions',
        width: '10%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { id, assignedSessions } = p.row.original

          const assignedLiveSessionRows = liveSessions.filter(ls => {
            const containsLs = assignedSessions.map(als => als.liveSessionId).includes(ls.liveSessionId)
            if (containsLs) return true
            return false
          })

          const [open, setOpen] = useState(false)

          const { fetchLiveSessions } = useManageCourseLiveSessions()
          const [courseLiveSessions, setCourseLiveSessions] = useState<LiveSessionRow[]>([])

          useEffect(() => {
            void (async () => {
              if (!open) return
              // Doesn't re-fetch
              if (courseLiveSessions.length !== 0) return

              const response = await fetchLiveSessions(CourseId.parse(courseId), { skipPastSessions: true })
              setCourseLiveSessions(response)
            })()
          }, [courseLiveSessions.length, open, fetchLiveSessions])

          useEffect(() => {
            if (open) {
              setFocusedUserId(p.row.original.id)
            } else {
              setFocusedUserId(undefined)
            }
          }, [open, id, p.row.original.id])

          const filteredLiveSessionsForEnrollment = courseLiveSessions.filter(ls => {
            const containsLs = assignedSessions.map(als => als.liveSessionId).includes(ls.liveSessionId)
            if (containsLs) return false
            return true
          })

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                label: t('manage.view-details'),
                id: 'view-details',
                onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${id}` }),
                icon: 'user',
              },
              {
                type: 'label',
                label: t('manage.complete-progress'),
                id: 'complete-content',
                onClick: () =>
                  setUserAction({
                    modal: 'complete-content',
                    userId: p.row.original.userInfo.baseUserInfo.userId,
                    contentType: 'course',
                    contentId: courseId,
                  }),
                icon: 'checkmark--outline',
                disabled: p.row.original.progress === 1,
                hidden: !canSetContentCompletion,
              },
              {
                type: 'label',
                label: t('manage.reset-progress'),
                id: 'reset-content',
                onClick: () =>
                  setUserAction({
                    modal: 'reset-course',
                    contentType: 'course',
                    contentId: courseId,
                  }),
                icon: 'checkmark--outline',
                disabled: p.row.original.progress === 0,
                hidden: !canResetProgress,
              },
              {
                type: 'nested',
                id: 'assign-live-session',
                label: t('manage.live-session.assign-session'),
                icon: 'user--add',
                disabled: filteredLiveSessionsForEnrollment.length === 0,
                hidden: !canEditAssignments,
                menuItems: filteredLiveSessionsForEnrollment.map((ls, i) => ({
                  type: 'canvas',
                  id: `live-session-${i}`,
                  render() {
                    return (
                      <LiveSessionMenuItem
                        liveSession={ls}
                        targetUser={id}
                        onDone={() => handleAfterAssignReload([id])}
                        action='assign'
                      />
                    )
                  },
                })),
              },
              {
                type: 'nested',
                id: 'user--remove',
                label: t('manage.live-session.unassign-session'),
                icon: 'user--remove',
                disabled: assignedLiveSessionRows.length === 0,
                // this assumes that assignment permission on the course applies to its sessions as well
                hidden: !canEditAssignments,
                menuItems: assignedLiveSessionRows.map((ls, i) => ({
                  type: 'canvas',
                  id: `live-session-${i}`,
                  render() {
                    return (
                      <LiveSessionMenuItem
                        liveSession={ls}
                        targetUser={id}
                        onDone={() => handleAfterAssignReload([id])}
                        action='unassign'
                      />
                    )
                  },
                })),
              },
            ]
          }, [
            p.row.original.userInfo.baseUserInfo.userId,
            id,
            filteredLiveSessionsForEnrollment,
            assignedLiveSessionRows,
            p.row.original.progress,
          ])

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                aria-label='Details'
                variant='transparent'
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={menuItems}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
      },
    ],
    [
      handleAfterAssignReload,
      liveSessions,
      t,
      courseId,
      canEditAssignments,
      canResetProgress,
      canSetContentCompletion,
    ]
  )

  const getEntityId = useCallback<(entity: UserTableRow) => string>(e => e.id, [])

  const { tableInstance, selectedIds } = useManageTableSmall({ tableOptions: { data, columns }, getEntityId })

  const assignedLsPerUser = tableInstance.selectedFlatRows.map(row =>
    row.original.assignedSessions.map(ls => ls.liveSessionId)
  )

  // Gets union and intersection of the assigned session to filter on the select session menu
  const liveSessionsAssignedToAllUser = _.intersection(...assignedLsPerUser)
  const liveSessionsAssignedToAnyUser = _.union(...assignedLsPerUser)

  return (
    <View direction='column' gap='xsmall'>
      <Text size='large' bold>
        {t('table.learners')}
      </Text>
      <View grow justifyContent='space-between'>
        <RoundedSearchBar
          value={filter.query}
          onChange={value => setFilter(f => ({ ...f, query: value }))}
          placeholder={t('manage.search.users')}
        />
        <View>
          <LiveSessionUserGroupFilter
            title={t('dictionary.group-plural')}
            selectedGroupIds={filter.groups}
            setGroupIds={groupIds => setFilter(curr => ({ ...curr, programs: groupIds }))}
            groupsData={groupsData}
          />

          <LiveSessionUserGroupFilter
            title={t('dictionary.program-plural')}
            selectedGroupIds={filter.programs}
            setGroupIds={groupIds => setFilter(curr => ({ ...curr, programs: groupIds }))}
            groupsData={programsData}
          />
          <LiveSessionAssignedFilter
            value={filter.assignedToLiveSession}
            onChange={value => setFilter(f => ({ ...f, assignedToLiveSession: value }))}
          />
          <UserContentProgressFilter
            value={filter.status}
            onChange={value => setFilter(f => ({ ...f, status: value }))}
          />
          <ClearAllFilters filter={filter} setFilter={setFilter} />
        </View>
      </View>
      <ManageTableSmall
        mapEntityToCsv={userTableRowToCsv}
        getEntityId={getEntityId}
        tableInstance={tableInstance}
        focusedId={focusedUserId}
        translations={{
          csvPrefix: t('admin.organization.users.users'),
          searchPlaceholder: t('dictionary.search'),
          tableLoading: t('dictionary.loading'),
          tableNoResults: t('manage.users.no-results'),
        }}
        isSearchOpen={false}
        footerButton={
          canEditAssignments ? (
            <Button onClick={() => setAction({ modal: 'assign' })}>
              {t('admin.organization.paths.enroll')}
            </Button>
          ) : undefined
        }
        bulkActions={
          canEditAssignments ? (
            <>
              <SelectLiveSession
                liveSessions={liveSessions}
                targetUsers={selectedIds as UserId[] /** todo: remove cast */}
                onDone={() => handleAfterAssignReload(selectedIds as UserId[] /** todo: remove cast */)}
                currentLiveSessionIds={liveSessionsAssignedToAllUser}
                action='assign'
                renderTrigger={({ disabled }) => {
                  if (disabled) return null
                  return (
                    <ActionButton color='blueBright'>{t('manage.live-session.assign-session')}</ActionButton>
                  )
                }}
              />
              <SelectLiveSession
                liveSessions={liveSessions}
                targetUsers={selectedIds as UserId[] /** todo: remove cast */}
                currentLiveSessionIds={liveSessionsAssignedToAnyUser}
                onDone={() => handleAfterAssignReload(selectedIds as UserId[] /** todo: remove cast */)}
                action='unassign'
                renderTrigger={({ disabled }) => {
                  if (disabled) return null
                  return (
                    <ActionButton color='redBright'>{t('manage.live-session.unassign-session')}</ActionButton>
                  )
                }}
              />
            </>
          ) : undefined
        }
      />
      <Spacer size='xlarge' />
      {focusedUserId !== undefined && (
        <UserModalActions
          targetUserId={focusedUserId}
          action={userAction}
          onClose={() => setUserAction({ modal: undefined })}
          onDone={() => {
            setUserAction({ modal: undefined })
            handleUpdateUserRow(focusedUserId)
          }}
        />
      )}
      {action?.modal === 'assign' && (
        <AssignModal
          isOpen
          subjectType='course'
          subjects={courseId}
          subjectsSupportAssignmentSettings={true}
          autoAssignmentAvailable={true}
          panes={assignmentPanes}
          showDueDates={assignmentPanes}
          title={t('dictionary.enrollments')}
          activePane='user'
          onClose={() => {
            setAction(undefined)
          }}
          onSave={async selections => {
            const result = await assignWithDueDate(
              parseModalToContentAssignment([courseId], 'course', selections)
            )
            if (result?.error !== undefined) return

            notifications.push({ type: 'assigned' })
            setAction(undefined)
            handleAfterAssignReload()
          }}
        />
      )}
    </View>
  )
}
