/**
 * Sync the call service state with the livesession awareness data
 */

import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { usePresenceAtom } from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { CurrentUserPresenceSessionAtom } from 'sierra-client/features/sana-now/presence/state'
import { SyncLocalPresence } from 'sierra-client/features/sana-now/presence/sync-presence'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionAwarenessStatesChanged } from 'sierra-client/state/live-session/actions'
import { selectLocalAwarenessState } from 'sierra-client/state/sana-now/selectors'
import { LivePresenceData } from 'sierra-domain/api/live-session-presence'
import { LiveSessionId } from 'sierra-domain/api/nano-id'

const TIME_UNTIL_CONSIDERED_IDLE = 10 * 60 * 1000

const useIsIdle = (): boolean => {
  const [isIdle, setIsIdle] = useState(false)

  useIdleTimer({
    timeout: TIME_UNTIL_CONSIDERED_IDLE,
    onActive: () => setIsIdle(false),
    onIdle: () => setIsIdle(true),
  })

  return isIdle
}

export const SyncAwarenessStateToBackendPresence = ({
  liveSessionId,
  userShouldIdle,
}: {
  liveSessionId: LiveSessionId
  userShouldIdle: boolean
}): JSX.Element | null => {
  const localState = useSelector(selectLocalAwarenessState)
  const isIdle = useIsIdle()

  const data = useMemo(() => {
    return {
      followMeEnabled: localState.followMeEnabled,
      isFollowingFollowMe: localState.isFollowingFollowMe,
      scrollAtSlateNodeElementId: localState.scrollAtSlateNodeElementId,
      agoraScreenShareUid: localState.agoraScreenShareUID,
      agoraUid: localState.agoraUID,
      breakoutRoomId: localState.breakoutRoomId,
      isRecordingWithId: localState.isRecordingWithId,
      videoState: localState.videoState,
      audioState: localState.audioState,
      timeUserWasEngaged: localState.timeUserWasEngaged,
      timeStartedRaisingHand: localState.timeStartedRaisingHand,
      isWritingToReflectionId: localState.isWritingToReflection,
      isWritingToReflectionIdAnonymous: localState.isWritingToReflectionAnonymous,
      isWritingToChatThreadId: localState.isWritingToChatThreadId,
      followVideoState: localState.followVideoState,
    } satisfies LivePresenceData
  }, [localState])

  if (userShouldIdle && isIdle) return null
  return <SyncLocalPresence liveSessionId={liveSessionId} data={data} />
}

export const SyncAwarenessBackendPresenceToRedux = (): null => {
  const dispatch = useDispatch()
  const remoteAwarenessStateAtom = usePresenceAtom()
  const currentSessionId = useAtomValue(CurrentUserPresenceSessionAtom)
  const values = useAtomValue(remoteAwarenessStateAtom)

  useEffect(() => {
    dispatch(
      liveSessionAwarenessStatesChanged(
        values.map(value => ({
          clientId: value.sessionId,
          isCurrentClient: currentSessionId === value.sessionId,
          agoraScreenShareUID: value.data.agoraScreenShareUid,
          agoraUID: value.data.agoraUid,
          breakoutRoomId: value.data.breakoutRoomId,
          isRecordingWithId: value.data.isRecordingWithId,
          videoState: value.data.videoState,
          audioState: value.data.audioState,
          timeUserWasEngaged: value.data.timeUserWasEngaged,
          timeStartedRaisingHand: value.data.timeStartedRaisingHand,
          followMeEnabled: value.data.followMeEnabled,
          isFollowingFollowMe: value.data.isFollowingFollowMe,
          scrollAtSlateNodeElementId: value.data.scrollAtSlateNodeElementId,
          followVideoState: value.data.followVideoState,
          isWritingToReflection: value.data.isWritingToReflectionId,
          isWritingToReflectionAnonymous: value.data.isWritingToReflectionIdAnonymous,
          isWritingToChatThreadId: value.data.isWritingToChatThreadId,
          userId: value.userId,
        }))
      )
    )
  }, [currentSessionId, dispatch, values])

  return null
}
