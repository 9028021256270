import { ItemType } from 'sierra-client/components/common/modals/multi-assign-modal/types'

export const useContentGroupAssignmentPanes = (): Array<ItemType> => {
  return ['user', 'user-group']
}

export const useLiveSessionAssignmentPanes = (): Array<ItemType> => {
  return ['user', 'user-group']
}

export const useCalendarEventAssignmentPanes = (): Array<ItemType> => {
  return ['user', 'user-group']
}
