import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { useDragScroll } from 'sierra-client/features/program'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ApplyChangesModal,
  StaggeredAssignmentsSaveAction,
} from 'sierra-client/views/manage/programs/staggered-assignments/apply-changes-modal'
import {
  emailTemplateIdAtom,
  newEmailTemplateIds,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import { AddEmailNotificationPanel } from 'sierra-client/views/manage/programs/staggered-assignments/panels/add-email-notification-panel'
import { AddStepPanel } from 'sierra-client/views/manage/programs/staggered-assignments/panels/add-step-panel'
import { ProgramPanelCommon } from 'sierra-client/views/manage/programs/staggered-assignments/panels/common'
import { deleteEmailTemplateBindings } from 'sierra-client/views/manage/programs/staggered-assignments/queries/email-template-queries'
import { ProgramStepsEdit } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/edit'
import { getSectionStepRange } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/utils'
import { StaggeredAssignmentsMode } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import {
  getStepId,
  isEventProgramStep,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { Layout, Panel, Tooltip } from 'sierra-ui/components'
import { PanelInPanelOverlay } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Heading, ScrollView, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'

type InitialOutline = {
  programId: ProgramId
  initialOutline: ProgramOutline
  canChangeUserVersion: boolean
  onSave: (outline: ProgramOutline, mode: StaggeredAssignmentsMode) => void
  enrollmentCount: number
}

const Footer: React.FC<InitialOutline & { cancel: () => void }> = ({
  initialOutline,
  cancel,
  canChangeUserVersion,
  onSave,
  enrollmentCount,
  programId,
}) => {
  const [isSaveConfirmationOpen, { on: openSaveConfirmation, off: closeSaveConfirmation }] = useToggle()
  const { t } = useTranslation()

  const { outline, isSavingOutline } = useOutlineEdit()
  const hasChanged = useMemo(() => !_.isEqual(initialOutline, outline), [initialOutline, outline])
  const sectionTitles = outline.sections.map(section => section.title)
  const hasDuplicateSectionTitles = useMemo(() => {
    return new Set(sectionTitles).size !== sectionTitles.length
  }, [sectionTitles])

  const hasEmptySections = useMemo(() => {
    return outline.sections.some((_, index) => getSectionStepRange(index, outline) === null)
  }, [outline])

  const save: StaggeredAssignmentsSaveAction = useCallback(
    mode => {
      onSave(outline, mode)
    },
    [onSave, outline]
  )

  return (
    <ProgramPanelCommon.OuterPanelFooterWrapper justifyContent='flex-end'>
      <Button variant='secondary' onClick={cancel}>
        {t('dictionary.cancel')}
      </Button>
      <ConditionalWrapper
        condition={hasDuplicateSectionTitles || hasEmptySections}
        renderWrapper={children => (
          <Tooltip
            title={
              hasDuplicateSectionTitles
                ? t('manage.programs.edit-program.prevent-same-title-tooltip')
                : t('manage.programs.edit-program.prevent-empty-section-tooltip')
            }
          >
            {children}
          </Tooltip>
        )}
      >
        <Button
          variant='primary'
          disabled={!hasChanged || isSavingOutline || hasDuplicateSectionTitles || hasEmptySections}
          onClick={enrollmentCount > 0 ? openSaveConfirmation : () => save('new')}
        >
          {t('dictionary.save')}
        </Button>
      </ConditionalWrapper>
      <ApplyChangesModal
        programId={programId}
        canChangeUserVersion={canChangeUserVersion}
        open={isSaveConfirmationOpen}
        onClose={closeSaveConfirmation}
        primaryAction={save}
      />
    </ProgramPanelCommon.OuterPanelFooterWrapper>
  )
}

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`
const PanelScrollArea = styled(ScrollView)`
  padding: 32px 24px 128px 24px;
`

export const EditOutlinePanel: React.FC<InitialOutline> = ({
  initialOutline,
  programId,
  canChangeUserVersion,
  onSave,
  enrollmentCount,
}) => {
  const [isSaveConfirmationOpen, { on: openSaveConfirmation, off: closeSaveConfirmation }] = useToggle()
  const [newEmailTemplateIdList, setNewEmailTemplateIdList] = useAtom(newEmailTemplateIds)

  const {
    outline,
    setOutline,
    panels: { editOutlineOpen, addContentOpen, addEmailNotificationOpen },
    setPanels: {
      editOutline: { off: editOutlineOff },
      addContent: { off: addStepOff },
      addEmailNotification: { off: addEmailOff },
    },
  } = useOutlineEdit()

  const templateId = useAtomValue(emailTemplateIdAtom)

  const hasChanged = useMemo(() => !_.isEqual(initialOutline, outline), [initialOutline, outline])

  /* Run on cancel to delete all created email templates */
  const deleteUnusedEmailTemplates = useCallback(async () => {
    await deleteEmailTemplateBindings(newEmailTemplateIdList)
    setNewEmailTemplateIdList([])
  }, [newEmailTemplateIdList, setNewEmailTemplateIdList])

  /* Run on save */
  const deleteRemovedEmailTemplates = useCallback(async () => {
    // Delete email templates which were created and then deleted
    const currentEmailSteps = outline.steps.filter(isEventProgramStep)

    const addedButRemovedEmails = newEmailTemplateIdList.filter(
      id => !currentEmailSteps.some(c => c.emailTemplateBindingsId === id)
    )

    // Try deleting templates which were deleted
    const initialEmailSteps = initialOutline.steps.filter(isEventProgramStep)
    const removedEmails = initialEmailSteps
      .filter(initial => !currentEmailSteps.some(current => getStepId(current) === getStepId(initial)))
      .map(e => e.emailTemplateBindingsId)

    const toRemove = addedButRemovedEmails.concat(removedEmails)

    await deleteEmailTemplateBindings(toRemove)
    setNewEmailTemplateIdList([])
  }, [initialOutline, newEmailTemplateIdList, outline, setNewEmailTemplateIdList])

  const reset = useCallback(() => setOutline(initialOutline), [initialOutline, setOutline])

  const cancel = useCallback(() => {
    reset()
    addStepOff()
    editOutlineOff()
    addEmailOff()
    void deleteUnusedEmailTemplates()
  }, [reset, addStepOff, editOutlineOff, addEmailOff, deleteUnusedEmailTemplates])

  const checkForChanges = (): void => {
    if (hasChanged) {
      openSaveConfirmation()
    } else {
      cancel()
    }
  }

  const { t } = useTranslation()

  const scrollListRef = useRef<HTMLDivElement>(null)

  useDragScroll({
    listRef: scrollListRef,
    dragTypes: ['program-step', 'program-section'],
  })

  return (
    <Panel
      size={{ width: 800 }}
      animation='slide-right'
      padding='none'
      open={editOutlineOpen}
      onClose={checkForChanges}
    >
      {addContentOpen && <PanelInPanelOverlay onClick={addStepOff} />}
      {addEmailNotificationOpen && <PanelInPanelOverlay onClick={addEmailOff} />}
      <Layout
        height='100%'
        panels={[
          <AddStepPanel open={addContentOpen} key='content' />,
          <AddEmailNotificationPanel
            templateId={templateId}
            programId={programId}
            open={addEmailNotificationOpen}
            key='email-notification'
          />,
        ]}
      >
        <StrategicErrorBoundary id='program-edit-outline'>
          <ProgramPanelCommon.LayoutWrapper $withoutPadding>
            <ActionModal
              title={t('manage.program.program-details.outline.cancel-modal-title')}
              open={isSaveConfirmationOpen}
              onClose={closeSaveConfirmation}
              primaryAction={cancel}
              deleteAction
              primaryActionLabel={t('modal.discard-changes')}
              secondaryActionLabel={t('modal.back')}
              secondaryAction={closeSaveConfirmation}
            >
              {t('manage.program.program-details.outline.cancel-modal-text')}
            </ActionModal>

            <InnerContainer>
              <PanelScrollArea ref={scrollListRef}>
                <View padding='16' paddingTop='none'>
                  <Heading size='h5' bold avoidHanging={false}>
                    {t('manage.program.program-details.outline.edit-outline-button')}
                  </Heading>
                </View>

                <ProgramStepsEdit steps={initialOutline} programId={programId} />
              </PanelScrollArea>

              <Footer
                initialOutline={initialOutline}
                canChangeUserVersion={canChangeUserVersion}
                enrollmentCount={enrollmentCount}
                cancel={checkForChanges}
                onSave={(...info) => {
                  onSave(...info)
                  void deleteRemovedEmailTemplates()
                }}
                programId={programId}
              />
            </InnerContainer>
          </ProgramPanelCommon.LayoutWrapper>
        </StrategicErrorBoundary>
      </Layout>
    </Panel>
  )
}
