import { FC, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import {
  DropIndicator,
  ProgramStepDragItem,
} from 'sierra-client/views/manage/programs/staggered-assignments/renderer/drag-n-drop'
import {
  DropLocation,
  ProgramSectionDragItem,
} from 'sierra-client/views/manage/programs/staggered-assignments/renderer/types'
import {
  getSectionStepRange,
  useDelayedState,
} from 'sierra-client/views/manage/programs/staggered-assignments/renderer/utils'
import { isDefined, isNotDefined } from 'sierra-domain/utils'
import styled, { css } from 'styled-components'

const HitBox = styled.div<{
  $active: boolean
  $debugging: boolean
  $position: DropLocation
  $collapsed: boolean
}>`
  position: absolute;
  width: 100%;
  height: 30px;
  opacity: 0.2;

  pointer-events: ${p => (p.$active ? 'all' : 'none')};

  ${p =>
    p.$position === 'below'
      ? css`
          bottom: -10px;
        `
      : css`
          top: -10px;
        `}

  ${p =>
    p.$debugging &&
    css`
      background: green;
      outline: 2px dashed red;
      outline-offset: -2px;
    `}

${p =>
    p.$collapsed &&
    css`
      top: -4px;
      height: calc(100% + 8px);
    `}
`

export const Dropzone: FC<{ position: DropLocation; sectionIndex: number; collapsed?: boolean }> = ({
  position,
  sectionIndex,
  collapsed = false,
}) => {
  const hitboxRef = useRef<HTMLDivElement>(null)
  const [hovering, setDelayedHovering, setImmediateHovering] = useDelayedState(false, 150)
  const { moveSection, moveStep, outline } = useOutlineEdit()
  const debug = useIsDebugMode()

  const [collected, dropRef] = useDrop<
    ProgramStepDragItem | ProgramSectionDragItem,
    {
      index: number
    },
    { canDrop: boolean; isOver: boolean }
  >(() => {
    return {
      accept: ['program-step', 'program-section'],
      collect: monitor => {
        const dragged = monitor.getItem()

        return {
          canDrop: isNotDefined(dragged) ? false : monitor.canDrop(),
          isOver: monitor.isOver(),
        }
      },
      canDrop: item => {
        const targetRange = getSectionStepRange(sectionIndex, outline)

        if (item.type === 'program-section') {
          // Don't allow dropping sections on themselves
          if (item.index === sectionIndex) {
            return false
          }

          const draggedRange = getSectionStepRange(item.index, outline)

          // Don't allow dropping sections from right above or right below (i.e. we don't allow drops to the same position)
          if (targetRange !== null && draggedRange !== null) {
            if (
              (targetRange.start === draggedRange.end + 1 && position === 'above') ||
              (targetRange.end === draggedRange.start - 1 && position === 'below')
            ) {
              return false
            }
          }
        } else {
          const section = outline.sections[sectionIndex]
          const selfIndex = section?.selfIndex

          // Prevent dropping step into the same place, for empty and non-empty sections
          if (isDefined(selfIndex)) {
            if (
              (selfIndex === item.index && position === 'below') ||
              (selfIndex === item.index - 1 && position === 'above')
            ) {
              return false
            }
          } else if (targetRange !== null) {
            if (
              (targetRange.start === item.index + 1 && position === 'above') ||
              (targetRange.end === item.index - 1 && position === 'below')
            ) {
              return false
            }
          }
        }
        return true
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return
        }

        const receivingSection = outline.sections[sectionIndex]

        if (!isDefined(receivingSection)) {
          throw Error('The receiving section is not available in the outline')
        }

        let toPosition = null

        if ('selfIndex' in receivingSection && isDefined(receivingSection.selfIndex)) {
          toPosition = receivingSection.selfIndex
        } else {
          const range = getSectionStepRange(sectionIndex, outline)

          if (isDefined(range)) {
            const { start, end } = range
            toPosition = collapsed ? start : position === 'above' ? start : end + 1
          }
        }

        if (!isDefined(toPosition)) {
          throw Error('failed to resolve the index to move to')
        }

        if (item.type === 'program-section') {
          moveSection(item.index, toPosition, 'above')
        } else {
          moveStep(
            item.index,
            toPosition,
            collapsed ? 'below' : 'above',
            collapsed ? 'section' : 'root',
            collapsed
          )
        }

        return { index: item.index }
      },
    }
  }, [sectionIndex, outline, position, collapsed, moveSection, moveStep])

  dropRef(hitboxRef)

  return (
    <>
      {hovering && collected.canDrop && <DropIndicator $position={collapsed ? 'on' : position} />}
      <HitBox
        $position={position}
        ref={hitboxRef}
        $active={collected.canDrop}
        $debugging={debug && collected.canDrop}
        $collapsed={collapsed}
        onPointerOver={() => setDelayedHovering(true)}
        onPointerLeave={() => setImmediateHovering(false)}
      />
    </>
  )
}
