import { useMutation } from '@tanstack/react-query'
import { sortBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { graphql } from 'sierra-client/api/graphql/gql'
import { AssignUsersToEventInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import * as format from 'sierra-client/core/format'
import { useHasContentKindPermission, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  LiveSessionAssignedFilter,
  LiveSessionUserGroupFilter,
} from 'sierra-client/views/manage/courses/components/live-session-filters'
import { getLiveSessionTimes } from 'sierra-client/views/manage/courses/utils/live-session-utils'
import { useContentGroupAssignmentPanes } from 'sierra-client/views/manage/courses/utils/use-content-group-assignment-panes'
import { useEventGroupAssignmentData } from 'sierra-client/views/manage/event-groups/event-group-assignment-table/use-event-group-assignment-table-data'
import { EventRow } from 'sierra-client/views/manage/event-groups/event-utils'
import { SelectSessionModal } from 'sierra-client/views/manage/event-groups/select-session-modal'
import { useGroups } from 'sierra-client/views/manage/groups/use-groups'
import { AssignedViaPill } from 'sierra-client/views/manage/users/components/content-assignment-components'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { BaseProgramInfoUser, EnrolledUser } from 'sierra-domain/api/manage'
import { CourseId, EventGroupId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminAssignmentsUnassignFromCourse } from 'sierra-domain/routes'
import { BaseUserRowWithType } from 'sierra-domain/user/base-user-row'
import { MenuItem, Tooltip, UserDisplay, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

type UserTableRow = {
  id: UserId
  userInfo: BaseUserRowWithType
  progress?: number
  programs: BaseProgramInfoUser[]
  assignedAt?: string
  assignedCalendarEvents: EnrolledUser['calendarEvents']
}

const userTableRowToCsv = (row: UserTableRow): Record<string, string> => ({
  userId: row.id,
  email: row.userInfo.baseUserInfo.email,
  firstName: row.userInfo.baseUserInfo.firstName ?? '',
  lastName: row.userInfo.baseUserInfo.lastName ?? '',
  progress: row.progress?.toFixed(3) ?? '0',
  assignedAt: row.assignedAt !== undefined ? new Date(row.assignedAt).toISOString() : '',
  assignedCalendarEvents: row.assignedCalendarEvents
    .map(session => session.startTime.toISOString())
    .join(', '),
})

type EventGroupAssignmentTableProps = {
  courseId: EventGroupId
  afterSubmit?: () => void
  events: EventRow[]
  openCreateCalendarEventPanel: (open: boolean) => void
}

const assignUsersToCalendarEventMutation = graphql(`
  mutation assignFromUserTable($input: AssignUsersToEventInput!) {
    assignUsersToEvent(input: $input) {
      __typename
    }
  }
`)

const unassignUsersToCalendarEventMutation = graphql(`
  mutation unassignFromUserTable($input: UnassignUsersFromEventInput!) {
    unassignUsersFromEvent(input: $input) {
      __typename
    }
  }
`)

function hasAllParticipants(assignedUsers: { id: string }[], selectedUsers: string[]): boolean {
  const assignedUsersSet = new Set(assignedUsers.map(({ id }) => id))
  return selectedUsers.every(selectedUser => assignedUsersSet.has(selectedUser))
}

export const EventGroupAssignmentTable: React.FC<EventGroupAssignmentTableProps> = ({
  courseId,
  events,
  afterSubmit,
  openCreateCalendarEventPanel,
}) => {
  const { t } = useTranslation()
  const { assignWithDueDate } = useDueDate()
  const notifications = useNotif()
  const { groupsData: programsData } = useGroups('program')
  const { groupsData } = useGroups('user')
  const assignmentPanes = useContentGroupAssignmentPanes()
  const canAssignOrUnassignOrReset = useHasContentKindPermission(courseId, 'EDIT_ASSIGNMENTS')
  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')

  const { allAssignedUsers, filter, setFilter, refresh, hasFilterChanged } = useEventGroupAssignmentData({
    courseId,
    initialUsersToLoad: 150,
  })

  const assignUsersToCalendarEvent = useMutation({
    mutationFn: (input: AssignUsersToEventInput) => graphQuery(assignUsersToCalendarEventMutation, { input }),
    onSuccess: () => {
      refresh()
      afterSubmit?.()
    },
  })

  const unassignUsersToCalendarEvent = useMutation({
    mutationFn: (input: AssignUsersToEventInput) =>
      graphQuery(unassignUsersToCalendarEventMutation, { input }),
    onSuccess: () => {
      refresh()
      afterSubmit?.()
    },
  })

  const unAssignUserMutation = useMutation({
    mutationFn: (userIds: UserId[]) =>
      typedPost(XRealtimeAdminAssignmentsUnassignFromCourse, { userIds, courseId }),
    onSuccess: refresh,
  })

  const [userAction, setUserAction] = useState<UserModalActionsProps['action']>({ modal: undefined })
  const [focusedUserId, setFocusedUserId] = useState<UserId | undefined>(undefined)

  const [action, setAction] = useState<
    | { modal: 'enroll-users' | 'enroll-programs' | 'delete' }
    | {
        modal: 'unassign-users' | 'assign-session'
        ids: UserId[]
      }
    | undefined
  >()

  const onRemoveUsers = useCallback((ids: UserId[]) => {
    setAction({ modal: 'unassign-users', ids })
  }, [])

  const handleUpdateUserRow = useCallback<(userId: UserId | undefined) => void>(
    userId => {
      if (userId !== undefined) refresh()
    },
    [refresh]
  )

  const data = useMemo<UserTableRow[]>(() => {
    // const liveSessionById = _.groupBy(liveSessions, (ls) =>ls.liveSessionId);
    return allAssignedUsers.data.map(u => ({
      id: u.userInfo.baseUserInfo.userId,
      userInfo: u.userInfo,
      progress: u.progress,
      programs: u.programs,
      assignedAt: u.assignedAt?.toISOString(),
      assignedCalendarEvents: sortBy(u.calendarEvents, it => it.startTime.valueOf()),
    }))
  }, [allAssignedUsers.data])

  const columns = useMemo<Column<UserTableRow>[]>(
    () => [
      {
        id: 'select',
        accessor: 'id',
        Header: p => <SelectableHeader {...p} />,
        Cell: p => <SelectableRow {...p} />,
        width: '5%',
      },
      {
        id: 'name',
        Header: t('table.name'),
        width: '45%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { userInfo } = p.row.original
          return (
            <RouterLink href={`/manage/users/${userInfo.baseUserInfo.userId}`}>
              <UserDisplay
                primaryText={[userInfo.baseUserInfo.firstName, userInfo.baseUserInfo.lastName].join(' ')}
                secondaryText={userInfo.baseUserInfo.email}
                avatar={{
                  ...getAvatarPropsFromBaseUserInfo(userInfo.baseUserInfo),
                  src: getAvatarImage(userInfo.baseUserInfo.userId, userInfo.baseUserInfo.avatar),
                  size: 'small',
                }}
              />
            </RouterLink>
          )
        },
      },
      {
        id: 'calendarEvents',
        accessor: 'assignedCalendarEvents',
        Header: t('dictionary.session'),
        width: '20%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { assignedCalendarEvents } = p.row.original
          if (assignedCalendarEvents.length === 0) {
            return null
          }
          return (
            <View direction='column'>
              {assignedCalendarEvents.map(ls => {
                const formattedTimes = getLiveSessionTimes(
                  ls.startTime.toISOString(),
                  ls.endTime.toISOString()
                )
                if (formattedTimes === undefined) return null
                return (
                  <Text color='foreground/primary' key={ls.calendarEventId} size='small'>
                    {formattedTimes.date}, {formattedTimes.time}
                  </Text>
                )
              })}
            </View>
          )
        },
      },

      {
        id: 'programs',
        accessor: 'programs',
        Header: t('table.assigned-via'),
        Cell: p => {
          const { assignedAt, programs } = p.row.original

          if (assignedAt === undefined) return null

          return (
            <AssignedViaPill
              assignmentData={{
                assignedAt: assignedAt,
                availableAt: assignedAt,
                type: programs.length === 0 ? 'individual' : 'program',
                programs,
              }}
            />
          )
        },
        width: '15%',
      },
      {
        id: 'progress',
        accessor: 'progress',
        Header: t('dictionary.status'),
        width: '15%',
        Cell: p => {
          const { progress } = p.row.original
          return (
            <>
              {progress === undefined || progress === 0
                ? t('manage.users.status.not-started')
                : t('manage.users.status.n-completed', { n: format.percentage(progress) })}
            </>
          )
        },
      },
      {
        id: 'actions',
        width: '10%',
        Cell: (p: CellProps<UserTableRow>) => {
          const { id } = p.row.original

          const [open, setOpen] = useState(false)

          useEffect(() => {
            if (open) {
              setFocusedUserId(id)
            } else {
              setFocusedUserId(undefined)
            }
          }, [open, id, p.row.original.id])

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                label: t('manage.view-details'),
                id: 'view-details',
                onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${id}` }),
                icon: 'user',
              },
              {
                type: 'label',
                label: t('manage.complete-progress'),
                id: 'complete-content',
                onClick: () => {
                  setUserAction({
                    modal: 'complete-content',
                    userId: p.row.original.userInfo.baseUserInfo.userId,
                    contentType: 'course',
                    contentId: courseId,
                  })
                },
                icon: 'checkmark--outline',
                hidden: !canAssignOrUnassignOrReset || !canSetContentCompletion,
                disabled: p.row.original.progress === 1,
              },
              {
                type: 'label',
                label: t('manage.reset-progress'),
                id: 'reset-content',
                onClick: () =>
                  setUserAction({
                    modal: 'reset-course',
                    contentType: 'course',
                    contentId: courseId,
                  }),
                icon: 'checkmark--outline',
                hidden: !canAssignOrUnassignOrReset || !canResetProgress,
                disabled: p.row.original.progress === 0,
              },
              {
                type: 'label',
                label: t('manage.event-groups.manage-events-for-users'),
                id: 'assign-to-event',
                onClick: () => {
                  setAction({
                    modal: 'assign-session',
                    ids: [id],
                  })
                  setOpen(false)
                },
                hidden: !canAssignOrUnassignOrReset,
                icon: 'user--add',
              },
              {
                type: 'label',
                label: t('dictionary.unassign'),
                id: 'unassign',
                onClick: () => {
                  onRemoveUsers([p.row.original.userInfo.baseUserInfo.userId])
                  setOpen(false)
                },
                icon: 'time',
                hidden: !canAssignOrUnassignOrReset,
                color: 'destructive/background',
                disabled: p.row.original.programs.length !== 0 || p.row.original.assignedAt === undefined,
              },
            ]
          }, [
            p.row.original.progress,
            p.row.original.programs.length,
            p.row.original.assignedAt,
            p.row.original.userInfo.baseUserInfo.userId,
            id,
          ])

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                variant='transparent'
                aria-label={t('dictionary.details')}
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={menuItems}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
      },
    ],
    [canAssignOrUnassignOrReset, t, courseId, onRemoveUsers, canResetProgress, canSetContentCompletion]
  )

  const getEntityId = useCallback((e: UserTableRow): string => e.id, [])

  const { tableInstance } = useManageTableSmall({ tableOptions: { data, columns }, getEntityId })
  const selectedIds = useMemo(
    () => tableInstance.selectedFlatRows.map(row => row.original.userInfo.baseUserInfo.userId),
    [tableInstance.selectedFlatRows]
  )

  return (
    <View direction='column' gap='xsmall'>
      <Text size='large' bold>
        {t('table.learners')}
      </Text>
      <View grow justifyContent='space-between'>
        <RoundedSearchBar
          value={filter.query}
          onChange={value => setFilter(f => ({ ...f, query: value }))}
          placeholder={t('manage.search.users')}
        />
        <View>
          <LiveSessionUserGroupFilter
            title={t('dictionary.group-plural')}
            selectedGroupIds={filter.groups}
            setGroupIds={groupIds => setFilter(curr => ({ ...curr, programs: groupIds }))}
            groupsData={groupsData}
          />

          <LiveSessionUserGroupFilter
            title={t('dictionary.program-plural')}
            selectedGroupIds={filter.programs}
            setGroupIds={groupIds => setFilter(curr => ({ ...curr, programs: groupIds }))}
            groupsData={programsData}
          />
          <LiveSessionAssignedFilter
            value={filter.assignedToCalendarEvent}
            onChange={value => setFilter(f => ({ ...f, assignedToCalendarEvent: value }))}
          />
          <UserContentProgressFilter
            value={filter.status}
            onChange={value => setFilter(f => ({ ...f, status: value }))}
          />
          {hasFilterChanged && (
            <Tooltip title={t('dashboard.search.clear-hover')}>
              <IconButton
                iconId='close'
                variant='transparent'
                onClick={() =>
                  setFilter({
                    groups: [],
                    programs: [],
                    query: '',
                    assignedToCalendarEvent: undefined,
                    status: undefined,
                  })
                }
              />
            </Tooltip>
          )}
        </View>
      </View>
      <ManageTableSmall
        mapEntityToCsv={userTableRowToCsv}
        getEntityId={getEntityId}
        tableInstance={tableInstance}
        focusedId={focusedUserId}
        translations={{
          csvPrefix: t('admin.organization.users.users'),
          searchPlaceholder: t('dictionary.search'),
          tableLoading: t('dictionary.loading'),
          tableNoResults: t('manage.users.no-results'),
        }}
        isSearchOpen={false}
        footerButton={
          canAssignOrUnassignOrReset ? (
            <Button onClick={() => setAction({ modal: 'enroll-users' })}>
              {t('admin.organization.paths.enroll')}
            </Button>
          ) : undefined
        }
        bulkActions={
          canAssignOrUnassignOrReset ? (
            <>
              <ActionButton
                color='blueBright'
                onClick={() =>
                  setAction({
                    modal: 'assign-session',
                    ids: selectedIds,
                  })
                }
              >
                {t('manage.event-groups.manage-events-for-users')}
              </ActionButton>
              <ActionButton color='redBright' onClick={() => onRemoveUsers(selectedIds)}>
                {t('dictionary.unassign')}
              </ActionButton>
            </>
          ) : undefined
        }
      />
      {focusedUserId !== undefined && (
        <UserModalActions
          targetUserId={focusedUserId}
          action={userAction}
          onClose={() => setUserAction({ modal: undefined })}
          onDone={() => {
            setUserAction({ modal: undefined })
            handleUpdateUserRow(focusedUserId)
          }}
        />
      )}
      {['enroll-users', 'enroll-groups'].includes(action?.modal ?? '') && (
        <AssignModal
          isOpen={true}
          subjectType='course'
          subjects={courseId}
          subjectsSupportAssignmentSettings={true}
          autoAssignmentAvailable={true}
          panes={assignmentPanes}
          showDueDates={[]}
          title={t('dictionary.enrollments')}
          activePane={'user'}
          onClose={() => {
            setAction(undefined)
          }}
          onSave={async selections => {
            const result = await assignWithDueDate(
              parseModalToContentAssignment([courseId], 'course', selections)
            )
            if (result?.error !== undefined) return
            refresh()
            setAction(undefined)
            afterSubmit?.()
          }}
        />
      )}
      {action?.modal === 'assign-session' && (
        <SelectSessionModal
          open={true}
          onClose={() => {
            setAction(undefined)
          }}
          users={action.ids}
          onSessionSelected={async session => {
            if (session.onSelectedAction === 'unassign') {
              await unassignUsersToCalendarEvent.mutateAsync({
                calendarEventId: session.id,
                userIds: action.ids,
              })
            } else {
              await assignUsersToCalendarEvent.mutateAsync({
                calendarEventId: session.id,
                userIds: action.ids,
              })
            }
          }}
          onCreateNewSession={() => {
            openCreateCalendarEventPanel(true)
          }}
          sessions={events.map(event => ({
            id: event.data.id,
            title: event.data.title,
            schedule: event.data.schedule,
            participants: event.data.assignedUsers.map(({ id }) => id),
            participantLimit: event.data.participantLimit ?? undefined,
            image: event.data.image,
            assetContext: { type: 'course' as const, courseId: CourseId.parse(courseId) },
            location: event.data.location,
            // If all selected users are assigned to a session, we give the option to unassign
            onSelectedAction: hasAllParticipants(event.data.assignedUsers, action.ids)
              ? 'unassign'
              : 'assign',
          }))}
        />
      )}
      {action?.modal === 'unassign-users' && (
        <ActionModal
          open
          isLoading={unAssignUserMutation.isPending}
          onClose={() => setAction(undefined)}
          primaryAction={async (): Promise<void> => {
            if (action.modal !== 'unassign-users') return
            await unAssignUserMutation.mutateAsync(action.ids)
            setAction(undefined)
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', { count: action.ids.length })}
          deleteAction
        />
      )}
    </View>
  )
}
