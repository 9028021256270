import { useEffect } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { initErrorTracking } from 'sierra-client/error/init-error-tracking'

export const ErrorTracking: React.FC = () => {
  const withReplays = getFlag('session-replays')

  useEffect(() => {
    initErrorTracking({
      withReplays,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
