import { useAtomValue } from 'jotai'
import _ from 'lodash'
import { forwardRef, useMemo, useRef, useState } from 'react'
import { useDrag, useDragLayer, useDrop } from 'react-dnd'
import { DragItemTypes, VerticalStackDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { newPageCardColumnColorAtom } from 'sierra-client/state/settings'
import { BlockDefinition } from 'sierra-client/views/block-types'
import {
  insertNodeAfterNodeWithId,
  removeNodeWithId,
  updateNodeWithId,
} from 'sierra-client/views/v3-author/command'
import { useParent, useSiblings } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType, rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { AddContentButton } from 'sierra-client/views/v3-author/sections/add-content-button'
import { StacksColorPicker } from 'sierra-client/views/v3-author/sections/color-picker'
import { withHorizontalStack, withVerticalStack } from 'sierra-client/views/v3-author/sections/with-sections'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { CourseTheme } from 'sierra-domain/content/v2/content'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { iife } from 'sierra-domain/utils'
import { createParagraph, createVerticalStack } from 'sierra-domain/v3-author/create-blocks'
import { color, ColorBuilder } from 'sierra-ui/color'
import { Icon, Tooltip } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { getTheme, legacyLight, ThemeName } from 'sierra-ui/theming/legacy-theme'
import { useMediaQuery } from 'sierra-ui/utils'
import { Element, Path, Transforms } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled, { css, ThemeProvider, useTheme } from 'styled-components'

const ADD_COLUMN_BUTTON_SIZE = '24px'

const wrapColumnBreakpoint = v2_breakpoint.tablet

const VerticalContent = styled.div`
  & > p,
  & > ul,
  & > ol,
  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    margin-left: ${spacing[16]};
    margin-right: ${spacing[16]};

    @media screen and (min-width: ${v2_breakpoint.phone}) {
      margin-left: ${spacing[32]};
      margin-right: ${spacing[32]};
    }

    &:first-child {
      margin-top: ${spacing[32]};
    }

    &:last-child {
      margin-bottom: ${spacing[32]};
    }
  }

  /* Default type margin */

  & > * + * {
    margin-top: ${spacing['12']};
  }

  /* Exceptions from default */

  & > p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: ${spacing['4']};
  }
`

const TopGroupButton = styled.div<{ isDragging: boolean }>`
  position: absolute;
  width: fit-content;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  justify-content: flex-end;
  bottom: 100%;
  padding-bottom: 4px;
  color: var(--icon-color);
  transition: all 100ms;

  ${p =>
    p.isDragging &&
    css`
      pointer-events: none;
    `}
`

const DeleteButton = styled(IconButton)`
  border: none;
`

const DragButton = styled(IconButton)`
  cursor: grab;
  border: none;
`

const AddButton = styled.div.attrs({ role: 'button' })`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 100ms;
  color: var(--icon-color);
  border: none;
  opacity: 0.5;
`

const AddColumnButtonWrapper = styled.div.attrs({ contentEditable: false })`
  position: absolute;
  transition: all 100ms;
  display: flex;
  align-items: stretch;
  margin-left: 4px;
  justify-content: center;
  border-left: 4px solid transparent;
  border-radius: 8px;

  top: 0;
  left: 100%;
  height: 100%;
  flex-direction: column;
  width: 28px;

  &:hover {
    background: ${token('surface/strong')};

    ${AddButton} {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${wrapColumnBreakpoint}) {
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: row;
    height: 28px;
    border-left: unset;
    border-top: 4px solid ${p => color(p.theme.home.backgroundColor)};
  }
`

export const VerticalWrapper = styled.article<{
  readOnly: boolean
  $isEmpty: boolean
  $backgroundColor: ColorBuilder
  $hoverColor: ColorBuilder
  isDragging: boolean
  indicateDrop: 'before' | 'after'
  isOver: boolean
  disableHover: boolean
}>`
  --icon-color: ${p => color(p.theme.home.textColor).opacity(0.8).toString()};
  --hover-background-color: ${p => p.$hoverColor};

  position: relative;
  padding: 0;
  min-width: 0;

  transition: background-color 100ms;
  border-radius: ${p => p.theme.borderRadius['size-8']};

  background-color: ${p => p.$backgroundColor};

  ${TopGroupButton} {
    opacity: 0;
  }

  ${p =>
    p.$isEmpty &&
    css`
      @media screen and (max-width: ${wrapColumnBreakpoint}) {
        display: none;
      }
    `}

  ${p =>
    !p.readOnly &&
    !p.disableHover &&
    css`
      &:hover {
        background-color: var(--hover-background-color);

        ${TopGroupButton} {
          opacity: 1;
        }
      }

      &:last-of-type:not(:nth-of-type(4)) {
        @media screen and (max-width: ${wrapColumnBreakpoint}) {
          margin-bottom: ${ADD_COLUMN_BUTTON_SIZE};
        }
      }

      ${p.isDragging &&
      css`
        opacity: 0.4;

        ${TopGroupButton} {
          pointer-events: none;
          opacity: 1;
        }
      `}

      ${p.isOver &&
      p.indicateDrop === 'before' &&
      css`
        &::before {
          position: absolute;
          content: '';
          background: color-mix(in srgb, currentColor 50%, transparent);
          left: calc(-0.75rem - 2.5px);
          top: -2.5px;
          bottom: -2.5px;
          width: 5px;
          border-radius: 2.5px;
          border-color: transparent;
        }

        @media screen and (max-width: ${wrapColumnBreakpoint}) {
          &:before {
            width: unset;
            height: 5px;
            top: calc(-0.5rem - 2.5px);
            left: -2.5px;
            right: -2.5px;
          }
        }
      `}

            ${p.isOver &&
      p.indicateDrop === 'after' &&
      css`
        &::before {
          position: absolute;
          content: '';
          background: color-mix(in srgb, currentColor 50%, transparent);
          top: -2.5px;
          bottom: -2.5px;
          right: calc(-0.75rem - 2.5px);
          border-radius: 2.5px;
          width: 5px;
          border-color: transparent;
        }

        @media screen and (max-width: ${wrapColumnBreakpoint}) {
          &:before {
            width: unset;
            height: 5px;
            top: unset;
            bottom: calc(-0.5rem - 2.5px);
            right: -2.5px;
            left: -2.5px;
          }
        }
      `}
    `}
`

function containsTextNode(element: Element): boolean {
  const textNodeTypes: Element['type'][] = [
    'paragraph',
    'numbered-list',
    'bulleted-list',
    'check-list',
    'heading',
  ]

  return textNodeTypes.includes(element.type)
}

export const VerticalStack: BlockDefinition = {
  Wrapper: forwardRef<HTMLElement, SlateWrapperProps>(
    ({ readOnly, element, children, attributes, ...props }, ref) => {
      assertElementType('vertical-stack', element)

      const { t } = useTranslation()
      const editor = useSlateStatic()
      const path = ReactEditor.findPath(editor, element)
      const firstChild = element.children[0]
      const lastChild = element.children[element.children.length - 1]

      const parent = useParent({ nodeId: element.id })
      const parentId = Element.isElement(parent) ? parent.id : undefined
      const siblings = useSiblings({ nodeId: element.id })
      const indexOfElement = siblings.indexOf(element)
      const isFirstSibling = indexOfElement === 0
      const isLastSibling = indexOfElement === siblings.length - 1

      const defaultTheme = useTheme()

      const [previewThemeName, setPreviewThemeName] = useState<ThemeName | undefined>()
      const elementThemeName = previewThemeName ?? element.theme?.name

      const theme =
        elementThemeName !== undefined
          ? getTheme(defaultTheme, previewThemeName ?? elementThemeName)
          : defaultTheme

      const shouldHide = useMemo(() => {
        const isEmpty =
          hasOnlyEmptyTextInNodes([element]) &&
          element.children.every(isElementType(['heading', 'paragraph']))

        return readOnly && isEmpty
      }, [element, readOnly])

      const nodeRef = useRef<HTMLElement | null>(null)
      const [indicateDrop, setIndicateDrop] = useState<'before' | 'after'>('before')
      const columnsAreWrapped = useMediaQuery(`(max-width: ${wrapColumnBreakpoint})`)

      const [{ isDraggingSelf }, dragRef] = useDrag<VerticalStackDragItem, void, { isDraggingSelf: boolean }>(
        () => ({
          type: DragItemTypes.VerticalStack,
          item: {
            type: DragItemTypes.VerticalStack,
            id: element.id,
            parentId,
            path,
            width: nodeRef.current?.clientWidth,
            theme: theme,
          },
          collect: monitor => ({
            isDraggingSelf: !!monitor.isDragging(),
          }),
        }),
        [path, element, parentId, nodeRef, theme]
      )

      const [{ isOver, canDrop }, dropRef] = useDrop<
        VerticalStackDragItem,
        unknown,
        { isOver: boolean; canDrop: boolean }
      >(
        () => ({
          accept: DragItemTypes.VerticalStack,
          drop: (item, monitor) => {
            if (monitor.didDrop()) return

            if (indicateDrop === 'before') {
              if (isFirstSibling) return Transforms.moveNodes(editor, { at: item.path, to: path })
              if (isLastSibling)
                return Transforms.moveNodes(editor, { at: item.path, to: Path.previous(path) })
              if (Path.isBefore(item.path, path))
                return Transforms.moveNodes(editor, { at: item.path, to: Path.previous(path) })

              return Transforms.moveNodes(editor, { at: item.path, to: path })
            }

            // indicateDrop === 'after'
            if (isFirstSibling) return Transforms.moveNodes(editor, { at: item.path, to: Path.next(path) })
            if (isLastSibling) return Transforms.moveNodes(editor, { at: item.path, to: path })
            if (Path.isBefore(item.path, path))
              return Transforms.moveNodes(editor, { at: item.path, to: path })

            return Transforms.moveNodes(editor, { at: item.path, to: Path.next(path) })
          },
          collect: monitor => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
          }),
          canDrop: item => {
            if (item.parentId !== parentId) return false
            if (item.id === element.id) return false

            return true
          },
          hover: (_, monitor) => {
            if (!monitor.isOver({ shallow: true }) || !monitor.canDrop()) return

            const hoveringPosition = monitor.getClientOffset()
            const targetBoundingBox = nodeRef.current?.getBoundingClientRect()

            if (hoveringPosition !== null && targetBoundingBox !== undefined) {
              if (columnsAreWrapped) {
                setIndicateDrop(
                  hoveringPosition.y > targetBoundingBox.y + targetBoundingBox.height / 2 ? 'after' : 'before'
                )
              } else {
                setIndicateDrop(
                  hoveringPosition.x > targetBoundingBox.x + targetBoundingBox.width / 2 ? 'after' : 'before'
                )
              }
            }
          },
        }),
        [
          indicateDrop,
          editor,
          path,
          parentId,
          element.id,
          nodeRef,
          columnsAreWrapped,
          isFirstSibling,
          isLastSibling,
        ]
      )

      const { isDragging: isDraggingSibling, draggedItem } = useDragLayer<{
        isDragging: boolean
        draggedItem: VerticalStackDragItem
      }>(monitor => ({
        isDragging: monitor.isDragging(),
        draggedItem: monitor.getItem(),
      }))

      const isNewPageCard = useAtomValue(newPageCardColumnColorAtom)

      dropRef(nodeRef)

      return (
        <ThemeProvider theme={theme}>
          <VerticalWrapper
            {...attributes}
            {...props}
            ref={current => {
              nodeRef.current = current
              if (ref === null) return
              else if (typeof ref === 'function') ref(current)
              else ref.current = current
            }}
            readOnly={readOnly}
            $isEmpty={shouldHide}
            $backgroundColor={iife(() => {
              if (isNewPageCard) {
                return elementThemeName === undefined
                  ? color(theme.home.textColor).opacity(0.04)
                  : color(theme.home.backgroundColor)
              } else {
                const hasSameBackgroundAsCard =
                  elementThemeName === undefined ||
                  theme.home.backgroundColor === defaultTheme.home.backgroundColor

                return hasSameBackgroundAsCard ? color('transparent') : color(theme.home.backgroundColor)
              }
            })}
            $hoverColor={iife(() => {
              if (isNewPageCard) {
                return elementThemeName === undefined
                  ? color(theme.home.textColor).opacity(0.08)
                  : color(theme.home.backgroundColor).shift(0.05)
              } else {
                return color(theme.home.backgroundColor).shift(0.04)
              }
            })}
            indicateDrop={indicateDrop}
            isDragging={isDraggingSelf}
            isOver={isOver && canDrop}
            disableHover={isDraggingSibling && draggedItem.parentId !== parentId}
          >
            {!readOnly && (
              <TopGroupButton isDragging={isDraggingSibling} contentEditable={false}>
                <StacksColorPicker
                  onMouseOver={(themeName: string) => {
                    const theme = CourseTheme.parse({ type: 'preset', name: themeName })
                    setPreviewThemeName(theme.name)
                  }}
                  onMouseOut={() => {
                    if (previewThemeName !== undefined) setPreviewThemeName(undefined)
                  }}
                  onClick={(themeName: string): void => {
                    const theme = CourseTheme.parse({ type: 'preset', name: themeName })
                    const elementTheme = element.theme

                    if (_.isEqual(elementTheme, theme)) {
                      updateNodeWithId(editor, element.id, { theme: undefined })
                      setPreviewThemeName(undefined)
                    } else {
                      updateNodeWithId(editor, element.id, { theme })
                    }
                  }}
                  selectedTheme={elementThemeName}
                />
                {siblings.length > 2 && (
                  <DeleteButton
                    variant='secondary'
                    size='small'
                    iconId='trash-can'
                    tooltip={t('author.table.delete-column')}
                    onClick={() => {
                      removeNodeWithId(editor, element.id)
                    }}
                  />
                )}
                <DragButton
                  variant='secondary'
                  size='small'
                  iconId='draggable'
                  tooltip={t('author.move-column')}
                  // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
                  ref={dragRef as (el: HTMLButtonElement) => void}
                />
              </TopGroupButton>
            )}

            {firstChild !== undefined && !containsTextNode(firstChild) && (
              <AddContentButton
                onClick={() => {
                  Transforms.insertNodes(editor, createParagraph(), { at: path.concat(0) })
                  const anchor = rangeOfNode(editor, path)[0]
                  Transforms.setSelection(editor, { anchor, focus: anchor })
                }}
              />
            )}

            <VerticalContent>{children}</VerticalContent>

            {lastChild !== undefined && !containsTextNode(lastChild) && (
              <AddContentButton
                onClick={() => {
                  Transforms.insertNodes(editor, createParagraph(), {
                    at: path.concat(element.children.length),
                  })
                  const anchor = rangeOfNode(editor, path)[1]
                  Transforms.setSelection(editor, { anchor, focus: anchor })
                }}
              />
            )}

            {!readOnly && isLastSibling && siblings.length < 4 && (
              <ThemeProvider theme={legacyLight}>
                <AddColumnButtonWrapper>
                  <Tooltip title='Add column'>
                    <AddButton
                      onClick={() => {
                        insertNodeAfterNodeWithId(editor, element.id, createVerticalStack())
                      }}
                    >
                      <Icon iconId='add' size='size-14' />
                    </AddButton>
                  </Tooltip>
                </AddColumnButtonWrapper>
              </ThemeProvider>
            )}
          </VerticalWrapper>
        </ThemeProvider>
      )
    }
  ),
  plugin: withVerticalStack,
}

const HorizontalWrapper = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding: 0;
  grid-column: 2 / -2;

  @media screen and (max-width: ${wrapColumnBreakpoint}) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  max-width: unset;

  gap: 1rem;

  ${AddColumnButtonWrapper} {
    opacity: 0;
  }

  &:hover {
    ${AddColumnButtonWrapper} {
      opacity: 1;
    }
  }
`

export const HorizontalStack: BlockDefinition = {
  Wrapper: forwardRef<HTMLDivElement, SlateWrapperProps>(({ children, ...props }, ref) => {
    const { element } = props
    assertElementType('horizontal-stack', element)

    return (
      <RenderingContext
        // We do not want to show placeholders in sections
        placeholder={{ type: 'untranslated', value: '' }}
      >
        <BlockWrapper {...props} ref={ref}>
          <HorizontalWrapper>
            <RenderingContext withGrid={false}>{children}</RenderingContext>
          </HorizontalWrapper>
        </BlockWrapper>
      </RenderingContext>
    )
  }),
  plugin: withHorizontalStack,
  actionsVerticalAlignment: 'top',
}
