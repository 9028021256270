import { isEnabled as isEnabledBlockQuote } from 'sierra-client/views/v3-author/block-quote/isEnabled'
import { isEnabled as isEnabledCode } from 'sierra-client/views/v3-author/code/isEnabled'
import { isInElement } from 'sierra-client/views/v3-author/command'
import { isEnabled as isEnabledEmbed } from 'sierra-client/views/v3-author/embed/isEnabled'
import { isEnabled as isEnabledEmbedUrlSpecific } from 'sierra-client/views/v3-author/embed/isEnabledUrlEmbed'
import { isEnabled as isEnabledFileAttachment } from 'sierra-client/views/v3-author/file-attachment/isEnabled'
import { isEnabled as isEnabledFlipCard } from 'sierra-client/views/v3-author/flip-cards/isEnabled'
import { isEnabled as isEnabledHeading } from 'sierra-client/views/v3-author/heading/isEnabled'
import { isEnabled as isEnabledImage } from 'sierra-client/views/v3-author/images/isEnabled'
import { isEnabled as isEnabledLink } from 'sierra-client/views/v3-author/link/isEnabled'
import { isEnabled as isEnabledLists } from 'sierra-client/views/v3-author/list/isEnabled'
import { isEnabled as isEnabledChecklist } from 'sierra-client/views/v3-author/list/isEnabledChecklist'
import { isEnabled as isEnabledMarkdown } from 'sierra-client/views/v3-author/markdown/isEnabled'
import { isEnabled as isEnabledMatrix } from 'sierra-client/views/v3-author/matrix/is-enabled'
import { isEnabled as isEnabledPreamble } from 'sierra-client/views/v3-author/preamble/isEnabled'
import { isEnabled as isEnabledSections } from 'sierra-client/views/v3-author/sections/isEnabled'
import { isEnabled as isEnabledSeperator } from 'sierra-client/views/v3-author/separator/isEnabled'
import * as ContentGeneration from 'sierra-client/views/v3-author/slash-menu/content-generation'
import * as Domain from 'sierra-client/views/v3-author/slash-menu/domain'
import { SlashMenuEntry } from 'sierra-client/views/v3-author/slash-menu/types'
import { isEnabled as isEnabledGenerateTakeaways } from 'sierra-client/views/v3-author/takeaways/isEnabled'
import { isEnabled as isEnabledVideo } from 'sierra-client/views/v3-author/video/isEnabled'
import { Flags } from 'sierra-domain/api/private'
import { SlashMenuEntryId } from 'sierra-domain/v3-author/slash-menu'
import { Editor } from 'slate'

function defineTextGenerator({ label }: { label: string }): SlashMenuEntry {
  return {
    label: { type: 'untranslated', value: label },
    icon: 'generate--paragraph',
    isEnabled: () => {
      return false
    },
    edit: ContentGeneration.generateParagraph(),
  }
}

export const isSlashMenuEntryEnabled = (editor: Editor, flags: Flags, entry: SlashMenuEntry): boolean => {
  return (
    entry.isEnabled?.({ editor, flags }) === true &&
    !isInElement(editor, [
      'question-card-multiple-choice-alternative-option',
      'question-card-multiple-choice-alternative-explanation',
      'assessment-introduction',
      'check-list-item',
      'code',
      'table-cell',
      'table-header-cell',
      'preamble',
      'block-quote',
      'takeaways',
      'poll-card-alternative',
    ])
  )
}

export const slashMenuEntries: Readonly<Record<SlashMenuEntryId, SlashMenuEntry>> = {
  'table': {
    label: 'dictionary.table',
    icon: 'table--split',
    isEnabled: ({ editor }) =>
      !isInElement(editor, 'flip-card') &&
      !isInElement(editor, 'question-card') &&
      !isInElement(editor, 'vertical-stack'),
    edit: Domain.addTable,
  },
  'matrix': {
    label: { type: 'untranslated', value: 'Add matrix' }, //Matrix-TODO: Add translation
    icon: 'rating--3', //Matrix-TODO: Add icon
    isEnabled: (
      { editor, flags } //Matrix-TODO: Check this
    ) => isEnabledMatrix({ editor, flags }),
    edit: Domain.addMatrix,
  },
  'heading1': {
    label: 'font.display',
    icon: 'headline-1',
    isEnabled: isEnabledHeading,
    edit: Domain.addHeading(5),
  },
  'heading2': {
    label: 'font.title',
    icon: 'headline-2',
    isEnabled: isEnabledHeading,
    edit: Domain.addHeading(0),
  },
  'heading3': {
    label: 'font.heading',
    icon: 'headline-3',
    isEnabled: isEnabledHeading,
    edit: Domain.addHeading(2),
  },
  'heading4': {
    label: 'font.subheading',
    icon: 'headline-4',
    isEnabled: isEnabledHeading,
    edit: Domain.addHeading(3),
  },
  'turn-into-paragraph': {
    label: 'author.slate.turn-into.paragraph',
    icon: 'paragraph',
    isEnabled: ({ editor }) => isInElement(editor, 'heading') && !isInElement(editor, 'list-item'),
    edit: Domain.turnIntoParagraph,
  },
  'generate-takeaways': {
    label: 'author.slate.generate-takeaway',
    icon: 'generate--takeaway',
    isEnabled: ({ editor }) => isEnabledGenerateTakeaways(editor),
    edit: ContentGeneration.generateTakeaways,
  },
  'add-takeaways': {
    label: 'author.slate.add-takeaway',
    icon: 'checkbox--checked',
    isEnabled: ({ editor }) => isEnabledGenerateTakeaways(editor),
    edit: Domain.addTakeaways,
  },
  'paragraph': {
    label: 'author.slate.paragraph',
    icon: 'paragraph',
    isEnabled: ({ editor }) => !isInElement(editor, 'list-item'),
    edit: Domain.addParagraph,
  },
  'link': {
    label: 'author.slate.link.link',
    icon: 'link',
    isEnabled: isEnabledLink,
    edit: Domain.addLink,
  },
  'bullet-list': {
    label: 'author.slate.bullet-list',
    icon: 'list--bulleted',
    isEnabled: isEnabledLists,
    edit: Domain.addBulletedList(),
  },
  'numbered-list': {
    label: 'author.slate.numbered-list',
    icon: 'list--numbered',
    isEnabled: isEnabledLists,
    edit: Domain.addNumberedList(),
  },
  'check-list': {
    label: 'author.slate.checklist',
    icon: 'list--checked',
    isEnabled: isEnabledChecklist,
    edit: Domain.addChecklist,
  },
  'block-quote': {
    label: 'author.slate.block-quote',
    icon: 'quote',
    isEnabled: isEnabledBlockQuote,
    edit: Domain.addBlockQuote,
  },
  'image': {
    label: 'author.slate.image',
    icon: 'image',
    isEnabled: isEnabledImage,
    edit: Domain.addImage,
  },
  'video': {
    label: 'author.slate.video',
    icon: 'video',
    isEnabled: isEnabledVideo,
    edit: Domain.addVideo,
  },
  'preamble': {
    label: 'author.slate.preamble',
    icon: 'preamble',
    isEnabled: isEnabledPreamble,
    edit: Domain.addPreamble,
  },
  'file-attachment': {
    label: 'author.slate.file-attachment',
    icon: 'document--blank',
    isEnabled: isEnabledFileAttachment,
    edit: Domain.addFileAttachment,
  },
  'separator': {
    label: 'author.slate.separator',
    icon: 'divider',
    isEnabled: isEnabledSeperator,
    edit: Domain.addSeparator,
  },
  'code': {
    label: 'author.slate.code',
    icon: 'code',
    isEnabled: isEnabledCode,
    edit: Domain.addCode,
  },
  'embed': {
    label: 'author.slate.embed',
    icon: 'code', //todo: was author-embed
    isEnabled: isEnabledEmbed,
    edit: Domain.addEmbed(undefined),
  },
  'markdown': {
    label: 'author.slate.markdown',
    icon: 'markdown',
    isEnabled: isEnabledMarkdown,
    edit: Domain.addMarkdown,
  },
  'flip-cards': {
    label: 'author.slate.flip-cards',
    icon: 'touch',
    isEnabled: isEnabledFlipCard,
    edit: Domain.addFlipCards,
  },
  'section-2': {
    label: 'author.slate.section-2',
    icon: 'sections--2',
    isEnabled: isEnabledSections,
    edit: Domain.addSections(2),
  },
  'section-3': {
    label: 'author.slate.section-3',
    icon: 'sections--3',
    isEnabled: isEnabledSections,
    edit: Domain.addSections(3),
  },
  'section-4': {
    label: 'author.slate.section-4',
    icon: 'sections--4',
    isEnabled: isEnabledSections,
    edit: Domain.addSections(4),
  },
  'generate-text-100': defineTextGenerator({ label: 'Generate paragraph' }),
  'generate-text-150': defineTextGenerator({ label: 'Generate lesson' }),
  'generate-text-50': defineTextGenerator({ label: 'Generate lesson' }),
  'question-card': {
    label: 'author.slate.add-inline-question',
    icon: 'checkmark--outline',
    isEnabled: ({ editor }) => !isInElement(editor, ['flip-card', 'question-card', 'vertical-stack']),
    edit: Domain.addQuestionCard,
  },
  'figma': {
    label: { type: 'untranslated', value: 'Figma' },
    icon: 'figma-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('figma'),
  },
  'airtable': {
    label: { type: 'untranslated', value: 'Airtable' },
    icon: 'airtable-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('airtable'),
  },
  'drive': {
    label: { type: 'untranslated', value: 'Google Drive' },
    icon: 'drive-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('drive'),
  },
  'loom': {
    label: { type: 'untranslated', value: 'Loom' },
    icon: 'loom-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('loom'),
  },
  'miro': {
    label: { type: 'untranslated', value: 'Miro' },
    icon: 'miro-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('miro'),
  },
  'pitch': {
    label: { type: 'untranslated', value: 'Pitch' },
    icon: 'pitch-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('pitch'),
  },
  'youtube': {
    label: { type: 'untranslated', value: 'Youtube' },
    icon: 'youtube-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('youtube'),
  },
  'trello': {
    label: { type: 'untranslated', value: 'Trello' },
    icon: 'trello-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('trello'),
  },
  'vimeo': {
    label: { type: 'untranslated', value: 'Vimeo' },
    icon: 'vimeo-logo',
    isEnabled: isEnabledEmbedUrlSpecific,
    edit: Domain.addEmbed('vimeo'),
  },
}
