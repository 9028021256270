import { motion } from 'framer-motion'
import React, { Dispatch, useEffect, useReducer } from 'react'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { ChatContainer } from 'sierra-client/features/sana-now/chat/chat-container'
import { useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Drawer } from 'sierra-ui/components'
import { getElevatedSurfaceStyles } from 'sierra-ui/components/elevation/elevated-surface-styles'
import styled from 'styled-components'

const OPEN_WIDTH = 350
const HIDDEN_WIDTH = 0

const VideoAvatarColumnCombinedSidebar = styled(motion.div)`
  height: 100%;
`

type SidebarState = 'open' | 'hidden'

type SidebarTransitionState = {
  currentState: SidebarState
  transitioning: boolean
  nextState?: SidebarState
}
type Action = { type: 'change-state'; nextState: SidebarState } | { type: 'animation-done' }

const sidebarStateWidths: Record<SidebarState, number> = {
  open: OPEN_WIDTH,
  hidden: HIDDEN_WIDTH,
}

export const RightSidebarContent: React.FC = () => {
  const { liveSessionId } = useLiveSessionIdContext()
  const liveSessionNanoId12 = ScopedLiveSessionId.extractId(liveSessionId)

  const liveSessionPermission = useLiveSessionPermissions(liveSessionNanoId12)
  const hasLiveSessionChatPermission = liveSessionPermission.has('CHAT')

  if (!hasLiveSessionChatPermission) {
    return null
  }

  return <ChatContainer showClose liveSessionId={liveSessionId} />
}

// This container reserves space and adds padding
const OuterContainer = styled.div<{ $addPadding: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${p => p.$addPadding && '0.25rem'};
  padding-left: 0;
`

// This container sets border-radius and box-shadow
const InnerContainer = styled.div`
  ${getElevatedSurfaceStyles({ shadowSize: 'large', border: true })};
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`

const MessagesContainer = styled(motion.div)`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const UpdateReducerState = ({
  dispatch,
}: {
  flexibleContentId: CreateContentId
  dispatch: Dispatch<Action>
}): JSX.Element | null => {
  const rightSidebarContext = useRightSidebarContext()

  useEffect(() => {
    if (rightSidebarContext.isOpen) {
      return dispatch({ type: 'change-state', nextState: 'open' })
    }

    return dispatch({ type: 'change-state', nextState: 'hidden' })
  }, [rightSidebarContext.isOpen, dispatch])

  return null
}

type ChatProps = {
  flexibleContentId: CreateContentId
}

const MobileChat: React.FC<ChatProps> = () => {
  const rightSidebarContext = useRightSidebarContext()

  return (
    <Drawer
      disableScrollbarGutter
      overlayVariant='none'
      onClose={rightSidebarContext.close}
      open={rightSidebarContext.isOpen}
    >
      <RightSidebarContent />
    </Drawer>
  )
}

const DesktopChat: React.FC<ChatProps> = ({ flexibleContentId }) => {
  const [state, dispatch] = useReducer(
    (state: SidebarTransitionState, action: Action): SidebarTransitionState => {
      switch (action.type) {
        case 'change-state':
          if (sidebarStateWidths[action.nextState] !== sidebarStateWidths[state.currentState]) {
            return {
              nextState: action.nextState,
              currentState: state.currentState,
              transitioning: true,
            }
          } else {
            return {
              currentState: action.nextState,
              transitioning: false,
              nextState: undefined,
            }
          }
        case 'animation-done':
          return {
            transitioning: false,
            nextState: undefined,
            currentState: state.nextState ?? state.currentState,
          }
      }
    },
    { currentState: 'hidden', transitioning: false }
  )

  return (
    <>
      <UpdateReducerState dispatch={dispatch} flexibleContentId={flexibleContentId} />
      <VideoAvatarColumnCombinedSidebar
        animate={{
          width: sidebarStateWidths[state.nextState ?? state.currentState],
        }}
        initial={{
          width: 0,
        }}
        transition={{
          ease: [0.25, 0.1, 0.25, 1],
          duration: 0.2,
        }}
        onAnimationComplete={() => dispatch({ type: 'animation-done' })}
      >
        <OuterContainer $addPadding={state.currentState === 'open' || state.transitioning}>
          <InnerContainer>
            {state.currentState === 'open' && !state.transitioning && (
              <MessagesContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  ease: [0.25, 0.1, 0.25, 1],
                  duration: 0.15,
                }}
              >
                <RightSidebarContent />
              </MessagesContainer>
            )}
          </InnerContainer>
        </OuterContainer>
      </VideoAvatarColumnCombinedSidebar>
    </>
  )
}

export const SideBar = ({ flexibleContentId }: { flexibleContentId: CreateContentId }): JSX.Element => {
  const isMobile = useIsMobile()

  const ChatRenderer = isMobile ? MobileChat : DesktopChat

  return <ChatRenderer flexibleContentId={flexibleContentId} />
}
