import { motion } from 'framer-motion'
import { FC, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOutlineEdit } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit'
import { ProgramStepDragItem } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/drag-n-drop'
import { isNotDefined } from 'sierra-domain/utils'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const EmptyState = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      marginTop: 0,
    },
    visible: {
      opacity: 1,
      marginTop: 16,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  transition: {
    duration: 0.2,
  },
})<{ $indicateDrop: boolean }>`
  grid-column: 2 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 14px;
  height: 80px;
  margin-bottom: 8px;
  outline: 1px dashed ${p => token(p.$indicateDrop ? 'form/focus/border' : 'form/border/2')};
  transition: outline-color 200ms;
`

type CollectedSectionDragProps = { canDrop: boolean; isOver: boolean }

export const EmptyStateDropZone: FC<{ sectionIndex: number }> = ({ sectionIndex }) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const { addStepToEmptySection } = useOutlineEdit()

  const { t } = useTranslation()

  const [{ canDrop, isOver }, dropRef] = useDrop<
    ProgramStepDragItem,
    {
      index: number
    },
    CollectedSectionDragProps
  >(() => {
    return {
      accept: 'program-step',
      collect: monitor => {
        const dragged = monitor.getItem()

        return {
          canDrop: isNotDefined(dragged) ? false : monitor.canDrop(),
          isOver: monitor.isOver(),
        }
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return
        }

        addStepToEmptySection(item.id, sectionIndex)
        return { index: item.index }
      },
    }
  }, [addStepToEmptySection, sectionIndex])

  dropRef(nodeRef)

  return (
    <EmptyState ref={nodeRef} $indicateDrop={canDrop && isOver}>
      <Text color='foreground/muted' size='micro'>
        {t('manage.program.program-details.outline.empty-section')}
      </Text>
    </EmptyState>
  )
}
