import { SanaImage } from 'sierra-client/components/common/image'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { ScenarioChatState } from 'sierra-client/views/v3-author/scenario/chat/state'
import { ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Header = styled(View)`
  width: 50%;
  max-width: 500px;
  text-align: center;
  align-items: center;

  @media (max-width: ${v2_breakpoint.phone}) {
    width: 90%;
  }
`

const AssistantImage = styled(SanaImage)`
  width: 120px;
  border: 5px solid white;
  border-radius: 25px;
  transform: rotate(-3deg);
  margin-bottom: 16px;

  @media (max-width: ${v2_breakpoint.phone}) {
    margin-bottom: 8px;
  }
`

export const ScenarioHeader: React.FC<{
  file: ScenarioFile
  chatState: ScenarioChatState
  onStart: () => void
}> = ({ file, chatState, onStart }) => {
  const { character, scenario: _scenario } = file.data.input
  const { scenario: _generatedScenario } = file.data.generated ?? {}
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const scenario = _generatedScenario ?? _scenario

  return (
    <Header direction='column' gap={isMobile ? '12' : '16'}>
      <AssistantImage ratio='1:1' src={character?.avatar} />
      <Heading bold size={isMobile ? 'h5' : 'h3'} align='center'>
        {scenario?.title}
      </Heading>
      <Text size={isMobile ? 'small' : 'regular'} color='foreground/muted'>
        {scenario?.description}
      </Text>
      {chatState.type === 'init' && (
        <>
          <Spacer size='4' />
          <Button onClick={onStart}>{t('scenario-card.start-button-text')}</Button>{' '}
        </>
      )}
      <View gap='4'>
        <Icon size='size-12' iconId='locked--filled' color='foreground/muted' />
        <Text size='micro' color='foreground/muted'>
          {t('scenario-card.chat-header.private-notice')}
        </Text>
      </View>
    </Header>
  )
}
