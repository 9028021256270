import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { apiQueryOptions, CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { QueryCourseLearnerUnionResponse } from 'sierra-domain/api/content-v2'
import { HomeContentResponse } from 'sierra-domain/api/learn'
import { XRealtimeContentHome, XRealtimeContentQueryCourse } from 'sierra-domain/routes'

export const homeContentQuery = {
  ...apiQueryOptions(XRealtimeContentHome, { includeNotifications: false }),
  retry: false,
  staleTime: 5 * 1000, // 5 seconds
} as const satisfies UseQueryOptions<HomeContentResponse>

export const useLiveContentMutation = <TSelectData = QueryCourseLearnerUnionResponse>(
  courseId: string,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData, unknown> => {
  return useCachedQuery(
    XRealtimeContentQueryCourse,
    { courseId },
    {
      retry: false,
      ...options,
    }
  )
}
