import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

export const OverviewGrid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-areas: 'info' 'content';
  padding-bottom: 1.5rem;
  overflow: inherit;
  margin: 0 12px;

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    grid-template-columns: 1fr;
    grid-template-areas: 'info' 'content';
    margin: 0 48px;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    grid-template-columns: 5fr 80px 2fr 80px;
    grid-template-areas: 'content . info .';
  }
`

export const DetailsTop = styled(View).attrs({
  gap: '24',
  direction: 'column',
  alignItems: 'flex-start',
  padding: '24',
  paddingBottom: 'none',
})``

export const DetailsBottom = styled.div`
  padding: 0 1.5rem 1.5rem;
`

export const ProgressLineWrapper = styled.div`
  width: 100%;
`

// The extra -2px on the top margin is to center the progress bar on the hero image bottom edge.
export const OverviewDetails = styled.div<{
  $offsetHeight: number
  $negativeMargin: number
  $largeMobileMargin?: boolean
}>`
  width: 100%;

  grid-area: info;
  border-radius: 24px;
  box-shadow: 0px 8px 16px 0px #00000014;
  outline: 1px solid ${token('border/default')};
  margin-top: ${p => (p.$largeMobileMargin ?? false ? 'calc(-6rem - 2px)' : '-14rem')};

  background-color: ${token('surface/default')};
  z-index: 2;

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    min-width: fit-content;
    max-width: 30vw;
    margin-top: -18rem;
    margin-right: 32px;
    justify-self: flex-end;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    position: sticky;
    top: ${p => (p.$offsetHeight ? `${p.$offsetHeight}px` : `7rem`)};
    margin-top: ${p => (p.$negativeMargin ? `-${p.$negativeMargin}px` : `-23rem`)};
    transform: translateY(-50%);
    min-width: auto;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const FixedButtons = styled.div<{ $reveal: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  transform: ${p => (p.$reveal ? `translateY(0)` : 'translateY(101%)')};
  transition: transform 0.3s ease-out;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    transform: translateY(101%);
  }
`
