import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'

type SelfPacedCardScroll = {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>
  completedRef: React.MutableRefObject<HTMLSpanElement | null>
  scrollStarted: boolean
  scrollCompleted: boolean
  setScrollStarted: (started: boolean) => void
  setScrollCompleted: (completed: boolean) => void
  scrollBy: (y: number) => void
}

const SelfPacedCardScrollContext = createContext<SelfPacedCardScroll>({
  scrollRef: createRef<HTMLDivElement | null>(),
  completedRef: createRef<HTMLSpanElement | null>(),
  scrollStarted: false,
  scrollCompleted: false,
  setScrollStarted: () => {},
  setScrollCompleted: () => {},
  scrollBy: () => {},
})

export const SelfPacedCardScrollProvider: FCC = ({ children }) => {
  const { currentFile } = useSelfPacedFiles()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const completedRef = useRef<HTMLSpanElement | null>(null)
  const [scrollStarted, setScrollStarted] = useState(false)
  const [scrollCompleted, setScrollCompleted] = useState(false)

  // Scroll the layout to the top when we change cards
  // Reset scroll started when file changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }

    setScrollStarted(false)
  }, [currentFile])

  // Track scrolling
  useEffect(() => {
    const scrollElement = scrollRef.current

    if (scrollElement === null) {
      return
    }

    const updateScrollStarted = (): void => {
      setScrollStarted(scrollElement.scrollTop > 10)
    }

    scrollElement.addEventListener('scroll', updateScrollStarted)

    return () => {
      scrollElement.removeEventListener('scroll', updateScrollStarted)
    }
  }, [currentFile, setScrollStarted])

  const scrollBy = (y: number): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: y,
        behavior: 'smooth',
      })
    }
  }

  const value = useMemo(
    () => ({
      scrollRef,
      completedRef,
      scrollStarted,
      scrollCompleted,
      setScrollStarted,
      setScrollCompleted,
      scrollBy,
    }),
    [scrollCompleted, scrollStarted]
  )

  return <SelfPacedCardScrollContext.Provider value={value}>{children}</SelfPacedCardScrollContext.Provider>
}

export const useSelfPacedCardScroll = (): SelfPacedCardScroll => useContext(SelfPacedCardScrollContext)
