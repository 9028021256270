import React, { useRef, useState } from 'react'
import { getCloudinaryUrlFromFileContentVideo } from 'sierra-client/api/content'
import { HiddenInput } from 'sierra-client/components/common/inputs/hidden-input'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUploadVideo } from 'sierra-client/hooks/use-video-upload'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { CreateOperationState, apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { Button } from 'sierra-ui/primitives'

type RecordingNarrationProps = {
  operationState: CreateOperationState
  fileId: FileId
  assetContext: AssetContext
}

export const UploadVideoButton: React.FC<RecordingNarrationProps> = ({
  operationState,
  fileId,
  assetContext,
}) => {
  const { t } = useTranslation()
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const videoUploadRef = useRef<HTMLInputElement | null>(null)
  const user = useSelector(selectUser)

  const { uploadVideo } = useUploadVideo()

  const saveFile = async ({
    blob,
    createdBy,
  }: {
    blob: Blob
    createdBy: UserId | undefined
  }): Promise<void> => {
    const response = await uploadVideo(blob, assetContext)

    if (response !== undefined) {
      const { url, durationInSeconds } = response
      const cloudinaryUrl = getCloudinaryUrlFromFileContentVideo(url)
      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          file.narration = { url: cloudinaryUrl, durationInSeconds, createdBy }
        },
      })
    }
  }

  return (
    <>
      <HiddenInput
        ref={videoUploadRef}
        type='file'
        accept='video/mp4'
        onChange={async event => {
          const file = event.target.files?.[0]
          if (file !== undefined) {
            setIsUploadingVideo(true)
            try {
              await saveFile({
                blob: file,
                createdBy: user?.uuid,
              })
            } finally {
              setIsUploadingVideo(false)
            }
          }
        }}
      />

      <Button
        loading={isUploadingVideo}
        onClick={() => {
          videoUploadRef.current?.click()
        }}
        variant='secondary'
        icon='upload'
      >
        {t('dictionary.upload')}
      </Button>
    </>
  )
}
