import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { UsePathCollaborators, usePathCollaborators } from 'sierra-client/views/manage/paths/use-paths'
import { PathData } from 'sierra-domain/api/admin'
import {
  BaseListUsersRequest,
  ContentProgramEnrollment,
  ListContentEnrolledUsersResponse,
} from 'sierra-domain/api/manage'
import { PathId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsUnassignFromPath,
  XRealtimeAdminPathsDeletePath,
  XRealtimeAdminPathsDuplicatePath,
  XRealtimeAdminPathsListEnrolledGroups,
  XRealtimeAdminPathsListEnrolledUsers,
  XRealtimeAdminPathsPathDetail,
  XRealtimeAdminPathsPathEnrollmentsCount,
  XRealtimeAdminPathsUpdatePath,
} from 'sierra-domain/routes'

export type UsePathDetailsData = {
  path: PathData | undefined
  enrollments: number | undefined
  isLoading: boolean
  pathUserAssignments: ListContentEnrolledUsersResponse & { isLoading: boolean; filtered: boolean }
  pathGroupAssignments: ContentProgramEnrollment[]
  fetchPath: (pathId: PathId) => Promise<PathData>
  fetchEnrollmentCount: (pathId: PathId) => Promise<void>
  fetchUserAssignments: (
    pathId: PathId,
    filters?: Partial<BaseListUsersRequest>,
    options?: {
      reset?: boolean
      forCsv?: boolean
    }
  ) => Promise<ListContentEnrolledUsersResponse>
  // TODO: Is this parameter name correct?
  fetchGroupAssignments: (courseId: string) => Promise<ContentProgramEnrollment[]>
  deletePath: (pathId: PathId) => Promise<null>
  updatePath: (pathId: PathId, pathData: PathData) => Promise<void>
  duplicatePath: (pathId: PathId) => Promise<PathId>
  unassignFromPath: (pathId: PathId, unassigns: { userIds: UserId[] }) => Promise<void>
  collaborators: UsePathCollaborators['collaborators']
  fetchCollaborators: UsePathCollaborators['fetchCollaborators']
  updateCollaborators: UsePathCollaborators['updateCollaborators']
}

export const usePathDetails = (pathId: PathId): UsePathDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [path, setPath] = useState<PathData | undefined>(undefined)
  const [enrollments, setEnrollments] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [pathUserAssignments, setPathUserAssignments] = useState<UsePathDetailsData['pathUserAssignments']>({
    hasMore: false,
    data: [],
    isLoading: false,
    filtered: false,
  })
  const [pathGroupAssignments, setPathGroupAssignments] = useState<ContentProgramEnrollment[]>([])
  const { collaborators, fetchCollaborators, updateCollaborators } = usePathCollaborators()

  const fetchPath = useCallback<UsePathDetailsData['fetchPath']>(
    async fetchPathId => {
      const response = await postWithUserErrorException(XRealtimeAdminPathsPathDetail, {
        pathId: fetchPathId,
      })
      setPath(response.data)

      return response.data
    },
    [postWithUserErrorException]
  )

  const fetchEnrollmentCount = useCallback<UsePathDetailsData['fetchEnrollmentCount']>(
    async fetchPathId => {
      const response = await postWithUserErrorException(XRealtimeAdminPathsPathEnrollmentsCount, {
        requestedPathIds: [fetchPathId],
      })
      setEnrollments(response.pathToEnrollments[fetchPathId])
    },
    [postWithUserErrorException]
  )

  const updatePath = useCallback<UsePathDetailsData['updatePath']>(
    async (updatePathId, pathData) => {
      await postWithUserErrorException(XRealtimeAdminPathsUpdatePath, {
        pathId: updatePathId,
        data: pathData,
      })
      setPath(pathData)
    },
    [postWithUserErrorException]
  )

  const duplicatePath = useCallback<UsePathDetailsData['duplicatePath']>(
    async duplicatePathId => {
      const { duplicatePathId: duplicatedPathId } = await postWithUserErrorException(
        XRealtimeAdminPathsDuplicatePath,
        {
          pathId: duplicatePathId,
        }
      )
      return duplicatedPathId
    },
    [postWithUserErrorException]
  )

  const deletePath = useCallback<UsePathDetailsData['deletePath']>(
    async deletePathId => {
      return postWithUserErrorException(XRealtimeAdminPathsDeletePath, { pathId: deletePathId })
    },
    [postWithUserErrorException]
  )

  const unassignFromPath = useCallback<UsePathDetailsData['unassignFromPath']>(
    async (pathId, { userIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromPath, {
        pathId,
        userIds,
      })

      await fetchEnrollmentCount(pathId)
      setPathUserAssignments(current => ({
        ...current,
        data: current.data.filter(u => !userIds.includes(u.userInfo.baseUserInfo.userId)),
      }))
    },
    [postWithUserErrorException, fetchEnrollmentCount]
  )

  const fetchUserAssignments = useCallback<UsePathDetailsData['fetchUserAssignments']>(
    async (pathId, commonFilters, options = {}) => {
      setPathUserAssignments(current => ({
        ...current,
        isLoading: true,
        filtered: Boolean(commonFilters?.query),
      }))

      const response = await postWithUserErrorException(XRealtimeAdminPathsListEnrolledUsers, {
        pathId,
        commonFilters: {
          lastUserId: undefined,
          maxResults: options.forCsv === true ? enrollments : undefined,
          query: undefined,
          sortBy: { direction: 'asc', type: 'name' },
          requestedUserIds: undefined,
          groupIds: undefined,
          ...commonFilters,
        },
      })

      // Don't set state for CSV export
      if (options.forCsv === true) {
        setPathUserAssignments(current => ({
          ...current,
          isLoading: false,
          filtered: Boolean(commonFilters?.query),
        }))
        return response
      }

      setPathUserAssignments(current =>
        options.reset === true
          ? { ...response, isLoading: false, filtered: Boolean(commonFilters?.query) }
          : {
              hasMore: response.hasMore,
              data: current.data.concat(response.data),
              isLoading: false,
              filtered: Boolean(commonFilters?.query),
            }
      )
      return response
    },
    [enrollments, postWithUserErrorException]
  )

  const fetchGroupAssignments = useCallback<UsePathDetailsData['fetchGroupAssignments']>(
    async pathId => {
      const response = await postWithUserErrorException(XRealtimeAdminPathsListEnrolledGroups, {
        id: pathId,
      })
      setPathGroupAssignments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  // Fetches on load
  useEffect(() => {
    void (async () => {
      setIsLoading(true)
      await Promise.all([fetchPath(pathId), fetchEnrollmentCount(pathId), fetchCollaborators(pathId)])
      setIsLoading(false)
    })()
  }, [pathId, fetchPath, fetchEnrollmentCount, fetchCollaborators])

  useEffect(() => {
    void fetchUserAssignments(pathId, undefined, { reset: true })
  }, [pathId, fetchUserAssignments])

  useEffect(() => {
    void fetchGroupAssignments(pathId)
  }, [pathId, fetchGroupAssignments])

  return {
    path,
    enrollments,
    pathUserAssignments,
    pathGroupAssignments,
    isLoading,
    fetchPath,
    fetchUserAssignments,
    fetchGroupAssignments,
    fetchEnrollmentCount,
    deletePath,
    updatePath,
    duplicatePath,
    unassignFromPath,
    collaborators,
    fetchCollaborators,
    updateCollaborators,
  }
}

export const DEFAULT_PATH_DATA: PathData = {
  title: '',
  description: '',
  content: [],
  settings: {
    sequentialLearning: false,
    theme: {
      type: 'preset',
      name: 'white',
    },
    isPathVisible: false,
  },
  image: undefined,
}
