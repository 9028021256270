import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableProvidedProps,
  DropResult,
} from '@hello-pangea/dnd'
import { useCallback, useMemo, useState } from 'react'
import { DebugYDocShortcutActions } from 'sierra-client/editor/debug-ydoc-shortcut'
import { SlateDebugMode } from 'sierra-client/editor/debug/slate-debug-mode'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { FCC } from 'sierra-client/types'
import { ContentCommenting } from 'sierra-client/views/commenting/commenting'
import { LearnerNotesProvider } from 'sierra-client/views/learner-notes/learner-notes-context'
import {
  useEditorChatId,
  useEditorChatIdentifier,
  useEditorCommentingEnabled,
  useEditorId,
  useEditorReadOnly,
} from 'sierra-client/views/v3-author/context'
import * as DragCommands from 'sierra-client/views/v3-author/dnd'
import { InlineToolbar } from 'sierra-client/views/v3-author/inline-toolbar/inline-toolbar'
import { color } from 'sierra-ui/color'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { useSlateStatic } from 'slate-react'
import styled, { useTheme } from 'styled-components'

const EditorDropArea = styled.div<DroppableProvidedProps & DroppableProvided['innerRef']>`
  position: relative;
  height: fit-content;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const DragAndDropContainer: FCC<{ setContainer: (_: HTMLElement | null) => void }> = ({
  children,
  setContainer,
}) => {
  const editor = useSlateStatic()
  const onDragEnd = useCallback(
    (result: DropResult): void => DragCommands.onDragEnd(result, editor),
    [editor]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'top-level'}>
        {provided => (
          <EditorDropArea
            ref={node => {
              provided.innerRef(node)
              setContainer(node)
            }}
            {...provided.droppableProps}
          >
            {children}

            {provided.placeholder}
          </EditorDropArea>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export const EditorContent: FCC = ({ children }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const theme = useTheme()
  const editorId = useEditorId()
  const chatId = useEditorChatId()
  const chatIdentifier = useEditorChatIdentifier()
  const enableCommenting = useEditorCommentingEnabled()
  const isDebugMode = useIsDebugMode()
  const readOnly = useEditorReadOnly()

  const commentingColor = useMemo(() => color(theme.home.actionButtonBackgroundColor), [theme])

  return (
    <DragAndDropContainer setContainer={setContainer}>
      {container !== null ? (
        <ContentCommenting
          editorId={editorId}
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          container={container}
          enableCommenting={enableCommenting}
          color={commentingColor}
        >
          <ConditionalWrapper
            condition={readOnly}
            renderWrapper={children => (
              <LearnerNotesProvider container={container} color={commentingColor}>
                {children}
              </LearnerNotesProvider>
            )}
          >
            <InlineToolbar container={container} />
            {children}
          </ConditionalWrapper>
        </ContentCommenting>
      ) : (
        isDebugMode && <>[Debug] Waiting for the commenting content container to be set</>
      )}

      {container !== null && isDebugMode && <SlateDebugMode container={container} />}

      <DebugYDocShortcutActions />
    </DragAndDropContainer>
  )
}
