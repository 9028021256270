import { createFileRoute } from '@tanstack/react-router'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useScormContext } from 'sierra-client/context/scorm-context'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { usePost } from 'sierra-client/hooks/use-post'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { EditionPage } from 'sierra-client/views/learner/course/edition-page'
import { XRealtimeStrategyCourseGroupGetAssignedEdition } from 'sierra-domain/routes'
import { z } from 'zod'

const Page = (): JSX.Element | null => {
  const { postWithUserErrorException } = usePost()
  const [assignmentChecked, setAssignmentChecked] = React.useState(false)
  const skipAssignmentCheckParam = Route.useSearch({ select: search => search.skipAssignmentCheck })

  const { scormCourseGroupId, courseKind } = useScormContext()

  if (scormCourseGroupId === undefined) throw Error('No course group id defined')

  if (courseKind === undefined) throw Error('No course kind defined')

  useEffect(() => {
    let cancelled = false
    const doCheck = async (): Promise<void> => {
      if (skipAssignmentCheckParam === 'enabled') {
        setAssignmentChecked(true)
        return
      }

      const { edition } = await postWithUserErrorException(XRealtimeStrategyCourseGroupGetAssignedEdition, {
        courseGroupId: scormCourseGroupId,
      })

      if (cancelled) return

      if (edition === undefined) {
        // Continue
        setAssignmentChecked(true)
      } else {
        const { courseId, language } = edition

        if (courseKind === 'native:self-paced') {
          await i18next.changeLanguage(language)
          await getGlobalRouter().navigate({ to: `/s/${courseId}`, replace: true })
        } else throw Error('Invalid course kind')
      }
    }

    void doCheck()

    return () => {
      cancelled = true
    }
  }, [scormCourseGroupId, skipAssignmentCheckParam, postWithUserErrorException, courseKind])

  return (
    <SanaPage
      mode='light'
      headerType='none'
      page={PageIdentifier.LearnerSelectEditionPage({ courseGroupId: scormCourseGroupId })}
    >
      {assignmentChecked && <EditionPage scormCourseGroupId={scormCourseGroupId} />}
    </SanaPage>
  )
}

export const Route = createFileRoute('/select-edition')({
  component: requireLoggedIn(Page, { allowScorm: true }) as React.FC,
  validateSearch: z.object({
    skipAssignmentCheck: z.string().optional(),
  }),
})
