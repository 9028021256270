import { Dispatch, SetStateAction } from 'react'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/components/util/show-intercom-launcher'
import { EditTeamspaceGeneralTab } from 'sierra-client/features/teamspace/components/modals/edit-modal/tabs/general-tab'
import { EditTeamspaceMembersTab } from 'sierra-client/features/teamspace/components/modals/edit-modal/tabs/members-tab'
import { EditTeamspacePermissionsTab } from 'sierra-client/features/teamspace/components/modals/edit-modal/tabs/permissions-tab'
import { PanelBody } from 'sierra-client/features/teamspace/components/modals/panels/panel-elements'
import { TeamspaceIcon } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { useHasTeamspacePermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { useDateToLocaleString } from 'sierra-client/utils/date-utils'
import { Teamspace } from 'sierra-domain/api/teamspace'
import { LightUser } from 'sierra-domain/user'
import { isDefined } from 'sierra-domain/utils'
import { ClosePanelButton, Panel, Tabs } from 'sierra-ui/components'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'

const CreationInformation: React.FC<{ teamspace: Teamspace }> = ({ teamspace }) => {
  const { t } = useTranslation()
  const createdAtText = useDateToLocaleString(teamspace.createdAt)
  const ownerIds = teamspace.userMembers
    .filter(member => member.role === 'owner')
    .map(member => member.userId)
  const users = useUsersLegacy(ownerIds).filter(isDefined)

  return (
    <View gap='2'>
      <Text color='foreground/muted'>{t('teamspace.created-by')}</Text>
      <Text bold color='foreground/muted'>
        {users[0]?.firstName} {users[0]?.lastName}
      </Text>
      <Text color='foreground/muted'>
        {t('dictionary.on')} {createdAtText}
      </Text>
    </View>
  )
}

export const EditTeamSpaceModal: React.FC<{
  open: boolean
  onClose: () => void
  teamspace: Teamspace
  mode: 'edit' | 'read-only'
  tab: string
  setTab: Dispatch<SetStateAction<string>>
  requestAccessUser?: LightUser
}> = ({ open, onClose, teamspace, mode, tab, setTab, requestAccessUser }) => {
  const { t } = useTranslation()
  const hasListMembersPermission = useHasTeamspacePermission(teamspace.id, 'LIST_MEMBERS')
  const createdAtText = useDateToLocaleString(teamspace.createdAt)

  useOverrideIntercomLauncherVisibility(open ? 'hidden' : 'default')

  const allTabs = [
    {
      id: 'general',
      label: t('teamspace.edit-modal.tab.general'),
      content: <EditTeamspaceGeneralTab onClose={onClose} teamspace={teamspace} />,
    },
    {
      id: 'members',
      label: t('teamspace.edit-modal.tab.members'),
      content: (
        <EditTeamspaceMembersTab mode={mode} requestAccessUser={requestAccessUser} teamspace={teamspace} />
      ),
    },
    {
      id: 'permissions',
      label: t('teamspace.edit-modal.tab.permissions'),
      content: <EditTeamspacePermissionsTab onClose={onClose} teamspace={teamspace} />,
    },
  ]

  const editTeamspaceTabs = hasListMembersPermission ? allTabs : allTabs.filter(tab => tab.id !== 'members')

  return (
    <Panel
      size={{ width: 656 }}
      animation={'slide-right'}
      open={open}
      onClose={onClose}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={onClose} ariaLabel={t('dictionary.close')} />
      <PanelBody>
        <View direction='column' marginBottom='24' gap='2'>
          <Heading size='h5' bold>
            <TeamspaceIcon displayName={teamspace.displayName} themeName={teamspace.iconTheme} />
            <Spacer size='8' />
            {teamspace.displayName}
          </Heading>

          {hasListMembersPermission ? (
            <CreationInformation teamspace={teamspace} />
          ) : (
            <Text color='foreground/muted'>
              {t('manage.programs.created-at', {
                date: createdAtText,
              })}
            </Text>
          )}
        </View>
        {mode === 'edit' ? (
          <Tabs items={editTeamspaceTabs} value={tab} onChange={newTab => setTab(newTab)} grow />
        ) : (
          <EditTeamspaceMembersTab mode={mode} requestAccessUser={requestAccessUser} teamspace={teamspace} />
        )}
      </PanelBody>
    </Panel>
  )
}
