import React, { useCallback, useState } from 'react'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/components/util/show-intercom-launcher'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIntercomDirectTrackEvent } from 'sierra-client/intercom/track'
import { useCachedQuery } from 'sierra-client/state/api'
import { DiscardAlert } from 'sierra-client/views/manage/components/alerts/discard-alert'
import { PanelHeadline } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { LinkInvite } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/invite-link'
import { SetAttributesPanel } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/set-attributes-panel'
import { InviteViaEmailTab } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/invite-via-email-tab'
import { useUserInvitationDomainLoader } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { XRealtimeAdminEmailInvitesHasMandatoryUserAttributesData } from 'sierra-domain/routes'
import { isDefined, isNullable } from 'sierra-domain/utils'
import { Layout, Panel, Tabs } from 'sierra-ui/components'
import { PanelInPanel } from 'sierra-ui/components/layout-kit/layout-panel'
import { TabItem } from 'sierra-ui/components/tabs/tabs'
import { LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

type InviteUsersPanelProps = {
  canAssignContent: boolean
  canInviteViaEmail: boolean
  canInviteViaGroupLink: boolean
  isOpen: boolean
  closeDirectly: () => void
  onSave: () => void
}

type TabID = 'invite-via-email' | 'invite-via-link'

const TabWrapper = styled(View).attrs({ paddingTop: 'small', direction: 'column', gap: '16', grow: true })`
  height: 100%;
`

const InviteViews: React.FC<{
  canInviteViaEmail: boolean
  canInviteViaGroupLink: boolean
  closeDirectly: () => void
  closeWithValidation: () => void
  onSave: () => void
}> = ({ closeDirectly, closeWithValidation, onSave, canInviteViaEmail, canInviteViaGroupLink }) => {
  const [currentTab, setCurrentTab] = useState<TabID>(
    canInviteViaEmail ? 'invite-via-email' : 'invite-via-link'
  )
  const { t } = useTranslation()

  const res = useCachedQuery(
    XRealtimeAdminEmailInvitesHasMandatoryUserAttributesData,
    {},
    { staleTime: 60 * 1000 }
  )

  if (isNullable(res.data)) {
    return <LoadingSpinner />
  }

  if (res.data === true) {
    if (!canInviteViaEmail) {
      return <Text color='foreground/muted'>{t('dictionary.no-permissions')}</Text>
    } else {
      return (
        <InviteViaEmailTab
          onSave={onSave}
          closeWithValidation={closeWithValidation}
          closeDirectly={closeDirectly}
        />
      )
    }
  }

  const tabItems: TabItem<TabID>[] = [
    canInviteViaEmail
      ? {
          id: 'invite-via-email' as const,
          label: t('manage.users.invite.tabs.invite-via-email'),
          content: (
            <InviteViaEmailTab
              onSave={onSave}
              closeWithValidation={closeWithValidation}
              closeDirectly={closeDirectly}
            />
          ),
        }
      : undefined,
    canInviteViaGroupLink
      ? {
          id: 'invite-via-link' as const,
          label: t('manage.users.invite.tabs.invite-via-link'),
          content: (
            <TabWrapper>
              <LinkInvite />
            </TabWrapper>
          ),
        }
      : undefined,
  ].filter(isDefined)

  return (
    <Tabs
      value={currentTab}
      onChange={(newTab: string) => setCurrentTab(newTab as TabID)}
      grow={true}
      hideLine={true}
      items={tabItems}
    />
  )
}

const InviteUsersPanelInner: React.FC<InviteUsersPanelProps> = ({
  isOpen,
  closeDirectly,
  onSave,
  canAssignContent,
  canInviteViaEmail,
  canInviteViaGroupLink,
}) => {
  const [alertDiscardOpen, { on: openAlertDiscard, off: closeAlertDiscard }] = useToggle(false)
  const { t } = useTranslation()
  const intercomTrackEvent = useIntercomDirectTrackEvent()

  const { hasChanged, editingAttributes, closeAttributeEdit, resetConfig } = useComposeUserInvitationConfig()

  useOverrideIntercomLauncherVisibility(isOpen ? 'hidden' : 'default')

  const closeWithValidation = useCallback(() => {
    if (hasChanged) {
      openAlertDiscard()
    } else {
      closeDirectly()
      resetConfig()
    }
  }, [resetConfig, closeDirectly, hasChanged, openAlertDiscard])

  // Send intercom event if we enter edit attributes mode
  // There is currently no better way to do this without changing code inside the useComposeUserInvitationConfig
  useOnChanged((wasEditing, isEditing) => {
    if (wasEditing !== true && isEditing === true) {
      intercomTrackEvent('[TOUR]-add-details-for-users')
    }
  }, editingAttributes)

  return (
    <>
      <Panel
        size={{ width: 650 }}
        animation='slide-right'
        padding='none'
        onClose={closeWithValidation}
        open={isOpen}
        id='impact-intercom-product-tour--edit-attributes-overview'
      >
        <Layout
          height='100%'
          panels={[
            <PanelInPanel key='edit-attributes' open={editingAttributes} onEscapeKeyDown={closeAttributeEdit}>
              <SetAttributesPanel canAssignContent={canAssignContent} />
            </PanelInPanel>,
          ]}
        >
          <View direction='column' padding='32' paddingBottom='none' grow>
            <PanelHeadline>{t('admin.organization.users.invite-users')}</PanelHeadline>
            <Spacer size='8' />
            <InviteViews
              closeDirectly={closeDirectly}
              closeWithValidation={closeWithValidation}
              onSave={onSave}
              canInviteViaEmail={canInviteViaEmail}
              canInviteViaGroupLink={canInviteViaGroupLink}
            />
          </View>
        </Layout>
      </Panel>
      <DiscardAlert
        open={alertDiscardOpen}
        onClose={closeAlertDiscard}
        onConfirm={() => {
          closeAlertDiscard()
          closeDirectly()
          resetConfig()
        }}
      />
    </>
  )
}

export const InviteUsersPanel: React.FC<InviteUsersPanelProps> = props => {
  const { isLoading } = useUserInvitationDomainLoader()

  // todo(workflows): We only want to do this if there's no data, otherwise we'll reset the form
  // when loading new data.
  if (isLoading) return null

  return <InviteUsersPanelInner {...props} />
}
