import { useMemo } from 'react'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  LSFilter,
  UseManageCourseLiveSessionsOutput,
} from 'sierra-client/views/manage/courses/use-manage-course-live-sessions'
import { GroupRow } from 'sierra-domain/api/manage'
import { BaseGroupInfo } from 'sierra-domain/base-group-info'
import { MenuItem, Popover } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const MinWidth = styled.div`
  min-width: 160px;
`

const GroupPopover = styled.div`
  width: 30rem;
  background-color: ${palette.primitives.white};
  padding: 0.5rem;
`

type LiveSessionUserGroupFilterProps = {
  title: string
  groupsData: GroupRow[]
  selectedGroupIds: string[]
  setGroupIds: (groupIds: string[]) => void
}

export const LiveSessionUserGroupFilter: React.FC<LiveSessionUserGroupFilterProps> = ({
  title,
  groupsData,
  selectedGroupIds,
  setGroupIds,
}) => {
  const { t } = useTranslation()
  const { groupOptions, selectedGroups } = useMemo<{
    selectedGroups: BaseGroupInfo[]
    groupOptions: BaseGroupInfo[]
  }>(() => {
    const groupOptions = groupsData.map(g => g.groupInfo)
    const selectedGroups = groupOptions.filter(g => selectedGroupIds.includes(g.groupId))
    return { groupOptions, selectedGroups }
  }, [selectedGroupIds, groupsData])
  return (
    <MinWidth>
      <Popover
        align='start'
        renderTrigger={() => (
          <DefaultDropdownTrigger grow>
            <View gap='xxsmall'>
              {selectedGroupIds.length > 0 && (
                <Text size='small' bold color='grey35'>
                  {selectedGroupIds.length}
                </Text>
              )}
              <Text>{title}</Text>
            </View>
          </DefaultDropdownTrigger>
        )}
      >
        <GroupPopover>
          <ChipInputWithAutocomplete<BaseGroupInfo, true, true, false>
            value={selectedGroups}
            onChange={(_, values) => setGroupIds(values.map(g => g.groupId))}
            options={groupOptions}
            getOptionLabel={option => option.groupName ?? ''}
            autoFocus
            inputPlaceholder={t('author.course-settings.skill-placeholder')}
            size='small'
            filterSelectedOptions
            multiple
            limitTags={5}
            freeSolo={false}
            open
          />
        </GroupPopover>
      </Popover>
    </MinWidth>
  )
}

const IGNORE_FILTER = 'all' as const
export const LiveSessionAssignedFilter: React.FC<{
  value: LSFilter['assignedToLiveSession']
  onChange: (value: LSFilter['assignedToLiveSession']) => void
}> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const items: MenuItem<Exclude<LSFilter['assignedToLiveSession'], undefined> | typeof IGNORE_FILTER>[] = [
    {
      type: 'label',
      id: 'all',
      label: t('manage.live-session.filter.any-assignment'),
    },
    {
      type: 'label',
      id: 'assigned',
      label: t('manage.live-session.filter.assigned'),
    },
    {
      type: 'label',
      id: 'not-assigned',
      label: t('manage.live-session.filter.not-assigned'),
    },
  ]
  const selectedItem = items.find(item => item.id === value) ?? items.find(item => item.id === IGNORE_FILTER)

  return (
    <MinWidth>
      <SingleSelectDropdown
        selectedItem={selectedItem}
        onSelect={item => {
          if (item.id === IGNORE_FILTER) {
            onChange(undefined)
            return
          } else {
            onChange(item.id)
          }
        }}
        menuItems={items}
      />
    </MinWidth>
  )
}

export const ClearAllFilters: React.FC<Pick<UseManageCourseLiveSessionsOutput, 'filter' | 'setFilter'>> = ({
  setFilter,
}) => {
  const { t } = useTranslation()

  return (
    <IconButton
      tooltip={t('dashboard.search.clear-hover')}
      iconId='close'
      onClick={() =>
        setFilter({
          groups: [],
          programs: [],
          query: '',
          assignedToLiveSession: undefined,
          status: undefined,
        })
      }
    />
  )
}
