import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { getFlag } from 'sierra-client/config/global-config'
import { DataKey, useOnce } from 'sierra-client/hooks/use-once'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { cardBackgroundStyles } from 'sierra-client/views/flexible-content/card-background'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { AiBackground, Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 40ch;
  padding: 2.5rem;
  margin: auto;
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Image = styled.div<{ $background?: string }>`
  width: 100%;
  height: 100%;

  ${cardBackgroundStyles}
`

const IconContainer = styled.div`
  position: relative;
`

const CloseIcon = styled(IconButton).attrs({
  iconId: 'close',
  variant: 'transparent',
})`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const NavigationContainer = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 2.5rem;
`

const Dot = styled.button<{ $selected: boolean }>`
  background-color: ${p => (p.$selected ? token('foreground/primary') : token('foreground/muted'))};
  width: ${p => (p.$selected ? '6px' : '4px')};
  aspect-ratio: 1 / 1;
  border-radius: 99px;
  padding: 0;
  cursor: pointer;
  transition: ease-in-out 0.2s;
`

const ProgressContainer = styled(View)`
  height: 32px;
  justify-content: center;
`

type ProgressBarProps = {
  noOfSteps: number
  currentStep: number
  gotoIndex: (index: number) => void
}

const ProgressBar: React.FC<ProgressBarProps> = ({ noOfSteps, currentStep, gotoIndex }) => {
  return (
    <ProgressContainer>
      {Array(noOfSteps)
        .fill(null)
        .map((_, index) => (
          <Dot key={index} $selected={index === currentStep} onClick={() => gotoIndex(index)} />
        ))}
    </ProgressContainer>
  )
}

type PaginatedNewsModalProps = {
  useOnceKey: DataKey
  content: {
    title: string
    body: string[]
    imagePath: string
  }[]

  // Uses the AI background behind the images, for use with transparent images.
  useAiImageBackground?: boolean
}

type AnimationDirection = 'forward' | 'backward'
const animations = {
  enter: (direction: AnimationDirection) => {
    return {
      x: direction === 'forward' ? '20%' : '-20%',
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: AnimationDirection) => {
    return {
      x: direction === 'forward' ? '-20%' : '20%',
      opacity: 0,
    }
  },
}
const transition = { duration: 0.2, ease: [0.25, 0, 0.25, 1] }

const SlideContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
`

const SlideAnimation: FCC<{ direction: AnimationDirection; currentIndex: number }> = ({
  currentIndex,
  direction,
  children,
}) => {
  return (
    <AnimatePresence mode='popLayout' initial={false} custom={direction}>
      <SlideContainer
        key={currentIndex}
        custom={direction}
        variants={animations}
        initial='enter'
        animate='center'
        exit='exit'
        transition={transition}
      >
        {children}
      </SlideContainer>
    </AnimatePresence>
  )
}

const MODAL_WIDTH = 550
const MODAL_HEIGHT = 600

export const PaginatedNewsModal: React.FC<PaginatedNewsModalProps> = ({
  useOnceKey,
  content,
  useAiImageBackground,
}) => {
  const showNewsModal = getFlag('news-modal')
  const { t } = useTranslation()
  const { showOnce, setSeen } = useOnce(useOnceKey)
  const [currentIndex, setCurrentIndex] = useState(0)
  const previousIndex = usePrevious(currentIndex)

  const animationDirection: AnimationDirection = (previousIndex ?? -1) < currentIndex ? 'forward' : 'backward'

  const current = content[currentIndex]
  assertIsNonNullable(current)

  const imageSrc = getFileContentImage(current.imagePath, {
    optimize: false,
    bucket: 'sierra-static',
  })

  const onNext = (): void => {
    if (currentIndex === content.length - 1) {
      setSeen()
      return
    }

    setCurrentIndex(currentIndex + 1)
  }

  const onPrev = (): void => {
    if (currentIndex === 0) {
      setSeen()
      return
    }

    setCurrentIndex(currentIndex - 1)
  }

  if (!showOnce || !showNewsModal) return null

  return (
    <Modal disableScrollbarGutter open onClose={setSeen} size={{ width: MODAL_WIDTH, height: MODAL_HEIGHT }}>
      <IconContainer>
        <CloseIcon onClick={setSeen} />
      </IconContainer>

      <ImageContainer>
        {useAiImageBackground === true && <AiBackground base='blueVivid' variant='primary' />}
        <SlideAnimation direction={animationDirection} currentIndex={currentIndex}>
          <Image key={currentIndex} $background={imageSrc} />
        </SlideAnimation>
      </ImageContainer>

      <ModalContent>
        <SlideAnimation direction={animationDirection} currentIndex={currentIndex}>
          <BodyContainer>
            <Heading size='h5' bold align='center'>
              {current.title}
            </Heading>
            <Spacer />
            <ScrollView direction='column' gap='xxsmall'>
              {current.body.map((text, i) => (
                <Text size='regular' key={i} color='foreground/secondary'>
                  {text}
                </Text>
              ))}
            </ScrollView>
          </BodyContainer>
        </SlideAnimation>
      </ModalContent>

      <NavigationContainer>
        {currentIndex > 0 ? (
          <Button variant='secondary' onClick={onPrev}>
            {t('modal.back')}
          </Button>
        ) : (
          <div />
        )}
        <ProgressBar noOfSteps={content.length} currentStep={currentIndex} gotoIndex={setCurrentIndex} />
        <View marginLeft='auto'>
          <Button
            onClick={onNext}
            decoratorPosition='right'
            icon={currentIndex !== content.length - 1 ? 'next--filled' : 'checkmark--filled'}
          >
            {currentIndex === content.length - 1 ? t('dictionary.done') : t('dictionary.next')}
          </Button>
        </View>
      </NavigationContainer>
    </Modal>
  )
}
