import { useEffect } from 'react'
import { selectNextUpFileId } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { preloadFileImage } from 'sierra-client/views/flexible-content/card-background/preload-file-image'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'

export const NextCardImagePreload = (): null => {
  const { currentFile, flexibleContentId, fileIds, getFile } = useSelfPacedFiles()
  const nextFileId = useSelector(state => selectNextUpFileId(state, flexibleContentId))(
    currentFile?.id,
    fileIds
  )
  const nextFile = nextFileId !== undefined ? getFile(nextFileId) : undefined

  useEffect(() => {
    if (nextFile) preloadFileImage(nextFile)
  }, [nextFile])

  return null
}
