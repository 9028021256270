import { useMemo } from 'react'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import { OrganizationLink, SquareLogo } from 'sierra-client/features/multi-tenancy/components'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useOrganizationConfig } from 'sierra-client/features/multi-tenancy/hooks/use-organization-config'
import { isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'

/**
 * This hook constructs the menu items needed for the sidebar dropdown presentation.
 * It pulls in data via `useOrganizationCluster`.
 */
export const useOrganizationClusterMenuItems = (): Array<MenuItem> => {
  const orgCluster = useOrganizationCluster()
  const organizationConfig = useOrganizationConfig()

  const menuItems = useMemo(() => {
    if (orgCluster.loading || orgCluster.cluster.length === 0 || !organizationConfig.isSuccess) {
      return []
    }

    return [
      {
        type: 'group',
        id: 'organizations-group',
        label: 'Organizations',
        menuItems: orgCluster.cluster.map(org => {
          return {
            type: 'canvas',
            id: org.domain,
            label: org.name,
            render() {
              const active = org.domain === organizationConfig.data.domain

              return (
                <OrganizationLink href={`//${org.domain}`} target='_blank'>
                  {isDefined(org.squareLogoUrl) ? (
                    <SquareLogo src={org.squareLogoUrl} />
                  ) : (
                    <OrganizationLogo orgName={org.name} brandSettings={undefined} />
                  )}

                  <Text bold={active}>{org.name}</Text>

                  {org.isClusterParent === true && <Icon iconId='building' color='foreground/muted' />}
                </OrganizationLink>
              )
            },
          }
        }),
      },
      {
        type: 'separator',
        id: 'organizations-separator',
      },
    ] satisfies MenuItem[]
  }, [orgCluster.cluster, orgCluster.loading, organizationConfig.isSuccess, organizationConfig.data])

  return menuItems
}
