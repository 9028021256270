import { keepPreviousData, useMutation } from '@tanstack/react-query'
import { atom, createStore, useAtom, useSetAtom } from 'jotai'
import React, { useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ExpensiveContentFragmentFragment } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/components/util/show-intercom-launcher'
import { SelectContentForm } from 'sierra-client/features/skills/components/assign-content/select-content-form'
import { useInvalidateSkillContentTableQuery } from 'sierra-client/features/skills/components/tabular/skill-content-table-query'
import {
  assignImplicitSkillLevelToContent,
  getSkillGeneralInfoQuery,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  FilterMultiSelectTabular,
  ToolbarContainer,
  ToolbarRightContainer,
  ToolbarSearch,
  type FilterMultiSelectMenuItem,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { PanelHeadline } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { filterEmptyFilters } from 'sierra-domain/filter/transport'
import { isDefined } from 'sierra-domain/utils'
import { ClosePanelButton, Panel } from 'sierra-ui/components'
import { Skeleton, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const AddContentContainer = styled(View)`
  height: 100%;
  overflow: hidden;
`

const assignContentPanelStore = createStore()
const OpenAssignContentPanelAtom = atom(false)

export const useSetOpenAssignContentPanel = (): ((value: boolean) => void) => {
  return useSetAtom(OpenAssignContentPanelAtom, {
    store: assignContentPanelStore,
  })
}

const getAssignableContent = graphql(`
  query AssignableContent(
    $query: String
    $limit: Int
    $cursor: String
    $filter: ContentFilter
    $sortBy: [ContentSortAttributeInput!]
  ) {
    content(
      query: $query
      limit: $limit
      cursor: $cursor
      filter: $filter
      sortBy: $sortBy
      contentTypes: [COURSE, PROGRAM, PATH]
    ) {
      cursor
      data {
        ...ExpensiveContentFragment
        ... on NativeCourseGroup {
          courseEditions {
            edition {
              ...ExpensiveContentFragment
            }
          }
        }
        ... on ScormCourseGroup {
          courseEditions {
            edition {
              ...ExpensiveContentFragment
            }
          }
        }
      }
    }
  }
`)

const createSkillContentFilter = (skillId: SkillId): Filter => ({
  type: 'filter.filter',
  domain: {
    type: 'content.skill',
  },
  operator: {
    type: 'operator.not-contains',
  },
  predicate: {
    type: 'predicate.or',
    values: [
      {
        type: 'value.uuid',
        value: skillId,
      },
    ],
  },
})

const unsupportedContentFilter = (): Filter => ({
  type: 'filter.filter',
  domain: {
    type: 'content.classification',
  },
  operator: {
    type: 'operator.neq',
  },
  predicate: {
    type: 'predicate.and',
    values: [
      {
        type: 'value.string',
        value: 'live',
      },
      {
        type: 'value.string',
        value: 'event',
      },
    ],
  },
})

type ContentTypeFilter = 'course' | 'external' | 'program' | 'path'

const createContentFilter = (contentType: ContentTypeFilter[]): Filter => ({
  type: 'filter.filter',
  domain: {
    type: 'content.classification',
  },
  operator: {
    type: 'operator.eq',
  },
  predicate: {
    type: 'predicate.or',
    values: contentType.map(value => ({
      type: 'value.string',
      value,
    })),
  },
})

const ContentFilterMultiSelect: React.FC<{ onFilterChange: (filter: ContentTypeFilter[]) => void }> = ({
  onFilterChange,
}) => {
  const { t } = useTranslation()

  const menuItems: FilterMultiSelectMenuItem<ContentTypeFilter>[] = [
    {
      id: 'course',
      label: t('dictionary.course-plural'),
    },
    {
      id: 'external',
      label: t('dictionary.external'),
    },
    {
      id: 'program',
      label: t('dictionary.program-plural'),
    },
    {
      id: 'path',
      label: t('dictionary.path-plural'),
    },
  ]

  return <FilterMultiSelectTabular menuItems={menuItems} onFilterChange={onFilterChange} />
}

export const AssignContentPanel: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const { t } = useTranslation()
  const notification = useNotif()
  const tracking = useTracking()

  const [open, setOpen] = useAtom(OpenAssignContentPanelAtom, { store: assignContentPanelStore })
  const handleClose = (): void => {
    setOpen(false)
  }

  useOverrideIntercomLauncherVisibility(open ? 'hidden' : 'default')

  const skillQuery = useGraphQuery({ document: getSkillGeneralInfoQuery }, { id: skillId })
  const title = skillQuery.data?.skill?.name

  const [searchQuery, setSearchQuery] = useDebouncedState('', { wait: 100 })
  const [contentFilter, setContentFilter] = useState<ContentTypeFilter[]>([])

  useOnChanged((_, value) => {
    if (!value) {
      setSearchQuery('')
      setContentFilter([])
    }
  }, open)

  const contentQuery = useGraphQuery(
    {
      document: getAssignableContent,
      queryOptions: {
        enabled: open,
        placeholderData: keepPreviousData,
      },
    },
    {
      query: searchQuery,
      filter: JSON.stringify(
        filterEmptyFilters({
          type: 'filter.and',
          filters: [
            unsupportedContentFilter(),
            createSkillContentFilter(skillId),
            createContentFilter(contentFilter),
          ],
        })
      ),
      limit: 500,
    }
  )

  const content: ExpensiveContentFragmentFragment[] = isDefined(contentQuery.data)
    ? Array.from(contentQuery.data.content.data)
    : []

  const invalidateSkillContentTableQuery = useInvalidateSkillContentTableQuery(skillId)

  const { mutate: assignSkillToContentMutation } = useMutation({
    mutationFn: ({ contentIds, skillId }: { contentIds: string[]; skillId: SkillId }) => {
      return graphQuery(assignImplicitSkillLevelToContent, {
        contentIds,
        skillId,
      })
    },
    onSuccess() {
      void invalidateSkillContentTableQuery()
    },
    onError() {
      notification.push({ type: 'error' })
    },
  })

  const handleSubmit = (contentToAssign: ExpensiveContentFragmentFragment[]): void => {
    assignSkillToContentMutation(
      {
        contentIds: contentToAssign.map(it => it.contentId),
        skillId: skillId,
      },
      {
        onSuccess() {
          tracking.content.addContent({
            skillId,
            content: contentToAssign.map(content => ({
              contentType: content.contentType,
              courseKind: 'courseKind' in content ? content.courseKind : undefined,
            })),
          })
          handleClose()
        },
      }
    )
  }

  return (
    <Panel
      size={{ width: 656 }}
      animation={'slide-right'}
      open={open}
      onClose={handleClose}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={handleClose} ariaLabel={t('dictionary.close')} />
      <AddContentContainer direction='column' gap='8'>
        <View paddingTop='32' paddingLeft='40' paddingRight='40' marginBottom='32'>
          <PanelHeadline>{t('skills.add-content.title', { title })}</PanelHeadline>
        </View>

        <View paddingLeft='40' paddingRight='40' direction='column' gap='16'>
          <ToolbarContainer>
            <ToolbarSearch onQueryChange={setSearchQuery} />
            <ToolbarRightContainer>
              <ContentFilterMultiSelect onFilterChange={setContentFilter} />
            </ToolbarRightContainer>
          </ToolbarContainer>
        </View>

        {contentQuery.isLoading ? (
          <View
            paddingLeft='40'
            paddingRight='40'
            marginTop='10'
            justifyContent='center'
            direction='column'
            gap='24'
          >
            <Skeleton $height={50} $width='100%' $radius={12} />
            <Skeleton $height={50} $width='100%' $radius={12} />
            <Skeleton $height={50} $width='100%' $radius={12} />
            <Skeleton $height={50} $width='100%' $radius={12} />
          </View>
        ) : (
          <SelectContentForm onCancel={handleClose} content={content} onSubmit={handleSubmit} />
        )}
      </AddContentContainer>
    </Panel>
  )
}
