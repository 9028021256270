import React, { useMemo, useState } from 'react'
import { useAblyYDocWithAwareness } from 'sierra-client/collaboration/use-ably-ydoc'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { useAddSubtitleTracks } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { VideoIsTranscribingIndicator } from 'sierra-client/components/blocks/video/video-transcription-loading-indicator'
import { getFlag } from 'sierra-client/config/global-config'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUploadVideoWithProgress } from 'sierra-client/hooks/use-video-upload-with-progress'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { VideoCardNarration } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import {
  NarrationState,
  NarrationStateSync,
} from 'sierra-client/views/flexible-content/card-narration/narration-state-sync'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { Upload } from 'sierra-client/views/v3-author/common/media-uploader/shared'
import { acceptedVideoTypes, maxFileSizeGb } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { getNarrationsYMap } from 'sierra-domain/collaboration/narrations'
import {
  createScopedNarrationId,
  ScopedCreateContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, VideoData } from 'sierra-domain/flexible-content/types'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, Modal } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider } from 'sierra-ui/theming'
import styled from 'styled-components'

const VideoPlaceholder = styled(View).attrs({
  justifyContent: 'center',
  cursor: 'pointer',
})`
  width: 100%;
  height: 100%;
`

type Video = {
  url?: string
  disableSkipping?: boolean
  disableSubtitles?: boolean
}

export type VideoCardProps = {
  readOnly: boolean
  video: VideoData['video']
  onUploadDone?: (video: Video) => void
  setVideoDuration?: (durationInSeconds: number) => void
  assetContext: AssetContext
  file: File
}

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  && > .video-js {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }
`

const NarrationModal: React.FC<{
  fileId: FileId
  scopedCreateContentId: ScopedSelfPacedContentId
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  narrationModalOpen: boolean
  closeNarrationModal: () => void
}> = ({
  fileId,
  scopedCreateContentId,
  narrationSettings,
  narrationMetadata,
  narrationModalOpen,
  closeNarrationModal,
}) => {
  const isAiVideoAvatarsEnabled = getFlag('ai-video-avatars')
  const contentId = ScopedSelfPacedContentId.extractId(scopedCreateContentId)
  const yDocId = createScopedNarrationId({ scopedFileId: fileId, scopedCreateContentId })
  const yDocContext = useAblyYDocWithAwareness(yDocId)

  const {
    quotaExceededOverride,
    errorGeneratingNarrationOverride,
    showDebugData,
    minutesUsedOverride,
    synthesiaUnavailableOverride,
  } = useDebug('ai-narrations', {
    quotaExceededOverride: ['no-override', 'exceeded'] as const,
    errorGeneratingNarrationOverride: ['no-override', 'error'] as const,
    synthesiaUnavailableOverride: ['no-override', 'unavailable'] as const,
    showDebugData: false,
    minutesUsedOverride: ['no-override', '5%', '50%', '95%', '100%', '150%'] as const,
  })

  const isDebugMode = useIsDebugMode()

  const narrationsYMap = useMemo(() => {
    const yDoc = yDocContext?.yDoc
    if (yDoc === undefined) {
      return undefined
    }
    return getNarrationsYMap(yDoc)
  }, [yDocContext])

  if (yDocContext?.yDoc === undefined) {
    return <Debug>Narrations yDoc was undefined</Debug>
  }

  if (narrationsYMap === undefined) {
    return <Debug>narrationsYMap is undefined</Debug>
  }

  const { usage, characterLimit, quota, avatars, defaultAvatarId } = narrationSettings
  const minutesUsed = iife(() => {
    const actualUsage = usage?.minutesUsed ?? 0

    switch (minutesUsedOverride) {
      case 'no-override':
        return actualUsage
      case '5%':
        return Math.ceil(quota * 0.05)
      case '50%':
        return Math.ceil(quota * 0.5)
      case '95%':
        return Math.ceil(quota * 0.95)
      case '100%':
        return quota
      case '150%':
        return Math.ceil(quota * 1.5)
      default:
        assertNever(minutesUsedOverride)
    }
  })

  const quotaExceeded = iife(() => {
    const actualValue = minutesUsed >= quota
    if (isDebugMode && quotaExceededOverride === 'exceeded') {
      return true
    } else {
      return actualValue
    }
  })

  const errorGeneratingNarration = iife(() => {
    const actualValue = narrationMetadata?.type === 'error'
    if (isDebugMode && errorGeneratingNarrationOverride === 'error') {
      return true
    } else {
      return actualValue
    }
  })

  if (!isAiVideoAvatarsEnabled) return null

  return (
    <LightTokenProvider>
      <Modal
        open={narrationModalOpen}
        onClose={closeNarrationModal}
        disableScrollbarGutter
        size={{ width: 500 }}
      >
        <VideoCardNarration
          contentId={contentId}
          fileId={fileId}
          narrationMetadata={narrationMetadata}
          quotaExceeded={quotaExceeded}
          characterLimit={characterLimit}
          minutesUsed={minutesUsed}
          quota={quota}
          yDoc={yDocContext.yDoc}
          awareness={yDocContext.awareness}
          avatars={avatars}
          showDebugData={showDebugData}
          errorGeneratingNarration={errorGeneratingNarration}
          defaultAvatarId={defaultAvatarId}
          forceShowSynthesiaUnavailable={synthesiaUnavailableOverride === 'unavailable'}
          onClose={closeNarrationModal}
        />
      </Modal>
    </LightTokenProvider>
  )
}

const GenerateNarrationButton: React.FC<{
  onNarrationModalOpenChange: () => void
}> = ({ onNarrationModalOpenChange }) => {
  const { t } = useTranslation()

  return (
    <Button
      icon='sana-symbol'
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        onNarrationModalOpenChange()
      }}
    >
      {t('author.video.generate-video')}
    </Button>
  )
}

const CreateVideoCard: React.FC<{
  video: VideoData['video']
  onUploadDone?: (video: Video) => void
  setVideoDuration?: (durationInSeconds: number) => void
  assetContext: AssetContext
  file: File
}> = ({ video, onUploadDone, setVideoDuration, assetContext, file }) => {
  const { t } = useTranslation()
  const { uploadVideo } = useUploadVideoWithProgress()
  const addSubtitles = useAddSubtitleTracks(video.url, video.disableSubtitles !== true)
  const isAiVideoAvatarsEnabled = getFlag('ai-video-avatars')
  const [narrationModalOpen, setNarrationModalOpen] = useState(false)
  const [narrationState, setNarrationState] = useState<NarrationState | undefined>(undefined)

  const { permission } = useCreatePageYDocContext()
  const { scopedCreateContentId, createContentId, contentType } = useCreatePageContext()
  const { nodeId: selectedNodeId } = useCreatePageNodeIdContext()
  const narrationMetadata =
    selectedNodeId !== undefined ? narrationState?.narrations[selectedNodeId] : undefined

  const isSelfPacedContentId = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)

  const aiNarrationsOpen =
    selectedNodeId !== undefined && narrationModalOpen && isSelfPacedContentId && narrationState !== undefined

  const handleUploadDone = (payload: { url: string } | undefined): void => {
    if (payload === undefined) return

    onUploadDone?.({
      url: payload.url,
    })
  }

  const possibleNarrationUrl = file.narration?.url ?? video.url
  const videoUrl = isAiVideoAvatarsEnabled ? possibleNarrationUrl : video.url

  return videoUrl === undefined ? (
    <VideoPlaceholder>
      <Upload
        iconId={'play--outline'}
        accept={acceptedVideoTypes}
        additionalInfo={` (${t('author.slate.up-to', {
          number: maxFileSizeGb,
          filetype: 'GB',
        })})`}
        uploadMedia={uploadVideo}
        onUploaded={handleUploadDone}
        assetContext={assetContext}
        narrationButton={
          isAiVideoAvatarsEnabled ? (
            <GenerateNarrationButton onNarrationModalOpenChange={() => setNarrationModalOpen(true)} />
          ) : undefined
        }
      />
      <NarrationStateSync
        contentId={createContentId}
        canEdit={permission === 'edit'}
        contentType={contentType}
        setNarrationState={setNarrationState}
      />
      {aiNarrationsOpen && (
        <NarrationModal
          narrationModalOpen={narrationModalOpen}
          closeNarrationModal={() => {
            setNarrationModalOpen(false)
          }}
          fileId={selectedNodeId}
          scopedCreateContentId={scopedCreateContentId}
          narrationSettings={narrationState.settings}
          narrationMetadata={narrationMetadata}
        />
      )}
    </VideoPlaceholder>
  ) : (
    <CardWrapper>
      <VideoPlayer
        options={{
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
          },
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4',
            },
          ],
        }}
        onLoadedMetadata={player => {
          const duration = player.player().duration()
          if (!Number.isNaN(duration)) setVideoDuration?.(duration)
          addSubtitles(player)
        }}
        disableSkipping={false}
      />
      {video.url !== undefined && <VideoIsTranscribingIndicator videoUrl={video.url} />}
    </CardWrapper>
  )
}

const ReadOnlyVideoCard: React.FC<{ video: VideoData['video']; file: File }> = ({ video, file }) => {
  const isAiVideoAvatarsEnabled = getFlag('ai-video-avatars')

  const possibleNarrationUrl = file.narration?.url ?? video.url
  const videoUrl = isAiVideoAvatarsEnabled ? possibleNarrationUrl : video.url

  return videoUrl === undefined ? (
    <VideoPlaceholder>
      <Icon iconId='play--outline' color='grey35' />
      <Text size='regular' color='grey35'>
        No video set
      </Text>
    </VideoPlaceholder>
  ) : (
    <CardWrapper>
      <VideoPlayer
        options={{
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
          },
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4',
            },
          ],
        }}
        disableSkipping={video.disableSkipping}
      />
    </CardWrapper>
  )
}

export const VideoCard: React.FC<VideoCardProps> = ({
  video,
  onUploadDone,
  setVideoDuration,
  readOnly,
  assetContext,
  file,
}) => {
  if (readOnly) {
    return <ReadOnlyVideoCard video={video} file={file} />
  }

  return (
    <CreateVideoCard
      file={file}
      video={video}
      onUploadDone={onUploadDone}
      setVideoDuration={setVideoDuration}
      assetContext={assetContext}
    />
  )
}
