import { Embed } from 'sierra-domain/v3-author'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { SimpleStyledCSS } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export const imageNarrowLayoutMaxWidth = css`
  grid-column: 1 / -1;
`
export const imageWideLayoutMaxWidth = css`
  grid-column: 2 / -2;
`

type SizeVariantProps = {
  customSize?: number
  variant?: Embed['variant']
  maxWidth?: number
}

const NarrowSizeCss = ({ variant, customSize, maxWidth }: SizeVariantProps): SimpleStyledCSS => {
  if (customSize !== undefined) {
    return css`
      max-width: ${customSize}px;
      ${imageNarrowLayoutMaxWidth}
      margin-left: auto;
      margin-right: auto;
    `
  }
  switch (variant) {
    case 'narrow':
      return css`
        grid-column: 2 / -2;

        /* These are here to fix the case for when the image is inside the NoGridImgContainer.
        Without them the image fills all available space whereas now we have a "default size" before any manual resizing. */
        ${maxWidth !== undefined &&
        css`
          max-width: ${maxWidth}px;
          margin-left: auto;
          margin-right: auto;
        `}
      `
    case 'center':
      return css`
        grid-column: 2 / -2;

        margin: 0 20%;
      `
    case 'wide':
      return css`
        grid-column: 1 / -1;

        @media screen and (min-width: ${v2_breakpoint.desktop}) {
          margin: 0 10%;
        }
      `
    case 'full-width':
      return css`
        grid-column: 1 / -1;
      `
    default:
      return css`
        grid-column: 2 / -2;

        @media screen and (min-width: ${v2_breakpoint.phone}),
          print and (print-width: ${v2_breakpoint.phone}) {
          grid-column: 2 / span 12;
        }
      `
  }
}

const WideSizeCss = ({ variant, customSize, maxWidth }: SizeVariantProps): SimpleStyledCSS => {
  if (customSize !== undefined) {
    return css`
      ${imageWideLayoutMaxWidth}
    `
  }

  switch (variant) {
    case 'center':
      return css`
        max-width: 40ch;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `

    case 'narrow':
      return css`
        max-width: 85ch;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

        /* These are here to fix the case for when the image is inside the NoGridImgContainer.
        Without them the image fills all available space whereas now we have a "default size" before any manual resizing. */
        ${maxWidth !== undefined &&
        css`
          max-width: ${maxWidth}px;
        `}
      `

    case 'wide':
      return css`
        max-width: 100ch;
      `

    case 'full-width':
      return css`
        max-width: 100%;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `

    default:
      return css`
        padding: 0;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `
  }
}

export const SizeCss = css<SizeVariantProps>`
  ${p => (p.theme.editor.isWideLayout ? WideSizeCss : NarrowSizeCss)}
`

type WrapProps = SizeVariantProps & {
  isResizing?: boolean
}

export const Wrap = styled.div.attrs(({ customSize }: Pick<WrapProps, 'customSize'>) => ({
  style: {
    maxWidth: customSize === undefined ? undefined : `${customSize}px`,
  },
}))<WrapProps>`
  position: relative;
  transition: box-shadow 100ms ease-in-out;
  border-radius: 8px;

  && {
    ${SizeCss}
    padding: 0;
  }

  width: -webkit-fill-available;

  ${p => p.isResizing === true && 'user-select: none;'}
`
