import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import {
  selectFlexibleContentFile,
  selectNodeParentFolderId,
} from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import {
  isAbortingMultiSelectionClearAtom,
  multiSelectionAtom,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/atoms'
import { useIsEnabledMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-is-enabled-multi-selection'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import {
  MultiSelectionTimeoutProvider,
  useMultiSelectionTimeoutRef,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/setup/timeout-context'
import { isMetaKey } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'

export const _SetupMultiSelection: FCC<{
  createContentId: CreateContentId
  parentFolderId: FolderId
  currentCard: File
}> = ({ createContentId, currentCard, parentFolderId, children }) => {
  const { containsSelection, addSelection } = useMultiSelection()
  const setMultiSelection = useSetAtom(multiSelectionAtom)
  const isAnyMultiSelectionMenuOpen = useAtomValue(isAbortingMultiSelectionClearAtom)
  const setAbortingMultiSelectionClearAtom = useSetAtom(isAbortingMultiSelectionClearAtom)
  const { ref: timeoutRef } = useMultiSelectionTimeoutRef()

  const resetMultiSelection = useCallback((): void => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    // Selection should always include the current card
    setMultiSelection({
      [currentCard.id]: { file: currentCard, parentFolderId },
    })
    setAbortingMultiSelectionClearAtom(false)
  }, [currentCard, parentFolderId, setAbortingMultiSelectionClearAtom, setMultiSelection, timeoutRef])

  // When switching course, clear the selection
  useEffect(resetMultiSelection, [createContentId, currentCard.id, resetMultiSelection])

  // Selection should always include the current card
  useEffect(() => {
    if (!containsSelection(currentCard.id)) {
      addSelection([{ fileId: currentCard.id, info: { file: currentCard, parentFolderId } }])
    }
  }, [addSelection, containsSelection, currentCard, parentFolderId])

  // When clicking anywhere on the page, we remove the selection
  // unless we open a dropdown menu
  useEffect(() => {
    const clearSelection = (e: MouseEvent): void => {
      if (isMetaKey(e) || isAnyMultiSelectionMenuOpen) {
        return
      }

      resetMultiSelection()
    }
    document.addEventListener('click', clearSelection)

    return () => {
      document.removeEventListener('click', clearSelection)
    }
  }, [setMultiSelection, isAnyMultiSelectionMenuOpen, resetMultiSelection])

  return children
}

type SetupMultiSelection = { createContentId: CreateContentId; currentCardId: FileId | undefined }

export const SetupMultiSelection: FCC<SetupMultiSelection> = ({
  currentCardId,
  createContentId,
  children,
}) => {
  const isEnabledMultiSelection = useIsEnabledMultiSelection()
  const timeoutRef = useRef(null)
  const currentCard = useSelector(state => selectFlexibleContentFile(state, createContentId, currentCardId))
  const parentFolderId = useSelector(state => selectNodeParentFolderId(state, createContentId, currentCardId))

  if (!isEnabledMultiSelection || currentCard === undefined) {
    return children
  }

  return (
    <MultiSelectionTimeoutProvider value={{ ref: timeoutRef }}>
      <_SetupMultiSelection
        createContentId={createContentId}
        currentCard={currentCard}
        parentFolderId={parentFolderId ?? 'folder:root'}
      >
        {children}
      </_SetupMultiSelection>
    </MultiSelectionTimeoutProvider>
  )
}
