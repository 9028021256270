import { createFileRoute } from '@tanstack/react-router'
import { getFlag } from 'sierra-client/config/global-config'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { prefetchAdminCanEditUserAttributes } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attribute-settings-loader'
import { prefetchUserInvitationDomain } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { prefetchUserDetailData } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { ManageUserDetails } from 'sierra-client/views/manage/users/manage-user-details/manage-user-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { UserId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const userId = Route.useParams({ select: p => p.userId })

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageUserDetail({ userId })}>
      <ManageUserDetails key={userId} userId={userId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/users/$userId')({
  component: Page,
  validateSearch: z.object({
    groupId: z.string().optional(),
  }),
  params: {
    parse: z.object({ userId: UserId }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (context.isLoggedIn && getFlag('preloading')) {
      void prefetchUserInvitationDomain()
      void prefetchUserDetailData(params.userId)
      void prefetchAdminCanEditUserAttributes(params.userId)
    }
  },
})
