import React, { useEffect, useState } from 'react'
import type { ApplySyntaxHighlighting } from 'sierra-client/editor/syntax-highlighting/apply-syntax-highlighting'
import { getParent, isElementType } from 'sierra-client/views/v3-author/queries'
import { NodeEntry, Range, Text } from 'slate'
import { useSlateStatic } from 'slate-react'

type Decorate = (entry: NodeEntry) => Range[]

export function useSyntaxHighlighting(): Decorate {
  const editor = useSlateStatic()

  const [applySyntaxHighlighting, setApplySyntaxHighlighting] = useState<ApplySyntaxHighlighting | undefined>(
    undefined
  )

  useEffect(() => {
    void import('sierra-client/editor/syntax-highlighting/apply-syntax-highlighting').then(
      applySyntaxHighlighting => {
        setApplySyntaxHighlighting(() => applySyntaxHighlighting.default)
      }
    )
  }, [])

  const decorate: Decorate = React.useCallback(
    ([node, path]) => {
      if (applySyntaxHighlighting === undefined) return []
      const [parent] = getParent(editor, [node, path])
      if (!isElementType('code', parent)) return []
      const language = parent.language
      if (language === undefined) return []
      if (!Text.isText(node)) return []

      return applySyntaxHighlighting(editor, [node, path], language)
    },
    [applySyntaxHighlighting, editor]
  )
  return decorate
}
