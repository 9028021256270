import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import {
  HeaderButtonColorCSS,
  HeaderGroupLastButtonCSS,
} from 'sierra-client/components/liveV2/header-buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import * as flexSelectors from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { isInline } from 'sierra-client/views/v3-author/question-card/is-inline'
import { useIsInQuestionVariation } from 'sierra-client/views/v3-author/question-card/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Entity } from 'sierra-domain/entity'
import { File } from 'sierra-domain/flexible-content/types'
import { QuestionCard, QuestionCard as QuestionCardType, QuestionVariations } from 'sierra-domain/v3-author'
import { MenuItem } from 'sierra-ui/components'
import { Description, Label, TextContainer } from 'sierra-ui/components/menu'
import { IconButton, Switch, View } from 'sierra-ui/primitives'
import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const InnerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`

const useUpdateFunctions = (
  editor: Editor,
  questionCardOrVariations: Omit<Entity<QuestionCard>, 'children'> | Entity<QuestionVariations>
): {
  updateTests: (include: boolean) => void
  updateHideCorrectAnswers: (hideCorrectAnswers: boolean) => void
} => {
  const updateTests = useCallback(
    (include: boolean) => {
      updateNodeWithId(editor, questionCardOrVariations.id, {
        includeInPlacementTestAndReview: include,
      })

      if (questionCardOrVariations.type === 'question-variations') {
        questionCardOrVariations.children.forEach(questionCard => {
          updateNodeWithId(editor, questionCard.id, {
            includeInPlacementTestAndReview: include,
          })
        })
      }
    },
    [editor, questionCardOrVariations]
  )

  const updateHideCorrectAnswers = useCallback(
    (hideCorrectAnswers: boolean) => {
      updateNodeWithId(editor, questionCardOrVariations.id, {
        hideCorrectAnswers,
      })

      if (questionCardOrVariations.type === 'question-variations') {
        questionCardOrVariations.children.forEach(questionCard => {
          updateNodeWithId(editor, questionCard.id, {
            hideCorrectAnswers,
          })
        })
      }
    },
    [editor, questionCardOrVariations]
  )

  return { updateTests, updateHideCorrectAnswers }
}

const getItemMenuItems = (
  t: TranslationLookup,
  questionCard: Omit<Entity<QuestionCard>, 'children'> | Entity<QuestionVariations>,
  updateTests: (include: boolean) => void,
  updateHideCorrectAnswers: (hideCorrectAnswers: boolean) => void
): MenuItem[] => {
  return [
    {
      id: 'include-in-tests',
      type: 'canvas',
      render() {
        return (
          <InnerContainer>
            <TextContainer>
              <Label color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold={true}>
                {t('author.question-card.include-in-tests')}
              </Label>

              <Description color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
                {t('author.question-card.include-in-tests-desc')}
              </Description>
            </TextContainer>

            <View padding='none xxsmall'>
              <Switch
                ariaLabel='Include in tests'
                size='small'
                checked={questionCard.includeInPlacementTestAndReview === true}
                onChange={() => {
                  updateTests(questionCard.includeInPlacementTestAndReview !== true)
                }}
                disabled={false}
              />
            </View>
          </InnerContainer>
        )
      },
    },
    {
      id: 'hide-correct-answers',
      type: 'canvas',
      render() {
        return (
          <View direction='column'>
            <InnerContainer>
              <TextContainer>
                <Label color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
                  {t('author.question-card.hide-correct')}
                </Label>

                <Description color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
                  {t('author.question-card.hide-correct-desc')}
                </Description>
              </TextContainer>

              <View padding='none xxsmall'>
                <Switch
                  ariaLabel='Hide corrrect answers'
                  size='small'
                  checked={questionCard.hideCorrectAnswers ?? false}
                  onChange={() => {
                    updateHideCorrectAnswers(!(questionCard.hideCorrectAnswers ?? false))
                  }}
                  disabled={false}
                />
              </View>
            </InnerContainer>
          </View>
        )
      },
    },
  ]
}

const SettingsWrapper = styled.div`
  display: flex;
  z-index: 2;
`

export const SettingButtons: React.FC<{ element: Entity<QuestionCardType> | Entity<QuestionVariations> }> = ({
  element,
}) => {
  const editor = useSlateStatic()
  const readOnly = useEditorReadOnly()
  const isInlineQuestion = isInline({ editor })
  const isInQuestionVariationGroup = useIsInQuestionVariation({ nodeId: element.id })
  const { t } = useTranslation()
  const { file, flexibleContentId } = useFileContext()
  const selectedNodeId = file.id
  const currentFolder = useSelector(state =>
    flexSelectors.selectNodeParentFolder(state, flexibleContentId, selectedNodeId)
  )
  const includeInTestsIsHidden =
    currentFolder?.settings?.placementTestEnabled !== true && currentFolder?.settings?.reviewEnabled !== true

  const hasSettingsOnCard = (() => {
    if (readOnly) return false
    if (isInQuestionVariationGroup) return false
    else return isInlineQuestion
  })()

  const { updateTests, updateHideCorrectAnswers } = useUpdateFunctions(editor, element)

  const iconMenuItems: MenuItem[] = useMemo(
    () => getItemMenuItems(t, element, updateTests, updateHideCorrectAnswers),
    [t, element, updateTests, updateHideCorrectAnswers]
  )
  if (!hasSettingsOnCard) return null
  return (
    <SettingsWrapper contentEditable={false}>
      <IconMenu iconId='settings' items={includeInTestsIsHidden ? _.drop(iconMenuItems) : iconMenuItems} />
      <IconButton
        variant='transparent'
        iconId='trash-can'
        onClick={() => removeNodeWithId(editor, element.id)}
      />
    </SettingsWrapper>
  )
}

const StyledIconMenu = styled(IconMenu)`
  cursor: pointer;

  padding: 0 0.375rem;

  ${HeaderButtonColorCSS}
  ${HeaderGroupLastButtonCSS}
`

export const SettingButtonsToolbar: React.FC<{
  questionCardOrVariations: Omit<Entity<QuestionCard>, 'children'> | Entity<QuestionVariations>
  editor: Editor
  file: File
  createContentId: CreateContentId
}> = ({ editor, questionCardOrVariations, file, createContentId }) => {
  const { t } = useTranslation()
  const selectedNodeId = file.id
  const currentFolder = useSelector(state =>
    flexSelectors.selectNodeParentFolder(state, createContentId, selectedNodeId)
  )
  const includeInTestsIsHidden =
    currentFolder?.settings?.placementTestEnabled !== true && currentFolder?.settings?.reviewEnabled !== true

  const { updateTests, updateHideCorrectAnswers } = useUpdateFunctions(editor, questionCardOrVariations)

  const iconMenuItems: MenuItem[] = useMemo(
    () => getItemMenuItems(t, questionCardOrVariations, updateTests, updateHideCorrectAnswers),
    [t, questionCardOrVariations, updateTests, updateHideCorrectAnswers]
  )

  return (
    <StyledIconMenu
      color='currentColor'
      iconId='settings'
      withChevron
      items={includeInTestsIsHidden ? _.drop(iconMenuItems) : iconMenuItems}
    />
  )
}
