import { useAtom } from 'jotai'
import { throttle } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  getFileContentImage,
  getMediaLibraryImagePlaceholderUrl,
  getMediaLibraryImageUrl,
  getNamespacedImage,
  getPlaceholderImageUrl,
  getUnsplashContentImage,
  getUnsplashPreviewImage,
} from 'sierra-client/api/content'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { CardBackgroundImage } from 'sierra-domain/flexible-content/types'
import { css } from 'styled-components'

import { atom } from 'jotai'
import { useLoadNamespacedCourseAssets } from 'sierra-client/hooks/use-resolve-asset'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { assertNever } from 'sierra-domain/utils'

const imageCacheAtom = atom<Set<string>>(new Set<string>())

export const resolveBackgroundImageUrl = (
  image: CardBackgroundImage,
  { width }: { width?: number } | undefined = {},
  loadNamespacedCourseAssets: boolean = false,
  assetContext: AssetContext = { type: 'unknown' }
): string | undefined => {
  if (image === undefined) return undefined

  switch (image.type) {
    case 'unsplash':
      return getUnsplashContentImage(image.url, { width, height: width })
    case 'file':
      if (loadNamespacedCourseAssets && assetContext.type !== 'unknown') {
        return getNamespacedImage(assetContext, image.file, 'image', { optimize: true, width })
      }
      return getFileContentImage(image.file, { optimize: true, width })
    case 'url':
      return image.url
    case 'media-library-image':
      return getMediaLibraryImageUrl(image)
    default:
      assertNever(image)
  }
}

export const resolvePlaceholderBackgroundImageUrl = (
  image: CardBackgroundImage,
  { width }: { width?: number } | undefined = {},
  loadNamespacedCourseAssets: boolean = false,
  assetContext: AssetContext = { type: 'unknown' }
): string | undefined => {
  if (image === undefined) return undefined
  if (image.type === 'unsplash') return getUnsplashPreviewImage(image.url)
  if (image.type === 'media-library-image') return getMediaLibraryImagePlaceholderUrl(image)

  let url = ''
  switch (image.type) {
    case 'file':
      if (loadNamespacedCourseAssets && assetContext.type !== 'unknown') {
        return getNamespacedImage(assetContext, image.file, 'image', {
          optimize: true,
          width: 200,
          blur: 2000,
        })
      } else {
        url = getFileContentImage(image.file, { optimize: true, width })
      }
      break
    case 'url':
      url = image.url
      break
    default:
      assertNever(image)
  }

  return getPlaceholderImageUrl(url)
}

export const prefetchImageUrl = (url: string): Promise<void> =>
  new Promise(res => {
    const image = new Image()
    image.onload = () => {
      res()
    }

    image.src = url
  })

const useSmartImageLoader = (placeholderUrl?: string, url?: string): string | undefined => {
  const [currentUrl, setCurrentUrl] = useState<string | undefined>()

  const [imageCache, setImageCache] = useAtom(imageCacheAtom)

  // When swapping backgrounds too quickly the backround can flash,
  // to remedy this we throttle the update a bit, so that the transition can be smoother.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetCurrentUrl = useCallback(
    throttle((url: string) => {
      setCurrentUrl(url)
    }, 200),
    []
  )

  useEffect(() => {
    let aborted = false
    if (url === undefined || placeholderUrl === undefined) {
      setCurrentUrl(undefined)
      return
    }

    // Set a placeholder while the real image is loading
    throttledSetCurrentUrl(placeholderUrl)

    const image = new Image()
    image.onload = () => {
      setImageCache(previous => new Set([...previous, url]))
      if (!aborted) throttledSetCurrentUrl(url)
    }

    image.src = url
    return () => {
      aborted = true
      throttledSetCurrentUrl.cancel()
    }
  }, [placeholderUrl, url, throttledSetCurrentUrl, setImageCache])

  if (url !== undefined && imageCache.has(url)) return url

  return currentUrl
}

export const useSmartImageUnionLoader = (
  assetContext: AssetContext,
  backgroundImage?: ImageUnion,
  { width }: { width?: number } = {}
): string | undefined => {
  const loadNamespacedCourseAssets = useLoadNamespacedCourseAssets()

  const placeholderUrl = resolvePlaceholderBackgroundImageUrl(
    backgroundImage,
    { width },
    loadNamespacedCourseAssets,
    assetContext
  )
  const url = resolveBackgroundImageUrl(backgroundImage, { width }, loadNamespacedCourseAssets, assetContext)

  return useSmartImageLoader(placeholderUrl, url)
}

export type CardBackgroundProps = { $background?: string; $imageFit?: ImageFit }

export const cardBackgroundStyles = css<CardBackgroundProps>`
  ${p =>
    p.$background === undefined
      ? ''
      : css<CardBackgroundProps>`
          background-image: url('${p => p.$background}');
          background-position: center center;
          background-size: ${p => p.$imageFit ?? 'cover'};
          background-repeat: no-repeat;
          transition: background-image 300ms ease-out;
          will-change: background-image;
        `}
`
