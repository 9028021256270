import { SyncYJson } from '@sanalabs/y-redux'
import { debounce } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { AblyPresenceReduxSync } from 'sierra-client/collaboration/awareness/sync-ably-presence'
import { useAblyYDoc } from 'sierra-client/collaboration/use-ably-ydoc'
import { useFlagWithRefresh } from 'sierra-client/hooks/use-live-flag'
import { useChannelPresenceIfConnected } from 'sierra-client/realtime-data/use-channel-presence'
import { useSelector } from 'sierra-client/state/hooks'
import {
  liveSessionAwarenessStatesChanged,
  liveSessionDataChanged,
} from 'sierra-client/state/live-session/actions'
import {
  selectLiveSessionData,
  selectLiveSessionLocalAwarenessState,
} from 'sierra-client/state/live-session/selectors'
import { selectLocalAwarenessState } from 'sierra-client/state/sana-now/selectors'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { useOnChanged } from 'sierra-ui/utils'
import * as Y from 'yjs'

function useAblyYMapProvider(liveSessionId: ScopedLiveSessionId): Y.Map<unknown> | undefined {
  const [yMap, setYMap] = useState<Y.Map<unknown> | undefined>(undefined)
  const provider = useAblyYDoc(liveSessionId, { isLiveSession: true })

  useEffect(() => {
    if (provider === undefined) return
    setYMap(provider.yDoc.getMap('data-v3'))
  }, [provider])

  return yMap
}

export const LiveSessionYMapSyncAbly = ({
  liveSessionId,
}: {
  liveSessionId: ScopedLiveSessionId
}): JSX.Element | null => {
  const yMap = useAblyYMapProvider(liveSessionId)

  if (yMap === undefined) return null

  return <SyncYJson yJson={yMap} setData={liveSessionDataChanged} selectData={selectLiveSessionData} />
}

const RefreshFlag: FC = () => {
  const { refresh } = useFlagWithRefresh('backend-presence-for-live-sessions')
  const localPresence = useSelector(selectLocalAwarenessState)
  const remotePresences = useSelector(state => state.liveSession.awareness)
  const refetchDebounce = useMemo(() => debounce(refresh, 1000, { maxWait: 10_000 }), [refresh])

  useOnChanged(() => {
    void refetchDebounce()
  }, localPresence)

  useOnChanged(() => {
    void refetchDebounce()
  }, remotePresences)

  return null
}

// Presence
export const LiveSessionAblyPresenceReduxSync = ({
  liveSessionId,
}: {
  liveSessionId: ScopedLiveSessionId
}): JSX.Element | null => {
  const channelPresence = useChannelPresenceIfConnected(liveSessionId)
  if (channelPresence === undefined) return null

  return (
    <>
      <AblyPresenceReduxSync
        presence={channelPresence.presence}
        connectionId={channelPresence.connectionId}
        setAwarenessStates={liveSessionAwarenessStatesChanged}
        selectLocalAwarenessState={selectLiveSessionLocalAwarenessState}
      />
      <RefreshFlag />
    </>
  )
}
