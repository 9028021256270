import React, { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectUnreadMessagesCount, selectUnresolvedBlockThread } from 'sierra-client/state/chat/selectors'
import { browseCommentThread } from 'sierra-client/state/commenting/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCommentingContext } from 'sierra-client/views/commenting/context'
import { useEditorId } from 'sierra-client/views/v3-author/context'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { IconButton, Spacer, Text } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import { default as styled } from 'styled-components'

const UnreadMessagesChatWrapper = styled.span`
  display: flex;
  flex-direction: row;
  background: ${p => p.theme.color.orangePastel};
  border-radius: 1rem;
  color: ${palette.primitives.black};
  padding: ${spacing['4']} ${spacing.xxsmall};
`

const ChatIcon: React.FC<{
  chatId: ScopedChatId
  unitId: string
  blockId: string
}> = ({ chatId, unitId, blockId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const commenting = useCommentingContext()

  const unresolvedThread = useSelector(state => selectUnresolvedBlockThread(state, chatId, blockId))

  const onClick = useCallback(() => {
    if (unresolvedThread !== undefined && unresolvedThread.type === 'tiptap-comment') {
      void dispatch(
        browseCommentThread({
          reason: 'user',
          comment: unresolvedThread,
        })
      )
    } else {
      void commenting?.initiateCommenting({
        contentReference: {
          type: 'block',
          unitId,
          blockId,
        },
        indicateDraft: false,
      })
    }
  }, [blockId, commenting, dispatch, unitId, unresolvedThread])

  return (
    <IconButton
      variant='transparent'
      iconId='add-comment'
      size='small'
      tooltip={t('dictionary.comment-singular')}
      onClick={onClick}
      data-comment-anchor={blockId}
    />
  )
}

export const BlockCommentButton: React.FC<{ blockId: string; chatId: ScopedChatId }> = ({
  blockId,
  chatId,
}) => {
  const unitId = useEditorId()
  const unresolvedThread = useSelector(state => selectUnresolvedBlockThread(state, chatId, blockId))
  const threadId = unresolvedThread?.id
  const unreadCount = useSelector(state => selectUnreadMessagesCount(state, chatId, threadId))

  return (
    <ConditionalWrapper
      condition={unreadCount > 0}
      renderWrapper={children => (
        <UnreadMessagesChatWrapper>
          {children}
          <Spacer size='xxsmall' />
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
            {unreadCount}
          </Text>
        </UnreadMessagesChatWrapper>
      )}
    >
      <ChatIcon chatId={chatId} unitId={unitId} blockId={blockId} />
    </ConditionalWrapper>
  )
}
