import { HeaderProps } from 'react-table'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { MUIBox } from 'sierra-ui/mui'
import { Spacer } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RotateIcon = styled(Icon)<{ $rotate: number }>`
  transform: rotate(${p => p.$rotate}deg);
`
export const SortableHeader = <T extends Record<string, unknown> = Record<string, unknown>>({
  label,
  column,
}: HeaderProps<T> & { label: string }): JSX.Element => {
  return (
    <MUIBox {...column.getSortByToggleProps()} display='inline-flex' alignItems='center'>
      <TruncatedTextWithTooltip bold={column.isSorted} size='small'>
        {label}
      </TruncatedTextWithTooltip>
      <Spacer size='4' />
      {column.isSorted && (
        <RotateIcon
          iconId={'chevron--down--small'}
          $rotate={column.isSortedDesc === true ? 0 : 180}
          size='size-16'
        />
      )}
    </MUIBox>
  )
}
