import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ChangedNodeSelection,
  calculateChangedNodeSelections,
} from 'sierra-client/editor/version-history//utils/changed-node-selections'
import { editorDOMRects } from 'sierra-client/editor/version-history//utils/editor-dom-rects'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import { UserId } from 'sierra-domain/api/uuid'
import { SerializedDOMRect } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { Text } from 'sierra-ui/primitives'
import { zIndex } from 'sierra-ui/theming'
import { Editor } from 'slate'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  &&&& {
    height: 100%;
  }
`

const NameTag = styled.div<{ $background: ColorName | undefined }>`
  background-color: ${p => color(p.$background ?? 'greenVivid')};
  position: absolute;
  border-radius: 6px;

  top: -24px;
  left: 0;
  padding: 2px 4px;
  width: max-content;
`
const _ChangeRect = styled.div<{
  rect: SerializedDOMRect
  $background: ColorName | undefined
}>`
  background-color: ${p => color(p.$background ?? 'greenVivid').opacity(0.3)};
  position: absolute;
  top: ${({ rect }) => rect.top}px;
  left: ${({ rect }) => rect.left}px;
  height: ${({ rect }) => rect.height}px;
  width: ${({ rect }) => rect.width}px;
  z-index: ${Math.max(...Object.values(zIndex)) + 1};
`

const ChangeRect: FCC<
  {
    rect: SerializedDOMRect
    $background: ColorName | undefined
    shouldScroll: boolean
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, shouldScroll, ...props }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useScrollToView(ref, { shouldScroll, behavior: 'smooth', block: 'center' })
  return (
    <_ChangeRect ref={ref} {...props}>
      {children}
    </_ChangeRect>
  )
}

type HighlightedSelection = { rects: SerializedDOMRect[]; selection: ChangedNodeSelection }

export const HighlightChanges: FCC<{
  editor: Editor
  previousDocument: SlateDocument
  currentDocument: SlateDocument
  authorId: UserId | undefined
}> = ({ editor, children, previousDocument, currentDocument, authorId }) => {
  const user = useUserLegacy(authorId)
  const [highlightedSelections, setHighlightedSelections] = useState<HighlightedSelection[]>([])
  const containerRef = useRef<HTMLDivElement | null>(null)

  const stablePrevious = useDeepEqualityMemo(previousDocument)
  const stableCurrent = useDeepEqualityMemo(currentDocument)
  const changedNodeSelections = useMemo(
    () => calculateChangedNodeSelections(stablePrevious, stableCurrent),
    [stablePrevious, stableCurrent]
  )

  useEffect(() => {
    let isCancelled = false
    function findRectsLoop(): void {
      if (isCancelled) return
      if (changedNodeSelections.length === 0) return

      const container = containerRef.current?.getBoundingClientRect()
      if (!container) {
        requestAnimationFrame(findRectsLoop)
        return
      }

      const current = changedNodeSelections.map(
        (selection): HighlightedSelection =>
          // Move the rects up and to to left to account for the position of the container
          ({
            rects: editorDOMRects(editor, selection).map(
              (it): SerializedDOMRect => ({
                ...it,
                top: it.top - container.y,
                y: it.y - container.y,
                left: it.left - container.x,
                x: it.x - container.x,
              })
            ),
            selection,
          })
      )

      setHighlightedSelections(previous => {
        if (_.isEqual(previous, current)) return previous
        else return current
      })

      requestAnimationFrame(findRectsLoop)
    }

    findRectsLoop()
    return () => {
      setHighlightedSelections([])
      isCancelled = true
    }
  }, [changedNodeSelections, editor])

  const [hoverGroup, setHoverGroup] = useState<number | undefined>(undefined)

  return (
    <Container ref={containerRef}>
      {highlightedSelections.map(({ rects, selection }, hoverGroupIndex) =>
        rects.map((rect, index) => (
          <ChangeRect
            $background={user?.avatarColor ?? 'greenVivid'}
            shouldScroll={index === 0}
            onMouseOver={() => setHoverGroup(hoverGroupIndex)}
            onMouseOut={() =>
              setHoverGroup(previous => (previous === hoverGroupIndex ? undefined : previous))
            }
            onBlur={() => setHoverGroup(undefined)}
            rect={rect}
            // By using the selection along with the index of the rect
            // we get a key that is stable across re-renders
            key={`${JSON.stringify(selection)}-${index}`}
          >
            {user !== undefined && index === 0 && hoverGroup === hoverGroupIndex && (
              <NameTag $background={user.avatarColor}>
                <Text bold size='micro' color='white'>
                  {user.firstName} {user.lastName}
                </Text>
              </NameTag>
            )}
          </ChangeRect>
        ))
      )}
      {children}
    </Container>
  )
}
