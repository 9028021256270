import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { StepState } from 'sierra-client/features/program/admin/user-timeline/step-state'
import { EnrollmentStep } from 'sierra-client/features/program/admin/user-timeline/types'
import {
  getResourceUrl,
  getStepId,
  getStepImage,
  getStepProgress,
} from 'sierra-client/features/program/admin/user-timeline/utils'
import { GridArea } from 'sierra-client/features/program/components/grid'
import { StepNumber } from 'sierra-client/features/program/components/step-number'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { renderScheduleDescriptionWithPreviousCompletion } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline, ProgramStep } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { StepBadge } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'

const StepContainer = styled.div`
  display: grid;
  grid-template-columns: 16px 3fr 1fr;
  grid-template-areas: 'number content schedule';
  align-items: center;
  gap: 12px;
  border-radius: 16px;
`

const RightAlignText = styled.div`
  text-align: right;
`

export const Step: FC<{
  outline: ProgramOutline
  step: ProgramStep
  enrollmentStep: EnrollmentStep
  programId: ProgramId
}> = ({ outline, step, enrollmentStep, programId }) => {
  const { t } = useTranslation()

  const image = getStepImage(enrollmentStep) ?? undefined
  const progress = getStepProgress(enrollmentStep)
  const stepId = getStepId(enrollmentStep)

  const assetResolver = useAssetResolver({ size: 'default-sm' })
  const assetContext: AssetContext = useMemo(() => ({ type: 'program', programId }), [programId])

  return (
    <StepContainer>
      <GridArea area='number'>
        {progress === 1 ? (
          <StepBadge variant='completed' size='small' />
        ) : (
          <StepNumber index={step.index} outline={outline} />
        )}
      </GridArea>

      <GridArea area='content'>
        <View gap='16'>
          <Thumbnail height={2} width={3} radius={0.375} image={assetResolver(image, assetContext)} />

          <View direction='column' gap='none'>
            <ConditionalWrapper
              condition={enrollmentStep.__typename !== 'UserProgramEmailStep'}
              renderWrapper={children =>
                enrollmentStep.__typename !== 'UserProgramEmailStep' ? (
                  <a href={getResourceUrl(stepId)}>{children}</a>
                ) : (
                  children
                )
              }
            >
              <Text bold color='foreground/secondary'>
                {step.title}
              </Text>
            </ConditionalWrapper>

            <StepState enrollmentStep={enrollmentStep} />
          </View>
        </View>
      </GridArea>

      <GridArea area='schedule'>
        <RightAlignText>
          <Text>
            {renderScheduleDescriptionWithPreviousCompletion({
              outline: outline,
              index: step.index,
              t,
            })}
          </Text>

          {iife(() => {
            switch (step.schedule.type) {
              case 'now': {
                return (
                  <Text color='foreground/muted'>
                    {isDefined(enrollmentStep.availableAt)
                      ? DateTime.fromISO(enrollmentStep.availableAt).toISODate()
                      : t('manage.programs.not-assigned-yet')}
                  </Text>
                )
              }
              case 'absolute': {
                return null
              }
              case 'relative': {
                return (
                  <Text color='foreground/muted'>
                    {isDefined(enrollmentStep.availableAt)
                      ? DateTime.fromISO(enrollmentStep.availableAt).toISODate()
                      : t('manage.programs.not-assigned-yet')}
                  </Text>
                )
              }
              case 'on-previous-steps-completion': {
                const availableAt = enrollmentStep.availableAt
                return (
                  <Text color='foreground/muted'>
                    {availableAt !== undefined && availableAt !== null
                      ? DateTime.fromISO(availableAt).toISODate()
                      : t('manage.programs.not-assigned-yet')}
                  </Text>
                )
              }
              default:
                assertNever(step.schedule)
            }
          })}
        </RightAlignText>
      </GridArea>
    </StepContainer>
  )
}
