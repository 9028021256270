import { produce } from 'immer'
import { useAtom, useSetAtom } from 'jotai'
import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import { LiveContentAssignmentType } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import {
  addContentStepPanelOpenAtom,
  editOutlinePanelOpenAtom,
  emailNotificationOpenAtom,
  emailTemplateIdAtom,
  isSavingOutlineAtom,
  outlineAtom,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { useEmailTemplate } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-email-template'
import { useScrollHighlight } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-scroll-highlight'
import { DropLocation } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/types'
import { getSectionStepRange } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/utils'
import {
  createScheduleOffset,
  getStepId,
  isContentProgramStep,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { DueDateGroup, ProgramOutline, ProgramStep, Schedule } from 'sierra-domain/api/manage'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'

const panelTimeout = 250

export type OutlineEdit = {
  outline: ProgramOutline
  setOutline: (outline: ProgramOutline) => void
  updateDueDate: (stepIndex: number, dueDate: DueDateGroup | undefined) => void
  updateSchedule: (stepIndex: number, schedule: Schedule) => void
  updateLiveSessionAssignment: (stepIndex: number, assignment: LiveContentAssignmentType) => void
  moveStep: (
    fromPosition: number,
    toPosition: number,
    location?: DropLocation,
    destinationLevel?: 'section' | 'root',
    collapsed?: boolean
  ) => void
  moveSection: (sectionIndex: number, toPosition: number, location: DropLocation) => void
  removeStep: (stepIndex: number) => void
  getStep: (stepId: string) => ProgramStep
  getPreviousStep: (stepId: string) => ProgramStep | undefined
  panels: { editOutlineOpen: boolean; addContentOpen: boolean; addEmailNotificationOpen: boolean }
  setPanels: {
    editOutline: { on: () => void; off: () => void }
    addContent: { on: () => void; off: () => void }
    addEmailNotification: { on: (id?: string) => void; off: () => void }
  }
  addSteps: (steps: ProgramStep[]) => void
  updateStep: (updatedStep: ProgramStep) => void
  isSavingOutline: boolean
  setIsSavingOutline: (isSaving: boolean) => void
  addSection: () => void
  addStepToEmptySection: (stepId: string, sectionIndex: number) => void
  deleteSection: (sectionIndex: number) => void
  ungroupSection: (sectionIndex: number) => void
  patchSection: (sectionIndex: number, patch: { title?: string | null; description?: string | null }) => void
}

const getStepMetadata = (s: ProgramStep): object => {
  switch (s.type) {
    case 'email':
      return {
        image: s.image,
        title: s.title,
        description: s.description,
      }
    case 'path':
      return {
        title: s.title,
      }
    case 'course':
      return {
        title: s.title,
        courseKind: s.courseKind,
      }
    default:
      assertNever(s)
  }
}

export const useOutlineEdit = (): OutlineEdit => {
  const tracking = useTracking()
  const [outline, setOutline] = useAtom(outlineAtom)
  const [editOutlineOpen, setEditOutlineOpen] = useAtom(editOutlinePanelOpenAtom)
  const [addEmailNotificationOpen, setEmailNotificationOpen] = useAtom(emailNotificationOpenAtom)
  const setAddEmailTemplateId = useSetAtom(emailTemplateIdAtom)
  const [addContentOpen, setAddContentOpen] = useAtom(addContentStepPanelOpenAtom)
  const [isSavingOutline, setIsSavingOutline] = useAtom(isSavingOutlineAtom)
  const { resetHighlight } = useScrollHighlight({ stepId: undefined })
  const { resetTemplate } = useEmailTemplate()

  const editOutlineOn = useCallback(() => setEditOutlineOpen(true), [setEditOutlineOpen])
  const editOutlineOff = useCallback(() => setEditOutlineOpen(false), [setEditOutlineOpen])

  const addContentOn = useCallback(() => {
    setTimeout(() => setAddContentOpen(true), panelTimeout)
  }, [setAddContentOpen])
  const addContentOff = useCallback(() => setAddContentOpen(false), [setAddContentOpen])

  const addEmailNotificationOn: OutlineEdit['setPanels']['addEmailNotification']['on'] = useCallback(
    id => {
      setTimeout(() => {
        setEmailNotificationOpen(true)
        setAddEmailTemplateId(id)
      }, panelTimeout)
    },
    [setEmailNotificationOpen, setAddEmailTemplateId]
  )
  const addEmailNotificationOff = useCallback(() => {
    resetTemplate()
    setAddEmailTemplateId(undefined)
    setEmailNotificationOpen(false)
  }, [setAddEmailTemplateId, setEmailNotificationOpen, resetTemplate])

  const addSteps: OutlineEdit['addSteps'] = useCallback(
    steps => {
      const result = produce(outline, draft => {
        steps.forEach(step => draft.steps.push(step))
      })

      setOutline(result)
      steps.forEach(s => tracking.program.step.save(getStepId(s), s.type, getStepMetadata(s)))
    },
    [outline, setOutline, tracking.program]
  )

  const updateStep: OutlineEdit['updateStep'] = useCallback(
    updatedStep => {
      const updatedOutline = produce(outline, draft => {
        const updateStepIndex = draft.steps.findIndex(s => getStepId(s) === getStepId(updatedStep))
        if (updateStepIndex < 0) {
          throw new Error(`Could not find step ${getStepId(updatedStep)} while updating.`)
        }

        draft.steps.splice(updateStepIndex, 1, updatedStep)
      })
      setOutline(updatedOutline)
      tracking.program.step.edit(getStepId(updatedStep), updatedStep.type, getStepMetadata(updatedStep))
    },
    [outline, setOutline, tracking.program]
  )

  const moveStep: OutlineEdit['moveStep'] = useCallback(
    (fromPosition, toPosition, location = 'above', destinationLevel = 'section', collapsed) => {
      const maybeStep = outline.steps.at(fromPosition)

      if (maybeStep) {
        tracking.program.step.dragDrop(getStepId(maybeStep), maybeStep.type)
      }

      const result = produce(outline, draft => {
        const dropTarget = draft.steps[toPosition]
        const moving = draft.steps.splice(fromPosition, 1)[0]

        if (moving === undefined) {
          return outline
        }

        const fromSectionIndex = moving.sectionIndex

        if (isDefined(fromSectionIndex)) {
          tracking.program.section.removeStep({ sectionIndex: fromSectionIndex })
        }

        /**
         * The index we insert the item back into depends on two things:
         * 1. Is the item dropped above or below the "drop target"?
         * 2. Is the item dragged downwards in the list, causing a shift when spliced?
         */
        const insertIndex =
          (location === 'above' ? toPosition : toPosition + 1) + (toPosition > fromPosition ? -1 : 0)

        if (destinationLevel === 'section') {
          moving.sectionIndex = dropTarget?.sectionIndex ?? undefined
          if (isDefined(moving.sectionIndex)) {
            tracking.program.section.addStep({
              sectionIndex: moving.sectionIndex,
              collapsed: collapsed ?? false,
            })
          }
        } else {
          moving.sectionIndex = undefined
        }

        draft.steps.splice(insertIndex, 0, moving)

        /**
         * Figure out which self-positioned sections needs to be adjusted
         * based on the move operation. Basically, the section will be affect
         * if the number of steps above it either increases or decreases.
         */
        draft.sections.forEach(section => {
          if (isDefined(section.selfIndex)) {
            const selfIndex = section.selfIndex
            const shouldAdjustPosition =
              (selfIndex <= fromPosition && selfIndex >= toPosition) ||
              (selfIndex >= fromPosition && selfIndex <= toPosition)
            const adjustment = toPosition <= fromPosition ? 1 : -1

            section.selfIndex += shouldAdjustPosition ? adjustment : 0
          }
        })

        /**
         * After we moved an item within the program, we need to
         * make sure the that the new first step isn't running with
         * a completion-based schedule. If it is, we change it to a
         * relative schedule instead.
         */
        const first = draft.steps[0]
        if (first?.schedule.type === 'on-previous-steps-completion') {
          first.schedule = {
            type: 'relative',
            offset: first.schedule.offset ?? createScheduleOffset(0),
          }
        }

        // Check if the "sending" section now needs a selfIndex
        const senderNeedsSelfIndex = isDefined(fromSectionIndex)
          ? draft.steps.reduce((sum, s) => (s.sectionIndex === fromSectionIndex ? sum + 1 : sum), 0) === 0
          : false

        if (senderNeedsSelfIndex && isDefined(fromSectionIndex)) {
          const fromSection = draft.sections[fromSectionIndex]

          if (isDefined(fromSection)) {
            const spliceOffset = fromPosition > toPosition ? 1 : 0
            // if we're dragging a step out of a section and placing it
            // directly above the empty section, we want to push the section down by one
            const sameIndexOffset = fromPosition === toPosition && location === 'above' ? 1 : 0

            fromSection.selfIndex = fromPosition + spliceOffset + sameIndexOffset
          }
        }
      })

      setOutline(result)
    },
    [outline, setOutline, tracking.program]
  )

  const moveSection = useCallback(
    (sectionIndex: number, to: number, dropLocation: DropLocation) => {
      const result = produce(outline, draft => {
        const section = draft.sections[sectionIndex]
        const stepCount = draft.steps.reduce(
          (count, s) => (s.sectionIndex === sectionIndex ? count + 1 : count),
          0
        )

        if (!isDefined(section)) {
          return
        }

        tracking.program.section.moveSection({ sectionIndex })

        if (stepCount === 0) {
          section.selfIndex = dropLocation === 'above' ? to : to + 1
        } else {
          const range = getSectionStepRange(sectionIndex, outline)

          if (range === null) {
            return
          }

          const { start, end } = range

          // Pop all steps that are in the section
          const count = end - start + 1
          const steps = draft.steps.splice(start, count)

          /**
           * The index we insert the item back into depends on two things:
           * 1. Is the item dropped above or below the "drop target"?
           * 2. Is the item dragged downwards in the list, causing a shift when spliced?
           */
          const toLocation = dropLocation === 'above' ? to : to + 1
          const spliceOffset = to > end ? -count : 0
          const insertIndex = toLocation + spliceOffset

          draft.steps.splice(insertIndex, 0, ...steps)
        }
      })

      setOutline(result)
    },
    [outline, setOutline, tracking.program.section]
  )

  /**
   * This should be called something like `addStepToEmptySection` instead
   */
  const addStepToEmptySection = useCallback(
    (stepId: string, sectionIndex: number) => {
      const result = produce(outline, draft => {
        const section = draft.sections[sectionIndex]
        const stepIndex = draft.steps.findIndex(s => getStepId(s) === stepId)
        const step = draft.steps.splice(stepIndex, 1)[0]
        const stepCount = draft.steps.reduce(
          (count, step) => (step.sectionIndex === sectionIndex ? count + 1 : count),
          0
        )

        if (isNotDefined(step) || isNotDefined(section)) {
          return
        }

        tracking.program.section.addStep({ sectionIndex, collapsed: false })

        const fromSectionIndex = step.sectionIndex

        if (stepCount > 0) {
          throw Error('Section is not empty')
        }

        // Insert the step back into the outline structure at the right index
        const spliceOffset = stepIndex < (section.selfIndex ?? 0) ? 1 : 0
        const at = isDefined(section.selfIndex) ? section.selfIndex - spliceOffset : draft.steps.length
        draft.steps.splice(at, 0, step)
        step.sectionIndex = sectionIndex

        // This section no longer needs a selfIndex
        section.selfIndex = undefined

        // Check if the "sending" section now needs a selfIndex
        const senderNeedsSelfIndex = isDefined(fromSectionIndex)
          ? draft.steps.reduce((sum, s) => (s.sectionIndex === fromSectionIndex ? sum + 1 : sum), 0) === 0
          : false

        if (senderNeedsSelfIndex && isDefined(fromSectionIndex)) {
          const fromSection = draft.sections[fromSectionIndex]

          if (isDefined(fromSection)) {
            fromSection.selfIndex = stepIndex
          }
        }
      })

      setOutline(result)
    },
    [outline, setOutline, tracking.program.section]
  )

  const updateDueDate: OutlineEdit['updateDueDate'] = useCallback(
    (stepIndex, dueDate) => {
      const result = produce(outline, draft => {
        const step = draft.steps[stepIndex]

        if (step === undefined || !isContentProgramStep(step)) {
          return
        }

        step.dueDate = dueDate
      })

      setOutline(result)
    },
    [outline, setOutline]
  )

  const updateSchedule: OutlineEdit['updateSchedule'] = useCallback(
    (stepIndex, schedule) => {
      const maybeStep = outline.steps.at(stepIndex)

      if (maybeStep && !_.isEqual(maybeStep.schedule, schedule)) {
        tracking.program.step.setStaggeredAssignmentTiming(getStepId(maybeStep), maybeStep.type, schedule)
      }

      const result = produce(outline, draft => {
        const step = draft.steps[stepIndex]

        if (step === undefined) {
          return
        }

        step.schedule = schedule
      })

      setOutline(result)
    },
    [outline, setOutline, tracking.program]
  )

  const updateLiveSessionAssignment: OutlineEdit['updateLiveSessionAssignment'] = useCallback(
    (stepIndex, assignment) => {
      const result = produce(outline, draft => {
        const step = draft.steps[stepIndex]

        if (step === undefined || !isContentProgramStep(step)) {
          return
        }

        if (assignment === 'auto-assign') {
          step.autoAssignLiveSession = true
          step.enableSelfEnrollment = false
        }

        if (assignment === 'self-enroll') {
          step.autoAssignLiveSession = false
          step.enableSelfEnrollment = true
        }

        if (assignment === 'manual') {
          step.autoAssignLiveSession = false
          step.enableSelfEnrollment = false
        }
      })

      setOutline(result)
    },
    [outline, setOutline]
  )

  const removeStep: OutlineEdit['removeStep'] = useCallback(
    stepIndex => {
      const maybeStep = outline.steps.at(stepIndex)
      if (maybeStep) {
        tracking.program.step.removed(getStepId(maybeStep), maybeStep.type)
        if (isDefined(maybeStep.sectionIndex)) {
          tracking.program.section.removeStep({ sectionIndex: maybeStep.sectionIndex })
        }
      }

      const result = produce(outline, draft => {
        draft.steps.splice(stepIndex, 1)
      })
      setOutline(result)
    },
    [outline, setOutline, tracking.program]
  )

  const getStep: OutlineEdit['getStep'] = useCallback(
    stepId => {
      const step = outline.steps.find(step => getStepId(step) === stepId)

      if (step === undefined) {
        throw new Error(`Could not find step with id ${stepId} in outline.`)
      }

      return step
    },
    [outline]
  )

  const getPreviousStep: OutlineEdit['getPreviousStep'] = useCallback(
    stepId => {
      const stepIndex = outline.steps.findIndex(step => getStepId(step) === stepId)

      if (stepIndex === 0) {
        return undefined
      }

      if (stepIndex === -1) {
        throw new Error(`Could not find step with id ${stepId} in outline.`)
      }

      return outline.steps[stepIndex - 1]
    },
    [outline]
  )

  const addSection = useCallback(() => {
    const result = produce(outline, draft => {
      const section = {
        draftId: draft.sections
          .reduce((count, section) => ('draftId' in section ? count + 1 : count), 0)
          .toString(),
        title: 'New section',
      }

      draft.sections.push(section)
    })
    tracking.program.section.add({ sectionIndex: result.sections.length - 1 })

    setOutline(result)
  }, [setOutline, outline, tracking.program.section])

  const ungroupSection = useCallback(
    (sectionIndex: number) => {
      const result = produce(outline, draft => {
        // Remove the section
        draft.sections.splice(sectionIndex, 1)

        // Update all steps, accounting for the deleted section
        draft.steps.forEach(step => {
          if (isDefined(step.sectionIndex)) {
            if (step.sectionIndex === sectionIndex) {
              step.sectionIndex = null
            } else if (sectionIndex < step.sectionIndex) {
              step.sectionIndex -= 1
            }
          }
        })
      })

      tracking.program.section.ungroup({ sectionIndex })

      setOutline(result)
    },
    [setOutline, outline, tracking.program.section]
  )

  const deleteSection = useCallback(
    (sectionIndex: number) => {
      const result = produce(outline, draft => {
        // Remove the section instance
        draft.sections.splice(sectionIndex, 1)

        // Remove all the steps that referenced the section
        draft.steps = draft.steps.filter(step => !(step.sectionIndex === sectionIndex))

        // Update remaining steps, accounting for the deleted section
        draft.steps.forEach(step => {
          if (isDefined(step.sectionIndex)) {
            if (step.sectionIndex > sectionIndex) {
              step.sectionIndex -= 1
            }
          }
        })
      })

      tracking.program.section.remove({ sectionIndex })

      setOutline(result)
    },
    [setOutline, outline, tracking.program.section]
  )

  const patchSection = useCallback(
    (sectionIndex: number, section: { title?: string | null; description?: string | null }) => {
      const result = produce(outline, draft => {
        const changingSection = draft.sections[sectionIndex]

        if (changingSection === undefined) {
          return
        }

        changingSection.title = section.title === undefined ? changingSection.title : section.title ?? ''
        changingSection.description =
          section.description === undefined ? changingSection.description : section.description ?? ''
      })

      setOutline(result)
    },
    [setOutline, outline]
  )

  useEffect(() => {
    if (editOutlineOpen === false) {
      resetHighlight()
    }
  }, [editOutlineOpen, resetHighlight])

  return {
    outline,
    setOutline,
    updateDueDate,
    updateSchedule,
    updateLiveSessionAssignment,
    removeStep,
    moveStep,
    moveSection,
    getStep,
    getPreviousStep,
    panels: {
      editOutlineOpen,
      addContentOpen,
      addEmailNotificationOpen,
    },
    setPanels: {
      editOutline: { on: editOutlineOn, off: editOutlineOff },
      addContent: { on: addContentOn, off: addContentOff },
      addEmailNotification: { on: addEmailNotificationOn, off: addEmailNotificationOff },
    },
    addSteps,
    updateStep,
    isSavingOutline,
    setIsSavingOutline,
    addSection,
    addStepToEmptySection,
    deleteSection,
    ungroupSection,
    patchSection,
  }
}
