import { createFileRoute } from '@tanstack/react-router'
import { getDefaultStore } from 'jotai'

import React from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { getFlag } from 'sierra-client/config/global-config'
import {
  getDashboardToRedirectTo,
  latestVisitedDashboardAtom,
  listDashboardQuery,
  LoadRecentOrDefaultDashboard,
  prefetchGetDashboard,
  prefetchInsightsViews,
} from 'sierra-client/features/insights'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { isDefined, isNotDefined } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// Sadly needed due to how the manage layout works
const Wrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const InsightsPage: React.FC = () => {
  const insightsEnabled = useInsightsEnabled()
  const { t } = useTranslation()

  if (!insightsEnabled) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsights()}>
      <Wrapper direction='column'>
        <View direction='column'>
          <ManageHeadline>{t('manage.insights.headline')}</ManageHeadline>
          <LoadRecentOrDefaultDashboard />
        </View>
      </Wrapper>
    </ManagePageWrapper>
  )
}

const RouteComponent = InsightsPage

const prefetchListAndDashboard = (lastVisitedDashboardId: string): void => {
  const dashboardId = NanoId12.parse(lastVisitedDashboardId)
  void prefetchGetDashboard({ dashboardId })

  void queryClient.prefetchQuery(listDashboardQuery)
}

const prefetchListAndRedirectToDashboard = async (): Promise<void> => {
  const dashboardsList = await queryClient.fetchQuery(listDashboardQuery)

  const dashboardId = getDashboardToRedirectTo(dashboardsList.dashboards)
  if (isNotDefined(dashboardId)) return

  void prefetchGetDashboard({ dashboardId })
}

export const Route = createFileRoute('/manage/insights/')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (!context.isLoggedIn) return
    if (!getFlag('preloading')) return

    void prefetchInsightsViews()

    // This logic mappes the behaviour in <LoadRecentOrDefaultDashboard />
    // to prefetch the list of dashhboards and the dashboard to redirect to
    const store = getDefaultStore()
    const lastVisitedDashboardId = store.get(latestVisitedDashboardAtom)
    if (isDefined(lastVisitedDashboardId)) {
      void prefetchListAndDashboard(lastVisitedDashboardId)
    } else {
      void prefetchListAndRedirectToDashboard()
    }
  },
})
