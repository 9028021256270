import { WritableAtom, atom, useSetAtom } from 'jotai'
import { isEqual } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Chat } from 'sierra-client/components/chat/chat'
import { TypingUserData } from 'sierra-client/components/chat/typing-user-data-type'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import { selectUsersTypingInChat } from 'sierra-client/state/live-session/selectors'
import { ScopedChatId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Container = styled(View)`
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  align-items: stretch;
`

const SyncUsersTypingAtom: FC<{
  usersTypingAtom: WritableAtom<TypingUserData[], [TypingUserData[]], void>
}> = ({ usersTypingAtom }) => {
  const usersTyping = useSelector(selectUsersTypingInChat, isEqual)
  const setUsersTyping = useSetAtom(usersTypingAtom)

  useEffect(() => {
    setUsersTyping(usersTyping)
  }, [setUsersTyping, usersTyping])

  return null
}

export const ChatContainer = ({
  liveSessionId,
  showClose = true,
}: {
  liveSessionId: ScopedLiveSessionId
  showClose?: boolean
}): JSX.Element | null => {
  const { t } = useTranslation()

  const { state, close, isOpen } = useRightSidebarContext()
  const [threadId, changeThreadId] = useState<string>()
  const [usersTypingAtom] = useState(() => atom<TypingUserData[]>([]))

  const dispatch = useDispatch()
  const setCurrentUserIsTyping = useStableFunction((threadId: string | undefined) => {
    dispatch(liveSessionLocalAwarenessStateMerged({ isWritingToChatThreadId: threadId }))
  })

  return (
    <Container>
      <Chat
        title={t('dictionary.chat')}
        visible={isOpen && state === 'chat_open'}
        chatId={ScopedChatId.fromId(liveSessionId)}
        chatIdentifier={{ type: 'live-session', liveSessionId: ScopedLiveSessionId.extractId(liveSessionId) }}
        onClose={close}
        showClose={showClose}
        componentKey={`card-${liveSessionId}`}
        hasRoundedCorners
        threadId={threadId}
        changeThreadId={changeThreadId}
        limitMessages={100}
        usersTypingAtom={usersTypingAtom}
        setCurrentUserIsTyping={setCurrentUserIsTyping}
      />
      <SyncUsersTypingAtom usersTypingAtom={usersTypingAtom} />
    </Container>
  )
}
