import { createFileRoute } from '@tanstack/react-router'

import {
  XRealtimeAdminPathsCreatePath,
  XRealtimeAdminPathsListPaths,
  XRealtimeAdminPathsUpdatePath,
} from 'sierra-domain/routes'

import React, { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { Path } from 'sierra-domain/api/admin'
import { PathId } from 'sierra-domain/api/nano-id'
import styled from 'styled-components'

/* eslint-disable */

/* Should delete this page??? */

const PageContainer = styled.div`
  padding: 3em;
`
const PathsList = styled.ul`
  margin: 2em;
  list-style: square;

  li {
    padding: 1em;
    border-bottom: 1px solid #aaa;
  }
`

interface Props {}

type PathList = Array<Path>

const PathsPage: React.FC<Props> = () => {
  const { postWithUserErrorException } = usePost()
  const [paths, setPaths] = useState<PathList>()

  const createPath = async () => {
    await postWithUserErrorException(XRealtimeAdminPathsCreatePath, {
      title: 'this is a title from the frontend',
    })
    await fetchPaths()
  }

  const fetchPaths = async () => {
    const response = await postWithUserErrorException(XRealtimeAdminPathsListPaths, {})
    setPaths(response.paths)
  }

  const patchPath = async (pathId: PathId) => {
    await postWithUserErrorException(XRealtimeAdminPathsUpdatePath, {
      pathId,
      data: {
        title: `${Date.now()} new random title`,
        description: `${Date.now()} random description`,
        image: {
          type: 'unsplash',
          width: Math.floor(Math.random() * 1000),
          height: Math.floor(Math.random() * 1000),
          user: {
            name: 'Lorem Ipsum',
            username: 'loremipsum',
          },
          url: 'https://source.unsplash.com/random',
        },
        settings: {
          sequentialLearning: Math.random() > 0.5,
          isPathVisible: false,
          theme: undefined,
        },
        content: [],
      },
    })

    await fetchPaths()
  }

  useEffect(() => {
    fetchPaths()
  }, [])

  return (
    <PageContainer>
      <h2>Create path:</h2>
      <button onClick={createPath}>New Path</button>

      <hr />

      <h1>List of paths:</h1>
      {paths && (
        <PathsList>
          {paths.map(p => (
            <li key={p.pathId}>
              <button onClick={() => patchPath(p.pathId)}>Patch patch</button>
              <hr />
              <pre>
                <code>{JSON.stringify(p, null, 2)}</code>
              </pre>
            </li>
          ))}
        </PathsList>
      )}
    </PageContainer>
  )
}

const RouteComponent = PathsPage

export const Route = createFileRoute('/admin/paths-playground/')({
  component: RouteComponent as React.FC,
})
