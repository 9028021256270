import React from 'react'
import * as format from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { HomeworkState } from 'sierra-domain/api/homework'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { Pill, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PrimaryLabel = styled(Text).attrs({ size: 'micro', bold: true })`
  white-space: nowrap;
`

const SecondaryLabel = styled(TruncatedText).attrs({ lines: 1, size: 'micro', color: 'foreground/muted' })``

const getTooltip = (homework?: HomeworkState): I18nArgs | undefined => {
  if (isNotDefined(homework)) return undefined

  const { grade, submissions, maxSubmissions } = homework

  if (isDefined(maxSubmissions)) {
    switch (grade) {
      case 'not-graded':
        return [
          'homework.progress-tooltip.not-graded.max-submissions',
          { count: submissions, maxSubmissions },
        ]
      case 'failed':
      case 'failed-with-no-retries':
        return ['homework.progress-tooltip.failed.max-submissions', { count: submissions, maxSubmissions }]
      case 'not-submitted':
      case 'passed':
      case 'dismissed':
        return undefined
      default:
        assertNever(grade)
    }
  } else {
    switch (grade) {
      case 'not-graded':
        return ['homework.progress-tooltip.not-graded', { count: submissions }]
      case 'failed':
      case 'failed-with-no-retries':
        return ['homework.progress-tooltip.failed', { count: submissions }]
      case 'not-submitted':
      case 'passed':
      case 'dismissed':
        return undefined
      default:
        assertNever(grade)
    }
  }
}

export const ProgressPill: React.FC<{
  progress?: number
  homeworks: HomeworkState[]
}> = ({ progress, homeworks }) => {
  const { t } = useTranslation()
  const selectedHomework = getPrioritizedHomework(homeworks)
  const hasFailedWithNoRetries = selectedHomework?.grade === 'failed-with-no-retries'
  const isComplete = progress === 1

  // If completed, just show that it is completed, no need for other states
  if (isComplete) {
    return (
      <Pill variant='success'>
        <Icon size='size-12' iconId='checkmark--filled' color='success/foreground' />
        <PrimaryLabel color='success/foreground'>{t('dictionary.completed')}</PrimaryLabel>
      </Pill>
    )
  }

  if (hasFailedWithNoRetries) {
    return (
      <Pill variant='error'>
        <Icon size='size-12' iconId='close--circle--filled' color='destructive/background' />
        <PrimaryLabel color='destructive/background'>{t('homework.failed')}</PrimaryLabel>
      </Pill>
    )
  }

  const hasPendingHomework = selectedHomework?.grade === 'not-graded' || selectedHomework?.grade === 'failed'
  const tooltip = getTooltip(selectedHomework)

  return (
    <Pill variant='ghost'>
      <PrimaryLabel>{format.percentage(progress)}</PrimaryLabel>
      {hasPendingHomework && (
        <SecondaryLabel tooltip={isDefined(tooltip) ? t(...tooltip) : undefined}>
          {t('manage.users.status.homework.not-graded')}
        </SecondaryLabel>
      )}
    </Pill>
  )
}
