import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  HotspotArrow,
  HotspotContainer,
  HotspotIcon,
  HotspotText,
} from 'sierra-client/views/v3-author/images/hotspots/common'
import { Hotspot, HotspotAction, HotspotState } from 'sierra-client/views/v3-author/images/hotspots/types'
import { Icon } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const HotspotTextAuthor = styled(HotspotText)<{ isOpen: boolean }>`
  cursor: auto;
  text-align: left;

  ${p =>
    !p.isOpen &&
    css`
      display: none;
    `}
`

const MovingHotspotIcon = styled(HotspotIcon)<{ isMoving?: boolean }>`
  ${p =>
    (p.isMoving ?? false) &&
    css`
      cursor: grabbing;
    `}
`

interface MovingHotspotContainerProps {
  isMoving?: boolean
}

const MovingHotspotContainer = styled(HotspotContainer)<MovingHotspotContainerProps>`
  cursor: grabbing;

  ${p =>
    (p.isMoving ?? false) &&
    css`
      z-index: 2;
    `}
`

const HotspotSurface = styled.div<{ isMoving: boolean; addModeEnabled: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  cursor: ${p => (p.addModeEnabled ? 'crosshair' : 'pointer')};

  ${p =>
    p.isMoving &&
    css`
      cursor: grabbing;
    `}
`

const HotspotTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-width: 100%;
  border: none;
  resize: none;
  line-height: 1.5;
  display: block;
  margin: 0;
  margin-bottom: 8px;
  padding: 8px;
  font-size: 16px;
`

const DeleteButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${palette.primitives.black};
  padding: 0;
  margin: 0;
  margin-left: 8px;
  margin-top: 8px;
`

interface AuthorHotspotProps {
  hotspotId: string
  hotspot: Hotspot
  startMoving?: (hotspotId: string, hotspot: Hotspot) => void
  hotspotDispatch?: (action: HotspotAction) => void
  dragPosition?: { x: number; y: number }
}

const AuthorHotspot: React.FC<AuthorHotspotProps> = ({
  hotspotId,
  hotspot,
  startMoving,
  hotspotDispatch,
  dragPosition,
}) => {
  const { t } = useTranslation()
  const { text } = hotspot
  const x = dragPosition ? dragPosition.x : hotspot.x
  const y = dragPosition ? dragPosition.y : hotspot.y
  const isMoving = !!dragPosition

  // Start open if there's no text (i.e. hotspot is new)
  const [isOpen, setIsOpen] = useState(() => !text)

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // Make sure we don't trigger mousedown on the image (which creates a new hotspot)
      e.preventDefault()
      e.stopPropagation()

      // Only listen to left click
      if (e.buttons !== 1) return

      startMoving?.(hotspotId, hotspot)
    },
    [hotspot, hotspotId, startMoving]
  )

  const onClick = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])

  const [tmpText, setTmpText] = useState<string | undefined>(text)

  // Set previous text when the editor starts up
  // useEffect(() => setTmpText(text), [])

  const commitText = useCallback(() => {
    if (!hotspotDispatch) return
    if (tmpText === text) return // Don't update unnecessarily

    hotspotDispatch({
      type: 'update',
      id: hotspotId,
      hotspot: {
        ...hotspot,
        text: tmpText ?? '',
      },
    })
  }, [hotspotDispatch, text, tmpText, hotspot, hotspotId])

  useOnChanged((prevOpen, currOpen) => {
    if ((prevOpen ?? false) && !currOpen) {
      // Was closed
      commitText()
    } else if (!(prevOpen ?? false) && isOpen) {
      // Was opened
      setTmpText(text)
    }
  }, isOpen)

  // Save on every keystroke
  useEffect(
    () => commitText(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tmpText]
  )

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTmpText(e.target.value)
  }, [])

  const onClickDelete = useCallback(() => {
    if (!hotspotDispatch) return

    hotspotDispatch({
      type: 'delete',
      id: hotspotId,
    })
  }, [hotspotDispatch, hotspotId])

  return (
    <MovingHotspotContainer key={hotspotId} $x={x} $y={y} isMoving={isMoving} isOpen={isOpen}>
      <MovingHotspotIcon
        isOpen={isOpen}
        onMouseDown={isMoving ? undefined : onMouseDown}
        onClick={isMoving ? undefined : onClick}
        title={
          isOpen
            ? t('author.block-editor.image-hotspot-click-to-save-and-close')
            : t('author.block-editor.image-hotspot-click-to-edit')
        }
        isMoving={isMoving}
      />
      <HotspotTextAuthor
        isOpen={isOpen}
        onMouseDown={e => {
          e.stopPropagation()
        }}
      >
        <HotspotTextArea
          value={tmpText}
          onChange={onChange}
          placeholder={t('author.block-editor.image-hotspot-start-writing')}
        />
        <DeleteButton onClick={onClickDelete}>
          <Icon size='size-24' iconId='trash-can' />
        </DeleteButton>
      </HotspotTextAuthor>
      {isOpen && <HotspotArrow />}
    </MovingHotspotContainer>
  )
}

interface AuthorHotspotsProps {
  hotspotState: HotspotState
  hotspotDispatch: (action: HotspotAction) => void
}

export const AuthorHotspots: React.FC<AuthorHotspotsProps> = ({ hotspotState, hotspotDispatch }) => {
  const { t } = useTranslation()

  const ref = useRef<HTMLDivElement>(null)

  const [movingHotspot, setMovingHotspot] = useState<{ id: string; hotspot: Hotspot } | undefined>(undefined)
  const [currentCoords, setCurrentCoords] = useState<{ x: number; y: number } | undefined>(undefined)

  const [movedOverThreshold, setMovedOverThreshold] = useState(false)

  const { hotspots, addModeEnabled } = hotspotState

  useEffect(() => {
    if (!movingHotspot || !currentCoords) {
      setMovedOverThreshold(false)
      return
    }

    // Don't recalc if already true
    if (movedOverThreshold) return

    const { x: x1, y: y1 } = movingHotspot.hotspot
    const { x: x2, y: y2 } = currentCoords

    const dx = x2 - x1
    const dy = y2 - y1
    const d = Math.sqrt(dx * dx + dy * dy)

    // TODO: use pixel threshold instead of relative
    setMovedOverThreshold(d > 0.03)
  }, [movingHotspot, currentCoords, movedOverThreshold])

  const reset = (): void => {
    setMovingHotspot(undefined)
    setCurrentCoords(undefined)
  }

  const getFractionalCoordinates = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const el = ref.current
      if (!el) return

      const { x, y, width, height } = el.getBoundingClientRect()

      const clickX = e.clientX
      const clickY = e.clientY

      const xFrac = (clickX - x) / width
      const yFrac = (clickY - y) / height

      return {
        x: xFrac,
        y: yFrac,
      }
    },
    [ref]
  )

  const onImageClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // Only listen to left click
      if (e.buttons !== 1) return

      const res = getFractionalCoordinates(e)
      if (!res) return

      const { x, y } = res

      hotspotDispatch({
        type: 'add',
        hotspot: { x, y, text: '' },
      })
    },
    [hotspotDispatch, getFractionalCoordinates]
  )

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (currentCoords === undefined) return

      const res = getFractionalCoordinates(e)
      if (!res) return

      const { x, y } = res

      const LIMIT = 0.05
      const LOWER_LIMIT = -LIMIT
      const UPPER_LIMIT = 1 + LIMIT

      const anyOutOfBounds = [x, y].some(n => n < LOWER_LIMIT || n > UPPER_LIMIT)

      if (anyOutOfBounds) {
        reset()
        return
      }

      setCurrentCoords({ x, y })
    },
    [currentCoords, getFractionalCoordinates]
  )

  const onMouseUp = useCallback(() => {
    // Save?
    if (movedOverThreshold && movingHotspot && currentCoords) {
      const { x: _x, y: _y } = currentCoords

      const x = Math.max(0, Math.min(1, _x))
      const y = Math.max(0, Math.min(1, _y))

      hotspotDispatch({
        type: 'update',
        id: movingHotspot.id,
        hotspot: {
          ...movingHotspot.hotspot,
          x,
          y,
        },
      })
    }
    reset()
  }, [hotspotDispatch, currentCoords, movedOverThreshold, movingHotspot])

  const startMoving = useCallback(
    (hotspotId: string, hotspot: Hotspot) => {
      if (currentCoords !== undefined) return

      setMovingHotspot({ id: hotspotId, hotspot })
      setCurrentCoords(hotspot)
    },
    [currentCoords]
  )

  const sortedHotspots = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      hotspots !== undefined
        ? _.chain(hotspots)
            .map((hotspot, hotspotId) => [hotspotId, hotspot] as const)
            .orderBy(([, hotspot]) => hotspot.y)
            .value()
        : [],
    [hotspots]
  )

  if (Object.keys(hotspots).length === 0 && !addModeEnabled) return null

  return (
    <HotspotSurface
      addModeEnabled={addModeEnabled}
      isMoving={movedOverThreshold}
      onMouseDown={addModeEnabled ? onImageClick : undefined}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={reset}
      title={addModeEnabled ? t('author.block-editor.image-hotspot-click-to-create') : undefined}
      ref={ref}
    >
      {sortedHotspots.map(([hotspotId, hotspot]) => (
        <AuthorHotspot
          key={hotspotId}
          hotspotId={hotspotId}
          hotspot={hotspot}
          {...(movedOverThreshold && movingHotspot && movingHotspot.id === hotspotId
            ? {
                dragPosition: currentCoords,
              }
            : {
                startMoving,
                hotspotDispatch,
              })}
        />
      ))}
    </HotspotSurface>
  )
}
