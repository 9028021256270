import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecordingsInSessionQuery } from 'sierra-client/api/hooks/use-recording'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { floatingPill } from 'sierra-client/components/common/header-view'
import { FlexibleContentProgressBar } from 'sierra-client/components/liveV2/progress-bar'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FileView } from 'sierra-client/learn/learn-ui/file-view/file-view'
import { selectCourseProgressState } from 'sierra-client/state/card-progress/selectors'
import { selectRecapFileIds, selectRecapFiles } from 'sierra-client/state/flexible-content/recap-file'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { RoundAvatar, StyledColumn, Tooltip, TruncatedText } from 'sierra-ui/components'

import { Logging } from 'sierra-client/core/logging'
import { GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { CreateCardErrorForLearner } from 'sierra-client/views/flexible-content/create-card-error'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { PolarisEditorProvider } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { ProhibitCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { RecapCardRenderer } from 'sierra-client/views/recap/card-renderer'
import { RecapFileTree } from 'sierra-client/views/recap/recap-file-tree'
import { SelfPacedTitle } from 'sierra-client/views/self-paced/header'
import { GoToPreviousButton, NextUpButton } from 'sierra-client/views/self-paced/navigation-buttons'
import { PolarisOuterContainer } from 'sierra-client/views/self-paced/self-paced-page'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { CreateContentId, LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ScopedLiveContentId, ScopedYDocId } from 'sierra-domain/collaboration/types'
import { LiveSession } from 'sierra-domain/content/session'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { FlexibleContentJsonData } from 'sierra-domain/flexible-content/types'
import { GetLiveSessionRecordingResponse, LiveSesssionRecordingStatus } from 'sierra-domain/recordings'
import { getUserName } from 'sierra-domain/utils'
import { IconButton, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const overlayStyles = css`
  position: fixed;
`

const RecapCardCanvas = styled(CardCanvas)`
  flex-direction: column;
  overflow: hidden;
`

const CardColumn = styled(StyledColumn)`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  flex-basis: 1px;
  will-change: transform;

  border: 1px solid ${token('border/default')};
`

const RecapCardRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* Ensure the content fills the height regardless of card type */

  & > * {
    flex: 1;
  }
`

const PreviousButton = styled(GoToPreviousButton)``

const LeftBottomControlsWrapper = styled(View)`
  ${overlayStyles};
  left: 1rem;
  bottom: 1rem;
`

const RecordingButton = styled(View)`
  ${floatingPill}
`

const NextButton = styled(NextUpButton)`
  ${overlayStyles};
  right: 1rem;
  bottom: 1rem;
`

const RecapProgressBar = ({
  currentFileId,
  goToFile,
  flexibleContentId,
  flexibleContent,
  liveContentId,
}: {
  currentFileId: FileId | undefined
  goToFile: (fileId: FileId) => void
  flexibleContentId: CreateContentId
  flexibleContent?: FlexibleContentJsonData
  liveContentId: LiveContentId
}): JSX.Element | null => {
  const supportedFileIds = useSelector(state => selectRecapFileIds(state, liveContentId))
  const progress = useSelector(state => selectCourseProgressState(state, flexibleContentId))

  if (flexibleContent === undefined || currentFileId === undefined) return null

  return (
    <FlexibleContentProgressBar
      mode='recap'
      supportedFileIds={supportedFileIds}
      onClickFile={goToFile}
      flexibleContent={flexibleContent}
      currentFileId={currentFileId}
      progress={progress}
    />
  )
}

const VideoWrapper = styled(motion.div)`
  position: absolute;
  left: 1rem;
  bottom: 4.5rem;
  z-index: 1;
  width: 30rem;
  max-width: 90%;
  min-width: 16rem;
  aspect-ratio: 16 / 9;
  background: black;
  padding: ${spacing['4']};
  border-radius: ${p => p.theme.borderRadius.regular};
  resize: horizontal;
  overflow: auto;
`

const RecordingListItemView = styled(View)<{ $active: boolean; $status: LiveSesssionRecordingStatus }>`
  background-color: ${p => (p.$active ? 'rgba(212,242,255,0.5)' : 'rgba(212,242,255,0)')};
  color: ${token('foreground/secondary')};

  ${p =>
    p.$status !== 'completed' &&
    css`
      opacity: 0.5;
    `}
`

const RecordingListItem: React.FC<{
  recording: GetLiveSessionRecordingResponse
  active: boolean
  onClickRecording: (recordingId: string) => void
}> = ({ recording, onClickRecording, active }) => {
  const { formatTimestamp } = useLocalizedFormatters()
  const user = useUserLegacy(recording.recordedBy)
  const displayName = getUserName(user) ?? '<unknown user>'

  return (
    <Tooltip title={`Recording by ${displayName} at ${new Date(recording.createdAt).toLocaleString()}`}>
      <RecordingListItemView
        onClick={() => {
          if (recording.status === 'completed') {
            onClickRecording(recording.recordingId)
          }
        }}
        cursor={recording.status === 'completed' ? 'pointer' : 'default'}
        $status={recording.status}
        $active={active}
      >
        <RoundAvatar
          size='tiny'
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={getAvatarImage(user?.uuid, user?.avatar)}
          color={user?.avatarColor}
        />
        <TruncatedText size='small' color='currentColor' lines={1}>
          {formatTimestamp(recording.createdAt)}
        </TruncatedText>
      </RecordingListItemView>
    </Tooltip>
  )
}

const SectionHeader = styled(TruncatedText).attrs({
  bold: true,
  line: 1,
  size: 'small',
  color: 'foreground/secondary',
})``

const pasteFile = (): void => {}

const Recordings: React.FC<{
  recordings: GetLiveSessionRecordingResponse[] | undefined
  showRecordingPlayer: boolean
  recordingId: string | undefined
  onClickRecording: (recordingId: string) => void
}> = ({ recordings, showRecordingPlayer, recordingId, onClickRecording }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' padding='xxsmall'>
      <SectionHeader>{t('dictionary.recordings')}</SectionHeader>
      {recordings?.map(recording => (
        <RecordingListItem
          key={recording.recordingId}
          recording={recording}
          active={showRecordingPlayer && recordingId === recording.recordingId}
          onClickRecording={onClickRecording}
        />
      ))}
    </View>
  )
}

const RecapContainer = styled(PolarisOuterContainer)`
  flex: 1;
`

export const RecapPlayer: React.FC<{
  liveSessionId: LiveSessionId
  fileId: FileId
  liveSession: LiveSession
  liveContentId: LiveContentId
  yDoc: Y.Doc
  yDocId: ScopedYDocId
  awareness: Awareness
}> = props => {
  const { t } = useTranslation()

  const recordingsQueryResult = useRecordingsInSessionQuery({ liveSessionId: props.liveSessionId })
  const [showRecordingPlayer, setShowRecordingPlayer] = useState<boolean>(true)
  const [recordingId, setRecordingId] = useState<string>()
  const [recordingUrl, setRecordingUrl] = useState<string>()
  const recordings = recordingsQueryResult.data?.recordings.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  )
  const hasRecordings = recordingsQueryResult.isSuccess && recordingsQueryResult.data.recordings.length > 0

  const dispatch = useDispatch()

  useEffect(() => {
    if (recordings === undefined) return

    const latestAvailableRecording = recordings.findLast(recording => recording.hlsUrl !== undefined)
    if (latestAvailableRecording === undefined) return

    setRecordingId(latestAvailableRecording.recordingId)
  }, [recordings])

  useEffect(() => {
    if (recordingId !== undefined && recordings !== undefined) {
      const recording = recordings.find(recording => recording.recordingId === recordingId)
      if (recording === undefined) throw new Error('No recording with this ID found')
      if (recording.hlsUrl === undefined) throw new Error('This recording do not have a video')
      setRecordingUrl(recording.hlsUrl)
    }
  }, [recordingId, recordings])

  const onClickRecording = (clickedRecordingId: string): void => {
    if (recordingId === clickedRecordingId) {
      setShowRecordingPlayer(showRecordingPlayer => !showRecordingPlayer)
    } else {
      setRecordingId(clickedRecordingId)

      // lol
      setShowRecordingPlayer(false)
      setTimeout(() => {
        setShowRecordingPlayer(true)
      }, 500)
      void dispatch(
        Logging.liveSession.watchedRecording({ context: 'recap', recordingId: clickedRecordingId })
      )
    }
  }

  const recapFiles = useSelector(state => selectRecapFiles(state, props.liveContentId))
  const file = recapFiles.find(file => file.id === props.fileId)
  const fileIds = recapFiles.map(file => file.id)
  const flexibleContent = useSelector(state => selectFlexibleContent(state, props.liveContentId))

  const { nextFileId } = useMemo<{ nextFileId?: FileId; prevFileId?: FileId }>(() => {
    if (!file) return {}

    const currentIndex = fileIds.indexOf(file.id)
    return { nextFileId: fileIds[currentIndex + 1], prevFileId: fileIds[currentIndex - 1] }
  }, [file, fileIds])

  const nextFile = recapFiles.find(file => file.id === nextFileId)

  const goToRoot = useCallback(() => {
    return getGlobalRouter().navigate({ to: `/r/${props.liveSessionId}`, replace: true })
  }, [props.liveSessionId])

  const goToFile = useCallback(
    (fileId: FileId) => {
      return getGlobalRouter().navigate({ to: `/r/${props.liveSessionId}/${fileId}`, replace: true })
    },
    [props.liveSessionId]
  )

  const goStep = useCallback(
    (steps: number) => {
      const fileIndex = fileIds.findIndex(fileId => fileId === props.fileId)
      const newFileId = fileIds[fileIndex + steps]
      if (newFileId === undefined) throw new Error(`Can't find new file id`)

      return goToFile(newFileId)
    },
    [fileIds, props.fileId, goToFile]
  )

  const goBack = useCallback(() => goStep(-1), [goStep])
  const goForward = useCallback(() => goStep(1), [goStep])

  const isFirst = props.fileId === fileIds[0]
  const isLast = props.fileId === _.last(fileIds)

  useEffect(() => {
    const handleKey = async (keyboardEvent: KeyboardEvent): Promise<void> => {
      switch (keyboardEvent.key) {
        case 'ArrowRight':
          if (!isLast) return goForward()
          break
        case 'ArrowLeft':
          if (!isFirst) return goBack()
          break
      }
    }

    window.addEventListener('keydown', handleKey)
    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [goBack, goForward, isLast, isFirst])

  useEffect(() => {
    if (file !== undefined && !fileIds.includes(file.id)) {
      const firstFileId = fileIds[0]
      if (firstFileId !== undefined) {
        void goToFile(firstFileId)
      } else {
        throw new Error(`Incorrect file`)
      }
    }
  }, [file, fileIds, goToFile])

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: props.liveContentId }),
    [props.liveContentId]
  )

  if (!file) {
    return null
  } else if (!fileIds.includes(file.id)) {
    void goToRoot()
    return null
  }

  return (
    <>
      <RecapContainer>
        <PolarisEditorProvider
          yDocId={props.yDocId}
          yDoc={props.yDoc}
          awareness={props.awareness}
          pasteFile={pasteFile}
          fileId={file.id}
          readOnly={true}
        >
          <RecapProgressBar
            liveContentId={props.liveContentId}
            currentFileId={file.id}
            flexibleContent={flexibleContent}
            flexibleContentId={props.liveSession.data.flexibleContentId}
            goToFile={goToFile}
          />

          <GlobalNestedSidebar
            renderSidebar={() => (
              <>
                <View paddingLeft='8' gap='8'>
                  <SelfPacedTitle flexibleContentId={props.liveSession.data.flexibleContentId} />
                </View>
                {hasRecordings && (
                  <Recordings
                    recordings={recordings}
                    showRecordingPlayer={showRecordingPlayer}
                    recordingId={recordingId}
                    onClickRecording={onClickRecording}
                  />
                )}
                <FileView
                  flexibleContentId={props.liveSession.data.flexibleContentId}
                  fileTree={
                    <RecapFileTree liveContentId={props.liveContentId} file={file} goToFile={goToFile} />
                  }
                />
              </>
            )}
          />

          <CardColumn $shy={false} $disableScrollbarGutter $borderRadius={'10px'}>
            <PolarisCardTheme {...file}>
              <StrategicErrorBoundary
                id='recap'
                strategies={['remount']}
                Fallback={CreateCardErrorForLearner}
              >
                <RecapCardCanvas card={file} key={file.id} assetContext={assetContext}>
                  <ProhibitCardProgress>
                    <RecapCardRendererContainer>
                      <RecapCardRenderer
                        liveSessionId={props.liveSessionId}
                        scopedCreateContentId={ScopedLiveContentId.fromId(props.liveContentId)}
                        file={file}
                      />
                    </RecapCardRendererContainer>
                  </ProhibitCardProgress>
                </RecapCardCanvas>
              </StrategicErrorBoundary>

              {(!isFirst || hasRecordings) && (
                <LeftBottomControlsWrapper>
                  {!isFirst && (
                    <motion.div layout>
                      <Tooltip title={t('dictionary.previous')}>
                        <PreviousButton goToPrevious={goBack} />
                      </Tooltip>
                    </motion.div>
                  )}
                  {hasRecordings && (
                    <motion.div layout>
                      <Tooltip title={showRecordingPlayer ? 'Close recording' : 'Show recording'}>
                        <RecordingButton>
                          <IconButton
                            variant='transparent'
                            iconId={showRecordingPlayer ? 'close' : 'play--filled'}
                            color='black'
                            onClick={() => setShowRecordingPlayer(isShowing => !isShowing)}
                          />
                        </RecordingButton>
                      </Tooltip>
                    </motion.div>
                  )}
                </LeftBottomControlsWrapper>
              )}

              {nextFile && (
                <Tooltip title={t('dictionary.next')}>
                  <NextButton
                    action={{
                      arrow: {
                        action: goForward,
                      },
                    }}
                  />
                </Tooltip>
              )}
            </PolarisCardTheme>
            {hasRecordings && recordingUrl !== undefined && (
              <AnimatePresence>
                {showRecordingPlayer && (
                  <VideoWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <VideoPlayer
                      authSearchParams={new URL(recordingUrl).search}
                      options={{
                        autoplay: true,
                        bigPlayButton: true,
                        controls: true,
                        controlBar: { pictureInPictureToggle: false }, // add this toggle
                        // playbackRates: [1, 1.25, 1.5, 2],
                        preload: 'auto',
                        fill: true,
                        sources: [{ src: recordingUrl, type: 'application/x-mpegURL' }],
                      }}
                    />
                  </VideoWrapper>
                )}
              </AnimatePresence>
            )}
          </CardColumn>
        </PolarisEditorProvider>
      </RecapContainer>
    </>
  )
}
