import { useAtom, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import {
  generalSettingsDraftAtom,
  initialGeneralSettingsAtom,
} from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/atoms'
import { GeneralSettings } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/types'
import { useUserDetailData } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { UserId } from 'sierra-domain/api/uuid'

export const useInitializeGeneralSettings = (userId: UserId): boolean => {
  const { userData } = useUserDetailData(userId)
  const [initialGeneralSettings, setInitialGeneralSettings] = useAtom(initialGeneralSettingsAtom)
  const setGeneralSettingsDraftAtom = useSetAtom(generalSettingsDraftAtom)

  useEffect(() => {
    if (userData === undefined) {
      return
    }

    const initialGeneralSettings: GeneralSettings = {
      firstName: userData.firstName ?? '',
      lastName: userData.lastName ?? '',
      accessLevel: userData.accessLevel,
      accessRole: userData.accessRole?.role,
      userStatus: userData.status,
      email: userData.email ?? '',
    }

    setInitialGeneralSettings(initialGeneralSettings)
    setGeneralSettingsDraftAtom(initialGeneralSettings)
  }, [
    setGeneralSettingsDraftAtom,
    setInitialGeneralSettings,
    userData,
    userData?.accessLevel,
    userData?.firstName,
    userData?.status,
  ])

  return initialGeneralSettings === undefined
}
