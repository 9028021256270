import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import {
  useHasContentKindPermission,
  useHasOrganizationPermission,
} from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as actions from 'sierra-client/state/author-course-settings/actions'
import { selectors as settingsSelectors } from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { CourseSettingsButton } from 'sierra-client/views/course-settings/components/buttons'
import { DurationPicker } from 'sierra-client/views/course-settings/components/duration-picker'
import { GenerateDescriptionTextArea } from 'sierra-client/views/course-settings/components/generate-description-text-area'
import { PreviewThemePicker } from 'sierra-client/views/course-settings/components/preview-theme-picker'
import { TagsInput } from 'sierra-client/views/course-settings/components/tags-input'
import { HorizontalSettingsField } from 'sierra-client/views/course-settings/tabs/utils'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { CourseSettings, LinkSettings } from 'sierra-domain/api/common'
import type { AssetContext } from 'sierra-domain/asset-context'
import { FormElement } from 'sierra-ui/components'
import { InputPrimitive, Spacer, Switch } from 'sierra-ui/primitives'
import { ThemeName } from 'sierra-ui/theming/legacy-theme'

export const CourseSettingsTab: SettingsTabComponent = ({ courseId, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()

  const originalSettings = useSelector(settingsSelectors.selectOriginalSettings)
  const draftSettings = useSelector(settingsSelectors.selectDraftSettings)
  const parsedFormErrors = useSelector(settingsSelectors.selectParsedFormErrors)
  const courseKind = useSelector(settingsSelectors.selectCourseKind)
  const readOnly = useSelector(settingsSelectors.selectReadOnly)
  const safeThemeName: ThemeName = draftSettings?.theme?.name ?? 'white'

  const isTemplate = draftSettings?.templateSettings !== undefined
  const canMakeTemplatePublic = useHasOrganizationPermission('EDIT_TEMPLATE_PUBLIC')

  const canDeleteCourse = useHasContentKindPermission(courseId, 'DELETE')

  const enableScormPackageUpdate = getFlag('scorm-package-update')

  const assetContext: AssetContext = { type: 'course', courseId: courseId }

  const disabled = readOnly ?? false

  const handleSettingsChange = <F extends keyof CourseSettings, V extends CourseSettings[F]>(
    field: F,
    value: V
  ): void => {
    void dispatch(
      actions.updateCourseSettings({
        [field]: value,
      })
    )
  }

  const handleLinkSettingsChange = (linkSettings: LinkSettings): void => {
    void dispatch(
      actions.updateCourseSettings({
        linkSettings,
      })
    )
  }

  if (originalSettings === 'loading' || draftSettings === null || courseKind === null) return null

  return (
    <>
      {courseKind !== 'linkedin' && courseKind !== 'native:live' && courseKind !== 'native:self-paced' && (
        <>
          <FormElement label={t('dictionary.name')}>
            <InputPrimitive
              id='title'
              placeholder={t('author.course.name-placeholder')}
              value={draftSettings.title}
              error={parsedFormErrors.title !== undefined}
              onChange={e => handleSettingsChange('title', e.target.value)}
            />
          </FormElement>
          {courseKind === 'link' && (
            <FormElement label={t('author.slate.link.link')}>
              <InputPrimitive
                id='url'
                placeholder={t('author.slate.link.placeholder-url')}
                value={draftSettings.linkSettings?.url ?? ''}
                error={parsedFormErrors.linkSettings !== undefined}
                onChange={e => handleLinkSettingsChange({ url: e.target.value })}
              />
            </FormElement>
          )}
          <Spacer size='24' />
        </>
      )}
      {courseKind !== 'linkedin' && (
        <>
          <FormElement label={t('dictionary.description')}>
            <GenerateDescriptionTextArea
              courseKind={courseKind}
              disabled={disabled}
              placeholder={t('dictionary.description') + '...'}
              value={draftSettings.description ?? ''}
              onChange={val => handleSettingsChange('description', val)}
              selfPacedContentId={courseId}
            />
          </FormElement>
          <Spacer size='24' />
        </>
      )}
      <TagsInput
        initialSelectionTagIds={originalSettings.tags.map(s => s.id)}
        disabled={disabled}
        onChange={selection =>
          handleSettingsChange(
            'tags',
            selection.map(id => {
              return { id: id }
            })
          )
        }
      />
      <Spacer size='24' />
      {courseKind !== 'linkedin' && (
        <>
          <FormElement label={t('manage.paths.cover-image')}>
            <ImageUpload
              value={draftSettings.image}
              onChange={image => handleSettingsChange('image', image)}
              assetContext={assetContext}
              disabled={disabled}
            />
          </FormElement>
          <Spacer size='24' />
        </>
      )}
      {courseKind !== 'native:event-group' && (
        <>
          <FormElement label={t('admin.theme')}>
            <PreviewThemePicker
              theme={safeThemeName}
              onChange={theme => handleSettingsChange('theme', { type: 'preset', name: theme })}
              disabled={disabled}
            />
          </FormElement>
          <Spacer size='24' />
        </>
      )}
      {courseKind !== 'native:live' && courseKind !== 'native:event-group' && (
        <>
          <DurationPicker
            initialDuration={originalSettings.durationMinutes !== 0 ? originalSettings.durationMinutes : 30}
            onChange={newDuration => handleSettingsChange('durationMinutes', newDuration)}
            courseKind={courseKind}
            onToggle={automaticCalc => handleSettingsChange('automaticDuration', automaticCalc)}
            automaticCalc={originalSettings.automaticDuration}
            disabled={disabled}
          />
          <Spacer size='24' />
        </>
      )}
      {isTemplate && canMakeTemplatePublic === true && (
        <>
          <HorizontalSettingsField
            title={t('course-settings-v2.template-settings')}
            description={t('course-settings-v2.template-settings-visible-to-all-authors')}
          >
            <Switch
              checked={draftSettings.templateSettings?.visibleToAllAuthors ?? false}
              onChange={() =>
                handleSettingsChange('templateSettings', {
                  ...draftSettings.templateSettings,
                  visibleToAllAuthors: !Boolean(draftSettings.templateSettings?.visibleToAllAuthors),
                })
              }
              ariaLabel={t('course-settings-v2.template-settings-visible-to-all-authors')}
            />
          </HorizontalSettingsField>
          <Spacer size='24' />
        </>
      )}
      {enableScormPackageUpdate && courseKind === 'scorm' && (
        <>
          <HorizontalSettingsField
            title={t('course-settings.update-scorm-package')}
            description={t('course-settings.update-scorm-package-description')}
          >
            <CourseSettingsButton.UpdateScormPackage courseId={courseId} />
          </HorizontalSettingsField>
          <Spacer size='24' />
        </>
      )}
      {canDeleteCourse && (
        <>
          {courseKind !== 'native:course-group' ? (
            <CourseSettingsButton.DeleteCourse
              courseId={courseId}
              onDeleted={() => {
                onClose()
                notifications.push({ type: 'course-removed' })
                void getGlobalRouter().navigate({ to: '/create' })
              }}
            />
          ) : (
            <CourseSettingsButton.DetachAllEditionsFromCourseGroup courseGroupId={courseId} />
          )}
          <Spacer size='24' />
        </>
      )}
    </>
  )
}
