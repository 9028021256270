import _ from 'lodash'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectMessagesInThread, selectUnresolvedBlockThread } from 'sierra-client/state/chat/selectors'
import { browseCommentThread } from 'sierra-client/state/commenting/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCommentingContext } from 'sierra-client/views/commenting/context'
import { useEditorChatId } from 'sierra-client/views/v3-author/context'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { ColorBuilder, color } from 'sierra-ui/color'
import { SpacingProps, spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { Element } from 'slate'
import styled, { css } from 'styled-components'

const offset = 0
const border = 2

const BlockCommentIndicatorContainer = styled.span<{
  visible: boolean
  radius: NonNullable<BlockCommentIndicatorProps['radius']>
  withCommentCount: boolean
  $color: ColorBuilder
  $isInteractive: boolean
}>`
  position: absolute;
  inset: -${offset}px;
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: ${p => spacing[p.radius]};
  box-sizing: border-box;

  ${p =>
    !p.$isInteractive &&
    css`
      user-select: none;
      pointer-events: none;
    `}

  ${p =>
    p.withCommentCount &&
    css`
      & {
        border-bottom-right-radius: 0;
      }
    `}

  ${p =>
    p.visible &&
    css`
      & {
        box-shadow: 0 0 0px ${border}px ${p.$color.toString()};
      }
    `}
`

const CommentCountLabel = styled.div<{ $color: ColorBuilder }>`
  position: absolute;
  right: -${border}px;
  bottom: 0;
  padding: 0.25rem 0.5rem;
  background: ${p => p.$color.toString()};
  border-top-left-radius: ${spacing['4']};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

  ${fonts.body.micro};
  color: ${p => p.$color.shift(0.9).toString()};
`

type BlockCommentIndicatorProps = {
  element: Element
  radius?: SpacingProps
}

const BlockCommentIndicatorInner: React.FC<BlockCommentIndicatorProps & { chatId: ScopedChatId }> = ({
  element,
  radius = '4',
}) => {
  const { t } = useTranslation()
  const chatId = useEditorChatId()
  const commenting = useCommentingContext()
  const commentingColor = commenting?.getColor() || color('orangeLight')
  const dispatch = useDispatch()

  const unresolvedThread = useSelector(
    state => (chatId !== undefined ? selectUnresolvedBlockThread(state, chatId, element.id) : undefined),
    _.isEqual
  )

  const unresolvedThreadId = unresolvedThread?.id

  const threadId = unresolvedThreadId
  const count =
    useSelector(
      state => (chatId !== undefined ? selectMessagesInThread(state, chatId, threadId) : undefined),
      _.isEqual
    )?.length ?? 0

  const hasComments = count > 0
  const isTextNode = [
    'heading',
    'paragraph',
    'bulleted-list',
    'numbered-list',
    'preamble',
    'title-card-heading',
    'list-item',
    'bullet-card-item',
  ].includes(element.type)

  const displayCommentCount = hasComments && !isTextNode
  const containerRef = useRef<HTMLSpanElement | null>(null)

  const handleCountClick = useCallback(() => {
    if (commenting && unresolvedThread && unresolvedThread.type === 'tiptap-comment') {
      void dispatch(
        browseCommentThread({
          reason: 'user',
          comment: unresolvedThread,
        })
      )
    }
  }, [commenting, dispatch, unresolvedThread])

  return (
    <BlockCommentIndicatorContainer
      visible={hasComments}
      contentEditable={false}
      radius={radius}
      withCommentCount={displayCommentCount}
      ref={containerRef}
      $color={commentingColor}
      $isInteractive={hasComments}
    >
      {displayCommentCount && (
        <CommentCountLabel onClick={handleCountClick} $color={commentingColor}>
          {t('create.n-comment', { count })}
        </CommentCountLabel>
      )}
      {/*  Slate requires an empty element here, otherwise the element will be able to gain focus. */}
      <span />
    </BlockCommentIndicatorContainer>
  )
}

export const BlockCommentIndicator: React.FC<BlockCommentIndicatorProps> = props => {
  const chatId = useEditorChatId()

  if (chatId === undefined) {
    return null
  }

  return <BlockCommentIndicatorInner chatId={chatId} {...props} />
}
