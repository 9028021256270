import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'

/**
 * Hook to debounce an action.
 * example:
 * ```tsx
 * const debouncedAction = useDebouncedAction((query: string) => expensiveFetch(query), { wait: 300 })
 * ```
 */
export const useDebouncedAction = <I>(
  action: (_: I) => void,
  options: { wait: number } = { wait: 300 }
): ((_: I) => void) => {
  const debouncedAction = useMemo(() => debounce(action, options.wait), [action, options.wait])
  return useCallback(debouncedAction, [debouncedAction])
}
