import { Navigate, Outlet, createRootRouteWithContext } from '@tanstack/react-router'
import { LazyRouterDevtools } from 'sierra-client/devtools/router-devtools'
import { getGlobalRouter } from 'sierra-client/router'
import { SierraRouterContext } from 'sierra-client/router-provider'
import { AppRootComponent, initAppGlobals } from 'sierra-client/routes/-app.client'

initAppGlobals()

const RootComponent = (): JSX.Element => {
  return (
    <>
      <AppRootComponent>
        <Outlet />
      </AppRootComponent>
      <LazyRouterDevtools />
    </>
  )
}

export const Route = createRootRouteWithContext<SierraRouterContext>()({
  component: RootComponent,
  notFoundComponent: () => {
    const router = getGlobalRouter()
    const invalidPath = router.latestLocation.pathname
    return <Navigate to='/404' mask={{ to: invalidPath }} replace />
  },
  /**
   * Global search params, mostly for typing expected string params.
   * - should always be optional, as crashing here will crash the whole app (including the 500-page)
   * - should never add defaults, as that will append them to every page
   */
  validateSearch: (search: Record<string, unknown>) => {
    const globalSearch: { errorCode?: string; settingsTab?: 'settings' | 'notifications' } = {}

    if (search.settingsTab === 'settings' || search.settingsTab === 'notifications') {
      globalSearch.settingsTab = search.settingsTab
    }

    if (search.errorCode !== undefined) {
      globalSearch.errorCode = String(search.errorCode)
    }

    return globalSearch
  },
})
