import { encode as toBase64 } from 'js-base64'
import { subscribeSse } from 'sierra-client/state/api'
import { OpenAIMessage, OutputControls } from 'sierra-domain/api/author-v2'
import {
  SSEXRealtimeAuthorGenerateBulletCardHTML,
  SSEXRealtimeAuthorGenerateGeneralCardHTML,
  SSEXRealtimeAuthorGenerateInteractiveCard,
} from 'sierra-domain/routes-sse'

type Input = Omit<Parameters<typeof subscribeSse>[0], 'route' | 'input'>

export const generateCardHTMLSse = ({
  messages,
  outputControls,
  targetCardType,
  ...args
}: {
  messages: OpenAIMessage[]
  outputControls: OutputControls | undefined
  targetCardType: 'general' | 'bullet'
} & Input): ReturnType<typeof subscribeSse> => {
  return subscribeSse({
    route:
      targetCardType === 'general'
        ? SSEXRealtimeAuthorGenerateGeneralCardHTML
        : SSEXRealtimeAuthorGenerateBulletCardHTML,
    input: {
      outputControls,
      messages: toBase64(JSON.stringify(messages)),
    },
    ...args,
  })
}

export const generateInteractiveCardSse = ({
  messages,
  outputControls,
  ...args
}: {
  messages: OpenAIMessage[]
  outputControls: OutputControls | undefined
} & Input): ReturnType<typeof subscribeSse> => {
  return subscribeSse({
    route: SSEXRealtimeAuthorGenerateInteractiveCard,
    input: { messages: toBase64(JSON.stringify(messages)), outputControls },
    ...args,
  })
}
