import { createFileRoute } from '@tanstack/react-router'

import { useLocation, type ParsedLocation } from '@tanstack/react-router'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import {
  useBrowseTeamspaces,
  useJoinTeamspaceMutation,
  useRequestTeamspaceAccess,
} from 'sierra-client/api/hooks/use-teamspace'
import { TeamspaceAccess, useCheckTeamspaceAccess } from 'sierra-client/features/teamspace'
import { teamspaceJoinedLogger } from 'sierra-client/features/teamspace/logger'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, PlainSanaPage } from 'sierra-client/layout/sana-page'
import { GlobalRouter, getGlobalRouter } from 'sierra-client/router'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { useDispatch } from 'sierra-client/state/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeCollaborationRequestAccess } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { min_height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'

const ErrorPageWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 30.625rem;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  ${min_height_100dvh}
`

const RequestCourseAccessPage: FC<{ source: string; message: string; submittedMessage: string }> = ({
  source,
  message,
  submittedMessage,
}) => {
  const { t } = useTranslation()

  const contentId = source
    .split('/')
    .filter(part => !part.startsWith('file:'))
    .pop() as NanoId12

  const permissions = useContentKindPermissions(contentId)

  const { postWithUserErrorException } = usePost()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const sendRequestAccess = async (): Promise<void> => {
    if (isSubmitted) return

    try {
      setIsSubmitted(true)
      await postWithUserErrorException(XRealtimeCollaborationRequestAccess, { contentId })
    } catch (e) {
      console.error(e)
    }
  }

  const [isRedirecting, setIsRedirecting] = useState(false)
  useEffect(() => {
    if (isRedirecting) return

    if (/^\/create\/(s|l)/.test(source) && (permissions.has('EDIT_CONTENT') || permissions.has('COMMENT'))) {
      setIsRedirecting(true)
      void getGlobalRouter().navigate({ to: source })
    }
  }, [isRedirecting, permissions, source])

  if (permissions.status === 'loading') {
    return null
  }

  return (
    <PlainSanaPage mode='light' headerType='none' page={PageIdentifier.Error()}>
      <ErrorPageWrap>
        <Heading size='h3' bold avoidHanging align='center'>
          {t('content.permission.access-denied.title')}
        </Heading>
        <Spacer size='xsmall' />
        <Text size='large' spacing='xsmall' align='center'>
          {message}
        </Text>
        <Spacer />

        <View direction='column' alignItems='center'>
          <View>
            <Button
              type='submit'
              variant='primary'
              onClick={() => {
                void sendRequestAccess()
              }}
              icon={isSubmitted ? 'checkmark' : undefined}
            >
              {t('content.permission.access-denied.request-access')}
            </Button>
            {permissions.has('LEARN') && /^\/create\/s/.test(source) && (
              <Button variant='transparent' href={`/s/${contentId}`}>
                {t('content.permission.access-denied.view-published')}
              </Button>
            )}
            {permissions.has('LEARN') && /^\/create\/l/.test(source) && (
              <Button variant='transparent' href={`/course/${contentId}`}>
                {t('content.permission.access-denied.view-sessions')}
              </Button>
            )}
          </View>
          <View>
            <Text aria-hidden={!isSubmitted} color='foreground/muted'>
              {isSubmitted ? submittedMessage : '\u00A0'}
            </Text>
          </View>
        </View>
      </ErrorPageWrap>
    </PlainSanaPage>
  )
}

const AccessDeniedTeamspace: FC<{ source: string }> = ({ source }) => {
  const { t } = useTranslation()
  const teamspaceId = source.split('/').pop() as NanoId12

  const access = useCheckTeamspaceAccess(teamspaceId)
  const publicTeamspaceQuery = useBrowseTeamspaces({
    select: data => data.available.find(teamspace => teamspace.id === teamspaceId),
  })

  const joinMutation = useJoinTeamspaceMutation()
  const dispatch = useDispatch()

  const requestAccessMutation = useRequestTeamspaceAccess()

  useEffect(() => {
    switch (access) {
      case TeamspaceAccess.ALLOWED:
      case TeamspaceAccess.DENIED:
      case TeamspaceAccess.LOADING:
        break
      case TeamspaceAccess.ERROR:
        void notFound404Redirect()
        break
      default:
        access satisfies never
    }
  }, [access])

  if (access === TeamspaceAccess.LOADING || publicTeamspaceQuery.isPending) {
    return null
  }

  const isPublicTeamspace = isDefined(publicTeamspaceQuery.data)

  switch (access) {
    case TeamspaceAccess.ALLOWED:
      void getGlobalRouter().navigate({ to: source })
      return null

    case TeamspaceAccess.ERROR:
      return null

    case TeamspaceAccess.DENIED:
      return (
        <PlainSanaPage mode='light' headerType='none' page={PageIdentifier.Error()}>
          <ErrorPageWrap>
            <Heading size='h3' bold avoidHanging align='center'>
              {t('content.permission.access-denied.teamspace.message')}
            </Heading>
            <Spacer />

            <View justifyContent='center'>
              <Button
                id={'home-button'}
                type='submit'
                variant='ghost'
                onClick={() => {
                  void getGlobalRouter().navigate({ to: '/' })
                }}
              >
                {t('content.permission.access-denied.back-home')}
              </Button>

              <Button
                type='submit'
                variant={requestAccessMutation.isSuccess ? 'primary' : 'secondary'}
                icon={requestAccessMutation.isSuccess ? 'checkmark' : undefined}
                onClick={() => {
                  if (isPublicTeamspace) {
                    joinMutation.mutate(
                      { teamspaceId: teamspaceId },
                      {
                        onSuccess: () => {
                          void dispatch(
                            teamspaceJoinedLogger({
                              teamspaceId: teamspaceId,
                            })
                          )
                        },
                      }
                    )
                  } else {
                    requestAccessMutation.mutate({ teamspaceId })
                  }
                }}
              >
                {isPublicTeamspace
                  ? `${t('dictionary.join')} ${publicTeamspaceQuery.data?.displayName ?? 'teamspace'}`
                  : t('content.permission.access-denied.request-access')}
              </Button>
            </View>
          </ErrorPageWrap>
        </PlainSanaPage>
      )
  }
  access satisfies never
}

const stripSearchString = (str: string): string => {
  const searchStartIndex = str.indexOf('?')
  return searchStartIndex === -1 ? str : str.slice(0, searchStartIndex)
}

/**
 * Returns the source page that redirected to the "Access denied" page, which
 * can be set either via navigation masking or the "source" query param.
 */
function useAccessDeniedRedirectSource(): string {
  const location = useLocation<GlobalRouter, ParsedLocation>()

  const sourceSearchQuery = (location.search as { source?: string }).source
  if (isDefined(sourceSearchQuery)) return stripSearchString(sourceSearchQuery)

  const maskedPathname = location.maskedLocation?.pathname
  if (isDefined(maskedPathname)) return maskedPathname

  return ''
}

function Custom401(): JSX.Element {
  const { t } = useTranslation()
  const source = useAccessDeniedRedirectSource()

  if (source !== '') {
    if (/^\/create\/(s|l)/.test(source)) {
      return (
        <RequestCourseAccessPage
          source={source}
          message={t('content.permission.access-denied.message')}
          submittedMessage={t('content.permission.access-denied.submitted-message')}
        />
      )
    }

    if (/^\/l\//.test(source)) {
      return (
        <RequestCourseAccessPage
          source={source}
          message={t('content.permission.access-denied.live.message')}
          submittedMessage={t('content.permission.access-denied.live.submitted-message')}
        />
      )
    }

    if (/^\/t\//.test(source)) {
      return <AccessDeniedTeamspace source={source} />
    }
  }

  return (
    <PlainSanaPage mode='light' headerType='none' page={PageIdentifier.Error()}>
      <ErrorPageWrap>
        <Heading size='h3' bold avoidHanging align='center'>
          {t('content.permission.access-denied.title')}
        </Heading>
        <Spacer />

        <View direction='column'>
          <Button
            type='submit'
            variant='secondary'
            id={'home-button'}
            onClick={() => {
              void getGlobalRouter().navigate({ to: '/' })
            }}
          >
            {t('content.permission.access-denied.back-home')}
          </Button>
        </View>
      </ErrorPageWrap>
    </PlainSanaPage>
  )
}

export type LoadingPermissionsWrapperProps = {
  isLoading: boolean
  hasAccess: boolean
}

export const LoadingPermissionsWrapper: FC<PropsWithChildren<LoadingPermissionsWrapperProps>> = ({
  isLoading,
  hasAccess,
  children,
}) => {
  if (isLoading) {
    return null
  }

  if (!hasAccess) {
    return <Custom401 />
  }

  return children
}

const RouteComponent = Custom401

export const Route = createFileRoute('/access-denied')({
  component: RouteComponent as React.FC,
})
