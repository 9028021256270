import { capitalize } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Tabs } from 'sierra-client/components/common/layout/tabs'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useSkillsFlagEnabled } from 'sierra-client/features/skills'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  CollaboratorsTab,
  CourseSettingsTab,
  ExportTab,
  GlossaryTab,
  TranslateTab,
} from 'sierra-client/views/course-settings/tabs'
import { CertificatesTab } from 'sierra-client/views/course-settings/tabs/certificates-tab'
import { CollaboratorGroupsTab } from 'sierra-client/views/course-settings/tabs/collaborator-groups-tab'
import { ExercisesTab } from 'sierra-client/views/course-settings/tabs/exercises-tab'
import { SkillsTab } from 'sierra-client/views/course-settings/tabs/skills-tab'
import { SettingsTabConfig, TabID } from 'sierra-client/views/course-settings/types'
import { Button, LoadingSpinner, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  flex: 1;
  display: grid;
  gap: 0.5rem;
  grid: repeat(2, 1fr) / 1fr;
  justify-items: center;
`

const TabWrapper = styled.div`
  padding: 1.5rem 0;
  min-height: 75vh;
`

const settingsTabs: Record<TabID, SettingsTabConfig> = {
  'course-settings': {
    id: 'course-settings',
    labelKey: 'author.course-settings.course-settings',
    generatesDraft: true,
    component: CourseSettingsTab,
  },
  'collaborators': {
    id: 'collaborators',
    labelKey: 'dictionary.collaborator-plural',
    generatesDraft: false,
    component: CollaboratorsTab,
  },
  'collaborator-groups': {
    id: 'collaborator-groups',
    labelKey: 'dictionary.collaborator-group-plural',
    generatesDraft: false,
    component: CollaboratorGroupsTab,
  },
  'export': {
    id: 'export',
    labelKey: 'dictionary.export',
    generatesDraft: false,
    component: ExportTab,
  },
  'glossary': {
    id: 'glossary',
    labelKey: 'dictionary.glossary',
    generatesDraft: false,
    component: GlossaryTab,
  },
  'translate': {
    id: 'translate',
    labelKey: 'dictionary.translate',
    generatesDraft: false,
    component: TranslateTab,
  },
  'certificates': {
    id: 'certificates',
    labelKey: 'dictionary.certificate-plural',
    generatesDraft: false,
    component: CertificatesTab,
  },
  'skills': {
    id: 'skills',
    labelKey: 'dictionary.skills',
    generatesDraft: false,
    component: SkillsTab,
  },
  'exercises': {
    id: 'exercises',
    labelKey: 'dictionary.homework-plural',
    generatesDraft: false,
    component: ExercisesTab,
  },
}

type CourseSettingsModalProps = { onSave?: () => void; onClose?: () => void }

export const CourseSettingsModal: React.FC<CourseSettingsModalProps> = ({
  onSave = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isFetching = useSelector(settingsState.selectors.isFetching)
  const view = useSelector(settingsState.selectors.currentView)
  const originalSettings = useSelector(settingsState.selectors.selectOriginalSettings)
  const courseKind = useSelector(settingsState.selectors.selectCourseKind)
  const hasUnsavedSettingsChanges = useSelector(settingsState.selectors.hasUnsavedSettingsChanges)
  const courseId = useSelector(settingsState.selectors.currentCourseId)

  const orgPermissions = useOrganizationPermissions()
  const userCanCreateCertificates = orgPermissions.has('CREATE_CERTIFICATES')
  const userCanManageSkills = orgPermissions.has('MANAGE_SKILLS')
  const skillsFlagEnabled = useSkillsFlagEnabled()
  const skillsEnabled = skillsFlagEnabled && userCanManageSkills

  const [confirmClose, setConfirmClose] = useState(false)

  const activeTabs = useMemo<SettingsTabConfig[]>(() => {
    let tabs: TabID[] = []

    if (courseKind === 'linkedin' || courseKind === 'link' || courseKind === 'scorm') {
      tabs = ['course-settings', 'collaborators']
    } else if (courseKind === 'native:self-paced') {
      tabs = ['course-settings', 'collaborators', 'export', 'glossary', 'translate']
    } else if (courseKind === 'native:live') {
      tabs = ['course-settings', 'collaborators', 'export', 'translate']
    } else if (courseKind === 'native:course-group' || courseKind === 'scorm:course-group') {
      tabs = ['course-settings', 'collaborators', 'collaborator-groups', 'export']
    } else if (courseKind === 'native:event-group') {
      tabs = ['course-settings', 'collaborators']
    }

    if (userCanCreateCertificates) {
      tabs = [...tabs, 'certificates']
    }

    if (skillsEnabled) {
      tabs = [...tabs, 'skills']
    }

    return tabs.map(id => settingsTabs[id])
  }, [courseKind, userCanCreateCertificates, skillsEnabled])

  const handleSaveSettings = async (): Promise<void> => {
    await dispatch(settingsActions.saveSettings({ notify: true }))
    onSave()
  }

  const handleModalClose = (): void => {
    if (!hasUnsavedSettingsChanges) {
      void dispatch(settingsActions.setView(null))

      onClose()
    } else {
      setConfirmClose(true)
    }
  }

  if (view === null || courseId === null) return null

  return (
    <AppThemeTokenProvider followSystemColorScheme={false}>
      {/* Todo: refactor to support darkmode */}
      <Modal
        open
        onClose={handleModalClose}
        title={originalSettings === 'loading' ? '' : originalSettings.title}
        size='medium'
        footer={
          hasUnsavedSettingsChanges ? (
            <FooterContainer>
              <Button onClick={handleSaveSettings} disabled={!hasUnsavedSettingsChanges}>
                {t('author.save-settings')}
              </Button>
              <Text color='grey35' size='small'>
                {t('author.save-settings-description')}
              </Text>
            </FooterContainer>
          ) : undefined
        }
      >
        {isFetching ? (
          <LoadingSpinner padding='large' />
        ) : activeTabs.length === 1 && activeTabs[0] !== undefined ? (
          React.createElement(activeTabs[0].component, { courseId, onSave, onClose: handleModalClose })
        ) : (
          <Tabs
            small
            onClick={(tab: TabID | null) => dispatch(settingsActions.setView(tab))}
            current={view}
            tabs={activeTabs.map(tab => ({
              id: tab.id,
              title: capitalize(t(tab.labelKey)),
              content: (
                <TabWrapper>
                  {React.createElement(tab.component, { courseId, onSave, onClose: handleModalClose })}
                </TabWrapper>
              ),
            }))}
          />
        )}
      </Modal>
      <ActionModal
        open={confirmClose}
        title={t('author.discard-changes')}
        primaryActionLabel={t('dictionary.discard')}
        onClose={() => {
          setConfirmClose(false)
        }}
        primaryAction={() => {
          void dispatch(settingsActions.resetDraftSettings())
          void dispatch(settingsActions.setView(null))

          onClose()
        }}
        deleteAction
      >
        {t('author.about-to-discard-unsaved-changes')}
      </ActionModal>
    </AppThemeTokenProvider>
  )
}
