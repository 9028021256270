import React, { FC, useEffect, useRef, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useNarrationVideo } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-video'
import { BlurButton, Loader } from 'sierra-client/views/flexible-content/card-narration/atoms'
import { GeneratingNarrationLayer } from 'sierra-client/views/flexible-content/card-narration/generating-narration-layer'
import { Layers } from 'sierra-client/views/flexible-content/card-narration/layers'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'
import { assertNever, iife } from 'sierra-domain/utils'
import styled from 'styled-components'

const Video = styled.video`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  max-width: 100%;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
`

type ExistingNarrationProps = {
  onDelete: () => void | Promise<void>
  card: File
  contentId: CreateContentId
}

const Container = styled(Layers.Container).attrs({
  larger: true,
  shadow: true,
  $position: 'absolute',
})``

export const ExistingNarration: FC<ExistingNarrationProps> = ({ onDelete, card, contentId }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [playing, handlers] = useToggle()
  const { t } = useTranslation()
  const { permission } = useCreatePageYDocContext()
  const fileTheme = useThemeForFile(card)
  const videoState = useNarrationVideo(card.narration, contentId)

  const playPauseToggle = (): void => {
    if (videoRef.current === null) return
    try {
      if (videoRef.current.paused) {
        void videoRef.current.play()
      } else {
        void videoRef.current.pause()
      }
    } catch (e) {
      console.debug('Could not interact with narration', e)
    }
  }

  useEffect(() => {
    const onPlay = (): void => {
      handlers.on()
    }

    const onPause = (): void => {
      handlers.off()
    }

    const node = videoRef.current

    if (node === null) return

    node.addEventListener('play', onPlay)
    node.addEventListener('pause', onPause)

    return () => {
      node.removeEventListener('play', onPlay)
      node.removeEventListener('pause', onPause)
    }
  }, [videoRef, handlers])

  const [canPlay, setCanPlay] = useState(false)

  const confirmationModal = useConfirmationModalContext()

  if (card.narration === undefined) return null

  return (
    <React.Fragment
      // We need a key to invalidate the video, otherwise it will not update properly when the video changes
      key={card.id}
    >
      {iife(() => {
        switch (videoState.type) {
          case 'success':
            return (
              <Container>
                <Layers.Layer>
                  <Video
                    ref={videoRef}
                    onClick={playPauseToggle}
                    disablePictureInPicture
                    onCanPlay={() => setCanPlay(true)}
                  >
                    <source src={videoState.videoUrl} type='video/mp4' />
                    {t('ai-narrations.no-video-format-support')}
                  </Video>
                  {!canPlay && (
                    <Layers.Layer $passive>
                      <Loader fileTheme={fileTheme} $loading />
                    </Layers.Layer>
                  )}
                </Layers.Layer>
                <Layers.Layer
                  $passive
                  withFade
                  data-hover={true}
                  padding='xxsmall'
                  alignItems={permission === 'edit' ? 'flex-end' : 'center'}
                  justifyContent='center'
                >
                  <BlurButton
                    iconId={playing ? 'pause--filled' : 'play--filled'}
                    onClick={playPauseToggle}
                    tooltip={playing ? t('dictionary.pause') : t('dictionary.play')}
                  />
                  {permission === 'edit' && (
                    <BlurButton
                      iconId='trash-can'
                      onClick={() => {
                        confirmationModal.show({
                          title: t('alert.are-you-sure'),
                          bodyText: '',
                          confirmLabel: t('dictionary.remove'),
                          onConfirm: () => {
                            void onDelete()
                          },
                        })
                      }}
                      tooltip={t('create.narration.remove')}
                    />
                  )}
                </Layers.Layer>
              </Container>
            )
          case 'loading':
            return (
              <Container>
                <Layers.Layer $passive>
                  <Loader fileTheme={fileTheme} $loading />
                </Layers.Layer>
              </Container>
            )
          case 'generating':
            return (
              <Container>
                <GeneratingNarrationLayer
                  videoState={videoState}
                  contentId={contentId}
                  canEdit={permission === 'edit'}
                  confirmationModalContext={confirmationModal}
                />
              </Container>
            )
          case 'error':
            //Todo (Norah | Jesper): Handle error state
            return null
          case 'no-narration':
            return null
          default:
            assertNever(videoState)
        }
      })}
    </React.Fragment>
  )
}
