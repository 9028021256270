import _ from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { TabBar, TabContent, TabContentInner, Tabs } from 'sierra-client/components/common/layout/tabs'
import {
  UseContentSubjectAssignmentsProps,
  useContentSubjectAssignments,
} from 'sierra-client/components/common/modals/multi-assign-modal/hooks'
import { CloseIcon } from 'sierra-client/components/common/modals/multi-assign-modal/icons'
import {
  ItemContent,
  ItemList,
  SelectedItem,
} from 'sierra-client/components/common/modals/multi-assign-modal/items'
import {
  CourseList,
  ListProps,
  LiveSessionList,
  PathList,
  ProgramList,
  UserGroupList,
  UserList,
} from 'sierra-client/components/common/modals/multi-assign-modal/lists'
import {
  AssignModalSelection,
  ItemType,
  ItemUnion,
  LiveContentAssignmentType,
  SubjectType,
} from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { DueDateDialog } from 'sierra-client/views/manage/components/due-date'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { DueDateGroup } from 'sierra-domain/api/manage'
import { assertNever, iife } from 'sierra-domain/utils'
import { MenuItem, Modal } from 'sierra-ui/components'
import { Button, Spacer, Text } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled from 'styled-components'

const ModalContent = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  min-width: 65.875rem;
  width: 100%;
  max-width: 65.875rem;

  display: grid;
  grid-template-columns: 1fr 1fr;

  height: calc(90vh - 2rem);
  max-height: calc(90vh - 2rem);
`

const ModalColumn = styled.div<{ backgroundColor?: DesignToken }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(90vh - 2rem);

  background-color: ${p => (p.backgroundColor ? token(p.backgroundColor) : token('surface/default'))};

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  margin: 0 1.5rem;
  padding: 1.5rem 0;
  z-index: 3;
`

const AssignHeader = styled(ModalHeader)`
  background-color: ${token('surface/default')};
  border-bottom: 1px solid ${token('border/default')};
`

const SelectedHeader = styled(ModalHeader)`
  border-bottom: 1px solid ${token('border/strong')};
`

const SelectedFooter = styled.div<{ $withDropdown?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  padding: 1rem 0;
  border-top: 1px solid ${token('border/strong')};

  flex-direction: row;
  justify-content: space-between;
`

const ModalHeading = styled(Text).attrs({
  bold: true,
  center: true,
  size: 'regular',
})``

const Separator = styled.span`
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${token('border/default')};
`

const TabContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex: auto;

  ${TabBar} {
    position: sticky;
    bottom: 0;
    background-color: ${token('surface/default')};
    z-index: 3;
    border-top: 1px solid ${token('border/default')};
    /* Hack to get positioning of top border correct */
    border-left: 2rem solid ${token('surface/default')};
    border-right: 2rem solid ${token('surface/default')};
  }

  ${TabContent}, ${TabContentInner} {
    height: 100%;
  }
`

export const AssignmentTypeInput: React.FC<{
  subjectType: SubjectType
  autoAssignmentAvailable: boolean
  value: LiveContentAssignmentType
  setValue: (x: LiveContentAssignmentType) => void
}> = ({ subjectType, value, setValue, autoAssignmentAvailable }) => {
  const { t } = useTranslation()

  // TODO: Unify with checkbox and handle user/group differences better.
  const dropdownCopy = useMemo<Record<LiveContentAssignmentType, string>>(
    () => ({
      'self-enroll':
        subjectType === 'user-group'
          ? t('manage.assign-modal.live-assignment-type-label.self-enroll-group')
          : t('manage.assign-modal.live-assignment-type-label.self-enroll-user'),
      'auto-assign': t('manage.assign-modal.live-assignment-type-label.auto-assign'),
      'manual': t('manage.assign-modal.live-assignment-type-label.manual'),
    }),
    [t, subjectType]
  )

  const dropdownItems: MenuItem<LiveContentAssignmentType>[] = useMemo(
    () =>
      _.compact(['manual', 'self-enroll', autoAssignmentAvailable && 'auto-assign']).map(assignmentType => ({
        type: 'label',
        id: assignmentType,
        label: dropdownCopy[assignmentType],
      })),
    [dropdownCopy, autoAssignmentAvailable]
  )

  return (
    <SingleSelectDropdown<LiveContentAssignmentType>
      variant='ghost'
      bold
      grow={false}
      selectedItem={dropdownItems.find(it => it.id === value)}
      menuItems={dropdownItems}
      onSelect={item => setValue(item.id)}
    />
  )
}

const SectionTitle = styled(Text).attrs({
  size: 'small',
  color: 'grey50',
})``

const SelectedSection = styled.div`
  padding-top: 2rem;

  margin: 0 1.5rem;

  &:not(:first-child) {
    border-top: 1px solid ${token('border/strong')};
  }
`

const SelectedItemList = styled(ItemList)`
  border-color: ${token('border/strong')};
`

type PaneConfig<T extends ItemType> = {
  id: ItemType
  title: TranslationKey
  render: (props: ListProps<T>) => JSX.Element
}

type PaneConfigurations = { [K in ItemType]: PaneConfig<K> }

const useAvailablePaneConfigurations = (): PaneConfigurations => {
  return useMemo(
    () => ({
      'course': {
        id: 'course',
        title: 'dictionary.course-plural',
        render(props) {
          return <CourseList key='course' {...props} />
        },
      },
      'path': {
        id: 'path',
        title: 'dictionary.path-plural',
        render(props) {
          return <PathList key='path' {...props} />
        },
      },
      'user-group': {
        id: 'user-group',
        title: 'dictionary.group-plural',
        render(props) {
          return <UserGroupList key='user-group' {...props} />
        },
      },
      'program': {
        id: 'program',
        title: 'dictionary.program-plural',
        render(props) {
          return <ProgramList key='program' {...props} />
        },
      },
      'user': {
        id: 'user',
        title: 'dictionary.user-plural',
        render(props) {
          return <UserList key='user' {...props} />
        },
      },
      'live-session': {
        id: 'live-session',
        title: 'content.sessions',
        render(props) {
          return <LiveSessionList key='live-session' {...props} />
        },
      },
    }),
    []
  )
}

const itemTypeToTranslationKey: Record<ItemType, TranslationKey> = {
  'course': 'dictionary.course-plural',
  'path': 'dictionary.path-plural',
  'user': 'dictionary.user-plural',
  'user-group': 'dictionary.group-plural',
  'program': 'dictionary.program-plural',
  'live-session': 'content.sessions',
}

export type AssignModalProps<T extends SubjectType> = {
  subjectType: T
  /** This has to be memorized, if you are sending only one id, don't wrap it in an array */
  subjects: string[] | string
  panes: Array<ItemType>
  activePane: ItemType
  title: string
  isOpen: boolean
  showDueDates?: ItemType[]
  onClose: () => void
  onSave: (selections: AssignModalSelection[]) => void | Promise<void>
} & (T extends 'course' | 'path'
  ? { subjectsSupportAssignmentSettings: boolean; autoAssignmentAvailable: boolean }
  : { subjectsSupportAssignmentSettings?: never; autoAssignmentAvailable?: never })

export const AssignModal = <T extends SubjectType>({
  subjectType,
  subjects,
  panes,
  activePane,
  title,
  isOpen,
  showDueDates = [],
  onClose,
  onSave,
  subjectsSupportAssignmentSettings,
  autoAssignmentAvailable,
}: AssignModalProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const availablePaneConfigurations = useAvailablePaneConfigurations()

  // Makes sure it's an array because that is how it's used
  const memoSubjects = useMemo(() => (_.isArray(subjects) ? subjects : [subjects]), [subjects])

  const [tab, setTab] = useState(activePane)
  const [selected, setSelected] = useState<ItemUnion[]>([])
  const [dueDates, setDueDates] = useState<{ id: string; type: ItemType; dueDate: DueDateGroup }[]>([])
  const [showDueDateDialog, setShowDueDateDialog] = useState<ItemUnion | undefined>()
  const [debouncedQuery, query, setQuery] = useDebouncedAndLiveState('')
  const canSetContentDueDates = useHasOrganizationPermission('SET_CONTENT_DUE_DATES')

  const [liveAssignmentType, setLiveAssignmentType] = useState<LiveContentAssignmentType>('manual')

  const settingAssignmentTypeAvailable = useMemo(() => {
    switch (subjectType) {
      case 'user':
      case 'program':
        return selected.some(
          x =>
            // We can't check if the path contains live courses, so we allow this setting for all paths.
            x.type === 'path' ||
            (x.type === 'course' && x.kind === 'native:live') ||
            (x.type === 'course' && x.kind === 'native:event-group')
        )
      case 'course':
      case 'path':
        return (
          subjectsSupportAssignmentSettings === true &&
          panes.every(pane => pane === 'user' || pane === 'user-group' || pane === 'program')
        )
      case 'live-session':
      case 'user-group':
      case 'calendar-event':
        return false
      default:
        assertNever(subjectType)
    }
  }, [subjectsSupportAssignmentSettings, subjectType, selected, panes])

  const { alreadyAssigned } = useContentSubjectAssignments({
    subjects: memoSubjects,
    subjectType,
    // todo: fix cast :/
  } as UseContentSubjectAssignmentsProps)

  const contentRef = useRef<HTMLDivElement>(null)
  const dueDateById = useMemo(
    () =>
      _.chain(dueDates)
        .keyBy('id')
        .mapValues(item => item.dueDate)
        .value(),
    [dueDates]
  )

  const selectedIds: Set<string> = useMemo(() => new Set(selected.map(item => item.id)), [selected])

  const handleSelect = useCallback(
    (item: ItemUnion): void => {
      if (selectedIds.has(item.id)) return // Should not be reachable
      if (item.disabled === true) return // Should not be reachable

      setSelected(currentSelected => [...currentSelected, item])
    },
    [selectedIds]
  )

  const handleRemove = useCallback((item: ItemUnion): void => {
    setSelected(currentSelected => currentSelected.filter(it => it.id !== item.id))
    setDueDates(dds => dds.filter(dd => dd.id !== item.id))
  }, [])

  const handleToggle = useCallback(
    (item: ItemUnion): void => {
      if (selectedIds.has(item.id)) {
        handleRemove(item)
      } else {
        handleSelect(item)
      }
    },
    [selectedIds, handleRemove, handleSelect]
  )

  const handleSave = (): void => {
    // const allSelectedIds = selected.map(s => s.id)
    // const dueDatesSelection = dueDates.filter(d => allSelectedIds.includes(d.id)) // Makes sure we only send due dates for what is actually selected
    const dueDateById = _.chain(dueDates)
      .keyBy('id')
      .mapValues(i => i.dueDate)
      .value()

    const selectionWithMeta = selected.map((s): AssignModalSelection => {
      const includeAssignmentType =
        settingAssignmentTypeAvailable &&
        (s.type === 'user' ||
          s.type === 'user-group' ||
          s.type === 'program' ||
          (s.type === 'course' && s.kind === 'native:live') ||
          (s.type === 'course' && s.kind === 'native:event-group') ||
          // We don't have enough information about the path here.
          s.type === 'path')

      return {
        dueDate: dueDateById[s.id],
        liveAssignmentType: includeAssignmentType ? liveAssignmentType : undefined,
        ...iife(() => {
          // ?? there has to be a better way to do this
          switch (s.type) {
            case 'user':
              return {
                id: s.id,
                type: s.type,
              }
            case 'course':
              return {
                id: s.id,
                type: s.type,
              }
            case 'user-group':
              return {
                id: s.id,
                type: s.type,
              }
            case 'program':
            case 'live-session':
            case 'path':
              return {
                id: s.id,
                type: s.type,
              }
            default:
              assertNever(s)
          }
        }),
      }
    })

    void onSave(selectionWithMeta)
  }

  const handleClose = (): void => {
    setSelected([])
    onClose()
  }

  const dueDateDialogType: 'user' | 'group' =
    subjectType === 'user' || showDueDateDialog?.type === 'user' ? 'user' : 'group'
  const paneConfigurations = panes.map(pane => availablePaneConfigurations[pane])

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} size={{ width: 1054, height: 904 }}>
        <ModalContent ref={contentRef}>
          <ModalColumn>
            <AssignHeader>
              <ModalHeading>{title}</ModalHeading>
              <Spacer axis='vertical' size='small' />
              <Separator />
              <Spacer axis='vertical' size='small' />
              <RoundedSearchBar
                value={query}
                onChange={value => setQuery(value)}
                placeholder={t('dictionary.search')}
              />
            </AssignHeader>
            {isOpen && (
              <TabContainer>
                {paneConfigurations.length === 1 ? (
                  paneConfigurations.map(pane => {
                    return pane.render({
                      subjectType,
                      subjects: memoSubjects,
                      selectedIds,
                      handleToggle,
                      searchQuery: debouncedQuery,
                      assignedIds: alreadyAssigned[pane.id],
                    })
                  })
                ) : (
                  <Tabs
                    current={tab}
                    onClick={setTab}
                    small
                    keepMounted
                    tabs={panes
                      .map(paneId => availablePaneConfigurations[paneId])
                      .map(pane => ({
                        id: pane.id,
                        title: t(pane.title),
                        content: pane.render({
                          subjectType,
                          subjects: memoSubjects,
                          selectedIds,
                          handleToggle,
                          searchQuery: debouncedQuery,
                          assignedIds: alreadyAssigned[pane.id],
                        }),
                      }))}
                  />
                )}
              </TabContainer>
            )}
          </ModalColumn>
          <ModalColumn backgroundColor='surface/soft'>
            <SelectedHeader>
              <ModalHeading>{t('modal.selected')}</ModalHeading>
            </SelectedHeader>
            <SelectedItemList>
              {_.chain(selected)
                .groupBy('type')
                .map((group: ItemUnion[], type: ItemType) => (
                  <SelectedSection key={`section-${type}`}>
                    <SectionTitle>{t(itemTypeToTranslationKey[type])}</SectionTitle>
                    {group.map(item => (
                      <SelectedItem key={item.id} onClick={() => handleRemove(item)}>
                        <ItemContent
                          item={item}
                          onDueDateClick={
                            canSetContentDueDates && showDueDates.includes(type)
                              ? i => setShowDueDateDialog(i)
                              : undefined
                          }
                          dueDate={dueDateById[item.id]}
                        />
                      </SelectedItem>
                    ))}
                  </SelectedSection>
                ))
                .value()}
            </SelectedItemList>
            <SelectedFooter>
              {settingAssignmentTypeAvailable && (
                <AssignmentTypeInput
                  subjectType={subjectType}
                  autoAssignmentAvailable={
                    selected.some(it => it.type === 'course' && it.kind === 'native:live') ||
                    autoAssignmentAvailable === true
                  }
                  value={liveAssignmentType}
                  setValue={setLiveAssignmentType}
                />
              )}
              <Button onClick={handleSave} disabled={selected.length === 0}>
                {t('dictionary.assign')}
              </Button>
            </SelectedFooter>
          </ModalColumn>
          <CloseIcon onClick={handleClose} />
        </ModalContent>
      </Modal>
      <DueDateDialog
        open={showDueDateDialog !== undefined}
        value={showDueDateDialog !== undefined ? dueDateById[showDueDateDialog.id] : undefined}
        type={dueDateDialogType}
        onClose={() => setShowDueDateDialog(undefined)}
        onChange={dueDate => {
          if (showDueDateDialog === undefined) return
          setDueDates(dd => {
            const without = dd.filter(i => i.id !== showDueDateDialog.id)
            if (dueDate === undefined) return without
            return without.concat({
              id: showDueDateDialog.id,
              type: showDueDateDialog.type,
              dueDate,
            })
          })
          setShowDueDateDialog(undefined)
        }}
      />
    </>
  )
}
