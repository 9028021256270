import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { HomeV3Browse, prefetchBrowseContent } from 'sierra-client/views/learner/home/home-v3-browse'

const BrowsePage: React.FC = () => {
  return <HomeV3Browse />
}

const RouteComponent = requireLoggedIn(BrowsePage)

export const Route = createFileRoute('/browse')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (context.isLoggedIn && getFlag('preloading')) {
      void prefetchBrowseContent()
    }
  },
})
