import { useCallback } from 'react'
import { useEventGroupEnrollmentRequestsPill } from 'sierra-client/api/hooks/use-event-group-enrollment-requests-pill'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useGetHomeworksPill } from 'sierra-client/api/hooks/use-homework'
import { getFlag } from 'sierra-client/config/global-config'
import { LabelHeaderItem } from 'sierra-client/features/collapsable-sidebar/items/header-item'
import { sidebarMainNavigationClickedLogger } from 'sierra-client/features/global-sidebar/logger'
import { getSkillsRecommendedContentQuery } from 'sierra-client/features/skills'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { useManageRouting } from 'sierra-client/views/manage/components/hooks/use-manage-routing'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { useTagsEnabled } from 'sierra-client/views/manage/permissions/use-tags-enabled'
import { PageName } from 'sierra-domain/segment/page'
import { assertNever } from 'sierra-domain/utils'
import { TokenOrColor, resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { aiGradient } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled, { css } from 'styled-components'

const StyledPill = styled.div.attrs({
  role: 'status',
})<{ pillVariant?: 'default' | 'ai'; $background?: TokenOrColor }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding: 0 4px;
  min-width: 16px;
  border-radius: 8px;

  ${p => {
    switch (p.pillVariant) {
      case 'default':
      case undefined: {
        return p.$background !== undefined
          ? css`
              background-color: ${resolveTokenOrColor(p.$background, p.theme)};
            `
          : null
      }
      case 'ai': {
        return css`
          ${aiGradient}
        `
      }
      default:
        assertNever(p.pillVariant)
    }
  }}
`

const StyledPillText = styled(Text).attrs({
  size: 'technical',
  bold: true,
})<{ color?: DesignToken }>`
  color: ${p => (p.color === undefined ? palette.primitives.white : token(p.color))};
  white-space: nowrap;
`

const matchesManagePage = (url: string, page: string): boolean => {
  if (url.startsWith('courses') || url.startsWith('paths')) {
    return page === 'content'
  }
  return url.startsWith(page)
}

export function useManageLayoutSidebarItems(): LabelHeaderItem[] {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const orgPermissions = useOrganizationPermissions()

  const enrollmentRequestsPill = useEventGroupEnrollmentRequestsPill()
  const homeworksPill = useGetHomeworksPill()

  /* Flags */
  const insightsEnabled = useInsightsEnabled()
  const homeworkAvailable = orgPermissions.has('MANAGE_HOMEWORK')
  const tagsAvailable = useTagsEnabled()
  const skillsAvailable = getFlag('skills/allow-new-skills') && orgPermissions.has('MANAGE_SKILLS')
  const eventGroupsEnabled = getFlag('enable-event-groups')
  const isReportsDeprecated = getFlag('insights/deprecated-reports-ui')
  const preload = getFlag('preloading')

  const certificatesAvailable = useAreCertificatesEnabled()

  const skillsQuery = useGraphQuery(
    {
      document: getSkillsRecommendedContentQuery,
      queryOptions: {
        enabled: skillsAvailable,
        refetchInterval: 60_000,
      },
    },
    {}
  )
  const skillsSuggestionCount = skillsQuery.isSuccess
    ? skillsQuery.data.skills.data.reduce((count, skill) => count + skill.recommendedContent.length, 0)
    : null

  const log = useCallback(
    (value: string) => () => {
      void dispatch(sidebarMainNavigationClickedLogger({ navigatedTo: `manage-${value}` }))
    },
    [dispatch]
  )

  const manageRouterPath = useManageRouting()
  const [section, subpage] = manageRouterPath
  const url = `${section}${subpage !== undefined ? `/${subpage}` : ''}`

  return [
    {
      type: 'label',
      id: PageName.Insights,
      title: t('admin.organization.tab.insights'),
      href: '/manage/insights',
      hidden: !insightsEnabled,
      onClick: log('insights'),
      preload,
      isSelected: matchesManagePage(url, 'insights'),
    },
    {
      type: 'label',
      id: PageName.OrganizationOverview,
      title: t('admin.organization.tab.overview'),
      href: '/manage/overview',
      hidden: insightsEnabled,
      onClick: log('overview'),
      isSelected: matchesManagePage(url, 'overview'),
    },
    {
      type: 'label',
      id: PageName.OrganizationReports,
      title: t('admin.organization.tab.reports'),
      href: '/manage/reports',
      hidden: isReportsDeprecated,
      onClick: log('reports'),
      isSelected: matchesManagePage(url, 'reports'),
    },
    {
      type: 'label',
      id: 'people',
      title: t('admin.organization.tab.users'),
      href: '/manage/users',
      onClick: log('users'),
      preload,
      isSelected: matchesManagePage(url, 'users'),
    },
    {
      type: 'label',
      id: 'user-groups',
      title: t('admin.organization.tab.groups'),
      href: '/manage/user-groups',
      onClick: log('user-groups'),
      isSelected: matchesManagePage(url, 'user-groups'),
    },
    {
      type: 'label',
      id: 'programs',
      title: t('admin.organization.tab.programs'),
      href: '/manage/programs',
      onClick: log('programs'),
      isSelected: matchesManagePage(url, 'programs'),
      tourId: 'tour-manage-programs',
    },
    {
      type: 'label',
      id: PageName.OrganizationContent,
      title: t('dictionary.content-plural'),
      href: '/manage/content',
      onClick: log('content'),
      preload,
      isSelected: matchesManagePage(url, 'content'),
    },
    {
      type: 'label',
      id: PageName.Skills,
      title: t('dictionary.skills'),
      hidden: skillsAvailable !== true,
      href: '/manage/skills',
      onClick: log('skills'),
      postComponent:
        skillsSuggestionCount !== null && skillsSuggestionCount > 0 ? (
          <StyledPill pillVariant='ai'>
            <StyledPillText color='success/foreground'>{skillsSuggestionCount.toString()}</StyledPillText>
          </StyledPill>
        ) : undefined,
      isSelected: matchesManagePage(url, 'skills'),
    },
    {
      type: 'label',
      id: PageName.OrganizationEventGroups,
      title: t('dictionary.in-person-events'),
      hidden: eventGroupsEnabled !== true,
      href: '/manage/in-person-events',
      postComponent:
        enrollmentRequestsPill !== undefined ? (
          <StyledPill $background='destructive/background'>
            <StyledPillText>{enrollmentRequestsPill}</StyledPillText>
          </StyledPill>
        ) : undefined,
      onClick: log('in-person-events'),
      isSelected: matchesManagePage(url, 'in-person-events'),
    },
    {
      type: 'label',
      id: PageName.Homeworks,
      title: t('dictionary.homework-plural'),
      href: '/manage/exercises',
      hidden: homeworkAvailable !== true,
      postComponent:
        homeworksPill !== undefined ? (
          <StyledPill
            $background='success/background'
            aria-label={t('manage.homework.submission-pill', { count: parseInt(homeworksPill, 10) })}
          >
            <StyledPillText>{homeworksPill}</StyledPillText>
          </StyledPill>
        ) : undefined,
      onClick: log('exercises'),
      preload,
      isSelected: matchesManagePage(url, 'exercises'),
    },
    {
      type: 'label',
      id: 'certificates',
      title: t('admin.organization.tab.certificates'),
      href: '/manage/certificates',
      hidden: !certificatesAvailable,
      onClick: log('certificates'),
      isSelected: matchesManagePage(url, 'certificates'),
    },
    {
      type: 'label',
      id: PageName.OrganizationSkills,
      title: t('admin.organization.tab.tags'),
      hidden: !tagsAvailable,
      href: '/manage/tags',
      onClick: log('tags'),
      isSelected: matchesManagePage(url, 'tags'),
      tourId: 'tour-manage-tags',
    },
  ]
}
