import React from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  FileScrollAnchor,
  useScrollToActiveFileInSidebar,
} from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import { selectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { RenderFolder } from 'sierra-client/components/liveV2/shared/folder-item'
import { CardIcon } from 'sierra-client/features/collapsable-sidebar'
import { useFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { FileTree } from 'sierra-client/views/flexible-content/render-file-tree'
import { File } from 'sierra-domain/flexible-content/types'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const FileContainer = styled(View)<{ $isCurrent: boolean }>`
  padding: 8px 4px 8px 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 0.5rem;
  gap: 10px;
  align-items: center;

  background-color: ${p => (p.$isCurrent ? token('surface/soft')(p) : 'transparent')};
  color: ${token('foreground/secondary')};
`

const FileExplorerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  flex-grow: 1;
`

const FileHighlight: FCC<{ isCurrent: boolean }> = ({ children, isCurrent }) => {
  return (
    <FileContainer $isCurrent={isCurrent} position='relative'>
      {children}
    </FileContainer>
  )
}

const FileTitle: React.FC<{ node: File; isCurrent: boolean }> = ({ node, isCurrent }) => {
  const { yDoc } = useFlexibleContentYDoc()
  const cardTitle = useFileTitle(yDoc, node)
  return (
    <TruncatedTextWithTooltip
      lines={1}
      size='small'
      bold
      color={isCurrent ? 'foreground/primary' : 'foreground/muted'}
    >
      {cardTitle}
    </TruncatedTextWithTooltip>
  )
}

const DisablePointerEvents = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`

const NormalFile = ({ node, currentCard }: { node: File; currentCard: File | undefined }): JSX.Element => {
  const isCurrent = currentCard?.id === node.id

  return (
    <FileScrollAnchor fileId={node.id}>
      <FileHighlight isCurrent={isCurrent}>
        <DisablePointerEvents>
          <CardIcon isCurrentItem={isCurrent} showPlayIcon={isCurrent} />
        </DisablePointerEvents>
        <View grow overflow='hidden'>
          <FileTitle node={node} isCurrent={isCurrent} />
        </View>
      </FileHighlight>
    </FileScrollAnchor>
  )
}

export const FileExplorer = (): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const contentId = liveSession.data.flexibleContentId
  const flexibleContent = useSelector(state => selectFlexibleContent(state, contentId))
  const currentCard = useSelector(state => selectCurrentCard(state, contentId))

  useScrollToActiveFileInSidebar()

  if (!flexibleContent) return <p>loading...</p>

  return (
    <FileExplorerContainer>
      <FileTree
        content={flexibleContent}
        renderFile={file => <NormalFile node={file} currentCard={currentCard} />}
        renderFolder={(folder, children) => (
          <RenderFolder node={folder} currentCard={currentCard}>
            {children}
          </RenderFolder>
        )}
      />
    </FileExplorerContainer>
  )
}
