import {
  XRealtimeAdminAssignmentsUnassignFromCourse,
  XRealtimeAdminCoursesCourseComments,
  XRealtimeAdminCoursesCourseDetail,
  XRealtimeAdminCoursesListEnrolledGroups,
  XRealtimeAdminCoursesListEnrolledUsers,
  XRealtimeAdminCoursesListLiveSessions,
  XRealtimeAdminCoursesUpdateCourseVisibility,
  XRealtimeAuthorDeleteCourse,
} from 'sierra-domain/routes'

import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { getGlobalRouter } from 'sierra-client/router'
import { ContentUsersTableProps } from 'sierra-client/views/manage/components/content-tables'
import {
  BaseListUsersRequest,
  ContentProgramEnrollment,
  CourseDetailResponse,
  CourseRating,
  ListContentEnrolledUsersResponse,
  ListCourseEnrolledUsersRequest,
  LiveSessionRow,
} from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'

export type UseCourseDetailsData = {
  courseData?: CourseDetailResponse
  courseUserAssignments: ListContentEnrolledUsersResponse & { isLoading: boolean; filtered: boolean }
  courseProgramAssignments: ContentProgramEnrollment[]
  courseComments: CourseRating[]
  liveSessions: LiveSessionRow[]
  isLoading: boolean
  saveVisibility: (courseId: CourseId, value: boolean) => Promise<void>
  fetchCourse: () => Promise<void>
  fetchUserAssignments: ContentUsersTableProps['fetchUserAssignments'] // Declared on the table so we can re-use in use-path-details
  fetchProgramAssignments: (courseId: CourseId) => Promise<ContentProgramEnrollment[]>
  fetchLiveSessions: (courseId: CourseId) => Promise<LiveSessionRow[]>
  fetchComments: (courseId: CourseId) => Promise<CourseRating[]>
  unassignFromCourse: (courseId: CourseId, unassigns: { userIds: UserId[] }) => Promise<void>
  deleteCourse: () => Promise<void>
}

export const useManageCourseDetails = (courseId: CourseId): UseCourseDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [courseData, setCourseData] = useState<CourseDetailResponse | undefined>(undefined)
  const [courseComments, setCourseComments] = useState<CourseRating[]>([])
  const [liveSessions, setLiveSessions] = useState<LiveSessionRow[]>([])

  const [courseUserAssignments, setCourseUserAssignments] = useState<
    UseCourseDetailsData['courseUserAssignments']
  >({
    hasMore: false,
    data: [],
    isLoading: false,
    filtered: false,
  })
  const [courseProgramAssignments, setCourseProgramAssignments] = useState<ContentProgramEnrollment[]>([])

  const saveVisibility = useCallback(
    async (courseId: CourseId, value: boolean): Promise<void> => {
      setCourseData(current => (current !== undefined ? { ...current, isVisible: value } : undefined))
      await postWithUserErrorException(XRealtimeAdminCoursesUpdateCourseVisibility, {
        id: courseId,
        isVisible: value,
      })
    },
    [postWithUserErrorException]
  )

  const fetchCourse = useCallback(async () => {
    const courseDataResponse = await postWithUserErrorException(XRealtimeAdminCoursesCourseDetail, {
      id: courseId,
    })

    setCourseData(courseDataResponse)
  }, [postWithUserErrorException, courseId])

  const lastAssignmentQuery = useRef<{
    request: ListCourseEnrolledUsersRequest
    response: ListContentEnrolledUsersResponse
  } | null>(null)

  const fetchUserAssignments = useCallback<UseCourseDetailsData['fetchUserAssignments']>(
    async (courseId, commonFilters: Partial<BaseListUsersRequest> = {}, options = {}) => {
      const request: ListCourseEnrolledUsersRequest = {
        courseId,
        assignedToLiveSession: undefined,
        commonFilters: {
          lastUserId: undefined,
          maxResults: options.forCsv === true ? courseData?.assignedOrStartedUsersCount : undefined,
          query: undefined,
          sortBy: { direction: 'asc', type: 'name' },
          requestedUserIds: undefined,
          groupIds: undefined,
          ...commonFilters,
        },
      }

      // An empty query is equivalent to an undefined query. We canonicalize to be able to better
      // block redundant searches with the isEqual check below.
      if (request.commonFilters.query === '') {
        request.commonFilters.query = undefined
      }

      // If the last request was identical to this one, then just return the previous result
      if (
        options.reset !== true &&
        lastAssignmentQuery.current !== null &&
        _.isEqual(request, lastAssignmentQuery.current.request)
      ) {
        return lastAssignmentQuery.current.response
      }

      setCourseUserAssignments(current => ({
        ...current,
        isLoading: true,
        filtered: Boolean(request.commonFilters.query),
      }))

      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledUsers, request)

      lastAssignmentQuery.current = {
        request,
        response,
      }

      // Don't set state for CSV export
      if (options.forCsv === true) {
        setCourseUserAssignments(current => ({
          ...current,
          isLoading: false,
          filtered: Boolean(request.commonFilters.query),
        }))
        return response
      }

      setCourseUserAssignments(current =>
        options.reset === true
          ? { ...response, isLoading: false, filtered: Boolean(request.commonFilters.query) }
          : {
              hasMore: response.hasMore,
              data: current.data.concat(response.data),
              isLoading: false,
              filtered: Boolean(request.commonFilters.query),
            }
      )
      return response
    },
    [postWithUserErrorException, courseData?.assignedOrStartedUsersCount]
  )

  const fetchProgramAssignments = useCallback<UseCourseDetailsData['fetchProgramAssignments']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledGroups, {
        id: courseId,
      })
      setCourseProgramAssignments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  const fetchComments = useCallback<UseCourseDetailsData['fetchComments']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesCourseComments, {
        id: courseId,
      })
      setCourseComments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  const unassignFromCourse = useCallback<UseCourseDetailsData['unassignFromCourse']>(
    async (courseId, { userIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromCourse, {
        courseId,
        userIds,
      })

      await fetchCourse() // updates counts

      setCourseUserAssignments(current => ({
        ...current,
        data: current.data.filter(u => !userIds.includes(u.userInfo.baseUserInfo.userId)),
      }))
    },
    [postWithUserErrorException, fetchCourse]
  )

  const fetchLiveSessions = useCallback<UseCourseDetailsData['fetchLiveSessions']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListLiveSessions, {
        flexibleContentId: courseId,
      })
      setLiveSessions(response)
      return response
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    void (async () => {
      setIsLoading(true)
      await fetchCourse()
      setIsLoading(false)
    })()
  }, [fetchCourse])

  // Load separately
  useEffect(
    //
    () => void fetchUserAssignments(courseId, undefined, { reset: true }),
    [courseId, fetchUserAssignments]
  )

  useEffect(
    //
    () => void fetchProgramAssignments(courseId),
    [courseId, fetchProgramAssignments]
  )

  useEffect(
    //
    () => void fetchComments(courseId),
    [courseId, fetchComments]
  )

  // Fetch live sessions for native:live
  useEffect(() => {
    if (courseData?.kind !== 'native:live') return
    void fetchLiveSessions(courseId)
  }, [courseData?.kind, courseId, fetchLiveSessions])

  const deleteCourse = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAuthorDeleteCourse, {
      courseId,
    })

    void getGlobalRouter().navigate({ to: '/manage/content', replace: true })
  }, [postWithUserErrorException, courseId])

  return {
    courseData,
    courseUserAssignments,
    courseProgramAssignments,
    courseComments,
    liveSessions,
    isLoading,
    fetchUserAssignments,
    fetchProgramAssignments,
    fetchLiveSessions,
    saveVisibility,
    fetchCourse,
    unassignFromCourse,
    deleteCourse,
    fetchComments,
  }
}
