import { default as React, useCallback, useMemo } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import {
  HeaderButtonColorCSS,
  HeaderButtonGroupWrapper,
  HeaderGroupLastButtonCSS,
} from 'sierra-client/components/liveV2/header-buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  getAssessmentAllowRetry,
  getAssessmentHideCorrectAnswers,
  getAssessmentPassingCriteria,
  getAssessmentTimeLimit,
} from 'sierra-client/state/flexible-content/selectors'
import {
  useCreatePageContext,
  useIsLiveCourseOrInLive,
} from 'sierra-client/views/flexible-content/create-page-context'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { apply } from 'sierra-domain/editor/operations'
import { File } from 'sierra-domain/flexible-content/types'
import { Input, MenuItem } from 'sierra-ui/components'
import { Description, Label, TextContainer } from 'sierra-ui/components/menu/atoms'
import { InputPrimitive, Spacer, Switch, View } from 'sierra-ui/primitives'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const ToolbarItemContainer = styled(View).attrs({
  justifyContent: 'space-between',
  grow: true,
})``

const cleanPassingCriteria = (input: string): number => {
  const parsed = parseInt(input)

  if (isNaN(parsed) || parsed <= 0) {
    return 0
  }

  if (parsed >= 100) {
    return 100
  }

  return parsed
}

const cleanTimeLimitCriteria = (input: string): number => {
  const parsed = parseInt(input)

  if (isNaN(parsed) || parsed <= 0) {
    return 0
  }

  return parsed
}

const StyledIconMenu = styled(IconMenu)`
  cursor: pointer;

  padding: 0 0.375rem;

  ${HeaderButtonColorCSS}
  ${HeaderGroupLastButtonCSS}
`

const PassingCriteria: React.FC<{
  file: File
  initialTimeLimit: number | undefined
}> = ({ file, initialTimeLimit }) => {
  const fileId = file.id
  const { operationState } = useCreatePageContext()
  const initialPassingCriteria = getAssessmentPassingCriteria(file)
  const allowRetry = getAssessmentAllowRetry(file)
  const hideCorrectAnswers = getAssessmentHideCorrectAnswers(file)

  const { t } = useTranslation()

  const updatePassingCriteria = useCallback(
    async (passingCriteria: number): Promise<void> => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          if (file.data.type !== 'assessment-card') throw Error(`File ${fileId} is not an assessment-card`)
          file.data.settings.passingCriteria = passingCriteria
        },
      })
    },
    [operationState, fileId]
  )

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      void updatePassingCriteria(cleanPassingCriteria(e.target.value) / 100)
    },
    [updatePassingCriteria]
  )

  const updateAllowRetry = useCallback(async (): Promise<void> => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        if (file.data.type !== 'assessment-card') throw Error(`File ${fileId} is not an assessment-card`)
        file.data.settings.allowRetry = !allowRetry
      },
    })
  }, [operationState, fileId, allowRetry])

  const updateHideCorrectAnswers = useCallback(async (): Promise<void> => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        if (file.data.type !== 'assessment-card') throw Error(`File ${fileId} is not an assessment-card`)
        file.data.settings.hideCorrectAnswers = !hideCorrectAnswers
      },
    })
  }, [operationState, fileId, hideCorrectAnswers])

  const updateTimeLimit = useCallback(
    async (timeLimit: number | undefined): Promise<void> => {
      if (timeLimit === 0) return
      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          if (file.data.type !== 'assessment-card') throw Error(`File ${fileId} is not an assessment-card`)
          file.data.settings.timeLimit = timeLimit
        },
      })
    },
    [operationState, fileId]
  )

  const iconMenuItems: MenuItem[] = useMemo(
    () => [
      {
        id: 'passing-criteria',
        type: 'canvas',
        render() {
          return (
            <ToolbarItemContainer>
              <Input
                id='passing-criteria'
                label={t('assessment-card.passing-criteria')}
                helperText={t('assessment-card.passing-critera-info')}
                spacing='none'
                type='text'
                placeholder='0'
                value={`${Math.round(initialPassingCriteria * 100)}`}
                onChange={handleTextChange}
              />
            </ToolbarItemContainer>
          )
        },
      },
      {
        id: 'allow-retry',
        type: 'switch',
        label: t('assessment-card.allow-retries'),
        description: t('assessment-card.allow-retries-description'),
        onToggleChange: updateAllowRetry,
        checked: allowRetry,
      },
      {
        id: 'reveal-correct-answers',
        type: 'switch',
        label: t('assessment-card.hide-correct-answers'),
        description: t('assessment-card.hide-correct-answers-description'),
        onToggleChange: updateHideCorrectAnswers,
        checked: hideCorrectAnswers,
      },
      {
        id: 'time-limit',
        type: 'canvas',
        render() {
          return (
            <>
              <ToolbarItemContainer paddingRight='4'>
                <View direction='column' gap='none' grow>
                  <View grow justifyContent='space-between'>
                    <Label bold={true}>{t('assessment-card.time-limit')}</Label>

                    <Switch
                      text=''
                      textPos='left'
                      size='small'
                      onChange={() => {
                        if (initialTimeLimit === undefined) {
                          void updateTimeLimit(5)
                        } else void updateTimeLimit(undefined)
                      }}
                      checked={initialTimeLimit !== undefined ? true : false}
                    />
                  </View>
                  <TextContainer grow>
                    <Description color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
                      {t('assessment-card.time-limit-desc')}
                    </Description>
                  </TextContainer>
                  <Spacer size='8' />
                  {initialTimeLimit !== undefined && (
                    <InputPrimitive
                      type='number'
                      value={initialTimeLimit.toString()}
                      onChange={e => updateTimeLimit(cleanTimeLimitCriteria(e.target.value))}
                    />
                  )}
                </View>
              </ToolbarItemContainer>
            </>
          )
        },
      },
    ],
    [
      t,
      updateAllowRetry,
      allowRetry,
      updateHideCorrectAnswers,
      hideCorrectAnswers,
      initialPassingCriteria,
      handleTextChange,
      initialTimeLimit,
      updateTimeLimit,
    ]
  )

  return <StyledIconMenu withChevron color='foreground/primary' iconId='settings' items={iconMenuItems} />
}

export const AssessmentCardToolbar: React.FC<{
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
}> = ({ file, previewThemes }) => {
  const initialTimeLimit = getAssessmentTimeLimit(file)
  const isLiveCourseOrInLive = useIsLiveCourseOrInLive()

  /* No toolbar in live as it's a quiz card */
  if (isLiveCourseOrInLive) {
    return null
  }

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />
      {initialTimeLimit !== 'wrong-card-type' && (
        <PassingCriteria file={file} initialTimeLimit={initialTimeLimit} />
      )}
    </HeaderButtonGroupWrapper>
  )
}
