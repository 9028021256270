import { useSearch } from '@tanstack/react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { removeSearchParams } from 'sierra-client/router/search-params'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationForm } from 'sierra-client/views/authentication/authentication-layout'
import {
  ContinueAsGuest,
  ContinueWithGoogle,
  ContinueWithSaml,
  PrivacyPolicyDisclaimer,
  Separator,
  getAuthMethodForEmail,
  intersperse,
  useLogIn,
} from 'sierra-client/views/authentication/common'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { SamlAuthMethod } from 'sierra-domain/api/private'
import { isLeft } from 'sierra-domain/either'
import { isUserErrorCode } from 'sierra-domain/error'
import { XRealtimeAuthCreateSession } from 'sierra-domain/routes'
import { getValidatedEmail } from 'sierra-domain/utils'
import { FormElement, PasswordInputPrimitive } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'

type Props = {
  switchToForgotPassword: () => void
  switchToLiveGuestAccess?: () => void
  switchToSignUp?: () => void
  onLogIn?: () => void
}

export const SignIn: React.FC<Props> = ({
  switchToForgotPassword,
  switchToSignUp,
  switchToLiveGuestAccess,
  onLogIn,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const orgConfig = config.organization
  const errorCode = useSearch({ strict: false, select: s => s.errorCode })
  const { handleSamlLogIn } = useLogIn()

  useEffect(() => {
    if (errorCode !== undefined && isUserErrorCode(errorCode)) {
      setError(t(getUserErrorTranslationKey(errorCode)))
      void removeSearchParams(['errorCode'])
    }
  }, [errorCode, t])

  const method = useMemo(() => {
    const validatedEmail = getValidatedEmail(email.toLowerCase().trim())
    if (validatedEmail === undefined) return undefined

    return getAuthMethodForEmail(orgConfig.auth, validatedEmail)
  }, [orgConfig, email])

  const hasSamlLogin = orgConfig.auth.some(v => v.type === 'saml')
  const hasSocialLogin = orgConfig.auth.some(v => v.type === 'social')
  const hasPasswordLogin = orgConfig.auth.some(v => v.type === 'password')

  const submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    setError(undefined)

    const validatedEmail = getValidatedEmail(email.toLowerCase().trim())
    let errors = false

    if (validatedEmail === undefined) {
      errors = true
      setEmailError(t('login.errors.no-account'))
    } else {
      setEmailError(undefined)
    }

    if (!password) {
      errors = true
      setPasswordError(t('create-account.errors.password'))
    } else {
      setPasswordError(undefined)
    }

    if (errors) return

    const result = await postWithUserErrorCode(XRealtimeAuthCreateSession, {
      email,
      password,
    })
    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    onLogIn?.()

    await Auth.getInstance().synchronize()
  }

  const hasNoSSO = !hasSamlLogin && !hasSamlLogin

  const createContinueWithPassword = (): React.ReactElement => (
    <AuthenticationForm onSubmit={submit}>
      <View direction='column' gap='xsmall'>
        <FormElement label={''} helper={emailError} isError={emailError !== undefined}>
          <InputPrimitive
            placeholder={t('login.email')}
            type='text'
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            autoComplete='username'
          />
        </FormElement>
        {(method === undefined || method.type !== 'saml') && (
          <FormElement label={''} isError={passwordError !== undefined} helper={passwordError}>
            <PasswordInputPrimitive
              placeholder={t('login.password')}
              value={password}
              autoComplete='current-password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
          </FormElement>
        )}

        {error !== undefined && (
          <>
            <Spacer size='4' />
            <Text size='micro' color='redBright'>
              {error}
            </Text>
          </>
        )}
        {method !== undefined && method.type === 'saml' ? (
          <Button
            variant={hasNoSSO ? 'primary' : 'ghost'}
            onClick={() => handleSamlLogIn(method.provider)}
            grow
          >
            {t('login.action')}
          </Button>
        ) : (
          <Button type='submit' variant={hasNoSSO ? 'primary' : 'ghost'} id={'login-button'} grow>
            {t('login.action')}
          </Button>
        )}
        <View justifyContent='center'>
          <SecondaryButton onClick={switchToForgotPassword}>{t('login.forgot-password')}</SecondaryButton>
        </View>
      </View>
    </AuthenticationForm>
  )

  const methods = [
    switchToLiveGuestAccess !== undefined ? (
      <ContinueAsGuest switchToLiveGuestAccess={switchToLiveGuestAccess} />
    ) : null,
    hasSocialLogin ? <ContinueWithGoogle /> : null,
    hasSamlLogin ? (
      <>
        {orgConfig.auth
          .flatMap<SamlAuthMethod>(v => (v.type === 'saml' ? [v] : []))
          .map(v => (
            <ContinueWithSaml key={v.provider} provider={v.provider} display={v.display} icon={v.icon} />
          ))}
      </>
    ) : null,
    hasPasswordLogin ? createContinueWithPassword() : null,
  ].flatMap<JSX.Element>(v => (v !== null ? [v] : []))

  return (
    <>
      <AuthenticationContent productTourId='product-tour-login-start'>
        {switchToSignUp && hasPasswordLogin && (
          <>
            <Text color='foreground/primary' onClick={switchToSignUp} size='small' bold align='center'>
              {t('login.new-to-sana')} <SecondaryButton>{t('login.create-an-account')}</SecondaryButton>
            </Text>
          </>
        )}
        {intersperse(<Separator />, methods)}
      </AuthenticationContent>
      <PrivacyPolicyDisclaimer />
    </>
  )
}
