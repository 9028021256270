import React, { useEffect, useMemo, useState } from 'react'
import { ApplicationNotifications } from 'sierra-client/components/common/notifications'
import { versionHistoryAccessed } from 'sierra-client/core/logging/authoring/logger'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { VersionHistoryCenterContent } from 'sierra-client/editor/version-history/center-content'
import { VersionHistoryHeader } from 'sierra-client/editor/version-history/header'
import { VersionHistoryLeftSidebar } from 'sierra-client/editor/version-history/left-sidebar'
import { NavigateToDefaultFile } from 'sierra-client/editor/version-history/navigate-to-default-file'
import {
  VersionHistoryRightFoldingColumn,
  VersionHistoryRightSidebar,
} from 'sierra-client/editor/version-history/right-sidebar'
import { copyHistoryFileIntoEditor } from 'sierra-client/editor/version-history/utils/duplicate-file'
import { LoadHistory } from 'sierra-client/editor/version-history/utils/load-history'
import { GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { useCreatePageYdoc } from 'sierra-client/hooks/use-create-page-ydoc'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { accessDeniedRedirect, contentNotFoundRedirect } from 'sierra-client/router/navigation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CreatePageSidebarSkeleton } from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar-skeleton'
import { CreatePageTitle } from 'sierra-client/views/flexible-content/editor/topbar/create-page-title'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { isDefined } from 'sierra-domain/utils'
import { History } from 'sierra-domain/version-history/get-updates-meta'
import { ReplayUpdatesContext, replayUpdates } from 'sierra-domain/version-history/replay-updates'
import { Column, ColumnContainer, Layout } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

function useTargetIndex({
  history,
}: {
  history: History | undefined
}): [
  context: ReplayUpdatesContext | undefined,
  targetIndex: number,
  setTargetIndex: React.Dispatch<React.SetStateAction<number>>,
] {
  const [rawIndex, , setTargetIndex] = useDebouncedAndLiveState(0, {
    wait: 32,
    maxWait: 32,
    leading: true,
  })
  const targetIndex = Math.max(0, rawIndex % (history?.updates.length ?? 0))

  useEffect(() => {
    if (isDefined(history)) setTargetIndex(history.updates.length - 1)
  }, [history, setTargetIndex])

  const context = useMemo(
    () => (isDefined(history) ? replayUpdates(history.updates, targetIndex) : undefined),
    [history, targetIndex]
  )

  return useMemo(() => [context, targetIndex, setTargetIndex], [context, targetIndex, setTargetIndex])
}

const PageColumnContainer = styled(ColumnContainer)`
  overflow: hidden;
`

function EditAccessVersionHistoryPage({
  scopedContentId,
  nodeId,
  assetContext,
}: {
  scopedContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
}): JSX.Element {
  const mode = ScopedCreateContentId.contentType(scopedContentId)
  const contentId = ScopedCreateContentId.extractId(scopedContentId)

  const [history, setHistory] = useState<History | undefined>(undefined)
  const [context, targetIndex, setTargetIndex] = useTargetIndex({ history })
  const ydocContext = useCreatePageYdoc(scopedContentId)

  const userId = useSelector(selectUserId)

  const editHref =
    mode === 'self-paced'
      ? `/create/s/${contentId}/${nodeId ?? ''}`
      : `/create/l/${contentId}/${nodeId ?? ''}`

  const copyHistoryFile = (fileId: FileId): void => {
    if (isDefined(ydocContext) && isDefined(context) && isDefined(userId)) {
      copyHistoryFileIntoEditor({
        editorYdoc: ydocContext.yDoc,
        historyYdoc: context.currentYDoc,
        historyFileId: fileId,
        isSelfpacedContent: mode === 'self-paced',
        userId,
        scopedCreateContentId: scopedContentId,
      })
    }
  }

  return (
    <>
      <ApplicationNotifications />
      {nodeId === undefined && context !== undefined && (
        <NavigateToDefaultFile contentId={contentId} mode={mode} currentYDoc={context.currentYDoc} />
      )}
      <GlobalNestedSidebar
        renderSidebar={() => (
          <>
            <View paddingLeft='8' gap='8' marginBottom='10'>
              <CreatePageTitle createContentId={contentId} editable={false} />
            </View>

            {isDefined(context) && isDefined(history) ? (
              <nav>
                <VersionHistoryLeftSidebar
                  mode={mode}
                  state={context}
                  contentId={contentId}
                  nodeId={'folder:root'}
                  selectedNode={nodeId}
                  history={history}
                  targetIndex={targetIndex}
                  copyHistoryFile={copyHistoryFile}
                />
              </nav>
            ) : (
              <CreatePageSidebarSkeleton />
            )}
          </>
        )}
      />
      <Layout>
        <VersionHistoryHeader
          editHref={editHref}
          contentId={contentId}
          updates={history?.updates}
          targetIndex={targetIndex}
          assetContext={assetContext}
        />
        <PageColumnContainer gap='8' paddingLeft='8' paddingRight='8' paddingBottom='8'>
          <Column disableScrollbarGutter={true}>
            <LoadHistory scopedContentId={scopedContentId} onChange={setHistory} />

            {isDefined(context) && (
              <VersionHistoryCenterContent
                authorIds={history?.updates[targetIndex]?.userIds}
                scopedCreateContentId={scopedContentId}
                nodeId={nodeId}
                context={context}
                assetContext={assetContext}
              />
            )}

            {!isDefined(history) && (
              <Debug>
                <Text>Loading History</Text>
              </Debug>
            )}
            {!isDefined(context) && (
              <Debug>
                <Text>Loading Context</Text>
              </Debug>
            )}
          </Column>

          <VersionHistoryRightFoldingColumn open={true} width={294} disableScrollbarGutter>
            <VersionHistoryRightSidebar
              history={history}
              nodeId={nodeId}
              targetIndex={targetIndex}
              setTargetIndex={setTargetIndex}
            />
          </VersionHistoryRightFoldingColumn>
        </PageColumnContainer>
      </Layout>
    </>
  )
}

export function VersionHistoryPage({
  scopedContentId,
  nodeId,
  assetContext,
}: {
  scopedContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
}): JSX.Element {
  const contentId = ScopedCreateContentId.extractId(scopedContentId)

  const dispatch = useDispatch()
  useEffect(() => {
    const windowLocationHref = new URL(window.location.href)
    const subPath = ScopedCreateContentId.urlSubPathForId(scopedContentId)
    const contentLink = `${windowLocationHref.origin}/history/${subPath}/${contentId}`

    void dispatch(
      versionHistoryAccessed({
        contentId,
        contentType: ScopedCreateContentId.contentType(scopedContentId),
        contentLink,
      })
    )
  }, [contentId, dispatch, scopedContentId])

  return (
    <RequireCoursePermission
      contentId={contentId}
      minPermissionLevel='edit'
      showSpinner
      onAccessDenied={accessDeniedRedirect}
      onContentNotFound={contentNotFoundRedirect}
    >
      <EditAccessVersionHistoryPage
        scopedContentId={scopedContentId}
        nodeId={nodeId}
        assetContext={assetContext}
      />
    </RequireCoursePermission>
  )
}
