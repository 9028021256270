import { useCallback } from 'react'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { hasOnlyEmptyTextInNodes, isCurrentSelectionCollapsed } from 'sierra-domain/slate-util'
import { Editor, Element } from 'slate'
import { useSelected, useSlateSelector } from 'slate-react'

export const useShouldShowPrompt = ({ element }: { element: Element }): boolean => {
  const isElementSelected = useSelected()
  const { disableMenu } = useRenderingContext()
  const isReadOnly = useEditorReadOnly()

  const selectShouldShowPrompt = useCallback(
    (editor: Editor): boolean => {
      const isParagraph = element.type === 'paragraph'

      if (disableMenu || isReadOnly || !isParagraph) {
        return false
      }

      const isBlank = hasOnlyEmptyTextInNodes(element.children)
      const isSelectionCollapsed = isCurrentSelectionCollapsed(editor)

      return isBlank && isElementSelected && isSelectionCollapsed === true
    },
    [disableMenu, element.children, element.type, isElementSelected, isReadOnly]
  )

  return useSlateSelector(selectShouldShowPrompt)
}
