import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import {
  ContentFilter,
  ContentItem,
  contentToCsv,
  filterContent,
  parseCourseToContent,
  parsePathToContent,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { GroupContentItem } from 'sierra-client/views/manage/groups/utils/use-group-assigned-content'
import { usePaths } from 'sierra-client/views/manage/paths/use-paths'
import {
  AssignmentData,
  CourseStatus,
  LiveSessionAssignment,
  UserDetailResponse,
  UserDueDates,
} from 'sierra-domain/api/manage'

export type UserContentRow = ContentItem & {
  progress?: number
  assignmentData?: AssignmentData
  dueDates?: UserDueDates
  liveSessions?: LiveSessionAssignment[]
  subComponentData?: UserContentRow[]
}

export type UserContentFilter = ContentFilter & {
  progress?: CourseStatus
}

type ProgressClassificationData = {
  classification: CourseStatus
  // TODO add color, and other classification data
}

const getProgressClassificationData = ({
  progress,
}: Pick<UserContentRow, 'progress'>): ProgressClassificationData => {
  if (progress === undefined || progress === 0) return { classification: 'not-started' }
  if (progress < 1) return { classification: 'started' }
  return { classification: 'passed' }
}

const filterUserContent = (allContent: UserContentRow[], filter: UserContentFilter): UserContentRow[] => {
  const filteredContent = filterContent(allContent, filter)

  if (filter.progress === undefined) return filteredContent

  return filteredContent.filter(content => {
    return filter.progress === getProgressClassificationData(content).classification
  })
}

export const userContentRowToCsv = (content: UserContentRow | GroupContentItem): Record<string, string> => {
  const progress =
    'progress' in content
      ? content.progress
      : 'completionRate' in content
        ? content.completionRate
        : undefined

  const assignedAt =
    'assignmentData' in content
      ? content.assignmentData?.assignedAt
      : 'assignedAt' in content
        ? content.assignedAt
        : undefined

  const assignmentType = 'assignmentData' in content ? content.assignmentData?.type : undefined

  return {
    ...contentToCsv(content),
    progress: progress?.toFixed(3) ?? '0',
    assignedAt: assignedAt !== undefined ? new Date(assignedAt).toISOString() : '',
    assignmentType: assignmentType ?? '',
  }
}
type UseUserAssignedOrStartedContentInput = {
  courses?: UserDetailResponse['courses']
  coursesSelfStart?: UserDetailResponse['coursesSelfStart']
  paths?: UserDetailResponse['paths']
  liveSessions?: UserDetailResponse['liveSessions']
}

type UseUserAssignedContentOutput = {
  allItems: UserContentRow[]
  filteredItems: UserContentRow[]
  isLoading: boolean
  filter: UserContentFilter
  setFilter: Dispatch<SetStateAction<UserContentFilter>>
}

export const useUserAssignedContent = ({
  courses,
  coursesSelfStart,
  paths,
  liveSessions,
}: UseUserAssignedOrStartedContentInput): UseUserAssignedContentOutput => {
  const { paths: allPaths, isLoading: isPathsLoading } = usePaths()
  const courseIds = useMemo(
    () =>
      [...(courses ?? []), ...(coursesSelfStart ?? []), ...allPaths.flatMap(p => p.courses)].map(
        c => c.courseId
      ),
    [allPaths, courses, coursesSelfStart]
  )
  const { courses: allCourses, isLoading: isCoursesLoading } = useManageCourses(courseIds)
  const isLoading = isCoursesLoading || isPathsLoading
  const [filter, setFilter] = useState<UseUserAssignedContentOutput['filter']>({
    contentClassification: undefined,
    tagIds: [],
  })

  // Merges paths and courses, add subrows in paths
  const allItems: UserContentRow[] = useMemo(() => {
    if (isLoading) return []
    const courseById = _.keyBy(allCourses, c => c.courseId)
    const pathById = _.keyBy(allPaths, p => p.pathId)

    const mappedCourses: UserContentRow[] =
      courses !== undefined
        ? courses.flatMap(c => {
            const course = courseById[c.courseId]
            if (course === undefined) return []
            const mapped: UserContentRow = {
              ...parseCourseToContent(course),
              assignmentData: c.data,
              progress: c.progress,
              dueDates: c.dueDates,
              liveSessions:
                course.kind === 'native:live'
                  ? liveSessions === undefined
                    ? []
                    : liveSessions.filter(ls => ls.courseId === c.courseId)
                  : undefined,
            }
            return [mapped]
          })
        : coursesSelfStart !== undefined
          ? coursesSelfStart.flatMap(c => {
              const course = courseById[c.courseId]
              if (course === undefined) return []
              const mapped: UserContentRow = {
                ...parseCourseToContent(course),
                progress: c.progress,
              }
              return [mapped]
            })
          : []
    const mappedPaths: UserContentRow[] =
      paths === undefined
        ? []
        : paths.flatMap(p => {
            const path = pathById[p.pathId]
            if (path === undefined) return []

            const mapped: UserContentRow = {
              ...parsePathToContent(path),
              assignmentData: p.data,
              progress: p.progress,
              dueDates: p.dueDates,
              subComponentData: path.courses.flatMap(c => {
                const subRowCourse = courseById[c.courseId]
                if (subRowCourse === undefined) return []
                // TODO add due date to course in path?
                return [
                  {
                    ...parseCourseToContent(subRowCourse),
                    progress: p.contentProgress[c.courseId],
                    liveSessions:
                      subRowCourse.kind === 'native:live'
                        ? (liveSessions ?? []).filter(ls => ls.courseId === c.courseId)
                        : undefined,
                  },
                ]
              }),
            }
            return [mapped]
          })
    return [...mappedCourses, ...mappedPaths].sort(
      (a, b) =>
        new Date(b.assignmentData?.assignedAt ?? 0).getTime() -
        new Date(a.assignmentData?.assignedAt ?? 0).getTime()
    )
  }, [allCourses, allPaths, courses, coursesSelfStart, isLoading, liveSessions, paths])

  const filteredItems = useMemo(() => {
    const filteredUserContent = filterUserContent(allItems, filter)
    return filteredUserContent
  }, [allItems, filter])

  return {
    allItems,
    filteredItems,
    filter,
    setFilter,
    isLoading,
  }
}
