import { Atom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { BaseColumn } from 'sierra-client/lib/tabular/datatype/column'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { CellWithData } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { ColumnRef } from 'sierra-client/lib/tabular/types'
import { isSelected } from 'sierra-client/lib/tabular/utils/atom-helpers'

export type TableRowData<Data extends TableData = TableData> = Array<Row.Row<Data>>

export const columnToCell: Record<BaseColumn['type'], CellWithData['type']> = {
  booleans: 'boolean',
  numbers: 'number',
  strings: 'string',
  dateTimes: 'datetime',
  duration: 'duration',
  users: 'user',
  groups: 'group',
  enrolledBys: 'enrolledBy',
  content: 'content',
  skillContent: 'skillContent',
  links: 'link',
  certificates: 'certificate',
  certificateIssuedBy: 'certificateIssuedBy',
  issuedCertificates: 'issuedCertificate',
  expiryTimes: 'expiryTime',
  issuesRevoked: 'issuedRevoked',
  addStepContent: 'addStepContent',
  programVersionNumbers: 'programVersionNumber',
  homeworks: 'homework',
  userStacks: 'userStack',
  eventGroups: 'eventGroup',
  titlesAndSubtitles: 'titleAndSubtitle',
  singleSelect: 'singleSelect',
  card: 'card',
  canvas: 'canvas',
  pills: 'pill',
}

export const toTableRowData = <TD extends TableData>(
  tableData: TD,
  hiddenAtom: Atom<Set<ColumnRef>>,
  selectionAtom: Atom<Selection>,
  expandedAtom: Atom<Set<string>>
): TableRowData => {
  return tableData.rows.map(({ id: rowId, data }) => {
    const selected = selectAtom(selectionAtom, sel => isSelected(rowId, sel))

    const nested = tableData.nested[rowId]

    const cells = tableData.columns.map(col => {
      const enabled = selectAtom(hiddenAtom, hidden => !hidden.has(col.ref))

      const colData = data[col.ref]?.data

      // If we dont have that specific ref in data with data. Create an empty cell
      if (colData === undefined) {
        return {
          type: 'empty',
          pos: { column: col.ref, row: rowId },
          hints: col.hints,
          enabled: enabled,
          selected: selected,
        } satisfies Cell
      }

      return {
        type: columnToCell[col.type],
        // This type cast should be safe and match the type property because we have this
        // strictly checked in the input of the TableData.
        data: colData as any,
        pos: { column: col.ref, row: rowId },
        hints: col.hints,
        enabled: enabled,
        selected: selected,
      } as Cell // need to do 'as' here to correctly satisfy the Cell type
    })

    if (nested) {
      const expanded = selectAtom(expandedAtom, expanded => expanded.has(rowId))
      return Row.createRow({
        type: 'Row.Nested',
        ref: rowId,
        expanded,
        parent: cells,
        children: toTableRowData(nested, hiddenAtom, selectionAtom, expandedAtom),
      })
    } else {
      return Row.createRow({ type: 'Row.Flat', ref: rowId, cells })
    }
  })
}
