import _ from 'lodash'
import { ReactNode, useMemo } from 'react'
import { SkillId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  SkillSubscriberAttribute,
  SkillSubscriberSortAttributeInput,
  SubscribersQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { EmptyTableWrapper, TableHeight } from 'sierra-client/features/skills/components/tabular/styles'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { numbersColumn, stringsColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { DataLoaderStateMachine, createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { PaginatedTabular } from 'sierra-client/lib/tabular/provider/components/paginated'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined, isNonEmptyArray, isNullable, lowercase } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Button, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { skillSettingsQuery } from '../../shared-gql-queries'
import { SkillSettingAction } from '../skill-settings/skill-settings-panel'

export const subscribersQuery = graphql(`
  query Subscribers(
    $id: SkillId!
    $query: String
    $limit: Int
    $cursor: String
    $sortBy: [SkillSubscriberSortAttributeInput!]
  ) {
    skillSubscribers(id: $id, limit: $limit, cursor: $cursor, query: $query, sortBy: $sortBy) {
      data {
        userInfo {
          id
          firstName
          lastName
          email
          status
          avatar {
            ...AvatarFragment
          }
        }
        coursesPassed
        coursesStarted
        targetLevel {
          id
          name
        }
        achievedLevels {
          achievedLevel {
            id
            name
          }
        }
      }
      cursor
      totalCount
    }
  }
`)

type SubscribersData = SubscribersQuery['skillSubscribers']['data'][number]
type SubscribersTableDefinition = TableDefinitionOf<
  SubscribersData,
  [
    { type: 'users'; ref: Extract<SkillSubscriberAttribute, 'NAME'> },
    { type: 'strings'; ref: 'levelsAchieved' },
    { type: 'strings'; ref: 'levelTarget' },
    { type: 'numbers'; ref: 'coursesConsumed' },
  ]
>

export type SubscribersTableData = TableDataFromDefinition<SubscribersData, SubscribersTableDefinition>

const subscribersTableDefinition = (): SubscribersTableDefinition => ({
  nested: {},
  rows: {
    getId: s => s.userInfo.id,
  },
  columns: [
    usersColumn({
      ref: 'NAME',
      header: translatedLabel('dictionary.user-plural'),
      sortable: true,
      getData: s => {
        const avatar = convertGQLAvatar(s.userInfo.avatar)
        return {
          id: s.userInfo.id,
          email: s.userInfo.email,
          active: s.userInfo.status === 'ACTIVE',
          firstName: s.userInfo.firstName ?? '-',
          lastName: s.userInfo.lastName ?? '-',
          status: lowercase(s.userInfo.status) satisfies UserRep['status'],
          avatar: getAvatarUrl(s.userInfo.id, avatar),
          avatarColor: getAvatarColor(avatar),
        }
      },
    }),
    stringsColumn({
      ref: 'levelsAchieved',
      header: translatedLabel('skills.users.achieved-levels'),
      sortable: false,
      getData: s =>
        s.achievedLevels
          .map(l => l.achievedLevel.name)
          .map(_.capitalize)
          .join(', '),
    }),
    stringsColumn({
      ref: 'levelTarget',
      header: translatedLabel('skills.users.target-level'),
      sortable: false,
      getData: s => _.capitalize(s.targetLevel?.name ?? '-'),
    }),
    numbersColumn({
      ref: 'coursesConsumed',
      header: translatedLabel('skills.users.courses-consumed'),
      sortable: false,
      getData: s => s.coursesPassed,
    }),
  ],
})

export type SkillUsersTabularActions = {
  onUnsubscribeUser: (_: { userId: UserId; skillLevelSettingId?: SkillLevelSettingId }) => void
  onSubscribeUsers: () => void
  onSetTargetLevel: (_: {
    skillLevelSettingId: SkillLevelSettingId
    userId: UserId
    skillId: SkillId
  }) => void
  onSetLevelsAchieved: (_: {
    skillLevelSettingId: SkillLevelSettingId
    userId: UserId
    skillId: SkillId
  }) => void
  onSendReminder: () => void
}

const getSkillLevelsQuery = graphql(`
  query GetSkillLevels($id: SkillId!) {
    skill(id: $id) {
      skillLevels {
        levelSetting {
          id
          name
        }
      }
    }
  }
`)

const useVirtualColumns = (
  actions: SkillUsersTabularActions,
  skillId: SkillId
): VirtualColumns<SubscribersTableData> => {
  const query = useGraphQuery({ document: getSkillLevelsQuery }, { id: skillId })
  const { t } = useTranslation()

  const levels = useMemo(() => {
    return (
      query.data?.skill?.skillLevels
        .map(s => s.levelSetting)
        .map(l => ({ skillLevelId: l.id, label: l.name })) ?? []
    )
  }, [query.data?.skill?.skillLevels])

  return useMemo(
    () => ({
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          options: { columnToggle: false },
          getProps: (api, pos) => ({
            menuItems: [
              {
                id: pos.row + '-nested',
                type: 'nested',
                menuItems: levels.map(level => ({
                  id: pos.row + '-label',
                  type: 'label',
                  onClick: () => {
                    const maybeUser = getRowDataFromTableAPI(api, pos.row)?.NAME.data
                    if (maybeUser) {
                      actions.onSetTargetLevel({
                        userId: maybeUser.id,
                        skillLevelSettingId: level.skillLevelId,
                        skillId: skillId,
                      })
                    }
                  },
                  selected: getRowDataFromTableAPI(api, pos.row)?.levelTarget.data === level.label,
                  label: level.label,
                })),
                label: _.capitalize(t('skills.table.target-level.text')),
                icon: 'trophy',
              },

              {
                id: pos.row + '-label',
                type: 'label',
                onClick: () => {
                  const maybeUser = getRowDataFromTableAPI(api, pos.row)?.NAME.data
                  if (maybeUser) {
                    actions.onUnsubscribeUser({ userId: maybeUser.id })
                  }
                },
                color: 'destructive/background',
                label: _.capitalize(t('skills.table.unsubscribe.text')),
                icon: 'trash-can',
              },
            ] satisfies Array<MenuItem>,
          }),
        }),
      ],
    }),
    [actions, levels, skillId, t]
  )
}

export const skillsDataLoader = (
  skillId: SkillId
): DataLoaderStateMachine<SubscribersTableData, { cursor: string | undefined | null }> => {
  return createDataLoader({
    async fetchInit({ modifier, control, predicate }) {
      const sortBy = modifier.sorting?.map(
        sort =>
          ({
            // todo: fix cast
            key: sort.column as SkillSubscriberAttribute,
            order: toGQLSortOrder(sort.direction),
          }) satisfies SkillSubscriberSortAttributeInput
      )

      const r = await graphQuery(subscribersQuery, {
        id: skillId,
        limit: control.limit,
        query: predicate.query,
        sortBy: isDefined(sortBy) && isNonEmptyArray(sortBy) ? sortBy : undefined,
      })

      return {
        meta: { cursor: r.skillSubscribers.cursor },
        data: r.skillSubscribers.data,
        totalCount: r.skillSubscribers.totalCount,
        done: isNullable(r.skillSubscribers.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const r = await graphQuery(subscribersQuery, {
        id: skillId,
        limit: control.limit,
        cursor: meta.cursor,
      })

      return {
        meta: { cursor: r.skillSubscribers.cursor },
        data: r.skillSubscribers.data,
        totalCount: r.skillSubscribers.totalCount,
        done: isNullable(r.skillSubscribers.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(subscribersTableDefinition(), data)]
    },
  })
}

export const QUERY_KEY = ['graphql', 'skill-subscribers-table']
export const useSubscribersTableAPI = (
  skillId: SkillId,
  actions: SkillUsersTabularActions
): UseTableAPI<SubscribersTableData, any> => {
  const virtualColumns = useVirtualColumns(actions, skillId)
  return useTableAPI({
    dataLoader: useMemo(
      () => ({ loader: skillsDataLoader(skillId), options: { queryKey: QUERY_KEY } }),
      [skillId]
    ),
    virtualColumns: virtualColumns,
    options: {
      limit: 10,
    },
  })
}

const EmptyTable: React.FC<{ onAddSubscriber: () => void }> = ({ onAddSubscriber }) => {
  const { t } = useTranslation()

  return (
    <EmptyTableWrapper>
      <Icon iconId='education' color='foreground/primary' />
      <Text size='small' bold>
        {t('manage.skills.enroll-learners.title')}
      </Text>
      <Text align='center' color='foreground/muted'>
        {t('manage.skills.enroll-learners.description')}
      </Text>
      <Spacer size='2' />
      <Button onClick={onAddSubscriber} variant='secondary'>
        {t('manage.manage-learners')}
      </Button>
    </EmptyTableWrapper>
  )
}

const NonVisibleSkills: React.FC = () => {
  const { t } = useTranslation()

  return (
    <EmptyTableWrapper>
      <Icon iconId='view--off' color='foreground/primary' />
      <Text size='small' bold>
        {t('skills.table.not-visible-skills.text')}
      </Text>
      <Text align='center' color='foreground/muted'>
        {t('skills.table.not-visible-skills.description')}
      </Text>
      <Spacer size='2' />
      <SkillSettingAction buttonText={t('skills.table.not-visible-skills.button.text')} />
    </EmptyTableWrapper>
  )
}

export const SkillSubscribersTabular: React.FC<{
  actions: SkillUsersTabularActions
}> = ({ actions }) => {
  const { t } = useTranslation()
  const { pages, api, dataloaderState } = useTabularContext()
  const hideSkills = useGraphQuery({ document: skillSettingsQuery }).data?.skillSettings.hideSkills ?? false

  const nRows = pages.flat().length

  const SubscribeUsersToolbarButton: ReactNode = (
    <Button variant='transparent' onClick={actions.onSubscribeUsers}>
      {t('dictionary.add')}
    </Button>
  )

  return (
    <TableHeight>
      <TabularToolbar
        countsTranslationKeys={{
          totalKey: 'manage.program-members.n-users',
          filterKey: 'manage.program-members.n-filtered',
          selectedKey: 'manage.tables.n-selected',
        }}
        api={api}
        clearFilters={false}
        actions={SubscribeUsersToolbarButton}
      />
      {hideSkills ? (
        <NonVisibleSkills />
      ) : dataloaderState === 'init' ? (
        <View grow justifyContent='center' alignItems='center'>
          <LoadingSpinner />
        </View>
      ) : nRows === 0 ? (
        <EmptyTable onAddSubscriber={() => actions.onSubscribeUsers()} />
      ) : (
        <PaginatedTabular />
      )}
    </TableHeight>
  )
}
