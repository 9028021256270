import { FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import { EmojiDetector } from 'sierra-client/editor/emoji-detector'
import { useSyntaxHighlighting } from 'sierra-client/editor/syntax-highlighting'
import { useTextColors } from 'sierra-client/editor/text-color'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { FCC } from 'sierra-client/types'
import { useAutoFocus } from 'sierra-client/views/flexible-content/editor-focus-context'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { CursorOverlay } from 'sierra-client/views/v3-author/cursors/cursor-overlay'
import { EditorContent } from 'sierra-client/views/v3-author/inner-editor'
import { MeasureEditorScrollContainer } from 'sierra-client/views/v3-author/measure-editor-scroll-container'
import { rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import { Editor, Node, NodeEntry, Range, Transforms } from 'slate'
import { Editable, useSlateStatic } from 'slate-react'
import { EditableProps } from 'slate-react/dist/components/editable'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

export type CursorEditableProps = Omit<EditableProps & { cursorsEnabled: boolean }, 'readOnly' | 'decorate'>

const selectFirstNode = (editor: Editor): boolean => {
  const [firstNodeEntry] = Array.from(Node.children(editor, []))
  if (firstNodeEntry !== undefined) {
    const [firstTextNode] = rangeOfNode(firstNodeEntry)
    Transforms.select(editor, firstTextNode)
    return true
  }

  return false
}

const useSelectFirstNode = (execute: boolean): void => {
  const editor = useSlateStatic()
  const readOnly = useEditorReadOnly()

  useEffect(() => {
    if (!execute) return
    if (readOnly) return

    let reqId: number | undefined = undefined
    let counter = 0

    const loop = (): void => {
      counter += 1
      reqId = requestAnimationFrame(() => {
        const success = selectFirstNode(editor)
        if (!success && counter < 100) {
          loop()
        }
      })
    }
    loop()

    return () => {
      reqId !== undefined && cancelAnimationFrame(reqId)
    }
  }, [editor, readOnly, execute])
}

/**
 * A wrapper for slate's <Editable> component which supports collaborative text cursors.
 */
export const CursorEditable: FCC<CursorEditableProps> = ({ children, cursorsEnabled, ...props }) => {
  const readOnly = useEditorReadOnly()
  const decorateSyntaxHighlighting = useSyntaxHighlighting()
  const decorateTextColors = useTextColors()
  const decorate = useCallback(
    (entry: NodeEntry): Range[] => {
      return [...decorateSyntaxHighlighting(entry), ...decorateTextColors(entry)]
    },
    [decorateSyntaxHighlighting, decorateTextColors]
  )
  const shouldAutoFocus = useAutoFocus()
  useSelectFirstNode(shouldAutoFocus)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  containerRef.current = container

  const editor = useSlateStatic()
  const onFocus = useStableFunction((event: FocusEvent<HTMLDivElement, Element>) => {
    editor.pushActionsLogEntry('focus')
    props.onFocus?.(event)
  })

  const onBlur = useStableFunction((event: FocusEvent<HTMLDivElement, Element>) => {
    editor.pushActionsLogEntry('blur')
    props.onBlur?.(event)
  })

  const onMouseDown = useStableFunction((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    editor.pushActionsLogEntry('click')
    props.onMouseDown?.(event)
  })

  return (
    <>
      {/**
       * CursorEditable is the outermost element that wraps content in both the learner and editor views.
       * Currently, its parent is the scroll container for the content. We need its height in at least one case.
       * Until we refactor that need away, we'll measure it here.
       */}
      <MeasureEditorScrollContainer editorScrollContainer={container?.parentElement ?? null} />
      <Container ref={setContainer}>
        <ConditionalWrapper
          condition={cursorsEnabled}
          renderWrapper={children => <CursorOverlay containerRef={containerRef}>{children}</CursorOverlay>}
        >
          <>
            {!readOnly && <EmojiDetector />}

            <EditorContent>
              <Editable
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseDown={onMouseDown}
                readOnly={readOnly}
                {...props}
                decorate={decorate}
                autoFocus={shouldAutoFocus}
              >
                {children}
              </Editable>
            </EditorContent>
          </>
        </ConditionalWrapper>
      </Container>
    </>
  )
}
