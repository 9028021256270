import { atom } from 'jotai'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  AddStepQuery,
  AddStepQueryVariables,
  StepContentByIdsQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { addStepContentsColumn } from 'sierra-client/lib/tabular/column-definitions'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { selectedAtom } from 'sierra-client/lib/tabular/utils/atom-helpers'
import { VirtualColumn } from 'sierra-client/lib/tabular/virtual-columns'
import {
  getStepIdGraphQl,
  getStepTypeGraphQlTranslation,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, isNullable } from 'sierra-domain/utils'

type AddStepsData = AddStepQuery['content']['data'][number]
type AddStepTableDefinition = TableDefinitionOf<AddStepsData, [{ type: 'addStepContent'; ref: 'name' }]>
export type AddStepTableData = TableDataFromDefinition<AddStepsData, AddStepTableDefinition>

const getAssetContextFromStep = (step: AddStepsData): AssetContext => {
  switch (step.__typename) {
    case 'LinkCourse':
    case 'LinkedInCourse':
    case 'NativeCourseGroup':
    case 'NativeEventGroup':
    case 'NativeLiveCourse':
    case 'NativeSelfPacedCourse':
    case 'ScormCourse':
    case 'ScormCourseGroup':
      return { type: 'course', courseId: step.courseId }

    case 'Path':
      return { type: 'path', pathId: step.pathId }

    case 'Program':
      return { type: 'unknown' } // This can never happen, a program can not be a step of another program

    default:
      assertNever(step)
  }
}

const addStepTableDefinition = (t: TranslationLookup): AddStepTableDefinition => ({
  nested: {},
  rows: { getId: c => getStepIdGraphQl(c) },
  columns: [
    addStepContentsColumn({
      ref: 'name',
      header: { type: 'empty' },
      hints: ['bold'],
      sortable: false,
      getData: c => {
        const content = c
        const editions =
          content.__typename === 'NativeCourseGroup'
            ? content.courseEditions.map(edition => ({
                id: edition.edition.courseId,
                title: edition.edition.title,
                image: convertGQLImage(edition.edition.image),
              }))
            : undefined

        const assetContext = getAssetContextFromStep(content)

        return {
          title: content.title,
          image: convertGQLImage(content.image),
          assetContext,
          subline: getStepTypeGraphQlTranslation(content, t),
          editions: editions,
        }
      },
    }),
  ],
})

const addStepQuery = graphql(`
  query AddStep($query: String, $limit: Int, $cursor: String) {
    content(query: $query, limit: $limit, cursor: $cursor, contentTypes: [COURSE, PATH]) {
      cursor
      totalCount
      data {
        ...StepContentFragment
      }
    }
  }
`)

const stepContentByIdsQuery = graphql(`
  query StepContentByIds($ids: [InputContentId!]!) {
    content: contentWithIds(ids: $ids) {
      ...StepContentFragment
    }
  }
`)

export const fetchAddStepContent = async (params: AddStepQueryVariables): Promise<AddStepQuery> =>
  graphQuery(addStepQuery, params)

export const fetchStepContentByIds = async (ids: string[]): Promise<StepContentByIdsQuery> =>
  graphQuery(stepContentByIdsQuery, { ids: ids })

export type AddStepTableMeta = { cursor: string | null | undefined }

export const addStepDataLoader = (
  t: TranslationLookup
): DL.DataLoaderStateMachine<AddStepTableData, AddStepTableMeta> =>
  DL.createDataLoader({
    async fetchInit({ control, predicate }) {
      const res = await fetchAddStepContent({ limit: control.limit, query: predicate.query })

      return {
        meta: { cursor: res.content.cursor },
        data: res.content.data,
        totalCount: res.content.totalCount,
        done: isNullable(res.content.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const res = await fetchAddStepContent({
        cursor: meta.cursor,
        limit: control.limit,
      })

      return {
        meta: { cursor: res.content.cursor },
        data: res.content.data,
        totalCount: res.content.totalCount,
        done: isNullable(res.content.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(addStepTableDefinition(t), data)]
    },
  })

export const selectionButtonVirtualColumns = {
  left: [],
  right: [
    {
      ref: 'add-step',
      header: () =>
        ({
          type: 'empty',
          ref: 'empty-action',
          hints: [],
          enabled: atom(() => false),
          sortable: atom(() => false),
        }) satisfies Header,
      cell: (pos, api) => {
        return {
          type: 'addButton',
          pos,
          hints: ['sticky-right'],
          enabled: atom(() => true),
          selected: selectedAtom(pos, api.atoms.selection),
        } satisfies Cell
      },
    } satisfies VirtualColumn<AddStepTableData>,
  ],
}
