import { DOMOutputSpec } from '@tiptap/pm/model'
import { Editor } from '@tiptap/react'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import {
  ContentAutoCompleteQuery,
  EverythingSearchAutoCompleteQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar, getAvatarUrl } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import {
  InsightContentTypes,
  InsightsContentSuggestion,
  InsightsQueryRef,
  InsightsRefType,
} from 'sierra-client/views/insights/insights-content-search/types'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { DefaultTheme } from 'styled-components'

export const serializeInsightContentSuggestions = (editor: Editor | null): string =>
  editor?.getText({
    textSerializers: {
      mention: ({ node }) => node.attrs.id,
    },
  }) ?? ''

export function getQueryRefId(props: InsightsQueryRef): string {
  // Note: content is already prefixed on the backend
  switch (props.refType) {
    case 'content':
      return props.contentId
    case 'user':
      return `user:${props.userId}`
    case 'group':
      return `group:${props.groupId}`
  }
}

const getContentType = (
  content: ContentAutoCompleteQuery['content']['data'][0]['__typename']
): InsightContentTypes => {
  switch (content) {
    case 'Program':
      return 'program'
    case 'Path':
      return 'path'
    default:
      return 'course'
  }
}

export const contentToItems = (
  content: EverythingSearchAutoCompleteQuery['content'] | undefined
): InsightsContentSuggestion[] => {
  if (content === undefined) return []

  return content.data.map(item => ({
    ref: {
      type: 'structured-query-ref',
      contentId: item.contentId,
      refType: 'content',
      contentType: getContentType(item.__typename),
      title: item.title,
      image: convertGQLImage(item.image),
    },
  }))
}

export const usersToItems = (
  user: EverythingSearchAutoCompleteQuery['users'] | undefined
): InsightsContentSuggestion[] => {
  if (user === undefined) return []
  return user.data.map(item => ({
    ref: {
      type: 'structured-query-ref',
      userId: item.id,
      refType: 'user',
      title: item.displayName,
      avatar: convertGQLAvatar(item.avatar),
      email: item.email,
    },
  }))
}

export const groupToItems = (
  group: EverythingSearchAutoCompleteQuery['groups'] | undefined
): InsightsContentSuggestion[] => {
  if (group === undefined) return []
  return group.data.map(item => ({
    ref: {
      type: 'structured-query-ref',
      groupId: item.id,
      refType: 'group',
      title: item.name,
      avatar: convertGQLAvatar(item.avatar),
    },
  }))
}

export const refTypeToTranslation = (key: InsightsRefType): TranslationKey => {
  switch (key) {
    case 'content':
      return 'dictionary.content-singular'
    case 'user':
      return 'dictionary.user-plural'
    case 'group':
      return 'dictionary.group-plural'
  }
}

export const contentTypeToTranslation = (key: InsightContentTypes): TranslationKey => {
  switch (key) {
    case 'program':
      return 'dictionary.program-singular'
    case 'course':
      return 'dictionary.course-singular'
    case 'path':
      return 'dictionary.path-singular'
  }
}

/* Styled in TaggedContentEditor */
export function renderTaggedContentPill(node: ProseMirrorNode, theme: DefaultTheme): DOMOutputSpec {
  switch (node.attrs.tagType) {
    case 'user': {
      const userId = node.attrs.id.replace('user:', '')
      const avatarUrl = getAvatarUrl(userId, node.attrs.avatar)
      if (avatarUrl === undefined) {
        const avatarColor = resolveTokenOrColor(node.attrs.avatar.color, theme)
        const label = node.attrs.label
        const labelParts = label.split(' ')

        const firstInitial =
          labelParts.length > 0 && labelParts[0].length > 0 ? labelParts[0][0].toUpperCase() : ''
        const secondInitial =
          labelParts.length > 1 && labelParts[1].length > 0 ? labelParts[1][0].toUpperCase() : ''

        const initials = firstInitial + secondInitial
        return [
          'span',
          {
            class: 'tag-type-user',
          },
          [
            'div',
            {
              style: `background-color: ${avatarColor};`,
            },
            ['span', {}, `${initials}`],
          ],
          ['span', {}, `${label}`],
        ]
      }
      return [
        'span',
        {
          class: 'tag-type-user',
        },
        [
          'img',
          {
            src: avatarUrl,
            alt: node.attrs.label,
          },
        ],
        ['span', {}, `${node.attrs.label}`],
      ]
    }
    case 'group': {
      const avatarColor = resolveTokenOrColor(node.attrs.avatar.color, theme)
      const label = node.attrs.label
      const initials = label.length > 0 && label[0].length > 0 ? label[0][0].toUpperCase() : ''

      return [
        'span',
        {
          class: 'tag-type-user',
        },
        [
          'div',
          {
            style: `background-color: ${avatarColor};`,
          },
          ['span', {}, `${initials}`],
        ],
        ['span', {}, `${label}`],
      ]
    }
    case 'content':
      if (node.attrs.imageUrl === undefined) {
        return [
          'span',
          {
            class: 'tag-type-content',
          },
          ['div', {}],
          ['span', {}, `${node.attrs.label}`],
        ]
      }
      return [
        'span',
        {
          class: 'tag-type-content',
        },
        [
          'img',
          {
            src: node.attrs.imageUrl,
            alt: node.attrs.label,
          },
        ],
        ['span', {}, `${node.attrs.label}`],
      ]
    default:
      return [
        'span',
        {
          class: 'tag-type-default',
        },
        ['span', {}, `${node.attrs.label}`],
      ]
  }
}
