import { useMemo } from 'react'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isCopyPasteSupported } from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import {
  useMultiSelectionCopy,
  useMultiSelectionCreateModule,
  useMultiSelectionDelete,
  useMultiSelectionDuplicate,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/actions'
import { useAbortMultiSelectionClear } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-abort-multi-selection-clear'
import { useIsEnabledMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-is-enabled-multi-selection'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { MenuItem } from 'sierra-ui/components'

export const useMultiSelectionMenuItems = ({
  closeMenu,
}: {
  closeMenu: () => void
}): MenuItem[] | undefined => {
  const { t } = useTranslation()
  const { areMultipleSelected, selectionCount } = useMultiSelection()
  const isEnabledMultiSelection = useIsEnabledMultiSelection()
  const { deleteMultiSelection } = useMultiSelectionDelete()
  const { duplicateMultiSelection } = useMultiSelectionDuplicate()
  const { createModuleMultiSelection } = useMultiSelectionCreateModule()
  const { multiSelectionCopy, multiSelectionCopyLoading } = useMultiSelectionCopy()
  const { isEnabled: copyCardRecentlyFinished, setTrue: setCopyCardRecentlyFinished } =
    useResetBooleanAfterDelay()

  const { setAbortingMultiSelectionClear } = useAbortMultiSelectionClear()

  const isCopyPasteEnabled = isCopyPasteSupported()

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        id: 'duplicate',
        type: 'label',
        icon: 'duplicate',
        hidden: !isEnabledMultiSelection,
        label: t('dictionary.duplicate') + ` (${selectionCount})`,
        onClick: () => {
          setAbortingMultiSelectionClear(true)
          duplicateMultiSelection()
          setAbortingMultiSelectionClear(false)
          closeMenu()
        },
      },
      {
        id: 'create-module',
        type: 'label',
        icon: 'folder',
        hidden: !isEnabledMultiSelection,
        label: t('create.home.add-module') + ` (${selectionCount})`,
        onClick: () => {
          setAbortingMultiSelectionClear(true)
          createModuleMultiSelection()
          setAbortingMultiSelectionClear(false)
          closeMenu()
        },
      },
      {
        id: 'copy',
        type: 'label',
        icon: copyCardRecentlyFinished ? 'checkmark' : 'copy--file',
        hidden: !isCopyPasteEnabled,
        loading: multiSelectionCopyLoading,
        label: t('admin.copy') + ` (${selectionCount})`,
        onClick: () => {
          multiSelectionCopy()
          setCopyCardRecentlyFinished()
        },
      },
      { type: 'separator', id: 'separator' },
      {
        id: 'delete',
        type: 'label',
        icon: 'trash-can',
        color: 'destructive/background',
        hidden: !isEnabledMultiSelection,
        label: t('dictionary.delete') + ` (${selectionCount})`,
        onClick: () => {
          setAbortingMultiSelectionClear(true)
          deleteMultiSelection()
          setAbortingMultiSelectionClear(false)
          closeMenu()
        },
      },
    ],
    [
      isEnabledMultiSelection,
      t,
      selectionCount,
      copyCardRecentlyFinished,
      isCopyPasteEnabled,
      multiSelectionCopyLoading,
      setAbortingMultiSelectionClear,
      duplicateMultiSelection,
      closeMenu,
      createModuleMultiSelection,
      deleteMultiSelection,
      multiSelectionCopy,
      setCopyCardRecentlyFinished,
    ]
  )

  if (!isEnabledMultiSelection || menuItems.length === 0 || !areMultipleSelected) {
    return undefined
  }

  return menuItems
}
