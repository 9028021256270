import { createFileRoute } from '@tanstack/react-router'

import React, { useEffect } from 'react'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { Button } from 'sierra-ui/primitives'

const AuthenticatePage: React.FC = () => {
  const { t } = useTranslation()

  const user = useSelector(selectUser)

  useEffect(() => {
    if (user !== undefined) {
      if (window.opener !== undefined) {
        window.opener.postMessage({ name: 'authenticate' })
      }
      window.close()
    }
  }, [user])

  const handleClose = (): void => {
    if (window.opener !== undefined) {
      window.opener.postMessage({ name: 'authenticate' })
    }
    window.close()
  }

  return (
    <SanaPage mode='dark' headerType='auth' page={PageIdentifier.Authenticate()}>
      <AuthenticationLayout>
        <AuthenticationContainer>
          <AuthenticationContent>
            <Button variant='primary' onClick={handleClose} grow>
              {t('dictionary.close')}
            </Button>
          </AuthenticationContent>
        </AuthenticationContainer>
      </AuthenticationLayout>
    </SanaPage>
  )
}

const RouteComponent = requireLoggedIn(AuthenticatePage)

export const Route = createFileRoute('/authenticate')({
  component: RouteComponent as React.FC,
})
