import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { playVideoAndIgnoreAbort } from 'sierra-client/utils/video.utils'
import { useEditorFocusContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { CreateCardConfig } from 'sierra-client/views/flexible-content/editor/content-sidebar/use-template-cards'
import { embedFileNames, fileNames } from 'sierra-client/views/flexible-content/file-names'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const RootView = styled(View)`
  cursor: pointer;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  left: 0.5rem;
  padding: 0.25rem;
  background-color: ${token('surface/default')};
  border-radius: 6px;
  border: 1px solid ${token('border/default')};
`

const IconAndNameWrapper = styled(View)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledVideo = styled.video`
  aspect-ratio: 16 / 11;
  width: 160px;
  border-radius: 6px;
`
const CoverImgWrapper = styled.div`
  overflow: hidden;
  aspect-ratio: 16 / 11;
  width: 160px;
  border-radius: 6px;
`
const CoverImg = styled.img`
  height: 100%;
  width: auto;
  transition: transform 0.05s ease-in;

  &:hover {
    transform: scale(1.1);
  }
`

type CardMiniatureProps = {
  card: CreateCardConfig
  onClick?: () => void
}

// Safari flickers when poster prop is used.
const isNotSafari = (): boolean => !navigator.userAgent.match(/safari/i)

export const getCardTypeName = (card: CreateCardConfig, t: TranslationLookup): string => {
  if (card.fileType === 'embed' && card.embedType !== undefined) {
    return embedFileNames[card.embedType]
  }
  return t(card.fileType === 'generate' ? 'create.home.generate-from-doc' : fileNames[card.fileType])
}

export const CardMiniature: React.FC<CardMiniatureProps> = ({ card, onClick }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [loading, setLoading] = useState(false)
  const { setShouldAutoFocus } = useEditorFocusContext()
  const { t } = useTranslation()
  const onCardClick = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      setShouldAutoFocus(true)
      await card.onClick()
      onClick?.()
      setLoading(false)
    }
  }, [loading, card, onClick, setShouldAutoFocus])

  const handleMouseEnter = (): void => {
    if (videoRef.current !== null && videoRef.current.paused) {
      void playVideoAndIgnoreAbort(videoRef.current)
      videoRef.current.currentTime = 0
    }
  }

  const handleMouseLeave = (): void => {
    if (videoRef.current !== null && !videoRef.current.paused) {
      videoRef.current.currentTime = 0
      videoRef.current.pause()
    }
  }

  return (
    <RootView
      direction='column'
      alignItems='flex-start'
      position='relative'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onCardClick}
    >
      {card.video !== undefined ? (
        <StyledVideo
          ref={videoRef}
          preload='auto'
          muted
          playsInline
          loop
          poster={isNotSafari() ? card.cover.src : undefined}
          src={card.video.src}
        />
      ) : (
        <CoverImgWrapper>
          <CoverImg src={card.cover.src} />
        </CoverImgWrapper>
      )}
      <IconAndNameWrapper gap='xxsmall'>
        <IconWrapper>
          <Icon iconId={card.icon} color='foreground/primary' />{' '}
        </IconWrapper>
        <Text size='small' bold>
          {getCardTypeName(card, t)}
        </Text>
      </IconAndNameWrapper>
    </RootView>
  )
}
