import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { getFlag } from 'sierra-client/config/global-config'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsScormUser } from 'sierra-client/state/user/user-selector'
import { createGlobalStyle } from 'styled-components'

type Visibility = 'visible' | 'hidden'

const intercomVisibilityAtom = atom<Visibility>('hidden')
export const isIntercomBootedAtom = atom<boolean>(false)

const useToggleIntercomLauncherVisibility = (): ((visibility: Visibility | 'default') => void) => {
  const { update } = useIntercom()
  const intercomEnabled = getFlag('intercom')
  const intercomMessengerEnabled = getFlag('intercom-messenger')

  const defaultVisibilyValue = useAtomValue(intercomVisibilityAtom)

  return useCallback(
    visibility => {
      if (intercomEnabled && intercomMessengerEnabled) {
        if (visibility === 'default') {
          update({ hideDefaultLauncher: defaultVisibilyValue === 'hidden' })
        } else {
          update({ hideDefaultLauncher: visibility === 'hidden' })
        }
      }
    },
    [intercomEnabled, intercomMessengerEnabled, update, defaultVisibilyValue]
  )
}

export const useOverrideIntercomLauncherVisibility = (visibility: Visibility | 'default'): void => {
  const toggle = useToggleIntercomLauncherVisibility()

  useEffect(() => {
    toggle(visibility)

    return () => {
      toggle('default')
    }
  }, [toggle, visibility])
}

/**
 *
 * Hides *intercom* when view is `mounted`.
 * Returns *intercom* visibility to _default_ when `unmount`
 *
 */
export const useHideIntercom = (): void => {
  useOverrideIntercomLauncherVisibility('hidden')
}

/*
  For certain components (such as modals), radix will focus trap, causing the intercom icon to be visible, but being inclickable.
*/
export const IntercomPointerEventVisibility = createGlobalStyle`
    .intercom-lightweight-app {
        pointer-events: auto;
    }
`

export const IntercomLauncherVisibility: React.FC<{ visibility: Visibility }> = ({ visibility }) => {
  const setVisibilityValue = useSetAtom(intercomVisibilityAtom)
  const isBootedValue = useAtomValue(isIntercomBootedAtom)
  const isNotScormUser = useSelector(selectIsScormUser) === 'not-scorm-user'

  useEffect(() => {
    if (isBootedValue && isNotScormUser) {
      setVisibilityValue(visibility)
    }
  }, [isBootedValue, isNotScormUser, setVisibilityValue, visibility])

  const toggle = useToggleIntercomLauncherVisibility()

  useEffect(() => {
    toggle(visibility)
  }, [toggle, visibility])

  return null
}
