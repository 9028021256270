import { motion } from 'framer-motion'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const OrganizationLink = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
`

export const SquareLogo = styled.img<{ $size?: number }>`
  width: ${p => p.$size ?? 20}px;
  height: ${p => p.$size ?? 20}px;
`

export const Card = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
      height: 'auto',
      padding: '12px 16px',
    },
    closed: {
      opacity: 0,
      height: 0,
      padding: '0px 16px',
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    duration: 0.15,
    opacity: {
      duration: 0.3,
    },
  },
})<{ $disabled?: boolean }>`
  all: unset;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: ${token('elevated/background')};
  outline: 1px solid ${token('border/default')};
  box-shadow: 0px 2px 4px 0px #00000014;
  color: ${token('foreground/primary')};
  overflow: hidden;

  ${p =>
    p.$disabled === true &&
    css`
      color: ${token('form/disabled/foreground')};
      box-shadow: none;
    `}
`
