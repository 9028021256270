import { motion } from 'framer-motion'
import { MouseEventHandler, useCallback } from 'react'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { useUser } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useMatrixData } from 'sierra-client/views/v3-author/matrix/data-layer'
import { MatrixPosition } from 'sierra-domain/api/strategy-v2'
import { UserId } from 'sierra-domain/api/uuid'
import { color } from 'sierra-ui/color'
import { RoundAvatar } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

type MatrixPositionPercentage = { topPercent: number; leftPercent: number }
const matrixPositionToPercentage = (position: MatrixPosition): MatrixPositionPercentage => ({
  topPercent: Math.round((1 - (position.y + 100) / 200) * 100),
  leftPercent: Math.round(((position.x + 100) / 200) * 100),
})

const LeftRightOverFlowCss = css`
  max-height: 100%;
  width: fit-content;
  max-width: 13ch;
`

const TopBottomOverFlowCss = css`
  max-height: 50px;
  width: fit-content;
  max-width: 100%;
`

const OptionsContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  padding: 32px;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.03)};
  border-radius: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 'a b' 'd c';

  > * {
    height: fit-content;
    overflow: auto;

    &:first-child {
      grid-area: a;
      text-align: start;
      align-self: end;
      transform: translateY(50%);
      ${LeftRightOverFlowCss}
    }

    &:nth-child(2) {
      grid-area: b;
      text-align: center;
      transform: translateX(-50%);
      ${TopBottomOverFlowCss}
    }

    &:nth-child(3) {
      grid-area: c;
      text-align: right;
      margin-left: auto;
      transform: translateY(-50%);
      ${LeftRightOverFlowCss}
    }

    &:nth-child(4) {
      grid-area: d;
      margin-left: auto;
      align-self: end;
      text-align: center;
      transform: translateX(50%);
      ${TopBottomOverFlowCss}
    }
  }
`

const INSET = 32

const MatrixInnerContainer = styled.div`
  position: absolute;
  inset: ${INSET}px;
  width: calc(100% - ${INSET * 2}px);
  height: calc(100% - ${INSET * 2}px);
  overflow: visible;
  cursor: pointer;
`

const SVGContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const SVG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  z-index: -1;

  line {
    stroke: ${p => color(p.theme.home.textColor).opacity(0.5).toString()};
  }
`

const DashedCross: React.FC = () => {
  return (
    <SVGContainer contentEditable={false}>
      <SVG width='100%' height='100%'>
        <line x1='0' y1='50%' x2='100%' y2='50%' strokeDasharray='4, 4' strokeWidth='1' />
      </SVG>
      <SVG width='100%' height='100%'>
        <line x1='50%' y1='0' x2='50%' y2='100%' strokeDasharray='4, 4' strokeWidth='1' />
      </SVG>
    </SVGContainer>
  )
}

const MatrixPositionContainer = styled(motion.div)<{
  percentagePosition: MatrixPositionPercentage
}>`
  position: absolute;

  left: ${p => `${p.percentagePosition.leftPercent}%`};
  top: ${p => `${p.percentagePosition.topPercent}%`};
`

export const CreateMatrix: FCC = ({ children }) => {
  return (
    <OptionsContainer>
      {children}
      <DashedCross />
    </OptionsContainer>
  )
}

const AvatarContainer = styled.div`
  border-radius: 50%;
  box-shadow: 0 0 60px 2px rgba(0, 0, 0, 0.9);
  transform: translate(-50%, -50%);
`

const MatrixAvatar: FCC<{ userId?: UserId; large?: boolean }> = ({ userId, large }) => {
  const user = useUser(userId)

  if (userId === undefined) {
    return <RoundAvatar size='small' firstName={'Anonymous'} />
  }

  if (user?.status === 'loaded') {
    return (
      <AvatarContainer>
        <RoundAvatar
          size={large === true ? 'medium' : 'small'}
          withOutline
          firstName={user.firstName}
          lastName={user.lastName}
          src={getAvatarImage(user.uuid, user.avatar)}
          color={user.avatarColor}
        />
      </AvatarContainer>
    )
  }

  return null
}

export const LearnMatrix: FCC = ({ children }) => {
  const { matrixData, userPosition, setUserPosition } = useMatrixData()
  const currentUser = useCurrentUser()

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      const rect = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      const normalizedX = Math.round((x / rect.width) * 200 - 100)
      const normalizedY = Math.round((1 - y / rect.height) * 200 - 100)

      const matrixPosition = MatrixPosition.safeParse({ x: normalizedX, y: normalizedY })

      if (matrixPosition.success) {
        setUserPosition?.(matrixPosition.data)
      } else {
        console.error('Invalid matrix position:', matrixPosition)
      }
    },
    [setUserPosition]
  )

  return (
    <OptionsContainer>
      {children}
      <DashedCross />
      <MatrixInnerContainer onClick={handleClick}>
        {matrixData?.responses.map(response => {
          return (
            <MatrixPositionContainer
              layout
              layoutId={response.matrixResponseId}
              transition={{
                type: 'spring',
                stiffness: 500,
                damping: 30,
                duration: 0.5,
              }}
              key={response.matrixResponseId}
              percentagePosition={matrixPositionToPercentage(response.position)}
            >
              <MatrixAvatar userId={response.userId} />
            </MatrixPositionContainer>
          )
        })}
        {userPosition && currentUser.status === 'success' && (
          <MatrixPositionContainer
            layout
            layoutId='user'
            percentagePosition={matrixPositionToPercentage(userPosition)}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30,
              duration: 0.5,
            }}
          >
            <MatrixAvatar large userId={currentUser.data.uuid} />
          </MatrixPositionContainer>
        )}
      </MatrixInnerContainer>
    </OptionsContainer>
  )
}
