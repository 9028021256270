import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, UnstyledSanaPage } from 'sierra-client/layout/sana-page'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { fetchCourseDataById, fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { selectGetPassedTimestampByCourseId } from 'sierra-client/state/v2/selectors'
import { Certificate } from 'sierra-client/views/learner/certificate'
import { Wrap } from 'sierra-client/views/learner/certificate/components'
import { useIsValidCertificateCourseId } from 'sierra-client/views/learner/certificate/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { createGlobalStyle } from 'styled-components'
import { z } from 'zod'

const GlobalStyle = createGlobalStyle`
  @media print {
    .intercom-lightweight-app{
      display: none !important;
    }

    /* Force single page when printing */
    html, body {
      height: 100%;
    }

    body {
      margin: 0;
      padding: 0;
    }
  }
`

const CertificatePage = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const courseId = Route.useSearch({ select: search => search.courseId })
  const user = useSelector(selectUser)
  const course = useSelector(state => selectCourseById(state, courseId))
  const completionTimestamp = useSelector(selectGetPassedTimestampByCourseId)(courseId)
  const isCertEnabled = useIsValidCertificateCourseId({ courseId })

  useEffect(() => {
    void dispatch(fetchCourseStatusById({ courseId }))
    void dispatch(fetchCourseDataById({ courseId }))
  }, [dispatch, courseId])

  const wrap = (inner: JSX.Element): JSX.Element => <Wrap>{inner}</Wrap>

  return (
    <UnstyledSanaPage
      mode='light'
      headerType={'none'}
      page={PageIdentifier.LearnerCertificate({ courseId: courseId })}
    >
      {!isCertEnabled ? (
        wrap(<div>Invalid course id {courseId}.</div>)
      ) : user && course ? (
        completionTimestamp !== undefined ? (
          <>
            <GlobalStyle />
            <Certificate
              id={courseId}
              title={course.settings.title}
              user={user}
              completionTimestamp={completionTimestamp}
              course={course}
            />
          </>
        ) : (
          wrap(
            <div>{t('course-overview.certificate-incomplete', { courseTitle: course.settings.title })}</div>
          )
        )
      ) : (
        <div>{t('admin.loading')}</div>
      )}
    </UnstyledSanaPage>
  )
}

export const Route = createFileRoute('/certificate-v2')({
  component: requireLoggedIn(CertificatePage) as React.FC,
  validateSearch: z.object({
    courseId: CourseId,
  }),
})
