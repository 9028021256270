import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { horizontalPadding, removeScrollbar } from 'sierra-client/layout/styles/content'
import { FCC } from 'sierra-client/types'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled(View)`
  overflow-y: auto;
  ${removeScrollbar}
  min-height: calc(var(--VH, 1vh) * 100);
  ${horizontalPadding}

  background-color: ${token('surface/soft')};
`
const PoweredBySana = styled(Text).attrs({ children: <>Powered by Sana™</> })`
  color: ${token('foreground/secondary')};
`

// Pass Authentication form as child
export const AuthenticationLayout: FCC = ({ children }) => {
  return (
    <AppThemeTokenProvider>
      <Wrapper direction='column' justifyContent='space-around' alignItems='center'>
        <Spacer />
        {children}
        <PoweredBySana />
        <Spacer />
      </Wrapper>
    </AppThemeTokenProvider>
  )
}

export const AuthenticationForm = styled.form`
  width: 100%;
`
