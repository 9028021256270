import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'

export type HeaderType = 'none' | 'scorm' | 'auth'

export const headerTypeAtom = atom<HeaderType>('none')

type UserMenuStates = 'initial' | 'enter'

export const userMenuStateAtom = atom<{ previous: UserMenuStates; current: UserMenuStates }>({
  previous: 'initial',
  current: 'initial',
})

export function useToggleUserMenu(): () => void {
  const setState = useSetAtom(userMenuStateAtom)
  return useCallback(() => {
    setState(state => {
      const previous = state.current
      const userMenuState = previous === 'initial' ? 'enter' : 'initial'
      return { previous, current: userMenuState }
    })
  }, [setState])
}

export function useCloseUserMenu(): () => void {
  const setState = useSetAtom(userMenuStateAtom)
  return useCallback(() => {
    setState(state => {
      const previous = state.current
      const userMenuState = 'initial'
      return { previous, current: userMenuState }
    })
  }, [setState])
}

export function useUserMenuIsOpen(): boolean {
  return useAtomValue(userMenuStateAtom).current === 'enter'
}
