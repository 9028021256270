import React from 'react'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { BasicNewsModal } from 'sierra-client/components/common/modals/news-modal'
import { getFlag } from 'sierra-client/config/global-config'
import { AnyPageInstance } from 'sierra-client/core/logging/page/types'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { requireOrgPermission } from 'sierra-client/core/require-org-permission'
import { useTranslationForBaseRoute } from 'sierra-client/hooks/use-translation'
import { SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { ManageLayout } from 'sierra-client/views/manage/components/layout/manage-layout'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`

const _ManagePageWrapper: React.FC<{
  pageIdentifier: AnyPageInstance
  children: React.ReactNode
}> = ({ pageIdentifier, children }) => {
  const { t } = useTranslationForBaseRoute()
  const inPersonEventsEnabled = getFlag('enable-event-groups')

  return (
    <SanaPage showIntercomLauncher={true} disableMobile page={pageIdentifier}>
      <Wrapper>
        <ConfirmationModalProvider>
          <ManageLayout>{children}</ManageLayout>
        </ConfirmationModalProvider>
        {inPersonEventsEnabled && (
          <BasicNewsModal
            title={t('product-update-modal.in-person-events.title')}
            body={[t('product-update-modal.in-person-events.body')]}
            action={t('product-update-modal.in-person-events.action')}
            secondaryAction={t('product-update-modal.in-person-events.secondary-action')}
            onSecondary={() => getGlobalRouter().navigate({ to: '/manage/content' })}
            useOnceKey='inPersonEventsLaunchModal'
            imagePath='images/in-person-scheduling-hero.png'
          />
        )}
      </Wrapper>
    </SanaPage>
  )
}

export const ManagePageWrapper = requireLoggedIn(requireOrgPermission(_ManagePageWrapper, 'ACCESS_MANAGE'))
