import React, { useEffect } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeCollaborationGetDocumentHistory } from 'sierra-domain/routes'
import { History, getUpdatesMeta } from 'sierra-domain/version-history/get-updates-meta'
import { groupUpdates } from 'sierra-domain/version-history/group-updates'

export const LoadHistory: React.FC<{
  onChange: (updates: History | undefined) => void
  scopedContentId: ScopedCreateContentId
}> = ({ scopedContentId, onChange }) => {
  const updates = useCachedQuery(
    XRealtimeCollaborationGetDocumentHistory,
    {
      yDocId: scopedContentId,
    },
    { refetchOnWindowFocus: false }
  ).data?.updates

  useEffect(() => {
    if (updates === undefined) {
      onChange(undefined)
      return
    }

    const groupedUpdates = groupUpdates(updates)
    onChange({ updates: groupedUpdates, updatesMeta: getUpdatesMeta(groupedUpdates) })
    return () => onChange(undefined)
  }, [onChange, updates])

  return null
}
