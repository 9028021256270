import { FC } from 'react'
import { EnrollmentStep } from 'sierra-client/features/program/admin/user-timeline/types'
import { getNullableDate, getStepProgress } from 'sierra-client/features/program/admin/user-timeline/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'

type State = 'no-progress' | 'progress' | 'completed'
export const StepState: FC<{ enrollmentStep: EnrollmentStep }> = ({ enrollmentStep }) => {
  const { t } = useTranslation()

  const progress = getStepProgress(enrollmentStep)
  const view = iife((): State => {
    switch (true) {
      case progress === 0: {
        return 'no-progress'
      }
      case progress < 1: {
        return 'no-progress'
      }
      case progress === 1: {
        return 'completed'
      }
      default:
        throw Error('Progress is out of bounds')
    }
  })

  const dot = <Text color='foreground/muted'>·</Text>

  return (
    <View gap='6'>
      {iife(() => {
        switch (view) {
          case 'no-progress': {
            if (enrollmentStep.__typename === 'UserProgramEmailStep') {
              return (
                <Text bold color='foreground/muted'>
                  {t('manage.programs.step.email-state.not-delivered')}
                </Text>
              )
            }

            const due = enrollmentStep.dueDate

            return (
              <>
                <Text bold color='foreground/muted'>
                  {t('dictionary.not-started')}
                </Text>
                {isDefined(due) && (
                  <>
                    {dot}
                    <Text color='foreground/muted'>
                      {t('dictionary.due')} {due}
                    </Text>
                  </>
                )}
              </>
            )
          }
          case 'progress': {
            const percentage = progress * 100
            const due = enrollmentStep.dueDate

            return (
              <>
                <Text bold color='foreground/muted'>
                  {t('manage.programs.n-percentage-progress', { percentage })}
                </Text>
                {isDefined(due) && (
                  <>
                    {dot}
                    <Text color='foreground/muted'>
                      {t('dictionary.due')} {due}
                    </Text>
                  </>
                )}
              </>
            )
          }
          case 'completed': {
            if (enrollmentStep.__typename === 'UserProgramEmailStep') {
              const availableAt = getNullableDate(enrollmentStep.availableAt)

              return (
                <Text color='foreground/muted'>
                  {t('manage.programs.step.email-state.delivered')} {availableAt}
                </Text>
              )
            }

            const startedAt = getNullableDate(enrollmentStep.progress.startedAt)
            const passedAt = getNullableDate(enrollmentStep.progress.passedAt)

            return (
              <>
                <Text color='foreground/muted'>Started {startedAt}</Text>
                {isDefined(passedAt) && (
                  <>
                    {dot}
                    <Text color='foreground/muted'>
                      {t('dictionary.completed')} {passedAt}
                    </Text>
                  </>
                )}
              </>
            )
          }
          default:
            return assertNever(view)
        }
      })}
    </View>
  )
}
