import { useState } from 'react'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

const height = '24px'
const duration = '0.2s'
const delay = '0.4s'
const easing = 'cubic-bezier(0.25, 0.1, 0.25, 1)'

const StyledIcon = styled(Icon)`
  color: ${p => color(p.theme.home.textColor).opacity(0.7).toString()};
`

const HoverArea = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: ${p => p.theme.borderRadius['size-6']};
  height: ${height};
  inset: 0;

  color: ${p => p.theme.home.backgroundColor};
  background-color: ${p => color(p.theme.home.textColor).opacity(0.04).toString()};
  transition: opacity ${duration} ${easing};
  opacity: 0;
`

const AddContentArea = styled.button.attrs({ contentEditable: false })<{ $hover: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0;
  padding: 0;
  background: transparent;

  transition: height ${duration} ${easing};
  ${p =>
    p.$hover === true &&
    css`
      height: ${height};
      opacity: 1;
      transition: height ${duration} ${easing} ${delay};

      & > ${HoverArea} {
        transition: opacity ${duration} ${easing} ${delay};
        opacity: 1;
      }
    `}
`

export const AddContentButton: React.FC<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}> = ({ onClick }) => {
  const [hover, setHover] = useState(false)
  const readOnly = useEditorReadOnly()

  if (readOnly) return null
  return (
    <AddContentArea onClick={onClick} $hover={hover} contentEditable={false}>
      <HoverArea onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <StyledIcon iconId='add' size='size-16' />
      </HoverArea>
    </AddContentArea>
  )
}
