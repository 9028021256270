import { useSetAtom } from 'jotai'
import React from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { pictureInPictureAtom } from 'sierra-client/features/sana-now/picture-in-picture-atom'

export const PictureInPictureToggle: React.FC = () => {
  const setPictureInPictureState = useSetAtom(pictureInPictureAtom)

  const toggle = (): void => setPictureInPictureState(prev => ({ open: !prev.open }))

  return (
    <ShortcutMenu.Action
      iconId='breakout-room'
      group='live'
      label={'sana-now.toggle-picture-in-picture-area'}
      run={toggle}
      permission={'ACCESS_LMS'}
    />
  )
}
