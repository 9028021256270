import React from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { MatrixDataProvider } from 'sierra-client/views/v3-author/matrix/data-layer'
import { CreateMatrix, LearnMatrix } from 'sierra-client/views/v3-author/matrix/matrix'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const interactiveCardGrid = css`
  row-gap: 0;
  padding: 3rem 0rem;

  & {
    --middle: minmax(1rem, 6rem);
    --gutter: 1fr;

    @media screen and (max-width: ${v2_breakpoint.phone}) {
      --gutter: 4%;
    }
  }
`

const MatrixInteractiveCardWrapper = styled(BlockWrapper)`
  ${interactiveCardGrid}
`

export const MatrixContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, element, mode } = props
  assertElementType('matrix', element)
  const userId = useSelector(selectUserId)

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>

  return (
    <RenderingContext preventDrag={true} disableMenu={false} withGrid={true}>
      <MatrixInteractiveCardWrapper {...props} {...attributes} ref={ref}>
        <RenderingContext withGrid={false}>
          <MatrixDataProvider element={element} mode={mode}>
            {mode === 'create' || mode === 'template' || mode === 'version-history' ? (
              <CreateMatrix>{children}</CreateMatrix>
            ) : (
              <LearnMatrix>{children}</LearnMatrix>
            )}
          </MatrixDataProvider>
        </RenderingContext>
      </MatrixInteractiveCardWrapper>
    </RenderingContext>
  )
})

export const Matrix: SlateFC = ({ element, children }) => {
  assertElementType('matrix', element)

  return <>{children}</>
}
