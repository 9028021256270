import { FC } from 'react'
import { SubscribeToRemotePresence } from 'sierra-client/features/sana-now/presence/sync-remote-presence'
import {
  SyncAwarenessBackendPresenceToRedux,
  SyncAwarenessStateToBackendPresence,
} from 'sierra-client/features/sana-now/sync-awareness/sana-now-call-state-sync'
import { LiveSessionId } from 'sierra-domain/api/nano-id'

/**
 * Subscribes to the remote presence channel and syncs the awareness state
 * Updates the remote with the local state from redux
 * Updates the local redux state when changes to the remove is detected
 */
export const SyncBackendAwarenssWithRedux: FC<{ liveSessionId: LiveSessionId; userShouldIdle: boolean }> = ({
  liveSessionId,
  userShouldIdle,
}) => {
  return (
    <>
      <SyncAwarenessBackendPresenceToRedux />
      <SyncAwarenessStateToBackendPresence userShouldIdle={userShouldIdle} liveSessionId={liveSessionId} />
      <SubscribeToRemotePresence liveSessionId={liveSessionId} />
    </>
  )
}
