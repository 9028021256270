import { useEffect, useState } from 'react'
import { ConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { NarrationVideoState } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-video'
import { Layers } from 'sierra-client/views/flexible-content/card-narration/layers'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorCancelGenerateNarrationAvatar } from 'sierra-domain/routes'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const BlurContainer = styled(View)`
  flex-direction: column;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: 4px;
  flex-shrink: 0;
`

function estimateCompletionPercentage(createdAt: Date, estimatedCompletedAt: Date): number {
  const startTime = createdAt.getTime()
  const totalTime = estimatedCompletedAt.getTime() - startTime
  const now = new Date()
  const t = (now.getTime() - startTime) / totalTime

  // Calculating the percentage linearly makes it feel too predictable,
  // so apply a sigmoid function to give it a less deterministic feel.
  const percentage = 1 / (1 + Math.exp(-5 * (t - 0.3)))

  // The percentage should max out at 99, since we don't want to show
  // 100% until it is actually done loading
  return Math.min(99, Math.floor(percentage * 100))
}

const PercentageText: React.FC<{
  createdAt: Date
  estimatedCompletedAt: Date
}> = ({ createdAt, estimatedCompletedAt }) => {
  const [percentage, setPercentage] = useState<number>(
    estimateCompletionPercentage(createdAt, estimatedCompletedAt)
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(estimateCompletionPercentage(createdAt, estimatedCompletedAt))
    }, 300)

    return () => clearInterval(interval)
  })

  // eslint-disable-next-line react/jsx-no-literals
  return <Text bold color='grey5'>{`${percentage}%`}</Text>
}

const CancelButton = styled(Button).attrs({ variant: 'ghost' })`
  color: white;
  height: auto;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

type Props = {
  videoState: NarrationVideoState & { type: 'generating' }
  contentId: CreateContentId
} & (
  | {
      canEdit: true
      confirmationModalContext: ConfirmationModalContext
    }
  | {
      canEdit: false
      confirmationModalContext?: ConfirmationModalContext
    }
)

export const GeneratingNarrationLayer: React.FC<Props> = ({
  videoState,
  confirmationModalContext,
  canEdit,
  contentId,
}) => {
  const { t } = useTranslation()
  const cancelMutation = useTypedMutation(XRealtimeAuthorCancelGenerateNarrationAvatar, {})

  return (
    <Layers.Layer
      background='white'
      alignItems='center'
      justifyContent='center'
      direction='column'
      $backgroundImageUrl={videoState.thumbnailUrl}
    >
      <BlurContainer gap='none' justifyContent={'flex-end'} alignItems={'center'}>
        <View gap='4'>
          <Text bold size='small' color={'white'}>
            {t('dictionary.generating')}
          </Text>
          <PercentageText
            createdAt={videoState.createdAt}
            estimatedCompletedAt={videoState.estimatedCompletedAt}
          />
        </View>
        <Spacer size='24' />
        {canEdit && (
          <CancelButton
            loading={cancelMutation.isPending}
            onClick={() =>
              confirmationModalContext.show({
                bodyText: t('ai-narrations.cancel-warning'),
                confirmLabel: t('ai-narrations.cancel'),
                onConfirm: () => {
                  cancelMutation.mutate({
                    narrationId: videoState.narrationId,
                    contentId: contentId,
                  })
                },
              })
            }
          >
            {t('dictionary.cancel')}
          </CancelButton>
        )}
      </BlurContainer>
    </Layers.Layer>
  )
}
