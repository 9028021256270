//
// NOT TO BE EDITED MANUALLY!
// This file is generated by the generate-language-files.script.ts script, and is generated as part of pulling the latest translations from Localazy.
//

/**
 * Language code for a supported app language that is available in Localazy.
 * For definitions, see {@link APP_LANGUAGES}.
 */
export type AppLanguageCode = 'ar' | 'bg' | 'bn' | 'bs' | 'cs' | 'da' | 'de' | 'el' | 'en' | 'es' | 'et' | 'fa-AF' | 'fi' | 'fr' | 'fr-BE' | 'fr-CA' | 'fr-CH' | 'gu' | 'he' | 'hi' | 'hr' | 'hu' | 'id' | 'is' | 'it' | 'ja' | 'ka' | 'kk' | 'kn' | 'ko' | 'lt' | 'lv' | 'mk' | 'mr' | 'ms' | 'no' | 'nl' | 'pa' | 'pl' | 'pt' | 'pt-BR' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-Latn' | 'sv' | 'sw' | 'ta' | 'te' | 'th' | 'tr' | 'uk' | 'ur' | 'vi' | 'zh-CN' | 'zh-TW' | 'zh'

export type AppLanguages = typeof APP_LANGUAGES

export type AppLanguageDefinition = {
  code: AppLanguageCode
  localizedName: string
}

/**
 * Definitions for all supported app languages that are available in Localazy (e.g. languages that the app can be displayed in).
 * Not to be confused with the languages that a course can be translated into!
 */
export const APP_LANGUAGES = {
  /** Arabic */
  "ar": { code: 'ar', localizedName: 'العربية' },
  /** Bulgarian */
  "bg": { code: 'bg', localizedName: 'Български' },
  /** Bangla */
  "bn": { code: 'bn', localizedName: 'বাংলা' },
  /** Bosnian */
  "bs": { code: 'bs', localizedName: 'Bosanski' },
  /** Czech */
  "cs": { code: 'cs', localizedName: 'Čeština' },
  /** Danish */
  "da": { code: 'da', localizedName: 'Dansk' },
  /** German */
  "de": { code: 'de', localizedName: 'Deutsch' },
  /** Greek */
  "el": { code: 'el', localizedName: 'Ελληνικά' },
  /** English */
  "en": { code: 'en', localizedName: 'English' },
  /** Spanish */
  "es": { code: 'es', localizedName: 'Español' },
  /** Estonian */
  "et": { code: 'et', localizedName: 'Eesti' },
  /** Dari */
  "fa-AF": { code: 'fa-AF', localizedName: 'دری' },
  /** Finnish */
  "fi": { code: 'fi', localizedName: 'Suomi' },
  /** French */
  "fr": { code: 'fr', localizedName: 'Français' },
  /** Belgian French */
  "fr-BE": { code: 'fr-BE', localizedName: 'Français Belgique' },
  /** Canadian French */
  "fr-CA": { code: 'fr-CA', localizedName: 'Français Canadien' },
  /** Swiss French */
  "fr-CH": { code: 'fr-CH', localizedName: 'Français Suisse' },
  /** Gujarati */
  "gu": { code: 'gu', localizedName: 'ગુજરાતી' },
  /** Hebrew */
  "he": { code: 'he', localizedName: 'עברית' },
  /** Hindi */
  "hi": { code: 'hi', localizedName: 'हिन्दी' },
  /** Croatian */
  "hr": { code: 'hr', localizedName: 'Hrvatski' },
  /** Hungarian */
  "hu": { code: 'hu', localizedName: 'Magyar' },
  /** Indonesian */
  "id": { code: 'id', localizedName: 'Indonesia' },
  /** Icelandic */
  "is": { code: 'is', localizedName: 'Íslenska' },
  /** Italian */
  "it": { code: 'it', localizedName: 'Italiano' },
  /** Japanese */
  "ja": { code: 'ja', localizedName: '日本語' },
  /** Georgian */
  "ka": { code: 'ka', localizedName: 'ქართული' },
  /** Kazakh */
  "kk": { code: 'kk', localizedName: 'Қазақ Тілі' },
  /** Kannada */
  "kn": { code: 'kn', localizedName: 'ಕನ್ನಡ' },
  /** Korean */
  "ko": { code: 'ko', localizedName: '한국어' },
  /** Lithuanian */
  "lt": { code: 'lt', localizedName: 'Lietuvių' },
  /** Latvian */
  "lv": { code: 'lv', localizedName: 'Latviešu' },
  /** Macedonian */
  "mk": { code: 'mk', localizedName: 'Македонски' },
  /** Marathi */
  "mr": { code: 'mr', localizedName: 'मराठी' },
  /** Malay */
  "ms": { code: 'ms', localizedName: 'Melayu' },
  /** Norwegian Bokmål */
  "no": { code: 'no', localizedName: 'Norsk Bokmål' },
  /** Dutch */
  "nl": { code: 'nl', localizedName: 'Nederlands' },
  /** Punjabi */
  "pa": { code: 'pa', localizedName: 'ਪੰਜਾਬੀ' },
  /** Polish */
  "pl": { code: 'pl', localizedName: 'Polski' },
  /** Portuguese */
  "pt": { code: 'pt', localizedName: 'Português' },
  /** Brazilian Portuguese */
  "pt-BR": { code: 'pt-BR', localizedName: 'Português (Brasil)' },
  /** Romanian */
  "ro": { code: 'ro', localizedName: 'Română' },
  /** Russian */
  "ru": { code: 'ru', localizedName: 'Русский' },
  /** Slovak */
  "sk": { code: 'sk', localizedName: 'Slovenčina' },
  /** Slovenian */
  "sl": { code: 'sl', localizedName: 'Slovenščina' },
  /** Albanian */
  "sq": { code: 'sq', localizedName: 'Shqip' },
  /** Serbian */
  "sr": { code: 'sr', localizedName: 'Српски' },
  /** Serbian (Latin) */
  "sr-Latn": { code: 'sr-Latn', localizedName: 'Srpski (Latinica)' },
  /** Swedish */
  "sv": { code: 'sv', localizedName: 'Svenska' },
  /** Swahili */
  "sw": { code: 'sw', localizedName: 'Kiswahili' },
  /** Tamil */
  "ta": { code: 'ta', localizedName: 'தமிழ்' },
  /** Telugu */
  "te": { code: 'te', localizedName: 'తెలుగు' },
  /** Thai */
  "th": { code: 'th', localizedName: 'ไทย' },
  /** Turkish */
  "tr": { code: 'tr', localizedName: 'Türkçe' },
  /** Ukrainian */
  "uk": { code: 'uk', localizedName: 'Українська' },
  /** Urdu */
  "ur": { code: 'ur', localizedName: 'اردو' },
  /** Vietnamese */
  "vi": { code: 'vi', localizedName: 'Tiếng Việt' },
  /** Chinese (Simplified) */
  "zh-CN": { code: 'zh-CN', localizedName: '简体中文' },
  /** Chinese (Traditional) */
  "zh-TW": { code: 'zh-TW', localizedName: '繁體中文' },
  /** Chinese */
  "zh": { code: 'zh', localizedName: '中文' },
} satisfies Record<string, AppLanguageDefinition>

/**
 * All languages that the app can be displayed in.
 */
export const APP_LANGUAGE_CODES = Object.keys(APP_LANGUAGES) as AppLanguageCode[]
