import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { AuthHeader } from 'sierra-client/layout/header/auth'
import { ScormHeader } from 'sierra-client/layout/header/scorm-header'
import { HeaderType, headerTypeAtom } from 'sierra-client/state/header'
import { assertNever } from 'sierra-domain/utils'

export const Header: React.FC<{ headerType: HeaderType }> = ({ headerType }) => {
  const setHeaderType = useSetAtom(headerTypeAtom)
  useEffect(() => setHeaderType(headerType), [setHeaderType, headerType])

  switch (headerType) {
    case 'scorm':
      return <ScormHeader />
    case 'auth':
      return <AuthHeader />
    case 'none':
      return null
    default:
      assertNever(headerType)
  }
}
