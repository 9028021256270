import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { AddFilter, FilterIconAnchor } from 'sierra-client/lib/filter/components/add'
import { groupFilterInit } from 'sierra-client/lib/filter/components/filter-utils'
import { FilterRoot } from 'sierra-client/lib/filter/components/root'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { useTypedMutation } from 'sierra-client/state/api'
import { FilterGenerateDomain } from 'sierra-domain/api/filter'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { XRealtimeFilterGenerate } from 'sierra-domain/routes'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const InheritSize = styled(motion.div).attrs({
  layout: true,
  transition: {
    duration: 0.2,
    ease: 'easeInOut',
  },
})`
  width: inherit;
  height: inherit;
  min-height: inherit;
  min-width: inherit;
`

export const WithParentSize: React.FC<{
  children: React.FC<{
    width: number
    height: number
    scrollRef: HTMLDivElement | null
    setScrollRef: Dispatch<SetStateAction<HTMLDivElement | null>>
  }>
}> = props => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null)

  const [size, setSize] = React.useState({ width: 0, height: 0 })

  const onResize = React.useCallback(() => {
    if (ref.current) {
      setSize({ width: ref.current.clientWidth, height: ref.current.clientHeight })
    }
  }, [])

  React.useEffect(() => {
    const node = ref.current
    if (node) {
      onResize()
      const observer = new ResizeObserver(_.throttle(onResize, 100))
      observer.observe(node)
      window.addEventListener('resize', onResize)
      return () => (window.removeEventListener('resize', onResize), observer.disconnect())
    }
  }, [onResize])

  return (
    <InheritSize ref={ref}>
      <props.children
        width={size.width}
        height={size.height}
        scrollRef={scrollRef}
        setScrollRef={setScrollRef}
      />
    </InheritSize>
  )
}

type StopPropagationEventHandler = (ev?: { stopPropagation: () => void }) => void

export const useSelectOnClick = <Data extends TableData>(
  cell: Cell<Data>,
  api: TableAPI<Data>
): StopPropagationEventHandler => {
  const selected = useAtomValue(cell.selected)
  return React.useCallback(
    ev => {
      ev?.stopPropagation()
      const sel = api.query.selected().selection

      // if all -> continue with all but change excluded list
      // if manual -> continue with manual but change the rows list
      // if none -> go to manual
      switch (sel.type) {
        case 'all': {
          const excluded = selected
            ? [...sel.excluded, cell.pos.row]
            : [...sel.excluded].filter(ref => ref !== cell.pos.row)

          api.action.setSelection({
            type: 'all',
            excluded: new Set(excluded),
          })
          break
        }
        case 'manual': {
          const rows = selected
            ? [...sel.rows].filter(ref => ref !== cell.pos.row)
            : [...sel.rows, cell.pos.row]
          api.action.setSelection({
            type: 'manual',
            rows: new Set(rows),
          })
          break
        }
        case 'none':
          api.action.setSelection({
            type: 'manual',
            rows: new Set([cell.pos.row]),
          })
          break
      }
    },
    [api, cell, selected]
  )
}

export const useGenerateFilter = (
  domain?: FilterGenerateDomain,
  onChange?: (filter: Filter) => void
): ((query: string) => Promise<Filter>) => {
  const generateFilterMutation = useTypedMutation(XRealtimeFilterGenerate, {})

  return async (query: string): Promise<Filter> => {
    if (domain === undefined) {
      return Promise.reject(new Error('domain is undefined'))
    }

    const response = await generateFilterMutation.mutateAsync({ prompt: query, domain }, {})

    if (onChange !== undefined) onChange(response.filter)

    return response.filter
  }
}

export const Filtering: React.FC<{ api: TableAPI }> = ({ api }) => {
  const filter = useAtomValue(api.atoms.useFilter)
  const generateFilter = useGenerateFilter(filter?.domain, newFilter => filter?.ctx.update(() => newFilter))

  if (filter === undefined) return <></>

  return (
    <>
      <View>
        <AddFilter
          filterUsedDomains={false}
          ctx={filter.ctx}
          filter={filter.filter}
          filterInit={groupFilterInit}
          renderTrigger={() => <FilterIconAnchor />}
          generateFilter={filter.domain !== undefined ? generateFilter : undefined}
        />
      </View>
      <FilterRoot
        ctx={filter.ctx}
        filter={filter.filter}
        generateFilter={filter.domain !== undefined ? generateFilter : undefined}
        assetContext={{ type: 'unknown' }}
      />
      {/*{domain !== undefined && <FilterGenerationPopover domain={domain} ctx={filter.ctx} />}*/}
    </>
  )
}

export const CellRenderContext = React.createContext({
  hasChildrenRows: false,
})
