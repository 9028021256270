import { useCallback, useMemo, useState } from 'react'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ConfirmDeleteGroupModal } from 'sierra-client/views/manage/programs/components/modals'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import {
  ProgramActions,
  ProgramsTable,
  useProgramsTableAPI,
} from 'sierra-client/views/manage/programs/manage-programs/programs-table'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsSetProgramMemberships,
  XRealtimeAdminProgramsCreateProgram,
  XRealtimeAdminProgramsDeleteProgram,
} from 'sierra-domain/routes'
import { Modal } from 'sierra-ui/components'
import { Button, InputPrimitive, Label, Text, View } from 'sierra-ui/primitives'

export const ManagePrograms = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const tracking = useTracking()

  const assignUsers = useCallback(
    async (programIds: string[], userIds: UserId[]): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsSetProgramMemberships, {
        programIds: programIds,
        userIds,
      })
    },
    [postWithUserErrorException]
  )

  // Active action/modal
  const [action, setAction] = useState<
    | undefined
    | {
        modal: 'delete'
        programId: string
      }
    | {
        modal: 'assign-users'
        programIds: string[]
      }
    | {
        modal: 'new-program'
      }
  >(undefined)

  const [newProgramTitle, setNewProgramTitle] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const createProgram = useCallback(async (): Promise<void> => {
    setLoading(true)
    const { programId: programId } = await postWithUserErrorException(XRealtimeAdminProgramsCreateProgram, {
      programName: newProgramTitle,
    })
    tracking.program.created(programId)
    void getGlobalRouter().navigate({ to: `/manage/programs/${programId}` })
    setLoading(false)
  }, [newProgramTitle, postWithUserErrorException, tracking.program])

  const goToProgramDetails = useCallback(async (programId: string) => {
    await getGlobalRouter().navigate({ to: `/manage/programs/${programId}` })
  }, [])

  const actions: ProgramActions = useMemo(
    () => ({
      onNewProgram: () => setAction({ modal: 'new-program' }),
      onViewDetails: goToProgramDetails,
      onAddUsers: refs => setAction({ modal: 'assign-users', programIds: refs }),
      onDelete: ref => setAction({ modal: 'delete', programId: ref }),
    }),
    [goToProgramDetails]
  )

  const tableAPI = useProgramsTableAPI(actions)

  return (
    <>
      <PageTitle title={t('dictionary.program-plural')} />

      <ProgramsTable actions={actions} tableAPI={tableAPI} />
      <ConfirmDeleteGroupModal
        mode='program'
        open={action?.modal === 'delete'}
        onClose={() => setAction(undefined)}
        onConfirm={async (): Promise<void> => {
          if (action?.modal !== 'delete') return
          await postWithUserErrorException(XRealtimeAdminProgramsDeleteProgram, {
            programId: action.programId,
          })
          await tableAPI.api.action.refresh()
          notifications.push({ type: 'program-removed' })
          tracking.program.deleted(action.programId)
        }}
      />
      {action?.modal === 'assign-users' && (
        <AssignModal
          isOpen
          subjectType='program'
          subjects={action.programIds}
          title={t('admin.add-users')}
          panes={['user']}
          activePane='user'
          onClose={() => setAction(undefined)}
          onSave={async selections => {
            const userIds = selections.flatMap(item => (item.type === 'user' ? [item.id] : []))
            await assignUsers(action.programIds, userIds)
            notifications.push({ type: 'success' })
            await tableAPI.api.action.refresh()
            setAction(undefined)
          }}
        />
      )}
      <Modal
        size={{ width: 400, height: 202 }}
        open={action?.modal === 'new-program'}
        onClose={() => setAction(undefined)}
        padding='24'
      >
        <View direction='column'>
          <Text bold size='large'>
            {t('manage.programs.new-program')}
          </Text>
          <Label htmlFor='program-title-input'>
            <Text bold>{t('dictionary.title')}</Text>
          </Label>
          <InputPrimitive
            type='text'
            id='program-title-input'
            onChange={e => setNewProgramTitle(e.target.value)}
            value={newProgramTitle}
          />
          <View justifyContent='flex-end'>
            <Button variant='secondary' onClick={() => setAction(undefined)}>
              {t('dictionary.cancel')}
            </Button>
            <Button onClick={createProgram} loading={loading} disabled={loading}>
              {t('dictionary.create')}
            </Button>
          </View>
        </View>
      </Modal>
    </>
  )
}
