import { useAtomValue, useSetAtom } from 'jotai/index'
import React, { useCallback, useMemo } from 'react'
import {
  CardIcon,
  FolderIcon,
  NestedItemList,
  collapsableSidebarStateAtom,
  collapseIconVariants,
  sidebarHasNestedMenuOpenAtom,
} from 'sierra-client/features/collapsable-sidebar'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { FCC } from 'sierra-client/types'
import { FileContainer } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-file'
import { FolderTitleRow } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { safeGetNode } from 'sierra-domain/editor/operations/y-utilts'
import { FileId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { isDefined } from 'sierra-domain/utils'
import { History } from 'sierra-domain/version-history/get-updates-meta'
import { ReplayUpdatesContext } from 'sierra-domain/version-history/replay-updates'
import { EditableText, Icon, MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledEditableText = styled(EditableText)<{ $isCurrentItem: boolean }>`
  color: ${p => token(p.$isCurrentItem ? 'foreground/primary' : 'foreground/secondary')};
`

const MenuContainer = styled.div`
  opacity: 0;
  padding-right: 4px;
`

const SidebarFileMenu: React.FC<{
  onDuplicateClick: () => void
  container: HTMLElement | null
}> = ({ onDuplicateClick, container }) => {
  const { t } = useTranslation()

  const menuItems: MenuItem<'duplicate'>[] = useMemo(
    () => [
      {
        id: 'duplicate',
        type: 'label',
        label: t('version-history.header-duplicate-button'),
        onClick: onDuplicateClick,
      },
    ],
    [onDuplicateClick, t]
  )

  const [isOpen, setIsOpen] = React.useState(false)
  const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)

  return (
    <MenuContainer>
      <IconMenu
        size='small'
        iconId='overflow-menu--vertical'
        menuItems={menuItems}
        variant='ghost'
        container={container}
        isOpen={isOpen}
        onOpenChange={newIsOpen => {
          setSidebarHasNestedMenuOpen(newIsOpen)
          setIsOpen(newIsOpen)
        }}
        onSelect={() => {}}
      />
    </MenuContainer>
  )
}
const OpaqueIcon = styled(Icon).attrs({ color: 'foreground/primary' })`
  opacity: 0.4;
  right: 9px;
  position: absolute;
  pointer-events: none;
`

const StyledFileContainer = styled(FileContainer)`
  cursor: pointer;

  &:hover,
  &:focus-within {
    ${MenuContainer} {
      opacity: 1;
    }
  }

  &:hover,
  &:focus-within {
    ${OpaqueIcon} {
      opacity: 0;
    }
  }
`

const SidebarFile: React.FC<{
  file: File
  state: ReplayUpdatesContext
  targetIndex: number
  mode: ContentType
  contentId: CreateContentId
  history: History
  copyHistoryFile?: (fileId: FileId) => void
  isCurrentItem: boolean
}> = ({
  file,
  history,
  targetIndex,
  state: { currentYDoc },
  mode,
  contentId,
  copyHistoryFile,
  isCurrentItem,
}) => {
  const getFileTitle = useGetFileTitle(currentYDoc)
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  const userIdsWhoChangedFile = useMemo(() => {
    const userIds = history.updates[targetIndex]?.userIds
    if (userIds === undefined || userIds.length === 0) return []

    const updateMeta = history.updatesMeta[file.id]?.find(meta => meta.targetIndex === targetIndex)
    if (updateMeta === undefined) return []

    return userIds
  }, [file.id, history.updates, history.updatesMeta, targetIndex])

  const subPath = ScopedCreateContentId.urlSubPath(mode)
  const href = `/history/${subPath}/${contentId}/${file.id}`
  const handleOnClick = useCallback((): void => {
    void getGlobalRouter().navigate({ to: href })
  }, [href])

  const onDuplicateClick = useCallback(() => {
    copyHistoryFile?.(file.id)
  }, [copyHistoryFile, file.id])

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  return (
    <StyledFileContainer
      $isHighlighted={userIdsWhoChangedFile.length > 0}
      $isCurrentItem={isCurrentItem}
      onClick={handleOnClick}
      ref={setContainer}
    >
      <CardIcon isCurrentItem={isCurrentItem} />

      <View overflow='hidden' grow>
        <StyledEditableText
          bold
          $isCurrentItem={isCurrentItem}
          contentEditable={false}
          setContentEditable={() => {}}
          value={getFileTitle(file)}
          onRename={() => {}}
        />
      </View>
      {file.data.type !== 'notepad' && (
        <View animated variants={collapseIconVariants} animate={sidebarState}>
          {userIdsWhoChangedFile.length > 0 && <OpaqueIcon iconId='edit' />}
          {isDefined(copyHistoryFile) && (
            <SidebarFileMenu onDuplicateClick={onDuplicateClick} container={container} />
          )}
        </View>
      )}
    </StyledFileContainer>
  )
}

const SidebarModule: FCC<{
  folder: Folder
  currentCardId: NodeId | undefined
}> = ({ children, folder, currentCardId }) => {
  const isCurrentCardInFolder = isDefined(currentCardId) && folder.nodeIds.includes(currentCardId)

  return (
    <>
      {folder.id !== 'folder:root' && (
        <FolderTitleRow>
          <FolderIcon
            isCompleted={false}
            numberOfContent={folder.nodeIds.length}
            isCurrentItem={isCurrentCardInFolder}
          />
          <StyledEditableText
            $isCurrentItem={isCurrentCardInFolder}
            contentEditable={false}
            setContentEditable={() => {}}
            value={folder.title}
            onRename={() => {}}
          />
        </FolderTitleRow>
      )}

      {children}
    </>
  )
}

export const VersionHistoryLeftSidebar: React.FC<{
  nodeId: NodeId
  state: ReplayUpdatesContext
  selectedNode: NodeId | undefined
  mode: ContentType
  contentId: CreateContentId
  history: History
  targetIndex: number
  copyHistoryFile?: (fileId: FileId) => void
}> = ({ nodeId, history, targetIndex, copyHistoryFile, ...props }) => {
  const { currentYDoc } = props.state
  const node = useMemo(() => safeGetNode(currentYDoc, nodeId), [currentYDoc, nodeId])

  if (node === undefined) return <p>Node {nodeId} not found</p>

  if (node.type === 'folder') {
    return (
      <SidebarModule folder={node} currentCardId={props.selectedNode}>
        {node.id !== 'folder:root' ? (
          <NestedItemList open>
            {node.nodeIds.map(id => (
              <VersionHistoryLeftSidebar
                history={history}
                targetIndex={targetIndex}
                key={id}
                {...props}
                copyHistoryFile={copyHistoryFile}
                nodeId={id}
              />
            ))}
          </NestedItemList>
        ) : (
          node.nodeIds.map(id => (
            <VersionHistoryLeftSidebar
              history={history}
              targetIndex={targetIndex}
              key={id}
              {...props}
              copyHistoryFile={copyHistoryFile}
              nodeId={id}
            />
          ))
        )}
      </SidebarModule>
    )
  } else if (node.type === 'file') {
    return (
      <SidebarFile
        history={history}
        targetIndex={targetIndex}
        file={node}
        copyHistoryFile={copyHistoryFile}
        contentId={props.contentId}
        mode={props.mode}
        state={props.state}
        isCurrentItem={node.id === props.selectedNode}
      />
    )
  } else {
    return (
      <p>
        Node type {node.type} not supported. Id: ${nodeId}{' '}
      </p>
    )
  }
}
