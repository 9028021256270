import { isInElement } from 'sierra-client/views/v3-author/command'
import { Flags } from 'sierra-domain/api/private'
import { Editor } from 'slate'

export const isEnabled = ({ editor, flags }: { editor: Editor; flags: Flags }): boolean => {
  const isMatrixEnabled = flags['matrix-block']
  const isInValidBlock =
    !isInElement(editor, 'flip-card') &&
    !isInElement(editor, 'question-card') &&
    !isInElement(editor, 'vertical-stack')
  return isMatrixEnabled && isInValidBlock
}
