import { useFlagWithRefresh } from 'sierra-client/hooks/use-live-flag'

export const useMaxParticipantsInLive = (): number => {
  const { flag } = useFlagWithRefresh('backend-presence-for-live-sessions')
  if (flag) {
    return 500
  }

  return 180
}
