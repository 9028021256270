import { PrimitiveAtom, useSetAtom } from 'jotai'
import { FC, useEffect, useState } from 'react'
import { getCloudinaryUrlFromFileContentVideo } from 'sierra-client/api/content'
import { useUploadVideo } from 'sierra-client/hooks/use-video-upload'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { dynamic } from 'sierra-client/utils/dynamic'
import { ExistingNarration } from 'sierra-client/views/flexible-content/card-narration/existing-narration'
import { Layers } from 'sierra-client/views/flexible-content/card-narration/layers'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

const RecordingNarration = dynamic(
  () => import('sierra-client/views/flexible-content/card-narration/recording-narration')
)

type CardNarrationProps = {
  narrationStateAtom: PrimitiveAtom<boolean>
  createContentId: CreateContentId
  fileId: FileId
  assetContext: AssetContext
}

const CardNarration: FC<CardNarrationProps> = ({
  createContentId,
  fileId,
  narrationStateAtom,
  assetContext,
}) => {
  const { uploadVideo } = useUploadVideo()
  const { operationState } = useCreatePageContext()
  const card = useSelector(state => selectFlexibleContentFile(state, createContentId, fileId))
  const [firstLoad, setFirstLoad] = useState(true)

  const saveFile = async ({
    blob,
    createdBy,
  }: {
    blob: Blob
    createdBy: UserId | undefined
  }): Promise<void> => {
    const response = await uploadVideo(blob, assetContext)

    if (response !== undefined) {
      const { url, durationInSeconds } = response

      // To fix issue where Safari can't play narrations uploaded using Chrome we upload the video to cloudinary
      // and replace the url here.
      const cloudinaryUrl = getCloudinaryUrlFromFileContentVideo(url)

      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          file.narration = { url: cloudinaryUrl, durationInSeconds, createdBy }
        },
      })
    }
  }

  const deleteNarration = (): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        file.narration = undefined
      },
    })
  }

  const setNarrationState = useSetAtom(narrationStateAtom)
  useEffect(() => {
    if (!firstLoad) {
      setNarrationState(previous => !previous)
      setFirstLoad(false)
    }
  }, [card, firstLoad, setFirstLoad, setNarrationState])

  if (card === undefined) return null

  if (card.narration !== undefined) {
    return <ExistingNarration onDelete={deleteNarration} card={card} contentId={createContentId} />
  } else {
    return (
      <Layers.Container larger shadow={true} $position='absolute'>
        <RecordingNarration onSave={saveFile} card={card} />
      </Layers.Container>
    )
  }
}

// eslint-disable-next-line import/no-default-export
export default CardNarration
