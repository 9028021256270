import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import {
  ContentGroupsTable,
  ContentGroupsTableProps,
  ContentUsersTable,
  ContentUsersTableProps,
} from 'sierra-client/views/manage/components/content-tables'
import { Icon } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

type EmptySectionProps = {
  openEnrollment: () => void
  contentType?: 'course' | 'path'
  canEditAssignments: boolean
}

type EmptyGroupsSectionProps = {
  contentType?: 'course' | 'path'
}

const EmptyUserAndGroupSection: React.FC<EmptySectionProps> = ({ openEnrollment, canEditAssignments }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='none'>
      <Text size='large' bold>
        {t('content.enrolled')}
      </Text>
      <Spacer size='medium' />
      <EmptySection>
        <Icon iconId='user--group' size='size-16' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.courses.no-enrollments.title')}
        </Text>
        {canEditAssignments && (
          <>
            <Spacer size='4' />
            <EmptyBody size='small' align='center'>
              {t('manage.courses.no-enrollments.body')}
            </EmptyBody>
            <Spacer size='small' />
            <Button variant='ghost' onClick={openEnrollment}>
              {t('admin.organization.paths.enroll')}
            </Button>
          </>
        )}
      </EmptySection>
    </View>
  )
}

export const EmptyUserSection: React.FC<EmptySectionProps> = ({ openEnrollment, canEditAssignments }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='none'>
      <Text size='large' bold>
        {t('admin.analytics.learners')}
      </Text>
      <Spacer size='medium' />
      <EmptySection>
        <Icon iconId='user--group' size='size-16' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.courses.no-enrollments.title')}
        </Text>
        {canEditAssignments && (
          <>
            <Spacer size='4' />
            <EmptyBody size='small' align='center'>
              {t('manage.courses.no-learners.body')}
            </EmptyBody>
            <Spacer size='small' />
            <Button variant='ghost' onClick={openEnrollment}>
              {t('admin.organization.paths.enroll')}
            </Button>
          </>
        )}
      </EmptySection>
    </View>
  )
}

const EmptyGroupsSection: React.FC<EmptyGroupsSectionProps> = ({ contentType }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='none'>
      <Spacer size='xxlarge' />
      <Text size='large' bold>
        {t('dictionary.program-plural')}
      </Text>
      <Spacer size='medium' />
      <EmptySection>
        <Icon iconId='path' size='size-16' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.courses.no-programs.title')}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center'>
          {t('manage.courses.no-programs.body', { contentType: contentType })}
        </EmptyBody>
      </EmptySection>
    </View>
  )
}

type ContentEnrollmentSectionProps = ContentUsersTableProps &
  ContentGroupsTableProps & {
    contentType: 'course' | 'path'
    canEditAssignments: boolean
  }

export const ContentEnrollmentSection: React.FC<ContentEnrollmentSectionProps> = ({
  assignedUsers,
  assignedPrograms,
  contentId,
  fetchUserAssignments,
  openEnrollUsers,
  onRemoveUser,
  onSetUserDueDate,
  contentType,
  canEditAssignments,
}) => {
  const noAssignedUsers = assignedUsers.data.length === 0 && assignedUsers.filtered === false
  const noEnrollments = noAssignedUsers && assignedPrograms.length === 0
  const showEmptyUsers = noAssignedUsers && assignedPrograms.length > 0
  const showEmptyGroups = assignedPrograms.length === 0

  return (
    <View direction='column' gap='none'>
      {noEnrollments && (
        <EmptyUserAndGroupSection openEnrollment={openEnrollUsers} canEditAssignments={canEditAssignments} />
      )}
      {showEmptyUsers && (
        <EmptyUserSection openEnrollment={openEnrollUsers} canEditAssignments={canEditAssignments} />
      )}

      {!noAssignedUsers && (
        <ContentUsersTable
          contentId={contentId}
          assignedUsers={assignedUsers}
          onRemoveUser={onRemoveUser}
          openEnrollUsers={openEnrollUsers}
          fetchUserAssignments={fetchUserAssignments}
          onSetUserDueDate={onSetUserDueDate}
          contentType={contentType}
          canEditAssignments={canEditAssignments}
        />
      )}

      {showEmptyGroups && <EmptyGroupsSection contentType={contentType} />}
      {assignedPrograms.length > 0 && (
        <>
          <Spacer size='xxlarge' />
          <ContentGroupsTable assignedPrograms={assignedPrograms} />
        </>
      )}
    </View>
  )
}
