import fuzzysort from 'fuzzysort'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SetCalendarAttendanceInput } from 'sierra-client/api/graphql/gql/graphql'
import { getAvatarColor, getAvatarUrl } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { RouterLink } from 'sierra-client/components/common/link'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useCalendarEventPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { AssignedViaPill } from 'sierra-client/views/manage/users/components/content-assignment-components'
import { Avatar, BaseProgramInfoUser } from 'sierra-domain/api/manage'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { getUserName } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedTextWithTooltip, UserDisplay } from 'sierra-ui/components'
import { MenuItem } from 'sierra-ui/components/menu'
import { Button, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { z } from 'zod'

export const LearnerRow = z.object({
  data: z.object({
    id: UserId,
    firstName: z.string(),
    lastName: z.string(),
    avatar: Avatar,
    status: z.string(),
    email: z.string(),
    assignmentInfo: z.object({
      assignedAt: z.string().optional(),
      programs: BaseProgramInfoUser.array().optional(),
    }),
  }),
})

export type LearnerRow = z.infer<typeof LearnerRow>

export const getAttendanceStatusTranslationKey = (attendance: string): TranslationKey => {
  switch (attendance) {
    case 'ATTENDED':
      return 'manage.events.attended'
    case 'NOT_ATTENDED':
      return 'manage.events.did-not-attend'
    default:
      return 'dictionary.signed-up'
  }
}

export const getAttendanceCsvValue = (attendance: string): string => {
  switch (attendance) {
    case 'ATTENDED':
      return 'present'
    case 'NOT_ATTENDED':
      return 'absent'
    default:
      return ''
  }
}

const userTableRowToCsv = (row: LearnerRow): Record<string, string> => ({
  userId: row.data.id,
  email: row.data.email,
  firstName: row.data.firstName,
  lastName: row.data.lastName,
  attendance: getAttendanceCsvValue(row.data.status),
  assignedAt: row.data.assignmentInfo.assignedAt ?? '',
})

export const ManageAssignedLearnersTable: React.FC<{
  learners: LearnerRow[]
  showAssignModal: () => void
  showUnassignModal: (ids: UserId[]) => void
  showMarkAttendanceModal: () => void
  eventStartTime: DateTime
  deleteCalendarEventAttendance: (calendarEventId: CalendarEventId, attendeeId: UserId) => Promise<void>
  quickSetAttendanceStatus: (input: SetCalendarAttendanceInput) => Promise<void>
  calendarEventId: CalendarEventId
}> = ({
  showUnassignModal,
  learners,
  showAssignModal,
  showMarkAttendanceModal,
  eventStartTime,
  deleteCalendarEventAttendance,
  calendarEventId,
  quickSetAttendanceStatus,
}) => {
  const { t } = useTranslation()
  const [focusedUserId, setFocusedUserId] = useState<string | undefined>(undefined)
  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 500 })
  const calendarEventPermissions = useCalendarEventPermissions(calendarEventId)

  const changeStatusItems: (focusedUserId: UserId) => MenuItem[] = useCallback(
    focusedUserId => [
      {
        type: 'canvas',
        id: 'attended',
        render: () => {
          return (
            <View
              grow
              onClick={() =>
                quickSetAttendanceStatus({
                  attended: true,
                  attendeeId: focusedUserId,
                  calendarEventId: calendarEventId,
                })
              }
            >
              <Icon iconId='checkbox--checked' color={'greenBright'} />
              <Text size='small'>{t('manage.events.attended')}</Text>
            </View>
          )
        },
      },
      {
        type: 'canvas',
        id: 'didnt attend',
        render: () => {
          return (
            <View
              grow
              onClick={() =>
                quickSetAttendanceStatus({
                  attended: false,
                  attendeeId: focusedUserId,
                  calendarEventId: calendarEventId,
                })
              }
            >
              <Icon iconId='clean' color={'redBright'} />
              <Text size='small'>{t('manage.events.did-not-attend')}</Text>
            </View>
          )
        },
      },
    ],
    [calendarEventId, quickSetAttendanceStatus, t]
  )

  const filteredLearners = useMemo(() => {
    return fuzzysort
      .go(debouncedSearchTerm, learners, {
        all: true,
        keys: ['data.firstName', 'data.lastName', 'data.status', 'data.email'],
      })
      .map(({ obj }) => obj)
  }, [learners, debouncedSearchTerm])

  // Make assign button secondary after 30 minutes before event start time
  const setAssignButtonSecondary = DateTime.now() > eventStartTime.minus({ minute: 30 })

  const columns: Column<LearnerRow>[] = React.useMemo(
    () => [
      {
        id: 'select',
        accessor: event => event.data.id,
        Header: p => <SelectableHeader {...p} />,
        Cell: (p: CellProps<LearnerRow>) => <SelectableRow {...p} />,
        width: '5%',
      },
      {
        id: 'learnerId',
        Header: p => <SortableHeader label={t('table.learners')} smallLabel {...p} />,
        accessor: it => getUserName(it.data),
        Cell: (p: CellProps<LearnerRow>) => (
          <RouterLink href={`/manage/users/${p.row.original.data.id}`}>
            <UserDisplay
              primaryText={getUserName(p.row.original.data) ?? p.row.original.data.email}
              secondaryText={p.row.original.data.email}
              avatar={{
                color: getAvatarColor(p.row.original.data.avatar),
                src: getAvatarUrl(p.row.original.data.id, p.row.original.data.avatar),
                firstName: p.row.original.data.firstName,
                lastName: p.row.original.data.lastName,
                size: 'small',
              }}
            />
          </RouterLink>
        ),
        width: '25%',
      },
      {
        id: 'assigned-via',
        Header: p => <SortableHeader label={t('table.assigned-via')} smallLabel {...p} />,
        accessor: it => JSON.stringify(it.data.assignmentInfo),
        Cell: (p: CellProps<LearnerRow>) => {
          if (p.row.original.data.assignmentInfo.assignedAt === undefined) return null
          if (p.row.original.data.assignmentInfo.programs === undefined) return null

          return (
            <View>
              <AssignedViaPill
                assignmentData={{
                  assignedAt: p.row.original.data.assignmentInfo.assignedAt,
                  availableAt: p.row.original.data.assignmentInfo.assignedAt,
                  type: p.row.original.data.assignmentInfo.programs.length === 0 ? 'individual' : 'program',
                  programs: p.row.original.data.assignmentInfo.programs,
                }}
              />
            </View>
          )
        },
        width: '25%',
      },
      {
        id: 'status',
        Header: p => <SortableHeader label={t('table.status')} smallLabel {...p} />,
        accessor: it => it.data.status,
        Cell: (p: CellProps<LearnerRow>) => (
          <View>
            {p.row.original.data.status === 'ATTENDED' ? (
              <Icon iconId='checkbox--checkmark--filled' color='greenBright' />
            ) : p.row.original.data.status === 'NOT_ATTENDED' ? (
              <Icon iconId='checkbox--cross--filled' color='redVivid' />
            ) : null}
            <TruncatedTextWithTooltip
              color={
                p.row.original.data.status === 'NOT_REPORTED' ? 'foreground/muted' : 'foreground/primary'
              }
              size='small'
              bold
            >
              {t(getAttendanceStatusTranslationKey(p.row.original.data.status))}
            </TruncatedTextWithTooltip>
          </View>
        ),
        width: '25%',
      },
      {
        id: 'actions',
        width: '20%',
        disableSortBy: true,
        accessor: event => event.data,
        Cell: (p: CellProps<LearnerRow>) => {
          const [open, setOpen] = useState(false)

          useEffect(() => {
            if (open) {
              setFocusedUserId(p.row.original.data.id)
            } else {
              setFocusedUserId(undefined)
            }
          }, [open, p.row.original.data.id])

          const attendeId = p.row.original.data.id

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                label: t('manage.view-details'),
                id: 'view-details',
                onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${p.row.original.data.id}` }),
                icon: 'user',
              },
              {
                type: 'nested',
                label: t('dictionary.status'),
                id: 'teamspace',
                icon: 'edit',
                hidden: !calendarEventPermissions.has('TRACK_ATTENDANCE'),
                menuItems: changeStatusItems(p.row.original.data.id),
              },
              {
                type: 'label',
                label: t('dictionary.reset'),
                id: 'reset',
                icon: 'reset',
                hidden: !calendarEventPermissions.has('EDIT_ASSIGNMENTS'),
                disabled: p.row.original.data.status === 'NOT_REPORTED',
                onClick: () => deleteCalendarEventAttendance(calendarEventId, attendeId),
              },
              {
                type: 'label',
                label: t('dictionary.unassign'),
                id: 'unassign',
                icon: 'user--add',
                color: 'destructive/background',
                hidden: !calendarEventPermissions.has('EDIT_ASSIGNMENTS'),
                onClick: () => {
                  showUnassignModal([p.row.original.data.id])
                },
              },
            ]
          }, [attendeId, p.row.original.data.id, p.row.original.data.status])

          return (
            <>
              <View justifyContent='flex-end' grow>
                <IconMenu
                  iconId='overflow-menu--horizontal'
                  aria-label='Details'
                  variant='transparent'
                  onSelect={item => {
                    if ('onClick' in item) {
                      item.onClick?.()
                    }
                  }}
                  menuItems={menuItems}
                  isOpen={open}
                  onOpenChange={setOpen}
                />
              </View>
            </>
          )
        },
      },
    ],
    [
      t,
      calendarEventPermissions,
      changeStatusItems,
      deleteCalendarEventAttendance,
      calendarEventId,
      showUnassignModal,
    ]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: filteredLearners, columns },
    getEntityId: event => event.data.id,
  })

  return (
    <View direction='column' gap='16'>
      <Text size='large' bold>
        {t('table.learners')}
      </Text>
      <View>
        <RoundedSearchBar
          value={liveSearchTerm}
          onChange={value => {
            setSearchTerm(value)
          }}
          placeholder={t('admin.analytics.search-learner')}
        />
        {calendarEventPermissions.has('TRACK_ATTENDANCE') && (
          <Tooltip title={learners.length === 0 ? t('event-groups.attendance-no-users') : undefined}>
            <Button disabled={learners.length === 0} onClick={showMarkAttendanceModal}>
              {t('manage.events.mark-attendance')}
            </Button>
          </Tooltip>
        )}
        {calendarEventPermissions.has('EDIT_ASSIGNMENTS') && (
          <Button onClick={showAssignModal} variant={setAssignButtonSecondary ? 'secondary' : 'primary'}>
            {t('dictionary.assign')}
          </Button>
        )}
      </View>
      <ManageTableSmall
        focusedId={focusedUserId}
        tableInstance={tableInstance}
        getEntityId={event => event.data.id}
        hideSelect
        onViewDetails={() => {}}
        mapEntityToCsv={userTableRowToCsv}
        translations={{
          searchPlaceholder: t('search.sessions'),
          tableLoading: t('manage.sessions.table-loading'),
          tableNoResults: t('manage.users.no-results'),
          csvPrefix: t('content.sessions'),
        }}
      />
    </View>
  )
}
