import { HeaderView } from 'sierra-client/components/common/header-view'
import {
  ConfirmationModalProvider,
  useConfirmationModalContext,
} from 'sierra-client/components/common/modals/confirmation-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useDuplicateCourseVersionMutation } from 'sierra-client/editor/version-history/utils/duplicate-course-version'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { editUrl } from 'sierra-domain/api/editable-content'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { SerializedYDoc, serialize } from 'sierra-domain/collaboration/serialization'
import { safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { guardWith } from 'sierra-domain/utils'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { History } from 'sierra-domain/version-history/get-updates-meta'
import { replayUpdates } from 'sierra-domain/version-history/replay-updates'
import { Button } from 'sierra-ui/primitives'
import * as Y from 'yjs'

/**
 * We need to remove legacy and external notepads from the ydoc, since the history for these
 * live outside the ydoc and their states in the content ydoc does not necessarily reflect
 * their correct state at the point in time specified in version history.

 */
function serializeWithoutExternalNotepads(yDoc: Y.Doc): {
  serializedYDoc: SerializedYDoc
  didConvertNotepads: boolean
} {
  const serializedYDoc = serialize(yDoc)

  const previousSlateDocumentMap = serializedYDoc['']?.slateDocumentMap

  if (previousSlateDocumentMap === undefined) {
    // No need to update anything if there are no slate documents in the content

    return { serializedYDoc, didConvertNotepads: false }
  }

  const newSlateDocumentMap = { ...previousSlateDocumentMap }
  let didConvertNotepads = false
  for (const fileId of Object.keys(previousSlateDocumentMap)) {
    if (!guardWith(FileId, fileId)) {
      continue
    }
    const file = safeGetFile(yDoc, fileId)
    if (file === undefined) {
      continue
    }

    if (!(file.data.type === 'notepad' || file.data.type === 'external-notepad')) {
      continue
    }

    //  Reset notepads to an empty document
    didConvertNotepads = true
    newSlateDocumentMap[fileId] = {
      nodes: [createParagraph()],
      encoding: 'y-xml-text',
    }
  }

  return {
    serializedYDoc: {
      ...serializedYDoc,
      '': {
        ...serializedYDoc[''],
        slateDocumentMap: newSlateDocumentMap,
      },
    },
    didConvertNotepads,
  }
}

const DuplicateCurrentVersionButton: React.FC<{
  updates: History['updates']
  contentId: CreateContentId
  targetIndex: number
  isCurrentVersion: boolean
}> = ({ targetIndex, updates, contentId, isCurrentVersion }) => {
  const duplicateCourseVersionMutation = useDuplicateCourseVersionMutation()
  const notif = useNotif()
  const { t } = useTranslation()

  const confirmationModalContext = useConfirmationModalContext()

  const runDuplicationMutation = (serializedYDoc: SerializedYDoc): void => {
    void duplicateCourseVersionMutation.mutate(
      {
        serializedYDoc,
        courseId: contentId,
      },
      {
        onSuccess: async ({ kind, id }) => {
          const url = editUrl(kind, id)
          await getGlobalRouter().navigate({ to: url })
        },
        onError: e => {
          logger.error('Unable to duplicate course version', { error: e })
          notif.push({ type: 'error', body: 'Unable to duplicate course version' })
        },
      }
    )
  }

  const onDuplicateToCurrentVersion = (): void => {
    const { currentYDoc } = replayUpdates(updates, targetIndex)
    const { serializedYDoc, didConvertNotepads } = serializeWithoutExternalNotepads(currentYDoc)

    if (didConvertNotepads) {
      confirmationModalContext.show({
        title: 'version-history.notepads-not-supported.description',
        bodyText: 'version-history.notepads-not-supported.description',
        onConfirm: () => {
          runDuplicationMutation(serializedYDoc)
        },
      })
    } else {
      runDuplicationMutation(serializedYDoc)
    }
  }

  return (
    <Button disabled={isCurrentVersion} onClick={onDuplicateToCurrentVersion}>
      {t('version-history.duplicate-course')}
    </Button>
  )
}

export const VersionHistoryHeader: React.FC<{
  editHref: string
  contentId: CreateContentId
  updates: History['updates'] | undefined
  targetIndex: number
  assetContext: AssetContext
}> = ({ editHref, contentId, updates, targetIndex, assetContext }) => {
  const { t } = useTranslation()

  const isCurrentVersion = updates !== undefined && targetIndex === updates.length - 1

  return (
    <HeaderView
      rightContent={
        <>
          {updates && (
            <ConfirmationModalProvider>
              <DuplicateCurrentVersionButton
                isCurrentVersion={isCurrentVersion}
                targetIndex={targetIndex}
                updates={updates}
                contentId={contentId}
              />
            </ConfirmationModalProvider>
          )}
          <Button variant='secondary' href={editHref}>
            {t('dictionary.done')}
          </Button>
        </>
      }
      assetContext={assetContext}
    />
  )
}
