import React, { useEffect, useRef, useState } from 'react'
import {
  ConfirmationModalProvider,
  useConfirmationModalContext,
} from 'sierra-client/components/common/modals/confirmation-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { DropAWordDataLayer } from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { DropAWordMatterJsCard } from 'sierra-client/views/v3-author/drop-a-word/renderer/index'
import { PillDefinitionWithInitialPosition } from 'sierra-client/views/v3-author/drop-a-word/renderer/types'
import { getDropAWordBackgroundColor } from 'sierra-client/views/v3-author/drop-a-word/renderer/utils'
import { cohesionTodoT } from 'sierra-client/views/v3-author/question-card/question-responses'
import { UUID } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { ColorValue } from 'sierra-ui/color/types'
import { EditableText, Icon, RoundAvatar } from 'sierra-ui/components'
import { InputStyles, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider, token, useTokenValue } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled.div<{ $backgroundColor: ColorValue }>`
  position: relative;
  background-color: ${p => p.$backgroundColor};
  transition: background-color 100ms ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
`

const AddWordPosition = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

const AddWordInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;

  width: 348px;
  height: 44px;
  border-radius: 40px;
  padding: 14px;

  background: ${token('surface/default')};
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
`

const MAX_LENGTH = 20

const Input = styled.input.attrs({ type: 'text', maxLength: MAX_LENGTH })`
  ${InputStyles};
  height: auto;
  outline: 0 !important;
  padding: 0;
  border-radius: 0;

  flex: 1;
`

const RoundIconButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  border-radius: 99px;
  background-color: ${p => color(p.disabled === true ? 'grey25' : 'black')};

  cursor: pointer;
`

const AddWord = React.forwardRef<
  HTMLDivElement,
  {
    title: string | undefined
    onTitleChanged: ((title: string) => void) | undefined
    data: DropAWordDataLayer
  }
>(({ title, onTitleChanged, data }, ref) => {
  const { t } = useTranslation()
  const [word, setWord] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const user = useSelector(selectUser)
  const isAddWordDisabled = word.length === 0 // Text in P2 will throw if we add an empty string

  const addWord = (): void => {
    if (data.isInteractive === false) {
      return
    }

    data.onAddWord(word.trim())
    setWord('')
  }

  const [editingTitle, setEditingTitle] = useState(false)
  const titleRef = useRef<HTMLParagraphElement>(null)

  return (
    <AddWordPosition>
      <View direction='column' justifyContent='center' alignItems='center' gap='24'>
        <View direction='column' justifyContent='center' alignItems='center' gap='8'>
          <View
            onClick={() => {
              if (isDefined(onTitleChanged)) {
                setEditingTitle(true)
                titleRef.current?.focus()
              }
            }}
          >
            <EditableText
              ref={titleRef}
              aria-labelledby='editable-title-label'
              value={title === undefined || title === '' ? t('drop-a-word.title') : title}
              size='large'
              bold
              color='foreground/primary'
              onRename={str => onTitleChanged?.(str)}
              withSingleClick
              contentEditable={editingTitle && isDefined(onTitleChanged)}
              setContentEditable={setEditingTitle}
            />
          </View>

          <Text size='regular' bold color='foreground/muted'>
            {t('drop-a-word.subtitle')}
          </Text>
        </View>
        {data.isInteractive && (
          <View direction='column' justifyContent='flex-end' alignItems='stretch'>
            <LightTokenProvider>
              <AddWordInputContainer
                ref={ref}
                onClick={() => {
                  inputRef.current?.focus()
                }}
              >
                <RoundAvatar
                  size='minuscule'
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  src={user !== undefined ? getAvatarImage(user.uuid, user.avatar) : undefined}
                  color={user?.avatarColor}
                />
                <Input
                  value={word}
                  placeholder={t('drop-a-word.prompt')}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      addWord()
                    }
                  }}
                  onChange={e => setWord(e.target.value.trimStart())}
                  ref={inputRef}
                />
                {word.length > 0 && (
                  <View justifyContent='flex-end' marginRight='6'>
                    <Text size='technical' bold color='foreground/muted'>
                      {word.length} / {MAX_LENGTH}
                    </Text>
                  </View>
                )}
                <RoundIconButton
                  onClick={e => {
                    e.stopPropagation()
                    addWord()
                  }}
                  disabled={isAddWordDisabled}
                >
                  <Icon iconId='arrow--down' color='white' size='size-12' />
                </RoundIconButton>
              </AddWordInputContainer>
            </LightTokenProvider>
          </View>
        )}
      </View>
    </AddWordPosition>
  )
})

const PlaceholderContainer = styled.div`
  position: absolute;
  height: fit-content;
  width: max-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const EmptyPlaceholder: React.FC = () => {
  return (
    <View direction='column' gap='24'>
      <Icon iconId='arrow-down--filled' size='size-20' color='foreground/muted' />
      <View direction='column' gap='4'>
        <Text color='foreground/secondary' size='regular' bold align='center'>
          {cohesionTodoT('No responses yet')}
        </Text>
        <Text color='foreground/muted' size='small' align='center'>
          {cohesionTodoT('Submitted responses will appear here')}
        </Text>
      </View>
    </View>
  )
}

const DropAWordMatterJs: React.FC<{
  title: string | undefined
  onTitleChanged: ((title: string) => void) | undefined
  data: DropAWordDataLayer
}> = ({ title, onTitleChanged, data }) => {
  const { t } = useTranslation()
  const notif = useNotif()
  const confirmationModalContext = useConfirmationModalContext()
  const { pills } = data
  const deletePillWithIdWithConfirmation = useStableFunction((id: UUID) => {
    confirmationModalContext.show({
      bodyText: t('content.delete-button'),
      confirmLabel: t('content.delete-button'),
      deleteAction: true,
      onConfirm: () => {
        if (data.isInteractive) {
          data.deletePillWithId(id)
        } else {
          notif.push({ type: 'error' })
        }
      },
    })
  })

  const addWordRef = useRef<HTMLDivElement | null>(null)

  const [pillDefinitionsWithInitialPositions, setPillDefinitionsWithInitialPositions] = useState<
    PillDefinitionWithInitialPosition[]
  >(() => {
    // The pills that we receive when we first render will be randomly placed, and subsequent pills
    // will be placed in the same position as the word input
    return (pills ?? []).map(
      (pill): PillDefinitionWithInitialPosition => ({
        ...pill,
        initialPosition: 'random',
      })
    )
  })

  // Place pills received after the first render in the same position as the word input
  useEffect(() => {
    setPillDefinitionsWithInitialPositions(oldState => {
      const newState: PillDefinitionWithInitialPosition[] = []

      const oldPillIds = new Set(oldState.map(pill => pill.id))
      for (const pill of pills ?? []) {
        if (oldPillIds.has(pill.id)) {
          newState.push({ ...pill, initialPosition: 'random' })
        } else {
          const boundingBox = addWordRef.current?.getBoundingClientRect()
          newState.push({
            ...pill,
            initialPosition:
              boundingBox === undefined
                ? 'random'
                : {
                    x: boundingBox.left + boundingBox.width / 2,
                    y: boundingBox.top + boundingBox.height / 2,
                    angle: Math.random() * 0.2 - 0.1,
                  },
          })
        }
      }

      return newState
    })
  }, [pills])

  const editOrResponsesState = useEditOrResponsesStateSafe()

  return (
    <>
      <DropAWordMatterJsCard
        pillDefinitionsWithInitialPositions={pillDefinitionsWithInitialPositions}
        deletePillWithId={deletePillWithIdWithConfirmation}
        canDeletePillWithId={id => {
          if (data.isInteractive) {
            return data.canDeletePillWithId(id)
          } else {
            return false
          }
        }}
      >
        <AddWord ref={addWordRef} title={title} onTitleChanged={onTitleChanged} data={data} />
      </DropAWordMatterJsCard>
      {pills === undefined ? (
        <PlaceholderContainer>
          <LoadingSpinner />
        </PlaceholderContainer>
      ) : pills.length === 0 && editOrResponsesState?.type === 'responses' ? (
        <PlaceholderContainer>
          <EmptyPlaceholder />
        </PlaceholderContainer>
      ) : null}
    </>
  )
}

export const DropAWord: React.FC<{
  title: string | undefined
  onTitleChanged: ((title: string) => void) | undefined
  data: DropAWordDataLayer
}> = props => {
  const getToken = useTokenValue()
  const backgroundColor = getDropAWordBackgroundColor(getToken('surface/default'))

  return (
    <Wrapper $backgroundColor={backgroundColor}>
      <ConfirmationModalProvider>
        <DropAWordMatterJs {...props} />
      </ConfirmationModalProvider>
    </Wrapper>
  )
}
