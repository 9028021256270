import { File } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'

export type ScenarioFile = File & { data: { type: 'scenario' } }
export function assertScenarioCard(file: File): asserts file is ScenarioFile {
  assert(file.data.type === 'scenario')
}

export const replaceOrAdd = <T>(array: Array<T> | undefined, item: T, compF: (_: T) => boolean): Array<T> => {
  if (!array) return [item]
  const index = array.findIndex(compF)
  if (index !== -1) {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
  }
  return [...array, item]
}
