import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  GetLearnerProgramQuery,
  GetProgramQuery,
  GetProgramQueryVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { getGraphQueryKey, graphQueryFn, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const getLearnerProgram = graphql(`
  query GetLearnerProgram($programId: ProgramId!) {
    program(id: $programId, version: null) {
      id
      name
      description
      duration
      image {
        ...ImageFragment
      }
      visibility
      sections {
        id
        title
        description
      }
      steps {
        __typename
        sectionIndex

        schedule {
          __typename
          ... on AbsoluteDate {
            __typename
            date
          }
          ... on Directly {
            __typename
            isDirectly
          }
          ... on OnCompletionOfPrevious {
            __typename
            offset {
              value
              ... on Days {
                __typename
                value
              }
            }
          }
          ... on RelativeDate {
            __typename
            offset {
              value
              ... on Days {
                __typename
                value
              }
            }
          }
        }

        ... on ContentStep {
          content {
            __typename
            duration
            title
            description
            image {
              ...ImageFragment
            }
            ... on LinkCourse {
              url
            }
            ... on LinkedInCourse {
              url
            }
            ... on NativeEventGroup {
              __typename
              upcomingSelfEnrollmentCalendarEvents: calendarEvents(
                input: { isUpcoming: true, isSelfEnrollable: true }
              ) {
                ...ProgramPageSelfEnrollCalendarEvent
              }
              assignedCalendarEvents: calendarEvents(input: { isAssigned: true }) {
                id
                schedule {
                  ...CalendarEventScheduleFragment
                }
              }
            }
          }
        }
        ... on CourseProgramStep {
          courseId
          course {
            courseId
            title
            courseKind
          }
        }
        ... on PathProgramStep {
          pathId
          path {
            pathId
            courses {
              courseId
              title
              image {
                ...ImageFragment
              }
              duration
              courseKind
            }
          }
        }
        ... on EmailProgramStep {
          resourceId
          title
          image {
            ...ImageFragment
          }
        }
      }
    }
    me {
      enrollment(programId: $programId) {
        program {
          id
          name
          description
          duration
          image {
            ...ImageFragment
          }
          visibility
          sections {
            id
            title
            description
          }
          steps {
            sectionIndex
            __typename

            schedule {
              __typename
              ... on AbsoluteDate {
                __typename
                date
              }
              ... on Directly {
                __typename
                isDirectly
              }
              ... on OnCompletionOfPrevious {
                __typename
                offset {
                  value
                  ... on Days {
                    __typename
                    value
                  }
                }
              }
              ... on RelativeDate {
                __typename
                offset {
                  value
                  ... on Days {
                    __typename
                    value
                  }
                }
              }
            }

            ... on ContentStep {
              content {
                __typename
                duration
                title
                description
                image {
                  ...ImageFragment
                }
                ... on LinkCourse {
                  url
                }
                ... on LinkedInCourse {
                  url
                }
                ... on NativeEventGroup {
                  __typename
                  upcomingSelfEnrollmentCalendarEvents: calendarEvents(
                    input: { isUpcoming: true, isSelfEnrollable: true }
                  ) {
                    ...ProgramPageSelfEnrollCalendarEvent
                  }
                  assignedCalendarEvents: calendarEvents(input: { isAssigned: true }) {
                    id
                    schedule {
                      ...CalendarEventScheduleFragment
                    }
                  }
                }
              }
            }
            ... on CourseProgramStep {
              courseId
              course {
                courseId
                title
                courseKind
              }
            }
            ... on PathProgramStep {
              pathId
              path {
                pathId
                courses {
                  courseId
                  title
                  image {
                    ...ImageFragment
                  }
                  duration
                  courseKind
                }
              }
            }
            ... on EmailProgramStep {
              resourceId
              title
              image {
                ...ImageFragment
              }
            }
          }
        }
        nextStep {
          __typename
          ... on UserProgramCourseStep {
            courseId
          }
          ... on UserProgramPathStep {
            pathId
          }
        }
        progress {
          lastProgressAt
          passedAt
          progress
          startedAt
          timeLeft
        }
        steps {
          __typename
          dueDate
          availableAt
          content {
            __typename
            duration
            title
            description
            ... on NativeSelfPacedCourse {
              exercises {
                fileId
                grade
              }
            }
            ... on NativeCourseGroup {
              exercises {
                fileId
                grade
              }
            }

            image {
              ...ImageFragment
            }
            ... on LinkCourse {
              url
            }
            ... on LinkedInCourse {
              url
            }
            ... on NativeEventGroup {
              __typename
              upcomingSelfEnrollmentCalendarEvents: calendarEvents(
                input: { isUpcoming: true, isSelfEnrollable: true }
              ) {
                ...ProgramPageSelfEnrollCalendarEvent
              }
              assignedCalendarEvents: calendarEvents(input: { isAssigned: true }) {
                id
                schedule {
                  ...CalendarEventScheduleFragment
                }
              }
            }
          }
          ... on UserProgramPathStep {
            pathId
            progress {
              lastProgressAt
              passedAt
              progress
              startedAt
              timeLeft
            }
            path {
              sequentialLearning
            }
            currentStep {
              contentId
            }
            subSteps {
              __typename
              courseId
              contentId
              progress {
                lastProgressAt
                passedAt
                progress
                startedAt
                timeLeft
              }
              content {
                __typename
                duration
                title
                description

                ... on NativeSelfPacedCourse {
                  exercises {
                    fileId
                    grade
                  }
                }

                ... on NativeCourseGroup {
                  exercises {
                    fileId
                    grade
                  }
                }

                image {
                  ...ImageFragment
                }
                ... on LinkCourse {
                  url
                }
                ... on LinkedInCourse {
                  url
                }
                ... on NativeEventGroup {
                  __typename
                  upcomingSelfEnrollmentCalendarEvents: calendarEvents(
                    input: { isUpcoming: true, isSelfEnrollable: true }
                  ) {
                    id
                    ...ProgramPageSelfEnrollCalendarEvent
                  }
                  assignedCalendarEvents: calendarEvents(input: { isAssigned: true }) {
                    id
                    schedule {
                      ...CalendarEventScheduleFragment
                    }
                  }
                }
              }
              upcomingSelfEnrollmentLiveSessions {
                isEnrolled
                usedSpots
                liveSession {
                  liveSessionId
                  title
                  totalSpots
                  startTime
                  endTime
                  allDay
                  location {
                    __typename
                    ... on Physical {
                      value
                    }
                  }
                }
              }
            }
          }
          ... on UserProgramEmailStep {
            resourceId
            availableAt
            title
            image {
              ...ImageFragment
            }
          }
          ... on UserProgramCourseStep {
            courseId

            progress {
              lastProgressAt
              passedAt
              progress
              startedAt
              timeLeft
            }
            assignedLiveSessions {
              liveSession {
                location {
                  __typename
                  ... on Physical {
                    value
                  }
                }
                __typename
                ... on NotScheduledLiveSession {
                  liveSessionId
                  title
                  totalSpots
                }
                ... on ScheduledLiveSession {
                  liveSessionId
                  title
                  totalSpots
                  startTime
                  endTime
                  allDay
                }
              }
            }
            upcomingSelfEnrollmentLiveSessions {
              isEnrolled
              usedSpots
              liveSession {
                liveSessionId
                title
                totalSpots
                startTime
                endTime
                allDay
                location {
                  __typename
                  ... on Physical {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)

const getProgram = graphql(`
  query GetProgram($programId: ProgramId!, $programVersion: Int) {
    program(id: $programId, version: $programVersion) {
      id
      name
      description
      duration
      image {
        ...ImageFragment
      }
      visibility
      sections {
        id
        title
        description
      }
      steps {
        __typename
        sectionIndex

        schedule {
          __typename
          ... on AbsoluteDate {
            __typename
            date
          }
          ... on Directly {
            __typename
            isDirectly
          }
          ... on OnCompletionOfPrevious {
            __typename
            offset {
              value
              ... on Days {
                __typename
                value
              }
            }
          }
          ... on RelativeDate {
            __typename
            offset {
              value
              ... on Days {
                __typename
                value
              }
            }
          }
        }

        ... on ContentStep {
          content {
            __typename
            duration
            title
            description
            image {
              ...ImageFragment
            }
            ... on LinkCourse {
              url
            }
            ... on LinkedInCourse {
              url
            }
            ... on NativeEventGroup {
              __typename
              upcomingSelfEnrollmentCalendarEvents: calendarEvents(
                input: { isUpcoming: true, isSelfEnrollable: true }
              ) {
                ...ProgramPageSelfEnrollCalendarEvent
              }
              assignedCalendarEvents: calendarEvents(input: { isAssigned: true }) {
                id
                schedule {
                  ...CalendarEventScheduleFragment
                }
              }
            }
          }
        }
        ... on CourseProgramStep {
          courseId
          course {
            courseId
            title
            courseKind
          }
        }
        ... on PathProgramStep {
          pathId
          path {
            pathId
            courses {
              courseId
              title
              image {
                ...ImageFragment
              }
              duration
              courseKind
            }
          }
        }
        ... on EmailProgramStep {
          resourceId
          title
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
`)

export const selfEnrollToProgramMutation = graphql(`
  mutation SelfEnrollToProgram($programId: ProgramId!) {
    selfEnrollToProgram(programId: $programId) {
      __typename
    }
  }
`)

export const getLearnerProgramQuery = (programId: string): UseQueryOptions<GetLearnerProgramQuery> => {
  return {
    queryKey: getGraphQueryKey(getLearnerProgram, { programId }),
    queryFn: () => graphQueryFn(getLearnerProgram, { programId }),
  }
}

export const useGetLearnerProgram = ({
  programId,
}: {
  programId: string
}): UseQueryResult<GetLearnerProgramQuery, unknown> => {
  // Conversions would be made here

  const query = getLearnerProgramQuery(programId)
  const queryResult = useQuery(query)
  return queryResult
}

export const useGetProgram = ({
  programId,
  programVersion,
}: {
  programId: string
  programVersion?: number
}): UseQueryResult<GetProgramQuery, unknown> => {
  return useGraphQuery<GetProgramQuery, GetProgramQueryVariables>(
    { document: getProgram },
    { programId, programVersion: programVersion ?? null }
  )
}
