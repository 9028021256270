import { UserInvitationDomains } from 'sierra-domain/api/manage'
import { UserAttributesConfig } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { UserInvitationDomainRef } from 'sierra-domain/user-attributes/user-invitation-domain-ref'
import {
  UserAccessLevelDomainRep,
  UserAccessRoleDomainRep,
  UserCustomAttributeDomainRep,
  UserGroupsDomainRep,
  UserManagerDomainRep,
  UserProgramsDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { assertIsNonNullable, guardWith } from 'sierra-domain/utils'

export function extractUserAccessLevelDomain(
  userInvitationDomains: UserInvitationDomains
): UserAccessLevelDomainRep | undefined {
  return userInvitationDomains.domains.find(guardWith(UserAccessLevelDomainRep))
}

export function extractUserAccessRoleDomain(
  userInvitationDomains: UserInvitationDomains
): UserAccessRoleDomainRep | undefined {
  return userInvitationDomains.domains.find(guardWith(UserAccessRoleDomainRep))
}

export function extractUserGroupsDomain(
  userInvitationDomains: UserInvitationDomains
): UserGroupsDomainRep | undefined {
  return userInvitationDomains.domains.find(guardWith(UserGroupsDomainRep))
}

export function extractUserProgramsDomain(
  userInvitationDomains: UserInvitationDomains
): UserProgramsDomainRep | undefined {
  return userInvitationDomains.domains.find(guardWith(UserProgramsDomainRep))
}

export function extractUserManagerDomain(
  userInvitationDomains: UserInvitationDomains
): UserManagerDomainRep | undefined {
  return userInvitationDomains.domains.find(guardWith(UserManagerDomainRep))
}

export function extractUserCustomAttributeDomains(
  userInvitationDomains: UserInvitationDomains
): UserCustomAttributeDomainRep[] {
  return userInvitationDomains.domains.filter(guardWith(UserCustomAttributeDomainRep))
}

export function createInvitationAttribute(
  ref: UserInvitationAttribute['ref'],
  values: UserInvitationAttribute['values']
): UserInvitationAttribute {
  return {
    ref,
    values,
  }
}

export function equalsAttributeRef(aRef: UserInvitationDomainRef, bRef: UserInvitationDomainRef): boolean {
  const hasSameType = aRef.type === bRef.type
  const hasTheSameKeyIfAvailable = 'key' in aRef && 'key' in bRef ? aRef.key === bRef.key : true

  return hasSameType && hasTheSameKeyIfAvailable
}

export function findAttributeIndex(
  attributes: UserInvitationAttribute[],
  ref: UserInvitationDomainRef
): number {
  return attributes.findIndex(a => equalsAttributeRef(a.ref, ref))
}

export function findAttribute(
  attributes: UserInvitationAttribute[],
  ref: UserInvitationDomainRef
): UserInvitationAttribute | undefined {
  return attributes.find(a => equalsAttributeRef(a.ref, ref))
}

export function getAttribute(
  domainRef: UserInvitationDomainRef,
  email: string,
  config: UserAttributesConfig
): UserInvitationAttribute | undefined {
  const emailConfig = config[email]

  assertIsNonNullable(
    emailConfig,
    `Could not find email config for user ${email} when fetching attribute ${JSON.stringify(domainRef)}.`
  )

  return findAttribute(emailConfig.data, domainRef)
}
