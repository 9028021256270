import { atom } from 'jotai'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { TableRowData } from 'sierra-client/lib/tabular/to-table-row-data'
import { ColumnRef, Position } from 'sierra-client/lib/tabular/types'

export type VirtualColumn<Data extends TableData = TableData> = {
  ref: ColumnRef
  header: (api: TableAPI<Data>) => Header
  cell: (pos: Position, api: TableAPI<Data>, td: Data) => Cell<Data>
}

export type VirtualColumns<TD extends TableData = TableData> = {
  left: VirtualColumn<TD>[]
  right: VirtualColumn<TD>[]
}

const manifestVirtualColumnCell = <Data extends TableData = TableData>(
  pos: Position,
  api: TableAPI<Data>,
  td: Data,
  virtualColumn: VirtualColumn<Data>
): Cell<Data> => virtualColumn.cell(pos, api, td)

const manifestNestedRowVirtualColumns = <Data extends TableData = TableData>(
  rows: Row.Row<Data>[],
  virtual: VirtualColumns<Data>,
  api: TableAPI<Data>,
  td: Data
): Row.Row<Data>[] =>
  rows.flatMap(row => {
    if (row.type !== 'Row.Flat') return []
    return Row.createRow({
      type: 'Row.Flat',
      ref: row.ref,
      cells: [
        ...virtual.left.map(
          (): Cell =>
            ({
              type: 'empty',
              hints: ['nested-row-left'],
              pos: { row: 'virtual', column: 'virtual' },
              selected: atom(false),
              enabled: atom(true),
            }) satisfies Cell
        ),
        ...row.cells,
        ...virtual.right.map(v => {
          return manifestVirtualColumnCell({ column: v.ref, row: row.ref }, api, td, v)
        }),
      ],
    })
  })

export const withVerticalColumns = <Data extends TableData = TableData>(
  rows: TableRowData<Data>[],
  api: TableAPI<Data>,
  td: Data,
  virtual: VirtualColumns<Data>
): TableRowData<Data>[] => {
  return rows.map(
    (rows): TableRowData<Data> =>
      rows.map(row => {
        switch (row.type) {
          case 'Row.Flat':
            return Row.createRow({
              type: 'Row.Flat',
              ref: row.ref,
              cells: [
                ...virtual.left.map(v =>
                  manifestVirtualColumnCell({ column: v.ref, row: row.ref }, api, td, v)
                ),
                ...row.cells,
                ...virtual.right.map(v =>
                  manifestVirtualColumnCell({ column: v.ref, row: row.ref }, api, td, v)
                ),
              ],
            })
          case 'Row.Nested':
            return Row.createRow({
              type: 'Row.Nested',
              ref: row.ref,
              expanded: row.expanded,
              parent: [
                ...virtual.left.map(v =>
                  manifestVirtualColumnCell({ column: v.ref, row: row.ref }, api, td, v)
                ),
                ...row.parent,
                ...virtual.right.map(v =>
                  manifestVirtualColumnCell({ column: v.ref, row: row.ref }, api, td, v)
                ),
              ],
              children: manifestNestedRowVirtualColumns(row.children, virtual, api, td),
            })
        }
      })
  )
}
