import { createFileRoute } from '@tanstack/react-router'
import { queryClient } from 'sierra-client/api/query-client'
import { getFlag } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { EventGroupPage, getEventGroupQuery } from 'sierra-client/views/learner/event-group/event-group-page'
import { EventGroupId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

const RouteComponent = (): JSX.Element => {
  const eventGroupId = Route.useParams({ select: params => params.eventGroupId })
  return <EventGroupPage eventGroupId={eventGroupId} />
}

export const Route = createFileRoute('/event/$eventGroupId/')({
  component: requireLoggedIn(RouteComponent) as React.FC,
  params: {
    parse: z.object({ eventGroupId: EventGroupId }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (!context.isLoggedIn) return
    if (!getFlag('preloading')) return

    const eventGroupQuery = getEventGroupQuery(params.eventGroupId)
    void queryClient.prefetchQuery(eventGroupQuery)
  },
})
