import { createFileRoute } from '@tanstack/react-router'

import { useEffect, useMemo, useRef, useState } from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { config, getFlag } from 'sierra-client/config/global-config'
import { editorAccessed } from 'sierra-client/core/logging/authoring/logger'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { GlobalNestedBottomComponent, GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { prefetchPermissions } from 'sierra-client/hooks/use-permissions'
import { useRequiredRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { fetchCourseSettings } from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { store } from 'sierra-client/state/store'
import { FCC } from 'sierra-client/types'
import { CreatePageCombinedContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CardLocation,
  CardPopperContextProvider,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/card-location-context'
import {
  CreatePageGlobalBottomComponent,
  CreatePageGlobalSidebar,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-global-sidebar'
import { CreatePageFull } from 'sierra-client/views/v3-author/create-page-full'
import { CreateContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { isNotDefined } from 'sierra-domain/utils'
import { z } from 'zod'

function PageWithPermission({ contentId }: { contentId: CreateContentId }): JSX.Element {
  const dispatch = useDispatch()

  const windowLocationHref = new URL(window.location.href)
  const contentLink = `${windowLocationHref.origin}/create/s/${contentId}`

  // Segment logging for editor accessed
  useEffect(() => {
    void dispatch(editorAccessed({ contentId, contentType: 'self-paced', contentLink }))
  }, [contentLink, contentId, dispatch])

  return (
    <>
      <CreatePageFull />
      <ShortcutMenu.Action
        label='shortcut-menu.default.go-to-self-paced'
        run={() => {
          const hrefWithoutCreate = window.location.href.replace('/create/', '/')
          const url = new URL(hrefWithoutCreate)

          const host = config.auth.host
          if (host !== undefined) url.searchParams.set('x-host', host)

          window.open(url, '_blank')
        }}
        group='create'
        iconId='play--circle--filled'
        permission='ACCESS_EDITOR'
      />
    </>
  )
}

export const CardPopperManager: FCC = ({ children }) => {
  const [createCardLocation, setCreateCardLocation] = useState<CardLocation>(undefined)
  const [cardPopperOpen, setCardPopperOpen] = useState(false)

  const addCardMenuButtonRef = useRef<HTMLDivElement>(null)

  const contextValue = useMemo(() => {
    return {
      createCardLocation,
      setCreateCardLocation,
      cardPopperOpen,
      setCardPopperOpen,
      ref: addCardMenuButtonRef,
    }
  }, [cardPopperOpen, createCardLocation])

  return <CardPopperContextProvider value={contextValue}>{children}</CardPopperContextProvider>
}

function Page(): JSX.Element {
  const { contentId } = useRequiredRouterEditorIds()

  return (
    <CreatePageCombinedContext>
      <RequireCoursePermission contentId={contentId} showSpinner>
        <PageWithPermission contentId={contentId} />
      </RequireCoursePermission>
      <CardPopperManager>
        <GlobalNestedSidebar key={contentId} renderSidebar={() => <CreatePageGlobalSidebar />} />
        <GlobalNestedBottomComponent renderBottomContent={() => <CreatePageGlobalBottomComponent />} />
      </CardPopperManager>
    </CreatePageCombinedContext>
  )
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/create/s/$')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    'chat_message': z.string().optional().catch(undefined),
    'request-access': UserId.optional().catch(undefined),
  }),
  loader: ({ context, params }) => {
    if (!context.isLoggedIn) return
    if (!getFlag('preloading')) return

    const [potentialSelfPacedContentId] = (params._splat ?? '').split('/')
    if (isNotDefined(potentialSelfPacedContentId)) return

    const selfPacedContentId = SelfPacedContentId.parse(potentialSelfPacedContentId)
    void store.dispatch(fetchCourseSettings({ courseId: selfPacedContentId }))

    void prefetchPermissions('content-kind', { id: selfPacedContentId })
  },
})
