import { fonts } from 'sierra-ui/theming/fonts'
/* eslint-disable react/forbid-component-props */
import { default as React, useEffect, useMemo, useRef, useState } from 'react'
import { DebugData } from 'sierra-client/editor/debug/debug-data'
import { printTree } from 'sierra-client/editor/utils/print-tree'
import { AbsoluteRect } from 'sierra-client/features/react-debug-mode'
import { useEditorMode } from 'sierra-client/views/v3-author/context'
import { SerializedDOMRect, serializeDomRect } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { View } from 'sierra-ui/primitives'
import { Editor, Element, Node, Path, Text as SlateText, Text } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { default as styled } from 'styled-components'

const DebugNodeWrapper = styled.div`
  position: absolute;
  text-align: right;
  font-size: 12px;
  background-color: ${color('rgb(219, 112, 147)').opacity(0.75)};
  border-radius: 10px;
  color: white;
  padding: 4px;
  font-weight: ${fonts.weight.bold};
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
`

const DebugNode: React.FC<{ node: Node; path: Path }> = ({ node, path }) => {
  const title = Text.isText(node)
    ? 'text'
    : Editor.isEditor(node)
      ? 'editor'
      : Element.isElement(node)
        ? node.type
        : 'unknown'

  const ref = useRef<HTMLDivElement | null>(null)
  const height = ref.current?.clientHeight ?? 0
  return (
    <DebugNodeWrapper
      ref={ref}
      style={{
        top: 0 - height - 1,
        right: 0,
      }}
    >
      <View direction='column' gap='4'>
        <small>{title}</small>
        <small>{JSON.stringify(path)}</small>
      </View>
    </DebugNodeWrapper>
  )
}

export function* domNodePath(target: HTMLElement | null): Iterable<HTMLElement> {
  while (target) {
    yield target
    target = target.parentElement
  }
}

const DebugPortal = styled.div``

export const SlateDebugMode: React.FC<{ container: HTMLElement }> = ({ container }) => {
  const editor = useSlateStatic()
  const [target, setTarget] = useState<{ domNode: HTMLElement; node: Node; path: Path } | undefined>(
    undefined
  )

  useEffect(() => {
    function onMouseDown(e: MouseEvent): void {
      if (!e.shiftKey) return

      setTarget(target => {
        // This console log is intentional, the element should be logged to the console at this point so that it can be interacted with
        const node = target?.node
        if (node) printTree(node)
        // eslint-disable-next-line no-console
        console.log(target)
        return target
      })
    }

    function onMouseOver(e: MouseEvent): void {
      const domNode = Array.from(domNodePath(e.target as HTMLElement)).find(
        it => 'data-slate-node' in it.attributes
      )
      if (domNode === undefined) return

      try {
        const node = ReactEditor.toSlateNode(editor, domNode)
        const path = ReactEditor.findPath(editor, node)
        setTarget({ domNode, node, path })
      } catch (e) {
        console.debug('Failed to find dom node with error', e)
      }
    }

    container.addEventListener('mousedown', onMouseDown)
    container.addEventListener('mouseover', onMouseOver)
    return () => {
      container.removeEventListener('mouseover', onMouseOver)
      container.removeEventListener('mousedown', onMouseDown)
    }
  }, [container, editor])

  const rects = useMemo<SerializedDOMRect[]>(() => {
    if (target === undefined) return []
    if (Element.isElement(target.node)) {
      return Array.from(target.domNode.childNodes)
        .map(it => it as HTMLElement)
        .flatMap(it => {
          if ('getBoundingClientRect' in it) {
            return [it.getBoundingClientRect()]
          } else return []
        })
        .map(serializeDomRect)
    } else {
      return [serializeDomRect(target.domNode.getBoundingClientRect())]
    }
  }, [target])

  const containerRect = serializeDomRect(container.getBoundingClientRect())
  const mode = useEditorMode()
  return (
    <>
      <DebugPortal>
        {target &&
          rects.map((rect, i) => (
            <AbsoluteRect
              key={i}
              {...rect}
              top={rect.top - containerRect.top}
              left={rect.left - containerRect.left}
              outline={color('rgb(219, 112, 147)').opacity(0.75).shift(0.5).toString()}
              borderRadius='10px'
              outlineStyle={SlateText.isText(target.node) ? 'dotted' : 'dashed'}
            >
              {i === 0 && <DebugNode node={target.node} path={target.path} />}
            </AbsoluteRect>
          ))}
      </DebugPortal>
      <DebugData mode={mode} />
    </>
  )
}
