import { ElementExtended } from 'sierra-client/views/v3-author/export-pdf/types'
import { UserExternalTraining, UserTranscriptCourse, UserTranscriptResponse } from 'sierra-domain/api/manage'

const resolveCourseStatusModule = (course: UserTranscriptCourse): ElementExtended => {
  return {
    id: course.id,
    type: 'course-status',
    course: course,
  }
}

const resolveExternalTraining = (externalTraining: UserExternalTraining): ElementExtended => {
  return {
    id: externalTraining.title,
    type: 'external-training-status',
    externalTraining: externalTraining,
  }
}

export const resolvePrintableTranscriptElements = (transcript: UserTranscriptResponse): ElementExtended[] => {
  const elements: ElementExtended[] = []

  elements.push({
    id: transcript.user.email,
    type: 'user-info',
    user: transcript.user,
  })
  elements.push(...transcript.courses.map(course => resolveCourseStatusModule(course)))
  elements.push(
    ...transcript.externalTrainings.map(externalTraining => resolveExternalTraining(externalTraining))
  )

  return elements
}
