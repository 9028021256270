import { createFileRoute } from '@tanstack/react-router'
import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import {
  DashboardErrorBoundaryComponent,
  InsightsDashboard,
  dashboardsMetaAtom,
  latestVisitedDashboardAtom,
  prefetchGetDashboard,
} from 'sierra-client/features/insights'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import { z } from 'zod'

const ViewWrapper = styled(View).attrs({ direction: 'column', grow: true, gap: 'none' })`
  height: 100%;
  width: 100%;
`

const InsideErrorBoundary: React.FC<{ dashboardId: NanoId12 }> = ({ dashboardId }) => {
  const insightsEnabled = useInsightsEnabled()
  const setLatestVisitedDashboard = useSetAtom(latestVisitedDashboardAtom)
  const setDashboardsMeta = useSetAtom(dashboardsMetaAtom)
  const newInsightsHome = getFlag('insights/new-home')
  const { t } = useTranslation()

  useEffect(() => {
    setLatestVisitedDashboard(dashboardId)
  }, [dashboardId, setLatestVisitedDashboard])

  useEffect(() => {
    setDashboardsMeta(current => ({
      ...current,
      [dashboardId]: { ...current[dashboardId], lastVisitedTimestampMs: Date.now() },
    }))
  }, [dashboardId, setDashboardsMeta])

  if (!insightsEnabled) {
    return null
  }

  return (
    <ViewWrapper>
      {!newInsightsHome && <ManageHeadline>{t('manage.insights.headline')}</ManageHeadline>}
      <InsightsDashboard id={dashboardId} />
    </ViewWrapper>
  )
}

const InsightsDashboardPage: React.FC = () => {
  const dashboardId = Route.useParams({ select: params => params.dashboardId })

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsightsDashboard({ dashboardId })}>
      <StrategicErrorBoundary
        id='insights-wrapper'
        Fallback={DashboardErrorBoundaryComponent}
        strategies={[]}
        resetKeys={[dashboardId]}
        beforeSend={error => ({ cause: error.cause })}
      >
        <InsideErrorBoundary dashboardId={dashboardId} />
      </StrategicErrorBoundary>
    </ManagePageWrapper>
  )
}

const RouteComponent = InsightsDashboardPage

export const Route = createFileRoute('/manage/insights/dashboards/$dashboardId/')({
  component: RouteComponent as React.FC,
  params: {
    parse: z.object({ dashboardId: NanoId12 }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (!getFlag('preloading')) return
    if (!context.isLoggedIn) return

    void prefetchGetDashboard({ dashboardId: params.dashboardId })
  },
})
