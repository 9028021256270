import { createFileRoute } from '@tanstack/react-router'
import { AnimatePresence } from 'framer-motion'

import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import {
  SkillDebugControl,
  SkillDetailPage,
  SkillSubscribeBanner,
  getMySubcriptionsQuery,
  useSkillsFlagEnabled,
} from 'sierra-client/features/skills'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { PageContainer } from 'sierra-client/views/workspace/components'
import styled from 'styled-components'
import { z } from 'zod'

const OverlayView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), transparent);
  z-index: 1;
  pointer-events: none;
`

const SkillPage = ({ skillId }: { skillId: SkillId }): JSX.Element => {
  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)
  const [showBanner, toggleBanner] = useState(false)
  const permissions = useOrganizationPermissions()

  const { data } = useGraphQuery({ document: getMySubcriptionsQuery }, { id: skillId })
  const isSubscribed = data?.me.skills.some(it => it.id === skillId) ?? false

  useEffect(() => {
    if (isSubscribed || permissions.has('MANAGE_SKILLS')) {
      toggleBanner(false)
    } else {
      toggleBanner(true)
    }
  }, [isSubscribed, permissions])

  return (
    <>
      {showBanner && <OverlayView />}
      <PageContainer $sidebarOpen={sidebarOpen}>
        <SkillDebugControl />
        <SkillDetailPage skillId={skillId} isShowingBanner={showBanner} />
      </PageContainer>
      <AnimatePresence initial>
        {showBanner && <SkillSubscribeBanner onClose={() => toggleBanner(false)} skillId={skillId} />}
      </AnimatePresence>
    </>
  )
}

const Page = (): JSX.Element | null => {
  const skillId = Route.useParams({ select: p => p.skillId })

  const skillsAvailable = useSkillsFlagEnabled()

  useEffect(() => {
    if (!skillsAvailable) {
      void notFound404Redirect()
    }
  }, [skillsAvailable])

  if (!skillsAvailable) {
    return null
  }

  return (
    <SanaPage mode='light' headerType='none' page={PageIdentifier.SkillDetail({ skillId })}>
      <SkillPage skillId={skillId} />
    </SanaPage>
  )
}

export const Route = createFileRoute('/skill/$skillId')({
  component: requireLoggedIn(Page) as React.FC,
  validateSearch: z.object({
    tab: z.enum(['overview', 'manage']).optional().catch(undefined),
  }),
  params: {
    parse: z.object({ skillId: SkillId }).parse,
    stringify: p => p,
  },
})
