import _, { capitalize } from 'lodash'

import React, { useMemo, useState } from 'react'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { Logging } from 'sierra-client/core/logging'
import { TransparentNowButton } from 'sierra-client/features/sana-now/header/buttons'
import { usePresenceAtomParticipantIds } from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { addFacilitator, removeFacilitator } from 'sierra-client/state/live-session/actions'
import { selectFacilitatorIds, selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { LightUser } from 'sierra-domain/user'
import { getUserName, iife, isDefined } from 'sierra-domain/utils'
import { Icon, Popover, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const ListItemButtonContainer = styled.div``

const ListItemContainer = styled(View)`
  min-height: 42px;

  ${ListItemButtonContainer} {
    transition: opacity 150ms ease;
    opacity: 0;
  }

  &:hover {
    ${ListItemButtonContainer} {
      opacity: 1;
    }
  }
`

const ParticipantListItem: React.FC<{
  user: LightUser
}> = ({ user }) => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)
  const userIsCurrentUser = me?.uuid === user.uuid
  const guestUserEmail = user.email.startsWith('sana-live')
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const dispatch = useDispatch()
  const isUserFacilitator = useSelector(selectIsFacilitator)
  const liveSessionId = useLiveSessionIdContext().liveSessionId
  const isParticipantFacilitator = facilitatorIds.includes(user.uuid)

  const requestToggleFacilitator = (): void => {
    if (isParticipantFacilitator) {
      void dispatch(
        removeFacilitator({ userId: user.uuid, liveSessionId: ScopedLiveSessionId.extractId(liveSessionId) })
      )
      void dispatch(Logging.liveSession.facilitatorRemoved())
    } else {
      void dispatch(
        addFacilitator({ userId: user.uuid, liveSessionId: ScopedLiveSessionId.extractId(liveSessionId) })
      )
      void dispatch(Logging.liveSession.facilitatorAdded())
    }
  }

  return (
    <ListItemContainer justifyContent='space-between' grow gap='16' padding='none none'>
      <View>
        <RoundAvatar
          size='medium'
          firstName={user.firstName}
          lastName={user.lastName}
          color={user.avatarColor}
          src={getAvatarImage(user.uuid, user.avatar)}
        />
        <View direction='column' gap='none'>
          <TruncatedText lines={1} size='small' bold>
            {userIsCurrentUser ? capitalize(t('dictionary.you')) : getUserName(user) ?? ''}
          </TruncatedText>

          <Text size='small' color='foreground/muted'>
            {guestUserEmail ? 'guest user' : user.email}
          </Text>
        </View>
      </View>
      {isUserFacilitator && isParticipantFacilitator && facilitatorIds.length > 1 && (
        <ListItemButtonContainer>
          <IconButton
            size='small'
            variant='secondary'
            tooltip={t('live.remove-as-facilitator')}
            onClick={requestToggleFacilitator}
            iconId={'close'}
          />
        </ListItemButtonContainer>
      )}
      {isUserFacilitator && !isParticipantFacilitator && (
        <ListItemButtonContainer>
          <IconButton
            size='small'
            variant='secondary'
            tooltip={t('live.make-facilitator')}
            onClick={requestToggleFacilitator}
            iconId={'touch'}
          />
        </ListItemButtonContainer>
      )}
    </ListItemContainer>
  )
}

const OuterContainer = styled(ScrollView)`
  max-height: 324px;
  width: 381px;
  padding: 0 24px 24px 24px;
`

const NumberBlob = styled.div`
  background-color: ${token('foreground/muted')};
  padding: 2px 5px 2px 5px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  color: ${token('destructive/foreground')};
  width: fit-content;
  min-width: 16px;
  vertical-align: middle;
  text-align: center;
`

const SEARCH_HEIGHT = 55

const SearchContainer = styled(View)`
  position: sticky;
  width: 100%;
  height: ${SEARCH_HEIGHT}px;
  flex-shrink: 0;
  top: 0;
  background-color: ${token('elevated/background')};
  z-index: 2;
`

const LabelContainer = styled(View)`
  position: sticky;
  top: ${SEARCH_HEIGHT}px;
  width: 100%;
  background-color: ${token('elevated/background')};
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    height: 16px;
    background: linear-gradient(to bottom, ${token('elevated/background')}, transparent);
    pointer-events: none;
  }
`

const FilterInput = styled.input`
  ${fonts.body.small}
  width: 100%;
  transition: border-color 200ms ease;
  background: transparent;
  color: ${token('foreground/primary')};

  &::placeholder {
    color: ${token('foreground/primary')};
    opacity: 0.5;
  }
`

const SearchIcon = styled(Icon).attrs({
  iconId: 'search',
})`
  color: ${token('foreground/primary')};
`

const UserList: React.FC<{ label: string; emptyText: string; users: LightUser[] }> = ({
  label,
  users,
  emptyText,
}) => {
  return (
    <View direction='column'>
      <LabelContainer>
        <Text bold color={'foreground/secondary'}>
          {label}
        </Text>
        <NumberBlob>{users.length}</NumberBlob>
      </LabelContainer>
      {users.length === 0 && <Text color='foreground/muted'>{emptyText}</Text>}
      {users.map(facilitator => (
        <ParticipantListItem key={facilitator.uuid} user={facilitator} />
      ))}
    </View>
  )
}

export const ParticipantsButton = (): JSX.Element => {
  const participantIds = usePresenceAtomParticipantIds()

  const { t } = useTranslation()

  const users = _.uniqBy(useUsersLegacy(participantIds).filter(isDefined), user => user.uuid)
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const facilitators = users.filter(user => facilitatorIds.includes(user.uuid))
  const nonFacilitators = users.filter(user => !facilitatorIds.includes(user.uuid))
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const [open, setOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const searchFilteredUsers = useMemo(() => {
    const filteredUsers = users.filter(
      user =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLocaleLowerCase().includes(searchTerm.toLowerCase())
    )
    return filteredUsers
  }, [searchTerm, users])

  return (
    <Popover
      align='end'
      sideOffset={8}
      renderTrigger={() => (
        <TransparentNowButton
          $backgroundColor={backgroundColor}
          icon='user'
          decoratorPosition='left'
          variant='primary'
          onClick={() => {
            setOpen(!open)
          }}
        >
          {users.length.toString()}
        </TransparentNowButton>
      )}
    >
      <OuterContainer radius='size-20' direction='column' gap='none'>
        <SearchContainer>
          <SearchIcon />
          <FilterInput
            type='text'
            maxLength={50}
            spellCheck='false'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder={t('dictionary.search') + '...'}
          />
        </SearchContainer>
        {iife(() => {
          if (searchTerm.length === 0) {
            return (
              <>
                <UserList label={t('dictionary.facilitators')} users={facilitators} emptyText={''} />
                <Spacer size='24' />
                <UserList
                  label={t('admin.author.sessions.participants')}
                  users={nonFacilitators}
                  emptyText={t('sana-now.participant-modal.no-participants-have-joined-yet')}
                />
              </>
            )
          }

          if (searchFilteredUsers.length === 0) {
            return <Text color='foreground/muted'>{t('manage.users.no-results')}</Text>
          }

          return searchFilteredUsers.map(participant => (
            <ParticipantListItem key={participant.uuid} user={participant} />
          ))
        })}
      </OuterContainer>
    </Popover>
  )
}
