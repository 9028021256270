import { atom, useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useUserMenuIsOpen } from 'sierra-client/state/header'

export type SlidingHeaderProps = {
  $isFixed: boolean
  $shouldSlideDown: boolean
  $isNoTransition: boolean
}

const scrollOffsetAtom = atom(0)
const isFixedPositionAtom = atom(get => get(scrollOffsetAtom) > 140)
const isInSlidingAreaAtom = atom(get => get(scrollOffsetAtom) > 400)
const isInNoTransitionAreaAtom = atom(get => get(scrollOffsetAtom) >= 20 && get(scrollOffsetAtom) < 200)

// Should only ever be active once. Registers a scroll listener on the body
// element and updates the scroll offset in Redux.
export function useScrollOffset(): void {
  const setScrollOffset = useSetAtom(scrollOffsetAtom)
  useEffect(() => {
    const updateScroll = _.throttle(() => setScrollOffset(window.scrollY), 150)

    window.addEventListener('scroll', updateScroll)

    return () => {
      // Reset to 0 once unmounted
      setScrollOffset(0)

      updateScroll.cancel()
      window.removeEventListener('scroll', updateScroll)
    }
  }, [setScrollOffset])
}

export const useSlidingHeader = (): SlidingHeaderProps => {
  const $isFixed = useAtomValue(isFixedPositionAtom)
  const isInSlidingArea = useAtomValue(isInSlidingAreaAtom)
  const isInNoTransitionArea = useAtomValue(isInNoTransitionAreaAtom)
  const globalPageScrollOffset = useAtomValue(scrollOffsetAtom)
  const isMenuOpen = useUserMenuIsOpen()
  const [hasScrolledUp, setHasScrolledUp] = useState(true)
  const [isNoTransition, setIsNoTransition] = useState(false)
  const [lastOffset, setLastOffset] = useState(globalPageScrollOffset)
  const delta = 20

  useEffect(
    () => {
      if (isMenuOpen) return

      // If the delta is less then 10, do nothing
      if (Math.abs(lastOffset - globalPageScrollOffset) <= delta) {
        return
      }

      // This is for a hard transition between fixed and absolute positioned header
      if (isInNoTransitionArea) {
        setIsNoTransition(true)
      } else {
        setIsNoTransition(false)
      }

      // Check if we scroll down
      if (globalPageScrollOffset >= lastOffset) {
        setHasScrolledUp(false)
        setLastOffset(globalPageScrollOffset)
      } else {
        // Or up
        setHasScrolledUp(true)
        setLastOffset(globalPageScrollOffset)
      }

      // Don't do this at the very top
      if (!isInSlidingArea) {
        setHasScrolledUp(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastOffset, globalPageScrollOffset, isMenuOpen, isInSlidingArea]
  )

  return {
    $isFixed,
    $shouldSlideDown: isInSlidingArea && hasScrolledUp,
    $isNoTransition: isNoTransition,
  }
}
