import { isEditableYjsEditor } from 'sierra-client/editor'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import {
  removeRelativeRangeLocal,
  removeRelativeRangeRemote,
  storeRelativeRangeLocal,
  storeRelativeRangeRemote,
} from 'sierra-client/views/commenting/utils'
import { isReadOnlyYjsEditor } from 'sierra-client/views/v3-author/collaboration/with-read-only-yjs-editor'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/context'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { ScopedYDocId } from 'sierra-domain/collaboration/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Range } from 'slate'
import { useSlateStatic } from 'slate-react'

type UseStoredPositionUtils = {
  cleanUpDraftStoredPosition: (params: {
    storedPositionKey: string
    fileId: string
    yDocId: ScopedYDocId
  }) => Promise<void>

  createDraftStoredPosition: (params: {
    fileId: string
    range: Range
    yDocId: ScopedYDocId
  }) => Promise<NanoId12>
}

export function useStoredPositionUtils(): UseStoredPositionUtils {
  const editor = useSlateStatic()
  const readOnly = useEditorReadOnly()

  const cleanUpDraftStoredPosition: UseStoredPositionUtils['cleanUpDraftStoredPosition'] = useStableFunction(
    async ({ storedPositionKey, fileId, yDocId }) => {
      if (!readOnly && isEditableYjsEditor(editor)) {
        removeRelativeRangeLocal({
          editor,
          key: storedPositionKey,
        })
      } else {
        await removeRelativeRangeRemote({
          yDocId,
          fileId,
          key: storedPositionKey,
        })
      }
    }
  )

  const createDraftStoredPosition: UseStoredPositionUtils['createDraftStoredPosition'] = useStableFunction(
    async ({ fileId, range, yDocId }) => {
      const key = nanoid12()

      if (!readOnly && isEditableYjsEditor(editor)) {
        storeRelativeRangeLocal({ editor, key, range })
      } else {
        if (!isEditableYjsEditor(editor) && !isReadOnlyYjsEditor(editor)) {
          throw Error('Encountered invalid editor while creating range for commenting')
        }

        await storeRelativeRangeRemote({
          yDocId,
          editor,
          fileId,
          key,
          range,
        })
      }

      return key
    }
  )

  return {
    cleanUpDraftStoredPosition,
    createDraftStoredPosition,
  }
}
