import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { getFlag } from 'sierra-client/config/global-config'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import {
  DistributionSettings,
  OrganizationClusterVisibilitySettings,
  translateVisibility,
  useOrganizationCluster,
} from 'sierra-client/features/multi-tenancy'
import {
  ContentSkillSelectionWithRecommendation,
  unassignSkillsFromContentMutation,
  useAssignSkillsToContentMutation,
  useSkillsFlagEnabled,
  type ContentSkill,
} from 'sierra-client/features/skills'
import { useIsVerticalScrollbarVisible } from 'sierra-client/hooks/use-is-scrollbar-visible'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { subscribeSse, useCachedQuery } from 'sierra-client/state/api'
import * as actions from 'sierra-client/state/author-course-settings/actions'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectors as settingsSelectors } from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCoursePermissionSettings } from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { CourseTagsSelector } from 'sierra-client/views/flexible-content/editor/topbar/course-tags-selector'
import { SmartCardsGenerator } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/smart-cards-generator'
import { PublishModalState } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/types'
import { Card } from 'sierra-client/views/showcase/sections/lil-stack'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { ContentDistributionSettings, CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { Tag } from 'sierra-domain/api/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import {
  XRealtimeAuthorCourseContentLength,
  XRealtimeAuthorExtractFirstImageInContent,
} from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateDescription } from 'sierra-domain/routes-sse'
import { assertNever, iife, isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { CloseModalButton, FormElement, Modal } from 'sierra-ui/components'
import { Button, InputPrimitive, ScrollView, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const StyledModal = styled(Modal)`
  --publish_modal-padding: 24px;
  --publish_modal-padding--scroll: 12px;
  --publish_modal-top: 0;

  position: absolute;
  top: var(--publish_modal-top);
  right: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  border: 1px solid ${token('border/default')};
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);

  /* Set the transform origin for the Modal pop animation. */
  transform-origin: 80% 0;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --publish_modal-padding: 32px;
    --publish_modal-padding--scroll: 18px;
    --publish_modal-top: 54px;

    right: 10px;
    left: auto;
    width: 618px;
  }
`

const ThumbnailImageContainer = styled.div`
  background-color: transparent;
  position: relative;
  height: 72px;
  max-width: 112px;
  min-width: 112px;
`

const ThumbnailImg = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-block: var(--publish_modal-padding);
  height: 100svh;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --_bottom-space: 12px;
    height: auto;
    max-height: calc(100svh - var(--_bottom-space) - var(--publish_modal-top));
  }
`

const FormScrollView = styled(ScrollView)`
  gap: 24px;
  padding-inline: var(--publish_modal-padding) var(--publish_modal-padding--scroll);
  padding-block-end: 24px;

  scrollbar-gutter: stable;
`

const TopContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex-direction: row;
    align-items: center;
  }
`

const ButtonGroup = styled.footer<{ elevated: boolean }>`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-block-start: auto;
  padding-inline: var(--publish_modal-padding);
  padding-block-start: calc(var(--publish_modal-padding) * 0.6);

  ${p =>
    p.elevated &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: var(--publish_modal-padding);
        right: var(--publish_modal-padding);
        border-top: 1px solid ${token('border/default')};
      }
    `}
`

const DescriptionWrapper = styled.div`
  position: relative;
`

const GenerateButton = styled(Button)`
  position: absolute;
  bottom: 12px;
  left: 8px;
`

type PublishModalContentProps = {
  onModalClose: () => void
  publish: (contentDistributionSettings?: ContentDistributionSettings[]) => Promise<void>
  selfPacedContentId: CourseId
  title: string
}

const PublishModalContent: FC<PublishModalContentProps> = ({
  onModalClose,
  publish,
  selfPacedContentId,
  title,
}) => {
  const smartCardsFlag = getFlag('smart-cards')

  const { t } = useTranslation()
  const notification = useNotif()

  const skillsFlagEnabled = useSkillsFlagEnabled()
  const manageSkillsAllowed = useHasOrganizationPermission('MANAGE_SKILLS')
  const skillsEnabled = skillsFlagEnabled && manageSkillsAllowed

  const contentId = `course:${selfPacedContentId}`

  const draftSettings = useSelector(settingsSelectors.selectDraftSettings)
  const { coursePermissionSettings, updateVisibilityInOrg } = useCoursePermissionSettings()

  const [contentTitle, setContentTitle] = useState(title)
  const [contentDescription, setContentDescription] = useState(draftSettings?.description ?? '')

  const [contentImage, setContentImage] = useState<ImageUnion | undefined>(draftSettings?.image ?? undefined)

  const [smartCards, setSmartCards] = useState<Card[] | undefined>(
    draftSettings?.nativeCourseSettings?.smartCards?.map(card => ({
      id: card.id,
      title: card.title,
      description: card.description,
      image: card.image,
      theme: card.theme?.name,
      sourceCourseId: card.sourceCourseId,
    }))
  )

  const [selectedTags, setSelectedTags] = useState<Tag[]>(draftSettings?.tags ?? [])
  const [visibilityUpdate, setVisibilityUpdate] = useState<CourseVisibilityInOrg | undefined>()

  const organizationCluster = useOrganizationCluster()

  const visibility: CourseVisibilityInOrg | undefined = useMemo(() => {
    if (isDefined(visibilityUpdate)) {
      return visibilityUpdate
    }

    if (coursePermissionSettings.status === 'done') {
      return coursePermissionSettings.visibilityInOrg
    }

    return undefined
  }, [visibilityUpdate, coursePermissionSettings])

  const [contentTitleError, setContentTitleError] = useState<string | undefined>(undefined)

  const [isGenerating, setIsGenerating] = useState(false)
  const [generateAttempt, setGenerateAttempt] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const contentLength = useCachedQuery(XRealtimeAuthorCourseContentLength, { contentId: selfPacedContentId })
    .data?.length

  const disableDescriptionGeneration =
    contentDescription.length > 0 || isGenerating || (contentLength !== undefined && contentLength < 500)

  const imageFromContent = useCachedQuery(
    XRealtimeAuthorExtractFirstImageInContent,
    { contentId: selfPacedContentId },
    { enabled: contentImage === undefined }
  ).data?.image

  const dispatch = useDispatch()

  const [skillsToAssign, setSkillsToAssign] = useState<ContentSkill[]>([])
  const [skillIdsToUnassign, setSkillIdsToUnassign] = useState<SkillId[]>([])

  const [contentDistributionSettings, setContentDistributionSettings] = useState<
    ContentDistributionSettings[]
  >([])

  const courseVisibilityDropdownAllowedItems = useCourseVisibilityDropdownAllowedItems(
    selfPacedContentId,
    contentDistributionSettings.length === 0
  )

  const updateCourseTitle = async (): Promise<void> => {
    await dispatch(
      settingsActions.setCourseTitle({
        title: contentTitle,
        courseId: selfPacedContentId,
      })
    )
  }

  const { mutateAsync: assignSkillsToContentMutation } = useAssignSkillsToContentMutation(contentId)

  const { mutate: unassignSkillsMutation } = useMutation({
    mutationFn: ({ skillIds }: { skillIds: SkillId[] }) => {
      return graphQuery(unassignSkillsFromContentMutation, {
        contentIds: [contentId],
        skillIds: skillIds,
      })
    },
    onError() {
      notification.push({ type: 'error' })
    },
  })

  const handleSaveSettingsAndPublish = async (): Promise<void> => {
    dispatch(
      actions.updateCourseSettings({
        description: contentDescription,
        tags: selectedTags,
        title: contentTitle,
        image: contentImage,
        nativeCourseSettings: {
          ...draftSettings?.nativeCourseSettings,
          smartCards: smartCards?.map(card => ({
            id: card.id,
            title: card.title,
            description: card.description,
            image: card.image,
            theme: card.theme !== undefined ? { type: 'preset', name: card.theme } : undefined,
            sourceCourseId: card.sourceCourseId,
          })),
        },
      })
    )
    await updateCourseTitle()
    await dispatch(settingsActions.saveSettings({ notify: true }))
    await publish(visibility !== 'private' ? contentDistributionSettings : undefined)

    if (isNonEmptyArray(skillIdsToUnassign)) {
      unassignSkillsMutation({ skillIds: skillIdsToUnassign })
    }

    if (isNonEmptyArray(skillsToAssign)) {
      await assignSkillsToContentMutation(
        {
          contentId,
          skills: skillsToAssign.map(skill => ({
            skillId: skill.id,
            skillLevelSettingId: skill.levelSettingId,
          })),
        },
        {
          onError() {
            notification.push({ type: 'error' })
          },
        }
      )
    }
  }

  const handleGenerateDescription = async (): Promise<void> => {
    setContentDescription('')
    setIsGenerating(true)
    setGenerateAttempt(prev => prev + 1)
    await subscribeSse({
      route: SSEXRealtimeAuthorGenerateDescription,
      input: { courseId: selfPacedContentId },
      onError: e => console.error(e),
      onMessage: event => {
        setContentDescription(prev => prev.concat(event.data.text))
      },
    })
    setIsGenerating(false)
    void dispatch(
      generativeFeatureUsed({
        contentId: selfPacedContentId,
        contentType: 'self-paced',
        generativeFeature: 'generate-course-description',
      })
    )
  }

  const handleSkillAddOrUpdate = (skill: ContentSkill): void => {
    const newSkillsToAssign = skillsToAssign.filter(s => s.id !== skill.id)
    setSkillsToAssign([...newSkillsToAssign, skill])
  }

  const { setScrollviewRef, isScrollbarVisible } = useIsVerticalScrollbarVisible()

  const [showImageModal, setShowImageModal] = useState(false)

  function handleUpload(image: ImageUnion): void {
    setContentImage(image)
    setShowImageModal(false)
  }

  const onVisibilityChange = useCallback(
    (newVisibility: CourseVisibilityInOrg) => {
      /**
       * When this change happens, we _always_ propagate it upwards.
       *
       * But when it is triggered in a multi-tenancy context, we need
       * to patch distribution settings accordingly for the content.
       */
      setVisibilityUpdate(newVisibility)

      if (!organizationCluster.loading && organizationCluster.selfIsParent && newVisibility !== 'private') {
        const newContentDistributionSettings = contentDistributionSettings.map(setting => ({
          ...setting,
          visibility: translateVisibility(newVisibility),
        }))
        setContentDistributionSettings(newContentDistributionSettings)
      }
    },
    [contentDistributionSettings, organizationCluster]
  )

  const assetContext = { type: 'course' as const, courseId: selfPacedContentId }
  const thumbnailImageSrc = useResolveAsset({
    assetContext,
    image: contentImage ?? imageFromContent,
    size: 'default',
  })

  return (
    <Form
      onSubmit={async event => {
        event.preventDefault()

        if (contentTitle.trim() === '') {
          setContentTitleError(t('author.publish-popup.error'))
          return
        }

        setIsSubmitting(true)

        if (isDefined(visibilityUpdate)) {
          await updateVisibilityInOrg(selfPacedContentId, visibilityUpdate)
        }
        await handleSaveSettingsAndPublish()
        setIsSubmitting(false)
      }}
    >
      <FormScrollView ref={setScrollviewRef}>
        <TopContainer>
          <ThumbnailImageContainer>
            {showImageModal && (
              <UploadImageModal
                open={showImageModal}
                onUploadDone={image => handleUpload(image)}
                onClose={() => setShowImageModal(false)}
                assetContext={assetContext}
              />
            )}
            <ThumbnailImg
              aria-label='course-image-picker'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setShowImageModal(true)
              }}
              src={thumbnailImageSrc}
            />
          </ThumbnailImageContainer>

          <View direction='column' gap='4'>
            <Text bold size='regular'>
              {t('author.publish-popup.title')}
            </Text>
            <Text size='regular' color='foreground/muted'>
              {t('author.publish-popup.sub-title')}
            </Text>
          </View>
        </TopContainer>
        <View direction='column' gap='24'>
          <FormElement label={t('dictionary.title')} helper={contentTitleError}>
            <InputPrimitive
              value={contentTitle}
              onChange={event => {
                setContentTitle(event.target.value)
                setContentTitleError(undefined)
              }}
              placeholder={t('teamspaces.add-name-placeholder')}
              required
            />
          </FormElement>

          <FormElement
            label={
              <View justifyContent='space-between'>
                {t('dictionary.description')}
                <Text color='grey25'>{t('input.optional')}</Text>
              </View>
            }
          >
            <DescriptionWrapper>
              <TextAreaPrimitive
                value={contentDescription}
                onChange={event => {
                  setContentDescription(event.target.value)
                }}
                placeholder={t('event-groups.add-a-description')}
                rows={4}
                autoExpand
              />
              {!disableDescriptionGeneration && (
                <GenerateButton
                  type='button'
                  variant='ghost'
                  loading={isGenerating}
                  icon='glitter'
                  onClick={handleGenerateDescription}
                  disabled={isGenerating}
                >
                  {generateAttempt > 0
                    ? t('author.generate-from-doc.re-generate')
                    : t('author.slate.generate')}
                </GenerateButton>
              )}
            </DescriptionWrapper>
          </FormElement>
        </View>

        {organizationCluster.loading ? null : (
          <>
            <PermissionFormSection
              title={t('share.visibility-permission.title')}
              subtitle={t('share.visibility-permission.subtitle')}
            >
              {isDefined(visibility) && (
                <CourseVisibilityDropdown
                  selectedVisibility={visibility}
                  onSelect={onVisibilityChange}
                  options={courseVisibilityDropdownAllowedItems}
                />
              )}
            </PermissionFormSection>
            {isDefined(visibility) && (
              <OrganizationClusterVisibilitySettings
                visibility={visibility}
                onSettingsChanged={setContentDistributionSettings}
              />
            )}
          </>
        )}

        {isDefined(visibility) && visibility !== 'private' && !skillsFlagEnabled && (
          <FormElement
            label={
              <View justifyContent='space-between'>
                {t('table.skills')}
                <Text color='foreground/muted'>{t('input.optional')}</Text>
              </View>
            }
          >
            <CourseTagsSelector selectedTags={selectedTags} onChange={setSelectedTags} />
          </FormElement>
        )}

        {isDefined(visibility) && visibility === 'visible-everyone' && skillsEnabled && (
          <View direction='column' gap='12'>
            <View direction='column' gap='2'>
              <View justifyContent='space-between'>
                <Text bold>{_.capitalize(t('dictionary.skills'))}</Text>
                <Text color='foreground/muted' bold>
                  {t('input.optional')}
                </Text>
              </View>
              <Text color='foreground/muted'>{t('skills.publish-modal.description')}</Text>
            </View>

            <ContentSkillSelectionWithRecommendation
              courseId={CourseId.parse(selfPacedContentId)}
              onSkillRemove={skill => {
                if (!skillsToAssign.some(s => s.id === skill.id)) {
                  setSkillIdsToUnassign(prev => [...prev, skill.id])
                } else {
                  setSkillsToAssign(prev => prev.filter(s => s.id !== skill.id))
                }
              }}
              onSkillAdd={skill => {
                handleSkillAddOrUpdate(skill)
                if (skillIdsToUnassign.includes(skill.id)) {
                  setSkillIdsToUnassign(prev => prev.filter(skillId => skillId !== skill.id))
                }
              }}
              onSkillLevelChange={handleSkillAddOrUpdate}
            />
          </View>
        )}

        {smartCardsFlag === true && (
          <SmartCardsGenerator
            smartCards={smartCards}
            setSmartCards={setSmartCards}
            closeModal={onModalClose}
            selfPacedContentId={selfPacedContentId}
            layout='standard'
            assetContext={assetContext}
          />
        )}
      </FormScrollView>

      <ButtonGroup elevated={isScrollbarVisible}>
        <Button variant='secondary' type='button' disabled={isSubmitting} onClick={onModalClose}>
          {t('dictionary.cancel')}
        </Button>
        <Button type='submit' loading={isSubmitting}>
          {t('author.save-and-publish')}
        </Button>
      </ButtonGroup>
    </Form>
  )
}

type PublishModalProps = {
  state: PublishModalState
  onStateChange: (s: PublishModalState) => void
  trigger: ReactNode
} & Omit<PublishModalContentProps, 'onOpenChange' | 'onModalClose'>

export const PublishModal: FC<PublishModalProps> = ({ state, trigger, onStateChange, ...contentProps }) => {
  const { t } = useTranslation()
  const { fetchInitialCoursePermissionSettings, coursePermissionSettings } = useCoursePermissionSettings()

  const handleClose = useCallback(() => {
    onStateChange('closed')
  }, [onStateChange])

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onStateChange('closed')
      }
    },
    [onStateChange]
  )

  useEffect(() => {
    if (coursePermissionSettings.status === 'idle' || state !== 'closed') {
      void fetchInitialCoursePermissionSettings(contentProps.selfPacedContentId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledModal
      open={state !== 'closed'}
      trigger={trigger}
      onOpenChange={onOpenChange}
      overlayVariant='light'
      animation='pop'
      disableScrollbarGutter
    >
      <CloseModalButton onClick={handleClose} ariaLabel={t('dictionary.close')} />

      {iife(() => {
        switch (state) {
          case 'reminder': {
            return <PublishModalContent onModalClose={handleClose} {...contentProps} />
          }
          case 'distribution-settings': {
            return (
              <DistributionSettings onModalClose={handleClose} courseId={contentProps.selfPacedContentId} />
            )
          }
          case 'closed': {
            return null
          }
          default: {
            return assertNever(state)
          }
        }
      })}
    </StyledModal>
  )
}
