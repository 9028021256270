import { useAtomValue } from 'jotai'
import { useCallback, useMemo } from 'react'
import { AssetType, ImageSizeType, resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { getFlag } from 'sierra-client/config/global-config'
import { loadNamespacedCourseAssetsAtom } from 'sierra-client/state/settings'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'

export const useLoadNamespacedCourseAssets = (): boolean => {
  const loadNamespacedCourseAssets = useAtomValue(loadNamespacedCourseAssetsAtom)
  const flag = getFlag('namespaced-course-assets')
  const useNamespaced = flag || loadNamespacedCourseAssets
  return useNamespaced
}

export const useResolveAsset = ({
  image,
  size,
  assetContext,
  assetType,
}: {
  image: ImageUnion | undefined
  size: ImageSizeType
  assetContext: AssetContext
  assetType?: AssetType
}): string => {
  const useNamespaced = useLoadNamespacedCourseAssets()
  const url = useMemo(() => {
    return resolveNamespacedCourseImage(image, size, useNamespaced, assetContext, assetType)
  }, [image, size, useNamespaced, assetContext, assetType])

  return url
}

export const useAssetResolver = ({
  size,
}: {
  size: ImageSizeType
}): ((image: ImageUnion | undefined, assetContext: AssetContext) => string) => {
  const useNamespaced = useLoadNamespacedCourseAssets()

  return useCallback(
    (image: ImageUnion | undefined, assetContext: AssetContext) => {
      return resolveNamespacedCourseImage(image, size, useNamespaced, assetContext)
    },
    [size, useNamespaced]
  )
}
