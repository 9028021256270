import { useMutation, UseMutationResult } from '@tanstack/react-query'
import _ from 'lodash'
import { useCallback } from 'react'
import { cardAdded } from 'sierra-client/core/logging/authoring/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  useBulkCopyCards,
  useCopyCard,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { CHARACTERS } from 'sierra-client/views/v3-author/scenario/character'
import { apply, CreateOperation, Destination, replaceIdsInFileData } from 'sierra-domain/editor/operations'
import { FileId, FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import {
  AssessmentCardData,
  BreakoutRoomData,
  BulletData,
  createFile,
  DropAWordData,
  EmbedData,
  ExternalNotepadData,
  File,
  FlipCardsData,
  Folder,
  GeneralData,
  HomeworkData,
  ImageData,
  LegacyNotepadData,
  LiveLobbyData,
  PollData,
  QuestionCardData,
  ReflectionsData,
  ScenarioData,
  SlateCardData,
  SlidingScaleData,
  StickyNotesCardData,
  StupidQuestionsData,
  VideoData,
} from 'sierra-domain/flexible-content/types'
import { SlateDocument } from 'sierra-domain/v3-author'

export const newSlateCardData = (): SlateCardData => ({ type: 'slate-card' })
export const newLiveLobbyData = (): LiveLobbyData => ({ type: 'live-lobby' })
export const newReflectionsData = (): ReflectionsData => ({
  type: 'reflections',
  settings: { allowAnonymousResponses: true },
})
export const newPollData = (): PollData => ({ type: 'poll' })
export const newSlidingScaleData = (): SlidingScaleData => ({ type: 'sliding-scale' })
export const newFlipCardsData = (): FlipCardsData => ({ type: 'flip-cards' })
export const newHomeworkData = (): HomeworkData => ({
  type: 'homework',
  limitOfSubmissions: undefined,
  submissionType: 'file',
})
export const newBulletData = (): BulletData => ({ type: 'bullet' })
export const newVideoData = (): VideoData => ({ type: 'video', video: {} })
export const newGeneralData = (): GeneralData => ({ type: 'general' })
export const newDropAWordData = (): DropAWordData => ({ type: 'drop-a-word' })
export const newLegacyNotepadData = (): LegacyNotepadData => ({ type: 'notepad' })
export const newExternalNotepadData = (): ExternalNotepadData => ({ type: 'external-notepad' })
export const newQuestionData = (): QuestionCardData => ({ type: 'question-card' })
export const newAssessmentData = (): AssessmentCardData => ({
  type: 'assessment-card',
  settings: { passingCriteria: 1, allowRetry: true, hideCorrectAnswers: false, timeLimit: undefined },
})

export const newEmbedData = ({ urlType }: Pick<EmbedData, 'urlType'>): EmbedData => ({
  type: 'embed',
  urlType: urlType,
})

export const newStupidQuestionsData = (): StupidQuestionsData => ({ type: 'stupid-questions' })
export const newScenarioData = (): ScenarioData => ({
  type: 'scenario',
  input: {
    scenario: {
      type: 'custom',
      title: '',
      description: '',
    },
    character: {
      voice: CHARACTERS[2]?.voice ?? 'alloy',
      avatar: CHARACTERS[2]?.image ?? '',
      name: CHARACTERS[2]?.name ?? '',
    },
  },
})

export const newStickyNotesCardData = (): StickyNotesCardData => ({ type: 'sticky-notes' })

export const newBreakoutRoomData = (): BreakoutRoomData => ({
  type: 'breakout-room',
  settings: { roomSize: 4, algorithm: 'random' },
})

export const newImageData = (fileUrl?: string): ImageData => ({
  type: 'image',
  image:
    fileUrl === undefined
      ? undefined
      : {
          type: 'file',
          file: fileUrl,
        },
})

type AddFile = (_: {
  fileId?: FileId
  data: File['data']
  title?: File['title']
  slateDocument?: SlateDocument
}) => Promise<void>

type CardAddedByUserLoggingType = 'createdByUser' | 'generatedContent'

export function useAddFileCreatedByUser({
  folderId,
  nextTo,
  cardAddedByUserLoggingType,
}: {
  folderId?: FolderId
  nextTo: NodeId | undefined
  cardAddedByUserLoggingType: CardAddedByUserLoggingType
}): AddFile {
  const { scopedCreateContentId, operationState, contentType, createContentId } = useCreatePageContext()
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const addFile: AddFile = useCallback(
    async ({ fileId, data, title, slateDocument }) => {
      if (userId === undefined) throw new Error('Must be logged in')
      const file = createFile({
        id: fileId,
        metadata: {
          createdBy: userId,
          createdAt: new Date().toISOString(),
          createdFromAction: { type: 'manual' },
        },
        data,
        title,
      })
      apply(operationState, {
        type: 'add-file',
        file,
        folderId,
        destination: nextTo === undefined ? { type: 'add-at-end' } : { type: 'next-to', nodeId: nextTo },
        slateDocument,
      })

      await navigateToCreateContentId({ scopedCreateContentId, nodeId: file.id })

      const cardType = data.type === 'embed' && data.urlType !== undefined ? data.urlType : data.type

      void dispatch(
        cardAdded({
          contentId: createContentId,
          contentType,
          createdByAction: cardAddedByUserLoggingType,
          cardType,
        })
      )
    },
    [
      cardAddedByUserLoggingType,
      contentType,
      createContentId,
      dispatch,
      folderId,
      nextTo,
      operationState,
      scopedCreateContentId,
      userId,
    ]
  )

  return addFile
}

export function useDuplicateFileCreatedByUser({
  folderId,
  nextTo,
  onSuccess,
}: {
  folderId: FolderId
  nextTo: NodeId | undefined
  onSuccess?: () => void
}): UseMutationResult<unknown, Error, { originalFile: File }, unknown> {
  const { scopedCreateContentId, operationState, createContentId, contentType } = useCreatePageContext()
  const userId = useSelector(selectUserId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const copyCard = useCopyCard({ operationState, contentId: createContentId }).mutateAsync

  const mutation = useMutation({
    mutationFn: async ({ originalFile }: { originalFile: File }): Promise<void> => {
      if (userId === undefined) throw new Error('Must be logged in')
      const { slateDocument } = await copyCard(originalFile.id)

      const file = createFile({
        ..._.omit(originalFile, ['id', 'type']),
        data: replaceIdsInFileData(originalFile.data),
        title:
          originalFile.title === undefined
            ? undefined
            : t('content.copy-of-title', { title: originalFile.title }),
        layout: originalFile.layout,
        metadata: {
          createdBy: userId,
          createdAt: new Date().toISOString(),
          createdFromAction: { type: 'duplicate', originalFileId: originalFile.id },
        },
      })
      apply(operationState, {
        type: 'add-file',
        file: file,
        folderId,
        destination: nextTo === undefined ? { type: 'add-at-end' } : { type: 'next-to', nodeId: nextTo },
        slateDocument,
      })
      await navigateToCreateContentId({ scopedCreateContentId, nodeId: file.id })
      void dispatch(
        cardAdded({
          contentId: createContentId,
          contentType,
          createdByAction: 'duplicated',
          cardType: file.data.type,
        })
      )
    },
    onSuccess,
  })

  return mutation
}

export function useMultiSelectDuplicateFilesCreatedByUser({
  onSuccess,
}: {
  onSuccess?: () => void
}): UseMutationResult<
  unknown,
  Error,
  { originalFiles: { file: File; parentFolderId: FolderId }[] },
  unknown
> {
  const { operationState, createContentId, contentType } = useCreatePageContext()
  const userId = useSelector(selectUserId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const copyCards = useBulkCopyCards({ operationState, contentId: createContentId }).mutateAsync

  const mutation = useMutation({
    mutationFn: async ({
      originalFiles,
    }: {
      originalFiles: { file: File; parentFolderId: FolderId }[]
    }): Promise<void> => {
      if (userId === undefined) throw new Error('Must be logged in')
      const copiedCards = await copyCards(
        originalFiles.map(({ file, parentFolderId }) => {
          return { fileId: file.id, parentFolderId }
        })
      )

      const operations = copiedCards.map(({ copyFile, sourceCardInfo }): CreateOperation => {
        const { slateDocument } = copyFile

        const file = createFile({
          ..._.omit(copyFile.file, ['id', 'type']),
          data: replaceIdsInFileData(copyFile.file.data),
          title:
            copyFile.file.title === undefined
              ? undefined
              : t('content.copy-of-title', { title: copyFile.file.title }),
          metadata: {
            createdBy: userId,
            createdAt: new Date().toISOString(),
            createdFromAction: { type: 'duplicate', originalFileId: sourceCardInfo.fileId },
          },
        })

        return {
          type: 'add-file',
          file: file,
          folderId: sourceCardInfo.parentFolderId,
          destination: { type: 'next-to', nodeId: sourceCardInfo.fileId },
          slateDocument,
        }
      })

      // Apply all operations in one go
      apply(operationState, ...operations)

      void dispatch(
        cardAdded({
          contentId: createContentId,
          contentType,
          createdByAction: 'duplicated',
          cardType: 'multiple',
        })
      )
    },
    onSuccess,
  })

  return mutation
}

export function useMultiSelectCreateModuleAndMoveFiles({
  onSuccess,
  folderDestination,
  newFolder,
}: {
  onSuccess?: () => void
  folderDestination: Destination
  newFolder: Folder
}): UseMutationResult<
  unknown,
  Error,
  { originalFiles: { file: File; parentFolderId: FolderId }[] },
  unknown
> {
  const { operationState } = useCreatePageContext()
  const userId = useSelector(selectUserId)

  const mutation = useMutation({
    mutationFn: async ({
      originalFiles,
    }: {
      originalFiles: { file: File; parentFolderId: FolderId }[]
    }): Promise<void> => {
      if (userId === undefined) throw new Error('Must be logged in')
      apply(operationState, { type: 'add-folder', folder: newFolder, destination: folderDestination })

      const operations = await Promise.all(
        originalFiles.map(async (originalFile): Promise<CreateOperation> => {
          return {
            type: 'move-file',
            fileId: originalFile.file.id,
            toFolderId: newFolder.id,
            destination: { type: 'add-at-end' },
          }
        })
      )

      // Apply all operations in one go
      apply(operationState, ...operations)
    },
    onSuccess,
  })

  return mutation
}

export function useMultiSelectMoveFiles({ onSuccess }: { onSuccess?: () => void }): UseMutationResult<
  unknown,
  Error,
  {
    fileIds: FileId[]
    folderId: FolderId | undefined
    destination: Destination
  },
  unknown
> {
  const { operationState } = useCreatePageContext()
  const userId = useSelector(selectUserId)

  const mutation = useMutation({
    mutationFn: async ({
      fileIds,
      folderId,
      destination,
    }: {
      fileIds: FileId[]
      folderId: FolderId | undefined
      destination: Destination
    }): Promise<void> => {
      if (userId === undefined) throw new Error('Must be logged in')
      const operations = await Promise.all(
        (destination.type === 'next-to' && destination.position === 'below'
          ? fileIds.reverse()
          : fileIds
        ).map((fileId): CreateOperation => {
          return {
            type: 'move-file',
            fileId: fileId,
            toFolderId: folderId,
            destination,
          }
        })
      )

      // Apply all operations in one go
      apply(operationState, ...operations)
    },
    onSuccess,
  })

  return mutation
}
