import { Logger } from 'sierra-client/core/logging/logger'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import {
  CourseShareLinkAccessLevel,
  CourseVisibilityInOrg,
  GenerateQuestionResponse,
  GenerateTakeawaysResponse,
} from 'sierra-domain/api/author-v2'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { UUID } from 'sierra-domain/api/uuid'
import { ContentType } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const questionGenerated = Logger.trackLoggerWithExtra<
  {
    courseId: string
    generateQuestionResponse: GenerateQuestionResponse
  },
  {
    courseId: string
    text: string | undefined
    selection: 'single' | 'multiple' | undefined
    alternatives: NonNullable<GenerateQuestionResponse['question']>['alternatives'] | undefined
  }
>('question_generated', input => {
  return {
    courseId: input.courseId,
    text: input.generateQuestionResponse.question?.text,
    selection: input.generateQuestionResponse.question?.selection,
    alternatives: input.generateQuestionResponse.question?.alternatives,
  }
})

export const takeawaysGenerated = Logger.trackLoggerWithExtra<
  {
    courseId: string
    fileId: string
    generatedTakeaways: GenerateTakeawaysResponse
  },
  {
    courseId: string
    fileId: string
    takeaways: NonNullable<GenerateTakeawaysResponse['takeaways']> | undefined
  }
>('takeaways_generated', input => {
  return {
    courseId: input.courseId,
    fileId: input.fileId,
    takeaways: input.generatedTakeaways.takeaways,
  }
})

type CreateFromSanaTemplateParamaters = { sanaTemplate: string }
export const createFromSanaTemplate = Logger.trackLoggerWithExtra<
  CreateFromSanaTemplateParamaters,
  CreateFromSanaTemplateParamaters
>('author_create_from_sana_template', ({ sanaTemplate }) => ({ sanaTemplate }))

type AddedCollaboratorsMetaData = {
  invitedUserIds?: string[]
  linkInvite?: CourseShareLinkAccessLevel
  nInvited?: number
}

type CollaboratorAddedProps = {
  props?: {
    userId: string
    assign: boolean
    role: 'owner' | 'editor' | 'commenter'
  }[]
  linkInvite?: CourseShareLinkAccessLevel
}

const selectMetadata = (metadata: CollaboratorAddedProps): AddedCollaboratorsMetaData => {
  const invitedUserIds =
    metadata.props !== undefined
      ? metadata.props.map(assignment => {
          return assignment.userId
        })
      : undefined

  const nInvited = invitedUserIds !== undefined ? invitedUserIds.length : undefined
  return {
    invitedUserIds,
    linkInvite: metadata.linkInvite,
    nInvited,
  }
}

export const collaboratorAdded = Logger.trackLoggerWithExtra<CollaboratorAddedProps, CollaboratorAddedProps>(
  'collaborator_added',

  input => {
    return {
      ...input,
      ...selectMetadata(input),
    }
  }
)

type EditorAccessedProps = {
  contentId?: NanoId12
  contentLink?: string
  contentTitle?: string
  contentType?: string
}

export const editorAccessed = Logger.trackLoggerWithExtra<EditorAccessedProps, EditorAccessedProps>(
  'editor_accessed',

  input => {
    return {
      ...input,
    }
  }
)

type ClickedQuestionQACardProps = {
  contentId: NanoId12
  cardId: string
  activityId: string
  where: 'search-bar' | 'word-cloud'
  in: 'editor' | 'self-paced'
}

export const clickedQuestionQACard = Logger.trackLoggerWithExtra<
  ClickedQuestionQACardProps,
  ClickedQuestionQACardProps
>(
  'clicked_question_QA_card',

  input => {
    return {
      ...input,
    }
  }
)

type WroteQuestionQACardProps = {
  contentId: NanoId12
  cardId: string
  activityId: string
}

export const wroteQuestionQACard = Logger.trackLoggerWithExtra<
  WroteQuestionQACardProps,
  WroteQuestionQACardProps
>(
  'wrote_question_QA_card',

  input => {
    return {
      ...input,
    }
  }
)

type AssistantPillActionProps = {
  name: string
}

export const assistantPillAction = Logger.trackLoggerWithExtra<
  AssistantPillActionProps,
  AssistantPillActionProps
>(
  'assistant_pill_action',

  input => {
    return {
      ...input,
    }
  }
)

type VersionHistoryAccessedProps = {
  contentId?: NanoId12
  contentLink?: string
  contentTitle?: string
  contentType?: string
}

export const versionHistoryAccessed = Logger.trackLoggerWithExtra<
  VersionHistoryAccessedProps,
  VersionHistoryAccessedProps
>(
  'version_history_accessed',

  input => {
    return {
      ...input,
    }
  }
)

type CardAddedProps = {
  contentId?: NanoId12
  contentType?: string
  cardType?: string
  createdByAction?: 'createdByUser' | 'pasted' | 'duplicated' | 'generatedContent'
}

export const cardAdded = Logger.trackLoggerWithExtra<CardAddedProps, CardAddedProps>(
  'card_added',

  input => {
    return {
      ...input,
    }
  }
)

type AINarrationPanelProps = {
  contentId: CreateContentId
  contentType: ContentType
  fileId: FileId
}

export const aiNarrationPanelOpened = Logger.trackLoggerWithExtra<
  AINarrationPanelProps,
  AINarrationPanelProps
>(
  'ai_narration_panel_opened',

  input => {
    return { ...input }
  }
)

export const aiNarrationPanelClosed = Logger.trackLoggerWithExtra<
  AINarrationPanelProps,
  AINarrationPanelProps
>(
  'ai_narration_panel_closed',

  input => {
    return { ...input }
  }
)

type AINarrationPanelNudgeProps = AINarrationPanelProps & { avatarId: string }

export const aiNarrationNudgeShown = Logger.trackLoggerWithExtra<
  AINarrationPanelNudgeProps,
  AINarrationPanelNudgeProps
>(
  'ai_narration_nudge_shown',

  input => {
    return { ...input }
  }
)

export const aiNarrationNudgeAccepted = Logger.trackLoggerWithExtra<
  AINarrationPanelNudgeProps,
  AINarrationPanelNudgeProps
>(
  'ai_narration_nudge_accepted',

  input => {
    return { ...input }
  }
)

export const aiNarrationNudgeDismissed = Logger.trackLoggerWithExtra<
  AINarrationPanelNudgeProps,
  AINarrationPanelNudgeProps
>(
  'ai_narration_nudge_dismissed',

  input => {
    return { ...input }
  }
)

type CardViewedProps = {
  contentId: NanoId12
  fileId: FileId
  cardType: string
  mode: EditorMode
}

export const cardViewed = Logger.trackLoggerWithExtra<CardViewedProps, CardViewedProps>(
  'card_viewed',

  input => {
    return {
      ...input,
    }
  }
)

type GenerativeFeatureUsedProps = {
  contentId?: NanoId12
  programId?: UUID
  contentType?: string
  generativeFeature?: string
}

export const generativeFeatureUsed = Logger.trackLoggerWithExtra<
  GenerativeFeatureUsedProps,
  GenerativeFeatureUsedProps
>(
  'generative_feature_used',

  input => {
    return {
      ...input,
    }
  }
)

type CoursePermissionUpdatedProps = {
  contentId?: NanoId12
  contentType?: string
  coursePermissionType?: 'shareLinkAccessLevel' | 'visibilityInOrg'
  coursePermissionUpdate?: CourseShareLinkAccessLevel | CourseVisibilityInOrg
}

export const coursePermissionUpdated = Logger.trackLoggerWithExtra<
  CoursePermissionUpdatedProps,
  CoursePermissionUpdatedProps
>('course_permission_updated', input => {
  return {
    ...input,
  }
})

type SharingModalCopyLinkButtonClickedProps = {
  contentId?: NanoId12
  contentType?: string
  coursePermissions?: {
    shareLinkAccessLevel: CourseShareLinkAccessLevel
    visibilityInOrg: CourseVisibilityInOrg
  }
}

export const sharingModalCopyLinkButtonClicked = Logger.trackLoggerWithExtra<
  SharingModalCopyLinkButtonClickedProps,
  SharingModalCopyLinkButtonClickedProps
>(
  'sharing_modal_copy_link_button_clicked',

  input => {
    return {
      ...input,
    }
  }
)

type CourseTranslatedProps = {
  courseId: string
  language: string
  courseKind: string
}

export const courseTranslated = Logger.trackLoggerWithExtra<CourseTranslatedProps, CourseTranslatedProps>(
  'course_translated',
  input => {
    return {
      ...input,
    }
  }
)

export const exportTabCourseDuplicated = Logger.trackLoggerWithExtra<
  { courseId: string },
  { courseId: string }
>('export_tab_course_duplicated', input => ({ ...input }))

export const exportTabExportCourseAsPDF = Logger.trackLoggerWithExtra<
  { courseId: string },
  { courseId: string }
>('export_tab_export_course_as_pdf', input => ({ ...input }))

type ExportTabExportCourseAsSCORMProps = {
  courseId: string
  version: '1_2' | '2004'
}
export const exportTabExportCourseAsSCORM = Logger.trackLoggerWithExtra<
  ExportTabExportCourseAsSCORMProps,
  ExportTabExportCourseAsSCORMProps
>('export_tab_export_course_as_scorm', input => ({ ...input }))

export const courseDuplicated = Logger.trackLoggerWithExtra<
  {
    courseId: string
    courseKind: string
  },
  {
    courseId: string
    courseKind: string
  }
>('course_duplicated', input => {
  return {
    ...input,
  }
})
