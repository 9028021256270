import storage from 'local-storage-fallback'
import { capitalize, debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { isDefined } from 'sierra-domain/utils'

const LOCAL_STORAGE_KEY = 'preferredSubtitleLanguage'

const savePreferredSubtitleLanguage = (language?: string): void => {
  try {
    if (language !== undefined) {
      storage.setItem(LOCAL_STORAGE_KEY, language)
    } else {
      storage.removeItem(LOCAL_STORAGE_KEY)
    }
  } catch (error) {
    //ignore issues
  }
}

const getPreferredSubtitleLanguage = (): string | null => {
  return storage.getItem(LOCAL_STORAGE_KEY)
}

const getSubtitleQuery = graphql(`
  query GetSubtitles($videoUrl: String!) {
    video(videoUrl: $videoUrl) {
      id
      subtitles {
        language
        text
      }
    }
  }
`)

export const useInvalidateSubtitleQuery = (videoUrl: string): (() => Promise<void>) => {
  return useInvalidateGraphQuery(getSubtitleQuery, { videoUrl })
}

export const useAddSubtitleTracks = (
  videoUrl?: string,
  enabled: boolean = false
): ((player: VideoJsPlayer) => void) => {
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null)

  const subtitleQuery = useGraphQuery(
    {
      document: getSubtitleQuery,
      queryOptions: {
        enabled: isDefined(player) && isDefined(videoUrl) && enabled,
      },
    },
    { videoUrl: videoUrl ?? '' }
  )

  useEffect(() => {
    if (!player) return

    const onLangChanged = debounce(() => {
      const tracks = player.textTracks()
      const currentLanguage = tracks.tracks_.find(
        track => track.kind === 'captions' && track.mode === 'showing'
      )?.language
      savePreferredSubtitleLanguage(currentLanguage ?? null)
    })

    const trackList = player.textTracks()
    trackList.on('change', onLangChanged)

    return () => {
      trackList.off('change', onLangChanged)
    }
  }, [player])

  useEffect(() => {
    if (subtitleQuery.data !== undefined && player !== null && enabled) {
      const preferredLanguage = getPreferredSubtitleLanguage()
      const subtitles = subtitleQuery.data.video.subtitles.map(subtitle => {
        const vttBlob = new Blob([subtitle.text], {
          type: 'text/vtt',
        })
        const src = URL.createObjectURL(vttBlob)
        const locale = new Intl.DisplayNames([subtitle.language], { type: 'language' })
        const languageLabel = capitalize(locale.of(subtitle.language) ?? subtitle.language)

        return {
          kind: 'captions',
          label: languageLabel,
          language: subtitle.language,
          id: subtitle.language,
          src,
          // This should set the track to enabled by defualt but doesn't seem to do anything
          // We manually enable the track bellow
          default: preferredLanguage === subtitle.language,
        }
      })

      const tracks = subtitles.map(subtitle => {
        const track = player.addRemoteTextTrack(subtitle, true)
        return track
      })

      const trackWithPreferredLanguage = player
        .textTracks()
        .tracks_.find(track => track.language === preferredLanguage)
      if (trackWithPreferredLanguage !== undefined) trackWithPreferredLanguage.mode = 'showing'

      return () => {
        subtitles.forEach(subtitle => {
          URL.revokeObjectURL(subtitle.src)
        })

        tracks.forEach(track => {
          player.removeRemoteTextTrack(track)
        })
      }
    }
  }, [enabled, player, subtitleQuery.data])

  return useCallback((player: VideoJsPlayer) => {
    setPlayer(player)
  }, [])
}
