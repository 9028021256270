import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { homeContentQuery } from 'sierra-client/api/hooks/use-home-content'
import { queryClient } from 'sierra-client/api/query-client'
import { getFlag } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { HomeV3 } from 'sierra-client/views/learner/home/home-v3'
import { prefetchRecentlyEditedQuery } from 'sierra-client/views/learner/home/use-recently-edited-query'

const HomePage: React.FC = () => {
  return <HomeV3 />
}

const RouteComponent = requireLoggedIn(HomePage)

export const Route = createFileRoute('/')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (!context.isLoggedIn) return
    if (!getFlag('preloading')) return

    void queryClient.prefetchQuery(homeContentQuery)
    void prefetchRecentlyEditedQuery()
  },
})
