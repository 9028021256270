import { useEffect, useState } from 'react'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { subscribeSse, useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorCourseContentLength } from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateDescription } from 'sierra-domain/routes-sse'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, TextAreaPrimitive } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const GenerateButton = styled(Button).attrs({ variant: 'ghost' })`
  position: absolute;
  bottom: 12px;
  left: 8px;
`

type GenerateTextAreaProps = {
  value: string
  onChange: (value: string) => void
  placeholder: string
  selfPacedContentId: CourseId
  courseKind: CourseKind
  disabled: boolean
}

export const GenerateDescriptionTextArea: React.FC<GenerateTextAreaProps> = ({
  value,
  onChange,
  placeholder,
  selfPacedContentId,
  courseKind,
  disabled,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isGenerating, setIsGenerating] = useState(false)
  const [generateAttempt, setGenerateAttempt] = useState(0)
  const [localDescription, setLocalDescription] = useState<string | undefined>(undefined)
  const description = localDescription ?? value

  useEffect(() => {
    if (localDescription !== undefined && localDescription !== value) {
      onChange(localDescription)
    }
  }, [localDescription, onChange, value])

  const contentLength = useCachedQuery(
    XRealtimeAuthorCourseContentLength,
    { contentId: selfPacedContentId },
    {
      enabled: disabled !== true,
    }
  ).data?.length

  const disableDescriptionGeneration =
    value.length > 0 ||
    isGenerating ||
    disabled ||
    (contentLength !== undefined && contentLength < 500) ||
    courseKind === 'native:event-group' // doesn't have any content

  const handleGenerateDescription = async (): Promise<void> => {
    setIsGenerating(true)
    setGenerateAttempt(generateAttempt + 1)
    await subscribeSse({
      route: SSEXRealtimeAuthorGenerateDescription,
      input: { courseId: selfPacedContentId },
      onError: e => console.error(e),
      onMessage: event => {
        setLocalDescription(prev => {
          const text = event.data.text
          return prev !== undefined ? prev + text : text
        })
      },
    })
    setIsGenerating(false)
    void dispatch(
      generativeFeatureUsed({
        contentId: selfPacedContentId,
        contentType: 'self-paced',
        generativeFeature: 'generate-course-description',
      })
    )
  }

  return (
    <Container>
      {courseKind === 'native:event-group' ? (
        <FreeTextEditor
          placeholder={placeholder}
          editable={disabled !== true}
          inputId='description'
          content={description}
          useHtml
          textOptionsEnabled={false}
          listOptionsEnabled={false}
          alignmentOptionsEnabled={false}
          strikethroughEnabled={false}
          underlineEnabled={false}
          onChange={e => {
            setLocalDescription(e)
          }}
          menuTranslations={{
            list: t('dictionary.list'),
            alignment: t('create.toolbar.text-alignment'),
            text: t('dictionary.text'),
            heading: t('font.heading'),
          }}
        />
      ) : (
        <TextAreaPrimitive
          id='description'
          resize='none'
          rows={4}
          autoExpand
          placeholder={placeholder}
          value={description}
          disabled={disabled}
          onChange={e => {
            setLocalDescription(e.target.value)
          }}
        />
      )}
      {!disableDescriptionGeneration && (
        <GenerateButton
          type='button'
          variant='ghost'
          loading={isGenerating}
          icon='glitter'
          onClick={handleGenerateDescription}
          disabled={isGenerating}
        >
          {generateAttempt > 0 ? t('author.generate-from-doc.re-generate') : t('author.slate.generate')}
        </GenerateButton>
      )}
    </Container>
  )
}
