export const getHost = (): string => {
  const isLocalhost = window.location.host.includes('localhost')
  const isStaging = window.location.host.includes('staging')

  if (isLocalhost) return 'sanalabs.localhost.sana.dev'
  if (isStaging) return 'master.staging.sana.dev'
  return 'sana.ai'
}

export type ReactSimpleOauth2LoginRef = {
  onBtnClick: () => void
}

export type OauthComponentProps = {
  clientId: string
  onSuccess: () => void
}
