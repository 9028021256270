import { useContext, useEffect, useMemo } from 'react'
import { useBroadcastEditorAwarenessState } from 'sierra-client/collaboration/awareness'
import { connectEditor, destroyEditor } from 'sierra-client/editor'
import { useWarnUnstablePropReference } from 'sierra-client/hooks/use-warn-unstable-prop'
import { typedPost } from 'sierra-client/state/api'
import { FCC } from 'sierra-client/types'
import {
  PolarisEditorContext,
  PolarisEditorProviderValue,
} from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-context'
import { withCollaboration } from 'sierra-client/views/v3-author/collaboration/with-collaboration'
import { withReadOnlyYjsEditor } from 'sierra-client/views/v3-author/collaboration/with-read-only-yjs-editor'
import { CopyPasteWithAssetsOptions } from 'sierra-client/views/v3-author/configuration/copy-paste-with-assets-options'
import { createSanaEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import { withBlankLine } from 'sierra-client/views/v3-author/plugins/with-blank-line'
import { withCardNormalization } from 'sierra-client/views/v3-author/plugins/with-card-normalization'
import { withCard, withTextThenCard } from 'sierra-client/views/v3-author/with-card'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId, ScopedYDocId } from 'sierra-domain/collaboration/types'
import { getSlateDocumentExists, safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SlateFileType } from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XRealtimePrepareExportAssetsAsZip } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import {
  createAssessmentCard,
  createBulletCard,
  createFlipCards,
  createHomeworkCard,
  createPollCard,
  createProjectCard,
  createQuestionCard,
  createReflectionCard,
  createScenarioCard,
  createSlidingScaleCard,
  createStickyNotesCard,
  createTitleCard,
} from 'sierra-domain/v3-author/create-blocks'
import { getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const ContextProvider = PolarisEditorContext.Provider

const commonElements = [
  'paragraph',
  'block-quote',
  'heading',
  'bulleted-list',
  'check-list',
  'video',
  'numbered-list',
  'image',
  'preamble',
  'separator',
  'code',
  'file-attachment',
  'markdown',
  'takeaways',
  'placeholder',
  'table',
] as const

const installFileTypePlugins = (fileType: SlateFileType, editor: SanaEditor): SanaEditor => {
  switch (fileType) {
    case 'general':
      return withCardNormalization(withBlankLine(editor), {
        validRootElements: [
          ...commonElements,
          'question-card',
          'question-variations',
          'flip-cards',
          'horizontal-stack',
          'embed',
          'matrix',
        ],
      })
    case 'notepad':
    case 'external-notepad':
      return withCardNormalization(withBlankLine(editor), {
        validRootElements: [...commonElements],
      })
    case 'slate-card':
      return withCard(editor, { defaultCard: createTitleCard() })
    case 'bullet':
      return withCard(editor, { defaultCard: createBulletCard() })
    case 'poll':
      return withCard(editor, { defaultCard: createPollCard() })
    case 'flip-cards':
      return withCard(editor, { defaultCard: createFlipCards() })
    case 'reflections':
      return withCard(editor, { defaultCard: createReflectionCard() })
    case 'question-card':
      return withCard(editor, { defaultCard: createQuestionCard() })
    case 'sticky-notes':
      return withCard(editor, { defaultCard: createStickyNotesCard() })
    case 'assessment-card':
      return withCard(editor, { defaultCard: createAssessmentCard() })
    case 'homework':
      return withCard(editor, { defaultCard: createHomeworkCard() })
    case 'project-card':
      return withCard(editor, { defaultCard: createProjectCard() })
    case 'scenario':
      return withCard(editor, { defaultCard: createScenarioCard() })
    case 'roleplay':
      return withCard(editor, { defaultCard: createScenarioCard() })
    case 'sliding-scale':
      return withTextThenCard(editor, { defaultCard: createSlidingScaleCard() })
    default:
      assertNever(fileType)
  }
}

const debug = (...messages: unknown[]): void => console.debug('[useInitEditor]', ...messages)

const generateCopyPasteToken = async (courseId: CreateContentId, fileId: NanoId12): Promise<string> => {
  const { signedUrl } = await typedPost(XRealtimePrepareExportAssetsAsZip, {
    courseId,
    fileIds: [fileId],
  })
  return signedUrl
}

function createYjsEditor(props: {
  yDocId: ScopedYDocId
  yDoc: Y.Doc
  awareness: Awareness
  pasteFile: (card: string) => void
  fileId: FileId
  slateFileType: SlateFileType
  readOnly: boolean
  copyPasteAssetOptions: CopyPasteWithAssetsOptions
}): SanaEditor & { key?: string } {
  const { yDocId, yDoc, awareness, pasteFile, fileId, slateFileType, readOnly, copyPasteAssetOptions } = props

  const baseEditor = createSanaEditor({ pasteFile, copyPasteAssetOptions })

  const fileEditor = installFileTypePlugins(slateFileType, baseEditor)

  const yjsEditor: SanaEditor & { key?: string } = iife(() => {
    const yType = getSlateDocumentSharedType(yDoc, fileId)

    if (readOnly) {
      return withReadOnlyYjsEditor(fileEditor, yType, yDocId)
    } else {
      return withCollaboration(fileEditor, {
        yType,
        awareness,
        editorId: fileId,
        yDocId,
      })
    }
  })

  yjsEditor.key = nanoid12()

  return yjsEditor
}

export function useInitEditorWithFile({
  yDocId,
  yDoc,
  awareness,
  pasteFile,
  fileId,
  slateFileType,
  readOnly,
}: {
  yDocId: ScopedYDocId
  yDoc: Y.Doc
  awareness: Awareness
  pasteFile: (card: string) => void
  fileId: FileId
  readOnly: boolean
  slateFileType: SlateFileType | undefined
}): PolarisEditorProviderValue['editor'] {
  const editor = useMemo<SanaEditor | undefined>(() => {
    if (slateFileType === undefined) return undefined
    if (!getSlateDocumentExists(yDoc, fileId)) return undefined

    const createContentId = ScopedCreateContentId.tryExtractId(yDocId)
    const copyPasteAssetOptions: CopyPasteWithAssetsOptions =
      createContentId !== null
        ? {
            type: 'enabled',
            createContentId,
            fileId,
            generateCopyPasteToken,
          }
        : { type: 'disabled' }

    return createYjsEditor({
      yDocId,
      readOnly,
      yDoc,
      awareness,
      pasteFile,
      fileId,
      slateFileType,
      copyPasteAssetOptions,
    })
  }, [slateFileType, yDocId, readOnly, yDoc, awareness, pasteFile, fileId])

  useEffect(() => {
    if (editor === undefined) return

    debug('Connecting editor for file', fileId)
    connectEditor(editor)

    return () => {
      debug('Destroying editor for file', fileId)
      destroyEditor(editor)
    }
  }, [editor, fileId])

  useBroadcastEditorAwarenessState({ editorId: fileId, yDoc, awareness })

  return useMemo(() => {
    if (slateFileType === undefined || editor === undefined) {
      return {
        type: 'unavailable',
        reason: `No editor available for file ${fileId} since the corresponding Slate document was not found.`,
      }
    } else {
      return {
        type: 'available',
        value: editor,
      }
    }
  }, [editor, fileId, slateFileType])
}

function useInitEditor({
  yDocId,
  yDoc,
  awareness,
  pasteFile,
  fileId,
  readOnly,
}: {
  yDocId: ScopedYDocId
  yDoc: Y.Doc
  awareness: Awareness
  pasteFile: (card: string) => void
  fileId: FileId
  readOnly: boolean
}): PolarisEditorProviderValue['editor'] {
  /**
   * Note: This memo function doesn't rerun when the file is removed from the YDoc.
   * Make sure to check whether it still exists.
   */
  const slateFileType = useMemo(() => {
    const fileDataType = safeGetFile(yDoc, fileId)?.data.type
    if (fileDataType === undefined) return undefined

    return SlateFileType.safeParse(fileDataType).data
  }, [fileId, yDoc])

  return useInitEditorWithFile({ yDocId, yDoc, awareness, pasteFile, fileId, slateFileType, readOnly })
}

export const PolarisEditorProvider: FCC<{
  yDocId: ScopedYDocId
  yDoc: Y.Doc
  awareness: Awareness
  pasteFile: (card: string) => void
  fileId: FileId
  readOnly: boolean
}> = props => {
  const { yDoc, awareness, children } = props
  useWarnUnstablePropReference(yDoc, 'yDoc')
  useWarnUnstablePropReference(awareness, 'awareness')

  const editor = useInitEditor(props)

  const value: PolarisEditorProviderValue = useMemo(
    () => ({
      yDoc,
      awareness,
      editor,
    }),
    [yDoc, awareness, editor]
  )

  return <ContextProvider value={value}>{children}</ContextProvider>
}

export const usePolarisContext = (): PolarisEditorProviderValue => {
  const context = useContext(PolarisEditorContext)
  if (context === undefined) throw new Error('Expected component to be wrapped in a <PolarisEditorProvider>')
  return context
}

export const usePolarisContextIfAvailable = (): PolarisEditorProviderValue | undefined => {
  return useContext(PolarisEditorContext)
}
