import { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { OrganizationClusterState } from 'sierra-client/features/multi-tenancy/types'
import { organizationComparator } from 'sierra-client/features/multi-tenancy/utils/organization-comparator'
import { useCachedQuery } from 'sierra-client/state/api'
import { Organization } from 'sierra-domain/organization'
import { XRealtimeUserGetClusterOrganizations, XRealtimeUserGetOrganization } from 'sierra-domain/routes'

function useOrganization(): UseQueryResult<Organization> {
  return useCachedQuery(XRealtimeUserGetOrganization, {})
}

const clusterEmptyState: OrganizationClusterState = {
  loading: true,
  cluster: null,
}

/**
 * Read information about the current organization's multi-tenancy cluster.
 */
export const useOrganizationCluster = (): OrganizationClusterState => {
  const organizationRequest = useOrganization()
  const clusterRequest = useCachedQuery(XRealtimeUserGetClusterOrganizations, {})

  const state = useMemo(() => {
    if (!clusterRequest.isSuccess || !organizationRequest.isSuccess) {
      return clusterEmptyState
    }

    const { domain } = organizationRequest.data
    const currentOrg = clusterRequest.data.cluster.find(org => domain === org.domain)
    const selfIsParent = currentOrg?.isClusterParent === true

    // Sort organizations to always have the parent first
    const sorted = clusterRequest.data.cluster.sort(organizationComparator)

    return { loading: false, cluster: sorted, selfIsParent } satisfies OrganizationClusterState
  }, [clusterRequest, organizationRequest])

  return state
}
