import { useAtomValue } from 'jotai'
import { useEffect, useRef } from 'react'
import { ChatBackendSync } from 'sierra-client/collaboration/chat-provider'
import { ABLY_STATUS_MAPPING } from 'sierra-client/collaboration/types'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useObservabilityTags } from 'sierra-client/components/liveV2/hooks/use-observability-tags'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { LiveSessionIdProvider } from 'sierra-client/components/liveV2/live-session-id-provider'
import { LiveSessionYMapSyncAbly } from 'sierra-client/components/liveV2/live-session-ymap-sync'
import { LiveSidebarProvider } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { SyncFacilitators } from 'sierra-client/components/liveV2/sync-facilitators'
import { usePageViewLogging } from 'sierra-client/core/logging/hooks'
import { LiveSessionPage } from 'sierra-client/core/logging/page/logger'
import { CreatePageYdocState, useLivePageYdoc } from 'sierra-client/hooks/use-create-page-ydoc'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ChatControlProvider } from 'sierra-client/layout/card-page/chat-control-provider'
import { ReduxErrors } from 'sierra-client/layout/sana-page/components/redux-errors'
import { useDispatch } from 'sierra-client/state/hooks'
import { addIssue, clearIssue } from 'sierra-client/state/live/actions'
import { connectionStateAtom } from 'sierra-client/state/realtime-data'
import { FCC } from 'sierra-client/types'
import { CreateContentObserver } from 'sierra-client/views/flexible-content/create-content-observer'
import { PolarisEditorProvider } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { ScopedChatId, ScopedLiveContentId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { LoadingSpinner, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  background-color: ${token('surface/default')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Loading = ({ debugMessage }: { debugMessage: string }): JSX.Element => (
  <LoadingContainer>
    <LoadingSpinner color='white' size='large' />
    <Debug>
      <Text>{debugMessage}</Text>
    </Debug>
  </LoadingContainer>
)

type WithContentRefProps = {
  flexibleContentId: LiveContentId
  liveSessionId: ScopedLiveSessionId
  render: (props: {
    flexibleContentId: LiveContentId
    liveSessionId: ScopedLiveSessionId
  }) => JSX.Element | null
}

const pasteFile = (): void => {}

const PolarisLiveEditorProvider: FCC<{
  context: CreatePageYdocState
}> = ({ children, context }) => {
  const currentCard = useSelectCurrentCard()
  const { yDoc, awareness, yDocId } = context

  if (currentCard === undefined) return <Debug>Current card is not set</Debug>
  return (
    <PolarisEditorProvider
      awareness={awareness}
      yDocId={yDocId}
      yDoc={yDoc}
      fileId={currentCard.id}
      pasteFile={pasteFile}
      readOnly={currentCard.data.type !== 'notepad'}
    >
      {children}
    </PolarisEditorProvider>
  )
}

const LiveSessionLoader: FCC = ({ children }) => {
  const currentCard = useSelectCurrentCard()
  return !currentCard ? <Loading debugMessage='Loading current card' /> : <>{children}</>
}

const LiveSessionContentProviders: React.FC<WithContentRefProps & { context: CreatePageYdocState }> = ({
  flexibleContentId,
  liveSessionId,
  context,
  render,
}): JSX.Element => {
  const { jsonDataYMap } = context

  const chatId = ScopedChatId.fromId(liveSessionId)

  return (
    <LiveSessionIdProvider liveSessionId={liveSessionId}>
      <CreateContentObserver jsonDataYMap={jsonDataYMap} createContentId={flexibleContentId} />
      <LiveSessionYMapSyncAbly liveSessionId={liveSessionId} />

      <SyncFacilitators liveSessionId={liveSessionId} />

      <ChatBackendSync
        chatId={chatId}
        chatIdentifier={{
          type: 'live-session',
          liveSessionId: ScopedLiveSessionId.extractId(liveSessionId),
        }}
      />

      <ChatControlProvider>
        <LiveSessionLoader>
          <PolarisLiveEditorProvider context={context}>
            <ConfirmationModalProvider>
              <LiveSidebarProvider>{render({ liveSessionId, flexibleContentId })}</LiveSidebarProvider>
            </ConfirmationModalProvider>
          </PolarisLiveEditorProvider>
        </LiveSessionLoader>
      </ChatControlProvider>
      <ReduxErrors />
    </LiveSessionIdProvider>
  )
}

const AblyClientConnectionHandler: React.FC = () => {
  const { t } = useTranslation()
  const { push, remove } = useNonExpiringNotif()
  const notificationId = useRef<string>()
  const dispatch = useDispatch()
  const connectionStatus = useAtomValue(connectionStateAtom)

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined
    const status = ABLY_STATUS_MAPPING[connectionStatus]
    if (status === 'connecting') {
      const id = push({
        type: 'custom',
        body: t('live.connection.reconnecting'),
        level: 'info',
      })
      notificationId.current = id
    } else if (status === 'disconnected') {
      // When reloading the window the connection becomes disconnected for a short period of time
      timeout = setTimeout(() => {
        void dispatch(addIssue('content-connection-lost'))
      }, 1000)
    } else {
      if (notificationId.current !== undefined) {
        remove(notificationId.current)
        notificationId.current = undefined
      }
      void dispatch(clearIssue('content-connection-lost'))
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [connectionStatus, dispatch, push, remove, t])

  return null
}

export const WithContentRef = ({
  flexibleContentId,
  liveSessionId,
  render,
}: WithContentRefProps): JSX.Element | null => {
  const scopedCreateContentId = ScopedLiveContentId.fromId(flexibleContentId)

  const liveSession = useLiveSessionContext()
  usePageViewLogging(LiveSessionPage({ liveSessionId, sessionTitle: liveSession.data.title }))

  const context = useLivePageYdoc(scopedCreateContentId, { isLiveSession: true })

  useObservabilityTags({
    flexibleContentId,
    liveSessionId: ScopedLiveSessionId.extractId(liveSessionId),
    videoCallSetting: liveSession.data.videoCallSetting.type,
  })

  if (context === undefined) {
    return <Loading debugMessage='Loading flexible content state' />
  }
  return (
    <>
      <LiveSessionContentProviders
        flexibleContentId={flexibleContentId}
        liveSessionId={liveSessionId}
        context={context}
        render={render}
      />
      <AblyClientConnectionHandler />
    </>
  )
}
