/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createRouter,
  ErrorRouteComponent,
  notFound,
  PathParamError,
  RouterProvider,
} from '@tanstack/react-router'
import { useEffect, useMemo, useState } from 'react'
import { errorLogger } from 'sierra-client/error/error-logger'
import { setGlobalRouter } from 'sierra-client/router'
import { createBrowserHistory as createBrowserHistoryWithoutBeforeUnload } from 'sierra-client/router/browser-history-no-beforeunload'
import { routeTree } from 'sierra-client/routeTree.gen'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { WhoopsPage } from 'sierra-client/views/whoops-page'
import { User } from 'sierra-domain/user'

export type SierraRouterContext = {
  isLoggedIn: boolean
  user?: User
}

const rootContext = {
  isLoggedIn: false,
  user: undefined,
} satisfies SierraRouterContext

const router = initGlobalRouter()

const DefaultRouterError: ErrorRouteComponent = ({ error }) => {
  const [sentryEventId, setSentryEventId] = useState<string | undefined>()

  useEffect(() => {
    console.error(error)

    const eventId = errorLogger.captureError(error, { tags: { errorBoundary: 'router-default' } })
    setSentryEventId(eventId)
  }, [error, setSentryEventId])

  return <WhoopsPage error={error} sentryEventId={sentryEventId} />
}

export const SierraRouterProvider = (): JSX.Element => {
  const user = useSelector(selectUser)

  const context: SierraRouterContext = useMemo(
    () => ({
      isLoggedIn: !!user,
      user,
    }),
    [user]
  )

  useEffect(() => {
    // The router context gets recomputed when routing, but to explicitly update it we must invalidate the current tree.
    // https://tanstack.com/router/v1/docs/framework/react/guide/router-context#invalidating-the-router-context
    if (context.user !== undefined) {
      void router.invalidate()
    }
  }, [context])

  return <RouterProvider router={router} context={context} defaultErrorComponent={DefaultRouterError} />
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- The returned type defines the global router type.
function initGlobalRouter() {
  const router = createRouter({
    routeTree,
    context: rootContext,
    history: createBrowserHistoryWithoutBeforeUnload(),
    defaultOnCatch: error => {
      // By default we expect path param validation to result in a 404, not a 500.
      if (error instanceof PathParamError) {
        throw notFound()
      }
    },
  })

  setGlobalRouter(router)

  return router
}

//
// Module augmentation to extend the global router type, so that it uses our
// global router's type for global functions by default. This lets us avoid
// passing around the router object in our app.
//
declare module '@tanstack/react-router' {
  interface Register {
    router: ReturnType<typeof initGlobalRouter>
  }

  /**
   * The state property of the Location and History objects,
   * which can be passed during navigation without affecting the URL.
   */
  interface HistoryState {}
}
