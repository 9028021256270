import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useSelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { useTeamspaceByContentId } from 'sierra-client/api/hooks/use-teamspace'
import { Link } from 'sierra-client/components/common/link'
import {
  CourseVisibilityDropdown,
  ShareLinkAccessDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { CopyLinkButton } from 'sierra-client/components/sharing/tabs/components/copy-link-button'
import { SessionListButton } from 'sierra-client/components/sharing/tabs/components/session-list-button'
import { SHARING_CONTENT_HELP_PAGE_URL } from 'sierra-client/config/links'
import {
  coursePermissionUpdated,
  sharingModalCopyLinkButtonClicked,
} from 'sierra-client/core/logging/authoring/logger'
import { useContentIds } from 'sierra-client/hooks/use-content-ids'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { selectTemplateSettings } from 'sierra-client/state/author-course-settings/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CoursePermissionSettings,
  useCoursePermissionSettings,
} from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { derivePublishButtonState } from 'sierra-client/views/flexible-content/editor/topbar/self-paced-content-topbar'
import { CourseShareLinkAccessLevel } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SharingContainer = styled(View)`
  position: relative;
  width: 100%;
`

const StyledLink = styled(Link)`
  display: flex;
  gap: ${spacing['6']};
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const deriveVisibilitySubtitleFromSettings = (
  coursePermissionSettings: CoursePermissionSettings
): TranslationKey => {
  if (coursePermissionSettings.status === 'done') {
    switch (coursePermissionSettings.visibilityInOrg) {
      case 'private':
        return 'share.visibility-permission.subtitle-private'
      case 'visible-admins':
        return 'share.visibility-permission.subtitle-visible-admins'
      case 'visible-everyone':
        return 'share.visibility-permission.subtitle-visible-everyone'
    }
    coursePermissionSettings.visibilityInOrg satisfies never
  }
  return 'share.visibility-permission.subtitle'
}

const deriveLinkPermissionSubtitleFromSettings = (
  coursePermissionSettings: CoursePermissionSettings
): TranslationKey => {
  if (coursePermissionSettings.status === 'done') {
    switch (coursePermissionSettings.shareLinkAccessLevel) {
      case 'invite-only':
        return 'share.link-permission.subtitle-invite-only'
      case 'can-view':
        return 'share.link-permission.subtitle-can-view'
      case 'can-comment':
        return 'share.link-permission.subtitle-can-comment'
      case 'can-edit':
        return 'share.link-permission.subtitle-can-edit'
    }
    coursePermissionSettings.shareLinkAccessLevel satisfies never
  }
  return 'share.link-permission.subtitle'
}

function usePublicTeamspaceVisiblity(contentId: NanoId12): 'not-found' | 'private' | 'public' | 'loading' {
  const teamspaceQuery = useTeamspaceByContentId(contentId, { enabled: true })
  const teamspaceVisibility = useMemo(() => {
    const teamspace = teamspaceQuery.data

    if (teamspace === undefined) return 'loading'
    else if (teamspace.type === 'found') return teamspace.visibility
    else return 'not-found'
  }, [teamspaceQuery.data])

  return teamspaceVisibility
}

export const CoursePermissionSection: React.FC = () => {
  const { t } = useTranslation()

  const { selfPacedContentId, liveContentId } = useContentIds()
  const contentId = selfPacedContentId || liveContentId
  assertIsNonNullable(contentId)
  const dispatch = useDispatch()
  const publishState = useSelfPacedPublishState(selfPacedContentId)
  const { latestYUpdateIdAtom } = useCreatePageYDocContext()
  const latestYUpdateId = useAtomValue(latestYUpdateIdAtom)
  const publishButtonState = derivePublishButtonState(publishState.state, latestYUpdateId)

  const isSelfPacedCourse = selfPacedContentId !== undefined
  const isSelfPacedDraft = isSelfPacedCourse && publishButtonState === 'draft'

  const templateSettings = useSelector(selectTemplateSettings)
  const isTemplate = templateSettings !== undefined

  const copyLink = new URL(window.location.href)

  const { coursePermissionSettings, updateShareLinkAccessLevel, updateVisibilityInOrg } =
    useCoursePermissionSettings()

  const teamspaceVisiblity = usePublicTeamspaceVisiblity(contentId)

  const displayVisibilityPermissionSection = !isTemplate
  const coursePermissions = useContentKindPermissions(contentId)

  const isEditAccessSharingAllowed = coursePermissions.has('EDIT_SHARING_SETTINGS')

  const courseVisibilityDropdownAllowedItems = useCourseVisibilityDropdownAllowedItems(contentId, true)

  const shareLinkAccessDropdownAllowedItems = useMemo(() => {
    let items: CourseShareLinkAccessLevel[] = ['invite-only', 'can-view', 'can-comment', 'can-edit']

    if (isTemplate) {
      items = items.filter(value => value !== 'can-view')
    }

    if (teamspaceVisiblity === 'public') {
      items = items.filter(value => value !== 'invite-only')
    }

    if (
      coursePermissionSettings.status === 'done' &&
      coursePermissionSettings.visibilityInOrg === 'visible-everyone' &&
      !coursePermissions.has('EDIT_VISIBLE_EVERYWHERE')
    ) {
      items = items.filter(value => value !== 'invite-only')
    }

    return items
  }, [coursePermissionSettings, coursePermissions, isTemplate, teamspaceVisiblity])

  const sendSegmentLog = (): void => {
    if (coursePermissionSettings.status === 'done') {
      void dispatch(
        sharingModalCopyLinkButtonClicked({
          contentId,
          contentType: isSelfPacedCourse ? 'self-paced' : 'live',
          coursePermissions: {
            shareLinkAccessLevel: coursePermissionSettings.shareLinkAccessLevel,
            visibilityInOrg: coursePermissionSettings.visibilityInOrg,
          },
        })
      )
    }
  }

  return (
    <SharingContainer direction='column' gap='12'>
      <Text size='small' as='h2' bold>
        {t('share.label.permissions')}
      </Text>
      {teamspaceVisiblity !== 'loading' && (
        <PermissionFormSection
          iconId='link'
          title={t('share.link-permission.title')}
          subtitle={t(deriveLinkPermissionSubtitleFromSettings(coursePermissionSettings))}
        >
          <AnimatePresence>
            {coursePermissionSettings.status === 'done' ? (
              <motion.div
                key='dropdown'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.1 }}
              >
                <ShareLinkAccessDropdown
                  selectedShareLinkAccess={coursePermissionSettings.shareLinkAccessLevel}
                  onSelect={async id => {
                    await Promise.all([
                      updateShareLinkAccessLevel(contentId, id),
                      dispatch(
                        coursePermissionUpdated({
                          contentId,
                          contentType: isSelfPacedCourse ? 'self-paced' : 'live',
                          coursePermissionType: 'shareLinkAccessLevel',
                          coursePermissionUpdate: id,
                        })
                      ),
                    ])

                    void coursePermissions.refetch()
                  }}
                  type={isSelfPacedCourse ? 'self-paced' : 'live'}
                  disabled={!isEditAccessSharingAllowed}
                  status={isSelfPacedDraft ? 'draft' : 'published'}
                  allowedItems={shareLinkAccessDropdownAllowedItems}
                />
              </motion.div>
            ) : undefined}
          </AnimatePresence>
        </PermissionFormSection>
      )}
      {displayVisibilityPermissionSection && (
        <PermissionFormSection
          iconId='building'
          title={t('share.visibility-permission.title')}
          subtitle={t(deriveVisibilitySubtitleFromSettings(coursePermissionSettings))}
        >
          <AnimatePresence>
            {coursePermissionSettings.status === 'done' ? (
              <motion.div
                key='dropdown'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.1 }}
                transition={{ delay: 0.03 }}
              >
                <CourseVisibilityDropdown
                  selectedVisibility={coursePermissionSettings.visibilityInOrg}
                  onSelect={id => {
                    void updateVisibilityInOrg(contentId, id)
                    void dispatch(
                      coursePermissionUpdated({
                        contentId,
                        contentType: isSelfPacedCourse ? 'self-paced' : 'live',
                        coursePermissionType: 'visibilityInOrg',
                        coursePermissionUpdate: id,
                      })
                    )
                  }}
                  disabled={
                    !isEditAccessSharingAllowed ||
                    (coursePermissionSettings.visibilityInOrg === 'visible-everyone' &&
                      !coursePermissions.has('EDIT_VISIBLE_EVERYWHERE'))
                  }
                  status={isSelfPacedDraft ? 'draft' : 'published'}
                  options={courseVisibilityDropdownAllowedItems}
                />
              </motion.div>
            ) : undefined}
          </AnimatePresence>
        </PermissionFormSection>
      )}
      <View justifyContent='space-between'>
        <View gap={'4'}>
          <StyledLink target='_blank' href={SHARING_CONTENT_HELP_PAGE_URL} size='small'>
            <Icon iconId='information' color='currentColor' />
            {t('share.label.help-permissions')}
          </StyledLink>
        </View>
        <View gap='16'>
          {!isSelfPacedCourse && <SessionListButton />}
          <CopyLinkButton sendSegmentLog={sendSegmentLog} text={copyLink.href} />
        </View>
      </View>
    </SharingContainer>
  )
}
