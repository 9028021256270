import { RandomEditorActions } from 'sierra-client/editor/actions/random-editor-actions'
import { TextToSpeechHoverTarget } from 'sierra-client/editor/text-to-speech'
import { FCC } from 'sierra-client/types'
import { CursorEditable, CursorEditableProps } from 'sierra-client/views/v3-author/cursors/cursor-editable'
import { useOnKeyDown } from 'sierra-client/views/v3-author/use-on-key-down'
import { spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { Range } from 'slate'
import { useFocused, useSlateSelector } from 'slate-react'
import styled, { css } from 'styled-components'

/* Wrapper around the editor instance
  [*] Handle cursors
  [*] Handles keyboard events
* */

export type LayoutType = 'page-card' | 'interactive-card'

const StyledCursorEditable = styled(CursorEditable)<{
  $hasRangedSelection: boolean
  $layout: LayoutType
}>`
  width: 100%;
  min-height: 100%;

  display: flex;
  flex: 1 1 auto;
  height: auto;

  isolation: isolate; /* TODO(seb): Not sure what this is used for or if it's still needed */
  background-color: transparent;
  flex-direction: column;

  & > * + * {
    margin-top: ${spacing['16']};
  }

  & > p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: ${spacing['4']};
  }

  ${p =>
    p.$layout === 'page-card' &&
    css`
      padding: ${spacing['80']} ${spacing.large};
    `}

  ${p =>
    p.$layout === 'interactive-card' &&
    css`
      && {
        > * {
          --middle: minmax(1rem, 6rem);
          --gutter: 1fr;

          @media screen and (max-width: ${v2_breakpoint.phone}) {
            --gutter: 4%;
          }
        }
      }
      padding: ${spacing['80']} ${spacing['8']};

      justify-content: center;
    `}

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    padding-right: 0;
    padding-left: 0;
  }

  [data-slate-node='element'] {
    /* All slate nodes must be 'position: relative' to support the BlockMenu */
    position: relative;
  }

  [data-text-to-speech='true']:hover {
    /* Show text to speech menu on hover if the element supports it */
    ${TextToSpeechHoverTarget} {
      opacity: 1;
    }
  }

  /**
   * When the editor has a ranged selection, we only want to be able to copy text from the slate document.
   * Other elements in the UI (such as buttons) should automatically be removed from the selection
   */
  ${p =>
    p.$hasRangedSelection &&
    css`
      user-select: none;
      [data-slate-string='true'] {
        user-select: text;
      }
    `}
`

const _EditorInner: FCC<CursorEditableProps & { layout?: LayoutType }> = ({ children, layout, ...props }) => {
  const isFocused = useFocused()
  const hasRangedSelection = useSlateSelector(
    editor => editor.selection !== null && !Range.isCollapsed(editor.selection)
  )
  return (
    <StyledCursorEditable {...props} $layout={layout} $hasRangedSelection={isFocused && hasRangedSelection}>
      {children}
    </StyledCursorEditable>
  )
}

export const EditorInner: FCC<CursorEditableProps & { layout?: LayoutType }> = ({ children, ...props }) => {
  const onKeyDown = useOnKeyDown()
  return (
    <>
      <RandomEditorActions onKeyDown={onKeyDown} />
      <_EditorInner onKeyDown={onKeyDown} {...props}>
        {children}
      </_EditorInner>
    </>
  )
}
