import { TimeSinceSent } from 'sierra-client/components/chat/chat-message/time-since-sent'
import { RouterLink } from 'sierra-client/components/common/link'
import { useUser } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { HomeNotification } from 'sierra-domain/api/learn'
import { UserId } from 'sierra-domain/api/uuid'
import { assertNever, getUserName, iife, isDefined } from 'sierra-domain/utils'
import { Icon, IconId, RoundAvatar } from 'sierra-ui/components'
import { InternalTruncatedText, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper, dotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const notificationDimension = '24px'

const IconContainer = styled.div`
  height: ${notificationDimension};
  width: ${notificationDimension};
  max-width: ${notificationDimension};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled(InternalTruncatedText).attrs({ lines: 1 })`
  flex-shrink: 0;
`

export const PlaceholderTitleText = styled(Text).attrs({
  as: 'span',
  size: 'regular',
  bold: true,
})``

const ContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 150ms;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${token('surface/soft')};
  }
`

export const NotificationContainer: React.FC<{
  icon: React.ReactNode
  title: React.ReactNode
  content: React.ReactNode
  notification: HomeNotification
  cardUrl?: string
}> = ({ icon, title, content, notification, cardUrl }) => {
  const { createdAt } = notification

  return (
    <>
      <Debug>
        <View background='warning/background' padding='4'>
          <Text bold size='micro' color='warning/foreground'>
            ({notification.id}) {notification.type}
          </Text>
        </View>
      </Debug>

      <ConditionalWrapper
        condition={isDefined(cardUrl)}
        renderWrapper={children =>
          isDefined(cardUrl) ? <RouterLink href={cardUrl}>{children}</RouterLink> : children
        }
      >
        <ContainerContainer>
          <View justifyContent='space-between'>
            <View gap='12'>
              <IconContainer>{icon}</IconContainer>
              {title}
            </View>
            <Text color='foreground/muted' size='small'>
              <TimeSinceSent timestamp={createdAt.toISOString()} />
            </Text>
          </View>
          <View gap='12' alignItems='flex-start'>
            <IconContainer />
            {content}
          </View>
        </ContainerContainer>
      </ConditionalWrapper>
    </>
  )
}
export const UserAvatar: React.FC<{ userId: UserId }> = ({ userId }) => {
  const user = useUser(userId)

  return (
    <>
      {user?.status === 'loaded' ? (
        <RoundAvatar
          size='small'
          firstName={user.firstName}
          lastName={user.lastName}
          src={getAvatarImage(user.uuid, user.avatar)}
          color={user.avatarColor}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

export function useUsername(userId: UserId): string {
  const user = useUser(userId)

  if (user?.status === 'loaded') {
    return getUserName(user) ?? ''
  } else {
    return '...'
  }
}

export const MetadataWithIcon: React.FC<{
  type: 'course' | 'event' | 'live'
  subtitle?: string
}> = ({ type, subtitle }) => {
  const { iconId, label } = iife((): { iconId: IconId; label: string } => {
    switch (type) {
      case 'course':
        return { iconId: 'course', label: 'Course' }
      case 'event':
        return { iconId: 'calendar', label: 'Event' }
      case 'live':
        return { iconId: 'play--circle--filled', label: 'Live' }
      default:
        assertNever(type)
    }
  })

  return (
    <View gap='4'>
      <Icon color='foreground/muted' iconId={iconId} />
      <Text color='foreground/muted' bold>
        {label}
      </Text>

      {subtitle !== undefined && (
        <>
          <Text color='foreground/muted'>{dotSeparator}</Text>
          <Text color='foreground/muted'>{subtitle}</Text>
        </>
      )}
    </View>
  )
}
