import { atom } from 'jotai'
import { round } from 'lodash'
import { Duration } from 'luxon'
import { resolveAvatarImageUnionUrl } from 'sierra-client/features/insights/display-widgets/data-utils'
import {
  getMonthLabelFromTableValue,
  getQuarterLabelFromTableValue,
  getWeekLabelFromTableValue,
} from 'sierra-client/features/insights/display-widgets/table/table-value-utils'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { getPercentage } from 'sierra-client/features/insights/display-widgets/value-utils'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { CellHeader } from 'sierra-client/lib/tabular/datatype/column'
import { Data } from 'sierra-client/lib/tabular/datatype/data'
import {
  CellCardBase,
  CellContentBase,
  CellGroupBase,
  CellStringBase,
  CellUserBase,
} from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { TabularLabel, labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { ContentItem } from 'sierra-client/views/manage/content/utils/content-utils'
import {
  CourseData,
  PathData,
  ProgramData,
  TableColumn,
  TableResult,
  TableRow,
  TableValue,
  ValueColumnRef,
} from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'

const colType2DataType = {
  'type.string': 'strings',
  'type.enum': 'strings',
  'type.boolean': 'booleans',
  'type.long': 'numbers',
  'type.double': 'numbers',
  'type.date': 'strings',
  'type.timestamp': 'strings',
  'type.uuid': 'strings',
  'type.duration': 'duration',
  'type.ratio': 'strings',
  'type.progress': 'pills',
  'type.course': 'content',
  'type.group': 'groups',
  'type.program': 'content',
  'type.user': 'users',
  'type.path': 'content',
  'type.content': 'content',
  'type.card': 'card',
  'type.exercise': 'strings',
  'type.poll-option': 'card',
  'type.skill': 'strings',
  'type.skill-level': 'strings',
  'type.year': 'numbers',
  'type.quarter': 'strings',
  'type.month': 'strings',
  'type.week': 'strings',
  'type.certificate': 'certificates',
  'type.course-tag': 'strings',
} as const satisfies Record<TableColumn['type']['type'], Data['type']>

const dummyContentAttributes = {
  learnersCount: -1,
  createdAt: '1970-01-01T00:00:00.000Z',
  tags: [],
  isFeatured: false,
  isPublicVisibility: false,
}

const contentFromCourse = (course: CourseData): ContentItem => ({
  contentType: 'course',
  id: course.id,
  title: course.title,
  image: course.image,
  courseKind: course.courseKind,
  isCourseEdition: course.isCourseEdition,
  //These fields are not used
  ...dummyContentAttributes,
})

const contentFromPath = (path: PathData): ContentItem => ({
  contentType: 'path',
  id: path.id,
  title: path.title,
  image: path.image,
  isCourseEdition: false,
  //These fields are not used
  ...dummyContentAttributes,
})

const contentFromProgram = (program: ProgramData): ContentItem => ({
  contentType: 'program',
  id: program.id,
  title: program.name,
  image: program.image,
  //These fields are not used
  courseKind: undefined,
  isCourseEdition: false,
  ...dummyContentAttributes,
})

export const toData = (row: TableRow, column: TableColumn, t: TranslationLookup): Data => {
  const value = row.values[column.name]

  if (value?.type === 'value.none') {
    return {
      type: 'strings',
      data: undefined,
    }
  }
  switch (column.type.type) {
    case 'type.string':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.string' ? value.value : undefined,
      }
    case 'type.enum':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.enum'
            ? value.label !== undefined
              ? labelToString(toLabel(value.label), t)
              : value.value
            : undefined,
      }
    case 'type.boolean':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.boolean' ? value.value : undefined,
      }
    case 'type.long':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.long' ? value.value : undefined,
      }
    case 'type.double':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.double' && value.value !== undefined ? round(value.value, 2) : undefined,
      }
    case 'type.date':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.date' ? value.value : undefined,
      }
    case 'type.timestamp':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.timestamp' ? value.value : undefined,
      }
    case 'type.uuid':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.uuid' ? value.value : undefined,
      }
    case 'type.duration':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.duration'
            ? value.value !== undefined
              ? Duration.fromISO(value.value)
              : undefined
            : undefined,
      }
    case 'type.ratio':
      return {
        type: colType2DataType[column.type.type],
        data:
          (value?.type === 'value.ratio' || value?.type === 'value.progress') && value.value !== undefined
            ? getPercentage(value.value)
            : undefined,
      }
    case 'type.progress': {
      const type = colType2DataType[column.type.type]

      if (value === undefined || value.type !== 'value.progress') {
        return { type, data: { type: 'error', text: '' } }
      }

      if (value.value === 1) {
        return { type, data: { type: 'success', text: t('dictionary.completed') } }
      }

      return {
        type,
        data: { type: 'ghost', text: getPercentage(value.value) },
      }
    }
    case 'type.course':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.course' && value.course !== undefined
            ? contentFromCourse(value.course)
            : undefined,
      }
    case 'type.group':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.group' && value.group !== undefined
            ? {
                id: value.group.id,
                name: value.group.name,
                avatar: value.group.avatar,
              }
            : undefined,
      }
    case 'type.program':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.program' && value.program !== undefined
            ? contentFromProgram(value.program)
            : undefined,
      }
    case 'type.user':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.user' && value.user !== undefined
            ? {
                id: value.user.id,
                firstName: value.user.firstName,
                lastName: value.user.lastName,
                displayName: value.user.displayName,
                email: value.user.email,
                active: value.user.status === 'active',
                status: value.user.status,
                avatarColor: value.user.avatar.type === 'color' ? value.user.avatar.color : undefined,
                avatar:
                  value.user.avatar.type === 'image'
                    ? resolveAvatarImageUnionUrl(value.user.id, value.user.avatar.image)?.url
                    : undefined,
              }
            : undefined,
      }
    case 'type.path':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.path' && value.path !== undefined ? contentFromPath(value.path) : undefined,
      }
    case 'type.year':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.year' ? value.year : undefined,
      }
    case 'type.quarter':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.quarter' ? getQuarterLabelFromTableValue(value) : undefined,
      }
    case 'type.month':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.month' ? getMonthLabelFromTableValue(value) : undefined,
      }
    case 'type.week':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.week' ? getWeekLabelFromTableValue(value) : undefined,
      }
    case 'type.content': {
      const getData = (): ContentItem | undefined => {
        if (value?.type === 'value.program' && value.program !== undefined) {
          return contentFromProgram(value.program)
        } else if (value?.type === 'value.course' && value.course !== undefined) {
          return contentFromCourse(value.course)
        } else if (value?.type === 'value.path' && value.path !== undefined) {
          return contentFromPath(value.path)
        } else {
          return undefined
        }
      }

      return {
        type: colType2DataType[column.type.type],
        data: getData(),
      }
    }
    case 'type.card':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.card' && value.card !== undefined ? value.card : undefined,
      }
    case 'type.exercise':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.exercise' ? value.exercise?.title : undefined,
      }
    case 'type.poll-option':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.poll-option' && value.pollOption !== undefined
            ? {
                id: value.pollOption.id,
                title: value.pollOption.title,
                image: value.pollOption.image,
                cardType: 'poll',
              }
            : undefined,
      }
    case 'type.skill':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.skill' ? value.skill?.name : undefined,
      }
    case 'type.skill-level':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.skill-level' ? value.skillLevel?.name : undefined,
      }
    case 'type.certificate':
      return {
        type: colType2DataType[column.type.type],
        data:
          value?.type === 'value.certificate' && value.certificate !== undefined
            ? {
                id: value.certificate.id,
                title: value.certificate.title,
                previewImageUrl: value.certificate.previewImageUrl,
              }
            : undefined,
      }
    case 'type.course-tag':
      return {
        type: colType2DataType[column.type.type],
        data: value?.type === 'value.course-tag' ? value.tag?.name : undefined,
      }
    default:
      assertNever(column.type)
  }
}

const TABLE_VALUES_AS_CELL = [
  'value.course',
  'value.user',
  'value.group',
  'value.program',
  'value.path',
  'value.card',
  'value.quarter',
  'value.month',
  'value.week',
] as const

type CellTableValueTypes = (typeof TABLE_VALUES_AS_CELL)[number]

type CellTableValue = Extract<TableValue, { type: CellTableValueTypes }>

const isCellTableValue = (tableValue: TableValue): tableValue is CellTableValue => {
  return TABLE_VALUES_AS_CELL.some(type => type === tableValue.type)
}

type ColumnHeaderCellBase = CellContentBase | CellUserBase | CellGroupBase | CellStringBase | CellCardBase

const valueToCell = (tableValue: CellTableValue): ColumnHeaderCellBase | undefined => {
  switch (tableValue.type) {
    case 'value.course':
      if (tableValue.course === undefined) {
        return undefined
      }
      return {
        type: 'content',
        data: contentFromCourse(tableValue.course),
      }
    case 'value.user':
      if (tableValue.user === undefined) {
        return undefined
      }
      return {
        type: 'user',
        data: {
          id: tableValue.user.id,
          firstName: tableValue.user.firstName,
          lastName: tableValue.user.lastName,
          displayName: tableValue.user.displayName,
          email: tableValue.user.email,
          active: tableValue.user.status === 'active',
          status: tableValue.user.status,
          avatarColor: tableValue.user.avatar.type === 'color' ? tableValue.user.avatar.color : undefined,
          avatar:
            tableValue.user.avatar.type === 'image'
              ? resolveAvatarImageUnionUrl(tableValue.user.id, tableValue.user.avatar.image)?.url
              : undefined,
        },
      }
    case 'value.group':
      if (tableValue.group === undefined) {
        return undefined
      }
      return {
        type: 'group',
        data: {
          id: tableValue.group.id,
          name: tableValue.group.name,
          avatar: tableValue.group.avatar,
        },
      }
    case 'value.program':
      if (tableValue.program === undefined) {
        return undefined
      }
      return {
        type: 'content',
        data: contentFromProgram(tableValue.program),
      }

    case 'value.path':
      if (tableValue.path === undefined) {
        return undefined
      }
      return {
        type: 'content',
        data: contentFromPath(tableValue.path),
      }
    case 'value.card':
      if (tableValue.card === undefined) {
        return undefined
      }
      return {
        type: 'card',
        data: tableValue.card,
      }
    case 'value.quarter':
      return {
        type: 'string',
        data: getQuarterLabelFromTableValue(tableValue),
      }
    case 'value.month':
      return {
        type: 'string',
        data: getMonthLabelFromTableValue(tableValue),
      }
    case 'value.week':
      return {
        type: 'string',
        data: getWeekLabelFromTableValue(tableValue),
      }
    default:
      assertNever(tableValue)
  }
}

const getHeaderFromColumn = (column: TableColumn): CellHeader | TabularLabel => {
  const columnValue: ValueColumnRef | undefined = column.ref.type === 'column.value' ? column.ref : undefined

  if (columnValue === undefined) return toLabel(column.label)
  if (!isCellTableValue(columnValue.value)) return toLabel(column.label)

  const columnHeaderCellbase = valueToCell(columnValue.value)
  if (columnHeaderCellbase === undefined) return toLabel(column.label)

  const enabled = atom(true)
  const selected = atom(false)

  return {
    type: 'cell',
    cell: {
      ...columnHeaderCellbase,
      enabled,
      selected,
      pos: { column: column.name, row: '0' },
      hints: ['open-in-new-tab'],
    },
    label: toLabel(column.label),
  }
}

export const resultToTableData = (
  rows: TableResult['rows'],
  schema: TableResult['schema'],
  t: TranslationLookup,
  sortable: boolean
): TableData => ({
  nested: {},
  columns: schema.columns.map(column => {
    return {
      ref: column.name,
      header: getHeaderFromColumn(column),
      type: colType2DataType[column.type.type],
      hints: ['open-in-new-tab'],
      enabled: true,
      sortable,
      tooltip: undefined,
    }
  }),
  rows: rows.map((row, index) => {
    return {
      id: String(index),
      data: Object.fromEntries(schema.columns.map(column => [column.name, toData(row, column, t)])),
    }
  }),
})

export const resultDataLoader = (
  result: TableResult,
  t: TranslationLookup,
  sortable: boolean = false
): DL.DataLoaderStateMachine<TableData, Record<string, never>> =>
  DL.createDataLoader({
    fetchInit() {
      return Promise.resolve({
        meta: {},
        data: result.rows,
        totalCount: result.rows.length,
        done: true,
      })
    },

    // This should be unreachable since `fetchInit` always returns `done: true`
    fetchMore({ pagination }) {
      return Promise.resolve({
        meta: {},
        data: [],
        totalCount: pagination.total,
        done: true,
      })
    },

    transformResults(data) {
      return [resultToTableData(data, result.schema, t, sortable)]
    },
  })
