import React from 'react'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, IconId, Tooltip, TruncatedText } from 'sierra-ui/components'
import {
  Button,
  HeadingSizes,
  LoadingSpinner,
  Spacer,
  Text,
  TextAreaPrimitive,
  View,
} from 'sierra-ui/primitives'
import { TextAreaPrimitiveProps } from 'sierra-ui/primitives/form/text-area-primitive'
import { palette, spacing, token } from 'sierra-ui/theming'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import { Padding, SanaColor } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

type StyledInfoIconProps = {
  noMargin?: boolean
}
const StyledInfoIcon = styled(Icon).attrs({
  iconId: 'information',
  color: 'grey25',
})<StyledInfoIconProps>`
  vertical-align: middle;
  margin-left: ${p => (p.noMargin === true ? undefined : '0.25rem')};
`

export const InfoIcon: React.FC<{
  title?: React.ComponentProps<typeof Tooltip>['title']
  noMargin?: boolean
}> = ({ title, noMargin }) => {
  if (title !== undefined) {
    return (
      <Tooltip title={title}>
        <StyledInfoIcon noMargin={noMargin} />
      </Tooltip>
    )
  } else {
    return <StyledInfoIcon noMargin={noMargin} />
  }
}

type TitleInputProps = {
  size?: HeadingSizes
  color?: SanaColor
  background?: SanaColor
  placeholderColor?: SanaColor | 'none'
  focusBorderColor?: SanaColor | 'none'
  noValueBorderColor?: SanaColor | 'none'
} & Pick<TextAreaPrimitiveProps, 'value' | 'onChange' | 'placeholder'>

const TitleInputInner = styled(TextAreaPrimitive).attrs({
  autoFocus: true,
  rows: 1,
  autoExpand: true,
})<TitleInputProps>`
  outline: none;

  ${p => fonts.heading[p.size ?? 'h4']};
  font-weight: ${fonts.weight.medium};

  ${p =>
    p.color !== undefined &&
    css`
      color: ${color(p.color).toString()};
    `}
  ${p =>
    p.background !== undefined &&
    css`
      background-color: ${color(p.background).toString()};
    `}
    &::placeholder,
    &::-webkit-input-placeholder {
    color: ${p =>
      p.placeholderColor === undefined || p.placeholderColor === 'none'
        ? 'inherit'
        : color(p.placeholderColor)};
  }
`

export const TitleInput: React.FC<TitleInputProps> = ({
  noValueBorderColor = 'none',
  focusBorderColor = 'none',
  placeholderColor = 'grey10',
  ...props
}) => (
  <TitleInputInner
    {...props}
    noValueBorderColor={noValueBorderColor}
    focusBorderColor={focusBorderColor}
    placeholderColor={placeholderColor}
  />
)

export const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ManageDetailGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar content';
  gap: 2rem;
  column-gap: 2rem;

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'sidebar' 'content';
  }
`

export const ManageDetailSidebar = styled.div`
  grid-area: sidebar;
  min-width: 0; /* Don't grow with content */
`

export const ManageDetailContent = styled.div`
  grid-area: content;
`

export const ManageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ManageDetailContainer = styled.div``

export const ManageSubPageSection = styled(View).attrs({
  gap: '8',
  direction: 'column',
})``

export const EmptyBody = styled(Text)`
  max-width: 40ch;
`

export const EmptySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing['80']};
  background-color: ${token('surface/soft')};
  border-radius: ${p => p.theme.borderRadius['size-8']};
`

export const BetweenContainer = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8',
})``

export const InheritedText = styled(TruncatedText).attrs({
  lines: 1,
  size: 'small',
})`
  color: inherit;
`

export const ActionButton = styled.button<{ color: ColorName; faded?: boolean }>`
  font-family: ${fonts.sansSerif};
  ${fonts.body.small};
  font-weight: ${fonts.weight.bold};
  color: ${p => p.theme.color[p.color]};
  background-color: transparent;
  padding: 0.25rem 0.675rem;
  border-radius: 2rem;
  margin-right: ${spacing['4']};
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;

  &:hover {
    background-color: ${p => p.theme.color[p.color]}20;
  }

  ${p =>
    p.faded === true &&
    css`
      opacity: 0.5;
      background-color: ${p.theme.color[p.color]}20;
      cursor: not-allowed;
    `}
`

export const FilterChoice = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
`

export const InfiniteScrollMessage = React.forwardRef<
  HTMLDivElement,
  { text: string; showSanaLogo?: boolean; padding?: Padding }
>(({ text, showSanaLogo, padding }, ref) => {
  return (
    <>
      <View
        // temporary workaround on Box.ref: https://github.com/mui-org/material-ui/issues/17010
        {...({ ref } as any)}
        padding={padding ?? '96'}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        {showSanaLogo === true && <LoadingSpinner size='small' color='grey25' />}
        <Text size='small' color='grey35'>
          {text}
        </Text>
      </View>
      {padding === undefined && <Spacer size='80' />}
    </>
  )
})

export const TableContainer = styled.div`
  padding-top: 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
`

export const TableFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 2rem 1.5rem;
  background-color: ${palette.primitives.white};
`

export const Empty: React.FC<{
  title: string
  body: string
  actionLabel?: string
  iconId?: IconId
  hideIcon?: boolean
  onClick?: () => void
}> = ({ title, body, actionLabel, hideIcon, iconId, onClick }) => {
  return (
    <>
      <Spacer size='medium' />
      <EmptySection>
        {hideIcon !== true && <Icon iconId={iconId ?? 'add'} size='size-16' color='foreground/primary' />}
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {title}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center'>
          {body}
        </EmptyBody>
        <Spacer size='small' />
        {actionLabel !== undefined && onClick !== undefined && (
          <Button onClick={onClick}>{actionLabel}</Button>
        )}
      </EmptySection>
    </>
  )
}
