import _ from 'lodash'
import React, { ReactNode } from 'react'
import { IconList, IconListItem } from 'sierra-client/components/common/icon-list'
import { RemoveIcon } from 'sierra-client/components/common/modals/multi-assign-modal/icons'
import { ItemUnion } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { CourseMark } from 'sierra-client/components/courses/mark'
import { formatDateFromTo } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { PreviewImages } from 'sierra-client/views/learner/components/preview-card'
import { getDueDateStringForGroup } from 'sierra-client/views/manage/components/due-date'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { getUserStatusTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { DueDateGroup } from 'sierra-domain/api/manage'
import { iife } from 'sierra-domain/utils'
import { Icon, Label, Tooltip, TruncatedText, UserDisplay } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DueDateButton = styled(Button)`
  margin-left: -0.75rem;
  color: ${token('foreground/secondary')};
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 100%;
  padding: 1.5rem 0;

  border-bottom: 1px solid ${token('border/strong')};
`

export const SelectedItem: React.FC<{ onClick: () => void; children: ReactNode }> = ({
  onClick,
  children,
}) => (
  <ItemContainer>
    {children}
    <RemoveIcon onClick={onClick} />
  </ItemContainer>
)

export const Item: FCC<{
  onClick: () => void
  isSelected: boolean
  item: ItemUnion
}> = ({ onClick, isSelected, item, children }) => {
  const { t } = useTranslation()

  const text = iife(() => {
    if (item.type === 'live-session' && item.disabledReason === 'full') {
      return t('dictionary.full')
    }

    if (item.disabled) return t('table.assigned')

    return isSelected ? t('modal.selected') : t('modal.select')
  })

  return (
    <ItemContainer>
      {children}
      <Button
        data-testid={`assign-${item.title}`}
        variant={isSelected ? 'primary' : 'secondary'}
        onClick={onClick}
        disabled={item.disabled}
      >
        {text}
      </Button>
    </ItemContainer>
  )
}

const PreviewImageContainer = styled.div`
  width: 5rem;
  min-width: 5rem;
`
type ItemContentProps = {
  item: ItemUnion
  onDueDateClick?: (item: ItemUnion) => void
  dueDate?: DueDateGroup
}

type BaseItemProps = { left?: JSX.Element }
const BaseItem: FCC<BaseItemProps> = ({ left, children }) => (
  <View grow gap='16' alignItems='stretch'>
    {left}
    <View grow direction='column' alignItems='flex-start' justifyContent='center' gap='none'>
      {children}
    </View>
  </View>
)

const CourseEditionIndentation = styled.div`
  border-radius: 2px;
  width: 4px;
  margin-right: ${16 - 4}px;
  background-color: ${palette.grey[5]};
`

export const ItemContent: React.FC<ItemContentProps> = ({ item, onDueDateClick, dueDate }) => {
  const { t } = useTranslation()
  const dueDateButton =
    onDueDateClick !== undefined ? (
      <DueDateButton
        key='due-date-button'
        onClick={() => onDueDateClick(item)}
        icon='calendar'
        variant='transparent'
      >
        {dueDate !== undefined ? getDueDateStringForGroup({ t, dueDate }) : t('due-date.set-due-date')}
      </DueDateButton>
    ) : null

  const typeLabel = (() => {
    if (item.type !== 'course' && item.type !== 'path') return null

    const { iconId, translationKey } = getContentClassificationData({
      contentType: item.type,
      courseKind: item.type === 'course' ? item.kind : undefined,
      isCourseEdition: item.type === 'course' ? item.isCourseEdition : false,
    })

    return (
      <View gap='4'>
        <Icon iconId={iconId} color='grey40' size='size-14' />
        <Text size='small' color='grey40'>
          {t(translationKey)}
        </Text>
      </View>
    )
  })()

  switch (item.type) {
    case 'course':
      return (
        <BaseItem
          left={
            <>
              {item.isCourseEdition && <CourseEditionIndentation />}
              <PreviewImageContainer>
                <CourseMark kind={item.kind}>
                  <Thumbnail image={item.image} />
                </CourseMark>
              </PreviewImageContainer>
            </>
          }
        >
          <TruncatedText key='title' size='small' lines={2} bold>
            {item.title}
          </TruncatedText>
          {typeLabel}
          {item.kind === 'native:live' ? null : dueDateButton}
        </BaseItem>
      )
    case 'path':
      return (
        <BaseItem
          left={
            <PreviewImageContainer>
              <PreviewImages type='thumbnail' images={item.images} />
            </PreviewImageContainer>
          }
        >
          <TruncatedText key='path-title' size='small' lines={2} bold>
            {item.title}
          </TruncatedText>
          {typeLabel}
          {dueDateButton}
        </BaseItem>
      )
    case 'user':
      return (
        <View direction='row' grow justifyContent='flex-start' alignItems='center'>
          <UserDisplay
            primaryText={item.title}
            secondaryText={item.email}
            avatar={{
              firstName: item.firstName,
              lastName: item.lastName,
              src: getAvatarImage(item.id, item.image),
              color: item.color,
              size: 'medium',
            }}
            nameLabel={
              item.status === 'active' ? undefined : (
                <Label $size='small' $bgColor='white' $color='grey30' $borderColor='grey30'>
                  {t(getUserStatusTranslationKey(item.status))}
                </Label>
              )
            }
          />
          {dueDateButton}
        </View>
      )
    case 'user-group':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' key='group-title-and-user-count'>
            <Tooltip title={item.title}>
              <TruncatedText color='foreground/primary' size='small' lines={1} bold>
                {item.title}
              </TruncatedText>
            </Tooltip>
            <TruncatedText size='small' lines={1} color='grey50'>
              {t('manage.users.n-users', { count: item.userCount })}
            </TruncatedText>
          </View>
          {dueDateButton}
        </BaseItem>
      )
    case 'program':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' key='group-title-and-user-count'>
            <TruncatedText color='foreground/primary' size='small' lines={1} bold>
              {item.title}
            </TruncatedText>
            <TruncatedText size='small' lines={1} color='grey50'>
              {t('manage.users.n-users', { count: item.userCount })}
            </TruncatedText>
          </View>
          {dueDateButton}
        </BaseItem>
      )
    case 'live-session':
      return (
        <BaseItem>
          <View gap='4' alignSelf='stretch' justifyContent='space-between' key='live-session-title'>
            <TruncatedText color='foreground/primary' size='small' lines={1} bold>
              {item.title}
            </TruncatedText>
            <Spacer axis='horizontal' size='4' />
            {item.startTime !== undefined && item.endTime !== undefined && (
              <TruncatedText size='small' lines={1} color='grey50'>
                {formatDateFromTo({ dateFrom: item.startTime, dateTo: item.endTime }) ?? ''}
              </TruncatedText>
            )}
          </View>
          <Spacer size='4' />
          <IconList>
            {_.compact([
              <IconListItem key='users' iconId='user--group' text={item.assignmentsText} />,
              item.locationValue !== undefined && item.locationValue.length > 0 && (
                <IconListItem key='location' iconId='location' text={item.locationValue} />
              ),
            ])}
          </IconList>
        </BaseItem>
      )
    default:
      return <></>
  }
}

export const ItemRow: React.FC<{
  item: ItemUnion
  isSelected: boolean
  onClick: () => void
}> = ({ item, isSelected, onClick }) => (
  <Item key={item.id} isSelected={isSelected} item={item} onClick={onClick}>
    <ItemContent item={item} />
  </Item>
)

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow-y: auto;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
