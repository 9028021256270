import _ from 'lodash'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Measurable } from 'sierra-client/domain/commenting/types'
import { InlineTextIndicator } from 'sierra-client/views/commenting/text-highlights-layer'
import { createMeasurable, rangeToMergedRelativeRectangles } from 'sierra-client/views/commenting/utils'
import { ColorBuilder } from 'sierra-ui/color'
import { MUIClickAwayListener, MUIPopper } from 'sierra-ui/mui'
import { FCC } from 'sierra-ui/types'
import { BaseRange } from 'slate'
import { useSlate } from 'slate-react'
import styled from 'styled-components'
// import { LearnerNotesHighlights } from './learner-notes-highlights'
import { motion } from 'framer-motion'
import { TextHighlightsLayer } from 'sierra-client/views/commenting/text-highlights-layer'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { RangeContentReference } from 'sierra-domain/chat'
import { Button, TextAreaPrimitive, View } from 'sierra-ui/primitives'

import { useNotif } from 'sierra-client/components/common/notifications'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { EditableContent, editUrl } from 'sierra-domain/api/editable-content'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAuthorUpdateLearnerNotes } from 'sierra-domain/routes'
import { textInNodes } from 'sierra-domain/slate-util'
import { zIndex } from 'sierra-ui/theming'

interface LearnerNotesContextValue {
  range: BaseRange | undefined
  setRange: (value: BaseRange | undefined) => void
  highlightIndicator: InlineTextIndicator | undefined
  setHighlightIndicator: (value: InlineTextIndicator | undefined) => void
}

const LearnerNotesContextImpl = createContext<LearnerNotesContextValue | null>(null)

export function useLearnerNotes(): LearnerNotesContextValue | undefined {
  const context = useContext(LearnerNotesContextImpl)
  if (context === null) {
    return undefined
  }
  return context
}

const LearnerNotesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 480px;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 16px 24px 1px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  gap: 16px;
`

const NoteBlockQuote = styled.div`
  position: relative;

  opacity: 0.8;
  background: rgba(251, 230, 191, 0.6);
  color: rgba(113, 52, 7, 1);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out;
  padding: 16px 32px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 20px;

  &::before {
    content: '';
    position: absolute;
    background: rgba(255, 198, 144, 1);

    opacity: 0.8;
    width: 4px;
    height: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 2px;
    left: 0;
  }
`

const StyledPre = styled.pre`
  font-family: 'Sana Serif 05';

  white-space: pre-wrap;
`

const RemoteNotesWrapper: React.FC<{
  text: string
  rangeText: string | undefined
  onDone: (courseId: EditableContent['id'], fileId?: FileId) => void
  onClose: () => void
}> = ({ text, rangeText, onDone, onClose }) => {
  const { t } = useTranslation()

  const courseId = useSelfPacedFiles().flexibleContentId

  return (
    <View justifyContent='flex-end' alignItems='flex-end'>
      <Button onClick={onClose} variant='transparent'>
        {t('cancel')}
      </Button>
      <Button
        variant='success'
        onClick={async () => {
          const result = await typedPost(XRealtimeAuthorUpdateLearnerNotes, {
            courseId,
            markedText: rangeText ?? '',
            noteText: text,
          })
          if (result.notesLocation !== undefined) {
            onDone(result.notesLocation.courseId, result.notesLocation.fileId)
          }
        }}
      >
        {t('modal.save')}
      </Button>
    </View>
  )
}

const StyledTextArea = styled(TextAreaPrimitive)`
  padding: 0;
  outline: none;
  :hover {
    outline: none;
  }
  border-radius: 0;
  font-size: 16px;
`

export const LearnerNotesProvider: FCC<{ container: HTMLElement; color: ColorBuilder }> = ({
  children,
  container,
  color,
}) => {
  const [range, setRange] = useState<BaseRange | undefined>(undefined)
  const [threadAnchor, setThreadAnchorState] = useState<Measurable>()
  const [highlightIndicator, setHighlightIndicator] = useState<InlineTextIndicator | undefined>()
  const [value, setValue] = useState('')
  const notif = useNotif()
  const { t } = useTranslation()

  const editor = useSlate()

  const rangeReference = useMemo((): RangeContentReference | undefined => {
    if (!range) return undefined

    const sourceText = editor
      .fragment(range)
      .map(node => textInNodes([node]).join(''))
      .join('\n\n')

    return {
      type: 'range',
      id: 'learner-note-highlight',
      unitId: 'temp-unit-id',
      rangePreview: sourceText,
    }
  }, [editor, range])

  useEffect(() => {
    const containerRect = container.getBoundingClientRect()

    if (range === undefined) return setThreadAnchorState(undefined)

    const rectangles = rangeToMergedRelativeRectangles({ editor, containerRect, range })
    const anchor = _.last(rectangles)

    if (anchor === undefined) return setThreadAnchorState(undefined)

    setThreadAnchorState(createMeasurable(anchor))

    setHighlightIndicator({
      id: 'learner-note-highlight',
      opacity: 0.3,
      rectangles: rectangles,
    })
  }, [container, editor, range])

  const handleOnDone = useCallback(
    (id: EditableContent['id'], fileId?: FileId) => {
      notif.push({
        type: 'custom',
        body: t('learner-notes.saved'), //'Saved to notes'
        target: '_blank',
        level: 'success',
        url: editUrl('native:self-paced', id, fileId),
      })
      setValue('')
      setThreadAnchorState(undefined)
      setHighlightIndicator(undefined)
    },
    [notif, t]
  )

  return (
    <LearnerNotesContextImpl.Provider value={{ range, setRange, highlightIndicator, setHighlightIndicator }}>
      <TextHighlightsLayer color={color} indicators={highlightIndicator ? [highlightIndicator] : []} />
      <MUIPopper
        style={{ zIndex: zIndex.TOOLBAR }}
        open={threadAnchor !== undefined}
        disablePortal
        anchorEl={threadAnchor}
        placement='bottom-start'
        modifiers={{
          offset: {
            enabled: true,
            offset: '0, 8',
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
          },
          hide: {
            enabled: false,
          },
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
      >
        <AppThemeTokenProvider>
          <MUIClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={() => {
              setRange(undefined)
              setHighlightIndicator(undefined)
            }}
          >
            <LearnerNotesContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1 }}
            >
              {rangeReference && (
                <NoteBlockQuote>
                  <StyledPre>
                    {rangeReference.rangePreview !== undefined && rangeReference.rangePreview.length > 0 ? (
                      <>{rangeReference.rangePreview}</>
                    ) : (
                      'Lorum ipsum dolor sit amet'
                    )}
                  </StyledPre>
                </NoteBlockQuote>
              )}
              <StyledTextArea
                autoFocus
                autoExpand
                placeholder={t('learner-notes.placeholder')} //'Write a note...'
                rows={2}
                value={value}
                onChange={event => setValue(event.target.value)}
              />

              <RemoteNotesWrapper
                text={value}
                rangeText={rangeReference?.rangePreview}
                onDone={handleOnDone}
                onClose={() => {
                  setValue('')
                  setThreadAnchorState(undefined)
                  setHighlightIndicator(undefined)
                }}
              />
            </LearnerNotesContainer>
          </MUIClickAwayListener>
        </AppThemeTokenProvider>
      </MUIPopper>
      {children}
    </LearnerNotesContextImpl.Provider>
  )
}
