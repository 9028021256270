import { UseQueryResult } from '@tanstack/react-query'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { RecommendationsResponse } from 'sierra-domain/api/recommendations'
import { ZodRouteInput } from 'sierra-domain/api/types'
import { XRealtimeStrategyRecommend2 } from 'sierra-domain/routes'

const staleTime = 5 * 60 * 1000 // 5 minutes

const defaultInput = {
  limit: 6,
  cardTypes: ['recently-edited', 'continue-editing'],
} as const satisfies ZodRouteInput<typeof XRealtimeStrategyRecommend2>

export const prefetchRecentlyEditedQuery = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeStrategyRecommend2, defaultInput, {
    staleTime,
  })
}

export const useRecentlyEditedQuery = (): UseQueryResult<RecommendationsResponse> => {
  return useCachedQuery(XRealtimeStrategyRecommend2, defaultInput, {
    staleTime,
  })
}
